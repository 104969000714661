<template>
  <div class="common-layout" style="width: 100%">
    <el-container style="height: 100%; width: 100%; padding: 24px;">
      <div style="display: flex; width: 100%;">
     <dbTree></dbTree>
       <router-view></router-view>
      </div>
    </el-container>
  </div>
</template>

<script>
import dbTree from "./DataSourceTree.vue";
export default {
  name: "DataSourceManage",
  components: {
    dbTree
  },
  setup() {
    return {
    };
  },

};
</script>


<style lang="scss">
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 20px;
}

.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.image {
  border: 1px;
  width: 120px;
  height: 120px;
}

.item .el-form-item__label {
  color: grey;
}

.item .el-form-item {
  margin-bottom: 0px;
}

/*设置tree每行的高度*/
.el-tree-node__content {
  height: 35px !important;
}

.el-card-define.el-card {
  height: 14em;
}
</style>
