export const breadCrumbPath = {
    ApiTest: {
        zh_CN: [
          { id: 0, path: '/home/serviceOperation', name: '服务运维' },
          { id: 1, path: '/home/serviceOperation/ApiTest', name: 'API测试小工具' },
        ],
    },
    toDevelopServices: {
        zh_CN: [
          { id: 0, path: '/home/mine', name: '我的' },
          { id: 1, path: '/home/mine/toDevelopServices', name: '待我开发服务' },
        ],
    },
    MyAPI: {
        zh_CN: [
          { id: 0, path: '/home/mine', name: '我的' },
          { id: 1, path: '/home/mine/myAPI', name: '我的服务' },
        ],
    },
    MySubscription: {
        zh_CN: [
          { id: 0, path: '/home/mine', name: '我的' },
          { id: 1, path: '/home/mine/mySubscription', name: '我的订阅' },
        ],
    },
    ApprovalCenter: {
        zh_CN: [
          { id: 0, path: '/home/mine', name: '我的' },
          { id: 1, path: '/home/mine/ApprovalCenter', name: '我的审批' },
        ],
    },
    ApproveCenterDetail: {
        zh_CN: [
          { id: 0, path: '/home/mine', name: '我的' },
          { id: 1, path: '/home/mine/ApprovalCenter', name: '我的审批' },
          { id: 2, path: '/home/mine/ApprovalCenter/ApproveDetail', name: '我的审批详情' },
        ],
    },
    myDesign: {
        zh_CN: [
          { id: 0, path: '/home/mine', name: '我的' },
          { id: 1, path: '/home/mine/ApprovalCenter', name: '我的设计' }
        ],
    },
    mineApiDesignDetail: {
        zh_CN: [
          { id: 0, path: '/home/mine', name: '我的' },
          { id: 1, path: '/home/mine/myDesign', name: '我的设计' },
          { id: 2, path: '/home/mine/myDesign/detail', name: 'API设计编辑页' }
        ],
    },
    APIAuthManagement: {
        zh_CN: [
          { id: 0, path: '/home/DataResourceCenter', name: '数据资源中心' },
          { id: 1, path: '/home/DataResourceCenter/APIAuthManagement', name: '外部服务鉴权管理' },
        ],
    },
    APIAuthManagementDetail: {
        zh_CN: [
          { id: 0, path: '/home/DataResourceCenter', name: '数据资源中心' },
          { id: 1, path: '/home/DataResourceCenter/APIAuthManagement', name: '外部服务鉴权管理' },
          { id: 2, path: '/home/DataResourceCenter/APIAuthManagement/APIAuthManagementDetail', name: '外部服务鉴权管理编辑页' }
        ],
    },
    DataSourceList: {
        zh_CN: [
          { id: 0, path: '/home/DataResourceCenter', name: '数据资源中心' },
          { id: 1, path: '/home/DataResourceCenter/DataSourceManage/DataSourceList', name: '数据源管理' },
        ],
    },
    DataSourceInfo: {
        zh_CN: [
          { id: 0, path: '/home/DataResourceCenter', name: '数据资源中心' },
          { id: 1, path: '/home/DataResourceCenter/DataSourceManage/DataSourceList', name: '数据源管理' },
          { id: 1, path: '/home/DataResourceCenter/DataSourceManage/DataSourceInfo', name: '数据源管理编辑页' },
        ],
    },
    ServiceClassificationManagementDetailList: {
        zh_CN: [
          { id: 0, path: '/home/DataResourceCenter', name: '数据资源中心' },
          { id: 1, path: '/home/DataResourceCenter/ServiceClassificationManagement/list', name: '业务分类' },
        ],
    },
    ServiceClassificationManagementDetail: {
        zh_CN: [
          { id: 0, path: '/home/DataResourceCenter', name: '数据资源中心' },
          { id: 1, path: '/home/DataResourceCenter/ServiceClassificationManagement/list', name: '业务分类' },
          { id: 1, path: '/home/DataResourceCenter/ServiceClassificationManagement/detail', name: '业务分类编辑页' },
        ],
    },
    AccountSettings: {
        zh_CN: [
          { id: 0, path: '/home/UserCenter/AccountSettings', name: '用户中心' },
          { id: 1, path: '/home/UserCenter/AccountSettings', name: '账号设置' },
        ],
    },
    ApiInformation:{
        zh_CN: [
          { id: 0, path: '/home/ApiMarket/ApiMarketList', name: '服务市场' },
          { id: 1, path: '/home/ApiMarket/ApiInformation', name: '服务市场API详情' },
        ],
    },
    ApiDesign:{
        zh_CN: [
          { id: 0, path: '/home/ApiManage', name: '服务开发' },
          { id: 1, path: '/home/ApiManage/ApiDesign', name: 'API设计' },
        ],
    },
    ApiDesignDetail: {
        zh_CN: [
          { id: 0, path: '/home/ApiManage', name: '服务开发' },
          { id: 1, path: '/home/ApiManage/ApiDesign', name: 'API设计' },
          { id: 2, path: '/home/ApiManage/ApiDesign/detail', name: 'API设计编辑页' }
        ],
    },
    ApiManageCreate:{
        zh_CN: [
          { id: 0, path: '/home/ApiManage', name: '服务开发' },
          { id: 1, path: '/home/ApiManage/ApiManageCreate', name: 'API开发' },
        ],
    },
    ApiManageList:{
        zh_CN: [
          { id: 0, path: '/home/ApiManage', name: '服务开发' },
          { id: 1, path: '/home/ApiManage/ApiManagelist', name: 'API管理' },
        ],
    },
    ApiDocView: {
        zh_CN: [
          { id: 0, path: '/home/ApiManage', name: '服务开发' },
          { id: 1, path: '/home/ApiManage/ApiManagelist', name: 'API管理' },
          { id: 2, path: '/home/ApiManage/ApiView', name: 'API详情' }
        ],
    },
    ApiManageRegister:{
        zh_CN: [
          { id: 0, path: '/home/ApiManage', name: '服务开发' },
          { id: 1, path: '/home/ApiManage/ApiManageRegister', name: 'API注册' },
        ],
    },
    ApiManageRegisterDetail: {
        zh_CN: [
          { id: 0, path: '/home/ApiManage', name: '服务开发' },
          { id: 1, path: '/home/ApiManage/ApiManageRegister', name: 'API注册' },
          { id: 2, path: '/home/ApiManage/ApiManageRegister/detail', name: 'API注册编辑页' }
        ],
    },
    ApiManageRegisterView: {
      zh_CN: [
        { id: 0, path: '/home/ApiManage', name: '服务开发' },
        { id: 1, path: '/home/ApiManage/ApiManageRegister', name: 'API注册' },
        { id: 2, path: '/home/ApiManage/ApiManageRegister/view', name: 'API注册详情页' }
      ],
    },
    ApiFlowChartManagementList:{
      zh_CN: [
        { id: 0, path: '/home/ApiFlowChart', name: '服务编排' },
        { id: 1, path: '/home/ApiFlowChart/ApiFlowChartManagement', name: '服务编排管理' },
      ], 
    },
    ApiFlowChartManagementEdit:{
      zh_CN: [
        { id: 0, path: '/home/ApiFlowChart', name: '服务编排' },
        { id: 1, path: '/home/ApiFlowChart/ApiFlowChartManagement', name: '服务编排管理' },
        { id: 1, path: '/home/ApiFlowChart/ApiFlowChartManagementEdit', name: '服务编排管理编辑页' },

      ], 
    },
    ApiFlowChartManagementDetail:{
      zh_CN: [
        { id: 0, path: '/home/ApiFlowChart', name: '服务编排' },
        { id: 1, path: '/home/ApiFlowChart/ApiFlowChartManagement', name: '服务编排管理' },
        { id: 1, path: '/home/ApiFlowChart/ApiFlowChartManagementDetail', name: '服务编排管理编排页' },

      ], 
    },
    ApiCodePluginsList:{
      zh_CN: [
        { id: 0, path: '/home/ApiFlowChart', name: '服务编排' },
        { id: 1, path: '/home/ApiFlowChart/apiCodePlugins', name: '编排代码插件管理' },
      ], 
    },
    AppInformation:{
        zh_CN: [
          { id: 0, path: '/home/BaseConfig', name: '系统管理' },
          { id: 1, path: '/home/BaseConfig/App/AppInformation', name: '应用配置' },
        ],
    },
    AppDetails:{
        zh_CN: [
          { id: 0, path: '/home/BaseConfig', name: '系统管理' },
          { id: 1, path: '/home/BaseConfig/App/AppInformation', name: '应用配置' },
          { id: 2, path: '/home/BaseConfig/App/AppDetails', name: '应用配置详情' },

        ],
    },
    BaseConfigTenant:{
        zh_CN: [
          { id: 0, path: '/home/BaseConfig', name: '系统管理' },
          { id: 1, path: '/home/BaseConfig/BaseConfigTenant', name: '租户配置' },
        ],
    },
    BaseConfigUser:{
        zh_CN: [
          { id: 0, path: '/home/BaseConfig', name: '系统管理' },
          { id: 1, path: '/home/BaseConfig/BaseConfigUser', name: '用户管理' },
        ],
    },
    BaseConfigRole:{
        zh_CN: [
          { id: 0, path: '/home/BaseConfig', name: '系统管理' },
          { id: 1, path: '/home/BaseConfig/BaseConfigRole', name: '角色管理' },
        ],
    },
    AuthorityManagementList:{
        zh_CN: [
          { id: 0, path: '/home/BaseConfig', name: '系统管理' },
          { id: 1, path: '/home/BaseConfig/AuthorityManagement/list', name: '菜单管理' },
        ],
    },
    AuthorityManagementDetail:{
        zh_CN: [
          { id: 0, path: '/home/BaseConfig', name: '系统管理' },
          { id: 1, path: '/home/BaseConfig/AuthorityManagement/list', name: '菜单管理' },
          { id: 1, path: '/home/BaseConfig/AuthorityManagement/detail', name: '菜单管理编辑页' },

        ],
    },
    ApprovalConfigList:{
        zh_CN: [
          { id: 0, path: '/home/BaseConfig', name: '系统管理' },
          { id: 1, path: '/home/BaseConfig/ApprovalConfig/list', name: '审批配置' },
        ],
    },
    ApprovalConfigDetail:{
        zh_CN: [
          { id: 0, path: '/home/BaseConfig', name: '系统管理' },
          { id: 1, path: '/home/BaseConfig/ApprovalConfig/list', name: '审批配置' },
          { id: 2, path: '/home/BaseConfig/ApprovalConfig/detail', name: '审批配置详情' },
        ],
    },
    CategoryManage:{
        zh_CN: [
          { id: 0, path: '/home/BaseConfig', name: '系统管理' },
          { id: 1, path: '/home/BaseConfig/CategoryManage', name: 'API目录配置' },
        ],
    },
    ApiOperationStatistics:{
        zh_CN: [
          { id: 0, path: '/home/Security', name: '服务网关' },
          { id: 1, path: '/home/BaseConfig/ApiOperationStatistics', name: 'API日志' },
        ],
    },
    LogDetails:{
        zh_CN: [
          { id: 0, path: '/home/Security', name: '服务网关' },
          { id: 1, path: '/home/Security/ApiOperationStatistics', name: 'API日志' },
          { id: 2, path: '/home/Security/LogDetails', name: 'API操作日志详情' },

        ],
    },
    Alarm:{
        zh_CN: [
          { id: 0, path: '/home/Security', name: '服务网关' },
          { id: 1, path: '/home/Security/Alarm', name: '告警配置' },

        ],
    },
    ApiOperationLow:{
        zh_CN: [
          { id: 0, path: '/home/Security', name: '服务网关' },
          { id: 1, path: '/home/Security/ApiOperationStatistics', name: 'API日志' },
          { id: 2, path: '/home/Security/ApiOperationLow', name: 'API操作日志详情' },

        ],
    },
    // InvokeDetails:{ 已废弃
    //     zh_CN: [
    //       { id: 0, path: '/home/Security', name: '服务网关' },
    //       { id: 1, path: '/home/BaseConfig/ApiOperationStatistics', name: 'API日志' },
    //       { id: 2, path: '/home/BaseConfig/InvokeDetails', name: '调用详情' },

    //     ],
    // },
    Policy:{
        zh_CN: [
          { id: 0, path: '/home/Security', name: '服务网关' },
          { id: 1, path: '/home/Security/Policy', name: '访问策略管理' },

        ],
    },
    Config:{
        zh_CN: [
          { id: 0, path: '/home/Security', name: '服务网关' },
          { id: 1, path: '/home/Security/Config', name: 'API安全配置' },

        ],
    },
    OperateManagementList:{
      zh_CN: [
        { id: 0, path: '/home/operateManagement', name: '服务运营' },
        { id: 1, path: '/home/operateManagement/list', name: '运营管理' },

      ],
    },
    OperateManagementEdit:{
      zh_CN: [
        { id: 0, path: '/home/operateManagement', name: '服务运营' },
        { id: 1, path: '/home/operateManagement/list', name: '运营管理' },
        { id: 2, path: '/home/operateManagement/edit', name: '运营管理编辑页' },


      ],
    },

    OperateIndicatorsManagementList:{
      zh_CN: [
        { id: 0, path: '/home/operateIndicatorsManagement', name: '服务运营' },
        { id: 1, path: '/home/operateIndicatorsManagement/list', name: '运营指标定义管理' },

      ],
    },
    OperateIndicatorsManagementEdit:{
      zh_CN: [
        { id: 0, path: '/home/operateIndicatorsManagement', name: '服务运营' },
        { id: 1, path: '/home/operateIndicatorsManagement/list', name: '运营指标定义管理' },
        { id: 2, path: '/home/OperateIndicatorsManagementEdit/edit', name: '运营指标定义管理编辑页' },


      ],
    },
    registerProject:{
        zh_CN: [
          { id: 0, path: '/home/Security', name: '服务网关' },
          { id: 1, path: '/home/Security/registerProject', name: '项目路由注册管理' },

        ],
    },
    registerProjectDetail:{
        zh_CN: [
          { id: 0, path: '/home/Security', name: '服务网关' },
          { id: 1, path: '/home/Security/registerProject', name: '项目路由注册管理' },
          { id: 2, path: '/home/Security/registerProjectDetail', name: '项目路由注册管理编辑页' },
        ],
    },
    OverallfunctionList:{
      zh_CN: [
        { id: 0, path: '/home/DataResourceCenter', name: '数据资源中心' },
        { id: 1, path: '/home/DataResourceCenter/Overallfunction/list', name: '函数管理' },

      ],
    },
    OverallfunctionDetail:{
        zh_CN: [
          { id: 0, path: '/home/DataResourceCenter', name: '数据资源中心' },
          { id: 1, path: '/home/DataResourceCenter/Overallfunction/list', name: '函数管理' },
          { id: 2, path: '/home/DataResourceCenter/Overallfunction/detail', name: '函数管理详情页' },
        ],
    },
    KeyManagement:{
      zh_CN: [
        { id: 0, path: '/home/DataResourceCenter', name: '数据资源中心' },
        { id: 1, path: '/home/DataResourceCenter/keyManagement/list', name: '密钥管理' },

      ],
    },
    KeyManagementDetail:{
        zh_CN: [
          { id: 0, path: '/home/DataResourceCenter', name: '数据资源中心' },
          { id: 1, path: '/home/DataResourceCenter/keyManagement/list', name: '密钥管理' },
          { id: 2, path: '/home/DataResourceCenter/keyManagement/detail', name: '密钥管理详情页' },
        ],
    },
}