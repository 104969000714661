<template>
  <div style="height: 260px" class="center-main">
    <div class="center-top-box" style="padding: 24px 0 0 20px" >
      <div class="center-top-left">
        <img
          src="../../../assets/image/cockpit/center-icon.png"
          alt=""
          style="width: 72px; height: 72px; margin-top: 17px"
        />
        <div class="center-top-content">
          <div class="top-text">数据源数量</div>
          <div class="mid-text">
            <!-- {{ pageData.dataSourceCount }} -->
            <AnimatedNumber
            v-if="initShow"
              :from="0"
              :to="pageData.dataSourceCount"
              style="display: inline-block"
            ></AnimatedNumber>
            <span class="unit-box">个</span>
          </div>
          <div class="bottom-text">总数</div>
        </div>
        <div class="center-top-content" style="width: 80px">
          <div class="top-text"></div>
          <div class="mid-text"></div>
          <div class="bottom-text"></div>
        </div>
      </div>
      <div class="center-top-left">
        <img
          src="../../../assets/image/cockpit/center-icon.png"
          alt=""
          style="width: 72px; height: 72px; margin-top: 17px"
        />
        <div class="center-top-content">
          <div class="top-text">API发布数量</div>
          <div class="mid-text">
            <AnimatedNumber
            v-if="initShow"
              :from="0"
              :to="pageData.publisherApiCountYear"
              style="display: inline-block"
            ></AnimatedNumber>
            <span class="unit-box">个</span>
          </div>
          <div class="bottom-text">累计</div>
        </div>
        <div class="center-top-content">
          <div class="top-text"></div>
          <div class="mid-text">
            <AnimatedNumber
            v-if="initShow"
              :from="0"
              :to="pageData.publisherApiCountToday"
              style="display: inline-block"
            ></AnimatedNumber>
            <span class="unit-box">个</span>
          </div>
          <div class="bottom-text">当日</div>
        </div>
      </div>
      <div class="center-top-left">
        <img
          src="../../../assets/image/cockpit/center-icon.png"
          alt=""
          style="width: 72px; height: 72px; margin-top: 17px"
        />
        <div class="center-top-content">
          <div class="top-text">API订阅数量</div>
          <div class="mid-text">
            <AnimatedNumber
            v-if="initShow"
              :from="0"
              :to="pageData.subscribedNumberYear"
              style="display: inline-block"
            ></AnimatedNumber>
            <span class="unit-box">个</span>
          </div>
          <div class="bottom-text">累计</div>
        </div>
        <div class="center-top-content">
          <div class="top-text"></div>
          <div class="mid-text">
            <AnimatedNumber
            v-if="initShow"
              :from="0"
              :to="pageData.subscribedNumberToday"
              style="display: inline-block"
            ></AnimatedNumber>
            <span class="unit-box">个</span>
          </div>
          <div class="bottom-text">当日</div>
        </div>
      </div>
    </div>
    <div class="center-top-box" style="padding: 24px 0 0 20px">
      <div class="center-top-left">
        <img
          src="../../../assets/image/cockpit/center-icon.png"
          alt=""
          style="width: 72px; height: 72px; margin-top: 17px"
        />
        <div class="center-top-content">
          <div class="top-text">API订阅次数</div>
          <div class="mid-text">
            <AnimatedNumber
            v-if="initShow"
              :from="0"
              :to="pageData.subscribedQuantityYear"
              style="display: inline-block"
            ></AnimatedNumber>
            <span class="unit-box">次</span>
          </div>
          <div class="bottom-text">累计</div>
        </div>
        <div class="center-top-content">
          <div class="top-text"></div>
          <div class="mid-text">
            <AnimatedNumber
            v-if="initShow"
              :from="0"
              :to="pageData.subscribedQuantityToday"
              style="display: inline-block"
            ></AnimatedNumber><span class="unit-box">次</span>
          </div>
          <div class="bottom-text">当日</div>
        </div>
      </div>
      <div class="center-top-left">
        <img
          src="../../../assets/image/cockpit/center-icon.png"
          alt=""
          style="width: 72px; height: 72px; margin-top: 17px"
        />
        <div class="center-top-content">
          <div class="top-text">API调用次数</div>
          <div class="mid-text">
            <!-- {{ pageData.totalInvokeYear }} -->
            <AnimatedNumber
            v-if="initShow"
              :from="0"
              :to="pageData.totalInvokeYear"
              style="display: inline-block"
            ></AnimatedNumber>
            <span class="unit-box">次</span>
          </div>
          <div class="bottom-text">累计</div>
        </div>
        <div class="center-top-content">
          <div class="top-text"></div>
          <div class="mid-text">
            <!-- {{ pageData.totalInvokeToday }} -->
             <AnimatedNumber
             v-if="initShow"
              :from="0"
              :to="pageData.totalInvokeToday"
              style="display: inline-block"
            ></AnimatedNumber>
            <span class="unit-box">次</span>
          </div>
          <div class="bottom-text">当日</div>
        </div>
      </div>
      <div class="center-top-left">
        <img
          src="../../../assets/image/cockpit/center-icon.png"
          alt=""
          style="width: 72px; height: 72px; margin-top: 17px"
        />
        <div class="center-top-content">
          <div class="top-text">API调用平均响应时间</div>
          <div class="mid-text" style="display: flex">
            <div style="width: 60px">
              <span :title="pageData.averageInvokeTimeYear">
                <!-- {{ parseInt(pageData.averageInvokeTimeYear) }} -->
                  <AnimatedNumber
                  v-if="initShow"
              :from="0"
              :to="pageData.averageInvokeTimeYear || 0"
              :round="10"
              style="display: inline-block"
            ></AnimatedNumber>
                </span
              >
              <span class="unit-box">s</span>
            </div>
            <div style="margin-left: 20px">
              <!-- {{ pageData.averageInvokeTimeToday
              }} -->
                 <AnimatedNumber
                 v-if="initShow"
              :from="0"
              :to="pageData.averageInvokeTimeToday || 0"
              :round="10"
              style="display: inline-block"
            ></AnimatedNumber><span class="unit-box">s</span>
            </div>
          </div>
          <div class="bottom-text" style="display: flex">
            <div style="width: 60px">累计</div>
            <div style="margin-left: 20px">当日</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {request} from '@/api'
import { ref, watch } from "vue";
export default {
  name: "CenterTop",
  props: {
    appId: {
      type: String,
    },
  },
  setup(props) {
    const text = ref("各项数据统计");
    const initShow = ref(false);
    const pageData = ref({
      // averageInvokeTimeToday: 0,
      // averageInvokeTimeYear: 0,
      // dataSourceCount: 0,
      // publisherApiCountToday: 0,
      // publisherApiCountYear: 0,
      // subscribedNumberToday: 0,
      // subscribedNumberYear: 0,
      // subscribedQuantityToday: 0,
      // subscribedQuantityYear: 0,
      // totalInvokeToday: 0,
      // totalInvokeYear: 0,
    });
    const getData = () => {
      request.getApiIndicator({appId: props.appId}).then((res) => {
        if (res.data.code == 200) {
          pageData.value = { ...res.data.data };
          initShow.value = true;
        }
      });
    };
    getData();
    watch(
      () => props.appId,
      (newData) => {
        initShow.value=false
        if (newData) {
          getData();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return {
      text,
      getData,
      pageData,
      initShow,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "./CenterTop.scss";
</style>