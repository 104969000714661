<template>
  <div>
    <div align="left" class="margin-botton-12">
        <strong>基础配置</strong>
    </div>
    <el-form
        ref="ruleFormRef"
        :model="form"
        :rules="basicRules"
        label-position="top"
      >
       <el-row>
            <el-col :span="20" align="left">
                <el-form-item
                label="编排组件包"
                prop="packageId"
                >
                <el-select
                        v-model="form.packageId"
                        filterable
                        clearable
                        remote
                        reserve-keyword
                        placeholder="请输入搜索关键字"
                        :remote-method="getArrangePackageList"
                        @change="changePackage"
                        style="width: 100%"
                    >
                        <el-option
                        v-for="pa in packageList"
                        :key="pa.packageId"
                        :label="pa.packageName"
                        :value="pa.packageId"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
                      <el-col :span="20" align="left">
                <el-form-item
                label="编排组件类"
                prop="classId"
                >
                <el-select
                        v-model="form.classId"
                        filterable
                        clearable
                        style="width: 100%"
                    >
                        <el-option
                        v-for="cl in classList"
                        :key="cl.classId"
                        :label="cl.nodeName"
                        :value="cl.classId"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        </el-form>

    <div align="left" class="margin-botton-12">
        <strong>出参配置</strong>
    </div>
     <el-button type="primary" icon="plus" @click="addRow" style="margin-bottom:20px;">新增出参</el-button>
     <el-table :data="out" max-height="285">
       
                 <el-table-column
                    prop="apiFieldCnname"
                    label="参数中文名称"
                    width="200"
                    :show-overflow-tooltip="true"
                    >
                    <template #default="scope">
                        <el-input
                        v-model="scope.row.apiFieldCnname"
                        clearable
                        maxlength="50"
                        placeholder="请输入"
                        ></el-input>
                    </template>
                    </el-table-column>
                     <el-table-column
                    prop="apiFieldName"
                    label="参数名称"
                    width="200"
                    :show-overflow-tooltip="true"
                    >
                    <template #default="scope">
                        <el-input
                        v-model="scope.row.apiFieldName"
                        clearable
                        maxlength="50"
                        placeholder="请输入"
                        ></el-input>
                    </template>
                    </el-table-column>
               <el-table-column prop="apiFieldType" label="参数类型" width="200">
                  <template #default="scope">
                    <el-select
                      clearable
                      v-model="scope.row.apiFieldType"
                      placeholder="请选择数据类型"
                    >
                      <el-option v-for="type in paramsTypes" :key="type.label" :label="type.label" :value="type.value" />
                    </el-select>
                  </template>
               </el-table-column>
                <el-table-column
                    prop="example"
                    label="示例值"
                    width="200"
                    :show-overflow-tooltip="true"
                    >
                    <template #default="scope">
                        <el-input
                        v-model="scope.row.example"
                        clearable
                        maxlength="50"
                        placeholder="请输入"
                        ></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="fieldDescription"
                    label="描述"
                    width="200"
                    :show-overflow-tooltip="true"
                    >
                    <template #default="scope">
                        <el-input
                        v-model="scope.row.fieldDescription"
                        clearable
                        maxlength="50"
                        placeholder="请输入"
                        ></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80" align="center" fixed="right">
                    <template #default="scope">
                        <el-popconfirm
                        title="删除后将无法恢复，请确认是否删除？"
                        confirm-button-type="danger"
                        icon="warning-filled"
                        icon-color="#F56C6C"
                        width="250"
                        placement="top-end"
                        @confirm="deleteRow(index,scope.$index)"
                        >
                        <template #reference>
                            <el-button
                            icon="delete"
                            type="danger"
                            text
                            ></el-button>
                        </template>
                        </el-popconfirm>
                    </template>
                    </el-table-column>
            </el-table>
  </div>
</template>

<script>
import { ref,onBeforeUnmount,onMounted, reactive} from 'vue';
import {request} from '@/api'
// import { ElMessage } from "element-plus";
import {getInitMapperTable} from '@/utils/function.js'
import { paramsTypesList } from '@/utils/constant'
export default {
    props:['iasmApiArrangeNode','nodeConfigGraphData','operatorFlowId','operatorData'],
    setup(props){
        let out = ref([])
        let parentout = ref([])
        let packageList = ref([])
        let classList = ref([])
        let ruleFormRef = ref(null)
        let paramsTypes = ref(paramsTypesList)
        const basicRules = reactive({
            packageId: [
                { required: true, message: "请选择编排组件包", trigger: "blur" },
            ],
            classId: [
                { required: true, message: "请选择编排组件类", trigger: "blur" }
            ],
            });


        let form = ref({
            packageId:null,
            classId: null
        })
        if(props.iasmApiArrangeNode && props.iasmApiArrangeNode.nodeData){
            let nodeData = JSON.parse(props.iasmApiArrangeNode.nodeData)
            out.value = nodeData.out
            form.value = nodeData.data
            parentout.value = nodeData.parentout
        }else{
            //获取上一级的table
            out.value = getInitMapperTable(
                props.nodeConfigGraphData,
                props.operatorData,
                props.operatorFlowId,
            );
        }

        const getNodeData = ()=>{
            return {
                data:form.value,
                out:out.value,
                parentout:parentout.value 
            }
        }

        const addRow = () => {
            out.value.push({
                 apiFieldCnname:null,
                 apiFieldName:null,
                 apiFieldType:null,
                 example:null,
                 fieldDescription:null,
            });
        };

        const deleteRow = (index)=>{
          out.value.splice(index, 1);
        }

        const getArrangePackageList = (query) => {
        //   if (query) {
            request
              .getArrangePackageList({
                apiName: query,
                appId: localStorage.getItem("appId"),
                currentPage: 1,
                pageSize: 10000,
              })
              .then((res) => {
                if (res?.data?.code === 200) {
                  packageList.value = res.data.data.result;
                }
              });
        //   } else {
        //     packageList.value = [];
        //   }
        };

        
        const changePackage = (packageId)=>{
            form.value.classId = null
            getPackage(packageId)
        }

        const getPackage = (packageId)=>{
            request
                .getPackageClassByPackageId({packageId})
                .then((res) => {
                if (res?.data?.code === 200) {
                    classList.value = res.data.data
                }
                });
        }

        const reset = ()=>{
           out.value = []
        }

        const validate =  async ()=>{
            if(!ruleFormRef.value) return
            let v
            await ruleFormRef.value.validate((valid) => {
                v =  valid
            });
            return v
        }

        onBeforeUnmount(() => {
         reset()
        });

        onMounted(()=>{
            getArrangePackageList()
            getPackage(form.value.packageId)
        })
        return{
            out,
            addRow,
            deleteRow,
            getNodeData,
            reset,
            getArrangePackageList,
            packageList,
            form,
            changePackage,
            classList,
            paramsTypes,
            basicRules,
            ruleFormRef,
            validate
        }
    }

}
</script>

<style lang="less" scoped>
.step-title{
    margin-top: 50px;
}
</style>