export const apiStatus = {
    draft:'草稿',
    design:'设计中',
    pending_developed:'待开发',
    developed:'开发中',
    released:'待发布',
    approval:'待审批',
    online:'已上线',
    offline:'已下线',
    redesign:'重新设计',

}

export const apiServiceTypes = {
    select: "查询服务",
    insert: "写入服务",
    update: "修改服务",
    delete: "删除服务",
  }

export const paramsTypesMap = {
    '1':'Integer',
    '2':'String',
    '3':'Date',
    '4':'Boolean',
    '5':'Long',
    '6':'Double',
    '7':'TimeStamp',
    '8':'Map',
    '9':'List',
    '10':'Array',
}

export const paramsTypesList = [
    {
        label:'Integer',
        value:'1'
    },
    {
        label:'String',
        value:'2'
    },
    {
        label:'Date',
        value:'3'
    },
    {
        label:'Boolean',
        value:'4'
    },
    {
        label:'Long',
        value:'5'
    },
    {
        label:'Double',
        value:'6'
    },
    {
        label:'TimeStamp',
        value:'7'
    },
    {
        label:'Map',
        value:'8'
    },
    {
        label:'List',
        value:'9'
    },
    {
        label:'Array',
        value:'10'
    },
]

export const RequestMap = {
    '1':'GET',
    '2':'POST',
    '3':'PUT',
    '4':'DELETE',
    '0':'任意请求方式',
}

export const RequestList = [
    {
        label:'GET',
        value:'1'
    },
    {
        label:'POST',
        value:'2'
    },
    {
        label:'PUT',
        value:'3'
    },
    {
        label:'DELETE',
        value:'4'
    },
    {
        label:'任意请求方式',
        value:'0'
    },
]

export const resultDataBuildList = [
    {
        label:'列表数据结构',
        value:'list'
    },
    {
        label:'明细数据结构',
        value:'detail'
    },
    {
        label:'结果值结构',
        value:'value'
    },
]

export const resultDataBuildMap = {
    'list':'列表数据结构',
    'detail':'明细数据结构',
    'value':'结果值结构',
}



export const sourceRequestsList = [
    {
        label:'GET',
        value:'1'
    },
    {
        label:'POST',
        value:'2'
    },
    {
        label:'PUT',
        value:'3'
    },
    {
        label:'DELETE',
        value:'4'
    }
]

export const createTypeList = [
    {
        label:'API开发',
        value:'platform_api'
    },
    {
        label:'API注册',
        value:'external_api'
    },
    {
        label:'API编排',
        value:'arrange_api'
    },
]

export const createTypeMap = {
    'platform_api':'API开发',
    'route_api':'API注册',
    'external_api':'API注册',
    'arrange_api':'API编排',
    
}

export const functionCateOptions = [
    {
      label: '平台函数',
      value: 'system',
    },
    {
      label: '自定义函数',
      value: 'custom',
    },
  ];

export const functionCateMap = {
    'system':'平台函数',
    'custom':'自定义函数',
    
}


export const functionTypes = [
    {
        label: '时间函数',
        value: '时间函数'
    },
    {
        label: '字符函数',
        value: '字符函数'
    },
    {
        label: '数字函数',
        value: '数字函数'
    },
    {
        label: '统计函数',
        value: '统计函数'
    },
    {
        label: '加解密函数',
        value: '加解密函数'
    },
    {
        label: '条件函数',
        value: '条件函数'
    },
    {
        label: '其它函数',
        value: '其它函数'
    },
]

export const functionStatusOptions = [
    {
      label: '正常',
      value: 1,
    },
    {
      label: '冻结',
      value: 2,
    },
    {
      label: '过时',
      value: 3,
    },
    {
      label: '废弃',
      value: 4,
    },
  ];

export const functionStatusMap = {
    1:'正常',
    2:'冻结',
    3:'过时',
    4:'废弃', 
}

export const expressionOptions = [
    {
      label: '等于',
      value: '=',
    },
    {
      label: '不等于',
      value: '!=',
    },
    {
      label: '大于',
      value: '>',
    },
    {
      label: '大于等于',
      value: '>=',
    },
    {
      label: '小于',
      value: '<',
    },
    {
      label: '小于等于',
      value: '<=',
    },
    {
      label: '为空',
      value: 'null',
    },
    {
      label: '不为空',
      value: 'notnull',
    }
  ]


  export const MODE =  {
    JSON: 'application/json',
    HTML: 'htmlmixed',
    JS:   'javascript',
    SQL:  'sql',
  }

export const keyTypeOptions = [
    {
      label: 'AES',
      value: 'AES',
    },
    {
      label: 'DES',
      value: 'DES',
    },
    {
      label: 'DSA',
      value: 'DSA',
    },
    {
      label: 'RSA',
      value: 'RSA',
    },
    {
      label: '国密SM2',
      value: 'SM2',
    },
    {
      label: '国密SM3',
      value: 'SM3',
    },
    {
      label: '国密SM4',
      value: 'SM4',
    },
  ]
