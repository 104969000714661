<template>
  <div style="height: 380px" class="main_bgimg bg_img4">
    <div class="main_header">
      <div class="main_header_text">{{ text }}</div>
    </div>
    <div class="main-tab-box">
      <span
        v-for="(item, index) in tab.list"
        :key="index"
        :class="['com-tab', active == item.id ? 'active-tab' : '']"
        @click="changeTab(item)"
        >{{ item.tabName }}</span
      >
    </div>
    <div class="main_content">
      <div class="main_content-left">
        <div class="img-text">
          <p class="img-text-top">
            {{ pageData.total.totalInvoke }}
            <span style="font-size: 14px">次</span>
          </p>
          <p class="img-text-bot">累计调用次数</p>
        </div>
        <img src="../../../assets/image/cockpit/apiCall.png" alt="" />
        <p>
          <img
            src="../../../assets/image/cockpit/apiSuccess.png"
            alt=""
            style="vertical-align: middle"
          />
          <span class="text-span">调用成功</span>
          <span class="count-span">
            {{ pageData.total.successInvoke }}
            <span class="unit-span">次</span>
          </span>
        </p>
        <p>
          <img
            src="../../../assets/image/cockpit/apiFail.png"
            alt=""
            style="vertical-align: middle"
          />
          <span class="text-span">调用失败</span>
          <span class="count-span">
            {{ (pageData.total.failedInvoke || 0) + (pageData.total.warnInvoke || 0) }}
            <span class="unit-span">次</span>
          </span>
        </p>
        <p>
          <img
            src="../../../assets/image/cockpit/apiSuccessRate.png"
            alt=""
            style="vertical-align: middle"
          />
          <span class="text-span">调用成功率</span>
          <span class="count-span">
            {{ pageData.total.successRate }}
            <span class="unit-span">%</span>
          </span>
        </p>
      </div>
      <div
        id="myChart2"
        :style="{ height: '340px' }"
        class="main_content-right"
      ></div>
    </div>
  </div>
</template>
<script>
import {request} from '@/api'
import * as echarts from "echarts";
import { ref, reactive, onMounted, watch } from "vue";
export default {
  name: "CenterBottom",
  props: {
    appId: {
      type: String,
    },
  },
  setup(props) {
    const text = ref("API调用");
    const active = ref(1);
    const tab = reactive({
      list: [
        { tabName: "本周", id: 1, type: "week" },
        { tabName: "本月", id: 2, type: "month" },
        { tabName: "全年", id: 3, type: "year" },
      ],
    });
    const changeTab = (tabInfo) => {
      active.value = tabInfo.id;
      getApiCallData(tabInfo.type);
    };
    const state = reactive({
      option: {
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          axisLabel: {
            rotate: "45",
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#687080",
              width: 0.5,
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        series: [
          {
            name: "成功数据量",
            data: [150, 230, 224, 218, 135, 147, 260],
            type: "line",
            lineStyle: {
              // 设置线条的style等
              normal: {
                color: "#0CC89B", // 折线线条颜色:红色
              },
            },
          },
          {
            name: "失败数据量",
            data: [20, 400, 100, 300, 50, 40, 300],
            type: "line",
            lineStyle: {
              // 设置线条的style等
              normal: {
                color: "#552117", // 折线线条颜色:红色
              },
            },
          },
        ],
      },
    });
    const initeCharts1 = () => {
      let myChart1 = echarts.init(document.getElementById("myChart2"));
      // 绘制图表
      myChart1.setOption(state.option);
      // 根据页面大小自动响应图表大小
      window.addEventListener("resize", function () {
        myChart1.resize();
      });
    };
    const pageData = reactive({
      list: [],
      total: {},
    });
    const init = (data) => {
      pageData.total = data.total;
      if (data.total.successRate) {
        pageData.total.successRate = Number(
          data.total.successRate.toFixed(2) * 100
        );
      }
      state.option.xAxis.data = [];
      state.option.series[0].data = [];
      state.option.series[1].data = [];
      const list = data.detail;
      list.map((item) => {
        state.option.xAxis.data.push(item.timeDimension);
        state.option.series[0].data.push(item.successInvoke || 0);
        state.option.series[1].data.push(item.failedInvoke || 0);
      });
      initeCharts1();
    };
    const apiCallData = reactive({
      detail: [],
      total: {},
    });
    const getApiCallData = (type) => {
      request.recordByTime({
          appId: props.appId,
          timeDimension: type ? type : "week",
        }).then((res) => {
        if (res.data.code == 200) {
          apiCallData.detail = res.data.data.detail;
          apiCallData.total = res.data.data.total;
          init(apiCallData);
        }
      });
    };
    getApiCallData();
    onMounted(() => {
      //initeCharts1();
    });
    watch(
      () => props.appId,
      (newData) => {
        if (newData) {
          getApiCallData();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return {
      text,
      active,
      tab,
      changeTab,
      state,
      pageData,
      getApiCallData,
      apiCallData,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "./CenterBottom.scss";
</style>