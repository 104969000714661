<template>
  <div style="height: 92%; margin: 20px">
    <div style="text-align: left; width: 100%; margin-bottom: 1%">
      <el-input
        v-model="formData.likeData"
        placeholder="请输入目录名称"
        style="width: 400px" 
        clearable
        class="input-transparent" 
        @keyup.enter="onSearch()"
             >
            <template #suffix>
                <el-icon class="icon-search" @click="onSearch()"><Search /></el-icon>
            </template>
          </el-input>
      
    </div>
    <!--编辑更新抽屉组件-->
    <div>
      <el-drawer v-model="drawer2" :direction="direction" destroy-on-close>
        <div
          style="
            text-align: left;
            margin-left: 0%;
            margin-bottom: 20%;
            margin-top: 1%;
            font-size: 26px;
          "
        >
          <span style="width: 8px; height: 30px; background-color: #409eff"
            >&nbsp;&nbsp;</span
          >
          <span style="margin-left: 2%">{{ labelName }}</span>
        </div>
        <el-form
          label-width="100px"
          :model="addForm"
          style="max-width: 460px"
          :rules="rules"
          ref="ruleFormRef"
        >
          <el-form-item label="目录名称" prop="categoryName">
            <el-input
              v-model="addForm.categoryName"
              maxlength="50"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="目录管理员" prop="categoryManager">
            <el-select
              v-model="addForm.categoryManager"
              filterable
              multiple
              placeholder="请选择责任人"
              style="width: 100%"
            >
              <el-option
                v-for="item in userList.list"
                :key="item.userId"
                :label="item.userName"
                :value="item.userId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="父级节点" prop="categoryParentId">
            <el-select
              v-model="addForm.categoryParentId"
              filterable
              placeholder="请选择父级节点"
              style="width: 100%"
            >
              <el-option
                v-for="item in treeData"
                :key="item.categoryId"
                :label="item.categoryName"
                :value="item.categoryId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input
              :rows="4"
              type="textarea"
              maxlength="1000"
              show-word-limit
              v-model="addForm.description"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <div>
            <el-button
              type="primary"
              @click="confirmClick(ruleFormRef)"
              v-has="'hidden&basicSetup.category.save'"
              >保存</el-button
            >
            <el-button @click="cancelClick">取消</el-button>
          </div>
        </template>
      </el-drawer>
    </div>
    <!--展示table组件-->
    <div  class="white-bg box-card-radius-eight">
      <div style="text-align:left;margin-bottom:20px;">
        <el-button
        type="primary"
        @click="handleAdd()"
        v-has="'hidden&basicSetup.category.save'"
      >
        <el-icon :size="20">
          <CirclePlus />
        </el-icon>
        新建
      </el-button>
      </div>
      <el-table
        :data="tableData.list"
        style="width: 100%"
        row-key="categoryId"
        default-expand-all
      >
        <!-- <el-table-column
          prop="no"
          label="序号"
          width="60"
          align="center"
          fixed
          type="index"
        /> -->
        <el-table-column
          prop="categoryName"
          label="目录名称"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="categoryManager"
          label="目录管理员"
          :show-overflow-tooltip="true"
          :formatter="formatUser"
        />
        <el-table-column
          prop="description"
          label="描述"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="updatedName"
          label="最后更新人"
          :show-overflow-tooltip="true"
        >
         <template #default="scope">{{scope.row.updatedName + ' ' + scope.row.updatedNumber}}</template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="最后更新时间"
          width="180px"
          :formatter="formatDateTime"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="operation"
          label="操作"
          width="250"
          fixed="right"
        >
          <template #default="scope">
            <el-space>
              <el-button
                v-if="scope.row.categoryId != '-1'"
                icon="Edit"
                size="medium"
                type="primary"
                link
                v-has="'hidden&basicSetup.category.save'"
                @click="edit(scope.row)"
              >
                
              </el-button>
              <el-button
                v-has="'hidden&basicSetup.category.delete'"
                v-if="scope.row.categoryId != '-1'"
                icon="delete"
                size="medium"
                type="danger"
                link
                @click="del(scope.row)"
              >
                
              </el-button>
              <el-button
                v-if="scope.row.categoryId != '-1'"
                v-has="'hidden&basicSetup.category.save'"
                size="medium"
                type="primary"
                link
                icon="Rank"
                @click="moveTo(scope.row)"
              >
                
              </el-button>
            </el-space>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog v-model="dialogVisible" title="移动目录" width="30%">
        <el-form
          label-width="100px"
          :model="addForm"
          style="max-width: 460px"
          :rules="rules"
          ref="ruleFormRef"
        >
          <el-form-item label="父级节点" prop="categoryParentId">
            <el-select
              v-model="addForm.categoryParentId"
              filterable
              placeholder="请选择父级节点"
              style="width: 100%"
            >
              <el-option
                v-for="item in treeData"
                :key="item.categoryId"
                :label="item.categoryName"
                :value="item.categoryId"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="saveMove"> 确定 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--分页组件-->
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import {request} from '@/api'
import { ElMessage, ElMessageBox } from "element-plus";
//import router from "@/router";
export default {
  setup() {
    const dialogVisible = ref(false);
    const OperationType = ref("");
    const treeToData = (tree) => {
      return tree.reduce((preResult, item) => {
        const { children, ...data } = item;
        return preResult.concat(
          data,
          children && children.length ? treeToData(children) : []
        );
      }, []);
    };
    const getData = () => {
      //查询列表
      request.getListTree({appId:localStorage.getItem("appId"),filterText:formData.likeData}).then((res) => {
        if (res.data.code == 200) {
          if (formData.likeData) {
            tableData.list = res.data.data;
          } else {
            tableData.list = res.data.data.children;
            treeData.value = treeToData([res.data.data]);
          }
        }
      });
    };
    const formatUser = (val) => {
      // 获取单元格数据
      var arr = [];
      if (val.categoryManager) {
        let data = val.categoryManager.split(",");
        for (var i = 0; i < data.length; i++) {
          for (var j = 0; j < userList.list.length; j++) {
            if (data[i] == userList.list[j].userId) {
              arr.push(userList.list[j].userName);
            }
          }
        }
      }
      return arr.join(",");
    };
    const formatDateTime = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      let y = dt.getFullYear();
      let m = (dt.getMonth() + 1 + "").padStart(2, "0");
      let d = (dt.getDate() + "").padStart(2, "0");
      let hh = (dt.getHours() + "").padStart(2, "0");
      let mm = (dt.getMinutes() + "").padStart(2, "0");
      let ss = (dt.getSeconds() + "").padStart(2, "0");
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
    };
    function cancelClick() {
      addForm.categoryName = null;
      addForm.categoryManager = null;
      addForm.description = null;
      addForm.categoryId = null;
      drawer2.value = false;
    }
    const userList = reactive({
      list: [],
    });
    const treeData = ref([]);
    const getUserList = async () => {
      request.selectUser1().then((res) => {
        let a = res.data.data;
        userList.list = a.result;
        getData();
      });
    };
    const confirmClick = async (formEl) => {
      await formEl.validate((valid) => {
        if (valid) {
          if (OperationType.value == "edit") {
            ElMessageBox.confirm("修改后，是否替换目录责任人？", "Warning", {
              confirmButtonText: "替换",
              cancelButtonText: "不替换",
              type: "提示",
            })
              .then(() => {
                addForm.isUpdateSubManager = true;
                save();
              })
              .catch(() => {
                addForm.isUpdateSubManager = false;
                save();
              });
          } else {
            save();
          }
        }
      });
    };
    const save = () => {
      var queryData = {};
      if (OperationType.value == "add") {
        queryData = {
          categoryName: addForm.categoryName,
          categoryManager: addForm.categoryManager.join(","),
          description: addForm.description,
          categoryId: "",
          categoryParentId: addForm.categoryParentId,
          appId: localStorage.getItem("appId"),
        };
      } else {
        queryData = {
          categoryName: addForm.categoryName,
          categoryManager: addForm.categoryManager.join(","),
          description: addForm.description,
          categoryId: addForm.categoryId,
          categoryParentId: addForm.categoryParentId,
          appId: localStorage.getItem("appId"),
          isUpdateSubManager: addForm.isUpdateSubManager,
        };
      }
      request.validName({
          appId: localStorage.getItem("appId"),
          categoryName: addForm.categoryName,
          categoryId: OperationType.value == "add" ? "" : addForm.categoryId,
        }).then((res) => {
        if (res.data.data == 0) {
          request.saveApicategory(queryData).then((resp) => {
            if (resp.data.code == 200) {
              addForm.categoryManager = null;
              addForm.categoryName = null;
              addForm.categoryParentId = null;
              addForm.description = null;
              drawer2.value = false;
              ElMessage.success(
                OperationType.value == "add" ? "新增成功" : "修改成功"
              );
              getData();
            } else if (resp.data.code == 500) {
              drawer2.value = false;
              ElMessage.error(resp.data.msg);
            } else {
              drawer2.value = false;
              ElMessageBox.alert("系统异常联系系统管理员");
            }
          });
        } else {
          ElMessage.error("目录名称已存在");
        }
      });
    };
    const edit = (rowData) => {
      labelName.value = "编辑目录";
      OperationType.value = "edit";
      addForm.categoryName = rowData.categoryName;
      addForm.categoryManager = rowData.categoryManager.split(",");
      addForm.description = rowData.description;
      addForm.categoryParentId = rowData.categoryParentId;
      addForm.categoryId = rowData.categoryId;
      drawer2.value = true;
    };
    const handleSizeChange = (val) => {
      //size修改
      page.pageSize = val;
      getData();
    };
    const handleCurrentChange = (val) => {
      //num修改
      page.pageNum = val;
      getData();
    };

    const del = (row) => {
      ElMessageBox.confirm(`确定删除目录：` + row.categoryName)
        .then(() => {
          request.deleteApicategory({categoryId:row.categoryId}).then((resp) => {
            let respCode = resp.data.code;
            if (respCode == 200) {
              ElMessage.success("删除成功");
              getData();
            } else {
              ElMessage.error(resp.data.msg);
            }
          });
        })
        .catch(() => {});
    };
    const changeStatus = (row) => {
      let params  = {
            categoryName: row.categoryName,
            categoryManager: row.categoryManager,
            description: row.description,
            lookupHId: row.lookupHId,
            tenantId: localStorage.getItem("tenantId"),
            createdBy: localStorage.getItem("userId"),
            updatedBy: localStorage.getItem("userId"),
            delFlag: "N",
            status: row.status
          }
          
        request.saveLookupHead(params)
        addForm.description = null;
        addForm.categoryName = null;
        addForm.lookupHId = null;
        addForm.categoryManager = null;
        getData();
    };
    const checkDetails = (row) => {
      console.log(row);
    };
    const rules = reactive({
      categoryName: [
        {
          required: true,
          message: "目录名称不能为空",
          trigger: "blur",
          min: 1,
          max: 50,
        },
      ],
      categoryManager: [
        {
          required: true,
          message: "目录管理员不能为空",
          trigger: "blur",
        },
      ],
      categoryParentId: [
        {
          required: true,
          message: "父级节点不能为空",
          trigger: "blur",
        },
      ],
    });

    const handleAdd = () => {
      labelName.value = "新建目录";
      OperationType.value = "add";
      addForm.description = null;
      addForm.categoryName = null;
      addForm.categoryManager = null;
      addForm.description = null;
      drawer2.value = true;
    };
    const onSearch = () => {
      getData("1");
    };
    const total = ref(0); //总条数
    const page = reactive({
      //配置对应的查询参数
      pageNum: 1,
      pageSize: 10,
    });
    const tableData = reactive({
      //表格参数
      list: [],
    });

    const drawer2 = ref(false);
    const direction = ref("rtl");
    const addForm = reactive({
      categoryName: "",
      categoryManager: "",
      description: "",
      categoryId: "",
      categoryParentId: "",
      appId: localStorage.getItem("appId"),
      isUpdateSubManager: "",
    });
    const formData = reactive({
      likeData: "",
    });
    const labelName = ref("");
    const ruleFormRef = ref(null);
    const reset = () => {
      formData.likeData = null;
      page.pageNum = 1;
      page.pageSize = 10;
      getData();
    };
    const moveTo = (rowData) => {
      addForm.categoryName = rowData.categoryName;
      addForm.categoryManager = rowData.categoryManager.split(",");
      addForm.description = rowData.description;
      addForm.categoryParentId = rowData.categoryParentId;
      addForm.categoryId = rowData.categoryId;
      dialogVisible.value = true;
    };
    const saveMove = () => {
      const queryData = {
        categoryName: addForm.categoryName,
        categoryManager: addForm.categoryManager.join(","),
        description: addForm.description,
        categoryId: addForm.categoryId,
        categoryParentId: addForm.categoryParentId,
        appId: localStorage.getItem("appId"),
      };
      request.saveApicategory(queryData).then((resp) => {
        if (resp.data.code == 200) {
          addForm.categoryManager = null;
          addForm.categoryName = null;
          addForm.categoryParentId = null;
          addForm.description = null;
          dialogVisible.value = false;
          ElMessage.success("操作成功");
          getData();
        } else if (resp.data.code == 500) {
          dialogVisible.value = false;
          ElMessage.error(resp.data.msg);
        } else {
          dialogVisible.value = false;
          ElMessageBox.alert("系统异常联系系统管理员");
        }
      });
    };
    getUserList();
    return {
      saveMove,
      save,
      OperationType,
      treeData,
      formData,
      tableData,
      total,
      page,
      del,
      getData,
      drawer2,
      direction,
      cancelClick,
      confirmClick,
      addForm,
      handleSizeChange,
      handleCurrentChange,
      edit,
      checkDetails,
      handleAdd,
      changeStatus,
      formatUser,
      rules,
      onSearch,
      labelName,
      ruleFormRef,
      reset,
      userList,
      getUserList,
      treeToData,
      formatDateTime,
      moveTo,
      dialogVisible,
    };
  },
  name: "BaseConfigBase",
};
</script>
<style scoped></style>
