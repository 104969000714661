<template>
  <div style="border-right:1px solid #d9d9d9;height: 100%;" >
     <div  class="operator-content">
            <div :title="item.operatorCnname" v-for="item in operatorList" :key="item.appId"
            class="img operatorDiv"
            @mousedown="
                dragInNode({
                text: item.nodeCnname,
                nodeCode: item.nodeCode,
                defaultNodeName: item.nodeCnname,
                nodeId: item.nodeId,
                id: item.nodeId,
                icon: item.nodeImg,
                operatorType: item.nodeType,
                })">
            <img :src="item.nodeImg" width="40" height="40" />
            <p>{{item.nodeCnname}}</p>
            </div>
     </div>
  </div>
</template>

<script>
import { ref} from 'vue';
import {request} from '@/api'
export default {
    emits: ['dragInNode', 'getStreamOperators'],
    setup(_,{ emit }) {
        let activePage = ref('1')
        let operatorList= ref([])
         
        const dragInNode = (data)=>{
          emit('dragInNode', data);
        }

        const findNodeList = () => {
            request.findNodeList({}).then((resp) => {
                operatorList.value = resp.data.data || []
                console.log(operatorList.value)
            })
            emit('getStreamOperators', operatorList.value);
        }

        findNodeList()
       return{
        findNodeList,
        dragInNode,
        activePage,
        operatorList
       }
    }
    
}
</script>

<style lang="less" scoped>
.operator-content{
    overflow: hidden;
    .operatorDiv{
        float: left;
        width: 68px;
        margin: 25px 28px;
        p{
            margin: 0;
        }
    }
}

</style>