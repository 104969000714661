<template>
  <div>
     <el-button type="primary" @click="addRow">新增过滤条件</el-button>
            <el-table :data="baseInfo" max-height="285">
                <el-table-column prop="relationship" label="逻辑关系">
                    <template #default="scope">
                        <el-select
                        v-model="scope.row.relationship"
                        clearable
                        placeholder="请选择逻辑关系"
                        >
                        <el-option label="且" value="0" />
                        <el-option label="或" value="1" />
                        </el-select>
                    </template>
                </el-table-column>

                 <el-table-column
                    prop="parameterName"
                    label="字段"
                    :show-overflow-tooltip="true"
                    >
                    <template #default="scope">
                        <el-input
                        v-model="scope.row.parameterName"
                        clearable
                        maxlength="50"
                        placeholder="请输入"
                        ></el-input>
                    </template>
                  </el-table-column>

                 <el-table-column prop="expression" label="表达式">
                    <template #default="scope">
                        <el-select
                        v-model="scope.row.expression"
                        clearable
                        placeholder="请选择表达式"
                        >
                        <el-option v-for="expression in expressionOptionsMap" :label="expression.label" :value="expression.value" :key="expression.value" />
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column prop="source" label="值来源">
                    <template #default="scope">
                        <el-select
                        v-model="scope.row.source"
                        clearable
                        placeholder="请选择参数类型"
                        >
                       <el-option label="API入参" value="api_input_paramter" />
                       <!-- <el-option label="上游结果数据" value="node_result_data"/> -->
                        <el-option label="固定常量" value="api_constant" />
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="parameterValue"
                    label="值"
                    :show-overflow-tooltip="true"
                    >
                    <template #default="scope">
                        <el-input
                            v-if="scope.row.source === 'api_constant'"
                            v-model="scope.row.parameterValue"
                            clearable
                            maxlength="50"
                            placeholder="请输入"
                        ></el-input>
                        <el-select
                            v-else
                            v-model="scope.row.parameterValue"
                            clearable
                            placeholder="请选择"
                            >
                            <el-option v-for="api in apiInputParamters" :key="api.paramName" :label="api.paramName" :value="api.paramName" />
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80" align="center">
                    <template #default="scope">
                        <el-popconfirm
                            title="删除后将无法恢复，请确认是否删除？"
                            confirm-button-type="danger"
                            icon="warning-filled"
                            icon-color="#F56C6C"
                            width="250"
                            placement="top-end"
                            @confirm="deleteRow(scope.$index)"
                        >
                        <template #reference>
                            <el-button
                            icon="delete"
                            type="danger"
                            text
                            ></el-button>
                        </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>

       <div align="left" class="step-title margin-botton-12">
        <strong>参数配置</strong>
      </div>
      <el-table :data="out" max-height="285">
            <el-table-column prop="apiFieldCnname" label="参数中文名称"></el-table-column>
            <el-table-column prop="apiFieldName" label="参数名称" :show-overflow-tooltip="true">
             </el-table-column>
            <el-table-column prop="apiFieldType" label="参数类型"></el-table-column>
            <el-table-column prop="example" label="示例值"></el-table-column>
            <el-table-column prop="fieldDescription" label="描述"></el-table-column>
       </el-table>
     
    
  </div>
</template>

<script>
import { ref,onBeforeUnmount } from 'vue';
import {expressionOptions} from '@/utils/constant'
export default {
    props:['iasmApiArrangeNode','nodeConfigGraphData','operatorData','operatorFlowId','iasmApiArrange'],
    setup(props){
        
        let baseInfo = ref([])
        let out = ref([])
        let parentout = ref([])
        let apiInputParamters = ref([])
        if(props.iasmApiArrangeNode && props.iasmApiArrangeNode.nodeData){
            let nodeData = JSON.parse(props.iasmApiArrangeNode.nodeData)
            baseInfo.value = nodeData.data
            out.value = nodeData.out
            parentout.value = nodeData.parentout
            apiInputParamters.value =  props.iasmApiArrange.apiInputParamters?JSON.parse(props.iasmApiArrange.apiInputParamters):[]

        }
        let expressionOptionsMap = ref(expressionOptions)
        let activeItem = ref('0')
        
        const getNodeData = ()=>{
            getParentout()
            return {
                data:baseInfo.value,
                out:out.value,
                parentout:parentout.value 
            }
        }

        const getParentout = ()=>{
            let parentList = []
            if(props.nodeConfigGraphData && props.nodeConfigGraphData.edges && props.nodeConfigGraphData.edges.length){
                props.nodeConfigGraphData.edges.forEach(item=>{
                    if(item.targetNodeId === props.operatorFlowId){
                        if(props.operatorData[item.sourceNodeId].iasmApiArrangeNode.nodeData){
                            let nodeData = props.operatorData[item.sourceNodeId].iasmApiArrangeNode.nodeData
                            parentList.push(JSON.parse(nodeData).out)
                        }
                    }
                })
            }
            parentout.value = parentList
        }

        const addRow = () => {
        baseInfo.value.push({
                 relationship:null,
                 parameterName:null,
                 expression:null,
                 source:'api_constant',
                 parameterValue:null,
                });
        };

        const validate = ()=>{
          return true
        }

        const reset = ()=>{
            baseInfo.value = []
            out.value = []
        }

        const deleteRow = (index)=>{
          baseInfo.value.splice(index, 1);
        }

        onBeforeUnmount(() => {
         reset()
        });

        return{
            validate,
            baseInfo,
            out,
            expressionOptionsMap,
            activeItem,
            addRow,
            deleteRow,
            getNodeData,
            parentout,
            getParentout,
            reset,
            apiInputParamters
        }
    }

}
</script>

<style lang="less" scoped>
/deep/.el-collapse-item__header{
    padding-left:20px;
    border-bottom:1px solid #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.85);
    font-size:14px;
}
.step-title{
    margin-top: 50px;
}
</style>