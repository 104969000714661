import { JSEncrypt } from 'jsencrypt';

export const dataEncryption = (data) => {
    if (data == null || data == "") {
        return data;
    }
    // 新建一个JSEncrypt对象
    let encryptor = new JSEncrypt()
    // 设置公钥
    let _pubKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqoL5xI/6PbftDpMfBfQHCE/Oj0ZXiuPdAAfJ2EvkNwiZCxAx9DTIoypbX5j2tuSNAkizNVpCq5HOcTKTcLsORv9gcjFbmgvZBgoWu7vv52zMJ/CRB/cFYRH7gL7RpuREtoIAOT/Aeq5X2+sBPgnWP+7MU7AzmqEVD4KgwnsfwHhuMy9NA9hNRHz6ZirURLSyBXqFl//2RxtC1bJZo7tOC2FlD9qWpAo9dIM7S5NjZapq9r4vRF9zeR9r/MR7RW373UA0md928eX/93f1rSSJgBZOTZRg5T8MWKt/eX0JgWUwozzE1/27+3Ru03wKmtIWJ9HitQ2CMAf8/K14sfxQlQIDAQAB';
    encryptor.setPublicKey(_pubKey) // publicKey为公钥
    // 加密数据
    return encryptor.encrypt(data);
}

export const decrypt = (data) => {
    if (data === null || data === '') {
        return data
    }
    let decryptor = new JSEncrypt()
    // 设置私钥
    const privateKey = 'MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCqgvnEj/o9t+0Okx8F9AcIT86PRleK490AB8nYS+Q3CJkLEDH0NMijKltfmPa25I0CSLM1WkKrkc5xMpNwuw5G/2ByMVuaC9kGCha7u+/nbMwn8JEH9wVhEfuAvtGm5ES2ggA5P8B6rlfb6wE+CdY/7sxTsDOaoRUPgqDCex/AeG4zL00D2E1EfPpmKtREtLIFeoWX//ZHG0LVslmju04LYWUP2pakCj10gztLk2Nlqmr2vi9EX3N5H2v8xHtFbfvdQDSZ33bx5f/3d/WtJImAFk5NlGDlPwxYq395fQmBZTCjPMTX/bv7dG7TfAqa0hYn0eK1DYIwB/z8rXix/FCVAgMBAAECggEADmXs7VozCU8kp1xo/Dm09lC24oZQEhxXpw8BTlBqPvNs6PA/J53la1HCUXo7Kwp1lcHJ0DGE+7PeONTKenRxKjGaVUoxr/pcIxRrAwNFqMcZHi5EMOX9AEtib2atkWgpHTgxCKSHflSGer2HZ1OxUci6XD6+vMnpW1vucz2uWrQtAZLSbdb/Uuw8bT2DZ5KLQf5nCuJwgStuqBb0xKkU0JE4F+oyBtMoVkWrQP0ml9DCb9vgCjwhOaz50DgTdkum1N3cPcygOIaKjPSAWhXkN15Le5BZhGGTXcEXj4Yd5p62uOBiIzJivhUgwXnZ+JmGdDsCvrqkjSJFkm8yeBNGgQKBgQDxnR5KM80OPPUa/5kc9QPwH02F2FyDlTbZXFDI70Qm03LKj1LgEB70Ii35GNVsuz/gk30Q4XCKFx93+U7IS9Xt4H9C3WAvWs/w1+p+wvEQjgpxcGP63OwUsdQBZrNTz7f+FNikjA77tTRrKIBqIZO2W9hzwaSLN+7GaFlPFMc0sQKBgQC0qg75txcgmCiEBCG8/0s5x2H25lXaVY+sM9MjjN/RAc6Pt6h3tdGa23IlfKVfcerCnFL6wvXIdH6K8v8aw5qX0Q1/rRlSo6Is8Aqsu9Z6FwfApvsOwNlRDllrueRhvskrLHR0gh/hpvA5ZLuhGbHhtGgiLA9Y3VKK1N8VkjWjJQKBgAz0eb1Mzb/nsNTDw7fzw0RKyWNV2JANniVFd+duPN9AEzWdPbpZ4u8ALirfFAKCf8hQ4DHZhKlC2CMcsQa4Wj4o/WneaNvhKKknSqGFXlue4hx3BEVdNyNc7ZstoIvr5VewUnVwNRV1/9uHDmuiZUMv5Yk/Zs04MIte6Pr+iDfhAoGAX1CZNgXyna+pMH09j4jhF0qp1KkRAm/BrQhqjIb2Tj61y/0JVJ8IRrsgLcblOMXRJXt0C/hv06IiYQxyqpwoI5CXnpSkl4TdOvgpqZgwP5mG7Y0rR01XWfxIER2x8B/H4o9BoXZFe7enZaBPnV8BCiM0DVNRhYC+26ATUj4hLkECgYBKu6/+bYE9fuPMaWqwTmk0fAjuIsQ5kEQWxd4PCtfS4s9F633VretX6OmOtL/7Kv3rUAQT7TBCrni8YzBcLvafHVLn2rvHpIpozYptJKBXRWngmCyh6lVawkPhe517XQF1BUcdef6TG7MQarh8vBXJtxQR2z4VlZMPgESQSjRxrw=='
    decryptor.setPrivateKey(privateKey)
    // 解密
    return decryptor.decrypt(data)
}