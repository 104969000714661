<template>
  <!--  <P ALIGN="LEFT"><FONT SIZE="5">租户管理 </FONT></P>-->
  <div style="height: calc(100% - 28px);width: 100%;padding-left: 0px;padding-top: 14px;margin-left: 0px;margin-top: 0px">
    <div style="text-align: left;width:100%;margin-bottom: 1%">
      <el-input v-model="formData.likeData" placeholder="模糊匹配 租户编码 租户名称" style="width:400px"/>
          &nbsp;&nbsp;
          <el-button type="primary" @click="onSearch()">查询</el-button>
          <el-button @click="reset()">重置</el-button>
            <el-button type="primary" @click="addTenantClick()" style="float: right;" v-has="'hidden&basicSetup.tenant.edit'">
              <el-icon :size="20">
                <CirclePlus />
              </el-icon>
              新建
            </el-button>
    </div>
    <el-drawer v-model="drawer2" prop="drawer2" :direction="direction" destroy-on-close>
      <div style="text-align:left;margin-left: 0%;margin-bottom: 20%;margin-top: 1%;font-size:26px">
        <span style="width: 8px; height: 30px; background-color: #409eff;">&nbsp;&nbsp;</span>
        <span style="margin-left: 2%">{{ labelName }}</span>
      </div>
      <el-form label-width="100px" :model="updatevalue" style="max-width: 460px" :rules="rules" ref="ruleFormRef">
        <el-form-item label="租户编码" prop="tenantCode">
          <el-input v-model="updatevalue.tenantCode" maxlength="20" show-word-limit />
        </el-form-item>
        <el-form-item label="租户名称" prop="tenantName">
          <el-input v-model="updatevalue.tenantName" maxlength="20" prshow-word-limit />
        </el-form-item>
        <el-form-item label="租户Owner" v-if="isEdit">
          <el-select v-model="updatevalue.tenantOwner" filterable placeholder="请选择用户" @click="remoteMethod">
            <el-option v-for="item in options.list" :key="item.userName" :label="item.userName" :value="item.userId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有效截止日期">
          <el-date-picker v-model="updatevalue.invalidTime" type="date" placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="租户状态" required>
          <el-radio-group v-model="updatevalue.status">
            <el-radio label="1">启用</el-radio>
            <el-radio label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input :rows="4" type="textarea" maxlength="1000" show-word-limit v-model="updatevalue.description" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div style="flex: auto">
          <el-button type="primary" @click="confirmTenantClick(ruleFormRef)">保存</el-button>
          <el-button @click="cancelTenantClick">取消</el-button>
        </div>
      </template>
    </el-drawer>
    <div>
      <el-table :data="tableData.list" stripe style="width: 100%">
        <el-table-column prop="no" label="序号" width="60%" align="center" fixed type="index" />
        <el-table-column prop="tenantCode" label="租户编码" :show-overflow-tooltip="true" fixed />
        <el-table-column prop="tenantName" label="租户名称" :show-overflow-tooltip="true" fixed />
        <el-table-column prop="tenantOwnerName" label="租户Owner" :show-overflow-tooltip="true" />
        <el-table-column prop="updatedName" label="最后更新人" :show-overflow-tooltip="true" />
        <el-table-column prop="updateTime" label="最后更新时间" width="180" :formatter="formatDateTime"
          :show-overflow-tooltip="true" />
        <el-table-column prop="invalidTime" label="租户有效截止时间" width="180" :formatter="formatDate"
          :show-overflow-tooltip="true" />
        <el-table-column prop="status" label="租户状态" width="120" align="center">
          <template #default="scope">
            <span v-if="scope.row.status === '1'">
              <el-icon color="#13ce66" size="150%">
                <CircleCheck />
              </el-icon>
            </span>
            <span v-else><el-icon size="150%">
                <CircleClose />
              </el-icon></span>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="180" fixed="right">
          <template #default="scope">
            <el-button size="small" type="primary" @click="editTenantClick(scope.row)" icon="Edit"
              v-has="'hidden&basicSetup.tenant.edit'">编辑</el-button>
            <el-divider direction="vertical" v-has="'hidden&basicSetup.tenant.edit'" />
            <el-button size="small" type="danger" @click="deleteCurrent(scope.row)" icon="delete"
              v-has="'hidden&basicSetup.tenant.delete'">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 10px 0">
         <el-pagination background small :current-page="page.pageNum" :page-size="page.pageSize" :page-sizes="[2, 5, 10, 20]"
          layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, ref } from "vue";
import {request} from '@/api'
export default {
  name: "BaseConfigTenant",
  setup() {
    // watch(
    //     drawer2,
    //     (newValue,oldValue)=>{
    //       console.log(newValue);
    //       console.log(oldValue);
    //       if(newValue===false){
    //         ruleFormRef.value.resetFields();
    //       }
    //     }
    // )
    // const tenantId = ref(localStorage.getItem("tenantId"));  //租户ID
    //const loginId = ref(localStorage.getItem("userId"));  //登录用户ID
    const getData = () => {
      request.selectTenant({  
        //注意是params，get请求必须写上params，不能写data
          likeData: formData.likeData,
          currentPage: page.pageNum,
          pageSize: page.pageSize
          // tenantId:tenantId.value
          }).then((resp) => {
        tableData.list = resp.data.data.result;
        total.value = resp.data.data.pageVO.total
      })
    }

    const onSearch = () => {
      getData()
    }

    const formatDateTime = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      let y = dt.getFullYear();
      let m = (dt.getMonth() + 1 + '').padStart(2, '0');
      let d = (dt.getDate() + '').padStart(2, '0');
      let hh = (dt.getHours() + '').padStart(2, '0');
      let mm = (dt.getMinutes() + '').padStart(2, '0');
      let ss = (dt.getSeconds() + '').padStart(2, '0');
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
    }

    const formatDate = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      let y = dt.getFullYear();
      let m = (dt.getMonth() + 1 + '').padStart(2, '0');
      let d = (dt.getDate() + '').padStart(2, '0');
      return `${y}-${m}-${d}`;
    }

    const remoteMethod = () => {
      request.selectUserByParams({tenantId: updatevalue.tenantId,appId:localStorage.getItem('appId')}).then((resp) => {
        options.list = resp.data.data.result;
      });
    }
    const addTenantClick = () => {
      isEdit.value = false
      drawer2.value = true;
      updatevalue.tenantId = null
      updatevalue.status = '1'
      updatevalue.tenantCode = null
      updatevalue.tenantName = null
      updatevalue.tenantOwner = null
      updatevalue.description = null
      updatevalue.invalidTime = null
      updatevalue.tenantOwnerName = null
      labelName.value = "新建租户"
    }

    const editTenantClick = (rowData) => {
      drawer2.value = true;
      labelName.value = "编辑租户"
      updatevalue.status = rowData.status
      updatevalue.tenantCode = rowData.tenantCode
      updatevalue.tenantName = rowData.tenantName
      updatevalue.tenantOwner = rowData.tenantOwner
      updatevalue.description = rowData.description
      updatevalue.invalidTime = rowData.invalidTime
      updatevalue.tenantId = rowData.tenantId
      updatevalue.tenantOwnerName = rowData.tenantOwnerName
      isEdit.value = true
      remoteMethod();
    }
    const cancelTenantClick = () => {
      updatevalue.status = null
      updatevalue.tenantCode = null
      updatevalue.tenantName = null
      updatevalue.tenantOwner = null
      updatevalue.description = null
      updatevalue.invalidTime = null
      updatevalue.tenantOwnerName = null
      drawer2.value = false;
    }

    const confirmTenantClick = async (formEl) => {
      await formEl.validate((valid) => {
        if (valid) {
          request.updateTenant({
              tenantId: updatevalue.tenantId,
              tenantCode: updatevalue.tenantCode,
              tenantName: updatevalue.tenantName,
              tenantOwner: updatevalue.tenantOwner,
              invalidTime: updatevalue.invalidTime,
              description: updatevalue.description,
              status: updatevalue.status,
              delFlag: 'N'
            }).then((resp) => {
            drawer2.value = false
            if (resp.data.code == 200) {
              if (updatevalue.tenantId === null || updatevalue.tenantId === '') {
                ElMessageBox.alert("开租成功. 租户管理员账号：[" + resp.data.data.tenantOwnerName
                  + "] 密码：[" + resp.data.data.initPassword + "] 请妥善保存.")
              } else {
                ElMessage.success("保存成功");
              }
            } else {
              ElMessage.error(resp.data.msg)
            }
          }).then(() => {
            getData()
          })
        }
      })
    }
    const deleteCurrent = (row) => {
      ElMessageBox.confirm(`确定删除当前租户：` + row.tenantName)
        .then(() => {
          request.delTenant({tenantId: row.tenantId}).then((resp) => {
            if (resp?.data?.code === 200) {
              ElMessage.success("删除成功");
            } else {
              ElMessage.error(resp?.data?.data || '删除失败');
            }
          }).then(() => {
            getData()
          })
        }).catch(() => { })
    }

    const handleSizeChange = (val) => { //size修改
      page.pageSize = val
      getData();
    }
    const handleCurrentChange = (val) => { //num修改
      page.pageNum = val
      getData();
    }

    const rules = reactive({
      tenantCode: [{ required: true, message: "租户编码不能为空", trigger: 'blur', min: 1, max: 50 },],
      tenantName: [{ required: true, message: "租户名称不能为空", trigger: 'blur', min: 1, max: 100 },],
      status: [{ required: true },]
    })

    const tableData = reactive({ //表格参数
      list: []
    })

    const drawer2 = ref(false)
    const searchData = ref('')
    const labelName = ref('');
    const updatevalue = reactive({
      tenantCode: '',
      tenantName: '',
      tenantOwner: '',
      invalidTime: '',
      status: '1',
      description: '',
      tenantId: '',
      delFlag: '',
      tenantOwnerName: ''
    })
    const total = ref(0);
    const page = reactive({  //配置对应的查询参数
      pageNum: 1,
      pageSize: 10,
    });
    const formData = reactive({
      likeData: '',
    });
    const direction = ref('rtl')
    const isEdit = ref()
    const options = reactive(
      {
        list: []
      }
    )
    const ruleFormRef = ref(null);
    const reset = () => {
      formData.likeData = null;
      page.pageNum = 1;
      page.pageSize = 10;
      getData();
    };
    return {
      getData,
      onSearch,
      formatDateTime,
      formatDate,
      remoteMethod,
      addTenantClick,
      editTenantClick,
      cancelTenantClick,
      confirmTenantClick,
      deleteCurrent,
      handleSizeChange,
      handleCurrentChange,
      rules,
      tableData,
      searchData,
      drawer2,
      labelName,
      updatevalue,
      page,
      total,
      formData,
      isEdit,
      direction,
      options,
      ruleFormRef,
      reset
    }
  },
  beforeCreate() {
    this.getData()
  },

}

</script>

<style scoped>
.page-position-common {
  float: right;
  margin-right: 15px !important;
  margin-left: 5px;
}

.new-button {
  float: left;
  margin-bottom: 15px;
}

.displayOwnerd {
  display: none;
}</style>