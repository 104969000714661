<template>
  <div class="not-found-page">
   <div class="not-found-box">
     <h3>
        抱歉，当前角色暂无权限，请联系管理员或切换角色。
    </h3>
   </div>
  </div>
</template>
<script>
export default {
  name: 'noPermission',
  setup() {
  }
}
</script>

<style lang="less" scoped>
.not-found-page{
  width:100%;
  height:100%;
  background: url('@/assets/image/homebg.png');
  .not-found-box{
    position:fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align:center;
  }
}
</style>
