<template>
  <div
    style="
      text-align: left;
      height: calc(100% - 28px);
      width: 100%;
      padding-left: 0px;
      padding-top: 14px;
      margin-left: 0px;
      margin-top: 0px;
    "
  >
    <div style="text-align: left; width: 100%; margin-bottom: 1%">
      <el-input
        v-model="formData.likeData"
        placeholder="模糊匹配 用户名称 用户账号 描述"
        style="width: 400px"
        clearable
        class="input-transparent"
        @keyup.enter="onSearch()"
      >
        <template #suffix>
          <el-icon class="icon-search" @click="onSearch()"><Search /></el-icon>
        </template>
      </el-input>
    </div>

    <div class="white-bg box-card-radius-eight">
      <div style="text-align: left; margin-bottom: 20px">
        <el-button
          type="primary"
          @click="addUserClick()"
          v-has="'hidden&basicSetup.user.add'"
        >
          <el-icon :size="20">
            <CirclePlus /> </el-icon
          >新建
        </el-button>
      </div>
      <el-table :data="tableData.list" stripe style="width: 100%">
        <el-table-column
          prop="no"
          label="序号"
          width="60"
          align="center"
          type="index"
        />
        <el-table-column
          prop="userName"
          label="用户名称"
          width="150"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="account"
          label="用户账号"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="phoneNumber"
          label="电话"
          width="150"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="email"
          label="邮箱"
          :show-overflow-tooltip="true"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="appNames"
          label="应用"
          width="150"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="roleNames"
          label="角色"
          width="200"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="effectiveStartTime"
          label="账号有效开始日期"
          width="150"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="effectiveEndTime"
          label="账号有效截止日期"
          width="150"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="updatedByName"
          label="最后更新人"
          width="120"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="updateTime"
          label="最后更新时间"
          width="180"
          :formatter="iasmjs.formatDate"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="status"
          align="center"
          label="用户状态"
          width="120"
        >
          <template #default="scope">
            <span v-if="scope.row.status === '1'">
              <el-icon color="#0E68E7" size="150%">
                <CircleCheck />
              </el-icon>
            </span>
            <span v-else
              ><el-icon size="150%">
                <CircleClose /> </el-icon
            ></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="operation"
          label="操作"
          width="180"
          fixed="right"
        >
          <template #default="scope">
            <el-tooltip
              v-has="'hidden&basicSetup.user.edit'"
              content="分配角色"
            >
              <el-button
                size="medium"
                type="primary"
                link
                @click="setRoles(scope.row)"
                icon="tools"
              ></el-button>
            </el-tooltip>
            <el-divider
              direction="vertical"
              v-has="'hidden&basicSetup.user.edit'"
            />
            <el-tooltip v-has="'hidden&basicSetup.user.edit'" content="编辑">
              <el-button
                size="medium"
                icon="Edit"
                type="primary"
                link
                @click="handleUserEdit(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-divider
              direction="vertical"
              v-has="'hidden&basicSetup.user.delete'"
            />
            <el-tooltip v-has="'hidden&basicSetup.user.delete'" content="删除">
              <el-button
                size="medium"
                icon="delete"
                type="danger"
                link
                @click="handleUserDelete(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-divider
              direction="vertical"
              v-has="'hidden&basicSetup.user.delete'"
            />
            <el-tooltip content="移出当前应用">
              <el-button @click="handleRemoveUser(scope.row)" size="medium" icon="Remove" type="danger" link  v-has="'hidden&basicSetup.user.delete'"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页组件-->
      <div style="padding: 10px 0">
        <el-pagination
          background
          small
          style="float: right"
          :current-page="page.pageNum"
          :page-size="page.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <el-drawer v-model="drawer2" destroy-on-close>
      <template #default>
        <div
          style="
            text-align: left;
            margin-left: 0%;
            margin-bottom: 20%;
            margin-top: 1%;
            font-size: 26px;
          "
        >
          <span style="width: 8px; height: 30px; background-color: #409eff"
            >&nbsp;&nbsp;</span
          >
          <span style="margin-left: 2%">{{ labelName }}</span>
        </div>
        <div>
          <el-form
            label-width="150px"
            ref="ruleFormRef"
            :rules="validateForm"
            :model="updateData"
            style="max-width: 460px"
          >
            <el-form-item prop="userName" label="用户名称">
              <el-input
                v-model="updateData.userName"
                autocomplete="off"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
            <el-form-item prop="account" label="用户账号">
              <el-input
                v-model="updateData.account"
                autocomplete="off"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
            <div v-if="add">
              <el-form-item prop="password" label="用户密码">
                <el-input
                  type="password"
                  name="oldPwd"
                  autocomplete="new-password"
                  style="width: 0; height: 0; float: left; display: none"
                /><!-- 添加隐藏密码输入框，避免浏览器自动填充 -->
                <el-input
                  v-model="updateData.password"
                  autocomplete="new-password"
                  type="password"
                  maxlength="20"
                  show-password
                  show-word-limit
                />
              </el-form-item>
            </div>
            <div v-else>
              <el-form-item v-if="seen" label="用户密码">
                <el-input
                  type="password"
                  name="oldPwd"
                  autocomplete="new-password"
                  style="width: 0; height: 0; float: left; display: none"
                /><!-- 添加隐藏密码输入框，避免浏览器自动填充 -->
                <el-input
                  v-model="updateData.password"
                  autocomplete="new-password"
                  type="password"
                  maxlength="20"
                  show-password
                  show-word-limit
                />
              </el-form-item>
            </div>
            <el-form-item prop="effectiveStartTime" label="账号有效开始日期">
             <el-date-picker v-model="updateData.effectiveStartTime"  type="datetime" value-format="YYYY-MM-DD HH:mm:ss" placeholder="选择日期时间" style="width:100%;">
             </el-date-picker>
            </el-form-item>
            <el-form-item prop="effectiveEndTime" label="账号有效截止日期">
             <el-date-picker v-model="updateData.effectiveEndTime"  type="datetime" value-format="YYYY-MM-DD HH:mm:ss" placeholder="选择日期时间"  style="width:100%;">
             </el-date-picker>
            </el-form-item>
            <el-form-item label="电话" prop="phoneNumber">
              <el-input
                v-model="updateData.phoneNumber"
                autocomplete="off"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input
                v-model="updateData.email"
                autocomplete="off"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
            <!-- <el-form-item prop="tenantId" label="租户">
              <el-select v-model="updateData.tenantId" filterable placeholder="请选择租户" @click="remoteMethod">
                <el-option v-for="item in tenantOptions.list" :key="item.tenantId" :label="item.tenantName"
                  :value="item.tenantId">
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item prop="appList" label="应用">
              <el-select
                v-model="updateData.appList"
                filterable
                remote
                reserve-keyword
                multiple
                collapse-tags
                collapse-tags-tooltip
                remote-show-suffix
                style="width: 100%"
              >
                <el-option
                  v-for="item in appOptions.list"
                  :key="item.appId"
                  :label="item.appName"
                  :value="item.appId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item prop="roleList" label="角色">
              <el-select
                v-model="updateData.roleList"
                filterable
                remote
                reserve-keyword
                multiple
                collapse-tags
                collapse-tags-tooltip
                remote-show-suffix
                style="width: 100%"
              >
                <el-option
                  v-for="item in roleOptions.list"
                  :key="item.roleId"
                  :label="item.roleName"
                  :value="item.roleId"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item prop="status" label="用户状态">
              <el-radio v-model="updateData.status" label="1">启用</el-radio>
              <el-radio v-model="updateData.status" label="2">禁用</el-radio>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                :rows="4"
                type="textarea"
                maxlength="1000"
                show-word-limit
                v-model="updateData.description"
              />
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template #footer>
        <div style="flex: auto">
          <el-button type="primary" @click="confirmUserInfo(ruleFormRef)"
            >保存</el-button
          >
          <el-button @click="cancelUserClick">取消</el-button>
        </div>
      </template>
    </el-drawer>

    <el-dialog
        v-model="dialogVisible"
        title="分配角色"
        width="30%"
      >
      <div>
        <span><span style="color:red;">*</span>角色： </span>
        <el-select
          v-model="roles"
          multiple
          collapse-tags
          collapse-tags-tooltip
          placeholder="请选择"
          clearable
          filterable
          style="width:80%;"
        >
          <el-option
            v-for="item in roleList"
            :key="item.roleId"
            :label="item.roleName"
            :value="item.roleId"
          />
        </el-select>
      </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="saveRoles">
              确定
            </el-button>
          </span>
        </template>
      </el-dialog>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import { request } from "@/api";
import { ElMessage, ElMessageBox } from "element-plus";
import iasmjs from "@/assets/js/iasm.js";
import { dataEncryption } from "@/assets/js/dataEncryption.js";

export default {
  name: "BaseConfigUser",
  setup() {
    let roleList = ref('')
    let roles = ref([])
    let dialogVisible = ref(false)
    const formData = reactive({
      likeData: "",
    });
    const tableData = reactive({
      //表格参数
      list: [],
    });
    const tenantOptions = reactive({
      //表格参数
      list: [],
    });
    const roleOptions = reactive({
      //表格参数
      list: [],
    });
    const appOptions = reactive({
      //表格参数
      list: [],
    });
    let setedUser = ref()
    const tenantId = ref(localStorage.getItem("tenantId")); //租户ID
    const loginId = ref(localStorage.getItem("userId")); //登录用户ID
    const total = ref(0); //总条数
    const seen = ref(true); //密码是否展示
    const labelName = ref("");
    const page = reactive({
      //配置对应的查询参数
      pageNum: 1,
      pageSize: 10,
    });
    const updateData = reactive({
      tenantId: "",
      userId: "",
      userName: "",
      account: "",
      password: "",
      // roleList: [],
      appList: [],
      status: "",
      description: "",
      createdBy: "",
      updatedBy: "",
      delFlag: "",
      email: "",
      phoneNumber: "",
      effectiveStartTime:"",
      effectiveEndTime:""
    });

    const drawer2 = ref(false);
    const onSearch = () => {
      //查询
      getUsersInfo();
    };
    const getUsersInfo = () => {
      request
        .selectUserByParams({
          tenantId: tenantId.value,
          appId: localStorage.getItem("appId"),
          likeData: formData.likeData,
          currentPage: page.pageNum,
          pageSize: page.pageSize,
        })
        .then((resp) => {
          tableData.list = resp.data.data.result;
          total.value = resp.data.data.pageVO.total;
          //this.loading = false;//关闭加载状态
        });
    };
    const handleUpdateUserStatus = (row, status) => {
      request
        .updateUserInfo({
          tenantId: row.tenantId,
          userId: row.userId,
          status: status,
        })
        .then((resp) => {
          resp.data = 1;
          location.reload();
        });
    };
    const remoteMethod = () => {
      request
        .selectRole({
          tenantId: tenantId.value,
          status: 1,
          currentPage: 1,
          pageSize: 100,
        })
        .then((resp) => {
          roleOptions.list = resp.data.data.result; //将请求到的数据赋值给用户数组
        });

      request.selectApp({ tenantId: tenantId.value }).then((resp) => {
        appOptions.list = resp.data.data.result; //将请求到的数据赋值给用户数组
      });
    };
    const add = ref(true);
    const addUserClick = () => {
      seen.value = true;
      labelName.value = "新建用户";
      updateData.status = "1";
      updateData.tenantId = tenantId.value;
      updateData.createdBy = loginId.value;
      updateData.updatedBy = loginId.value;
      updateData.userId = "";
      updateData.userName = "";
      updateData.account = "";
      updateData.password = "";
      updateData.description = "";
      updateData.delFlag = "";
      // updateData.roleList = "";
      updateData.appList = "";
      updateData.effectiveStartTime = "";
      updateData.effectiveEndTime = "";
      drawer2.value = true;
    };

    const setRoles = (row) => {
      request.assignRolesByUserId({userId: localStorage.getItem("userId")}).then((resp) => {
        roleList.value = resp.data.data;
         console.log(row,'>>(row.appList')
         if(row.roleList && row.roleList.length > 0){
          roles.value = row.roleList.split(",").map(item=>{
          return item * 1
        })
         }
        setedUser.value = row.userId
        dialogVisible.value = true
      });
    };

    const saveRoles = ()=> {
      request.updateUserRolelist({userId: setedUser.value,roleList:roles.value.join(",")}).then((resp) => {
        let respCode = resp.data.code
        if (respCode == "200") {
          ElMessage.success('设置成功');
          dialogVisible.value = false
          reloadTable()
        }
      });
    }

    const handleUserEdit = (row) => {
      add.value = false;
      if (ruleFormRef.value != null) {
        ruleFormRef.value.resetFields();
      }
      let roleName = localStorage.getItem("currentRoleName");
      if (roleName === "系统管理员") {
        seen.value = true;
      } else {
        seen.value = false;
      }
      labelName.value = "编辑用户";
      updateData.status = row.status;
      updateData.tenantId = row.tenantId;
      updateData.createdBy = row.createdBy;
      updateData.updatedBy = loginId.value;
      updateData.userId = row.userId;
      updateData.userName = row.userName;
      updateData.account = row.account;
      updateData.password = "";
      updateData.description = row.description;
      updateData.delFlag = row.delFlag;
      updateData.email = row.email;
      updateData.phoneNumber = row.phoneNumber;
      updateData.effectiveStartTime = row.effectiveStartTime;
      updateData.effectiveEndTime = row.effectiveEndTime;
      
     
      let appList = [];
      if (row.appList.length > 0) {
        appList = row.appList.split(",");
      }
      let j = 0;
      appOptions.list.forEach((item) => {
        appList.forEach((appIdStr) => {
          if (item.appId == appIdStr) {
            updateData.appList[j] = item.appId;
            j++;
          }
        });
      });
      drawer2.value = true;
    };
    const ruleFormRef = ref(null);
    const validateEmail = (rule, value, callback) => {
      var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
      if (emailReg.test(value)) {
        callback();
      } else {
        callback(new Error("邮箱格式不正确"));
      }
    };
    // const validatePhone=(rule, value, callback)=>{
    //   var reg = /^1[0-9]{10}$/;
    //   if (reg.test(value)) {
    //     callback();
    //   } else {
    //     callback(
    //       new Error(
    //         "请输入正确的电话号码"
    //       )
    //     );
    //   }
    // }
    const validateForm = reactive({
      userName: [
        { required: true, message: "请输入用户名称", trigger: "blur" },
      ],
      account: [{ required: true, message: "请输入账号名称", trigger: "blur" }],
      password: [{ required: true, message: "请输密码", trigger: "blur" }],
      // roleList: [{ required: true, message: "请选择角色", trigger: "blur" }],
      appList: [{ required: true, message: "请选择应用", trigger: "blur" }],
      status: [{ required: true, message: "请选择状态", trigger: "blur" }],
      email: [
        { required: true, message: "请输入邮箱", trigger: "blur" },
        { validator: validateEmail, trigger: "blur" },
      ],
      // phoneNumber: [{ required: true, message: '请输入电话号码', trigger: 'blur' },{ validator: validatePhone, trigger: "blur" }],
    });
    const confirmUserInfo = async (formEl) => {
      await formEl.validate(async (valid) => {
        if (valid) {
          // 获取选中角色的id
          let appList = updateData.appList.join(",");
          let password = "";
          // 加密数据
          password = dataEncryption(updateData.password);
          let params = {
            ...updateData,
            password: password,
            // roleList: roleList,
            appList: appList,
            createdBy: loginId.value,
            updatedBy: loginId.value,
          };
          let resp;
          if (updateData.userId == "") {
            resp = await request.saveUser(params);
          } else {
            resp = await request.updateUserInfo(params);
          }

          let respCode = resp.data.code;
          if (respCode == "200") {
            ElMessage.success(resp.data.msg);
            cancelUserClick();
            getUsersInfo();
          } else {
            ElMessage.error(resp.data.msg);
          }
        }
      });
    };
    const cancelUserClick = () => {
      drawer2.value = false;
      ruleFormRef.value.resetFields();
    };

    const handleRemoveUser = (row) => {
      ElMessageBox.confirm("确定将" + row.account + " 移出当前应用？")
        .then(() => {
          request
            .removeUserAppId({
              appId: localStorage.getItem("appId"),
              userId: row.userId,
            })
            .then((resp) => {
              if (resp.data.code == 200) {
                ElMessageBox.alert("移出成功");
                getUsersInfo();
              } else {
                ElMessageBox.alert(resp.data.msg);
              }
            });
        })
        .catch(() => {});
    };

    const handleUserDelete = (row) => {
      let accountStr = row.account;
      ElMessageBox.confirm("确定删除 " + accountStr + " 账号吗？")
        .then(() => {
          request
            .deleteUserInfo({
              tenantId: row.tenantId,
              userId: row.userId,
            })
            .then((resp) => {
              if (resp.data.code == 200) {
                ElMessageBox.alert("删除成功");
                getUsersInfo();
              } else {
                ElMessageBox.alert(resp.data.msg);
              }
            });
        })
        .catch(() => {});
    };
    const handleSizeChange = (val) => {
      //size修改
      page.pageSize = val;
      getUsersInfo();
    };
    const handleCurrentChange = (val) => {
      //num修改
      page.pageNum = val;
      getUsersInfo();
    };

    const reloadTable = ()=>{
      page.pageNum = 1;
      getUsersInfo();
    }

    const reset = () => {
      formData.likeData = null;
      page.pageNum = 1;
      page.pageSize = 10;
      getUsersInfo();
    };
    return {
      setedUser,
      reloadTable,
      tableData,
      roleOptions,
      tenantOptions,
      total,
      page,
      updateData,
      roleList,
      drawer2,
      tenantId,
      loginId,
      seen,
      labelName,
      formData,
      ruleFormRef,
      validateForm,
      onSearch,
      remoteMethod,
      getUsersInfo,
      handleUpdateUserStatus,
      handleUserEdit,
      saveRoles,
      setRoles,
      addUserClick,
      confirmUserInfo,
      cancelUserClick,
      handleUserDelete,
      handleRemoveUser,
      handleSizeChange,
      handleCurrentChange,
      iasmjs,
      appOptions,
      add,
      reset,
      dialogVisible,
      roles,
    };
  },

  beforeCreate() {
    this.getUsersInfo();
    this.remoteMethod();
  },
};
</script>

<style scoped>
.page-position-common {
  float: right;
  margin-right: 15px !important;
  margin-left: 5px;
}

.table {
  line-height: normal;
}
</style>
