import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-plus/theme-chalk/index.css';
// import locale from 'element-plus/lib/locale/lang/zh-cn'
import locale from 'element-plus/dist/locale/zh-cn.mjs'
//引入elementPlus
import ElementPlus from 'element-plus'
//引入elementPlus样式
import 'element-plus/dist/index.css'
import "@/assets/css/iasm.css";
import '@/assets/css/theme.scss'
import axios from "axios";
import * as ElIcons from '@element-plus/icons'

import {dispatchEventStroage,dispatchEventSessionStroage} from "@/assets/js/iasm.js"

import echarts from '@/assets/js/echarts.js'

import AnimatedNumber from 'animated-number-vue3'
import {setupMenusGuard} from './router/guard'
import {request} from '@/api'
const app = createApp(App)
for (const name in ElIcons) {
    app.component(name, ElIcons[name])
}

function isUrl(path,from) {
    let pathList
    if(path.includes('https')){
      pathList = path.split('https')
      window.open('https' + pathList[pathList.length - 1],'_blank')
      router.go(from.path)
      return true
    }else if(path.includes('http')){
      pathList = path.split('http')
      window.open('http' + pathList[pathList.length - 1],'_blank')
      router.go(from.path)
      return true
    }
    return false
}

let flag = false
function isPermissonMenu(path,menuList){
    menuList.forEach(menu=>{
        if(menu.path === path){
            flag = true
        }
        if(menu.children && menu.children.length>0){
            isPermissonMenu(path,menu.children)
        }
    })
    return flag

}

router.beforeEach(async (to, from, next) => {
    flag = false
    const { meta, path, matched } = to
    if(path =='/login' || path =='/start' || path =='/404' || path=='/home/noPermission' || path =='/home/UserCenter/AccountSettings' || path == '/Expired'){
        next()
        return
    }else{
        let menuList = []
        if(store.state.menuList && store.state.menuList.length>0){
            menuList = store.state.menuList
        }else{
            let roleId = parseInt(localStorage.getItem("currentRoleId"));
            let res = await request.getMenuListByRoleId({roleId,projectId:1})
            menuList = res.data.data
        }
        //直接输入或点击链接时判断
        if(!isPermissonMenu(path,menuList)){
            next('/404')
        }
        
        //如果是外链，直接跳转外链
        if(isUrl(path,from)) return
        // 路由拦截，父级菜单点击跳转时根据页面权限重定向第一个有权限的二级菜单
        let directPath = null
        if (meta?.isDirect) {
            let authCodeList = localStorage.getItem("AuthCodeList");
            const curRoute = matched.filter(el => el.path === path)
            let childlist = curRoute.length > 0 ? curRoute[0].children || [] : []
            childlist = childlist.filter(el => el.meta.permission && authCodeList.includes(el.meta.permission))
            directPath = childlist.length > 0 ? childlist[0].path : null
        }
        directPath ? next({path: directPath}) : next()
    }
    
})

app.use(store).use(router).use(ElementPlus, { locale }).use(AnimatedNumber).use(dispatchEventStroage).use(dispatchEventSessionStroage)

setupMenusGuard(router,store)

app.mount('#app')
app.provide('$axios', axios)

app.config.globalProperties.$echarts = echarts;


// 使用时，在按钮上绑定 v-has="'hidden&apiManage.list'" 或者 v-has="'forbidden&apiManage.list'" 或者 v-has="'notshow&apiManage.list'", &后为权限码
app.directive('has', {
    // eslint-disable-next-line
    mounted: function (el, bindings, vnode) {
        let permissionValue = bindings.value;
        let a = permissionValue.split("&");
        // 从缓存获取权限编码集合
        let authCodeList = localStorage.getItem("AuthCodeList");
        let boolean = authCodeList.includes(a[1]);
        if (!boolean) {
            if (a[0] == 'hidden') {
                el.parentNode && el.parentNode.removeChild(el)
            } else if (a[0] == 'notshow') {
                el.style.display = 'none'
            } else {
                // 禁用
                el.classList.add('is-disabled');//element-plus el-button 类名
                el.setAttribute('disabled', 'disabled');//element-plus el-button 属性
            }
        }
    }
})

// 解决页面元素大小响应报错问题
const debounce = (fn, delay) => {
    let timer = null
    return function () {
        let context = this
        let args = arguments
        clearTimeout(timer)
        timer = setTimeout(() => {
            fn.apply(context, args)
        }, delay);
    }
}
const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16)
        super(callback)
    }
}

