<template>
    <router-view v-if="isRouterAlive"></router-view>
</template>
 
<script>
import { nextTick, provide, ref } from "vue";

export default {
  name: 'APP',
  setup() {
    const isRouterAlive = ref(true);
    const reload = () => {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      })
    };
    provide('reload', reload);
    return {
      isRouterAlive,
      reload
    }
  }
}
</script>
 
<style lang="less">

/* 解决element-ui的table表格控件表头与内容列不对齐问题 */
.el-table th.gutter {
  display: table-cell !important;
}
::-webkit-scrollbar {
  width: 7px;
  height: 8px;
}


::-webkit-scrollbar-track {
  background-color: rgb(0 0 0 / 5%);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(144 147 153 / 30%);
  border-radius: 2px;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 20%);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(144 147 153 / 50%);
}

html,
body {
  width:100%;
  height:100%;
  /*background:beige;*/
  padding-top: 0px;
  padding-left: 0px;
  margin-left: 0px;
  margin-top: 0px;
}
#app{
  display: flex;
  flex-direction: column;
  width:100%;
  height:100%;
}
.el-table th.el-table__cell.is-leaf{
  background: #F5F6FD;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  color: #4e5156;
}
/deep/.el-table__body{
 /deep/.el-table__row{
    min-height: 60px!important;
    td{
      min-height: 60px;
      background: #fff!important;
      font-size: 14px;
      font-family: Source Han Sans CN, Source Han Sans CN-400;
      font-weight: 400;
      color: #091328;
    }
  }
  /deep/.hover-row{
    td{
       background: #F5F6FD!important;
    }
  }
}
.is-link{
  span{
    color: #1890ff;
  }
}

.custom-tree-node{
  
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-400;
  font-weight: 400;
  color: #091328;
  .custom-tree-label{
    display: flex;
    justify-content: center;
    align-content: center;
    .custom-tree-label-div{
      height: 20px;
      line-height: 20px;
      max-width:100px;
      overflow:hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
  
  background: rgba(0,103,255,0.12);
  .custom-tree-node{
    color: #0067ff;
  }
}
.el-checkbox__input.is-checked+.el-checkbox__label{
  color: #606266!important;
}
.el-radio__input.is-checked+.el-radio__label{
  color: #606266!important;
}
</style>
