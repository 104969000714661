<template>
    <div class="data-discovery--aside">
        <el-form v-model="dbForm" >
           <el-row :gutter="24" >
            <el-col :span="6">

            
             <el-form-item label="数据源" class="margin-botton-12">
                <el-select class="db-sel" v-model="dbForm.dbId" filterable clearable placeholder="请选择数据源" @click="getDbList" @change="changeDbInfo">
                    <el-option v-for="item in dbForm.dbList" :key="item.dataSourceId" :label="item.dataSourceNameCn" :value="item.dataSourceId" />
                </el-select>
            </el-form-item>
            </el-col>
             <el-col :span="6">
             <el-form-item :label="'表(' + dbForm.allTableList.length +')'" class="margin-botton-12">
                <!-- <el-select class="db-sel" v-model="dbForm.dbId" filterable clearable placeholder="请选择表" @click="getDbList" @change="changeDbInfo">
                    <el-option v-for="item in dbForm.dbList" :key="item.dataSourceId" :label="item.dataSourceNameCn" :value="item.dataSourceId" />
                </el-select> -->
            <div class="tb-collapse">
            <!-- <el-collapse v-model="activeName" accordion>
                <el-collapse-item name="1"> -->
                    <!-- <template #title>
                        表({{ dbForm.allTableList.length }})
                    </template> -->
                    <el-input
                        size="small"
                        class="search-table-name"
                        v-if="dbForm.allTableList.length > 0"
                        v-model="filterTableName"
                        placeholder="请输入表名称"
                        @input="filterTable"
                        @focus="showSelect = true">
                        <template #append>
                            <el-button title="刷新表字段" :icon="Refresh" @click="getTableList('Y')"></el-button>
                        </template>
                    </el-input>
                    <draggable class="table-item-ul" v-model="dbForm.tableList" :sort="false" @start="dragStart" @end="dragEnd" itemKey="element" v-if="showSelect">
                        <template #item="{ element }">
                            <div class="table-item" @dblclick="showTableFields(element)"><img :src="dragitemIcon" />&nbsp;&nbsp;{{ element }}</div>
                        </template>
                    </draggable>

                <!-- </el-collapse-item>
            </el-collapse> -->
        </div>
         </el-form-item>
         </el-col>
           </el-row>
        </el-form>

        
    </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import {request} from '@/api'
// import { Connection } from '@element-plus/icons'
import { Refresh } from '@element-plus/icons-vue'
import { ElMessage } from "element-plus"
import Draggable from "vuedraggable"
import store from '@/store'
// import dragitemIcon from '@/assets/image/mysql.webp'
import dragitemIcon from '@/assets/image/tableItem.png'
export default {
    components: { Draggable },
    props: {
        dbId: {
            type: String,
            default: () => ''
        },
        dbType: {
            type: String,
            default: () => ''
        },
    },
    setup(props, { emit }) {
        let showSelect = ref(false)
        const dbForm = reactive({
            dbType:'',
            dbId: '',
            dbList: [], // 数据源列表
            tableObj: {}, // 选择数据源下所有表集合
            tableList: [], // 选择数据源下所有表名称数组集合-过滤之后
            allTableList: [] // 选择数据源下所有表名称数据集合
        });
        const activeName = ref('1');
        const filterTableName = ref('')
        // 获取数据源列表
        const getDbList = () => {
            request.searchApiDbList().then(res => {
                dbForm.dbList = res?.data?.data || [];
            });
        };
        getDbList();
        // 获取数据源下表 列表
        const getTableList = (flag) => {
            if (!dbForm.dbId) {
                dbForm.tableList = [];
                dbForm.allTableList = []
                dbForm.tableObj = {}
                return
            }
            request.searchTableInfoList({
                    dataSourceId: dbForm.dbId,
                    refreshFlag: flag
                }).then(res => {
                if (res.data.code == 200) {
                    dbForm.tableList = []
                    dbForm.allTableList = []
                    dbForm.tableObj = res?.data?.data || {};
                    Object.keys(dbForm.tableObj).forEach((key) => {
                        dbForm.tableList.push(key)
                        dbForm.allTableList.push(key)
                    });
                    // showSelect.value = true
                } else {
                    dbForm.tableList = [];
                    dbForm.allTableList = []
                    dbForm.tableObj = {}
                    ElMessage.error(res.data.msg);
                }
                store.dispatch('updateTableList', dbForm.tableObj)
            })
        };
        // 更改数据源事件
        const changeDbInfo = (val) => {
            let db = dbForm.dbList.filter(item=>{
                return item.dataSourceId === val
            })
            if(db.length > 0){
                dbForm.dbType = db[0].subCategory
            }else{
                dbForm.dbType = ''
            }
            getTableList('N');
            // 清空右侧所有配置数据
            emit('clearConfigData')
            emit('getdbIdAndDbType',{
                dbType:dbForm.dbType,
                dbId:dbForm.dbId,
            })
        };
        // 拖拽开始事件
        const dragStart = (e) => {
            const item = dbForm.tableList[e.oldIndex]
            store.dispatch('changeDragItem', item)
        }

        const dragEnd = (e) => {
            console.log('拖拽结束事件', e);
        }

        const filterTable = (e) => {
            dbForm.tableList = dbForm.allTableList.filter(el => el.includes(e))
        }

        // sql手动编辑时， 双击表名在sql填写区域添加sql实例---select 所有字段  from 表名
        const showTableFields = (tableName) => {
            const allFieldsList = dbForm.tableObj[tableName]
            if (allFieldsList) {
                emit('setSqlExample', allFieldsList, tableName)
            }
            showSelect.value = false
        }

        onMounted(() => {
            dbForm.dbId = props.dbId || ''
            dbForm.dbType = props.dbType || ''
            if (dbForm.dbId) {
                getTableList('N');
            }
        })
        
        return {
            dragitemIcon, dbForm, activeName, getDbList, changeDbInfo, getTableList, dragStart, dragEnd, Refresh, filterTable, filterTableName, showTableFields,showSelect
        };
    }
}
</script>

<style lang="less" scoped>
    .data-discovery--aside {
        width: 100%;
        .db-sel {
            width: 100%;
        }
        .tb-collapse {
            width: 100%;
            position: relative;
            & /deep/ .el-collapse-item__header,
            & /deep/ .el-collapse-item__wrap {
                background: transparent;
            }
            .table-item-ul {
                position: absolute;
                z-index: 10;
                background-color: #fff;
                border-radius: 8px;
                padding: 5px;
                border: 1px #eee solid;
                max-height: 100px;
                overflow-y: auto;
                overflow-x: hidden;
            }
            .table-item {
                cursor: pointer;
                height: 30px;
                display: flex;
                align-items: center;
            }
            .search-table-name {
                margin-bottom: 8px;
            }
        }
    }
</style>