<template>
  <div>
    <el-drawer v-model="showDrawer" size="1000" style="text-align:left;" :close-on-click-modal="false">
    <template #header>
      <h4 class="drawer-title">{{nodeName}}</h4>
    </template>
    <template #default>
      <div>
       <component ref="componentRef" 
       :iasmApiArrangeNode="iasmApiArrangeNode" 
       :nodeConfigGraphData="nodeConfigGraphData"
       :iasmApiArrange="iasmApiArrange"
       :operatorFlowId="operatorFlowId"
       :operatorData="operatorData"
       :is="componentNameMap[properties.nodeId]" 
       v-if="showDrawer"/>
      </div>
    </template>
    <template #footer>
      <div style="flex: auto;">
        <el-button @click="cancelClick">取消</el-button>
        <el-button type="primary" @click="confirmClick">确定</el-button>
      </div>
    </template>
  </el-drawer>
  </div>
</template>

<script>
import { ref } from 'vue';
import filter_node from './operatorComponents/filterOperator.vue'
import api_node  from './operatorComponents/APIOperator.vue'
import process_node from  './operatorComponents/computOperator.vue'
import switch_node from  './operatorComponents/switchOperator.vue'
import java_node from  './operatorComponents/javaOperator.vue'


export default {
    components:{
      filter_node,
      api_node,
      process_node,
      switch_node,
      java_node
    },
    emits:['handleSuccess'],
    setup(props,{emit}){
        let componentRef = ref(null)
        let iasmApiArrangeNode = ref(null) 
        let nodeConfigGraphData = ref(null)
        let iasmApiArrange = ref(null)
        let operatorFlowId = ref(null) 
        let operatorData = ref(null)
        let componentNameMap = {
          "1146490369635262466":filter_node,
          "1146490369635262468":process_node,
          "1146490369635262465":api_node,
          "1146490369635262467":switch_node,
          "1146490369635262469":java_node,
        } 
        let properties = ref({
          nodeId:null,
        })
        let nodeName = ref(null)
        let nodeCode = ref(null)

        let showDrawer = ref(props.show)
        const cancelClick = ()=>{
            showDrawer.value = false
        }
        const confirmClick = async ()=>{
          if(componentRef.value && componentRef.value.validate){
            let v = await componentRef.value.validate()
            if(v){
              emit('handleSuccess',operatorFlowId.value,{
              iasmApiArrangeNode:{
                   nodeData:JSON.stringify(componentRef.value.getNodeData()),
                   nodeConfigName: nodeName.value,	
                   nodeId: properties.value.nodeId,
                   nodeCode: nodeCode.value,
                   operatorFlowId:operatorFlowId.value,
                }
            })
            showDrawer.value = false
            }
          }
        }

        const show = (params)=>{
          params.operatorData
          nodeCode.value = params.data.nodeCode
          properties.value = params.data.data.properties
          nodeName.value = params.data.nodeName
          if(params.operatorData[params.data.data.id]){
            iasmApiArrangeNode.value = params.operatorData[params.data.data.id].iasmApiArrangeNode
          }else{
            iasmApiArrangeNode.value =  null
          }
          nodeConfigGraphData.value = params.nodeConfigGraphData
          operatorFlowId.value = params.data.data.id
          operatorData.value = params.operatorData
          iasmApiArrange.value = params.iasmApiArrange
          showDrawer.value = true
        }
        return{
            cancelClick,
            confirmClick,
            showDrawer,
            show,
            properties,
            nodeName,
            nodeCode,
            componentNameMap,
            iasmApiArrangeNode,
            iasmApiArrange,
            componentRef,
            operatorFlowId,
            nodeConfigGraphData,
            operatorData,
        }
    }

}
</script>

<style lang="less" scoped>
.drawer-title{
  text-align:left;color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  border-bottom:1px solid #d9d9d9;
  padding-bottom:15px;
  margin-bottom: 0;
}
/deep/.el-drawer__header{
  margin-bottom: 0;
}

</style>