<template>
  <div class="api-info">
    
    <div class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
      <div class="header-title">
      <strong>基础信息</strong>
      <div> 
        <el-dropdown>
        <span class="el-dropdown-link">
          {{form.apiVersion}}版本
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="version in apiVersionList" :key="version.value"  @click="changeVersion(version)">{{version.label}}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      </div>
      </div>
    <el-divider />
      <el-form :model="form" align-right label-width="120">
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item
              label="API名称："
              prop="apiName"
              label-position="right"
            >
              <span class="val"> {{ form.apiName }} </span>
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item
              label="是否鉴权："
              prop="apiIssecurity"
              label-position="right"
            >
              <span class="val"> {{ form.apiIssecurity==='N'?'否':'是' }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="请求方式：" prop="apiRequestM">
              <span class="val">
                {{RequestMaps[form.apiRequestM]}}
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="返回数据结构：">
              <span class="val">
                {{resultDataBuildMaps[form.resultDataBuild]}}
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="请求数据类型："
              label-position="right"
            >
              <span class="val"> {{ 'application/json' }} </span>
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item
              label="返回数据类型："
              label-position="right"
            >
              <span class="val"> {{ 'JSON' }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="URL：" prop="apiUrl">
              <span class="ellipsis val"> {{ form.apiUrl }} </span>
            </el-form-item>
          </el-col>
          
           <el-col :span="8">
            <el-form-item label="所属应用：" prop="appName">
              <span class="val"> {{ form.appName }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="一级业务分类：" >
              <span class="val"> {{ oneBusinessCateName }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="二级业务分类：" >
              <span class="val"> {{ twoBusinessCateName }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务类型：" prop="apiServicetype">
              <span class="val"> {{ apiServiceTypesMap[form.apiServicetype] || '-' }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务类别：" >
              <span class="val"> {{ createTypeMaps[form.apiType]}} </span>
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="服务版本：" >
              <span class="val"> {{ form.apiVersion}} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务OWNER：" >
              <span class="val"> {{ form.apiOwnerName? form.apiOwnerName + ' ' + form.apiOwnerNumber  : '-'}} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务设计人：" >
              <span class="val"> {{ form.designUserName? form.designUserName + ' ' + form.designUserNumber : '-' }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务开发人：" >
              <span class="val"> {{ form.developerUserName? form.developerUserName + ' ' + form.developerUserNumber : '-'}} </span>
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="创建时间：" >
              <span class="val"> {{ form.creationTime}} </span>
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="最后更新时间：" >
              <span class="val"> {{ form.updateTime}} </span>
            </el-form-item>
          </el-col>
         
        </el-row>
        <el-row :gutter="24" v-if="form.apiCreateM === '2'">
          <el-col :span="24">
            <el-form-item label="源URL：" align="left">
              <span class="val"> {{ form.apiSourceUrl }} </span>
            </el-form-item>
          </el-col>
        </el-row>
        
            <el-row :gutter="24">
         <el-col :span="24">
            <el-form-item label="访问URL：" align="left">
              <span class="val"> {{ form.callUrl }} </span>
            </el-form-item>
          </el-col>
          </el-row>
          <el-row :gutter="24" >
            <el-col :span="24">
              <el-form-item label="Mock地址：" align="left">
                <span> {{ form.mockUrl }} </span>
                <el-tooltip content="复制完整URL">
                  <el-button  type="text" icon="CopyDocument" @click="copyUrl"/>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="24">
         <el-col :span="24">
            <el-form-item label="描述：" align="left">
              <span class="val"> {{ form.apiDescription }} </span>
            </el-form-item>
          </el-col>
           </el-row>
      </el-form>
    </div>

    
    <div class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
    <div class="header-title"><strong>订阅列表</strong></div>
    <el-divider />
      <el-table :data="subscribeList"  height="300"> 
          <el-table-column
            label="应用名称"
            prop="appName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="订阅人"
            prop="subscribeUserName"
            :show-overflow-tooltip="true"
          >
           <template #default="scope">
              {{scope.row.subscribeUserName + ' ' + scope.row.subscribeUserNumber}}
            </template>
          </el-table-column>
         
          <el-table-column
            label="状态"
            prop="subscribeStatus"
            :show-overflow-tooltip="true"
          >
            <template #default="scope">
              {{scope.row.subscribeStatus}}
            </template>
          </el-table-column>

          <el-table-column
            label="订阅时间"
            prop="subscribeDate"
            :show-overflow-tooltip="true"
          />
      </el-table>
    </div>

   
    <div class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
    <div class="header-title"><strong>请求入参</strong></div>
      <el-divider />
      <el-table :data="form.requestParamters"  height="300"> 
          <el-table-column
            label="参数名称"
            prop="paramName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
          label="参数类型"
          prop="paramType"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            {{paramsTypesMaps[scope.row.paramType] || scope.row.paramType}}
          </template>
        </el-table-column>
        <el-table-column
          label="默认值"
          prop="paramValue"
          :show-overflow-tooltip="true"
        >
          <template #default="scope" >
             {{scope.row.paramValue}}
          </template>
        </el-table-column>

        <el-table-column
          label="是否必填"
          prop="paramIsMust"
          :show-overflow-tooltip="true"
        >
          <template #default="scope" >
             {{scope.row.paramIsMust === 'Y'?'是':'否'}}
          </template>
        </el-table-column>
        <el-table-column prop="example" label="示例值" width="200" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column
            label="描述"
            prop="paramDescription"
            :show-overflow-tooltip="true"
          />
      </el-table>
    </div>

    <div class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
    <div class="header-title"><strong>响应出参</strong></div>
    <el-divider />
      <el-table :data="form.responseParamters" height="300">
        <el-table-column
            label="参数中文名称"
            prop="paramNameCm"
            :show-overflow-tooltip="true"
          >
           <template #default="scope">
            {{scope.row.paramNameCm || scope.row.apiFieldCnname}}
          </template>
          </el-table-column>
          <el-table-column
            label="参数名称"
            prop="paramName"
            :show-overflow-tooltip="true"
          >
          <template #default="scope">
            {{scope.row.paramName || scope.row.apiFieldName}}
          </template>
          </el-table-column>
          <el-table-column
          label="参数类型"
          prop="paramType"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            {{paramsTypesMaps[scope.row.apiFieldType] || scope.row.apiFieldType}}
          </template>
        </el-table-column>
        <el-table-column prop="example" label="示例值" width="200" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column
            label="描述"
            prop="fieldDescription"
            :show-overflow-tooltip="true"
          />
      </el-table>
    </div>
    <div class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
      <div class="header-title"><strong>请求示例</strong></div>
      <el-divider />
        <CodeMirror  class="codemirror" v-model:value="form.apiRequestE" ></CodeMirror>

    </div>
     <div class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
      <div class="header-title"><strong >返回示例</strong></div>
      <el-divider />
        <CodeMirror  class="codemirror" v-model:value="form.apiResponseE" ></CodeMirror>

    </div>

    <div class="box-card-radius-eight white-bg" style="margin-bottom:100px;" v-if="form.isMock === '1'">
        <div class="form-box">
        <div class="header-title"><strong>Mock数据</strong></div>
        <el-divider />
          <CodeMirror  class="codemirror"  v-model:value="form.mockData" ></CodeMirror>
        </div>
    </div>

    <div class="box-card-radius-eight white-bg"  v-if="fromPage == 2">
         <div class="header-title"><strong >审批意见</strong></div>
      <el-form :model="lform" :rules="notNull" ref="ruleFormRef">
        <el-form-item label="审批意见" prop="approvalStatus">
          <el-radio v-model="lform.approvalStatus" label="2">同意</el-radio>
          <el-radio v-model="lform.approvalStatus" label="3">驳回</el-radio>
        </el-form-item>
        <el-form-item label="" prop="rejectReasons" v-if="lform.approvalStatus === '3'">
           <el-input v-model="lform.rejectReasons" type="textarea" style="width: 100%;" rows="3" placeholder="驳回意见" />
        </el-form-item>
      </el-form>
      <div style="flex: auto">
        <el-button type="primary" @click="submitApproval(ruleFormRef)" v-has="'hidden&userCenter.myWaitFor.approve'">确定</el-button>
      </div>
    </div>

    <div align="right" v-if="type != 2" class="footer white-bg">
      <span v-if="!fromPage">
        <el-button
        v-if="form.approvalStatus == '待审批'"
        type="primary"
        disabled
        >审批中</el-button
      >
      <el-button
        v-else-if="form.approvalStatus == '审批通过'"
        type="primary"
        disabled
        >已订阅</el-button
      >
      <el-button
        v-if="form.approvalStatus == '审批异常'"
        type="primary"
        disabled
        >审批异常</el-button
      >
      <el-button
        v-if="(!form.approvalStatus || form.approvalStatus =='已撤回' || form.approvalStatus =='审批驳回') && form.apiIssecurity !== 'N' && form.appId != currentAppId"
        type="primary"
        @click="subscribe()"
        v-has="'hidden&apiMall.list.subscribe'"
        >申请订阅</el-button
      >
      <el-button
        v-if="type === '1'"
        type="primary"
        @click="apiTest('1')"
        >测试</el-button
      ></span>
      <el-button type="primary" @click="back" style="margin-left:12px;">返回</el-button>
    </div>
  </div>
</template>
    
<script>
import { useRoute } from "vue-router";
import {request} from '@/api'
import { reactive,ref,onMounted } from "vue";
import iasmjs from "@/assets/js/iasm.js";
import { ElMessage, ElMessageBox } from "element-plus";
import router from '@/router';
import {paramsTypesMap,RequestMap,apiServiceTypes,createTypeMap,resultDataBuildMap} from '@/utils/constant'
import {copyClipboard} from '@/utils/function'
import CodeMirror from '@/components/common/CodeMirror'

export default {
  components:{
    CodeMirror
  },
  setup() {
    const route = useRoute();
    const apiId = route.query.apiId;
    const type = route.query.type;
    const apiType = route.query.apiType;

    const fromPage = route.query.fromPage;
    const id = route.query.id
    let twoBusinessCateName = ref('-')
    let oneBusinessCateName = ref('-')
    let currentAppId = ref(localStorage.getItem("appId"))

    const tableData = reactive({
      //表格参数
      list: [],
      responseList:[]
    });

    let apiVersionList = ref([{
      value:1,
      label:1
    }])

    let subscribeList = ref([])

    let apiResponse = ref(null);

    let apiRequest = ref(null);
   

    let paramsTypesMaps = ref(paramsTypesMap)
    let RequestMaps = ref(RequestMap)
    let resultDataBuildMaps = ref(resultDataBuildMap)
    let apiServiceTypesMap = ref(apiServiceTypes)
    let createTypeMaps = ref(createTypeMap)
    let apiVersion = ref(1)
    let form = ref({
      apiId: "",
      apiName: "",
      apiRequestM: "",
      apiUrl: "",
      apiOwner: "",
      apiOwnerName: "",
      apiDescription: "",
      status: "",
      delFlag: "",
      apiCreateSql: "",
      dbId: "",
      tenantId: "",
      apiRequestE:"",
      apiResponseE:"",
      mockData:"",
      requestParamters: [],
      responseParamters: [],
      userList: [],
      approvalStatus: "",
      appName: "",
      callUrl:"",
      apiCreateM: "",
      apiSourceUrl: ""
    });
    

    const lform = reactive({
      approvalId: '',
      apiId: '',
      appId: '',
      approvalStatus: '',
      rejectReasons: ''
    })

    const copyUrl = ()=>{
      copyClipboard(form.value.mockUrl)
    }

    const getData = async (params) => {
      let res
      let result

      res = await request.getApiInfo(params)
      result = res.data.data
      if(res.data.code == 200){
          let requestParamters = result.requestParamters?JSON.parse(result.requestParamters) : []
          let responseParamters = result.responseParamters?JSON.parse(result.responseParamters) : [] 
          requestParamters =  requestParamters.filter(item=>{
              return item.parameterType !== 'authenticationParameters'
          })
          let apiRequestE =  result.apiRequestE?JSON.stringify(JSON.parse(result.apiRequestE),null,4):""
          let apiResponseE = result.apiResponseE?JSON.stringify(JSON.parse(result.apiResponseE),null,4):""
          form.value = {
            ...result,
            requestParamters,
            responseParamters,
            apiRequestE,
            apiResponseE
            }
          
          lform.apiId = result.apiId;
          lform.approvalId = result.approvalId;
          getAppNameByAppId(result.appId);
          getApiApprovalStatus();
          findApiVersion(result.baseApiId)
          getBusinessCateOptions(form.value.oneBusinessCateId,form.value.twoBusinessCateId)
          selectApiSubscribeList(form.value.apiId)
      }
    };

    const getBusinessCateOptions = (oneBusinessCateId,twoBusinessCateId)=>{
      request.selectBusinessCatePage({
          currentPage: 1,
          pageSize: 10000
        }).then((res) => {
        if (res?.data?.code === 200) {
            res.data.data.forEach(item=>{
              if(item.businessCateId=== oneBusinessCateId){
                oneBusinessCateName.value = item.businessCateName
                item.child.forEach(ele=>{
                  if(ele.businessCateId === twoBusinessCateId){
                    twoBusinessCateName.value = ele.businessCateName
                  }
                })
              }
            })
        }
      });
    }

    const changeVersion = (val) =>{ 
        form.value.apiVersion = val.apiVersion
        getData({ apiId:val.value})
    }

    const findApiVersion = (baseApiId)=>{
      request.findApiVersion({
        baseApiId,
        isApiMarket:'true'
      }).then((res) => {
            if (res?.data?.code === 200) {
               if(res.data.data.length > 1){
                apiVersionList.value = res.data.data.map((item)=>{
                  return {
                    value:item.apiId,
                    label:item.apiRunStatus === 'Y'?item.apiVersion+ " (正式版本)" : item.apiVersion+ " (编辑版本)"
                  }
                })
                // apiVersionList.value.pop()
              }
            } else {
                ElMessage.error(res?.data?.msg);
            }
      });
    }


    const getAppNameByAppId=(appId)=>{
      request.selectAppById({appId}).then((res) => {
        if(res.data.code==200){
          if(res.data.data&&res.data.data.result){
            form.value.appName=res.data.data.result[0].appName
          }
        }
       
      });
    }
    const getApiApprovalStatus = () => {
      request.getApiApprovalStatus({apiId: apiId,appId: localStorage.getItem("appId")}).then((res) => {
        let a = res.data.data;
        form.value.approvalStatus = a.approvalStatus;
      });
    };
    const subscribe = () => {
      ElMessageBox.confirm("确认订阅" + form.value.apiName + "?")
        .then(() => {
          request.subscribeApi({
              ...form.value,
              responseParamters:JSON.stringify(form.value.responseParamters),
              requestParamters:JSON.stringify(form.value.requestParamters),
              authorizedAppId: localStorage.getItem("appId"),
            }).then((res) => {
            if (res?.data?.code === 200) {
                ElMessage.success("等待审批！");
                getApiApprovalStatus();
            } else {
                ElMessage.error(res?.data?.msg || "申请订阅失败！");
            }
          });
        })
        .catch(() => {});
    };
    // 试用 - 接口测试
    const apiTest = () => {
       router.push({
          path:"/home/serviceOperation/ApiTest",
          query:{
            apiName:form.value.apiName,
            apiId:form.value.apiId,
            apiType
          }
        });
    };


    const ruleFormRef = ref(null);
    const notNull = reactive({
      approvalStatus: [{ required: true, message: '请选择审批意见', trigger: 'blur' }]
    });

    const selectApiSubscribeList = (apiId)=>{
       request.selectApiSubscribeList({apiId}).then((res) => {
            if (res?.data?.code === 200) {
                subscribeList.value = res.data.data
            } else {
                ElMessage.error(res?.data?.msg);
            }
          })
    }

    const submitApproval = async (formEl) => { //保存
      await formEl.validate(async (valid) => { //
        if (valid) {
            let res
            const params = {
                id,
                appId: localStorage.getItem('appId')
            }

            if (lform.approvalStatus === '2') {
                res =  await request.issuePass(params)
            } else if (lform.approvalStatus === '3') {
                params.rejectReasons = lform.rejectReasons || ''
                res =  await request.issueRefuse(params)
            }

            let respCode = res.data.code;
            if (respCode == '200') {
              router.back(-1);
              const msg = lform.approvalStatus === '2' ? '审批成功' : '驳回成功'
              ElMessage.success(msg);
              // store.dispatch('updateWaitForCount')
            } else {
              let msg = lform.approvalStatus === '2' ? '审批失败' : '驳回失败'
              if (res?.data?.msg) {
                msg = res?.data?.msg
              }
              ElMessage.error(msg);
            }
        }
      })
    }

   

    
    const back = () => {
      router.back(-1)
    }

    onMounted(()=>{
      getData({apiId: apiId,operateType: "7"});
    })
    return {
      copyUrl,
      currentAppId,
      selectApiSubscribeList,
      subscribeList,
      ruleFormRef,
      notNull,
      submitApproval,
      apiVersion,
      apiVersionList,
      changeVersion,
      form,
      lform,
      tableData,
      getData,
      iasmjs,
      getApiApprovalStatus,
      subscribe,
      type,
      getAppNameByAppId,
      apiTest,
      back,
      paramsTypesMaps,
      RequestMaps,
      resultDataBuildMaps,
      apiResponse,
      apiRequest,
      apiServiceTypesMap,
      createTypeMaps,
      oneBusinessCateName,
      twoBusinessCateName,
      fromPage
    };
  },
  name: "ApiInformation",
  methods: {
    getRowKey(row) {
      return row.roleId;
    },
  },
};
</script>
    
<style scoped lang="less">
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.api-info{
  padding: 20px;
  margin-bottom:60px;
  .val{
    font-family: Source Han Sans CN, Source Han Sans CN-400;
    font-weight: 400;
    color: #091328;
  }
  .header-title{
    display: flex;
    justify-content: space-between;
  }
}
.footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 48px);
    padding: 10px 14px;
    left: 0;
    box-shadow: 0 -4px 16px 0 rgba(0,67,161,0.10);
    z-index: 10;
    display: flex;
    justify-content: center;
  }
</style>