<template>
  <div style="height: 260px" class="main_bgimg bg_img3">
    <div class="main_header">
      <div class="main_header_text">{{ text }}</div>
    </div>
    <div class="main-tab-box">
      <span
        v-for="(item, index) in tab.list"
        :key="index"
        :class="['com-tab', active == item.id ? 'active-tab' : '']"
        @click="changeTab(item)"
        >{{ item.tabName }}</span
      >
    </div>
    <div class="main_content">
      <div id="myChart1" :style="{ height: '220px' }"></div>
    </div>
  </div>
</template>
<script>
import {request} from '@/api'
import { ref, reactive, onMounted, watch } from "vue";
import * as echarts from "echarts";
export default {
  name: "CenterMiddle",
  props: {
    appId: {
      type: String,
    },
  },
  setup(props) {
    const text = ref("API发布与订阅");
    const active = ref(1);
    const tab = reactive({
      list: [
        { tabName: "本周", id: 1, type: "week" },
        { tabName: "本月", id: 2, type: "month" },
        { tabName: "全年", id: 3, type: "year" },
      ],
    });
    const changeTab = (tabInfo) => {
      active.value = tabInfo.id;
      getApiPublishData(tabInfo.type);
    };
    const echatsData = reactive({});
    const apiPublishData = reactive({
      detail: [],
    });

    const state = reactive({
      option: {
        color: ["#80FFA5", "#00DDFF"],
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          //data: ["发布", "订阅"],
           icon: 'rect',
            itemWidth: 18,
            itemHeight: 6,
            // top: '-10px',
            // left:250,
            // zIndex:999,
            textStyle: {
              color: '#89b6da',
              fontSize: 14,
            },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#687080",
                width: 0.5,
              },
            },
          },
        ],
        series: [
          {
            name: "发布",
            type: "line",
            stack: "Total",
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: "#0072ff",
            },
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(0,114,255,0.1)",
                },
                {
                  offset: 1,
                  color: "rgba(0,114,255,0.4)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: [140, 232, 101, 264, 90, 340, 250],
          },
          {
            name: "订阅",
            type: "line",
            stack: "Total",
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: "#0cc89b",
            },
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(12,200,155,0.1)",
                },
                {
                  offset: 1,
                  color: "rgba(12,200,155,0.4)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: [120, 282, 111, 234, 220, 340, 310],
          },
        ],
      },
    });
    const initeCharts1 = () => {
      let myChart1 = echarts.init(document.getElementById("myChart1"));
      // 绘制图表
      myChart1.setOption(state.option);
      // 根据页面大小自动响应图表大小
      window.addEventListener("resize", function () {
        myChart1.resize();
      });
    };
    const init = (data) => {
      state.option.xAxis[0].data = [];
      state.option.series[0].data = [];
      state.option.series[1].data = [];
      const list = data.detail;
      list.map((item) => {
        state.option.xAxis[0].data.push(item.timeDimension);
        state.option.series[0].data.push(item.countPublish || 0);
        state.option.series[1].data.push(item.countSubscribe || 0);
      });
      initeCharts1();
    };
    const getApiPublishData = (type) => {
      request.subscribeAndPublish({
          appId: props.appId,
          timeDimension: type ? type : "week",
        }).then((res) => {
        if (res.data.code == 200) {
          apiPublishData.detail = res.data.data;
          init(apiPublishData);
        }
      });
    };
    getApiPublishData();
    watch(
      () => props.appId,
      (newData) => {
        if (newData) {
          getApiPublishData();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    onMounted(() => {
      //initeCharts1();
    });
    return {
      text,
      tab,
      active,
      changeTab,
      echatsData,
      apiPublishData,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "./CenterMiddle.scss";
</style>