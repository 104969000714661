<template>
  <div class="common-layout" style="height: 100%;width: 100%">
    <el-container style="height: 100%;box-sizing: border-box;">
      <el-main style="padding: 0;">
        <ApiMarketList />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import router from "@/router";
import ApiMarketList from "@/views/ApiMarketMain/ApiMarketList.vue";
export default {
  name: `ApiMarket`,
  components:{
    ApiMarketList
  },
  methods: {
    change(x) {
      router.push('/home/ApiMarket/' + x)
    }
  }
}
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>