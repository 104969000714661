<template>
  <el-drawer v-model="showDrawer" size="1000" style="text-align:left;"  @closed="close">
    <template #header>
      <h4 class="drawer-title">上传编排组件包</h4>
    </template>
    <template #default>
      <div>
        <el-form
            ref="uploadFormRef"
            :model="uploadForm"
            :rules="basicRules"
            label-position="top"
          >
          <el-row :gutter="24">
              <el-col :span="24" align="left">
                <el-form-item
                  label="包名称"
                  placeholder="请输入包名称"
                  prop="packageName"
                >
                  <el-input
                    clearable
                    v-model="uploadForm.packageName"
                  />
                </el-form-item>
              </el-col>
               <el-col :span="24" align="left">
                <el-form-item
                  label="包描述"
                  placeholder="请输入包描述"
                  prop="packageDesc"
                >
                  <el-input
                    clearable
                    v-model="uploadForm.packageDesc"
                    show-word-limit
                    type="textarea"
                    :rows="4"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24" align="left">
                <el-button type="link" icon="download" @click="handleDownload('template')">下载编排开发模板项目</el-button>
              </el-col>

               <el-col :span="24" align="left">
                <el-form-item
                  prop="file"
                >
                <template #label>
                  <p style="display:flex;align-items:center;color:#333333;"><el-icon size="15" style="color:rgb(74, 141, 237);"><Opportunity /></el-icon> 根据编排组件模板项目开发完毕后,打包成meavn jar包</p>
                </template>
                  <el-upload
                    style="width:100%;height:150px;border: 1px dashed #0e68e7;border-radius:8px;text-align:center;background:#F8FBFE;"
                    ref="uploadRef"
                    class="upload-demo"
                    accept=".jar"
                    action="/iods_gateway/iodsApibusinessAdmin/arrangePackage/uploadArrangePackage"
                    :limit="1"
                    :on-exceed="handleExceed"
                    :on-success="handleSuccess"
                    :on-change="handleChange"
                    :on-remove="onRemove"
                    :auto-upload="false"
                >
                    <template #trigger>
                    <div style="width:900px;padding-top:20px;color:#333333;">
                      <el-icon size="40"><Upload /></el-icon>
                      <p style="margin:0;" >点击上传文件</p>
                      <p style="margin:0;" >仅支持jar格式</p>
                    </div>
                    </template>
                </el-upload>
                <a v-if="showDownload" style="color:#1890ff;cursor: pointer;" @click="handleDownload('file')">点击下载文件 ({{uploadForm.packageJarName}})</a>
                </el-form-item>
              </el-col>
          </el-row>
        </el-form>
       
      </div>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="cancelClick">取消</el-button>
        <el-button type="primary" @click="confirmClick">确定</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script>
import { ref,reactive } from 'vue';
import { genFileId } from 'element-plus'
import { request } from "@/api";
export default {
emits:['reloadTable'],
setup(_,{emit}){
    let packageId = ref(null)
    let showDownload = ref(false)
    let showDrawer = ref(false)
    let uploadRef = ref(null)
    let uploadFormRef = ref(null)
    let uploadForm = ref({
        packageName:'',
        packageJarName:'',
        packageDesc:'',
        file:null
    })

    let validatePackageName = ''
    let validatePackageDesc = ''

    const validateFile =  (rule, value, callback) => {
      if (!packageId.value && !uploadForm.value.file) {
        callback(
          new Error(
            "请上传jar包！"
          )
        );
      } else {
        callback();
      }
    };
    const basicRules = reactive({
      packageName: [
        { required: true, message: "请输入包名称", trigger: "blur" }
      ],
      file: [
        { validator: validateFile, trigger: ["blur","change"] },
      ],
    });

    const handleExceed= (files) => {
        uploadRef.value.clearFiles()
        const file = files[0]
        file.uid = genFileId()
        uploadRef.value.handleStart(file)
    }

    const handleChange = (file,files)=>{
       console.log(file.raw,files,'.handleChange')
        uploadForm.value.file = file.raw
        // uploadForm.value.packageJarName = file.raw.name
    }

    const onRemove = ()=>{
       uploadForm.value.file = null
       uploadRef.value.clearFiles()
    }

    const handleSuccess = (file,files)=>{
        console.log(file,files,'.handleSuccess')
    }

    const  handleDownload = (type)=> {
      let requestUrl = window.origin + '/iods_gateway/iodsApibusinessAdmin/arrangePackage/downloadArrangePackage/' + packageId.value
      let fileName = uploadForm.value.packageJarName
      if(type === 'template'){
        requestUrl = window.origin + '/iods_gateway/iodsApibusinessAdmin/arrangePackage/developmentTemplatesDownLoad'
        fileName = '编排开发模板项目'
      }
        var xhr = new XMLHttpRequest();
        xhr.open("get", requestUrl, true); // get、post都可
        xhr.responseType = "blob";
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.setRequestHeader("Authorization", localStorage.getItem('token')); //加请求头
        xhr.onload = function () {
          if (xhr.status == 200) {
            let blob = new Blob([this.response], {type: type === 'template'? 'application/zip':'application/jar' });
            var a = document.createElement("a")
            let url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName  // 文件名
            a.click()
            window.URL.revokeObjectURL(url)
          }
        }
        xhr.send();
    }

   
    const show = (id)=>{
      packageId.value = id
      if(packageId.value){
        showDownload.value = true
        request.getArrangePackageDetails({packageId:packageId.value}).then((res) => {
          if (res?.data?.code === 200) {
             let {packageName,packageDesc,packageJarName} = res.data.data
             validatePackageName = packageName
             validatePackageDesc = packageDesc

             uploadForm.value.packageName = packageName
             uploadForm.value.packageDesc = packageDesc   
             uploadForm.value.packageJarName = packageJarName       

          }
      });
      }
      showDrawer.value = true
    }

    const cancelClick = ()=>{
         showDrawer.value = false
        //  tableData.value = []
    }

    const confirmClick = async ()=>{
      if(!uploadForm.value.file && packageId.value && validatePackageName == uploadForm.value.packageName && validatePackageDesc == uploadForm.value.packageDesc) {
        showDrawer.value = false
        return
      }
        await uploadFormRef.value.validate((valid) => {
          if (valid) {
            const formData = new FormData();
            if(uploadForm.value.file){
              formData.append('file', uploadForm.value.file);
            }
            

            if(packageId.value){
              formData.append('packageId', packageId.value)
            }

            for (const prop in uploadForm.value) {
              if (prop !== 'file' && Object.prototype.hasOwnProperty.call(uploadForm.value, prop)) {
                  formData.append(prop, uploadForm.value[prop]);
              }
            }
            request
                .uploadArrangePackage(formData)
                .then((res) => {
                if (res?.data?.code === 200) {
                    emit('reloadTable')
                    showDrawer.value = false
                }
                });
            } 
        })  
    } 

   
    const close = ()=>{
        uploadForm.value = {
            packageName:null,
            packageDesc:null,
            file:null
        }
        uploadRef.value.clearFiles()
        showDownload.value = false
    }

    return {
        showDrawer,
        uploadForm,
        uploadFormRef,
        show,
        cancelClick,
        confirmClick,
        close,
        basicRules,
        handleExceed,
        handleChange,
        handleSuccess,
        uploadRef,
        showDownload,
        handleDownload,
        onRemove
    }
}
}
</script>

<style>

</style>