<template>
  <div class="createSql" :style="{ height: height + 'px', margin: '28px' }">
    <div class="create-step">
      <el-tabs
        tab-position="right"
        v-model="active"
        class="tabs"
        @tab-change="handleStep"
        :before-leave="changeTabValid"
      >
        <el-tab-pane
          class="box-card-radius-eight white-bg"
          style="margin-bottom:100px;"
          label="基本信息"
          name="0"
        >
          <div align="left" class="step-title margin-botton-12">
            <strong>基本信息</strong>
          </div>
          <el-form
            ref="ruleFormRef"
            :model="form"
            :rules="basicRules"
            label-position="top"
          >
            <el-row :gutter="48">
              <el-col :span="8" align="left">
                <el-form-item
                  label="API名称"
                  placeholder="请输入名称"
                  prop="apiName"
                >
                  <el-input
                    clearable
                    v-model="form.apiName"
                    maxlength="100"
                    show-word-limit
                    @change="formChange"
                    
                  />
                </el-form-item>
              </el-col>
              <el-col :span="16" align="left">
                <el-form-item
                  v-if="urlFlag"
                  label="URL"
                  placeholder="请输入URL"
                  prop="apiUrl"
                >
                  {{ form.apiUrl }}
                </el-form-item>
                <el-form-item
                  v-else
                  label="URL"
                  placeholder="请输入URL"
                  prop="apiUrl"
                  
                >
                  <el-input
                    clearable
                    v-model.trim="form.apiUrl"
                    show-word-limit
                    :disabled="isEdit"
                    @change="formChange(true)"
                  >
                  <template #prepend>{{domain}}</template>
                   <template #append>
                      <el-tooltip content="复制完整URL">
                        <el-button  type="text" icon="CopyDocument" @click="copyUrl"/>
                      </el-tooltip>
                    </template>
                   </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="挂载目录" prop="categoryId">
                   <el-tree-select
                    v-model="form.categoryId"
                    placeholder="请选择挂载目录"
                    :data="treeData"
                    filterable
                    check-strictly
                    :render-after-expand="false"
                    :props="{
                      children: 'children',
                      label: 'categoryName',
                      value:'categoryId'
                    }"
                    style="width: 100%"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="业务分类" prop="businessCateId">
                  <el-cascader
                    v-model="form.businessCateId"
                    :options="businessCateOptions"
                    :props="{
                      value: 'businessCateId',
                      label: 'businessCateName',
                      children: 'child',
                      expandTrigger: 'hover',
                    }"
                    @change="changebusinessCateId"
                    style="width: 100%"
                  >
                  </el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="服务类型" prop="apiServicetype">
                  <el-select
                    clearable
                    v-model="form.apiServicetype"
                    
                    placeholder="请选择服务类型"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in apiServicetypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="服务版本" prop="apiVersion">
                  <el-select
                    clearable
                    v-model="form.apiVersion"
                    placeholder="请选择服务版本"
                    style="width: 100%"
                    @change="changeVersion"
                  >
                    <el-option
                      v-for="item in versionList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="是否安全认证" prop="apiIssecurity">
                  <el-radio-group
                  v-model="form.apiIssecurity">
                    <el-radio label="Y">是</el-radio>
                    <el-radio label="N">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="请求方式" prop="apiRequestM">
                  <el-select
                    clearable
                    v-model="form.apiRequestM"
                     @change="changeMethods"
                    filterable
                    placeholder="请选择请求方式"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in Requests"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
               <el-col :span="8" align="left" v-if="form.apiServicetype === 'select'">
                <el-form-item label="返回数据结构" prop="resultDataBuild" required>
                   <el-select
                    v-model="form.resultDataBuild"
                    filterable
                    placeholder="请选择返回数据结构"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in resultDataBuilds"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
              <el-form-item label="API责任人" prop="apiOwner">
                <el-select
                  clearable
                  v-model="form.apiOwner"
                  @change="changeApiOwner"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入搜索关键字"
                  :remote-method="getApiOwner"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in apiOwnerList"
                    :key="item.userId"
                    :label="item.userName + ' ' +item.account"
                    :value="item.userId"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            </el-row>

            <el-form-item label="描述">
              <el-input
                clearable
                v-model="form.apiDescription"
                maxlength="1000"
                show-word-limit
                type="textarea"
                :rows="4"
                @change="formChange"
              />
            </el-form-item>
          </el-form>

         
        </el-tab-pane>
        <el-tab-pane
         
          style="margin-bottom:100px;"
          label="取数逻辑"
          name="1"
        >
        <div  class="box-card-radius-eight white-bg"  style="margin-bottom:20px;">
          <div align="left" class="step-title margin-botton-12">
            <strong>取数逻辑</strong>
          </div>
          <DataDiscovery
            :key="loadFlag"
            :ref="(el) => setSqlConfigRef(el)"
            :refData="sqlConfRef"
            :dbId="form.dbId"
            :apiServicetype="form.apiServicetype"
            :dbType="form.dbType"
            :data="form.apiCreateJson"
            :paramList="tableData.list"
            :mysql="form.apiCreateSql"
            @sqlValid="sqlValid"
            @setSqlChangedFlag="setSqlChangedFlag"
            @setResultData="setResultData"
            @setdbIdAndDbType="setdbIdAndDbType"
          >
          </DataDiscovery>
</div>
<div  class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
           <div align="left" class="step-title margin-botton-12 margin-top-24">
            <strong>入参配置</strong>
          </div>
          <div align="left" class="table-btn__up margin-botton-12">
            <el-button type="primary" icon="plus" @click="addRow()"
              >新增入参</el-button
            >
          </div>
          <el-table :data="tableData.list" style="width: 100%" max-height="285">
            <el-table-column
              prop="paramNameCm"
              label="参数中文名称"
              width="200"
            >
              <template #default="scope">
                <el-input
                  clearable
                  v-model="scope.row.paramNameCm"
                  maxlength="50"
                  placeholder="请输入参数中文名称"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="paramName"
              label="参数名称"
              width="200"
            >
              <template #default="scope">
                <el-input
                  clearable
                  v-model="scope.row.paramName"
                  maxlength="50"
                  placeholder="请输入参数名称"
                  :disabled="scope.row.isDisable"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="paramValue"
              label="默认值"
              width="200"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                  clearable
                  v-model="scope.row.paramValue"
                  maxlength="50"
                  placeholder="请输入默认值"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="paramType" label="数据类型" width="200">
              <template #default="scope">
                <el-select
                  clearable
                  v-model="scope.row.paramType"
                  placeholder="请选择数据类型"
                >
                  <el-option v-for="type in paramsTypes" :key="type.label" :label="type.label" :value="type.value" />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="paramIsMust" label="是否必填写" width="200">
              <template #default="scope">
                <el-select
                  clearable v-model="scope.row.paramIsMust" default="N">
                  <el-option value="Y" label="是" />
                  <el-option value="N" label="否" />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="sort" label="排序" width="200">
              <template #default="scope">
                <el-input-number clearable v-model="scope.row.sort"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column
              prop="paramFun"
              width="500"
            >
            <template #header>
              <span style="display:flex;align-items:center;">输入函数 
                <el-tooltip 
                content="函数用法:函数名称(#{参数名称},固定参数值,...) 例如:trim(#{username}),对参数变量username去除前后空格"
                placement="top"
                >
                <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
                </span>
            </template>
               <template #default="scope">
               <el-autocomplete
                    v-model="scope.row.paramFun"
                    :fetch-suggestions="querySearch"
                    value-key="functionName"
                    label="functionName"
                    clearable
                    style="width:100%;"
                  />
              </template>
            </el-table-column>
             <el-table-column
              prop="example"
              label="示例值"
              width="200"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                  clearable
                  v-model="scope.row.example"
                  placeholder="请输入"
                  maxlength="50"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="paramDescription"
              label="描述"
              width="200"
            >
              <template #default="scope">
                <el-input
                  clearable
                  v-model="scope.row.paramDescription"
                  placeholder="请输入描述"
                  maxlength="50"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right">
              <template #default="scope">
                <el-popconfirm
                  title="删除后将无法恢复，请确认是否删除？"
                  confirm-button-type="danger"
                  icon="warning-filled"
                  icon-color="#F56C6C"
                  width="250"
                  placement="top-end"
                  @confirm="deleteRow(scope.$index)"
                >
                  <template #reference>
                    <el-button
                      icon="delete"
                      type="danger"
                      text
                      :disabled="scope.row.isDisable"
                    ></el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
</div>

<div  class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
          <div
            align="left"
            class="step-title margin-botton-12"
          >
            <strong>出参配置</strong>
          </div>
          <div align="left" class="table-btn__up margin-botton-12">
            <el-button type="primary" icon="plus" @click="addApiResponseRow"
              >新增出参</el-button
            >
          </div>

          <el-form
            ref="ruleFormRef3"
            :model="form"
            label-width="8%"
            :rules="mappingRules"
            label-position="top"
          >
            <el-form-item>
              <el-table
                ref="mappingDataList"
                :data="form.fieldMappingList"
                style="width: 100%"
                max-height="285"
              >
                <el-table-column
                  prop="apiFieldCnname"
                  label="参数中文名称"
                  width="200"
                  :show-overflow-tooltip="true"
                >
                  <template #default="scope">
                    <el-input
                      clearable
                      v-model="scope.row.apiFieldCnname"
                      maxlength="50"
                      placeholder="请输入参数中文名称"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="dbFieldName"
                  label="数据库字段名"
                   width="200"
                  :show-overflow-tooltip="true"
                >
                  <template #default="scope">
                    <el-input
                      clearable
                      v-model="scope.row.dbFieldName"
                      maxlength="50"
                      placeholder="请输入参数名称"
                      :disabled="scope.row.isDisable"
                    ></el-input>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="dbFieldType"
                  label="数据库字段类型"
                   width="150"
                  :show-overflow-tooltip="true"
                >
                  <template #default="scope">
                    <el-select
                      clearable
                      v-model="scope.row.dbFieldType"
                      placeholder="请选择数据类型"
                    >
                     <el-option v-for="type in paramsTypes" :key="type.label" :label="type.label" :value="type.value" />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="dbFieldLength" label="数据库字段长度" width="200">
                  <template #default="scope">
                    <el-input-number
                       clearable
                      v-model="scope.row.dbFieldLength"
                    ></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="apiFieldName"
                  label="api字段名"
                   width="200"
                  :show-overflow-tooltip="true"
                >
                  <template #default="scope">
                    <el-input
                      clearable
                      v-model="scope.row.apiFieldName"
                      maxlength="50"
                      placeholder="请输入参数名称"
                      :disabled="scope.row.isDisable"
                    ></el-input>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="apiFieldType"
                  label="api字段类型"
                   width="150"
                >
                  <template #default="scope">
                    <el-select
                      clearable
                      v-model="scope.row.apiFieldType"
                      placeholder="请选择数据类型"
                    >
                      <el-option v-for="type in paramsTypes" :key="type.label" :label="type.label" :value="type.value" />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="apiFieldLength" label="api字段长度"  width="200">
                  <template #default="scope">
                    <el-input-number
                      clearable
                      v-model="scope.row.apiFieldLength"
                    ></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column prop="sort" label="排序"  width="200">
                  <template #default="scope">
                    <el-input-number clearable v-model="scope.row.sort"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="paramFun"
                  width="500"
                >
                <template #header>
                  <span style="display:flex;align-items:center;">输出函数 
                    <el-tooltip 
                    content="函数用法:函数名称(#{参数名称},固定参数值,...) 例如:trim(#{username}),对参数变量username去除前后空格"
                    placement="top"
                    >
                    <el-icon><QuestionFilled /></el-icon>
                    </el-tooltip>
                    </span>
                </template>
                   <template #default="scope">
                   <el-autocomplete
                    v-model="scope.row.paramFun"
                    :fetch-suggestions="querySearch"
                    value-key="functionName"
                    label="functionName"
                    clearable
                    style="width:100%;"
                  />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="example"
                  label="示例值"
                  width="200"
                  :show-overflow-tooltip="true"
                >
                  <template #default="scope">
                    <el-input
                      clearable
                      v-model="scope.row.example"
                      placeholder="请输入"
                      maxlength="50"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="fieldDescription"
                  label="描述"
                   width="200"
                >
                  <template #default="scope">
                    <el-input
                      clearable
                      v-model="scope.row.fieldDescription"
                      placeholder="请输入描述"
                      maxlength="50"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="80" fixed="right">
                  <template #default="scope">
                    <el-popconfirm
                      title="删除后将无法恢复，请确认是否删除？"
                      confirm-button-type="danger"
                      icon="warning-filled"
                      icon-color="#F56C6C"
                      width="250"
                      placement="top-end"
                      @confirm="deleteApiResponseRow(scope.$index)"
                    >
                      <template #reference>
                        <el-button
                          icon="delete"
                          type="danger"
                          text
                          :disabled="scope.row.isDisable"
                        ></el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            
            <el-form-item label="返回结果示例">
             <span style="color:#606266;padding-right:20px;">是否作为Mock数据</span> 
             <el-radio-group
                  v-model="form.isMock">
                    <el-radio label="0">是</el-radio>
                    <el-radio label="1">否</el-radio>
              </el-radio-group>
              <CodeMirror  class="codemirror" v-model:value="form.apiResponseE"   ></CodeMirror>
            </el-form-item>
             <el-form-item label="Mock数据" v-if="form.isMock === '1'"> 
                  <CodeMirror  class="codemirror"  v-model:value="form.mockData"  ></CodeMirror>
            </el-form-item>
          </el-form>
  </div>
        </el-tab-pane>
       
      </el-tabs>
    </div>
    <div class="footer" align="right">
      <div class="btn">
        <el-button v-if="active !== '2' || activeHide" @click="cancel()"
          >取消</el-button
        >
        <el-button
          v-if="active === '2' && !activeHide"
          style="margin-top: 12px"
          @click="cancel()"
          >返回</el-button
        >
        <el-popconfirm
          title="取消迭代后将无法恢复，请确认是否取消？"
          width="250"
          placement="top-end"
          v-if="updateVersion === 'true'"
          @confirm="cancelIteration"
        >
          <template #reference>
            <el-button>取消迭代</el-button>
          </template>
        </el-popconfirm>
        <el-button
          v-if="
            (active !== '0' && active !== '2') || (active === '2' && activeHide)
          "
          @click="prev()"
          >上一步</el-button
        >
        <!-- <el-button
          type="primary"
          plain
          @click="handleSaveDraft"
          v-has="'hidden&apiManage.create.save'"
          v-if="
            !form.applyId &&
            activeHide &&
            (active === '0' ||
              (active !== '0' && active !== '1' && draft) ||
              (active === '1' && activeHide))
          "
          >暂存草稿</el-button
        > -->
        <el-button type="primary" @click="next()" v-if="active !== '1'"
          >下一步</el-button
        >
        
        <el-tooltip
          content="迭代正在审核中"
          placement="top-end"
          v-if="form.applyId"
        >
          <el-button type="info" plain>审核中</el-button>
        </el-tooltip>
        <el-button
          type="primary"
          v-if="!form.applyId && active === '1' && activeHide"
          v-has="'hidden&apiManage.create.save'"
          @click="saveApi('submit')"
          >提交</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { request } from "@/api";
import { ElMessage } from "element-plus";
import DataDiscovery from "@/components/DataDiscovery";
import useClipboard from 'vue-clipboard3'
import store from "@/store";
import { paramsTypesList,RequestList,resultDataBuildList } from '@/utils/constant'

import CodeMirror from '@/components/common/CodeMirror'
export default {
  name: "ApiManageCreate",
  components: {
    DataDiscovery,
    CodeMirror,
  },
  setup() {
    const height = window.innerHeight - 56 - 24 - 56 - 64 + 28;
    const route = useRoute();
    const router = useRouter();
    const apiId = route.query.apiId;
    const activePath = route.query.active;
    const updateVersion = ref(route.query.updateVersion);
    const changeFlag = route.query.changeFlag || "N";
    const isEdit = ref(route?.query.apiId ? true:false);
    const active = ref("0");
    const Requests = ref(RequestList)
    const resultDataBuilds = ref(resultDataBuildList)
    const page = reactive({
      pageNum: 1,
      pageSize: 10,
    });
    let functionList = ref([])
    const paramsTypes = ref(paramsTypesList)
    const total = ref(0); //总条数
    const show = ref(false);
    const saveData = ref(false);
    const urlFlag = ref(false);
    const fullBasic = ref(true);
    let activeHide = ref(true);
    const draft = ref(true);
    const loadFlag = ref(false);
    const changeSqlFlag = ref(false); // 监听sql语句是否改变
    let businessCateOptions = ref([]);
    let apiOwnerList = ref([])
    const tooltipOptions = reactive({
      effect: "light",
      popperClass: "my-tooltip-class",
    });

    let domain = ref('')
    const apiRealResponse = ref("");

    const ruleFormRef = ref(null);
    const ruleFormRef2 = ref(null);
    const ruleFormRef3 = ref(null);

    const headerList = reactive({
      list: [],
    });
    const testResultList = reactive({
      totalList: [],
      pageList: [],
    });

    let versionList = ref([
      {
        value: '1',
        label: '1',
        apiVersion:'1'
      },
    ]);

    let apiServicetypeList = ref([
      {
        value: "select",
        label: "查询服务",
      },
      {
        label: "写入服务",
        value: "insert",
      },
      {
        label: "修改服务",
        value: "update",
      },
      {
        label: "删除服务",
        value: "delete",
      },
    ]);
    
    
    let form = ref({
      isMock:'0',
      mockData:'',
      resultDataBuild:'',
      apiResponseE: null,
      resultData: [],
      dbType: "",
      apiServicetype: "",
      apiIssecurity: "Y",
      apiVersion: 1,
      businessCateId: [], //分类ID
      applyId: "", // api迭代审批时存在，迭代审批中时不允许修改迭代信息（不展示暂存草稿与提交按钮）
      apiId: null,
      apiName: "",
      apiRequestM: "1",
      apiUrl: "",
      apiOwner: localStorage.getItem('userName') + ' ' + localStorage.getItem('account'),
      copyApiOwner:localStorage.getItem('userId'),
      apiOwnerName:'',
      apiOwnerNumber:'',
      apiDescription: "",
      status: "",
      delFlag: "",
      apiCreateJson: "", // sql界面配置字符串
      apiCreateSql: "",
      dbId: "",
      tenantId: "",
      paramList: [],
      realParamList: [],
      fieldMappingList:[],
      userList: [],
      rqList: [],
      paramCategory: "",
      paramMap: {},
      editFlag: false,
      updateVersion: false,
      apiCreateM: "1",
      appId: null,
      categoryId: "",
      SqlConfigDto:{}
    });
    const validateUrl = (rule, value, callback) => {
      const reg = /((\/[a-zA-Z0-9_-]+){1,}(({[a-zA-Z0-9]+}){0,}))$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(
          new Error(
            "支持英文，数字，下划线，连接符（-），不超过200个字符，且只能以 / 开头, 以英文，数字结尾"
          )
        );
      }
    };
     const validateResultDataBuild = (rule, value, callback) => {
      if (!value && form.value.apiServicetype === 'select') {
        callback(
          new Error( "请选择返回数据结构")
        );
      } else {
        callback();
      }
    };
    const basicRules = reactive({
      apiName: [
        { required: true, message: "请输入名称", trigger: "blur" },
        {
          min: 1,
          max: 100,
          message: "长度在 1 到 100 个字符",
          trigger: "blur",
        },
      ],
      apiRequestM: [
        { required: true, message: "请选择请求方式", trigger: "change" },
      ],
      resultDataBuild: [
        { validator: validateResultDataBuild, trigger: ["blur","change"] },
      ],
      apiVersion: [
        { required: true, message: "请选择服务版本", trigger: "change" },
      ],
      businessCateId: [
        { required: true, message: "请选择分类", trigger: "change" },
      ],
      categoryId: [
        { required: true, message: "请选择挂载目录", trigger: "change" },
      ],
      apiUrl: [
        { required: true, message: "请输入接口路径", trigger: "blur" },
        {
          min: 1,
          max: 200,
          message: "长度在 1 到 200 个字符",
          trigger: "blur",
        },
        { validator: validateUrl, trigger: "blur" },
      ],
      apiOwner: [
        { required: true, message: "请选择责任人", trigger: "change" },
      ],
      apiServicetype: [
        { required: true, message: "请选择服务类型", trigger: "change" },
      ],
    });

    const tableData = reactive({
      list: [],
    });
    const paramCategoryOptions = ref([
      { label: "QueryParam", value: "2" },
      { label: "Body", value: "3", disabled: true },
      { label: "URLPath", value: "4", disabled: true },
    ]);

    const changeMethods = (val) => {
      paramCategoryOptions.value[1].disabled = val === "1";
    };
    const changePosition = (row) => {
      if (row.paramCategory == "3") {
        row.contentType = "json";
      } else {
        row.contentType = "";
      }
    };

    const addRow = () => {
      tableData.list.push({
        paramNameCm: "",
        paramName: "",
        paramValue: "",
        paramType: "",
        paramDescription: "",
        paramCategory: "",
        example:"",
        contentType: "",
        sort: 0,
        paramIsMust: "N",
        paramFun: "",
      }); //在tableData表格数组中添加对象
    };

    const addApiResponseRow = () => {
      form.value.fieldMappingList.push({
        apiFieldCnname: "",
        example:"",
        dbFieldName: "",
        dbFieldType: "",
        dbFieldLength: "",
        apiFieldName:"",
        apiFieldType: "",
        apiFieldLength: "",
        sort: 0,
        paramFun: "",
        fieldDescription: "",
        isEdit:true
      }); //在tableData表格数组中添加对象
      changeApiResponseE()
    };

    const deleteApiResponseRow = (index) => {
      // if (form.value.fieldMappingList.length > 1) {
      //   form.value.fieldMappingList.splice(index, 1);
      // }
      form.value.fieldMappingList.splice(index, 1);
    };

    const deleteRow = (index) => {
      // if (tableData.list.length > 1) {
      //   tableData.list.splice(index, 1);
      // } 
      tableData.list.splice(index, 1);
    };

    let sqlConfRef = ref(null);
    const setSqlConfigRef = (el) => {
      if (el) {
        sqlConfRef.value = el;
      }
      // if (el && (!sqlConfRef.value || loadFlag.value)) {
      //   sqlConfRef.value = el
      // }
    };

    
    const mappingRules = reactive({
      fieldName: [
        { required: true, message: "API结果别名", trigger: "blur" },
      ],
    });
    const changeApiResponseE = () => {
      // form.value.apiResponseE = JSON.stringify(form.value.fieldMappingList,null,2)
      form.value.resultData = JSON.stringify(form.value.fieldMappingList,null,2)
      
    };

    const paramData = reactive({
      list: [
        {
          paramId: "",
          apiId: "",
          paramName: "",
          paramValue: "",
          paramType: "",
          paramDescription: "",
          paramCategory: "",
          status: "",
        },
      ],
    });

    const getSqlConfigData = (form) => {
      const nodes = store.state.canvasData.nodes || [];
      if (sqlConfRef.value) {
        const { sqlVal, relRef, conditionsRef, fieldsRef, tabsValue } =
          sqlConfRef.value.mainContainer;

        const { dbId } = sqlConfRef.value.tableMenuRef.dbForm;
        form.value.dbId = dbId;

        if (tabsValue === "conf") {
          form.value.apiCreateSql = "";
          const { conditionData } = conditionsRef;
          const { selectData } = fieldsRef.tableData;
          const { inlineRelList } = relRef.state;
          form.value.SqlConfigDto = {
            tables: nodes.map((el) => {
              return {
                tableName: el.id,
                tableNameAlias: el.id,
                x: el.x,
                y: el.y,
              };
            }), // 选中表
            relation: inlineRelList || [],
            filter: conditionData.list || [],
            displayColumn: selectData || [],
          };
        } else {
          form.value.apiCreateSql = sqlVal;
        }
      } else if (form.value.apiCreateJson) {
        form.value.SqlConfigDto = { ...JSON.parse(form.value.apiCreateJson) };
      }
    };

    const formChange = (flag) => {
      fullBasic.value = true;
      flag ? setDynamicParams() : "";
    };

    const cancel = () => {
      if(route?.query?.listType === '3'){
        router.push('/home/mine/toDevelopServices');
      }else{
        router.push('/home/ApiManage/ApiManagelist');
      }
    };

    const submitForm = async (formEl) => {
      if (updateVersion.value) {
        // 迭代时不校验重复性
        let index = parseInt(active.value);
        active.value = (index + 1).toString();
        return;
      }
      await formEl.validate((valid) => {
        if (valid) {
          if (urlFlag.value == false) {
            request
              .checkUrlDuplicate({ apiUrl: form.value.apiUrl, apiId: form.value.apiId })
              .then((res) => {
                if (res.data.code == 500) {
                  ElMessage.error(res.data.msg);
                } else {
                  let index = parseInt(active.value);
                  active.value = (index + 1).toString();
                }
              });
          } else {
            let index = parseInt(active.value);
            active.value = (index + 1).toString();
          }
          fullBasic.value = false;
        }
      });
    };

  

    const submitForm2 = (index) => {
      const { dbId } = sqlConfRef.value.tableMenuRef.dbForm;
      const { sqlVal, tabsValue } = sqlConfRef.value.mainContainer;
      form.value.dbId = dbId;
      if (!dbId) {
        ElMessage.error("请选择数据源！");
      } else if (tabsValue === "conf") {
        if (
          form.value.resultData.length === 0 ||
          form.value.fieldMappingList.length === 0
        ) {
          ElMessage.error("返回结果不能为空！");
        } else {
          let idx = parseInt(active.value);
          index
            ? (active.value = index)
            : (active.value = (idx + 1).toString());
        }
      } else if (tabsValue === "inp") {
        const str = sqlVal
          .trim()
          .split("\n")
          .filter((el) => el.indexOf("-- ") !== 0 && el)
          .join("");
        if (!sqlVal || !str) {
          ElMessage.error("SQL不能为空！");
        } else if (!form.value.fieldMappingList.length) {
          ElMessage.error("返回结果不能为空！");
        } else {
          let idx = parseInt(active.value);
          index
            ? (active.value = index)
            : (active.value = (idx + 1).toString());
        }
        // else if (changeSqlFlag.value) {
        //   ElMessage.error("sql更新后请重新进行SQL校验！");
        // }
         
      }
    };

    const setSqlChangedFlag = (flag) => {
      changeSqlFlag.value = flag;
    };

    const setResultData = (list) => {
      if (form.value.fieldMappingList.length) {
        initForm(list || []);
      }
    };

    const setdbIdAndDbType = (obj)=>{
      form.value.dbId = obj.dbId
      form.value.dbType = obj.dbType
    }

    // sql校验 且 设置结果映射与返回结果示例
    const sqlValid = (sqlValidObj) => {
      const { sqlVal, tabsValue, fieldsRef } = sqlConfRef.value.mainContainer;
      if (tabsValue === "conf") {
        const { selectData } = fieldsRef.tableData;
        selectData.length === 0
          ? ElMessage.error("字段配置为空!")
          : initForm(selectData || []);
      } else {
        sqlFormatValid({
          ...sqlValidObj,
          sql:sqlValidObj.sql || sqlVal
        })
          .then((res) => {
            res = res.map((el) => {
              el.columnName = el.fieldName;
              el.fieldAlias = el.fieldNameAlias;
              return el;
            });
            initForm(res || []);
          })
          .catch((err) => {
            if (typeof err === "string") {
              // 避免与请求拦截器中的异常提示弹框冲突
              ElMessage.error(err || "SQL解析失败!");
            }
          });
      }
    };

    // 解析手动输入的SQL语句
    const sqlFormatValid = async (sqlValidObj) => {
      let sqlResonList = []; // 解析成功字段集合
      return await new Promise((resolve, reject) => {
        // 校验是否存在*号
        const str = sqlValidObj.sql
          .trim()
          .split("\n")
          .filter((el) => el.indexOf("-- ") !== 0 && el)
          .join("")
          .trim()
          .toLocaleLowerCase();
        const validFirst = str
          .substr(str.indexOf("select"))
          .substr(6)
          .trim()
          .charAt();
        if (validFirst === "*") {
          reject("不能使用*");
        } else {
          request
            .initApiSql({ apiCreateSql: sqlValidObj.sql, tarType:sqlValidObj.tarType, dbId:sqlValidObj.dbId })
            .then((res) => {
              if (res?.status === 200) {
                const { code, data, message } = res.data;
                if (code === 200) {
                  sqlResonList = data;
                  changeSqlFlag.value = false;
                  ElMessage.success("解析成功");
                  resolve(sqlResonList);
                } else {
                  reject(data || message || "SQL解析失败!");
                }
              } else {
                reject("SQL解析失败!");
              }
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    };

    const prev = () => {
      let index = parseInt(active.value);
      active.value = (index - 1).toString();
      // saveData.value = true;
    };

    const checkParamsName = () => {
      let flag = true;
      const nameList = tableData.list
        .map((el) => el.paramName)
        .filter((el) => !!el);
      const len = new Set(nameList);
      if (nameList.length !== len.size) {
        ElMessage.error("参数配置中参数名称不可重复，请检查！");
        flag = false;
      }
      return flag;
    };

    const next = () => {
      // let index = parseInt(active.value)
      if (active.value === "0") {
        if (!checkParamsName()) {
          return;
        }
        // active.value = (index+1).toString();
        submitForm(ruleFormRef.value);
      } else if (active.value == "1") {
        submitForm2();
        initForm4();
      }
    };

    

    const treeData = ref([]);
    // const treeToData = (tree) => {
    //   return tree.reduce((preResult, item) => {
    //     const { children, ...data } = item;
    //     return preResult.concat(
    //       data,
    //       children && children.length ? treeToData(children) : []
    //     );
    //   }, []);
    // };
    const getTreeData = () => {
      //查询列表
      request
        .getListTree({ appId: localStorage.getItem("appId"), filterText: "" })
        .then((res) => {
          if (res.data.code == 200) {
            // const list = treeToData([res.data.data]);
            // // 过滤categoryId为-1的项
            // treeData.value = (list || []).filter(
            //   (el) => el.categoryId !== "-1"
            // );
            treeData.value = res.data.data.children || []
          }
        });
    };
    if (router.currentRoute.value.query.id == "-1") {
      form.value.categoryId = "";
    } else {
      form.value.categoryId = router.currentRoute.value.query.id;
    }

    const initForm = (data) => {
      form.value.fieldMappingList = data;
      return;
    };

    const initForm4 = () => {
      var data = JSON.parse(JSON.stringify(tableData.list));
      paramData.list = data;
      apiRealResponse.value = null;
    };

    const getData = (params) => {
      store.dispatch("updataCanvasData", {
        nodes: [],
        edges: [],
      }); // 初始化拖拽数据
      if (typeof apiId == "undefined") {
        //新建
        return;
      }
      form.value.updateVersion = updateVersion.value;
      if (updateVersion.value == "true") {
        form.value.status = "draft";
        form.value.editFlag = false;
      } else {
        form.value.editFlag = true;
      }
      if (activePath === "1") {
        active.value = "1";
        activeHide.value = false;
      }
      request.getApiInfo(params).then((res) => {
        let result = res.data.data;
        form.value = {...result}
        form.value.businessCateId = [result.oneBusinessCateId, result.twoBusinessCateId];
        let paramList = result.paramList;
        tableData.list = paramList;
        paramData.list = paramList;
        form.value.apiResponseE = result.apiResponseE? result.apiResponseE: null
        form.value.copyApiOwner = result.apiOwner
        form.value.apiOwner = result.apiOwnerName + ' ' +result.apiOwnerNumber
        form.value.apiVersion = result.apiId
        findApiVersion(result.baseApiId)
        if (updateVersion.value == "true") {
          form.value.status = "draft";
        }
        if (form.value.status != "draft") {
          draft.value = true;
        }


        //从待我开发的服务--进入开发判断 待开发状态——————》开发中的状态
        if(route.query.listType == '3' && form.value.status === 'pending_developed'){
          form.value.status = 'developed'
          request.updateApiStatus({apiId,status:"developed",operationType:"3"})
        }

        form.value.apiCreateJson = result.apiCreateJson;
        initForm4();
        // 设置画布数据
        if (result.apiCreateJson) {
          setCanvasData(result.apiCreateJson);
        }
        loadFlag.value = !loadFlag.value;
      });
    };

    const setCanvasData = (jstr) => {
      const json = JSON.parse(jstr);

      const obj = {
        nodes: json.tables.map((el) => {
          return { id: el.tableName, label: el.tableName };
        }),
        edges: json.relation.map((el) => {
          return {
            source: el.fromTable,
            target: el.toTable,
            label:
              el.join === "INNER JOIN"
                ? "内联"
                : el.join === "LEFT JOIN"
                ? "左联"
                : "右联",
            x: el.x,
            y: el.y,
          };
        }),
      };
      store.dispatch("updataCanvasData", obj);
    };

    const saveApi = (type) => {

      if (!checkParamsName()) {
        return;
      }
      let params = [];
      params = tableData.list;
      form.value.paramList = params;
      if (updateVersion.value == "true") {
        // form.apiId = null;
        form.value.changeFlag = "Y"; // 迭代时，保存接口增加参数changeFlag="Y"
      }

      //后续记录操作日志
      if (!apiId) {
        form.value.operateType = "0";
      } else {
        form.value.operateType = "1";
        //
      }
      
      //api开发创建之后，状态是待发布
      if (form.value.status == "" || form.value.status == "developed") {
        form.value.status = "released";
      }

      form.value.appId = localStorage.getItem("appId");
      form.value.apiCreateM = "1";
      // 获取sql界面配置数据
      getSqlConfigData(form);
      const saveParams = {
        ...form.value,
        apiOwner:form.value.copyApiOwner,
        oneBusinessCateId: form.value.businessCateId[0],
        twoBusinessCateId: form.value.businessCateId[1],
        step: active.value.toString(),
        categoryId: form.value.categoryId,
      };
      if(apiId && (form.value.apiVersion !== versionList.value[versionList.value.length-1].value)){
        //服务版本转换
        saveParams.apiVersion = versionList.value.filter(item=>{
          return item.value == form.value.apiId
        })[0].apiVersion
      }
      request.saveApiBasicInfo(saveParams).then((resp) => {
        let respCode = resp.data.code;
        if (respCode == "200") {
          // 迭代状态中，如果点击提交，则提交成功后重新调用接口/submitApiChange
          if (type && type === "submit" && updateVersion.value == "true") {
            submitApiChange(saveParams);
          } else {
             router.push({
              path:"/home/ApiManage/ApiConfirmPage",
              query:{
                apiId: resp.data.data,
                listType:route?.query?.listType,
                apiType:'platform_api'
              }
             });
          }
        } else {
          ElMessage.error(resp.data.msg);
          // saveData.value = true;
        }
      });
    };

    // 提交迭代中新的API信息
    const submitApiChange = (saveParams) => {
      saveParams.apiId = route.query.apiId;
      request.submitApiChange(saveParams).then((res) => {
        let respCode = res?.data?.code;
        if (respCode == "200") {
          ElMessage.success(res?.data?.msg || "成功");
          router.push("/home/ApiManage/ApiManagelist");
        } else {
          ElMessage.error(res?.data?.data || "失败");
        }
      });
    };

    const getRequestMethods = () => {
      request.getLookupHead({ lookupHCode: "requestMethod" }).then((res) => {
        let a = res.data.data;
        form.value.rqList = a.details;
      });
    };

    const handleStep = (index) => {
      if (index === "2") {
        initForm4();
      }
      // if (active.value === '1' && index > '1') {
      //   submitForm2(index)
      // } else {
      //   active.value = index;
      // }
    };

   

    const setDynamicParams = () => {
      let list = tableData.list;
      // 取消列表中不可删除的状态, 与位置为urlPath的值
      list = list.map((el) => {
        el.isDisable ? delete el.isDisable : "";
        el.paramCategory === "4" ? (el.paramCategory = "") : "";
        return el;
      });
      // 解析url中的动态参数
      const dynamicList = form.value.apiUrl.match(/{[a-zA-Z0-9]+}/g);
      if (dynamicList) {
        const dynamicParams = dynamicList.map((el) => el.replace(/{|}/g, ""));
        // 将url中的动态参数添加或更新到参数配置列表中（如果已存在则不添加）
        dynamicParams.map((el) => {
          const existence = list.filter((item) => item.paramName === el);
          if (existence.length > 0) {
            existence[0].paramCategory = "4";
            existence[0].paramType = "2";
            existence[0].isDisable = true;
          } else {
            if (tableData.list.length === 1 && !tableData.list[0].paramName) {
              // 参数列表为空时直接赋值第一条数据
              tableData.list[0].paramName = el;
              tableData.list[0].paramCategory = "4";
              tableData.list[0].paramType = "2";
              tableData.list[0].isDisable = true;
            } else {
              tableData.list.push({
                //在tableData表格数组中添加对象
                paramName: el,
                paramValue: "",
                paramType: "2",
                paramDescription: "",
                paramCategory: "4",
                contentType: "",
                isDisable: true, // url 中动态参数不可删除
              });
            }
          }
        });
      }
    };
    const changeTabValid = async (activeName) => {
      let flag = true;
      let msg = "";
      // if (changeSqlFlag.value) {
      //   flag = false;
      //   msg = "sql更新后请重新进行SQL校验！";
      // }
      if (activeName === "0") {
        // 参数配置是否需要更新动态参数
        setDynamicParams();
      } else if (activeName === "2") {
        // 跳转到返回内容页签时，需要取数逻辑数据，校验取数逻辑是否设置
        const { sqlVal, tabsValue } = sqlConfRef.value.mainContainer;
        const { dbId } = sqlConfRef.value.tableMenuRef?.dbForm;
        form.value.dbId = dbId;
        if (!dbId) {
          // 表必选
          flag = false;
        } else if (tabsValue === "conf") {
          // sql界面配置
          // const selectData = fieldsRef?.tableData?.selectData || []
          form.value.resultData.length === 0 ? (flag = false) : "";
        } else {
          // SQL开发
          if (sqlVal) {
            // 校验SQL格式与字段
            if (!form.value.fieldMappingList) {
              flag = false;
            }
          } else {
            flag = false;
          }
        }
      }
      !flag ? ElMessage.error(msg || "请先完成取数逻辑配置！") : "";
      return flag;
    };

    // 取消迭代
    const cancelIteration = () => {
      request.changApiCancel({ apiId: route.query.apiId }).then((res) => {
        if (res?.data?.code === 200) {
          ElMessage.success(res?.data?.msg || "取消迭代成功");
          router.push("/home/ApiManage/ApiManagelist");
        } else {
          ElMessage.error("取消迭代失败");
        }
      });
    };

    const getBusinessCateOptions = () => {
      request
        .selectBusinessCatePage({
          currentPage: 1,
          pageSize: 10000,
        })
        .then((res) => {
          if (res?.data?.code === 200) {
            businessCateOptions.value = res.data.data;
          } else {
            ElMessage.error("失败");
          }
        });
    };
    const changebusinessCateId = (val) => {
      console.log(val, ">>>>changebusinessCateId");
    };

    const getApiCallUrl = ()=>{
      request.getApiCallUrl({
        apiType:'platform_api',
        apiUrl:'/test',
        status:'online'
      }).then((res) => {
            if (res?.data?.code === 200) {
                domain.value = res.data.data.domin + res.data.data.projectpath + res.data.data.urlprefix
            } else {
                ElMessage.error(res?.data?.msg);
            }
      });
    }

    const copyUrl = async ()=>{
       const {toClipboard} = useClipboard()
        try{
          await toClipboard(domain.value + form.value.apiUrl)
          ElMessage.success('复制成功');
        } catch (e){
          ElMessage.error('复制失败');
        }
    }

    const changeVersion = (val) =>{
        if(val === versionList.value[versionList.value.length-1].value) return
        getData({  apiId:val,apiUrl:form.value.apiUrl})
    }
    const findApiVersion = (baseApiId)=>{
      request.findApiVersion({
        baseApiId,
      }).then((res) => {
            if (res?.data?.code === 200) {
               if(res.data.data.length > 1){
                versionList.value = res.data.data.map((item,index)=>{
                  return {
                    value: item.apiId || item.apiVersion,
                    apiVersion:item.apiVersion,
                    label: res.data.data.length - 1 === index?item.apiVersion+ " (复刻版本)": item.apiRunStatus === 'Y'?item.apiVersion+ " (正式版本)" : item.apiVersion+ " (编辑版本)"
                  }
                })
              }
            } else {
                ElMessage.error(res?.data?.msg);
            }
      });
    }

    const changeApiOwner = (val) => {
      let user = apiOwnerList.value.filter((item) => {
        return item.userId === val;
      });
      if (user.length > 0) {
        form.value.apiOwnerName = user[0].userName;
        form.value.apiOwnerNumber = user[0].account;
      }
      form.value.copyApiOwner = val
    };

    const getOverallFunction = ()=>{
      request.getOverallFunction({
          currentPage: 1,
          pageSize: 100,
          
        }).then((res) => {
        if (res?.data?.code === 200) {
          functionList.value = res.data.data?.result || []
        } else {
          ElMessage.error('失败')
        }
      });
    }

    const getApiOwner = (query) => {
      if (query) {
        request
          .selectUserByParams({
            likeData: query,
            appId: localStorage.getItem("appId"),
            currentPage: 1,
            pageSize: 10000,
          })
          .then((res) => {
            if (res?.data?.code === 200) {
              apiOwnerList.value = res.data.data.result;
            }
          });
      } else {
        apiOwnerList.value = [];
      }
    };

    const querySearch = (queryString, cb) => {
      let results 
      let restaurants = functionList.value
      if(queryString && queryString !== 'null'){
          results = restaurants.filter(createFilter(queryString))
      }else{
          results = restaurants
      }
      
      cb(results)
    }

    const createFilter = (queryString) => {
      return (restaurant) => {
        return (
          restaurant.functionName.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
      }
    }

    

    onMounted(() => {
      getData({ apiId: apiId, changeFlag});
      getRequestMethods();
      getTreeData();
      getApiCallUrl()
      getBusinessCateOptions();
      getOverallFunction()
    });

    return {
      querySearch,
      getOverallFunction,
      functionList,
      isEdit,
      setdbIdAndDbType,
      apiOwnerList,
      getApiOwner,
      changeApiOwner,
      findApiVersion,
      changeVersion,
      Requests,
      resultDataBuilds,
      copyUrl,
      domain,
      getApiCallUrl,
      changebusinessCateId,
      versionList,
      apiServicetypeList,
      paramsTypes,
      businessCateOptions,
      changeSqlFlag,
      updateVersion,
      height,
      active,
      activeHide,
      draft,
      ruleFormRef,
      ruleFormRef2,
      ruleFormRef3,
      apiRealResponse,
      tooltipOptions,
      urlFlag,
      loadFlag,
      testResultList,
      headerList,
      page,
      total,
      show,
      saveData,
      fullBasic,
      form,
      basicRules,
      tableData,
      paramCategoryOptions,
      changePosition,
      addRow,
      deleteRow,
      sqlConfRef,
      setSqlConfigRef,
      mappingRules,
      changeApiResponseE,
      paramData,
      formChange,
      cancel,
      submitForm,
      submitForm2,
      prev,
      next,
      getData,
      saveApi,
      getRequestMethods,
      handleStep,
      getTreeData,
      treeData,
      changeTabValid,
      changeMethods,
      cancelIteration,
      sqlValid,
      setSqlChangedFlag,
      setResultData,
      addApiResponseRow,
      deleteApiResponseRow,
    };
  },
  
};
</script>

<style lang="less" scoped>
.create-step {
  height: 100%;
  // padding: 20px;
  // background: #fff;
  .box-card-radius-eight {
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .tabs {
    .table-btn__up {
      // margin-bottom: 12px;
    }
    & /deep/ .el-tabs__content {
      height: 100%;
      // overflow-y: auto;
    }
  }
  .sql-nodata {
    display: flex;
    flex-direction: column;
    align-items: center;
    .nodata-title {
      font-size: 14px;
      color: #4e5156;
      padding-bottom: 12px;
    }
  }
}
.footer {
  margin-top: 24px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 64px;
  line-height: 64px;
  background: #fff;
  z-index: 10;
  .btn {
    padding-right: 24px;
  }
}
</style>
