
import API from "@/plugins/axiosInstance";
let baseUrl =  'iods_gateway/iodsBaseAdmin/'


const about = ()=>{
  return API({
      url: baseUrl + 'license/details',
      method: 'post'
    })
}

const getDatasourceinfo = (params)=>{
    return API({
        url: baseUrl + `ds/datasourceinfo/info/${params.id}`,
        method: 'get',
      })
}

const selectAppById = (params)=>{
    return API({
        url: baseUrl + `app/selectAppById/${params.appId}`,
        method: 'get',
      })
}

const getLookupHead = (params)=>{
    return API({
        url: baseUrl + 'lookup/getLookupHead',
        method: 'get',
        params
      })
}

const selectUser1 = ()=>{
    return API({
        url: baseUrl + 'user/selectUser1',
        method: 'get'
      })
}

const selectApp1 = (params)=>{
    return API({
        url: baseUrl + 'app/selectApp1',
        method: 'get',
        params
      })
}



const getAuthorization = (params)=>{
    return API({
        url: baseUrl + 'mall/authorization',
        method: 'post',
        data:params
      })
}

const searchApiDbList = ()=>{
    return API({
        url: baseUrl + 'ds/datasourceinfo/searchApiDbList',
        method: 'post',
        data:{}
      })
}

const searchTableInfoList = (params)=>{
    return API({
        url: baseUrl + 'ds/datasourceinfo/searchTableInfoList',
        method: 'post',
        data:params
      })
}

const  deleteWithRef = (params)=>{
    return API({
        url: baseUrl + 'ds/datasourceinfo/deleteWithRef',
        method: 'post',
        data: [params.nodeKey]
      })
}

const  deleteDatasource = (params)=>{
    return API({
        url: baseUrl + 'ds/category/datasource/delete',
        method: 'post',
        data: [params.nodeKey]
      })
}

const  saveDatasource = (params)=>{
    return API({
        url: baseUrl + 'ds/category/datasource/save',
        method: 'post',
        data:params
      })
}

const renameDatasource = (params)=>{
    return API({
        url: baseUrl + 'ds/category/datasource/rename',
        method: 'post',
        data:params
      })
}

const moveDatasourceinfo = (params)=>{
    return API({
        url: baseUrl + 'ds/datasourceinfo/move',
        method: 'post',
        data:params
      })
}

const selectUser = ()=>{
    return API({
        url: baseUrl + 'user/selectUser',
        method: 'get'
      })
}

const issuePass = (params)=>{
    return API({
        url: baseUrl + 'audit/issuePass',
        method: 'get',
        params
      })
}

const issueRefuse = (params)=>{
    return API({
        url: baseUrl + 'audit/issueRefuse',
        method: 'post',
        data:params
      })
}

const applyList = (params)=>{
    return API({
        url: baseUrl + 'audit/applyList',
        method: 'post',
        data:params
      })
}

const pendingList = (params)=>{
    return API({
        url: baseUrl + 'audit/pendingList',
        method: 'post',
        data:params
      })
}

const processedList = (params)=>{
    return API({
        url: baseUrl + 'audit/processedList',
        method: 'post',
        data:params
      })
}

const recall = (params)=>{
    return API({
        url: baseUrl + 'audit/recall',
        method: 'post',
        data:params
      })
}

const deleteAudit = (params)=>{
    return API({
        url: baseUrl + 'audit/delete',
        method: 'post',
        data:params
      })
}

const searchApprovalNode = (params)=>{
    return API({
        url: baseUrl + 'audit/searchApprovalNode',
        method: 'post',
        data:params
      })
}

const selectAuth = (params)=>{
    return API({
        url: baseUrl + 'auth/selectAuth',
        method: 'get',
        params
      })
}

const delAuth = (params)=>{
    return API({
        url: baseUrl + 'auth/delAuth',
        method: 'post',
        data:params
      })
}

const addAuth = (params)=>{
    return API({
        url: baseUrl + 'auth/addAuth',
        method: 'post',
        data:params
      })
}

const updateAuth = (params)=>{
    return API({
        url: baseUrl + 'auth/updateAuth',
        method: 'post',
        data:params
      })
}

const selectRole = (params)=>{
    return API({
        url: baseUrl + 'role/selectRole',
        method: 'get',
        params
      })
}

const delRole = (params)=>{
    return API({
        url: baseUrl + 'role/delRole',
        method: 'post',
        data:params
      })
}

const addRole = (params)=>{
    return API({
        url: baseUrl + 'role/addRole',
        method: 'post',
        data:params
      })
}

const updateRole = (params)=>{
    return API({
        url: baseUrl + 'role/updateRole',
        method: 'post',
        data:params
      })
}

const getAuthTree = ()=>{
    return API({
        url: baseUrl + 'auth/getAuthTree',
        method: 'get'
      })
}

const getAuthByRoleId = (params)=>{
    return API({
        url: baseUrl + 'role/getAuthByRoleId',
        method: 'get',
        params
      })
}

const clearRoleAuth = (params)=>{
    return API({
        url: baseUrl + 'role/clearRoleAuth',
        method: 'post',
        params
      })
}

const saveRoleAuth = (params)=>{
    return API({
        url: baseUrl + 'role/saveRoleAuth',
        method: 'post',
        data:params
      })
}

const selectTenant = (params)=>{
    return API({
        url: baseUrl + 'tenant/selectTenant',
        method: 'get',
        params,
        timeout: 1000
      })
}

const selectUserByParams = (params)=>{
    return API({
        url: baseUrl + 'user/selectUser',
        method: 'get',
        params,
        timeout: 1000
      })
}

const removeUserAppId = (params)=>{
    return API({
        url: baseUrl + 'user/removeUserAppId',
        method: 'get',
        params
      })
}

const updateTenant = (params)=>{
    return API({
        url: baseUrl + 'tenant/updateTenant',
        method: 'post',
        data:params,
        timeout: 1000
      })
}

const delTenant = (params)=>{
    return API({
        url: baseUrl + 'tenant/delTenant',
        method: 'post',
        data:params,
        timeout: 1000
      })
}

const updateUserInfo = (params)=>{
    return API({
        url: baseUrl + 'user/updateUserInfo',
        method: 'post',
        data:params,
        timeout: 1000
      })
}

const selectApp = (params)=>{
    return API({
        url: baseUrl + 'app/selectApp',
        method: 'get',
        params
      })
}

const saveUser = (params)=>{
    return API({
        url: baseUrl + 'user/saveUser',
        method: 'post',
        data:params
      })
}

const deleteUserInfo = (params)=>{
    return API({
        url: baseUrl + 'user/deleteUserInfo',
        method: 'post',
        data:params
      })
}

const saveLookupHead = (params)=>{
    return API({
        url: baseUrl + 'lookup/saveLookupHead',
        method: 'post',
        data:params
      })
}

const saveLookupDetail = (params)=>{
    return API({
        url: baseUrl + 'lookup/saveLookupDetail',
        method: 'post',
        data:params
      })
}

const selectLookupHead = (params)=>{
    return API({
        url: baseUrl + 'lookup/selectLookupHead',
        method: 'get',
        params
      })
}

const authenticationAuthorization = (params)=>{
    return API({
        url: baseUrl + 'app/authenticationAuthorization',
        method: 'post',
        data:params
      })
}

const insertApp = (params)=>{
    return API({
        url: baseUrl + 'app/insertApp',
        method: 'post',
        data:params
      })
}

const updateApp = (params)=>{
    return API({
        url: baseUrl + 'app/updateApp',
        method: 'post',
        data:params
      })
}

const deleteApp = (params)=>{
    return API({
        url: baseUrl + 'app/deleteApp',
        method: 'post',
        data:params
      })
}

const getAlarmConfig = ()=>{
    return API({
        url: baseUrl + `alarm/getConfig?appId=${localStorage.getItem("appId")}`,
        method: 'get'
      })
}

const updateAlarmConfig = (params)=>{
    return API({
        url: baseUrl + 'alarm/updateConfig',
        method: 'post',
        data:params
      })
}

const logout = (params)=>{
    return API({
        url: baseUrl + 'user/logout',
        method: 'get',
        params
      })
}

const selectUserAppInfos = (params)=>{
    return API({
        url: baseUrl + 'app/selectUserAppInfos',
        method: 'get',
        params
      })
}

const selectRolesByUserId = (params)=>{
    return API({
        url: baseUrl + 'role/selectRolesByUserId',
        method: 'get',
        params,
      })
}

const switchApp = (params)=>{
    return API({
        url: baseUrl + 'app/switchApp',
        method: 'get',
        params
      })
}

const switchRoles = (params)=>{
    return API({
        url: baseUrl + 'role/switchRoles',
        method: 'get',
        params
      })
}


const updateUserOneself = (params)=>{
    return API({
        url: baseUrl + 'user/updateUserOneself',
        method: 'post',
        data:params
      })
}

const getApplicationByNo = (params)=>{
    return API({
        url: baseUrl + 'approve/getApplicationByNo',
        method: 'get',
        params
      })
}

const updateApprovalInfo = (params)=>{
    return API({
        url: baseUrl + 'approve/updateApprovalInfo',
        method: 'post',
        data:params
      })
}

const getMyApplications = (params)=>{
    return API({
        url: baseUrl + 'approve/getMyApplications',
        method: 'get',
        params
      })
}


const getWaitingForList = (params)=>{
    return API({
        url: baseUrl + 'approve/getWaitingForList',
        method: 'get',
        params
      })
}

const getMyApprovalList = (params)=>{
    return API({
        url: baseUrl + 'approve/getMyApprovalList',
        method: 'get',
        params
      })
}

const getWaitingCount = ()=>{
    return API({
        url: baseUrl + 'approve/getWaitingCount',
        method: 'get'
      })
}

const getDatasourceTree = (params)=>{
    return API({
        url: baseUrl + 'ds/category/getDatasourceTree',
        method: 'get',
        params
      })
}

const login = (params)=>{
    return API({
        url: baseUrl + 'user/login',
        method: 'post',
        data:params
      })
}

const loginByHeader = (params)=>{
    return API({
        url: baseUrl + 'user/login',
        method: 'post',
        data:params,
        
      })
}

const ssoStToken = (params)=>{
    return API({
        url: baseUrl + 'sso/ssoStToken',
        method: 'get',
        params
      })
}

const setWeportalToken = (params)=>{
    return API({
        url: baseUrl + 'ssoWeportal/setWeportalToken',
        method: 'post',
        data:params,
      })
}

const queryModelNode = (params)=>{
    return API({
        url: baseUrl + 'modelConf/queryModelNode',
        method: 'post',
        data:params
      })
}

const publicModel = (params)=>{
    return API({
        url: baseUrl + 'modelConf/publicModel',
        method: 'get',
        params
      })
}

const getMenuListByRoleId = (params)=>{
    return API({
        url: baseUrl + `role/getMenuListByRoleId`,
        method: 'get',
        params
      })
}

const findMenuList = (params)=>{
    return API({
        url: baseUrl + 'menu/findMenuList',
        method: 'post',
        data: params
      })
}

const addMenu = (params)=>{
    return API({
        url: baseUrl + 'menu/addMenu',
        method: 'post',
        data: params
      })
}

const findMenuById = (params)=>{
    return API({
        url: baseUrl + 'menu/findMenuById',
        method: 'post',
        data: params
      })
}

const updateMenu = (params)=>{
    return API({
        url: baseUrl + 'menu/updateMenu',
        method: 'post',
        data: params
      })
}

const deleteMenu = (params)=>{
    return API({
        url: baseUrl + 'menu/delete',
        method: 'post',
        data: params
      })
}

const updateMenuStatus = (params)=>{
    return API({
        url: baseUrl + 'menu/updateMenuStatus',
        method: 'post',
        data: params
      })
}

const connectV2 = (params)=>{
    return API({
        url: baseUrl + 'ds/datasourceinfo/connectV2',
        method: 'post',
        data:params
      })
}

const addDatasourceinfo = (params)=>{
    return API({
        url: baseUrl + 'ds/datasourceinfo/add',
        method: 'post',
        data:params
      })
}

const updateUserRolelist = (params)=>{
    return API({
        url: baseUrl + 'user/updateUserRoleList',
        method: 'post',
        data:params
      })
}

const assignRolesByUserId = (params)=>{
    return API({
        url: baseUrl + 'role/assignRolesByUserId',
        method: 'get',
        params
      })
}

const updateDatasourceinfo = (params)=>{
    return API({
        url: baseUrl + 'ds/datasourceinfo/update',
        method: 'post',
        data:params
      })
}

const queryModelNodePage = (params)=>{
    return API({
        url: baseUrl + 'modelConf/queryModelNodePage',
        method: 'get',
        params
      })
}
const findModelNodeDetails = (params)=>{
    return API({
        url: baseUrl + 'modelConf/findModelNodeDetails',
        method: 'get',
        params
      })
}

const saveModelNode = (params)=>{
    return API({
        url: baseUrl + 'modelConf/save',
        method: 'post',
        data: params
      })
}

const deleteModelNode = (params)=>{
    return API({
        url: baseUrl + 'modelConf/delete',
        method: 'get',
        params
      })
}

const queryRole = (params)=>{
    return API({
        url: baseUrl + 'modelConf/queryRole',
        method: 'get',
        params
      })
}

const getKeyList = (params)=>{
    return API({
        url: baseUrl + 'secret/pageList',
        method: 'get',
        params
      })
  }
  
  const addOrUpdateSecret = (params)=>{
    return API({
        url: baseUrl + 'secret/addOrUpdate',
        method: 'post',
        data: params
      })
  }  

  const querySecretKeyById = (params)=>{
    return API({
        url: baseUrl + 'secret/querySecretKeyById',
        method: 'post',
        data: params
      })
  }

  const querySecretKeyByCode = (params)=>{
    return API({
        url: baseUrl + 'secret/querySecretKeyByCode',
        method: 'post',
        data: params
      })
  }

  const deleteSecretKey = (params)=>{
    return API({
        url: baseUrl + 'secret/deleteSecretKey',
        method: 'post',
        data: params
      })
  }

  const updateStatus = (params)=>{
    return API({
        url: baseUrl + 'secret/updateStatus',
        method: 'post',
        data: params
      })
  }
export const baseApi = {
    about,
    updateStatus,
    deleteSecretKey,
    querySecretKeyByCode,
    querySecretKeyById,
    addOrUpdateSecret,
    getKeyList,
    removeUserAppId,
    deleteWithRef,
    deleteDatasource,
    saveDatasource,
    renameDatasource,
    moveDatasourceinfo,
    setWeportalToken,
    queryRole,
    deleteModelNode,
    saveModelNode,
    findModelNodeDetails,
    queryModelNodePage,
    updateDatasourceinfo,
    assignRolesByUserId,
    updateUserRolelist,
    connectV2,
    addDatasourceinfo,
    getDatasourceinfo,
    selectAppById,
    getLookupHead,
    selectUser1,
    selectApp1,
    getAuthorization,
    searchApiDbList,
    searchTableInfoList,
    selectUser,
    issuePass,
    issueRefuse,
    applyList,
    pendingList,
    processedList,
    recall,
    deleteAudit,
    searchApprovalNode,
    selectAuth,
    delAuth,
    addAuth,
    updateAuth,
    selectRole,
    delRole,
    addRole,
    updateRole,
    getAuthTree,
    getAuthByRoleId,
    clearRoleAuth,
    saveRoleAuth,
    selectTenant,
    selectUserByParams,
    updateTenant,
    delTenant,
    updateUserInfo,
    selectApp,
    saveUser,
    deleteUserInfo,
    saveLookupHead,
    saveLookupDetail,
    selectLookupHead,
    authenticationAuthorization,
    insertApp,
    updateApp,
    deleteApp,
    getAlarmConfig,
    updateAlarmConfig,
    logout,
    selectUserAppInfos,
    selectRolesByUserId,
    switchApp,
    switchRoles,
    updateUserOneself,
    getApplicationByNo,
    updateApprovalInfo,
    getMyApplications,
    getWaitingForList,
    getMyApprovalList,
    getWaitingCount,
    getDatasourceTree,
    login,
    loginByHeader,
    ssoStToken,
    queryModelNode,
    publicModel,
    updateMenuStatus,
    deleteMenu,
    updateMenu,
    findMenuById,
    addMenu,
    findMenuList,
    getMenuListByRoleId,
}