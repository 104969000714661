<template>
  <div
    class="policy-page box-card-radius-eight white-bg"
  >
    <div align="left" class="margin-botton-12">
      <el-button @click="addPolicy" type="primary">新增策略</el-button>
    </div>
    <el-table :data="tableData.list" stripe row-key="userId">
      <el-table-column
        prop="policyName"
        label="策略名称"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="description"
        label="策略描述"
        :show-overflow-tooltip="true"
      >
        <template #default="scope">
          {{scope.row.description || '-'}}
        </template>
      </el-table-column>
      <el-table-column
        prop="isLimitActive"
        label="是否限流"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="limitPolicy"
        label="限流配置"
        :formatter="formatConfig"
        :show-overflow-tooltip="true"
      >
      
      </el-table-column>
      <el-table-column
        prop="blackList"
        label="黑名单"
        :show-overflow-tooltip="true"
      >
       <template #default="scope">
          {{scope.row.blackList || '-'}}
        </template>
      </el-table-column>
      <el-table-column
        prop="whiteList"
        label="白名单"
        :show-overflow-tooltip="true"
      >
        <template #default="scope">
          {{scope.row.whiteList || '-'}}
        </template>
      </el-table-column>
      <el-table-column
        prop="updateTime"
        label="最后更新时间"
        :formatter="formatDateTime"
      >
      </el-table-column>
      <el-table-column prop="updatedByUserName" label="最后更新人"> 
        <template #default="scope">
          {{scope.row.updatedByUserName || '-'}}
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="操作" width="180" fixed="right">
        <template #default="scope">
          <el-space>
            <el-button size="medium" type="primary" icon="Edit"   link @click="handleEdit(scope.row)"></el-button>
            <el-button size="medium" icon="delete" link type="danger" @click="handleDelete(scope.row)"></el-button>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
       <el-pagination background small
       style="float:right;"
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      v-model="dialogVisible"
      :title="title"
      width="40%"
      class="policy-dialog"
    >
      <el-form
        ref="ruleFormRef"
        :rules="validateForm"
        :model="formData"
        label-width="250px"
        label-position="right"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item prop="policyName" label="安全策略配置名称">
              <el-input v-model="formData.policyName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item prop="description" label="安全策略配置描述">
              <el-input
                v-model="formData.description"
                maxlength="1000"
                show-word-limit
                type="textarea"
                :rows="4"
                @change="formChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item prop="isLimitActive" label="是否启用限流">
              <el-radio-group v-model="formData.isLimitActive">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" v-if="formData.isLimitActive == '1'">
          <el-col :span="24">
            <el-form-item prop="num" label="窗口阈值">
              <el-input-number
                v-model="formData.num"
                :min="1"
                :max="1000"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" v-if="formData.isLimitActive == '1'">
          <el-col :span="24">
            <el-form-item prop="type" label="窗口时间">
              <el-select v-model="formData.type" style="width: 100%">
                <el-option key="86400000" value="86400000" label="天" />
                <el-option key="3600000" value="3600000" label="小时" />
                <el-option key="60000" value="60000" label="分钟" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item prop="isListActive" label="是否启用黑白名单">
              <el-radio-group v-model="formData.isListActive">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" v-if="formData.isListActive == '1'">
          <el-col :span="24">
            <el-form-item prop="blackList" label="黑名单">
              <el-input
                v-model="formData.blackList"
                maxlength="1000"
                show-word-limit
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" v-if="formData.isListActive == '1'">
          <el-col :span="24">
            <el-form-item prop="whiteList" label="白名单">
              <el-input
                v-model="formData.whiteList"
                maxlength="1000"
                show-word-limit
                type="textarea"
                :rows="4"
                @change="formChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="save(ruleFormRef)">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ElMessageBox, ElMessage } from "element-plus";
import {request} from '@/api'
import { reactive, ref } from "vue";
export default {
  name: "Policy",
  setup() {
    const title = ref("");
    const ruleFormRef = ref(null);
    const formData = reactive({
      policyName: "",
      description: "",
      num: "",
      isLimitActive: "0",
      whiteList: "",
      blackList: "",
      sendManagerFlag: "",
      sendUserList: "",
      appId: localStorage.getItem("appId"),
      isListActive: "0",
      type: "",
      id: "",
    });
    const page = reactive({
      //配置对应的查询参数
      pageNum: 1,
      pageSize: 10,
      total: 0,
    });
    const tableData = reactive({
      list: [],
      user: [],
    });
    const selectedRows = ref([]);
    const selectChange = (selects) => {
      selectedRows.value = selects;
    };
    const formatDateTime = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return '-';
      }
      let dt = new Date(data);
      let y = dt.getFullYear();
      let m = (dt.getMonth() + 1 + "").padStart(2, "0");
      let d = (dt.getDate() + "").padStart(2, "0");
      let hh = (dt.getHours() + "").padStart(2, "0");
      let mm = (dt.getMinutes() + "").padStart(2, "0");
      let ss = (dt.getSeconds() + "").padStart(2, "0");
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
    };
    const formatConfig = (row, column) => {
      let data = row[column.property];
      if (data == null||!data) {
        return '-';
      }
      var config = JSON.parse(data);
      var text = "";
      for (var key in config) {
        if (key == "60000") {
          text = config[key] + "次/分钟";
        } else if (key == "3600000") {
          text = config[key] + "次/小时";
        } else {
          text = config[key] + "次/天";
        }
      }
      return text;
    };
    const getList = () => {
      request.selectApiPolicyConfigByPage({
          appId: localStorage.getItem("appId"),
          pageVO: {
            currentPage: page.pageNum,
            pageSize: page.pageSize,
          },
        }).then((resp) => {
        tableData.list = resp.data.data.result;
        page.total = resp.data.data.pageVO.total;
      });
    };
    const validateForm = reactive({
      policyName: [
        { required: true, message: "请输入安全配置策略名称", trigger: "blur" },
      ],
      isLimitActive: [
        { required: true, message: "请选择是否启用限流", trigger: "blur" },
      ],
      isListActive: [
        { required: true, message: "请选择是否启用黑名单", trigger: "blur" },
      ],
      num: [{ required: true, message: "请输入窗口阈值", trigger: "blur" }],
      type: [{ required: true, message: "请选择窗口时间", trigger: "blur" }],
    });
    const handleSizeChange = (val) => {
      //size修改
      page.pageSize = val;
      getList();
    };
    const handleCurrentChange = (val) => {
      //num修改
      page.pageNum = val;
      getList();
    };
    const save = async (formEl) => {
      await formEl.validate(async (valid) => {
        if (valid) {
          const limitPolicy = {};
          limitPolicy[formData.type] = formData.num;
          let params = {
              policyName: formData.policyName,
              description: formData.description,
              isLimitActive: formData.isLimitActive,
              isListActive: formData.isListActive,
              whiteList: formData.whiteList,
              blackList: formData.blackList,
              appId: localStorage.getItem("appId"),
              limitPolicy:
                formData.isLimitActive == "0"
                  ? ""
                  : JSON.stringify(limitPolicy),
              policyType: "template",
              id: title.value == "新增策略" ? "" : formData.id,
            }

          let res 

          if(title.value == "新增策略"){
            res = await request.saveApiPolicyConfig(params)
          }else{
            res = await request.updateApiPolicyConfig(params)
          }

          if (res.data.code == 200) {
              formData.policyName = "";
              formData.description = "";
              formData.isLimitActive = "";
              formData.isListActive = "";
              formData.whiteList = "";
              formData.blackList = "";
              ElMessage.success(
                title.value == "新增策略" ? "保存成功" : "编辑成功"
              );
              dialogVisible.value = false;
              getList();
          }else{
            ElMessage.error(res.data.msg);
          }
        }
      });
    };
    getList();
    const dialogVisible = ref(false);
    const addPolicy = () => {
      title.value = "新增策略";
      formData.policyName = "";
      formData.description = "";
      formData.isLimitActive = "0";
      formData.isListActive = "0";
      formData.whiteList = "";
      formData.blackList = "";
      formData.num = 1;
      formData.type = "";
      dialogVisible.value = true;
    };
    const handleDelete = (row) => {
      request.selectApiByPolicy({id:row.id}).then((res) => {
        if (res.data.code == 200  && (!res.data.data||res.data.data.length == 0)) {
          ElMessageBox.confirm("确定删除 " + row.policyName + " 吗？")
            .then(() => {
              request.deleteApiPolicyConfig({id:row.id}).then((res) => {
                if (res.data.code == 200) {
                  ElMessage.success("删除成功");
                  getList();
                }
              });
            })
            .catch(() => {});
        }else{
           ElMessage.error("该策略下有配置的API,不允许删除");
        }
      });
    };
    const handleEdit = (row) => {
      title.value = "编辑策略";
      getConfigInfo(row);
    };
    const getConfigInfo = (row) => {
      request.getApiPolicyConfigById({id:row.id}).then((res) => {
        if (res.data.code == 200) {
          dialogVisible.value = true;
          if (res.data.data.limitPolicy) {
            var limitPolicy = JSON.parse(res.data.data.limitPolicy);
            formData.num = Object.values(limitPolicy)[0];
            formData.type = Object.keys(limitPolicy)[0];
          } else {
            formData.num = 1;
            formData.type = "";
          }
          formData.policyName = res.data.data.policyName;
          formData.description = res.data.data.description;
          formData.isLimitActive = res.data.data.isLimitActive;
          formData.isListActive = res.data.data.isListActive;
          formData.whiteList = res.data.data.whiteList;
          formData.blackList = res.data.data.blackList;
          formData.id = res.data.data.id;
        }
      });
    };
    //getConfigInfo();
    return {
      formatConfig,
      formatDateTime,
      handleEdit,
      getConfigInfo,
      ruleFormRef,
      handleDelete,
      addPolicy,
      dialogVisible,
      formData,
      validateForm,
      tableData,
      getList,
      selectedRows,
      selectChange,
      page,
      handleSizeChange,
      handleCurrentChange,
      save,
      title,
    };
  },
};
</script>

<style scoped>
.policy-page >>> .el-form-item__label,
.policy-page >>> .el-radio__label,
.policy-page >>> .el-checkbox__label {
  font-size: 18px !important;
}
.policy-page >>> .el-dialog__body {
  padding-right: 90px;
}
</style>