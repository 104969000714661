<template>
  <div
    style="
      text-align: left;
      height: calc(100% - 28px);
      width: 100%;
      padding-left: 0px;
      padding-top: 14px;
      margin-left: 0px;
      margin-top: 0px;
    "
  >
  <div class="search"> 
        <el-input
              class="input-transparent"
              v-model="packageName"
              placeholder="请输入包名称"
              clearable
              style="margin-bottom: 24px;margin-left: 24px;width:40%;"
              @keyup.enter="reloadTable"
            >
              <template #suffix>
                <el-icon class="icon-search" @click="reloadTable"
                  ><Search
                /></el-icon>
              </template>
        </el-input>
  </div>
     <div class="white-bg box-card-radius-eight" style="overflow:hidden;">
      <div style="text-align: left; margin-bottom: 20px">
        
       <el-button
          type="primary"
          @click="handleUpload()"
        >
          <el-icon :size="20">
            <Upload /> </el-icon
          >上传编排组件包
        </el-button>
      </div>
       <el-table :data="tableData" stripe style="width: 100%">
         <el-table-column
          prop="no"
          label="序号"
          width="60"
          align="center"
          type="index"
        />
        <el-table-column
          prop="packageName"
          label="包名称"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="packageJarName"
          label="jar包名称"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="createdBy"
          label="创建人"
          :show-overflow-tooltip="true"
        >
         <template #default="scope">
        {{
          scope.row.createUserName + ' ' + scope.row.createUserNumber
        }}
         </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="最后修改时间"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="updatedBy"
          label="最后修改人"
          :show-overflow-tooltip="true"
        >
        <template #default="scope">
        {{
          scope.row.updateUserName ? scope.row.updateUserName + ' ' + scope.row.updateUserNumber : "-"
        }}
         </template>
        </el-table-column>
         <el-table-column
          prop="operation"
          label="操作"
          width="180"
          fixed="right"
        >
          <template #default="scope">
           <el-divider
              direction="vertical"
            />
            <el-tooltip  content="编排组件类管理">
              <el-button
                size="medium"
                icon="Connection"
                type="primary"
                link
                @click="handleConnect(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip  content="编辑">
              <el-button
                size="medium"
                icon="Edit"
                type="primary"
                link
                @click="handleEdit(scope.row)"
                
              ></el-button>
            </el-tooltip>
            <el-divider
              direction="vertical"
            />
            <el-tooltip  content="删除">
              <el-button
                size="medium"
                icon="delete"
                type="danger"
                link
                @click="handleDelete(scope.row)"
              ></el-button>
            </el-tooltip>
             <el-divider
              direction="vertical"
            />
            <el-tooltip  content="下载">
              <el-button
                size="medium"
                icon="download"
                type="primary"
                link
                @click="handleDownload(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
       </el-table>
       <!--分页组件-->
      <div style="padding: 10px 0; float: right;">
        <el-pagination
          background
          small
          :current-page="pageVO.currentPage"
          :page-size="pageVO.pageSize"
          :page-sizes="[2, 5, 10, 20]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageVO.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <apiCodePluginsDrawer ref="apiCodePluginsDrawerRef" @reloadTable="reloadTable"/>
    <apiCodePluginsUpload ref="apiCodePluginsUploadRef" @reloadTable="reloadTable"/>

    
  </div>
</template>
<script>
import {  ref,onMounted } from "vue";
// import {useRouter} from 'vue-router'
import { request } from "@/api";
import {ElMessageBox,ElMessage } from "element-plus";
import { WarningFilled } from "@element-plus/icons-vue";
import apiCodePluginsDrawer from './apiCodePluginsDrawer'
import apiCodePluginsUpload from './apiCodePluginsUpload'

export default {
  name: "ApiCodePluginsList",
  components:{
    apiCodePluginsDrawer,
    apiCodePluginsUpload
  },
  setup() {
    // let router = useRouter()
    let apiCodePluginsDrawerRef = ref(null)
    let apiCodePluginsUploadRef = ref(null)
    let tableData = ref([])
    let packageName = ref(null)
    let pageVO = ref({
      pageSize:10,
      currentPage:1,
      total:0
    })

    const handleUpload = ()=> {
        apiCodePluginsUploadRef.value.show()
    }
   

    const handleConnect = (row)=>{
        apiCodePluginsDrawerRef.value.show(row.packageId)
    }

    const handleEdit = (row)=>{
        apiCodePluginsUploadRef.value.show(row.packageId)
    }
    const handleDelete = (row) => {
      ElMessageBox.confirm(`确定删除当前组件包?`, {
        icon: WarningFilled,
        type: "warning",
      })
        .then(() => {
          request
            .deleteArrangePackage({
              packageId: row.packageId,
              operateType: "8",
            })
            .then((res) => {
              if (res?.data?.code === 200) {
                ElMessage.success("删除成功");
                reloadTable();
              }
            });
        })
        .catch(() => {});
    }

    const  handleDownload = (row)=> {
      let consturl = window.origin + '/iods_gateway/iodsApibusinessAdmin/arrangePackage/downloadArrangePackage/' + row.packageId
      let fileName = Date.now()+'_'+row.packageJarName
        var xhr = new XMLHttpRequest();
        xhr.open("get", consturl, true); // get、post都可
        xhr.responseType = "blob";
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.setRequestHeader("Authorization", localStorage.getItem('token')); //加请求头
        xhr.onload = function () {
          if (xhr.status == 200) {
            let blob = new Blob([this.response], { type: 'application/jar' });
            var a = document.createElement("a")
            var url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName  // 文件名
            a.click()
            window.URL.revokeObjectURL(url)
          }
        }
        xhr.send();
    }

    const changeStatus = (row)=>{
      request.updateIndicatordefinition({
        indexId: row.indexId,
        indexStatus: row.indexStatus // 1:启动 2：冻结
          }).then((res) => {
          if (res?.data?.code === 200) {
            ElMessage.success("修改状态成功");
              reloadTable()
          }else{
            ElMessage.error(res.data.msg);
          }
      })
    }

    const handleSizeChange = (val) => {
      pageVO.value.pageSize = val;
      getArrangePackageList();
    };
    const handleCurrentChange = (val) => {
      pageVO.value.currentPage = val;
      getArrangePackageList();
    };

    const reloadTable = ()=>{
       pageVO.value.currentPage = 1
      getArrangePackageList()
    }
    const getArrangePackageList = ()=>{
      request.getArrangePackageList({
            packageName:packageName.value,
            appId:localStorage.getItem('appId'),
            currentPage: pageVO.value.currentPage,
            pageSize:  pageVO.value.pageSize,
          }).then((res) => {
          if (res?.data?.code === 200) {
              tableData.value = res.data.data.result
              pageVO.value.total =  res.data.data.pageVO.total
          }
        });
    }
    
    onMounted(()=>{
        getArrangePackageList()
    })
    return {
      handleUpload,
      apiCodePluginsUploadRef,
      tableData,
      handleConnect,
      handleEdit,
      handleDelete,
      changeStatus,
      getArrangePackageList,
      pageVO,
      handleSizeChange,
      handleCurrentChange,
      reloadTable,
      handleDownload,
      apiCodePluginsDrawerRef,
      packageName
    };
  },
};
</script>

<style scoped>
</style>
