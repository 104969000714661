<template>
  <div class="data-source-right" >
    <div class="dataSourceTips" v-if="treedata.len == 0 && !dataSourceTreeDataLoading">
      <img :src="dataSourceTip" />
      当前没有目录，请先建立目录
    </div>
    <div v-else>
      <div  v-for="el in dataSourceInfo.classification" :key="el.list">
      <div align="left" class="margin-botton-12"><strong>{{ el.name }}</strong></div>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-for="item in dataSourceInfo[el.list]" :key="item.type">
          <el-tooltip effect="dark" content="当前没有目录，请先建立目录" placement="top" :disabled="treedata.len > 0">
          <div :class="['data-source-card', treedata.len === 0 ? 'no-data_hover' : 'dbs_hover']" @click="treedata.len === 0 ? '' : createDataSource(item)" :style="{height: boxHeight()}">
            <div class="musk">
              <Plus style="width:40px;color:#fff;opacity:0.8;"/>
            </div>
            <div class="dataSource-img">
              <img :src="item.src" alt="" />
            </div>
            <div class="dataSource-type">
              <div class="type-name">{{ item.label }}</div>
            </div>
          </div>
        </el-tooltip>
        </el-col>
      </el-row>
    </div>
    </div>
  </div>
</template>
<script>
import dbs from "../utils/datasource/index.js";
import router from "@/router";
import { reactive } from "vue";
import { useStore } from 'vuex'
import dataSourceTip from '@/assets/image/dataSourceTips.png'

export default {
  name: "DataSourceList",
  computed: {
    treedata () {
      const store = useStore()
      const data = store.state.dataSourceTreeData
      if(data[0]){
        return {
          len: data[0].children?data[0].children.length:0,
        }
      }else{
        return 0
      }
    },
    dataSourceTreeDataLoading() {
      const store = useStore()
      return store.state.dataSourceTreeDataLoading
    }
  },
  setup() {
    const dataSourceInfo = reactive({
      classification: [
        { name: '关系型数据库', list: 'list' },
        { name: '国产数据库', list: 'list2' },
        { name: '数据仓库', list: 'list1' }
      ],
      list: dbs.filter(el => el.classification === 'relationalDB'),
      list1: dbs.filter(el => el.classification === 'dataDB'),
      list2: dbs.filter(el => el.classification === 'domesticDB'),
    });
     const createDataSource = (item) => {
        router.push({path:'/home/DataResourceCenter/DataSourceManage/DataSourceInfo',query:{dataSourceType:item.type,operateType:'create'}})
    };

    // 计算卡片高度
    const boxHeight = () => {
      const doc = document.getElementsByClassName('data-source-card')
      if (doc && doc.length > 0) {
        const width = doc[0]?.clientWidth
        return width > 390 ? `${width*0.75}px` : '280px'
      } else {
        return '280px'
      }
    }
    return {
      dataSourceTip,
      dataSourceInfo,
      createDataSource,
      boxHeight
    };
  },
};
</script>

<style lang="less" scoped>
.data-source-card{
  position: relative;
  &:hover{
    .musk{
      display: block;
    }
  }
  .musk{
    display: none;
    position: absolute;
    text-align: center;
    line-height: 280px;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
  }
}
.dataSourceTips {
  background: #fff2e0;
  border: 0.5px solid #fb991f;
  border-radius: 4px;
  padding: 12px 24px;
  text-align: left;
  font-size: 12px;
  color: #4e5156;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
</style>