<template>
  <div>
  <ApiDesign :ApiFlowChart="true"/>
  </div>
</template>

<script lang="js">
import ApiDesign from "@/views/ApiDesign"

export default {
  name: "myDesign",
  components:{
    ApiDesign
  },
  setup() {
  return{
  }
  },
    
}
</script>


<style scoped>
</style>