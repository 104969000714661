<template>
<div class="main">
        <div >
            <el-input
            class="input-transparent"
            v-model.trim="functionSoso"
            placeholder="请输入关键字"
            clearable
            style="margin-bottom: 24px; width: 600px;"
            @keyup.enter="getOverallFunction"
            >
            <template #suffix>
                <el-icon class="icon-search" @click="getOverallFunction"><Search /></el-icon>
            </template>
        </el-input>
         <img
          src="../../assets/image/apiMarket/search-open.png"
          alt=""
          v-if="!onSearch"
          style="margin-bottom: 5px; cursor: pointer; margin-left: 20px"
          @click="onSearch = !onSearch"
        />
        <img
          src="../../assets/image/apiMarket/search-close.png"
          alt=""
          v-if="onSearch"
          style="margin-bottom: 5px; cursor: pointer; margin-left: 20px"
          @click="onSearch = !onSearch"
        />
        </div>
         <el-form
        v-if="onSearch"
        :inline="true"
        v-model="searchForm"
        style="text-align: left"
      >
        <el-form-item label="函数分类：">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{functionCateLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
           <template #dropdown>
            <el-dropdown-menu >
              <el-dropdown-item @click="changeFunctionCate('','全部')">全部</el-dropdown-item>
              <el-dropdown-item v-for="cate in functionCateOptionMap" :key="cate.label" @click="changeFunctionCate(cate.value,cate.label)">{{cate.label}}</el-dropdown-item>
            </el-dropdown-menu>
            </template>
          </el-dropdown>
          
        </el-form-item>
        
        <el-form-item label="函数类别：" >
           <el-dropdown>
            <span class="el-dropdown-link">
              {{functionTypeLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
           <template #dropdown>
            <el-dropdown-menu >
              <el-dropdown-item @click="changeFunctionType('','全部')">全部</el-dropdown-item>
              <el-dropdown-item v-for="type in functionTypeMap" :key="type.label" @click="changeFunctionType(type.value,type.label)">{{type.label}}</el-dropdown-item>
            </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-form-item>
        <el-form-item label="状态：" prop="status" >
          <el-dropdown>
            <span class="el-dropdown-link">
              {{statusLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
           <template #dropdown>
            <el-dropdown-menu >
              <el-dropdown-item @click="changeStatus('','全部')">全部</el-dropdown-item>
              <el-dropdown-item v-for="status in functionStatusOptionsMap" :key="status.label" @click="changeStatus(status.value,status.label)">{{status.label}}</el-dropdown-item>
            </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-form-item>
      </el-form>
        <div class="box-card-radius-eight white-bg">
          <el-table 
            :data="tableData"
            style="width: 100%;margin-bottom: 20px;"
            row-key="functionId"
            border>  
            <el-table-column
            prop="functionName"
            label="函数名称">
            </el-table-column>
            <el-table-column
            prop="functionCnname"
            label="中文简称">
            </el-table-column>
            
            <el-table-column
            label="函数分类"
            width="150">
             <template #default="scope"> {{functionCateMaps[scope.row.functionCate]}}</template>
            </el-table-column>
            <el-table-column
            prop="functionFamily"
            label="函数类别"
            width="150">
            </el-table-column>
             <el-table-column
            prop="functionStatus"
            width="150"
            label="状态">
             <template #default="scope"> {{functionStatusMaps[scope.row.functionStatus]}}</template>
            </el-table-column> 
            <el-table-column
            fixed="right"
            label="操作"
            width="150">
            <template #default="scope">
               <el-tooltip content="查看">
                <el-button
                  size="medium"
                  icon="View"
                  type="text"
                  @click="handleView(scope.row)"
                ></el-button>
              </el-tooltip>
            </template>
            </el-table-column>
        </el-table>
            <!--分页组件-->
      <div style="margin: 20px 0;overflow:hidden;">
         <el-pagination background small
           style="float:right;"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          :page-sizes="[10,20,50,100]"
          show
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
    </div>
        </div>
        
 </div>
</template>

<script>
import { ref, onMounted } from "vue";
import {  useRouter } from 'vue-router';
import {request} from '@/api'
import { ElMessage } from "element-plus";
import {functionCateOptions,functionTypes,functionStatusOptions,functionCateMap,functionStatusMap} from '@/utils/constant'


export default {
  name: `ServiceClassificationManagement`,
  setup() {
    const router = useRouter()
    let functionSoso = ref(null)
    let onSearch = ref(false);
    let tableData =  ref([])
    let page = ref({
      currentPage: 1,
      pageSize: 10,
    })
    let total = ref(10)
    let functionCateLabel = ref('全部')
    const functionCateOptionMap = ref(functionCateOptions)
    const functionCateMaps = ref(functionCateMap)
    const functionTypeMap = ref(functionTypes)
    let functionTypeLabel = ref('全部')
    const functionStatusOptionsMap = ref(functionStatusOptions)
    const functionStatusMaps = ref(functionStatusMap)

    let functionCate = ref(null)
    let functionFamily = ref(null)
    let functionStatus = ref(null)

    let statusLabel = ref('全部')
    const getOverallFunction = ()=>{
      request.getOverallFunction({
          ...page.value,
          functionCate:functionCate.value,
          functionFamily:functionFamily.value,
          functionStatus:functionStatus.value,
          functionSoso:functionSoso.value
          
        }).then((res) => {
        if (res?.data?.code === 200) {
          tableData.value = res.data.data?.result || []
          total.value =  res.data.data?.pageVO.total
        } else {
          ElMessage.error('失败')
        }
      });
    }

    const handleSizeChange = (val) => {
      page.value.pageSize = val;
      getOverallFunction();
    }
    const handleCurrentChange = (val) => {
      page.value.currentPage = val;
      getOverallFunction()
    }
    
    const changeFunctionCate = (value,label)=>{
      console.log(value)
       functionCate.value = value
       functionCateLabel.value = label
       getOverallFunction()
    }

    const changeFunctionType = (value,label)=>{
       console.log(value)
       functionFamily.value = value
       functionTypeLabel.value = label
       getOverallFunction()
    }

    const changeStatus = (value,label)=>{
      console.log(value)
       functionStatus.value = value
       statusLabel.value = label
       getOverallFunction()
    }

    const handleView =  (row)=>{
         router.push({
          path: '/home/DataResourceCenter/Overallfunction/detail',
          query: {
            operation:'edit',
            functionId:row.functionId
          }
        })
    }

    onMounted(()=>{
      getOverallFunction()
    })

    return {
      functionSoso,
      page,
      total,
      getOverallFunction,
      handleView,
      tableData,
      onSearch,
      functionCateOptionMap,
      functionCateMaps,
      functionTypeMap,
      functionCateLabel,
      changeFunctionCate,
      functionTypeLabel,
      changeFunctionType,
      functionStatusOptionsMap,
      functionStatusMaps,
      statusLabel,
      changeStatus,
      functionStatus,
      functionFamily,
      functionCate,
      handleSizeChange,
      handleCurrentChange
    }
  }
}
</script>

<style  lang="less">
.input-transparent {
    border-radius: 20px;
    font-size: 16px;
    .el-input__wrapper {
        background: transparent;
    }

}
.icon-search{
    cursor: pointer;
}

 .main-el-switch{
    position: relative;
    .el-switch__core {
    height: 24px;
    width: 60px;
    border-radius: 12px;
    &:after {
    left: 4px;
    top: 10px;
    }
    }
    &.el-switch {
    .is-active {
    .el-switch__core {
    &:after {
    margin-left: -20px;
    left: 100%;
    }
    }
    }
    }
    
    .el-switch__label--left {
    opacity: 0;
    }
    .el-switch__label--right {
    opacity: 0;
    }
    .is-active {
      opacity: 1;
    }
    .el-switch__label {
    position: absolute;
    top: 5px;
    }
    .el-switch__label--left {
    right: 0;
    color: #fff!important;
    z-index: 1;
    margin-right: 8px;
    }

    .el-switch__label--right {
    left: 0;
    color: #fff!important;
    z-index: 1;
    margin-left: 8px;
    }
}
//  /*修改展开按钮的样式 start*/
//     /*1.取消原本展开的旋转动效*/
//     /deep/.el-table__expand-icon{
//         -webkit-transform: rotate(0deg);
//         transform: rotate(0deg);
//     }
//     /*2.展开按钮未点击的样式是加号带边框*/
//      .el-table__expand-icon .el-icon:before{
//         content: "\e6d9";
//         border: 1px solid #ccc;
//         padding: 2px;
//     }
//     /*3.展开按钮点击后的样式是减号带边框*/
//     .el-table__expand-icon--expanded .el-icon:before{
//         content: "\e6d8";
//     }

</style>
<style lang="less" scoped>
.main{
    padding:20px;
    text-align: left;
    .toolbar{
      margin-bottom: 24px;
    }
}
</style>