<template>
    <div class="approval_center">
        <h4 align="left" class="marign-top-0">审批列表</h4>
        <el-tabs v-model="mytab">
            <el-tab-pane name="1" label="我的申请">
                <List :tabType="'1'" :key="mytab"></List>
            </el-tab-pane>
            <el-tab-pane name="2" label="我的待办">
                <List :tabType="'2'" :key="mytab"></List>
            </el-tab-pane>
            <el-tab-pane name="3" label="我的已办">
                <List :tabType="'3'" :key="mytab"></List>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { ref } from 'vue'
import List from './ApprovalCenterChild'
export default {
    name: 'ApprovalCenter',
    components: {
        List
    },
    setup() {
        const mytab = ref('1')

        return {
            mytab
        }
    }
}
</script>

<style lang="less" scoped>
    .approval_center {
        margin: 24px;
        background: #fff;
        border-radius: 8px;
        padding: 24px;
    }
</style>