<template>
<div class="api-log">
  <div style="text-align:left;margin-left: 0.4%;margin-bottom: 20px;margin-top: 1%;font-size:20px">
    <span style="width: 8px; height: 30px; background-color: #409eff;">&nbsp;&nbsp;</span>
    <span style="margin-left: 0.8%">API日志</span>
  </div>
  <div class="margin-botton-24" align="left">API名称：{{ currentApiName }}</div>
  <div style="height: 84%;width: 100%;padding-left: 0px;padding-top: 1%;margin-left: 0px;margin-top: 0px">
    <el-form v-model="searchForm" :inline="true" style="text-align:left;">
          <el-form-item label="操作类型" prop="operateType">
            <el-dropdown>
              <span class="el-dropdown-link">
              {{operateTypeLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
            <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item 
                v-for="operateType in operateTypeMap" 
                :key="operateType.value"
                @click="changeOperateType(operateType.value,operateType.label)">{{operateType.label}}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
            </el-dropdown>
          </el-form-item>
          <el-form-item label="操作状态" prop="operateStatus">
            <el-dropdown>
              <span class="el-dropdown-link">
              {{operateStatusLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
            <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item 
                v-for="operateStatus in operateStatusMap" 
                :key="operateStatus.value"
                @click="changeOperateStatus(operateStatus.value,operateStatus.label)">{{operateStatus.label}}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
            </el-dropdown>
          </el-form-item>
    </el-form>

    <div class="white-bg box-card-radius-eight">
    <el-table :data="logData.list" border style="width: 100%;">
      <el-table-column prop="operateTenantName" label="操作租户" width="160%" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="operateType" label="操作类型" width="100%">
      </el-table-column>
      <el-table-column prop="invokeAppName" label="调用方应用" width="160%">
      </el-table-column>
      <el-table-column prop="inParam" label="调用参数" width="350%" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="operateStatus" label="操作状态" width="100%" align="center">
        <template #default="scope">
          <span v-if="scope.row.operateStatus === 1">
            <el-icon color="#0E68E7" size="150%">
              <CircleCheck />
            </el-icon>
          </span>
          <span v-if="scope.row.operateStatus === 2">
            <el-icon color="#ff9900" size="150%">
              <Warning />
            </el-icon>
          </span>
          <span v-if="scope.row.operateStatus === 0">
            <el-icon size="150%">
              <CircleClose />
            </el-icon>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="operatorName" label="操作人" width="165%">
      </el-table-column>
      <el-table-column prop="operationTime" label="操作时间" width="165%" :formatter="iasmjs.formatDate" align="center">
      </el-table-column>
      <el-table-column prop="operateMsg" label="信息" :show-overflow-tooltip="true">
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <div class="margin-top-12 margin-botton-12">
       <el-pagination background small :current-page="page.pageNum" :page-size="page.pageSize" :page-sizes="[2, 5, 10, 20]"
        layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" style="justify-content: flex-end;" />
    </div>
    </div>
    
    <el-button style="margin-top:12px;" @click="cancel()">返回</el-button>
  </div>
  </div>
</template>

<script>

import {request} from '@/api'
import { reactive, ref } from "vue";
import { ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";
import iasmjs from "@/assets/js/iasm";
import router from "@/router";


export default {
  name: "LogDetails",
  setup() {
    let operateTypeLabel = ref('全部')
    const operateTypeMap = ref(
      [
        {
          value:null,
          label:'全部'
        },
        {
          value:'新建',
          label:'新建'
        },
        {
          value:'编辑',
          label:'编辑'
        },
        {
          value:'待上线',
          label:'待上线'
        },
        {
          value:'恢复',
          label:'恢复'
        },
        {
          value:'上线',
          label:'上线'
        },
        {
          value:'下线',
          label:'下线'
        },
        {
          value:'归档',
          label:'归档'
        },
        {
          value:'运行',
          label:'运行'
        },
        {
          value:'归档',
          label:'归档'
        },
        {
          value:'删除',
          label:'删除'
        },
        {
          value:'调用',
          label:'调用'
        },
        {
          value:'订阅',
          label:'订阅'
        },
        {
          value:'取消订阅',
          label:'取消订阅'
        },
        {
          value:'授权',
          label:'授权'
        },
        {
          value:'取消授权',
          label:'取消授权'
        }
    ])
    
    let operateStatusLabel = ref('全部')
    const operateStatusMap = ref([
        {
          value:null,
          label:'全部'
        },
        {
          value:'1',
          label:'成功'
        },
        {
          value:'2',
          label:'告警'
        },
        {
          value:'0',
          label:'失败'
        }
    ])
    
    const searchForm = reactive({
      operateType: null,
      operatorName: null,
      operateStatus: null
    });
    const total = ref(0);  //总条数
    const page = reactive({  //配置对应的查询参数
      pageNum: 1,
      pageSize: 10,
    });

    const changeOperateType = (val,label)=>{
      searchForm.operateType = val
      operateTypeLabel.value = label
      logDetails()
    }

    const changeOperateStatus = (val,label)=>{
      searchForm.operateStatus = val
      operateStatusLabel.value = label
      logDetails()
    }

    const handleSizeChange = (val) => { //size修改
      page.pageSize = val
      logDetails("size");
    };
    const handleCurrentChange = (val) => { //num修改
      page.pageNum = val
      logDetails("num");
    };
    const route = useRoute();

    const apiId = ref(route.query.apiId);
    const apiName = ref(route.query.apiName);
    const logData = reactive({
      list: []
    });
    const currentApiName = ref('');
    const logDetails = (value) => {
      if (!value && (searchForm.operateType || searchForm.operateStatus)) {
        page.pageNum = 1;
      }
      currentApiName.value = apiName.value;
      request.selectLogList({
          apiId: apiId.value,
          operateType: searchForm.operateType,
          operateStatus: searchForm.operateStatus,
          currentPage: page.pageNum,
          pageSize: page.pageSize
        }).then(res => {
        if (res.data.code == 200) {
          logData.list = res.data.data.result;
          total.value = res.data.data.pageVO.total
        } else {
          ElMessageBox.alert(res.data.msg);
        }
      })
    };

    const cancel = () => { //编辑
      router.back();
    };
    const reset = () => {
      searchForm.operateStatus = null;
      searchForm.operateType = null;
      page.pageNum = 1;
      page.pageSize = 10;
      request.selectLogList({
          apiId: apiId.value,
          currentPage: page.pageNum,
          pageSize: page.pageSize
        }).then(res => {
        if (res.data.code == 200) {
          logData.list = res.data.data.result;
          total.value = res.data.data.pageVO.total
        } else {
          ElMessageBox.alert(res.data.msg);
        }
      })
    };
    return {
      operateTypeMap,
      operateStatusMap,
      operateStatusLabel,
      page,
      total,
      handleSizeChange,
      handleCurrentChange,
      changeOperateType,
      changeOperateStatus,
      operateTypeLabel,
      apiId,
      apiName,
      cancel,
      iasmjs,
      logDetails,
      logData,
      currentApiName,
      searchForm,
      reset
    }
  },
  beforeCreate() {
    this.logDetails()
  }
}

</script>

<style scoped lang="less">
.api-log{
  background: #fff;
  border-radius: 8px;
  padding: 20px;
}
</style>