<template>
  <div class="createSql" style="margin:28px;padding-bottom:90px;">
    <div  class="box-card-radius-eight white-bg">
     <div align="left" class="step-title margin-botton-12"><strong>基本信息</strong></div>
          <el-form ref="ruleFormRef" :model="form" :rules="basicRules" label-position="top">
            <el-row :gutter="48">
              <!-- <el-col :span="8" align="left">
                <el-form-item label="业务分类" placeholder="请输入业务分类" prop="apiAuthCode"> 
                   <el-select
                    v-model="form.apiAuthCode"
                    filterable
                    placeholder="请选择业务分类"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in apiAuthCodeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col> -->
              <el-col :span="8" align="left">
                <el-form-item label="认证名称"  prop="apiAuthName">
                  <el-input v-model="form.apiAuthName"
                    placeholder="请输入认证名称"
                    maxlength="200" show-word-limit  />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="认证类型" prop="apiAuthType">
                  <el-select
                    v-model="form.apiAuthType"
                    filterable
                    :disabled="updateapiVersion === 'true'"
                    placeholder="请选择认证类型"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in apiAuthTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="URL" placeholder="请输入URL" prop="apiAuthUrl"> 
                  <el-input v-model.trim="form.apiAuthUrl"
                    maxlength="200" show-word-limit  />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="请求方式" prop="apiAuthMethod"> 
                  <el-select
                    v-model="form.apiAuthMethod"
                    filterable
                    placeholder="请选择请求方式"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in Requests"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
               <el-col :span="8" align="left">
                <el-form-item label="请求数据类型" prop="apiAuthDatatype"> 
                  <el-select v-model="form.apiAuthDatatype" style="width: 100%" >
                    <el-option label="application/form-data" value="application/form-data"></el-option>
                    <el-option label="application/json" value="application/json"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="超时时间(秒)" placeholder="请输入" prop="timeout">
                  <el-input-number :min="0" v-model="form.timeout" style="width: 100%"/>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="过期时间" placeholder="请输入" >
                  <el-input-number :min="0" v-model="form.expirationTime" style="width: 100%"/>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="过期单位" placeholder="请输入" >
                 <el-select v-model="form.timeUnit" style="width: 100%" >
                    <el-option label="秒" value="SECONDS"></el-option>
                    <el-option label="分钟" value="MINUTES"></el-option>
                    <el-option label="小时" value="HOURS"></el-option>
                    <el-option label="天" value="DAYS"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            
            <el-form-item label="描述">
              <el-input
                v-model="form.apiAuthDesc"
                maxlength="1000"
                show-word-limit
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-form>
        </div>

          <div  class="box-card-radius-eight white-bg" style="margin-top:20px;">
          <div align="left" class="step-title margin-botton-12 margin-top-24"><strong>认证参数</strong></div>
          <div align="left" class="table-btn__up margin-botton-12">
            <el-button type="primary" icon="plus" @click="addRow()">新增入参</el-button>
          </div>
          <el-table :data="apiAuthRequestjson" style="width: 100%" max-height="285">
            <el-table-column
              prop="parameterNameCm"
              label="参数中文名称"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                  v-model="scope.row.parameterNameCm"
                  maxlength="50"
                  placeholder="请输入参数中文名称"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="parameterName"
              label="参数名称"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                  v-model="scope.row.parameterName"
                  maxlength="50"
                  placeholder="请输入参数名称"
                  :disabled="scope.row.isDisable"
                ></el-input>
              </template>
            </el-table-column>
             <el-table-column
              prop="parameterType"
              label="参数类型"
            >
              <template #default="scope">
                <el-select v-model="scope.row.parameterType" default="N">
                  <el-option label="动态参数" value="dynamicParamter" />
                  <el-option label="静态参数" value="staticParamter" />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="ispwd"
              label="是否加密"
            >
              <template #default="scope">
                <el-select v-model="scope.row.ispwd" default="0">
                  <el-option value="1" label="是" />
                  <el-option value="0" label="否" />
                </el-select>
              </template>
            </el-table-column>
            
            <el-table-column
              prop="parameterValue"
              label="参数值"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                  :type="scope.row.ispwd === '1'? 'password':'input'"
                   :show-password="scope.row.ispwd === '1'? true:false"
                  v-model="scope.row.parameterValue"
                  autocomplete="off"
                  placeholder="请输入参数值"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="parameterArea"
              label="参数位置"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-select
                  v-model="scope.row.parameterArea"
                  placeholder="请选择参数位置"
                >
                  <el-option label="Header" value="Header" />
                  <el-option label="URLPath" value="URLPath" />
                  <el-option label="QueryParam" value="QueryParam" />
                  <el-option label="Body" value="Body" />
                </el-select>
              </template>
            </el-table-column>
            
           
           
            
            <el-table-column label="操作" width="80" align="center">
              <template #default="scope">
                <el-popconfirm
                  title="删除后将无法恢复，请确认是否删除？"
                  confirm-button-type="danger"
                  icon="warning-filled" icon-color="#F56C6C"
                  width="250" placement="top-end"
                  @confirm="deleteRow(scope.$index)">
                  <template #reference>
                    <el-button icon="delete" type="danger" text :disabled="scope.row.isDisable"></el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div  class="box-card-radius-eight white-bg" style="margin-top:20px;">
            <div align="left" class="step-title margin-botton-12 margin-top-24"><strong>认证解析</strong></div>
             <el-form ref="FormRef" :model="form" :rules="basicRules" label-position="top">
            <el-row :gutter="48">
              <el-col :span="8" align="left">
               <el-form-item label="解析类型" prop="apiAuthPasretype">
                  <el-select v-model="form.apiAuthPasretype" style="width: 100%" >
                    <el-option label="JSON" value="JSON"></el-option>
                    <el-option label="XML" value="XML"></el-option>
                    <el-option label="正则表达式" value="REGULAR"></el-option>
                  </el-select>
                </el-form-item>
                </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="解析表达式" prop="pasrevalue">
                 <el-input  
                 v-model="form.pasrevalue"
                 clearable
                  placeholder="请输入解析表达式" />
                </el-form-item>
              </el-col>
               <el-col :span="8" align="left" >
                <el-form-item >
                  <template #label><div style="opacity:0;">测试</div></template>
                  <el-button @click="testAuth">测试</el-button>
                </el-form-item>
              </el-col>
  
              <el-col style="max-height:500px;min-height:200px;overflow-y:auto;" :span="24" align="left" v-if="(form.apiAuthPasretype == 'JSON' || form.apiAuthPasretype == 'XML')" v-loading="loading">
                <VueJsonPretty
                 v-if="form.apiAuthPasretype == 'JSON'"
                :data="resultInfo"
                :deep="3"
                :showSelectController="true"
                @nodeClick="onJsonChange"
              />
              <xmlTree v-if="form.apiAuthPasretype == 'XML'" :data="resultInfo" />
              </el-col>

              
               </el-row>
            </el-form >
        </div>

         
    
    <div class="footer" align="right">
      <div class="btn">
        <el-button  @click="cancel">取消</el-button>
       <el-button type="primary" @click="toTestRes" v-if="showTestBtn">验证结果</el-button>
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </div>

     <el-dialog
        v-model="dialogVisible"
        title="验证结果"
        width="50%"
      >
      <VueJsonPretty
                :data="testResult"
                :deep="3"
                :showSelectController="true"
              />
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false">
              确认
            </el-button>
          </span>
        </template>
      </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted} from 'vue';
import {request} from '@/api'
import { ElMessage } from "element-plus";
import { useRouter,useRoute } from "vue-router";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import xmlTree from './xmlTree.vue'
import { paramsTypesList, RequestList } from '@/utils/constant'
export default {
  name: 'toDevelopServices',
  components:{
    VueJsonPretty,
    xmlTree
  },
  setup () {
    const router = useRouter();
    const route = useRoute();
    let loading = ref(false)
    const paramsTypes = ref(paramsTypesList)
    const Requests = ref(RequestList)
    let showTestBtn = ref(route?.query?.apiAuthId)
    let dialogVisible = ref(false)
    let testResult = ref()
    let resultInfo = ref('')
    let treeData = ref([])
    let apiAuthRequestjson = ref([])
     let businessCateOptions = ref([])
     let apiVersionList = ref([{
      value:1,
      label:1
    }])

    let apiAuthCodeList = ref([
        {
            label: '调度API组件认证',
            value: 'dispatch_api_auth',
        },
        {
            label: 'API算子组件认证',
            value: 'operator_api_auth',
        }
    ])
    let apiAuthTypeList = ref([
              { label: '默认', value: 'default' },
              { label: 'Bearer auth 认证', value: 'bearer_auth' },
              { label: 'Basic auth 认证', value: 'basic_auth' },
          ])
    let ruleFormRef = ref(null)
    let form = ref({
      // apiAuthCode:'',
      apiAuthName:'',
      apiAuthType:'',
      apiAuthUrl: "",
      apiAuthMethod: "",
      apiAuthDatatype: "",
      timeout: 30,
      expirationTime:null,
      timeUnit:null,
      apiAuthDesc: "",
      apiAuthPasretype: "",
      pasrevalue: ""
    })

    const validateUrl = (rule, value, callback) => {
      const reg = /((\/[a-zA-Z0-9_-]+){1,}(({[a-zA-Z0-9]+}){0,}))$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(
          new Error(
            "支持英文，数字，下划线，连接符（-），不超过200个字符，且只能以 / 开头, 以英文，数字结尾"
          )
        );
      }
    };
    const basicRules = reactive({
      apiAuthPasretype:[
        { required: true, message: "请选择", trigger: "change" },
      ],
      apiAuthName: [
        { required: true, message: "请输入名称", trigger: "blur" },
        { min: 1, max: 100, message: "长度在 1 到 100 个字符", trigger: "blur" },
      ],
      apiAuthMethod: [
        { required: true, message: "请选择请求方式", trigger: "change" },
      ],
      // apiAuthCode:[
      //   { required: true, message: "请选择", trigger: "change" },
      // ],
      apiAuthType: [
        { required: true, message: "请选择", trigger: "change" },
      ],
      apiAuthDatatype: [
        { required: true, message: "请选择", trigger: "change" },
      ],
      apiAuthUrl: [
        { required: true, message: "请输入接口路径", trigger: "blur" },
        { min: 1, max: 200, message: "长度在 1 到 200 个字符", trigger: "blur" },
        { validator: validateUrl, trigger: "blur" },
      ],
      timeout: [
        { required: true, message: "请输入", trigger: "change" },
      ],
    });

    const addRow = () => {
      apiAuthRequestjson.value.push({
        parameterNameCm:'',
        parameterName:'',
        parameterValue:'',
        parameterType:'',
        paramDescription:'',
        parameterArea:'',
        sort:0,
        ispwd:'0',
        paramFun:'',
      }); //在apiAuthRequestjson表格数组中添加对象
    }

    const deleteRow = (index) => {
       apiAuthRequestjson.value.splice(index, 1);
    };

    

    const treeToData = (tree) => {
      return tree.reduce((preResult, item) => {
        const { children, ...data } = item;
        return preResult.concat(
          data,
          children && children.length ? treeToData(children) : []
        );
      }, []);
    };

    const getTreeData = () => {
      //查询列表
      request.getListTree({appId:localStorage.getItem("appId"),filterText:''}).then((res) => {
        if (res.data.code == 200) {
          const list = treeToData([res.data.data]);
          // 过滤categoryId为-1的项
          treeData.value = (list || []).filter(el => el.categoryId !== '-1')
          // treeData.value = treeToData([res.data.data]);
        }
      });
    };

    const getBusinessCateOptions = ()=>{
      request.selectBusinessCatePage({
          currentPage: 1,
          pageSize: 10000
        }).then((res) => {
        if (res?.data?.code === 200) {
          businessCateOptions.value = res.data.data 
        }
      });
    }

    const saveApi = ()=>{
      let saveParams = {
        ...form.value,
        projectId:'1',
        apiAuthRequestjson:JSON.stringify(apiAuthRequestjson.value),
        appId:localStorage.getItem("appId"),
        apiId:route.query.apiId?route.query.apiId:null,
        operateType:route.query.operation === 'edit'?'1':'0'
      }
      request.addOrUpdateAuth(saveParams).then((resp) => {
        let respCode = resp.data.code;
        if (respCode == "200") {
          ElMessage.success('创建成功');
          cancel()
        } else {
          ElMessage.error(resp.data.msg);
        }
      });
    }

    const save =  async ()=>{
      if(!ruleFormRef.value) return
        await ruleFormRef.value.validate((valid) => {
        if (valid) {
          saveApi();
        }
      });
    }

    const testAuth = ()=>{
      let saveParams = {
        ...form.value,
        apiAuthRequestjson: JSON.stringify(apiAuthRequestjson.value),
        appId:localStorage.getItem("appId"),
      }
      loading.value = true
      request.getApiResultData(saveParams).then((resp) => {
        let respCode = resp.data.code;
        if (respCode == "200") {
          if(form.value.apiAuthPasretype == 'JSON'){
            resultInfo.value = eval('(' + resp.data.data + ')'); //设置json
          } else if(form.value.apiAuthPasretype == 'XML'){
            console.log(resp.data.data,'>>>>resp.data.data')
            parserXML(resp.data.data) //设置XML
          }
          loading.value = false
        } else {
          ElMessage.error(resp.data.msg);
          loading.value = false
        }
      });
    }

    const toTestRes = ()=>{
      request.getApiAuthData({
          "projectId":1,
          "apiAuthId":route.query.apiAuthId
            }).then((resp) => {
        let respCode = resp.data.code;
        if (respCode == "200") {
          testResult.value = resp.data
          dialogVisible.value = true
        } else {
          ElMessage.error(resp.data.msg);
        }
      });
    }

    function parserXML(val) {
      resultInfo.value = []
      try {
       let domparser = new DOMParser()
        // 解析XMLDocument对象
        let domNow= domparser.parseFromString(val, 'application/xml')
        // 生成XMLJavaScript对象
        xmlPath(resultInfo.value, domNow.documentElement)
        // 设置标签的序号
        setTagIndex(resultInfo.value)
        // 设置元素的xpath
        setXPath(resultInfo.value, '')
      } catch(e) {
        console.log(e)
      }
    }

    function xmlPath(arr, dom) {
      const domItem = {
        tagName: '',
        index: 0,
        nodeType: dom.nodeType,
        data: '',
        xpath: '',
        leaf: false,
        children: [],
        attributes: []
      }

      if (domItem.nodeType === 1) {
        // 元素对象
        domItem.tagName = dom.tagName
        const attrs = dom.attributes
        if (attrs.length > 0) {
          for(let i = 0; i< attrs.length; i++) {
            const attr = {
              name: attrs[i].name,
              value: attrs[i].value,
              xpath: ''
            }
            domItem.attributes.push(attr)
          }
        }
        const childNodes = dom.childNodes
        if (childNodes.length > 0) {
          for(let i = 0; i < childNodes.length; i++) {
            const child = childNodes[i]
            if ((child.nodeType === 3 && child.data.trim() === '') || child.nodeType === 8) continue
            domItem.leaf = true
            xmlPath(domItem.children, child, domItem.xpath)
          }
        }
      } else if (domItem.nodeType === 3) {
        // 文本对象
        const data = dom.data.trim()
        if (data !== '') {
          domItem.data = data
        }
      }
      arr.push(domItem)
    }

    function setTagIndex(arr) {
      // 设置标签序号
      if (arr.length === 1 && !arr[0].leaf) return
      arr.forEach((item, index) => {
        let count = [item]
        if (item.index === 0) {
          for (let i = 0; i < arr.length; i++) {
            if (item.tagName === arr[i].tagName && item.xpath === arr[i].xpath && index !== i) {
              count.push(arr[i])
            }
          }
        }
        if (count.length > 1) {
          count.forEach((el,n) => {
            el.index = n + 1
          })
        }
        if (item.leaf) {
          setTagIndex(item.children)
        }
      })
    }

    function setXPath(arr, basePath) {
      arr.forEach(item => {
        if (item.nodeType === 1) {
          item.xpath = item.index === 0 ? `${basePath}/${item.tagName}` : `${basePath}/${item.tagName}[${item.index}]`
        } else if (item.nodeType === 3) {
          item.xpath = item.index === 0 ? `${basePath}/text()` : `${basePath}/text()[${item.index}]`
        }
        if (item.attributes.length > 0) {
          item.attributes.forEach(attr => {
            attr.xpath = `${item.xpath}[@${attr.name}="${attr.value}"]`
          })
        }
        if (item.leaf) {
          setXPath(item.children, item.xpath)
        }
      })
    }

    
    const onJsonChange = (data)=>{
      let jsonpath = data.path;
      if (jsonpath.startsWith('root.')) {
        jsonpath = '$.' + jsonpath.substr(5);
      }
      form.value.pasrevalue = jsonpath
    }

    const cancel = ()=>{
      router.push('/home/DataResourceCenter/APIAuthManagement')
    }

    const getInfo = ()=>{
      let apiAuthId = route.query.apiAuthId
      request.findAuthDetails({apiAuthId,"projectId":1}).then((res) => {
        let result = res.data
         if (result.code == "200") {
          form.value = {...res.data.data}
          apiAuthRequestjson.value = result.data.apiAuthRequestjson?JSON.parse(result.data.apiAuthRequestjson): []
        } else {
          ElMessage.error(result.msg);
        }
      })
    }

    onMounted(()=>{
        getBusinessCateOptions()
        getTreeData()

        if(route.query.operation === 'edit'){
          getInfo()
        }
        
    })
    return {
        loading,
        paramsTypes,
        Requests,
        testResult,
        dialogVisible,
        showTestBtn,
        resultInfo,
        testAuth,
        toTestRes,
        parserXML,
        setTagIndex,
        setXPath,
        xmlPath,
        ruleFormRef,
        basicRules,
        form,
        getTreeData,
        treeData,
        businessCateOptions,
        getBusinessCateOptions,
        apiAuthRequestjson,
        apiAuthCodeList,
        apiAuthTypeList,
        apiVersionList,
        addRow,
        deleteRow,
        save,
        cancel,
        onJsonChange
    }
  },
}
</script>
<style lang="less">
.toTestRes{
  width: 1000px;
}
</style>
<style lang="less" scoped>
  .footer {
    margin-top: 24px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 64px;
    line-height: 64px;
    background: #fff;
    z-index: 10;;
    .btn {
      padding-right: 24px;
    }
  }
</style>