<template>
  <div>
    <CommonSearchForm :value="searchForm.queryKey" @handleSearch="handleSearch">
      <el-form :inline="true" v-model="searchForm" align="left">
        <el-form-item label="请求方式" prop="apiRequestM">
          <el-dropdown >
            <span class="el-dropdown-link">
              {{apiRequestMLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
           <template #dropdown>
            <el-dropdown-menu >
              <el-dropdown-item @click="changeApiRequestM('','全部')">全部</el-dropdown-item>
              <el-dropdown-item v-for="item in options.apiRequestM" :key="item.lookupDKey"
                  :value="item.lookupDKey"  @click="changeApiRequestM(item.lookupDKey,item.lookupDValue)">{{item.lookupDValue}}</el-dropdown-item>
            </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-form-item>
        <!-- <el-form-item label="创建方式" prop="apiCreateM">
          <el-dropdown >
            <span class="el-dropdown-link">
              {{apiCreateMLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
           <template #dropdown>
            <el-dropdown-menu >
              <el-dropdown-item @click="changeApiCreateM('','全部')">全部</el-dropdown-item>
               <el-dropdown-item @click="changeApiCreateM('1','手工创建')">手工创建</el-dropdown-item>
                <el-dropdown-item @click="changeApiCreateM('2','API注册')">API注册</el-dropdown-item>
            </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-form-item> -->
        <el-form-item label="周期" prop="queryType">
          
          <el-dropdown >
            <span class="el-dropdown-link">
              {{queryTypeLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
           <template #dropdown>
            <el-dropdown-menu >
               <el-dropdown-item @click="changeQueryType('1','本周')">本周</el-dropdown-item>
                <el-dropdown-item @click="changeQueryType('2','本月')">本月</el-dropdown-item>
                <el-dropdown-item @click="changeQueryType('3','全年')">全年</el-dropdown-item>

            </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-form-item>
      </el-form>
    </CommonSearchForm>

    <div class="api-charts" v-loading="loading">
      <div class="card" v-for="(item, index) in tableData.list" :key="index">
        <div class="inline api-info" @click="viewDoc(item)">
          <div class="market-card-top iasm-flex">
            <img src="../../assets/image/home/market.png" />
            <div class="top-left-info">
              <div class="tag" style="">
                <p align="left" class="ellipsis" :title="item.apiName">{{ item.apiName }}</p>
                <el-tag>{{ item.apiCreateM }}</el-tag>
                <el-tag type="warning" v-if="item.changeFlag === 'Y'">迭代中</el-tag>
                <el-tag type="info" color="#975fe412" v-else>{{ item.apiVersion ? item.apiVersion + "版本" : "-" }}</el-tag>
              </div>
              <div style="display: flex">
                <p class="ellipsis" style="margin: 0;padding-top: 6px;padding-right: 12px;">
                  <span class="market-label">
                    上线时间：
                    {{ renderCreateTime(item.apiOnlineTime) }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="content">
            <p>
              <span class="market-label-mid">URL</span>
              <span class="market-content-mid" :title="item.apiUrl">{{ item.apiUrl }}</span>
            </p>
            <p>
              <span class="market-label-mid">请求方式</span>
              <span class="market-content-mid">{{ item.apiRequestM === '1' ? 'GET' : 'POST' }}</span>
            </p>
            <p>
              <span class="market-label-mid">创建人</span>
              <span class="market-content-mid">{{ item.createdName ? item.createdName : "-" }}</span>
            </p>
            <p style="margin-bottom: 20px">
              <span class="market-label-mid">创建时间</span>
              <span class="market-content-mid">{{ renderCreateTime(item.creationTime) }}</span>
            </p>
          </div>
        </div>
        <div :id="item.apiId" class="inline api-line"></div>
      </div>
    </div>
    <!--分页组件-->
    <div class="margin-top-12 margin-botton-12">
       <el-pagination background small :current-page="tableData.page.pageNum" :page-size="tableData.page.pageSize" :page-sizes="[2, 5, 10, 20]"
        layout="total, sizes, prev, pager, next, jumper" :total="tableData.page.total" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" style="justify-content: flex-end;" />
    </div>
  </div>
</template>

<script>
import { nextTick, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router';
import CommonSearchForm from '@/components/common/listSearchForm.vue'
import {request} from '@/api'
import * as echarts from 'echarts'
export default {
  components: {
    CommonSearchForm
  },
  setup() {
    const loading = ref(true)
    let apiRequestMLabel = ref('全部')
    let apiCreateMLabel = ref('全部')
    let queryTypeLabel = ref('本周')
    const searchForm = reactive({
      queryKey: '',
      queryType: '1', // 查询类型 1-周 | 2-月 | 3-年
      appId: localStorage.getItem('appId'),
      apiCreateM: '', // 创建方式
      apiRequestM: '' // 请求方式 1-get | 2-post
    })

    const tableData = reactive({
      page: {
        pageNum: 1,
        pageSize: 5,
        total: 0
      },
      list: []
    })

    const options = reactive({
      apiRequestM: []
    })

    const getRequestMethods = () => {
      request.getLookupHead({lookupHCode: 'requestMethod'}).then(res => {
        let a = res.data.data;
        options.apiRequestM = a.details
      })
    }
    getRequestMethods()
    
    const changeApiRequestM = (val,label)=>{
      searchForm.apiRequestM = val
      apiRequestMLabel.value = label
      getData();
    }

    const changeApiCreateM = (val,label)=>{
      searchForm.apiCreateM = val
      apiCreateMLabel.value = label
      getData();
    }

    const changeQueryType = (val,label)=>{
      searchForm.queryType = val
      queryTypeLabel.value = label
      getData();
    }

    const handleSearch = (queryKey) => {
      tableData.page.pageNum = 1
      searchForm.queryKey = queryKey
      getData()
    }
    const handleSizeChange = (num) => {
      tableData.page.pageSize = num
      getData();
    }
    const handleCurrentChange = (num) => { //num修改
      tableData.page.pageNum = num
      getData();
    }

    const getData = () => {
      loading.value = true
      request.apiTrafficMonitoring({
          pageVO: {
            currentPage: tableData.page.pageNum,
            pageSize: tableData.page.pageSize
          },
          ...searchForm
        }).then((res) => {
        console.log(res);
        tableData.list = res.data.data.result
        tableData.page.total = res.data.data.pageVO.total
        renderLineEcharts()
      }).finally(() => {
        loading.value = false
      })
    }

    const renderLineEcharts = () => {
      nextTick(() => {
        for (let i = 0; i < tableData.list.length; i++) {
          const item = tableData.list[i]
          const domId = document.getElementById(item.apiId)
          const chartDom = echarts.init(domId)
          const options = {
            color: "#0072ff",
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                label: {
                  backgroundColor: "#0072ff",
                }
              }
            },
            grid: {
              top: '40px',
              left: "5%",
              right: "4%",
              bottom: "0",
              containLabel: true,
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: item.apiTrafficMonitoringVOList.map(el => el.invokeTime)
            },
            yAxis: {
              type: 'value',
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "#687080",
                  width: 0.5,
                },
              }
            },
            series: [
              {
                name: '调用次数',
                stack: "Total",
                smooth: true,
                showSymbol: false,
                lineStyle: {
                  color: "#0072ff",
                },
                data: item.apiTrafficMonitoringVOList.map(el => el.count),
                type: 'line',
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(0,114,255,0.1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,114,255,0.4)",
                    },
                  ]),
                },
                emphasis: {
                  focus: "series",
                }
              }
            ]
          }
          chartDom.setOption(options)
          // 根据页面大小自动响应图表大小
          window.addEventListener("resize", function () {
            chartDom.resize();
          });
        }
      })
    }

    const renderCreateTime = (data) => {
      if (!data) {
        return "-";
      }
      let dt = new Date(data);
      let y = dt.getFullYear();
      let m = (dt.getMonth() + 1 + "").padStart(2, "0");
      let d = (dt.getDate() + "").padStart(2, "0");
      let hh = (dt.getHours() + "").padStart(2, "0");
      let mm = (dt.getMinutes() + "").padStart(2, "0");
      let ss = (dt.getSeconds() + "").padStart(2, "0");
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
    };

    const router = useRouter()
    const viewDoc = (item) => { //api文档
      router.push({
        path:'/home/ApiManage/ApiView',
        query: {
          apiId:item.apiId,
          changeFlag:item.changeFlag || 'N'
        }
      });
      
    }

    onMounted(() => {
      handleSearch()
    })

    return {
      loading,
      searchForm,
      options,
      tableData,
      handleSearch,
      handleSizeChange,
      handleCurrentChange,
      getData,
      renderCreateTime,
      viewDoc,
      changeApiRequestM,
      changeApiCreateM,
      changeQueryType,
      apiRequestMLabel,
      apiCreateMLabel,
      queryTypeLabel
    }
  }
}
</script>

<style lang="less" scoped>
  .api-charts {
    .card {
      box-shadow: 4px 4px 16px 0px rgba(0,67,161,0.10);
      border-radius: 8px;
    }
    .inline {
      display: inline-block;
    }
    .api-info {
      width: 358px;
      cursor: pointer;
      .market-card-top {
        display: flex;
        padding: 12px 12px 0 12px;
        align-items: center;
        .top-left-info {
          margin-left: 16px;
          width: calc(100% - 46px);
          .tag {
            margin: 0;
            display: flex;
            align-items: center;
            flex-flow: nowrap;
            width: calc(100% - 12px);
            p {
              font-weight: 700;
              max-width: 170px;
              min-width: 140px;
              margin: 0 12px 0 0;
            }
            .el-tag {
              margin-right: 4px;
            }
          }
          .market-label {
              font-size: 12px;
              font-family: Source Han Sans CN, Source Han Sans CN-400;
              font-weight: 400;
              text-align: LEFT;
              color: #4e5156;
            }
        }
      }
      .content {
        margin: 18px 0 0;
        padding: 0 12px 0 18px;
        p {
          margin: 0 0 12px 0;
          text-align: left;
          display: flex;
          .market-label-mid {
            display: inline-block;
            width: 80px;
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-400;
            font-weight: 400;
            text-align: LEFT;
            color: #777d84;
          }
          .market-content-mid {
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-400;
            font-weight: 400;
            text-align: LEFT;
            color: #091328;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .api-line {
      height: 200px;
      width: calc(100% - 400px);
    }
  }
</style>