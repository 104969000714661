import { createStore } from 'vuex'
import {request} from '@/api'


export default createStore({
  state: {
    menuList:[],
    appInfo: {
      appId : localStorage.getItem("appId"),
      appName : localStorage.getItem("appName")
    },
    waitForCount: 0,
    dataSourceTreeData:[],
    dataSourceTreeDataLoading:true,
    dragItem: '', // 拖拽节点名称
    dragSetData: [], // 拖拽后获取的数据
    selectedTableObj: {}, // 选择数据源下的所有表集合
    canvasData: { // 画布数据
      nodes: [],
      edges: []
    },
    tableData: {
      inlineRelations: [], // 内联关系匹配
      conditoins: [], // 条件配置
      fieldsConf: [] // 字段配置

    } // sql界面配置数据集合
  },
  getters: {
    getAppInfo (state) {
      return state.appInfo;
    },
    getWaitForCount (state) {
      return state.waitForCount
    }
  },
  mutations: {
    setMenuList(state, payload) {
      state.menuList = payload;
    },
    // 定义mutations，用于修改状态(同步)
    updateAppInfo (state, payload) {
      state.appInfo = payload;
    },
    updateWaitForCount (state, payload) {
      state.waitForCount = payload
    },
    setTreeData(state,payload){
      state.dataSourceTreeDataLoading = false
      state.dataSourceTreeData = payload
    },
    changeDragItem(state, payload) {
      state.dragItem = payload
    },
    // 修改存储的位置
    changePosition(state, payload) {
      state.dragSetData.forEach(element => {
        if (element.attributes.tableName == payload.id) {
          element.tabStyle = `${payload.x},${payload.y}`
        }
      })
    },
    updateTableList(state, payload) {
      state.selectedTableObj = payload
    },
    updataCanvasData(state, payload) {
      state.canvasData = payload
    }
  },
  actions: {

   afterLoginAction({commit}){
      let roleId = localStorage.getItem("currentRoleId");
      request.getMenuListByRoleId({roleId,projectId:1}).then(res=>{
        commit('setMenuList', res.data.data)
      })
    },
    updateAppInfo (state, payload) {
      state.appInfo = payload;
    },
    updateWaitForCount ({ commit }) {
      request.getWaitingCount().then(res => {
        commit('updateWaitForCount', res?.data?.data?.waitingCount || 0)
      }).catch(() => {
        commit('updateWaitForCount', 0)
      })
    },
    getTreeData({commit}){
      request.getDatasourceTree({appId:localStorage.getItem("appId")}).then((res) => {
        if (res.data.code == 200) {
          // dataSource.tree = res.data.data;
          var tree = [
            {
              dataType: "",
              id: "root",
              key: "root",
              level: 0,
              linkId: null,
              title: "数据源",
              type: "root",
              children: res.data.data,
            },
          ]
          commit('setTreeData', tree)
          sessionStorage.setItem(
            "DatasourceTree",
            JSON.stringify(tree)
          );
        }
      });
    },
    changeDragItem({ commit }, payload) {
      commit('changeDragItem', payload)
    },
    changePosition({ commit }, payload) {
      commit('changePosition', payload)
    },
    updateTableList({ commit }, payload) {
      commit('updateTableList', payload)
    },
    updataCanvasData({ commit }, payload) {
      commit('updataCanvasData', payload)
    }
  },
  modules: {
  }
})
