<template>
  <div class="createSql" style="margin: 28px; padding-bottom: 90px">
    <div class="box-card-radius-eight white-bg">
      <div align="left" class="step-title margin-botton-12">
        <strong>基本信息</strong>
      </div>
      <el-form
        ref="ruleFormRef"
        :model="form"
        :rules="basicRules"
        label-position="top"
      >
        <el-row :gutter="48">
          <el-col :span="8" align="left">
            <el-form-item
              label="API名称"
              placeholder="请输入名称"
              prop="apiName"
            >
              <el-input
                v-model="form.apiName"
                maxlength="100"
                show-word-limit
                @change="formChange"
                
              />
            </el-form-item>
          </el-col>
          <el-col :span="16" align="left">
            <el-form-item label="URL" placeholder="请输入URL" prop="apiUrl">
              <el-input
                v-model.trim="form.apiUrl"
                :disabled="isEdit"
                maxlength="200"
              >
              <template #prepend>{{domain}}</template>
                   <template #append>
                      <el-tooltip content="复制完整URL">
                        <el-button  type="text" icon="CopyDocument" @click="copyUrl"/>
                      </el-tooltip>
                    </template>
                  </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" align="left">
            <el-form-item label="挂载目录" prop="categoryId">
              <el-tree-select
                    v-model="form.categoryId"
                    placeholder="请选择挂载目录"
                    :data="treeData"
                    filterable
                    check-strictly
                    :render-after-expand="false"
                    :props="{
                      children: 'children',
                      label: 'categoryName',
                      value:'categoryId'
                    }"
                    style="width: 100%"
                  />
                  
            </el-form-item>
          </el-col>
          <el-col :span="8" align="left">
            <el-form-item label="请求方式" prop="apiRequestM">
              <el-select
                    v-model="form.apiRequestM"
                    filterable
                    placeholder="请选择请求方式"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in Requests"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" align="left" v-if="form.apiServicetype === 'select'">
                <el-form-item label="返回数据结构" prop="resultDataBuild" required>
                   <el-select
                   :disabled="isView"
                    v-model="form.resultDataBuild"
                    filterable
                    placeholder="请选择返回数据结构"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in resultDataBuilds"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
          <el-col :span="8" align="left">
            <el-form-item label="业务分类" prop="businessCateId">
              <el-cascader
                v-model="form.businessCateId"
                :options="businessCateOptions"
                :props="{
                  value: 'businessCateId',
                  label: 'businessCateName',
                  children: 'child',
                  expandTrigger: 'hover',
                }"
                @change="changebusinessCateId"
                style="width: 100%"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8" align="left" >
            <el-form-item label="服务类型" prop="apiServicetype">
              <el-select
                v-model="form.apiServicetype"
                
                placeholder="请选择服务类型"
                style="width: 100%"
              >
                <el-option
                  v-for="item in apiServicetypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" align="left">
            <el-form-item label="服务版本" prop="apiVersion">
              <el-select
                v-model="form.apiVersion"
                
                placeholder="请选择服务版本"
                style="width: 100%"
                 @change="changeVersion"
              >
                <el-option
                  v-for="item in apiVersionList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" align="left">
            <el-form-item label="是否安全认证" prop="apiIssecurity">
              <el-radio-group
                v-model="form.apiIssecurity"
              >
                <el-radio label="Y">是</el-radio>
                <el-radio label="N">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8" align="left">
            <el-form-item label="API渠道" prop="apiType">
              <el-radio-group
                v-model="form.apiType" 
                @change="getApiType"
              >
                <el-radio label="route_api">内部API</el-radio>
                <el-radio label="external_api">外部API</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8" align="left" v-if="form.apiType === 'route_api'">
            <el-form-item
              placeholder="注册中心项目"
              prop="registryCenterId"
            >
            <template #label>
              <span style="color:red;">*</span>注册中心项目
            </template>
              <el-select
                @change="getApiCallUrl"
                style="width: 100%"
                v-model="form.registryCenterId"
                placeholder="请选择注册中心项目"
              >
                <el-option v-for="project in projectList" :key="project.registerProjectId" :label="project.projectCnname" :value="project.registerProjectId"></el-option>
              </el-select>
             
            </el-form-item>
          </el-col>
          <el-col :span="8" align="left" v-if="form.apiType === 'external_api'">
            <el-form-item
              label="源端URL"
              placeholder="多URL请用 ; 分号隔开"
              prop="sourceUrls"
            >
            <template #label>
              <span style="color:red;">*</span>源端URL
            </template>

              <el-input
                v-model="form.sourceUrls"
                maxlength="200"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" align="left" v-if="form.apiType === 'external_api'">
            <el-form-item label="源请求方式" prop="sourceRequestM" >
              <template #label>
              <span style="color:red;">*</span>源请求方式
            </template>
             <el-select
                    v-model="form.sourceRequestM"
                    filterable
                    placeholder="请选择请求方式"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in sourceRequests"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" align="left" v-if="form.apiType === 'external_api'">
            <el-form-item label="源端请求数据类型" prop="sourceSendtype">
              <template #label>
              <span style="color:red;">*</span>源端请求数据类型
            </template>
              <el-select
                style="width: 100%"
                v-model="form.sourceSendtype"
                placeholder="请选择源端请求数据类型"
              >
                <el-option label="application/form-data" value="application/form-data"></el-option>
                <el-option label="application/json" value="application/json"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" align="left">
            <el-form-item label="API责任人" prop="apiOwner">
              <el-select
                v-model="form.apiOwner"
                @change="changeApiOwner"
                filterable
                remote
                reserve-keyword
                placeholder="请输入搜索关键字"
                :remote-method="getdesignUser"
                style="width: 100%"
              >
                <el-option
                  v-for="item in designUserList"
                  :key="item.userId"
                  :label="item.userName + ' ' +item.account"
                  :value="item.userId"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="描述">
          <el-input
            v-model="form.apiDescription"
            maxlength="1000"
            show-word-limit
            type="textarea"
            :rows="4"
          />
        </el-form-item>
      </el-form>
    </div>

    <div class="box-card-radius-eight white-bg" style="margin-top: 20px">
      <div align="left" class="step-title margin-botton-12 margin-top-24">
        <strong>入参配置</strong>
      </div>
      <div align="left" class="table-btn__up margin-botton-12">
        <el-button type="primary" icon="plus" @click="addRow()"
          >新增输入参数</el-button
        >
      </div>
      <el-table :data="requestParamters" style="width: 100%" max-height="285">
        <el-table-column
          prop="paramNameCm"
          label="参数中文名称"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <el-input
              v-model="scope.row.paramNameCm"
              clearable
              maxlength="50"
              placeholder="请输入参数中文名称"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="paramName"
          label="参数名称"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <el-input
              v-model="scope.row.paramName"
              clearable
              maxlength="50"
              placeholder="请输入参数名称"
              :disabled="scope.row.isDisable"
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="parameterType" label="参数类型">
          <template #default="scope">
            <el-select
              v-model="scope.row.parameterType"
              clearable
              placeholder="请选择参数类型"
            >
              <el-option label="动态参数" value="dynamicParamter" />
              <el-option label="静态参数" value="staticParamter" />
              <el-option label="认证参数" value="authenticationParameters" />
            </el-select>
          </template>
        </el-table-column>

        <el-table-column
          prop="paramValue"
          label="默认值"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <el-select
              v-if="scope.row.parameterType === 'authenticationParameters'"
              v-model="scope.row.paramValue"
                clearable
                filterable
                placeholder="请输入搜索关键字"
              style="width: 100%"
              >
                    <el-option
                      v-for="parameter in authenticationParameterList"
                      :key="parameter.apiAuthId"
                      :label="parameter.apiAuthName"
                      :value="parameter.apiAuthId"
                    />
            </el-select>

            <el-input
              v-else
              v-model="scope.row.paramValue"
              clearable
              maxlength="50"
              placeholder="请输入默认值"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="paramType" label="数据类型">
          <template #default="scope">
            <el-select
              v-model="scope.row.paramType"
              clearable
              placeholder="请选择数据类型"
            >
             <el-option v-for="type in paramsTypes" :key="type.label" :label="type.label" :value="type.value" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
              prop="paramIsMust"
              label="是否必填写"
            >
              <template #default="scope">
                <el-select v-model="scope.row.paramIsMust" default="N" clearable>
                  <el-option value="Y" label="是" />
                  <el-option value="N" label="否" />
                </el-select>
              </template>
            </el-table-column>
        <el-table-column
          prop="parameterPosition"
          label="参数位置"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <el-select
              v-model="scope.row.parameterPosition"
              clearable
              placeholder="请选择参数位置"
            >
              <el-option label="Header" value="Header" />
              <el-option label="URLPath" value="URLPath" />
              <el-option label="QueryParam" value="QueryParam" />
              <el-option label="Body" value="Body" />
            </el-select>
          </template>
        </el-table-column>
       <el-table-column
              prop="example"
              label="示例值"
              width="200"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                  clearable
                  v-model="scope.row.example"
                  placeholder="请输入"
                  maxlength="50"
                ></el-input>
              </template>
            </el-table-column>
        <el-table-column
          prop="paramDescription"
          label="描述"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <el-input
              v-model="scope.row.paramDescription"
              clearable
              placeholder="请输入描述"
              maxlength="50"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center">
          <template #default="scope">
            <el-popconfirm
              title="删除后将无法恢复，请确认是否删除？"
              confirm-button-type="danger"
              icon="warning-filled"
              icon-color="#F56C6C"
              width="250"
              placement="top-end"
              @confirm="deleteRow(scope.$index)"
            >
              <template #reference>
                <el-button
                  icon="delete"
                  type="danger"
                  text
                  :disabled="scope.row.isDisable"
                ></el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="box-card-radius-eight white-bg" style="margin-top: 20px">
      <div align="left" class="step-title margin-botton-12 margin-top-24">
        <strong>出参配置</strong>
      </div>
      <div align="left" class="table-btn__up margin-botton-12">
        <el-button type="primary" icon="plus" @click="addResponseParamtersRow()"
          >新增输出参数</el-button
        >
      </div>
      <el-table
        ref="mappingDataList"
        :data="responseParamters"
        style="width: 100%"
        max-height="285"
    
      >
        <el-table-column
          prop="paramNameCm"
          label="参数中文名称"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <el-input
              v-model="scope.row.paramNameCm"
              clearable
              maxlength="50"
              placeholder="请输入参数中文名称"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="paramName"
          label="参数名称"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <el-input
              v-model="scope.row.paramName"
              clearable
              maxlength="50"
              placeholder="请输入参数名称"
            ></el-input>
          </template>
        </el-table-column>
         <el-table-column prop="paramType" label="数据类型">
          <template #default="scope">
            <el-select
              v-model="scope.row.paramType"
              clearable
              placeholder="请选择数据类型"
            >
              <el-option v-for="type in paramsTypes" :key="type.label" :label="type.label" :value="type.value" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
              prop="example"
              label="示例值"
              width="200"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                  clearable
                  v-model="scope.row.example"
                  placeholder="请输入"
                  maxlength="50"
                ></el-input>
              </template>
            </el-table-column>
        <el-table-column
          prop="fieldDescription"
          label="描述"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <el-input
              v-model="scope.row.fieldDescription"
              clearable
              placeholder="请输入描述"
              maxlength="50"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center">
          <template #default="scope">
            <el-popconfirm
              title="删除后将无法恢复，请确认是否删除？"
              confirm-button-type="danger"
              icon="warning-filled"
              icon-color="#F56C6C"
              width="250"
              placement="top-end"
              @confirm="deleteResponseParamtersRow(scope.$index)"
            >
              <template #reference>
                <el-button
                  icon="delete"
                  type="danger"
                  text
                  :disabled="scope.row.isDisable"
                ></el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-form  :model="form"  label-position="top" style="padding-top:20px;">
                <el-row :gutter="24">
                    <el-col :span="24" align="left">
                    <el-form-item label="返回结果示例">
                      <span style="color:#606266;padding-right:20px;">是否作为Mock数据</span> 
                      <el-radio-group v-model="form.isMock">
                              <el-radio label="0">是</el-radio>
                              <el-radio label="1">否</el-radio>
                      </el-radio-group>
                      <CodeMirror  class="codemirror" v-model:value="form.apiResponseE"  ></CodeMirror>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" align="left">
                  <el-form-item label="Mock数据" v-if="form.isMock === '1'"> 
                        <CodeMirror  class="codemirror"  v-model:value="form.mockData" ></CodeMirror>
                  </el-form-item>
                    </el-col>
                </el-row>
      </el-form>
    </div>

    <div class="footer" align="right">
      <div class="btn">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { request } from "@/api";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import useClipboard from 'vue-clipboard3'
import { paramsTypesList,RequestList,sourceRequestsList,resultDataBuildList } from '@/utils/constant'


import CodeMirror from '@/components/common/CodeMirror'
export default {
  name: "toDevelopServices",
  components:{
    CodeMirror
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const paramsTypes = ref(paramsTypesList)
    const Requests = ref(RequestList)
    const resultDataBuilds = ref(resultDataBuildList)
    const sourceRequests = ref(sourceRequestsList)
    let authenticationParameterList = ref([])
    const isEdit = ref(route?.query.routeApi?true:false)
    let domain = ref('')
    let treeData = ref([]);
    let responseParamters = ref([]);
    let requestParamters = ref([]);
    let businessCateOptions = ref([]);
    let apiVersionList = ref([
      {
        value: '1',
        label: '1',
        apiVersion:'1'
      },
    ]);
    let projectList = ref([])
    let userList = ref([]);
    let designUserList = ref([]);
    let apiServicetypeList = ref([
      {
        value: "select",
        label: "查询服务",
      },
      {
        label: "写入服务",
        value: "insert",
      },
      {
        label: "修改服务",
        value: "update",
      },
      {
        label: "删除服务",
        value: "delete",
      },
    ]);
    let ruleFormRef = ref(null);
    let form = ref({
      apiResponseE:'',
      mockData:'',
      isMock:'0',
      resultDataBuild:'',
      sourceSendtype:'',
      sourceRequestM:'',
      sourceUrls:'',
      registryCenterId:'',
      apiType:'route_api',
      apiServicetype: "",
      apiIssecurity: "Y",
      apiVersion: 1,
      businessCateId: [], //分类ID
      apiName: "",
      apiRequestM: "1",
      responseParamters: [],
      apiUrl: "",
      apiOwner: localStorage.getItem('userName') + ' ' + localStorage.getItem('account'),
      copyApiOwner:localStorage.getItem('userId'),
      apiOwnerName: "",
      apiOwnerNumber: "",
      apiDescription: "",
      categoryId: "",
    });

    const validateUrl = (rule, value, callback) => {
      const reg = /((\/[a-zA-Z0-9_-]+){1,}(({[a-zA-Z0-9]+}){0,}))$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(
          new Error(
            "支持英文，数字，下划线，连接符（-），不超过200个字符，且只能以 / 开头, 以英文，数字结尾"
          )
        );
      }
    };
     const validateResultDataBuild = (rule, value, callback) => {
      if (!value && form.value.apiServicetype === 'select') {
        callback(
          new Error( "请选择返回数据结构")
        );
      } else {
        callback();
      }
    };
    const basicRules = reactive({
      apiName: [
        { required: true, message: "请输入API名称", trigger: "blur" },
        {
          min: 1,
          max: 100,
          message: "长度在 1 到 100 个字符",
          trigger: "blur",
        },
      ],
      registryCenterId: [
        { validator: (rule, value, callback) => {
          if (!value && form.value.apiType === 'route_api') {
            callback(new Error("请选择注册中心项目"))
          } else {
            callback();
          }
        }, trigger: ["blur","change"] },
      ],
      sourceSendtype: [
        { validator: (rule, value, callback) => {
          if (!value && form.value.apiType === 'external_api') {
            callback(new Error("请选择源端请求数据类型"))
          } else {
            callback();
          }
        }, trigger: "blur" },
      ],
      sourceRequestM: [
        { validator: (rule, value, callback) => {
          if (!value && form.value.apiType === 'external_api') {
           callback(new Error("请选择源请求方式"))
          } else {
            callback();
          }
        }, trigger: "blur" },
      ],
      sourceUrls: [
        { validator: (rule, value, callback) => {
          if (!value && form.value.apiType === 'external_api') {
            callback(new Error("请输入源端URL"))
          } else {
            callback();
          }
        }, trigger: ["change","blur"] },
      ],
      apiType: [
        { required: true, message: "请选择API渠道", trigger: "change" },
      ],
      apiRequestM: [
        { required: true, message: "请选择请求方式", trigger: "change" },
      ],
      resultDataBuild: [
        { validator: validateResultDataBuild, trigger: ["blur","change"] },
      ],
      apiVersion: [
        { required: true, message: "请选择服务版本", trigger: "change" },
      ],
      businessCateId: [
        { required: true, message: "请选择业务分类", trigger: "change" },
      ],
      categoryId: [
        { required: true, message: "请选择挂载目录", trigger: "change" },
      ],
      apiUrl: [
        { required: true, message: "请输入URL", trigger: "blur" },
        {
          min: 1,
          max: 200,
          message: "长度在 1 到 200 个字符",
          trigger: "blur",
        },
        { validator: validateUrl, trigger: "blur" },
      ],
      apiOwner: [
        { required: true, message: "请选择API责任人", trigger: "change" },
      ],
      apiServicetype: [
        { required: true, message: "请选择服务类型", trigger: "change" },
      ],
    });

    const changeApiType = (val)=>{
      console.log(val)
    }

    const addRow = () => {
      requestParamters.value.push({
        paramNameCm: "",
        paramName: "",
        paramValue: "",
        paramType: "",
        parameterType:"",
        paramDescription: "",
        sort: 0,
        paramIsMust: "N",
        paramFun: "",
      }); //在requestParamters表格数组中添加对象
    };

    const deleteRow = (index) => {
      requestParamters.value.splice(index, 1);
    };

    const addResponseParamtersRow = () => {
      responseParamters.value.push({
        apiFieldCnname: "",
        dbFieldName: "",
        dbFieldType: "",
        dbFieldLength: "",
        apiFieldName: "",
        apiFieldType: "",
        apiFieldLength: "",
        sort: 0,
        fieldDescription: "",
        paramFun: "",
      }); //在requestParamters表格数组中添加对象
    };

    const deleteResponseParamtersRow = (index) => {
      responseParamters.value.splice(index, 1);
    };

  
    const getTreeData = () => {
      //查询列表
      request
        .getListTree({ appId: localStorage.getItem("appId"), filterText: "" })
        .then((res) => {
          if (res.data.code == 200) {
            treeData.value =res.data.data.children || []
          }
        });
    };

    const getBusinessCateOptions = () => {
      request
        .selectBusinessCatePage({
          currentPage: 1,
          pageSize: 10000,
        })
        .then((res) => {
          if (res?.data?.code === 200) {
            businessCateOptions.value = res.data.data;
          }
        });
    };

    const getdesignUser = (query) => {
      getUserList(query, "apiOwner");
    };

    const getApiDesigners = (query) => {
      getUserList(query, "apiDesigner");
    };

    const getUserList = (query, type) => {
      if (query) {
        request
          .selectUserByParams({
            likeData: query,
            appId: localStorage.getItem("appId"),
            currentPage: 1,
            pageSize: 10000,
          })
          .then((res) => {
            if (res?.data?.code === 200) {
              if (type === "apiOwner") {
                designUserList.value = res.data.data.result;
              } else {
                userList.value = res.data.data.result;
              }
            }
          });
      } else {
        if (type === "apiOwner") {
          designUserList.value = [];
        } else {
          userList.value = [];
        }
      }
    };

    const changeApiOwner = (val) => {
      let user = designUserList.value.filter((item) => {
        return item.userId === val;
      });
      if (user.length > 0) {
        form.value.apiOwnerName = user[0].userName;
        form.value.apiOwnerNumber = user[0].account;
      }
      form.value.copyApiOwner = val
    };

    const saveRouter = () => {
      let saveParams = {
        ...form.value,
        oneBusinessCateId: form.value.businessCateId[0],
        twoBusinessCateId: form.value.businessCateId[1],
        requestParamters: JSON.stringify(requestParamters.value),
        responseParamters: JSON.stringify(responseParamters.value),
        appId: localStorage.getItem("appId"),
        routeApi: route.query.routeApi ? route.query.routeApi : null,
        apiOwner: form.value.copyApiOwner
      };

      if(isEdit.value && (form.value.apiVersion !== apiVersionList.value[apiVersionList.value.length-1].value)){
        //服务版本转换
        saveParams.apiVersion = apiVersionList.value.filter(item=>{
          return item.value == form.value.routeApi
        })[0].apiVersion
      }

      let tip 
      if (route.query.routeApi) {
        saveParams.operateType = "1";
        tip = '编辑'
      }else{
        tip = '创建'
      }

      request.addOrUpdateRouteApi(saveParams).then((resp) => {
        let respCode = resp.data.code;
        if (respCode == "200") {
          ElMessage.success(tip + "成功");
          cancel();
        } else {
          ElMessage.error(resp.data.msg);
        }
      });
    };
    const save = async () => {
      if (!ruleFormRef.value) return;
      await ruleFormRef.value.validate((valid) => {
        if (valid) {
          saveRouter();
        }
      });
    };

    const cancel = () => {
      router.push("/home/ApiManage/ApiManageRegister");
    };

    const getInfo = (routeApi) => {
      request.findRouteApiDetails(routeApi).then((res) => {
        let result = res.data;
        if (result.code == "200") {
          form.value = {...result.data}
          form.value.businessCateId = [
            result.data.oneBusinessCateId,
            result.data.twoBusinessCateId,
          ];
          form.value.copyApiOwner =  form.value.apiOwner
          form.value.apiOwner = form.value.apiOwnerName + ' ' + form.value.apiOwnerNumber
          requestParamters.value = JSON.parse(result.data.requestParamters);
          responseParamters.value = JSON.parse(result.data.responseParamters);
          form.value.apiVersion = form.value.routeApi
          findApiVersion(form.value.baseRouteApi)
          getApiCallUrl()
        } else {
          ElMessage.error("异常");
        }
      });
    };

    const changeVersion = (val) =>{
        if(val === apiVersionList.value[apiVersionList.value.length-1].value) return
        getInfo({  apiUrl:form.value.apiUrl,routeApi:val})
    }

    const findApiVersion = (baseApiId)=>{
      request.findApiVersion({
        baseApiId,
      }).then((res) => {
            if (res?.data?.code === 200) {
               if(res.data.data.length > 1){
                apiVersionList.value = res.data.data.map((item,index)=>{
                  return {
                    value:item.apiId || item.apiVersion,
                    apiVersion:item.apiVersion,
                    label: res.data.data.length - 1 === index?item.apiVersion+ " (复刻版本)": item.apiRunStatus === 'Y'?item.apiVersion+ " (正式版本)" : item.apiVersion+ " (编辑版本)"
                  }
                })
              }
            } else {
                ElMessage.error(res?.data?.msg);
            }
      });
    }

    const getProjectList = async () => {
      let res = await request.pageRegisterProject({
        currentPage:1,
        pageSize:10000,
        appId:localStorage.getItem('appId')
      }); 
      if (res?.data?.code === 200) {
        projectList.value = res.data.data.result.filter(item=>{
          return item.projectStatus == 2
        });
      } else {
        ElMessage.error("失败");
      }
    };

    const getApiType = (val)=>{
      if(val === 'external_api'){
        form.value.registryCenterId = null
      }
      getApiCallUrl()
    }
    const getApiCallUrl = ()=>{
      request.getApiCallUrl({
        "apiType":'external_api',
        "apiUrl":'/test',
        status:'online',
        registryCenterId:form.value.apiType === 'route_api'? form.value.registryCenterId:null,
      }).then((res) => {
            if (res?.data?.code === 200) {
                domain.value = res.data.data.domin + res.data.data.projectpath + res.data.data.urlprefix
            } else {
                ElMessage.error(res?.data?.msg);
            }
      });
    }

    const copyUrl = async ()=>{
       const {toClipboard} = useClipboard()
        try{
          await toClipboard(domain.value + form.value.apiUrl)
          ElMessage.success('复制成功');
        } catch (e){
          ElMessage.error('复制失败');
        }
    }

    const getApiAuthList = async ()=>{
        request
          .pageListAuth({
            currentPage: 1,
            pageSize: 1000,
          })
          .then((res) => {
            if (res?.data?.code === 200) {
               authenticationParameterList.value = res.data.data.result
            }
          });
      }

    onMounted(() => {
      getApiAuthList()
      getBusinessCateOptions();
      getTreeData();
      getProjectList()
      if (route.query.operation === "edit") {
        let routeApi = route.query.routeApi;
        getInfo({routeApi});
      }else{
        getApiCallUrl()
      }
    });
    return {
      getApiAuthList,
      authenticationParameterList,
      isEdit,
      findApiVersion,
      changeVersion,
      paramsTypes,
      Requests,
      sourceRequests,
      resultDataBuilds,
      copyUrl,
      getApiCallUrl,
      getApiType,
      domain,
      projectList,
      getProjectList,
      addResponseParamtersRow,
      deleteResponseParamtersRow,
      ruleFormRef,
      getUserList,
      getApiDesigners,
      getdesignUser,
      designUserList,
      changeApiOwner,
      basicRules,
      form,
      getTreeData,
      treeData,
      businessCateOptions,
      getBusinessCateOptions,
      requestParamters,
      responseParamters,
      userList,
      apiServicetypeList,
      apiVersionList,
      changeApiType,
      addRow,
      deleteRow,
      save,
      cancel,
    };
  },
};
</script>

<style lang="less" scoped>
.footer {
  margin-top: 24px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 64px;
  line-height: 64px;
  background: #fff;
  z-index: 10;
  .btn {
    padding-right: 24px;
  }
}
</style>
