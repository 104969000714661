<template>
  <div style="height:260px" class="main_bgimg bg_img1">
    <div class="main_header">
      <div class="main_header_text">{{ text }}</div>
    </div>
    <div class="main_content iasm-flex">
      <div class="echarts">
        <div id="sub-dist-echart" :style="{ height: '200px', width: '200px' }"></div>
      </div>
      <div class="number">
        <ul>
          <li>
            <span>订阅</span>
            <span><strong>{{ state.apiBeSubscribedCount }}</strong>个</span>
            <span><strong>{{ state.apiBeSubscribedCountPercentage }}</strong>%</span>
          </li>
          <li>
            <span>未订阅</span>
            <span><strong>{{ state.apiNotSubscribedCount }}</strong>个</span>
            <span><strong>{{ state.apiNotSubscribedCountPercentage }}</strong>%</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted, watch } from "vue";
import * as echarts from 'echarts'
import 'echarts-gl'
import {request} from '@/api'
import { ElMessage } from 'element-plus'
export default {
  name: "LeftMiddle",
  props: {
    appId: {
      type: String,
    },
  },
  setup(props) {
    onMounted(() => {
      initeCharts()
    })

    const text = ref("API订阅分布");
    const optionsData = [
        {
          name: '订阅',
          value: 0,
          itemStyle: {
            color: '#0072ff'
          }
        },
        {
          name: '未订阅',
          value: 0,
          itemStyle: {
            color: '#f78012'
          }
        }
      ]
    const state = reactive({
      apiBeSubscribedCount: 0,
      apiBeSubscribedCountPercentage: '0',
      apiNotSubscribedCount: 0,
      apiNotSubscribedCountPercentage: '0',
      options: {
        series: [
          {
            name: 'pie2d',
            type: 'pie',
            radius: [45, 60],
            center: ['50%', '50%'],
            color: [
              '#0072ff',
              '#f78012'
            ],
            avoidLabelOverlap: true,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            startAngle: -20,
            clockwise: false,
            data: optionsData,
            itemStyle: {
              opacity: 0
            }
          }
        ]
      },
      
    })

    let options = {}

    const initData = async () => {
      return new Promise((resolve, reject) => {
        request.apiSubscriptionDistribution({ appId: props.appId }).then((res) => {
          if(res.status === 200) {
            const { code, data } = res.data
            if (code === 200) {
              resolve(data)
            } else {
              reject()
            }
          } else {
            reject()
          }
        }).catch(err => {
          reject(err)
        })
      })
    }

    const initeCharts = () => {
      initData().then(data => {
        optionsData[0].value = data.apiBeSubscribedCount
        optionsData[1].value = data.apiNotSubscribedCount
        state.apiBeSubscribedCount = data.apiBeSubscribedCount
        state.apiBeSubscribedCountPercentage = data.apiBeSubscribedCountPercentage.replace('%', '')
        state.apiNotSubscribedCount = data.apiNotSubscribedCount
        state.apiNotSubscribedCountPercentage = data.apiNotSubscribedCountPercentage.replace('%', '')
      }).catch(err => {
        ElMessage.error(err || 'API分布初始化错误')
      }).finally(() => {
        let myChart = echarts.init(document.getElementById('sub-dist-echart'))
        // 绘制图表
        options = getPie3D(optionsData, 0.8)
        // myChart.setOption(options)
        options.series.push(state.options.series[0])
        myChart.setOption(options)
        // 根据页面大小自动响应图表大小
        window.addEventListener("resize", function () {
          myChart.resize()
        })
      })
    }

    const getPie3D = (pieData, internalDiameterRatio) => {
        let series = []
        let sumValue = 0
        let startValue = 0
        let endValue = 0
        let k = 1 - internalDiameterRatio
        for (let i = 0; i < pieData.length; i++) {
          sumValue += pieData[i].value
          let seriesItem = {
            name: pieData[i].name,
            type: 'surface',
            parametric: true,
            wireframe: {
              show: false
            },
            pieData: pieData[i],
            pieStatus: {
              selected: false,
              hovered: false,
              k
            },
            center: ['10%', '50%'],
            itemStyle: {
              color: pieData[i].itemStyle.color
            }
          }
          series.push(seriesItem)
        }
        for (let i = 0; i < series.length; i++) {
          endValue = startValue + series[i].pieData.value
          const startRatio = startValue / sumValue
          const endRatio = endValue / sumValue
          
          series[i].pieData.startRatio = startRatio
          series[i].pieData.endRatio = endRatio
          let h = series[i].pieData.value ? series[i].name === '订阅' ? 28 : 10 : 0
          series[i].parametricEquation = getParametricEquation(startRatio, endRatio, k, h)
          startValue = endValue
        }
        let option = {
          title: {
            text: `{a|${state.apiBeSubscribedCountPercentage}}{b| %}`,
            left: 'center',
            top: 20,
            textStyle: {
              rich: {
                a: {
                  color: '#0bd3fe',
                  fontSize: 32,
                  fontWeight: 'bolder',
                  textShadowColor: '#0bd3fe',
                  textShadowBlur: 4
                },
                b: {
                  color: '#0bd3fe',
                  fontSize: 18,
                }
              }
            }
          },
          xAxis3D: {
            min: -1,
            max: 1
          },
          yAxis3D: {
            min: -1,
            max: 1
          },
          zAxis3D: {
            min: -1,
            max: 1
          },
          grid3D: {
            show: false,
            boxHeight: 8,  
            viewControl: {
              rotateSensitivity: 0,
              zoomSensitivity: 0
            }
          },
          series
        }
        return option
      }

    const getParametricEquation = (startRatio, endRatio, k=1/3, val) => {
      let startRadian = startRatio * Math.PI * 2
      let endRadian = endRatio * Math.PI * 2
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32
        },
        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20
        },
        x: function (u, v) {
          if (u < startRadian) {
            return Math.cos(startRadian) * (1 + Math.cos(v) * k)
          }
          if (u > endRadian) {
            return Math.cos(endRadian) * (1 + Math.cos(v) * k)
          }
          return Math.cos(u) * (1 + Math.cos(v) * k)
        },
        y: function (u, v) {
          if (u < startRadian) {
            return Math.sin(startRadian) * (1 + Math.cos(v) * k)
          }
          if (u > endRadian) {
            return Math.sin(endRadian) * (1 + Math.cos(v) * k)
          }
          return Math.sin(u) * (1 + Math.cos(v) * k)
        },
        z: function (u, v) {
          if(u < -Math.PI * 0.5) {
            return Math.sin(u) + 2.5
          }
          if(u > Math.PI * 2.5) {
            return Math.sin(u) * val * .1 + 2.5
          }
          return (Math.sin(v) > 0 ? 1 * val * .1 : -1) + 2.5;
        }
      }
    }

    watch(
      () => props.appId,
      (newData) => {
        if (newData) {
          initeCharts();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    )
    return {
      text,
      state
    };
  },
};
</script>
<style lang="scss" scoped>
@import "./LeftMiddle.scss";
</style>