<template>
  <div>
    <div align="left" class="step-title margin-botton-12">
        <strong>基础配置</strong>
    </div>
    <el-form
        ref="ruleFormRef"
        :model="form"
        label-position="top"
      >
        <el-row :gutter="24">
          <el-col :span="12" align="left">
            <el-form-item
              label="API类型"
              prop="apiType"
              
            >
              <el-radio-group
                v-model="form.apiType"
                @change="changeApiType"
              >
                <el-radio label="platform_api">开发服务</el-radio>
                <el-radio label="arrange_api">编排服务</el-radio>
                <el-radio label="external_api">注册服务</el-radio>
              </el-radio-group>
            </el-form-item>
             </el-col>
             <el-col :span="12" align="left">
            <el-form-item
              label="API"
              prop="API"
            >
             <el-select
                    v-model="form.apiId"
                      filterable
                      clearable
                      remote
                      reserve-keyword
                      placeholder="请输入搜索关键字"
                      :remote-method="getApiList"
                      @change="changeApi"
                      style="width: 100%"
                  >
                    <el-option
                      v-for="api in apiList"
                      :key="api.apiId"
                      :label="api.apiName + ' (版本' + api.apiVersion +')'"
                      :value="api.apiId"
                      
                    />
                  </el-select>
            </el-form-item>
          </el-col>
           <el-col :span="12" align="left">
            <el-form-item
              label="URL"
              prop="apiUrl"
              
            >  <el-input
                        v-model="form.apiUrl"
                        disabled
                        maxlength="50"
                        
                        ></el-input>
            </el-form-item>
             </el-col>
              <el-col :span="12" align="left">
            <el-form-item
              label="版本"
              prop="apiVersion"
              
            >  <el-input
                        v-model="form.apiVersion"
                        disabled
                        maxlength="50"
                        
                        ></el-input>
            </el-form-item>
             </el-col>
              <el-col :span="12" align="left">
            <el-form-item
              label="API责任人"
              prop="Owner"
              
            >  <el-input
                        v-model="form.Owner"
                        disabled
                        maxlength="50"
                        
                        ></el-input>
            </el-form-item>
             </el-col>
        </el-row>
        </el-form>
       <div align="left" class="step-title margin-botton-12">
        <strong>参数配置</strong>
      </div>
      <el-tabs v-model="mytab">
            <el-tab-pane name="1" label="入参配置">
              <el-button @click="addRequestParams" icon="plus" type="primary" style="margin-bottom:20px;">新增入参</el-button>
               <el-table :data="requestParams" max-height="285">
                        <el-table-column prop="paramName" label="参数名称">
                          <template #default="scope"><el-input v-model="scope.row.paramName" clearable></el-input></template>
                        </el-table-column>
                        <el-table-column prop="source" label="值来源">
                          <template #default="scope">
                             <el-select
                                v-model="scope.row.source"
                                filterable
                                style="width: 100%"
                            >
                              <el-option label="API入参" value="api_input_paramter"/>
                              <el-option label="上游结果数据" value="node_result_data"/>
                              <el-option label="固定常量" value="api_constant"/>
                             </el-select>
                          </template>
                        </el-table-column>
                        <el-table-column prop="paramValue" label="值"> 
                           <template #default="scope">
                            <el-select
                                v-if="scope.row.source === 'api_input_paramter'"
                                v-model="scope.row.paramValue"
                                clearable
                                placeholder="请选择"
                                style="width:100%;"
                                >
                                <el-option v-for="api in apiInputParamters" :key="api.paramName" :label="api.paramName" :value="api.paramName" />
                            </el-select>
                            <el-input
                                 v-else
                                v-model="scope.row.paramValue"
                                clearable
                                maxlength="50"
                                placeholder="请输入"
                            ></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="操作" width="80" align="center">
                            <template #default="scope">
                                <el-popconfirm
                                title="删除后将无法恢复，请确认是否删除？"
                                confirm-button-type="danger"
                                icon="warning-filled"
                                icon-color="#F56C6C"
                                width="250"
                                placement="top-end"
                                @confirm="deleteRow(scope.$index)"
                                >
                                <template #reference>
                                    <el-button
                                    icon="delete"
                                    type="danger"
                                    text
                                    ></el-button>
                                </template>
                                </el-popconfirm>
                            </template>
                            </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane name="2" label="出参配置">
               <el-button @click="addResponseParams" icon="plus" type="primary" style="margin-bottom:20px;">新增出参</el-button>
               <el-table :data="apiResponse" max-height="285"> 
                        <el-table-column prop="apiFieldCnname" label="参数中文名称">
                          <template #default="scope"><el-input v-model="scope.row.apiFieldCnname" clearable></el-input></template>
                        </el-table-column>
                        <el-table-column prop="apiFieldName" label="参数名称" :show-overflow-tooltip="true">
                          <template #default="scope"><el-input v-model="scope.row.apiFieldName" clearable></el-input></template>
                        </el-table-column>
                        <el-table-column prop="apiFieldType" label="参数类型">
                          <template #default="scope">
                            <el-select
                              :disabled="isView"
                                v-model="scope.row.apiFieldType"
                                placeholder="请选择数据类型"
                              >
                              <el-option v-for="type in paramsTypes" :key="type.label" :label="type.label" :value="type.value" />
                            </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column prop="example" label="示例值">
                          <template #default="scope"><el-input v-model="scope.row.example" clearable></el-input></template>
                        </el-table-column>
                        <el-table-column prop="fieldDescription" label="描述">
                          <template #default="scope"><el-input v-model="scope.row.value" clearable></el-input></template>
                        </el-table-column>
                        <el-table-column label="操作" width="80" align="center">
                            <template #default="scope">
                                <el-popconfirm
                                  title="删除后将无法恢复，请确认是否删除？"
                                  confirm-button-type="danger"
                                  icon="warning-filled"
                                  icon-color="#F56C6C"
                                  width="250"
                                  placement="top-end"
                                  @confirm="deleteResponseRow(scope.$index)"
                                >
                                <template #reference>
                                    <el-button
                                    icon="delete"
                                    type="danger"
                                    text
                                    ></el-button>
                                </template>
                                </el-popconfirm>
                            </template>
                            </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
  </div>
</template>

<script>
import { ref,onMounted,onBeforeUnmount } from 'vue';
import {expressionOptions,paramsTypesList} from '@/utils/constant'

import {request} from '@/api'

export default {
   props:['iasmApiArrangeNode','iasmApiArrange'],
    setup(props){
        let parentout = ref([])
        let paramsTypes = ref(paramsTypesList)
        let form = ref({
            apiType:'platform_api',
            apiId:null,
            apiName:null,
            apiUrl:null,
            apiVersion:null,
            Owner:null,
        })
        let mytab = ref('1')
        let apiList = ref([])
        let requestParams = ref([])
        let apiResponse = ref([])
        let expressionOptionsMap = ref(expressionOptions)
        let activeItem = ref('0')
        let apiInputParamters = ref([])

        const getNodeData = ()=>{
            return {
                data:form.value,
                out:apiResponse.value,
                in: requestParams.value,
                parentout:parentout.value 
            }
        }

        const addRequestParams = ()=>{
          requestParams.value.push(
            {
              paramName:null,
              source:null,
              paramValue:null,
            }
          )
        }

        const addResponseParams = ()=>{
          apiResponse.value.push({
            apiFieldCnname:null,
            apiFieldName:null,
            apiFieldType:null,
            example:null,
            fieldDescription:null,
          })
        }


        const deleteRow = (index)=>{
          requestParams.value.splice(index, 1);
        }

        const reset = ()=>{
            form.value = {
            apiType:'platform_api',
            apiId:null,
            apiName:null,
            apiUrl:null,
            apiVersion:null,
            Owner:null,
        }
        requestParams.value = []
        apiResponse.value = []

        }

        const deleteResponseRow = (index)=>{
          apiResponse.value.splice(index, 1);
        }
        const changeApiType = ()=>{
          
          form.value.apiId = null
          requestParams.value = []
          apiResponse.value = []
        }

        const changeApi = (apiId)=>{
          if(!apiId){
             requestParams.value = []
             apiResponse.value  = []
             form.value.apiName = null
             form.value.apiUrl = null
             form.value.apiVersion = null
             form.value.Owner = null
          
          }
          request.getApiInfo({apiId,operateType: "7",}).then((res) => {
            let result = res.data.data;
            if (result) {
            let list = result.requestParamters?JSON.parse(result.requestParamters) : [];
            requestParams.value =  list.filter(item=>{
              return item.parameterType !== 'authenticationParameters'
            })
            apiResponse.value =  result.responseParamters?JSON.parse(result.responseParamters) : []
            form.value.apiName = result.apiName
            form.value.apiUrl = result.apiUrl
            form.value.apiVersion = result.apiVersion
            form.value.Owner = result.apiOwnerName + ' ' + result.apiOwnerNumber

            
          }
        });
        }

        const getApiList = (query) => {
          if (query && form.value.apiType) {
            request
              .queryApiSubscribeList({
                apiName: query,
                apiType:form.value.apiType,
                appId: localStorage.getItem("appId"),
                currentPage: 1,
                pageSize: 10000,
              })
              .then((res) => {
                if (res?.data?.code === 200) {
                  apiList.value = res.data.data;
                }
              });
          } else {
            apiList.value = [];
          }
        };

        const validate = ()=>{
          return true
        }
        onMounted(()=>{
          if(props.iasmApiArrangeNode && props.iasmApiArrangeNode.nodeData){
            let nodeData = JSON.parse(props.iasmApiArrangeNode.nodeData)
            form.value = nodeData.data
            getApiList(form.value.apiName)
            requestParams.value = nodeData.in || []
            apiResponse.value = nodeData.out || []
            parentout.value = nodeData.parentout
          }
          apiInputParamters.value =  props.iasmApiArrange.apiInputParamters?JSON.parse(props.iasmApiArrange.apiInputParamters):[]
        })
        

        onBeforeUnmount(() => {
         reset()
        });

        return{
            form,
            expressionOptionsMap,
            activeItem,
            deleteRow,
            deleteResponseRow,
            addRequestParams,
            addResponseParams,
            mytab,
            requestParams,
            apiResponse,
            getApiList,
            changeApi,
            changeApiType,
            apiList,
            getNodeData,
            reset,
            apiInputParamters,
            validate,
            paramsTypes
        }
    }

}
</script>

<style lang="less" scoped>
.step-title{
    margin-top: 50px;
}
</style>