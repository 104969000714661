<template>
  <div class="createSql" style="margin:28px;padding-bottom:90px;">
    <div  class="box-card-radius-eight white-bg">
     <div align="left" class="step-title margin-botton-12"><strong>基本信息</strong></div>
          <el-form ref="ruleFormRef" :model="form" :rules="basicRules" label-position="top">
            <el-row :gutter="48">
              <el-col :span="8" align="left">
                <el-form-item label="项目中文名称" placeholder="请输入项目中文名称" prop="projectCnname">
                  <el-input v-model="form.projectCnname" maxlength="100" show-word-limit />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="项目英文名称" placeholder="项目英文名称" prop="projectEnname">
                  <el-input v-model="form.projectEnname" maxlength="100" show-word-limit :disabled="idEdit" />
                </el-form-item>
              </el-col>
             <el-col :span="8" align="left">
                <el-form-item label="项目URI" placeholder="微服务地址,例如:lb://baseservice" prop="projectUri">
                  <el-input v-model="form.projectUri" maxlength="100" show-word-limit  />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="项目路径" placeholder="项目路径" prop="projectPath">
                  <el-input v-model="form.projectPath" maxlength="100" show-word-limit  />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="项目责任人" prop="projectOwner">
                  <el-select
                    v-model="form.projectOwner"
                    @change="changeProjectOwner"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="请输入搜索关键字"
                      :remote-method="getProjectOwner"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in userList"
                      :key="item.userId"
                      :label="item.userName + ' ' +item.account"
                      :value="item.userId"
                      
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="作用范围" prop="projectScope">
                  <el-radio-group v-model="form.projectScope" >
                    <el-radio label="private">当前应用</el-radio>
                    <el-radio label="public">所有应用</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="状态" prop="projectStatus">
                  <el-radio-group v-model="form.projectStatus" >
                    <el-radio :label="1">待激活</el-radio>
                    <el-radio :label="2">正常</el-radio>
                    <el-radio :label="3">冻结</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="状态权重" prop="projectWeight">
                  <el-input-number v-model="form.projectWeight"  style="width:100%;"/>
                </el-form-item>
              </el-col>
            </el-row>
            
            <el-form-item label="业务描述">
              <el-input
                v-model="form.projectDesc"
                maxlength="1000"
                show-word-limit
                type="textarea"
                :rows="4"
                placeholder="请添加该项目的业务背景与价值的描述"
              />
            </el-form-item>
          </el-form>
        </div>

       
    
    <div class="footer" align="right">
      <div class="btn">
        <el-button  @click="cancel">取消</el-button>
       
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted} from 'vue';
import {request} from '@/api'
import { ElMessage } from "element-plus";
import { useRouter,useRoute } from "vue-router";

export default {
  name: 'toDevelopServices',
  setup () {
    const router = useRouter();
    const route = useRoute();
    let idEdit = ref(route?.query?.registerProjectId || false)
    let userList = ref([])
    let ruleFormRef = ref(null)
    let form = ref({
      projectCnname: "",
      projectEnname:'',
      projectUri:'',
      projectPath:'',
      projectOwner:'',
      projectOwnerAccount:'',
      projectScope:'',
      projectStatus:'',
      projectWeight:'',
      projectDesc:'',
      copyProjectOwner:'',

    })

    // const validateUrl = (rule, value, callback) => {
    //   const reg = /((\/[a-zA-Z0-9_-]+){1,}(({[a-zA-Z0-9]+}){0,}))$/;
    //   if (reg.test(value)) {
    //     callback();
    //   } else {
    //     callback(
    //       new Error(
    //         "支持英文，数字，下划线，连接符（-），不超过200个字符，且只能以 / 开头, 以英文，数字结尾"
    //       )
    //     );
    //   }
    // };
    const basicRules = reactive({
      projectCnname: [
        { required: true, message: "请输入名称", trigger: "blur" },
        { min: 1, max: 100, message: "长度在 1 到 100 个字符", trigger: "blur" },
      ],
      projectEnname: [
        { required: true, message: "请输入名称", trigger: "blur" },
        { min: 1, max: 100, message: "长度在 1 到 100 个字符", trigger: "blur" },
      ],
      projectStatus: [
        { required: true, message: "请选择", trigger: "change" },
      ],
      projectWeight:[
        { required: true, message: "请输入", trigger: "change" },
      ],
      projectScope: [
        { required: true, message: "请选择", trigger: "change" },
      ],
      projectOwner: [
        { required: true, message: "请选择", trigger: "change" },
      ],
      projectPath: [
        { required: true, message: "请输入", trigger: "blur" }
        // { validator: validateUrl, trigger: "blur" },
      ],
      projectUri: [
        { required: true, message: "请输入", trigger: "blur" },
      ]
    });

    const getProjectOwner = (query)=>{
      getUserList(query)
    }

    const getUserList = (query)=>{
      if (query) {
       request.selectUserByParams({
            appId:localStorage.getItem('appId'),
            likeData:query,
            currentPage: 1,
            pageSize: 10000
          }).then((res) => {
          if (res?.data?.code === 200) {
            userList.value = res.data.data.result
          } else {
            ElMessage.error('失败')
          }
        });
      } else {
        userList.value = []
      }
    }

   
    const changeProjectOwner = (val)=>{
        let user =  userList.value.filter(item => {
          return item.userId === val
        })
        if(user.length > 0){
          form.value.projectOwnerName = user[0].userName
          form.value.projectOwnerAccount = user[0].account
        }
        form.value.copyProjectOwner = val
    }

    const saveProject = ()=>{
      let saveParams = {
        ...form.value,
        appId:localStorage.getItem("appId"),
        registerProjectId:route.query.registerProjectId?route.query.registerProjectId:null,
        projectOwner:route.query.registerProjectId?form.value.copyProjectOwner:form.value.projectOwner
      }
      request.addOrUpdateRegisterProject(saveParams).then((resp) => {
        let respCode = resp.data.code;
        if (respCode == "200") {
          ElMessage.success('创建成功');
          cancel()
        } else {
          ElMessage.error(resp.data.msg);
        }
      });
    }
    const save =  async ()=>{
      if(!ruleFormRef.value) return
        await ruleFormRef.value.validate((valid) => {
        if (valid) {
          saveProject();
        }
      });
    }

    const cancel = ()=>{
      router.push('/home/Security/registerProject')
    }

    const getInfo = ()=>{
      let registerProjectId = route.query.registerProjectId
      request.findRegisterProjectDetails({registerProjectId}).then((res) => {
        let result = res.data
         if (result.code == "200") {
          form.value = {...result.data}
          form.value.copyProjectOwner = result.data.projectOwner
          form.value.projectOwner = result.data.projectOwnerName
        } else {
          ElMessage.error('异常');
        }
      })
    }

    onMounted(()=>{
        if(route.query.operation === 'edit'){
          getInfo()
        }
        
    })
    return {
        idEdit,
        changeProjectOwner,
        ruleFormRef,
        getUserList,
        getProjectOwner,
        basicRules,
        form,
        userList,
        save,
        cancel
    }
  },
}
</script>

<style lang="less" scoped>
  .footer {
    margin-top: 24px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 64px;
    line-height: 64px;
    background: #fff;
    z-index: 10;
    .btn {
      padding-right: 24px;
    }
  }
</style>