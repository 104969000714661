import { ElMessage } from 'element-plus'
export async function setupMenusGuard(router, store) {
  let nowToken = localStorage.getItem('token')
  let path = window.location.href.split('#')[1]
  console.log(path, '>>path', nowToken)
  if (nowToken) {
    if (path === '/') {
      router.replace('/home/ApiMarket/ApiMarketList')
      return
    }
    haveTokenThen(router, nowToken, path)
  } else {
    if (
      !path.includes('/login') &&
      !path.includes('/start') &&
      !path.includes('/Expired')
    ) {
      ElMessage.error('token失效，请重新登录')
      router.replace('/login')
      localStorage.clear()
    }
  }

  function haveTokenThen(nowToken) {
    console.log('nowToken', nowToken)
    sessionStorage.setItem('token', nowToken)
    if (!store.state.menuList.length) {
      store.dispatch('afterLoginAction')
    }
  }
}
