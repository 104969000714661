<template>
  <div class="alarm-page">
    <div class="base-info box-card-radius-eight white-bg margin-botton-24">
      <p class="base-info--title marign-top-0"><strong>基础信息</strong></p>
      <el-form
        ref="ruleFormRef"
        :rules="validateForm"
        :model="formData"
        label-width="250px"
        label-position="left"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item prop="enableFlag" label="是否启用告警策略">
              <el-radio-group
                v-model="formData.enableFlag"
                @change="changeEnableFlag"
              >
                <el-radio label="Y">是</el-radio>
                <el-radio label="N">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item prop="sendChannel" label="告警渠道">
              <el-checkbox-group
                v-model="formData.sendChannel"
                :disabled="formData.enableFlag == 'N'"
              >
                <el-checkbox label="email">邮件通知</el-checkbox>
                <el-checkbox disabled lebel="sms">短信通知</el-checkbox>
                <el-checkbox label="qywx" disabled>企业微信通知</el-checkbox>
                <el-checkbox label="wxgzh" disabled>企业公众号通知</el-checkbox>
                <el-checkbox label="dding" disabled>钉钉通知</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item prop="triggerSense" label="触发告警场景">
              <el-checkbox-group
                v-model="formData.triggerSense"
                :disabled="formData.enableFlag == 'N'"
              >
                <el-checkbox label="error">接口异常</el-checkbox>
                <el-checkbox label="limit">访问量超阈值</el-checkbox>
                <el-checkbox label="black">黑名单访问</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item prop="sendManagerFlag" label="是否发送到目录管理员">
              <template v-slot:label>
                是否发送到目录管理员
                <el-popover
                  placement="top-start"
                  effect="dark"
                  :width="180"
                  trigger="hover"
                  content="告警api所在目录管理员"
                >
                  <template #reference>
                    <el-icon style="margin: 7px 0 0 5px">
                      <QuestionFilled />
                    </el-icon>
                  </template>
                </el-popover>
              </template>
              <el-radio-group
                v-model="formData.sendManagerFlag"
                :disabled="formData.enableFlag == 'N'"
                @change="changeSendManagerFlag"
              >
                <el-radio label="Y">是</el-radio>
                <el-radio label="N">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="box-card-radius-eight white-bg margin-botton-64" 
      v-if="formData.sendManagerFlag == 'N' && formData.enableFlag == 'Y'"
    >
      <div align="left" class="margin-botton-12">
        <p class="base-info--title marign-top-0"><strong>适用用户</strong></p>
        <el-button
          icon="plus"
          @click="addUser"
          type="primary"
          :disabled="formData.enableFlag == 'N'"
          >新增用户</el-button
        >
      </div>
      <el-table :data="tableData.user" stripe row-key="userId">
        <el-table-column
          prop="userName"
          label="用户名称"
          :show-overflow-tooltip="true"
          fixed
        />
        <el-table-column
          prop="account"
          label="用户账号"
          :show-overflow-tooltip="true"
          fixed
        />
        <el-table-column
          prop="phoneNumber"
          label="电话"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="email"
          label="邮箱"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="operation"
          label="操作"
          width="180"
          fixed="right"
        >
          <template #default="scope">
            <el-popconfirm
                  :title="`删除后将无法恢复，请确认是否删除用户 ${scope.row.account}？`"
                  confirm-button-type="danger"
                  icon="warning-filled" icon-color="#F56C6C"
                  width="250" placement="top-end"
                  @confirm="handleUserDelete(scope.row)">
                  <template #reference>
                    <el-button icon="delete" type="danger" text></el-button>
                  </template>
                </el-popconfirm>
            <!-- <el-button
              size="small"
              icon="delete"
              type="danger"
              @click="handleUserDelete(scope.row)"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div align="right" class="footer white-bg">
      <el-button type="primary" @click="save(ruleFormRef)">保存</el-button>
    </div>
    <el-dialog v-model="dialogVisible" title="添加用户" width="50%">
      <el-table
        ref="multipleTableRef"
        :data="tableData.list"
        stripe
        @selection-change="selectChange"
        row-key="userId"
      >
        <el-table-column type="selection" width="40" fixed></el-table-column>
        <el-table-column
          prop="no"
          label="序号"
          width="100"
          align="center"
          fixed
          type="index"
        />
        <el-table-column
          prop="userName"
          label="用户名称"
          :show-overflow-tooltip="true"
          fixed
        />
        <el-table-column
          prop="account"
          label="用户账号"
          :show-overflow-tooltip="true"
          fixed
        />
        <el-table-column
          prop="phoneNumber"
          label="电话"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="email"
          label="邮箱"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
      </el-table>
      <div style="padding: 20px 0;">
         <el-pagination 
         style="float:right;"
          background small
          :current-page="page.pageNum"
          :page-size="page.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addUserSave"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ElMessage } from "element-plus";
import {request} from '@/api'
import { reactive, ref } from "vue";
export default {
  name: "Alarm",
  setup() {
    const multipleTableRef = ref();
    const ruleFormRef = ref(null);
    const formData = reactive({
      sendChannel: [],
      triggerSense: [],
      sendManagerFlag: "",
      sendUserList: "",
      appId: localStorage.getItem("appId"),
      enableFlag: "Y",
      alarmConfId: "",
    });
    const page = reactive({
      //配置对应的查询参数
      pageNum: 1,
      pageSize: 10,
      total: 0,
    });
    const tableData = reactive({
      list: [],
      user: [],
    });
    const selectedRows = ref([]);
    const selectChange = (selects) => {
      selectedRows.value = selects;
    };
    const getUsersInfo = () => {
      request.selectUserByParams({
          tenantId: localStorage.getItem("tenantId"),
          appId:localStorage.getItem('appId'),
          currentPage: page.pageNum,
          pageSize: page.pageSize,
        }).then((resp) => {
        tableData.list = resp.data.data.result;
        page.total = resp.data.data.pageVO.total;
      });
    };
    const validateForm = reactive({
      sendChannel: [
        { required: true, message: "请选择告警渠道", trigger: "blur" },
      ],
      triggerSense: [
        { required: true, message: "请选择触发告警场景", trigger: "blur" },
      ],
      sendManagerFlag: [
        { required: true, message: "请选择是否发送到管理员", trigger: "blur" },
      ],
      enableFlag: [
        { required: true, message: "请选择是否启用告警策略", trigger: "blur" },
      ],
    });
    const handleSizeChange = (val) => {
      //size修改
      page.pageSize = val;
      getUsersInfo();
    };
    const handleCurrentChange = (val) => {
      //num修改
      page.pageNum = val;
      getUsersInfo();
    };
    const getConfigInfo = () => {
      request.getAlarmConfig().then((res) => {
        if (res.data.code == 200) {
          formData.alarmConfId = res.data.data.alarmConfId;
          formData.enableFlag = res.data.data.enableFlag;
          formData.sendChannel = res.data.data.sendChannel.split(",");
          formData.sendManagerFlag = res.data.data.sendManagerFlag;
          formData.triggerSense = res.data.data.triggerSense.split(",");
          tableData.user = res.data.data.users || []
        }
      });
    };
    getConfigInfo();
    const changeSendManagerFlag = () => {
      if (formData.sendManagerFlag == "Y") {
        formData.sendUserList = [];
        tableData.user = [];
      }
    };
    const changeEnableFlag = () => {
      if (formData.enableFlag == "N") {
        formData.sendUserList = [];
        tableData.user = [];
        formData.sendChannel = [];
        formData.triggerSense = [];
        formData.sendManagerFlag = "";
      }
    };
    const save = async (formEl) => {
      if (!formData.enableFlag) {
        ElMessage.error("请选择是否启用告警策略");
        return;
      }
      if (formData.enableFlag == "N") {
        request.updateAlarmConfig({
            triggerSense: '',
            sendChannel: '',
            sendManagerFlag: "",
            appId: localStorage.getItem("appId"),
            enableFlag: formData.enableFlag,
            updatedBy: localStorage.getItem("userId"),
            sendUserList: "",
          }).then((res) => {
          if (res.data.code == 200) {
            ElMessage.success("保存成功");
            page.pageNum = 1
            getConfigInfo();
          }
        });
      } else {
        await formEl.validate((valid) => {
          if (valid) {
            const sendUserList = [];
            tableData.user.map((item) => {
              sendUserList.push(item.userId);
            });
            request.updateAlarmConfig({
                triggerSense: formData.triggerSense.join(","),
                sendChannel: formData.sendChannel.join(","),
                sendManagerFlag: formData.sendManagerFlag,
                appId: localStorage.getItem("appId"),
                enableFlag: formData.enableFlag,
                updatedBy: localStorage.getItem("userId"),
                sendUserList: sendUserList.join(","),
              }).then((res) => {
              if (res.data.code == 200) {
                ElMessage.success("保存成功");
                page.pageNum = 1
                getConfigInfo();
              }
            });
          }
        });
      }
    };
    const dialogVisible = ref(false);
    const addUser = () => {
      getUsersInfo();
      dialogVisible.value = true;
    };
    const handleUserDelete = (row) => {
      tableData.user = tableData.user.filter(
        (item) => item.userId != row.userId
      );
    };
    const addUserSave = () => {
      // 去重添加
      const selectIds = tableData.user.map(el => el.userId)
      const selects = selectedRows.value.filter(el => !selectIds.includes(el.userId))
      tableData.user = tableData.user.concat(selects)
      dialogVisible.value = false;
      selectedRows.value = [];
    };
    return {
      ruleFormRef,
      addUserSave,
      handleUserDelete,
      addUser,
      dialogVisible,
      formData,
      validateForm,
      tableData,
      getUsersInfo,
      selectedRows,
      selectChange,
      page,
      handleSizeChange,
      handleCurrentChange,
      save,
      getConfigInfo,
      changeSendManagerFlag,
      changeEnableFlag,
      multipleTableRef,
    };
  },
};
</script>

<style scoped>
.footer {
  position: absolute;
  bottom: 0;
  width: calc(100% - 48px);
  padding: 10px 24px;
  left: 0;
  box-shadow: 0 -4px 16px 0 rgba(0,67,161,0.10);
  z-index: 10;
  text-align: center;
}
</style>