<template>
  <div class="content">
    <h1>
      <font size="4"> 获取授权</font>
    </h1>
    <!-- <span>
      <font size="3">应用名称：{{ appName }}</font>
    </span>
    <span style="margin-left: 30%">
      <font size="3">应用编码：{{ appCode }}</font>
    </span> -->
    <div >
      <el-form label-width="120px" style="max-width: 800px">
        <el-form-item label="应用名称：" >
          <span>{{ appName }}</span>
        </el-form-item>
        <el-form-item label="应用编码：" >
          <span>{{ appCode }}</span>
        </el-form-item>
        <el-form-item label="授权码：" prop="appAuthCode">
          <span>{{ appAuthCode }}</span>
        </el-form-item>
       
        <el-form-item >
          <template #label>
            <el-tooltip content="Token30分钟内有效">
              <Warning style="width:20px;height:20px;margin-top:6px;color:#F7760D;"/>
            </el-tooltip>
            动态Token:
          </template>
          <el-input v-model="token" type="textarea" :autosize="{ minRows: 3, maxRows: 3 }" readonly />
        </el-form-item>
         <el-form-item>
          <div style="display:flex;width:100%;justify-content: flex-end;">
           <el-tooltip content="Token30分钟内有效">
              <el-button type="primary"  @click="GenerateToken()">
                 生成token
              </el-button>
           </el-tooltip>
          <el-button v-has="'hidden&resetKeyButton'"  plain  @click="GenerateToken()">
            重置密钥
          </el-button>
          </div>
        </el-form-item>
      </el-form>
      <!-- <el-form-item>
        <span style="color:red;margin-left: 53%">Token30分钟内有效</span>
      </el-form-item> -->
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import {request} from '@/api'

export default {
  name: "AppDetails",
  setup() {
    const route = useRoute()
    let appCode = ref(route.query.appCode)
    let appName = ref(route.query.appName)
    let appAuthCode = ref(route.query.appAuthCode)
    let token = ref('')
    const GenerateToken = () => {
      request.authenticationAuthorization({
          appCode: route.query.appCode,
          appName: route.query.appName,
          appAuthCode: route.query.appAuthCode,
          appId: route.query.appId
        }).then((resp) => {
        token.value = resp.data.data
      })
    }
    return {
      appCode,
      appName,
      appAuthCode,
      GenerateToken,
      token
    }
  }
}
</script>

<style scoped lang="less">
.content{
  padding: 20px;
  text-align:left;
  background: #fff;
  border-radius: 8px;
}
</style>