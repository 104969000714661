<template>
    <div class="data-discovery margin-botton-24">
        <Aside :ref="el => el && !tableMenuRef ? tableMenuRef = el : ''" :dbId="dbId" :dbType="dbType"
            @clearConfigData="clearConfigData" @setSqlExample="setSqlExample" @getdbIdAndDbType="getdbIdAndDbType" />
        <MainContainer
            :ref="el => el && !mainContainer ? mainContainer = el : ''"
            :list="data ? JSON.parse(data) : {}"
            :paramList="paramList" 
            :initData="refData" 
            :mysql="mysql"
            :apiServicetype="apiServicetype"
            @sqlValid="sqlValid"
            @setSqlChangedFlag="setSqlChangedFlag"/>
    </div>
</template>

<script>
import { ref,reactive } from 'vue'
import Aside from './home/Aside.vue'
import MainContainer from './home/Main.vue'
import store from '@/store';
export default {
    name: 'DataDiscovery',
    props: {
        data: {
            type: String,
            default: () => ''
        },
        dbId: {
            type: String,
            default: () => ''
        },
        dbType: {
            type: String,
            default: () => ''
        },
        apiServicetype:{
           type: String,
           default: () => 'select' 
        },
        paramList: {
            type: Array,
            default: () => []
        },
        refData: Object,
        mysql: {
            type: String,
            default: () => '-- 请双击表查看示例SQL\n'
        }
    },
    components: { Aside, MainContainer },
    setup(props, { emit }) {
        const mainContainer = ref()
        const tableMenuRef = ref()
        let sqlValidObj = reactive({
            tarType:props.dbType,
            dbId:props.dbId,
        })
        
        const clearConfigData = () => {
            store.dispatch('updataCanvasData', {
                nodes: [], edges: []
            })
            mainContainer.value.clearAllData()
            
        }

        const getdbIdAndDbType = (obj)=>{
            sqlValidObj.tarType = obj.dbType
            sqlValidObj.dbId = obj.dbId
            emit('setdbIdAndDbType', {
                ...obj
            })
            
        }

        const setSqlExample = (fields=[], tableName) => {
            const filedsName = fields.map(el => el.columnName).join(', ')
            const example = `-- select ${filedsName} from ${tableName}`
            mainContainer.value.setMySql(example)
        }

        const sqlValid = (sql) => {
            emit('sqlValid', {
                ...sqlValidObj,sql
            })
        }

        const setSqlChangedFlag = (flag) => {
            emit('setSqlChangedFlag', flag)
        }
        
        return {
            mainContainer, tableMenuRef, clearConfigData, setSqlExample, sqlValid, setSqlChangedFlag, getdbIdAndDbType,sqlValidObj
        }
    }
}
</script>

<style lang="less" scoped>
    .data-discovery {
    }
</style>