<template>
  <div
    style="
      text-align: left;
      height: calc(100% - 28px);
      width: 100%;
      padding-left: 0px;
      padding-top: 14px;
      margin-left: 0px;
      margin-top: 0px;
    "
  >
    <div style="text-align: left; width: 100%; margin-bottom: 1%">
      <el-input
        v-model="modelName"
        placeholder="模糊匹配模板名称"
        style="width: 400px"
        clearable
        class="input-transparent"
        @keyup.enter="onSearch()"
      >
        <template #suffix>
          <el-icon class="icon-search" @click="onSearch()"><Search /></el-icon>
        </template>
      </el-input>
    </div>

    <div class="white-bg box-card-radius-eight">
      <!-- <div style="text-align: left; margin-bottom: 20px">
        <el-button
          type="primary"
          @click="addSetting()"
        >
          <el-icon :size="20">
            <CirclePlus /> </el-icon
          >新建
        </el-button>
      </div> -->
      <el-table :data="tableData.list" stripe style="width: 100%">
        <el-table-column
          prop="no"
          label="序号"
          width="60"
          align="center"
          fixed
          type="index"
        />
        <el-table-column
          prop="modelType"
          label="业务类型"
          :show-overflow-tooltip="true"
          fixed
        />
        <el-table-column
          prop="modelCode"
          label="审批编码"
          :show-overflow-tooltip="true"
          fixed
        />
        <el-table-column
          prop="modelName"
          label="模板名称"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="modelStatus"
          label="模板状态"
          :show-overflow-tooltip="true"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="callbackUrl"
          label="模板回调URL"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
       
        <el-table-column
          prop="operation"
          label="操作"
          width="180"
          fixed="right"
        >
          <template #default="scope">
              <el-button
                size="medium"
                icon="Edit"
                type="primary"
                link
                @click="handleEdit(scope.row)"
              ></el-button>

              <!-- <el-divider
              direction="vertical"
            /> -->

             <!-- <el-button size="small" icon="delete" link type="danger" @click="handleDelete(scope.row)"></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!--分页组件-->
      <div style="padding: 10px 0">
        <el-pagination
          background
          small
          style="float: right"
          :current-page="page.pageNum"
          :page-size="page.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>

   
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import { request } from "@/api";
import {  useRouter } from 'vue-router';
import { ElMessage, ElMessageBox } from "element-plus";
import { WarningFilled } from "@element-plus/icons-vue";

export default {
  name: "ApprovalConfigList",
  setup() {
    let modelName = ref()
    const router = useRouter()
    const tableData = reactive({
      //表格参数
      list: [],
    });
   
    const total = ref(0); //总条数
    const page = reactive({
      //配置对应的查询参数
      pageNum: 1,
      pageSize: 10,
    });
   
    const onSearch = () => {
      //查询
      getList();
    };
    const getList = () => {
      request
        .queryModelNodePage({
          appId: localStorage.getItem("appId"),
          currentPage: page.pageNum,
          pageSize: page.pageSize,
          modelName:modelName.value
        })
        .then((resp) => {
          console.log(resp.data,'>>>resp.data')
          tableData.list = resp.data.data.result;
          total.value = resp.data.data.pageVO.total;
        });
    };
   
    const addSetting = () => {
      router.push({
        path:'/home/BaseConfig/ApprovalConfig/detail'
      })
    };

    const handleEdit = (row)=>{
      router.push({
        path:'/home/BaseConfig/ApprovalConfig/detail',
        query:{
          id:row.id
        }
      })
    }

    const handleDelete = (row) => {
      ElMessageBox.confirm(`确定删除当前服务：` + row.apiName, {
        icon: WarningFilled,
        type: "warning",
      })
        .then(() => {
          request
            .deleteModelNode({
              id: row.id,
              operateType: "8",
            })
            .then((res) => {
              if (res?.data?.code === 200) {
                ElMessage.success("删除成功");
                reloadTable();
              }
            });
        })
        .catch(() => {});
    };
    
    const handleSizeChange = (val) => {
      //size修改
      page.pageSize = val;
      getList();
    };
    const handleCurrentChange = (val) => {
      //num修改
      page.pageNum = val;
      getList();
    };

    const reloadTable = ()=>{
      page.pageNum = 1;
      getList();
    }

    
    return {
      reloadTable,
      tableData,
      total,
      page,
      onSearch,
      getList,
      handleEdit,
      handleDelete,
      addSetting,
      handleSizeChange,
      handleCurrentChange,
      modelName,
    };
  },

  beforeCreate() {
    this.getList();
  },
};
</script>

<style scoped>
.page-position-common {
  float: right;
  margin-right: 15px !important;
  margin-left: 5px;
}

.table {
  line-height: normal;
}
</style>
