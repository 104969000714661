<template>
  <div class="main">
    <div class="data-box">
      <div class="search-box">
        <div class="toolbar">
          <el-button
            type="primary"
            icon="plus"
            style="color: #fff"
            @click="handleAdd"
            >注册项目路由</el-button
          >
          <div style="display: flex">
            <el-tooltip
              class="box-item"
              effect="dark"
              content="卡片"
              placement="top"
              v-if="!showCard"
            >
              <div class="card" @click="showCard = !showCard">
                <img class="card-img" src="@/assets/image/apiMarket/card.png" />
                <img
                  class="card-active-img"
                  src="@/assets/image/apiMarket/card-active.png"
                />
              </div>
            </el-tooltip>
            <el-tooltip
              class="box-item"
              effect="dark"
              content="列表"
              placement="top"
              v-if="showCard"
            >
              <div class="list" @click="showCard = !showCard">
                <img class="list-img" src="@/assets/image/apiMarket/list.png" />
                <img
                  class="list-active-img"
                  src="@/assets/image/apiMarket/list-active.png"
                />
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>

      <el-row :gutter="20" v-if="showCard" class="cardType-row">
        <el-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          :xl="6"
          class="cardType-row--col"
          v-for="item in tableData"
          :key="item.registerProjectId"
        >
          <div class="card-box">
            <div class="card-box-top">
              <img
                src="@/assets/image/home/market.png"
                width="48"
                height="48"
              />
              <div>
                <p class="ellipsis apiname" :title="item.projectCnname">
                  {{ item.projectCnname }}
                </p>
                <div style="display: flex">
                  <p
                    class="ellipsis"
                    style="margin: 0; padding-top: 6px; padding-right: 12px"
                  >
                    <span class="card-time">
                      创建时间：
                      {{ item.creationTime }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card-box-mid">
              <p>
                <span class="card-label-mid">项目URI：</span>
                <span class="card-content-mid" :title="item.projectUri">{{
                  item.projectUri
                }}</span>
              </p>
              <p>
                <span class="card-label-mid">项目路径：</span>
                <span class="card-content-mid">{{ item.projectPath }}</span>
              </p>
              <p>
                <span class="card-label-mid">项目权重：</span>
                <span class="card-content-mid" :title="item.projectWeight">{{
                  item.projectWeight
                }}</span>
              </p>
              <p>
                <span class="card-label-mid" :title="item.projectOwnerName"
                  >责任人：</span
                >
                <span class="card-content-mid">{{
                  item.projectOwnerName
                }}</span>
              </p>
            </div>
            <div class="card-box-bottom">
              <el-tooltip content="运行状态">
                <div>
                  <el-switch
                    v-model="item.projectStatus"
                    @change="changeStatus(item)"
                    :active-value="2"
                    :inactive-value="3"
                    class="main-el-switch"
                    active-text="正常"
                    inactive-color="rgba(0, 0, 0, 0.25)"
                    inactive-text="冻结"
                  ></el-switch>
                </div>
              </el-tooltip>
              <el-dropdown :hide-on-click="false">
                <el-icon><MoreFilled /></el-icon>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item icon="Edit" @click="handleEdit(item)"
                      >编辑</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="Delete"
                      style="color: #f56c6c"
                      @click="handleDelete(item)"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
      <div
        class="box-card-radius-eight white-bg"
        style="overflow: hidden"
        v-else
      >
        <el-table :data="tableData" row-key="registerProjectId" border>
          <el-table-column
            prop="projectCnname"
            label="项目中文名称"
            width="300"
          ></el-table-column>
          <el-table-column
            prop="projectEnname"
            label="项目英文名称"
            width="300"
          ></el-table-column>
          <el-table-column
            prop="projectUri"
            label="项目URI"
            width="300"
          ></el-table-column>
          <el-table-column
            prop="projectPath"
            label="项目路径"
            width="300"
          ></el-table-column>
          <el-table-column
            prop="projectOwnerName"
            label="责任人"
             width="200"
          ></el-table-column>
          <el-table-column
            prop="projectWeight"
             width="100"
            label="项目权重"
          ></el-table-column>
          <el-table-column prop="projectStatus" label="运行状态" width="200">
            <template #default="scope">
              <el-switch
                v-model="scope.row.projectStatus"
                @change="changeStatus(scope.row)"
                :active-value="2"
                :inactive-value="3"
                class="main-el-switch"
                active-text="正常"
                inactive-color="rgba(0, 0, 0, 0.25)"
                inactive-text="冻结"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="creationTime"
            label="创建时间"
             width="200"
          ></el-table-column>
          <el-table-column
            prop="updateTime"
            label="修改时间"
             width="200"
          ></el-table-column>
          <el-table-column 
          label="操作" 
          fixed="right"
           width="200">
            <template #default="scope">
              <el-tooltip content="编辑">
                <el-button
                  size="medium"
                  icon="Edit"
                  type="text"
                  @click="handleEdit(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="删除">
                <el-button
                  @click="handleDelete(scope.row)"
                  size="medium"
                  icon="delete"
                  type="text"
                  style="color: #f56c6c"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页组件-->
      <div style="padding: 10px 0; float: right">
        <el-pagination
          background
          small
          :current-page="pageVO.currentPage"
          :page-size="pageVO.pageSize"
          :page-sizes="[2, 5, 10, 20]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageVO.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { request } from "@/api";
import { ElMessage, ElMessageBox } from "element-plus";
import { WarningFilled } from "@element-plus/icons-vue";

export default {
  name: "ApiDesign",
  setup() {
    const router = useRouter();
    const route = useRoute();
    let isMyDesign = ref(route.fullPath === "/home/mine/myDesign");
    let apiName = ref("");
    let tableData = ref();
    let showCard = ref(false);
    let pageVO = ref({
      currentPage: 1,
      pageSize: 10,
      total: 0,
    });
    let apiServicetypeMap = ref([
      {
        value: "select",
        label: "查询服务",
      },
      {
        value: "insert",
        label: "写入服务",
      },
      {
        value: "update",
        label: "修改服务",
      },
      {
        value: "delete",
        label: "删除服务",
      },
    ]);

    const handleSizeChange = (val) => {
      //size修改
      pageVO.value.pageSize = val;
      getProjectList();
    };
    const handleCurrentChange = (val) => {
      //num修改
      pageVO.value.currentPage = val;
      getProjectList();
    };

    const getProjectList = async () => {
      let res = await request.pageRegisterProject({
        ...pageVO.value,
        appId:localStorage.getItem('appId'),
      }); 
      if (res?.data?.code === 200) {
        tableData.value = res.data.data.result;
        pageVO.value.total = res.data.data.pageVO.total;
      } else {
        ElMessage.error("失败");
      }
    };
    const handleDelete = (row) => {
      ElMessageBox.confirm(`确定删除当前项目：` + row.projectCnname, {
        icon: WarningFilled,
        type: "warning",
      }).then(() => {
        request
          .deleteRegisterProject({
            registerProjectId: row.registerProjectId,
          })
          .then((res) => {
            if (res?.data?.code === 200) {
              ElMessage.success("删除成功");
              pageVO.value.currentPage = 1;
              getProjectList();
            } else {
              ElMessage.error("失败");
            }
          });
      }).catch(()=>{});
    };

    const changeStatus = (row) => {
      request
        .updateRegisterProjectStatus({
          registerProjectId: row.registerProjectId,
          projectStatus: row.projectStatus,
        })
        .then((res) => {
          if (res?.data?.code === 200) {
            ElMessage.success("修改成功");
            pageVO.value.currentPage = 1;
            getProjectList();
          } else {
            ElMessage.error(res.data.msg);
          }
        });
    };
    const handleEdit = (row) => {
      router.push({
        path: "/home/Security/registerProjectDetail",
        query: {
          operation: "edit",
          registerProjectId: row.registerProjectId,
        },
      });
    };

    

    const handleAdd = () => {
      router.push({
        path: "/home/Security/registerProjectDetail",
        query: {
          operation: "create",
        },
      });
    };

    onMounted(() => {
      getProjectList();
    });

    return {
      showCard,
      apiName,
      getProjectList,
      handleDelete,
      changeStatus,
      handleEdit,
      handleAdd,
      tableData,
      apiServicetypeMap,
      isMyDesign,
      pageVO,
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  margin-top: 20px;
  text-align: left;
  .toolbar {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }
  .data-source-left-bar {
    width: 224px;
  }
  .data-box {
    margin-left: 20px;
    .search-box {
      width: 100%;
      .card {
        &:hover {
          .card-img {
            display: none;
          }
          .card-active-img {
            display: block;
          }
        }
        .card-img {
          display: block;
        }
        .card-active-img {
          display: none;
        }
      }
      .list {
        &:hover {
          .list-img {
            display: none;
          }
          .list-active-img {
            display: block;
          }
        }
        .list-img {
          display: block;
        }
        .list-active-img {
          display: none;
        }
      }
    }
    .cardType-row {
      max-height: 650px;
      overflow-y: auto;
      .card-box {
        background: #ffffff;
        border: 2px solid #ffffff;
        border-radius: 8px;
        box-shadow: 4px 4px 16px 0px rgba(0, 67, 161, 0.1);
        margin-bottom: 12px;
        cursor: pointer;
        box-sizing: border-box;
        &:hover {
          background: linear-gradient(180deg, #d9e3ff, #ffffff 100%);
          box-shadow: 3px 6px 15px 5px rgba(21, 69, 137, 0.16);
        }
        .card-box-top {
          display: flex;
          padding: 12px 12px 0 12px;
          align-items: center;
          .apiname {
            margin: 0 12px 0 0;
            text-align: left;
            font-size: 16px;
            font-family: Source Han Sans CN, Source Han Sans CN-700;
            font-weight: 700;
            max-width: 170px;
            min-wdith: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .card-time {
            font-size: 12px;
            font-family: Source Han Sans CN, Source Han Sans CN-400;
            font-weight: 400;
            text-align: LEFT;
            color: #4e5156;
          }
        }
        .card-box-mid {
          margin: 18px 0 0;
          padding: 0 12px 0 18px;
          p {
            margin: 0 0 12px 0;
            text-align: left;
            display: flex;
          }
          .card-label-mid {
            display: inline-block;
            width: 80px;
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-400;
            font-weight: 400;
            text-align: LEFT;
            color: #777d84;
          }
          .card-content-mid {
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-400;
            font-weight: 400;
            text-align: LEFT;
            color: #091328;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .card-box-bottom {
          height: 40px;
          border-top: 1px solid #d6dae0;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          text-align: left;
          justify-content: space-between;
          padding: 5px 20px;
        }
      }
    }
  }
}
</style>
