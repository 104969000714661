<template>
<div class="main">
        <div class="base-info">
          <div align="left" class="step-title margin-botton-12"><strong>基本信息</strong></div>
             <el-form ref="ruleFormRef" :model="form" :rules="basicRules" label-position="top">
              <el-row :gutter="24">
              <el-col :span="8" align="left">
                <el-form-item label="菜单类型" prop="menuType">
                  <el-radio-group v-model="form.menuType" >
                    <el-radio label="1">目录</el-radio>
                    <el-radio label="2">菜单</el-radio>
                    <el-radio label="3">按钮</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              </el-row>
                <el-row :gutter="24">
              <el-col :span="8" align="left">
                <el-form-item label="菜单名称" placeholder="请输入菜单名称" prop="menuName">
                  <el-input v-model="form.menuName" show-word-limit   />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="国际化名称" placeholder="请输入国际化名称" prop="internationalizedName">
                  <el-input v-model="form.internationalizedName" show-word-limit   />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="菜单编码" placeholder="请输入菜单编码" prop="menuCode">
                  <el-input v-model="form.menuCode" show-word-limit   />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="上级菜单" >
                  <el-tree-select 
                   v-model="form.parentMenuId"
                   :check-strictly="true"
                   :data="treeData" 
                   :render-after-expand="false"
                   :filterable="true"
                   
                   node-key="menuId"
                   :props="{
                      children: 'children',
                      label: 'menuName'
                    }"
                    placeholder="请选择上级菜单"
                    style="width: 100%"
                    />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="排序" prop="sort">
                  <el-input-number v-model="form.sort"   style="width:100%;" />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left" v-if="form.menuType != 3">
                <el-form-item label="路由地址" prop="path">
                  <el-input v-model="form.path"   />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left" v-if="form.menuType != 3">
                <el-form-item label="组件路径" prop="component">
                  <el-input v-model="form.component"   />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="状态" prop="menuStatus">
                  <el-radio-group v-model="form.menuStatus"  >
                    <el-radio :label="1">未激活</el-radio>
                    <el-radio :label="2">正常</el-radio>
                    <el-radio :label="3">冻结</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left" v-if="form.menuType != 3">
                <el-form-item label="是否外链" prop="isLink">
                  <el-radio-group v-model="form.isLink"  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left" v-if="form.menuType != 3">
                <el-form-item label="是否显示" prop="isShow">
                  <el-radio-group v-model="form.isShow"  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
               <el-col :span="8" align="left" v-if="form.menuType != 3">
                <el-form-item label="图标" prop="icon">
                  <el-select
                  v-model="form.icon"
                  filterable
                  clearable 
                >
                 <template #prefix>
                  <el-icon color="#606266">
                    <component :is="form.icon"/>
                  </el-icon>
                </template>
                  <el-option v-for="icon in iconList" :key="icon" :label="icon" :value="icon">
                    <el-icon color="#606266"><component :is="icon" :key="icon"/></el-icon>
                    <span>&nbsp;&nbsp;{{ icon }}</span>
                  </el-option>  
                  </el-select>
               </el-form-item>
                  </el-col>
              
              <el-col :span="24" align="left">
                <el-form-item label="备注" prop="remark">
                 <el-input type="textarea" v-model="form.remark" show-word-limit   />
                </el-form-item>
              </el-col>
            </el-row>

             </el-form>
              
        </div>
        <div class="footer" align="right">
                <el-button @click="cancel">
                    取消
                </el-button>
                <el-button type="primary" @click="save">
                    确定
                </el-button>
        </div>
 </div>
</template>

<script>
import { reactive,ref,onMounted } from "vue";
import {  useRoute, useRouter } from 'vue-router';
import { ElMessage } from "element-plus";
import {request} from '@/api'
import * as ElementPlusIconsVue from '@element-plus/icons/lib'
export default {
  name: `ServiceClassificationManagementDetail`,
  setup() {
    const route = useRoute()
    const router = useRouter()
    let iconList = ref([])
    let operateType = ref(route.query?.operation);
    let menuId = ref(route.query?.menuId || null)
    let currentBusinessCateLevel = ref(0)
    let form = ref({
      icon:null,
      menuType:null,
      menuName:null,
      internationalizedName:null,
      menuCode:null,
      parentMenuId:null,
      sort:null,
      path:null,
      component:null,
      menuStatus:null,
      isLink:null,
      isShow:null,
      remark:null
    })
    let treeData = ref([])
    // const validateUrl = (rule, value, callback) => {
    //   const reg = /((\/[a-zA-Z0-9_-]+){1,}(({[a-zA-Z0-9]+}){0,}))$/;
    //   if (reg.test(value)) {
    //     callback();
    //   } else {
    //     callback(
    //       new Error(
    //         "支持英文，数字，下划线，连接符（-），不超过200个字符，且只能以 / 开头, 以英文，数字结尾"
    //       )
    //     );
    //   }
    // };
    const basicRules = reactive({
      sort: [
        { required: true, message: "请输入排序", trigger: "change" },
      ],
      menuType: [
        { required: true, message: "请选择菜单类型", trigger: "change" },
      ],
      menuName: [
        { required: true, message: "请输入菜单名称", trigger: "blur" },
        { min: 1, max: 100, message: "长度在 1 到 100 个字符", trigger: "blur" },
      ],
      menuCode: [
        { required: true, message: "请输入菜单编码", trigger: "change" },
      ],
      path: [
        { required: true, message: "请输入路由地址", trigger: "change" },
      ],
      // businessCateName: [
      //   { required: true, message: "请输入名称", trigger: "blur" },
      //   { min: 1, max: 100, message: "长度在 1 到 100 个字符", trigger: "blur" },
      // ],
      // businessCateDesc: [
      //   { required: true, message: "请选择请求方式", trigger: "change" },
      // ],
      // parentmenuId: [
      //   { required: true, message: "请选择挂载目录", trigger: "change" },
      // ],
      // apiUrl: [
      //   { required: true, message: "请输入接口路径", trigger: "blur" },
      //   { min: 1, max: 200, message: "长度在 1 到 200 个字符", trigger: "blur" },
      //   { validator: validateUrl, trigger: "blur" },
      // ],
      // apiOwner: [
      //   { required: true, message: "请选择责任人", trigger: "change" },
      // ]
    });

    const save = ()=>{
      if(operateType.value !=='create'){
        request.updateMenu({
          ...form.value,
          menuId:menuId.value,
          projectId:1
        }).then((res) => {
        if (res?.data?.code === 200) {
          ElMessage.success('成功')
          cancel()
        }
      });
      }else{
        request.addMenu({
          ...form.value,
          parentMenuId:form.value.parentMenuId?form.value.parentMenuId:'-1',
          projectId:1
        }).then((res) => {
        if (res?.data?.code === 200) {
          ElMessage.success('成功')
          cancel()
        }
      });
      }
        
    }

    const cancel = ()=>{
        router.push({
            path:'/home/BaseConfig/AuthorityManagement/list'
        })
        
    }

    const getInfo = ()=>{
      request.findMenuById({
         menuId:menuId.value,
         projectId:1
        }).then((res) => {
        if (res?.data?.code === 200) {
          form.value = {...res.data.data}
          form.value.parentMenuId = form.value.parentMenuId === '-1'? null:form.value.parentMenuId
        }
    })
    }

    const findMenuList = ()=>{
      request.findMenuList({
         "projectId" : 1
        }).then((res) => {
        if (res?.data?.code === 200) {
          treeData.value = res.data.data
        }
      })
    }

    const changeTree = ()=>{
      console.log(form.value.parentMenuId,'>>>>>>>>>>val')
    }

    const getIconsList = ()=>{
      for (const key of Object.entries(ElementPlusIconsVue)) {
        iconList.value.push(key[0])
      }
    }

    onMounted(()=>{
      if(operateType.value !=='create'){
        getInfo()
      }
       findMenuList()
       getIconsList()


    })
    return {
      getIconsList,
      iconList,
      changeTree,
        form,
        basicRules,
        operateType,
        save,
        cancel,
        getInfo,
        menuId,
        currentBusinessCateLevel,
        findMenuList,
        treeData

    }
  }
}
</script>


<style lang="less" scoped>
.main{
    height: calc(100% - 40px);
    margin:20px;
    text-align: left;
    
}
.base-info{
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
}
.footer{
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
}
</style>