<template>
  <div class="data-source-right-form">
    <div class="data-source-right-form-box">
      <div
        style="
          display: flex;
          align-items: center;
        "
      >
        <img :src="currentImg" alt="" style="width: 56px; height: 56px; box-shadow: 0 2px 16px 0 rgba(51, 51, 51, 0.10);" />
        <span style="font-size: 16px; margin-left: 16px; font-weight: 700;color: #091328;">
          {{ currentDataSourceType }}
        </span>
      </div>
      <el-divider border-style="dashed" />
      <div v-if="showCategory">
        <div
          style="
            text-align: left;
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 12px;
            color: #091328;
          "
        >
          目录
        </div>
        <el-form label-position="top" ref="categoryFormRef" :model="dbForm" :rules="categoryIdRules" label-width="7rem" inline>
          <el-row style="width: 100%;">
            <el-col :span="12">
              <el-form-item label="所属目录" prop="categoryId" >
                <el-tree-select
                  style="width: 100%"
                  v-model="dbForm.categoryId"
                  :data="treeData"
                  :render-after-expand="false"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="data-source-right-form-box" :style="{margin: showCategory ? '20px 0px 72px' : '-52px 0px 72px'}">
      <div
        style="
          text-align: left;
          font-weight: 700;
          font-size: 16px;
          margin-bottom: 12px;
          color: #091328;
        "
      >
        测试链接
      </div>
      <el-form
        ref="dataSourceFormRef"
        :model="dbForm"
        label-width="7rem"
        :rules="dataSourceRules"
        inline
        label-position="top"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="数据源名称"
              prop="dataSourceNameCn"
            >
              <el-input
                v-model="dbForm.dataSourceNameCn"
                maxlength="130"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item  prop="serviceName">
              <template #label>
                  服务名
               <el-tooltip content="数据库名称">
                <span style="position:relative;top:3px;"> <el-icon color="#000" :size="16" ><QuestionFilled /></el-icon></span>
               </el-tooltip>
              </template>
              <el-input
                v-model="dbForm.serviceName"
                maxlength="130"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="IP" prop="ip">
                <el-input
                  v-model="dbForm.ip"
                  maxlength="130"
                  style="width: 100%"
                ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="端口" prop="port">
              <el-input
                v-model="dbForm.port"
                maxlength="30"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户名" prop="account">
              <el-input
                v-model="dbForm.account"
                maxlength="30"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="密码" prop="passwd">
              <el-input
                v-model="dbForm.passwd"
                maxlength="100"
                style="width: 100%"
                type="password"
                show-password
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="Schema"
              prop="schema"
              v-if="
                dbForm.subCategory === 'postgres' ||
                dbForm.subCategory === 'hana' ||
                dbForm.subCategory === 'mssql' ||
                dbForm.subCategory === 'dm' ||
                dbForm.subCategory === 'guass'
              "
            >
              <el-input
                v-model="dbForm.schema"
                maxlength="130"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="描述" prop="note">
              <el-input
                type="textarea"
                v-model="dbForm.note"
                maxlength="200"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="data-source-right-form-box" :style="{margin: showCategory ? '20px 0px 72px' : '-52px 0px 72px','text-align':'left'}">
      <div
        style="
          text-align: left;
          font-weight: 700;
          font-size: 16px;
          margin-bottom: 12px;
          color: #091328;
        "
      >
        高级属性
      </div>

      <el-button style="margin-bottom:20px;" type="primary" @click="addRow" icon="plus">新增属性按钮</el-button>

      <el-table :data="dbConfig" max-height="285">
        <el-table-column
          prop="varname"
          label="属性名称"
          
          :show-overflow-tooltip="true"
          >
            <template #default="scope">
              <el-input
                v-model="scope.row.varname"
                clearable
                placeholder="请输入"
                ></el-input>
            </template>
        </el-table-column>
        <el-table-column
          prop="varvalue"
          label="属性值"
          
          :show-overflow-tooltip="true"
          >
            <template #default="scope">
              <el-input
                v-model="scope.row.varvalue"
                clearable
                placeholder="请输入"
                ></el-input>
            </template>
        </el-table-column>
        <el-table-column
          prop="vardesc"
          label="属性描述"
          
          :show-overflow-tooltip="true"
          >
            <template #default="scope">
              <el-input
                v-model="scope.row.vardesc"
                clearable
                placeholder="请输入"
                ></el-input>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center" fixed="right">
                    <template #default="scope">
                        <el-popconfirm
                        title="删除后将无法恢复，请确认是否删除？"
                        confirm-button-type="danger"
                        icon="warning-filled"
                        icon-color="#F56C6C"
                        width="250"
                        placement="top-end"
                        @confirm="deleteRow(scope.$index)"
                        >
                        <template #reference>
                            <el-button
                            icon="delete"
                            type="danger"
                            text
                            ></el-button>
                        </template>
                        </el-popconfirm>
                    </template>
                    </el-table-column>
      </el-table>
    </div>
    <div class="data-source-right-form-box-btn">
      <div style="text-align: right; margin: 0 20px 0 0">
        <el-button @click="back">取消</el-button>
        <el-button @click="onTestConnect(dataSourceFormRef)"
          >测试链接</el-button
        >
        <el-button type="primary" @click="submit(dataSourceFormRef)"
          >提交</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import {request} from '@/api'
import { reactive, ref ,watch} from "vue";
import mysqlImg from "../assets/image/dataSource/db/mysql.png";
import oracleImg from "../assets/image/dataSource/db/Oracle.png";
import pgsqlImg from "../assets/image/dataSource/db/PostgreSQL.png";
import hanaImg from "../assets/image/dataSource/db/SAPHANA.png";
import sapiqImg from "../assets/image/dataSource/db/SAPIQ.png";
import sqlsImg from "../assets/image/dataSource/db/SOLs.png";
import mariadbImg from "../assets/image/dataSource/mariadb.png";
import gpImg from '../assets/image/dataSource/greenplum.webp';
import dwsImg from '../assets/image/dataSource/dws.png'
import db2Img from '../assets/image/dataSource/DB2.webp'
import dmImg from '../assets/image/dataSource/dameng.png'
import guassImg from '../assets/image/dataSource/gs.png'
import { useStore } from 'vuex'
export default {
  name: "SetDataSourceInfo",
  setup() {
    const store = useStore()
    let dbConfig = ref([])
    const dbForm = reactive({
      account: "",
      port: "",
      ip: "",
      note: "",
      passwd: "",
      schema: "",
      resourceScope: "TENANT",
      responsible: localStorage.getItem('userId'),
      url: "",
      categoryId: "",
      subCategory: "",
      dataSourceId: ""
    });

    const router = useRouter();
   const showCategory=ref(true)
    if(router.currentRoute.value.query.operateType=='update'){
        showCategory.value=false
    }
    const back = () => {
     // router.back();
      store.dispatch('getTreeData')
      router.push('/home/DataResourceCenter/DataSourceManage/DataSourceList')
    };
    const treeData = ref([]);
    const treeDisable = ref(true);
    const currentImg = ref("");
    const currentDataSourceType = ref("");
    const setPageTitle = async (type) => {
      currentImg.value = "";
      currentDataSourceType.value = "";
      dbForm.subCategory = type;
      switch (type) {
        case "mysql":
          currentImg.value = mysqlImg;
          currentDataSourceType.value = "MySQL";
          dbForm.port = "3306";
          break;
        case "postgres":
          currentImg.value = pgsqlImg;
          currentDataSourceType.value = "PostgreSQL";
          dbForm.port = "5432";
          break;
        case "oracle":
          currentImg.value = oracleImg;
          currentDataSourceType.value = "Oracle";
          dbForm.port = "1521";
          break;
        case "hana":
          currentImg.value = hanaImg;
          currentDataSourceType.value = "SAP HANA";
          dbForm.port = "30015";
          break;
        case "sybaseiq":
          currentImg.value = sapiqImg;
          currentDataSourceType.value = "SAP IQ";
          dbForm.port = "5000";
          break;
        case "mssql":
          currentImg.value = sqlsImg;
          currentDataSourceType.value = "SQLSever";
          dbForm.port = "1433";
          break;
        case "mariadb":
          currentImg.value = mariadbImg;
          currentDataSourceType.value = "MariaDB";
          dbForm.port = "3306";
          break;
        case "greenplum":
          currentImg.value = gpImg;
          currentDataSourceType.value = "Greenplum";
          dbForm.port = "5432";
          break;
        case "db2":
          currentImg.value = db2Img;
          currentDataSourceType.value = "DB2";
          dbForm.port = "5000";
          break;
        case "dws":
          currentImg.value = dwsImg;
          currentDataSourceType.value = "DWS";
          dbForm.port = "25308";
          break;
        case "dm":
          currentImg.value = dmImg;
          currentDataSourceType.value = "达梦";
          dbForm.port = "5236";
          break;
        case "gaussdb":
          currentImg.value = guassImg;
          currentDataSourceType.value = "GuassDB";
          dbForm.port = "25308";
          break;
      }
    };
    setPageTitle(router.currentRoute.value.query.dataSourceType);
    const treeDataMapping = (data) => {
      return data
        .filter((item) => item.type !== "datasource")
        .map((item) =>
          Array.isArray(item.children) && item.children.length
            ? {
                value: item.id,
                label: item.title,
                children: treeDataMapping(item.children),
              }
            : { value: item.id, label: item.title }
        );
    };
    if (router.currentRoute.value.query.operateType == "create") {
      const list = sessionStorage.getItem("DatasourceTree");
      treeData.value = treeDataMapping(JSON.parse(list));
    }
    const responsibleList = () => {
      request.selectResponsible().then((res) => {
        if (res.data.code == 200) {
          // dataSource.tree = res.data.data;
        }
      });
    };
    //responsibleList()
    const dataSourceFormRef = ref(null);
    const categoryFormRef = ref(null)
    const categoryIdRules = reactive({
      categoryId: [{ required: true, message: "不能为空", trigger: "change" }],
    });
    const requiredRules = [
      { required: true, message: "不能为空！", trigger: "blur" },
    ];
    const dataSourceRules = reactive({
      ip: requiredRules,
      port: requiredRules,
      serviceName: requiredRules,
      url: requiredRules,
      account: requiredRules,
      passwd: requiredRules,
      dataSourceNameCn: requiredRules,
      categoryName: requiredRules,
      schema:requiredRules
    });

    const addRow = () => {
      dbConfig.value.push({
        varname:null,
        varvalue:null,
        vardesc:null,
      });
    };

    const deleteRow = (index)=>{
      dbConfig.value.splice(index, 1);
    }
    const onTestConnect = async (formEl) => {
      let categoryValid = true
      if (showCategory.value) {
        await categoryFormRef.value.validate((valid) => {
          if (valid) {
            categoryValid = true
          } else {
            categoryValid = false
          }
        })
      }
      if (!categoryValid) {
        return
      }
      await formEl.validate((valid) => {
        if (valid) {
          request.connectV2({...dbForm,dbConfig:JSON.stringify(dbConfig.value)}).then((res) => {
            if (res.data.code == 200) {
              if(res.data.data.success === 'true'){
                ElMessage.success("连接成功！");
              }else{
                ElMessage.error(res.data.data.msg);
              }
            } else {
              ElMessage.error(res.data.msg);
            }
          });
        }
      });
    };
    const submit = async (formEl) => {
      let categoryValid = true
      if (showCategory.value) {
        await categoryFormRef.value.validate((valid) => {
          if (valid) {
            categoryValid = true
          } else {
            categoryValid = false
          }
        })
      }
      if (!categoryValid) {
        return
      }
      await formEl.validate(async (valid) => {
        if (valid) {
          let res
          if(router.currentRoute.value.query.operateType == "create"){
            res = await request.addDatasourceinfo({...dbForm,dbConfig:JSON.stringify(dbConfig.value),appId:localStorage.getItem("appId")})
          }else{
            res = await request.updateDatasourceinfo({...dbForm,dbConfig:JSON.stringify(dbConfig.value),appId:localStorage.getItem("appId")})
          }
          
          if (res.data.code == 200) {
              ElMessage.success(
                router.currentRoute.value.query.operateType == "create"
                  ? "添加成功！"
                  : "修改成功！"
              );
             // router.back();
              store.dispatch('getTreeData')
             router.push('/home/DataResourceCenter/DataSourceManage/DataSourceList')
            } else {
              ElMessage.error(res.data.msg);
            }
        }
      });
    };
    const getInfo = async () => {
      if(!router.currentRoute.value.query.id){
        return
      }
      showCategory.value=false
      setPageTitle(router.currentRoute.value.query.dataSourceType);
      request.getDatasourceinfo({id:router.currentRoute.value.query.id}).then((res) => {
        if (res.data.code == 200) {
          let result = res.data.data
          dbForm.account =result.account;
          dbForm.passwd = "";
          dbForm.ip = result.ip;
          dbForm.note = result.note;
          dbForm.schema = result.schemaName;
          dbForm.resourceScope = result.resourceScope;
          dbForm.responsible = result.responsible;
          dbForm.url = result.url;
          dbForm.categoryId = result.categoryId;
          dbForm.subCategory = result.subCategory;
          dbForm.dataSourceId = result.dataSourceId;
          dbForm.serviceName= result.serviceName;
          dbForm.dataSourceNameCn= result.dataSourceNameCn;
          dbForm.port =result.port?.toString();
          let tableData =result.dbConfig
          dbConfig.value = tableData? JSON.parse(tableData):[]
        }
      });
    };
    if (router.currentRoute.value.query.operateType == "update") {
      getInfo();
    }
   watch(() => router.currentRoute.value.query.id, getInfo, {
    immediate: true,
  })
    return {
      showCategory,
      dbForm,
      dataSourceFormRef,
      categoryFormRef,
      dataSourceRules,
      setPageTitle,
      currentDataSourceType,
      currentImg,
      treeData,
      categoryIdRules,
      treeDisable,
      back,
      responsibleList,
      treeDataMapping,
      onTestConnect,
      submit,
      getInfo,
      dbConfig,
      addRow,
      deleteRow,
    };
  },
};
</script>