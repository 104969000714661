<template>
<div class="main">
        <div class="base-info">
          <div align="left" class="step-title margin-botton-12"><strong>基本信息</strong></div>
             <el-form ref="ruleFormRef" :model="form" :rules="basicRules" label-position="top">
                <el-row :gutter="24">
              <el-col :span="8" align="left">
                <el-form-item label="分类名称" placeholder="请输入分类名称" prop="businessCateName">
                  <el-input v-model="form.businessCateName" show-word-limit  :disabled="operateType === 'view'" />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="上级分类" >
                  <el-select
                    v-model="form.parentBusinessCateId"
                    :disabled="(operateType === 'view' || (operateType === 'edit' && currentBusinessCateLevel === 1))"
                    filterable
                    placeholder="请选择上级分类"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in treeData"
                      :key="item.businessCateId"
                      :label="item.businessCateName"
                      :value="item.businessCateId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
               <el-col :span="8" align="left">
                <el-form-item label="业务owner" prop="businessOwnerId">
                  <el-select
                    v-model="form.businessOwnerId"
                    @change="changeBusinessOwner"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="请输入搜索关键字"
                      :remote-method="getBusinessOwner"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in businessOwnerList"
                      :key="item.userId"
                      :label="item.userName  + ' ' +item.account"
                      :value="item.userId"
                      
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="8" align="left">
                <el-form-item label="排序" prop="businessCateDesc">
                  <el-input v-model="form.businessCateDesc" show-word-limit  :disabled="operateType === 'view'" />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="状态" prop="businessCateDesc">
                  <el-radio-group v-model="form.businessCateDesc" :disabled="operateType === 'view'" @change="changeMethods">
                    <el-radio label="1">未激活</el-radio>
                    <el-radio label="2">正常</el-radio>
                    <el-radio label="3">冻结</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col> -->
              <el-col :span="24" align="left">
                <el-form-item label="备注" prop="businessCateDesc">
                 <el-input type="textarea" v-model="form.businessCateDesc" show-word-limit  :disabled="operateType === 'view'" />
                </el-form-item>
              </el-col>
            </el-row>

             </el-form>
              
        </div>
        <div class="footer" align="right">
                <el-button @click="cancel">
                    取消
                </el-button>
                <el-button type="primary" @click="save">
                    确定
                </el-button>
        </div>
 </div>
</template>

<script>
import { reactive,ref,onMounted } from "vue";
import {  useRoute, useRouter } from 'vue-router';
import { ElMessage } from "element-plus";
import {request} from '@/api'

export default {
  name: `ServiceClassificationManagementDetail`,
  setup() {
    const route = useRoute()
    const router = useRouter()

    let operateType = ref(route.query?.operation);
    let businessCateId = ref(route.query?.businessCateId || null)
    let businessOwnerList = ref([])
    let currentBusinessCateLevel = ref(0)
    let form = ref({
      businessCateName:null,
      parentBusinessCateId:null,
      businessCateDesc:null,
      businessOwnerId:null,
      businessOwnerCopyId:null,
      businessOwnerName: "",
      businessOwnerAccount: "",
    })
    let treeData = ref([])
    const validateUrl = (rule, value, callback) => {
      const reg = /((\/[a-zA-Z0-9_-]+){1,}(({[a-zA-Z0-9]+}){0,}))$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(
          new Error(
            "支持英文，数字，下划线，连接符（-），不超过200个字符，且只能以 / 开头, 以英文，数字结尾"
          )
        );
      }
    };
    const basicRules = reactive({
      businessOwnerId: [
        { required: true, message: "请输入业务owner", trigger: "change" },
      ],
      businessCateName: [
        { required: true, message: "请输入名称", trigger: "blur" },
        { min: 1, max: 100, message: "长度在 1 到 100 个字符", trigger: "blur" },
      ],
      businessCateDesc: [
        { required: true, message: "请选择请求方式", trigger: "change" },
      ],
      parentBusinessCateId: [
        { required: true, message: "请选择", trigger: "change" },
      ],
      apiUrl: [
        { required: true, message: "请输入接口路径", trigger: "blur" },
        { min: 1, max: 200, message: "长度在 1 到 200 个字符", trigger: "blur" },
        { validator: validateUrl, trigger: "blur" },
      ],
      apiOwner: [
        { required: true, message: "请选择责任人", trigger: "change" },
      ]
    });

    const save = ()=>{
      let params = {...form.value}
      if(operateType.value =='edit'){
        params.businessCateId = businessCateId.value
        params.businessOwnerId = form.value.businessOwnerCopyId
      }
        request.saveIasmBusinessCate({
          ...params,
        }).then((res) => {
        if (res?.data?.code === 200) {
          ElMessage.success('成功')
          cancel()
        } else {
          ElMessage.error('失败')
        }
      });
    }

    const cancel = ()=>{
        router.push({
            path:'/home/DataResourceCenter/ServiceClassificationManagement/list'
        })
        
    }

    const getInfo = ()=>{
      request.findBusinessCate({
         businessCateId:businessCateId.value
        }).then((res) => {
        if (res?.data?.code === 200) {
          let {
            businessCateName,parentBusinessCateId,businessCateDesc,businessCateLevel,businessOwnerId,businessOwnerName,businessOwnerAccount
          } = res.data.data
          form.value = {
            businessCateName:businessCateName,
            parentBusinessCateId,
            businessCateDesc,
            businessOwnerCopyId:businessOwnerId,
            businessOwnerId:businessOwnerName + ' ' + businessOwnerAccount,
          }
          currentBusinessCateLevel.value = businessCateLevel
        } else {
          ElMessage.error('失败')
        }
    })
    }

    const selectBusinessCatePage = ()=>{
      request.selectBusinessCatePage({
         businessCateLevel:1
        }).then((res) => {
        if (res?.data?.code === 200) {
          console.log(res)
          treeData.value = res.data.data
        } else {
          ElMessage.error('失败')
        }
      })
    }

    const getBusinessOwner = (query)=>{
      if (query) {
       request.selectUserByParams({
            likeData:query,
            appId:localStorage.getItem('appId'),
            currentPage: 1,
            pageSize: 10000
          }).then((res) => {
          if (res?.data?.code === 200) {
           businessOwnerList.value = res.data.data.result
          }
        });
      } else {
        businessOwnerList.value = []
      }
    }

    const changeBusinessOwner = (val)=>{
        let user =  businessOwnerList.value.filter(item => {
          return item.userId === val
        })
        if(user.length > 0){
          form.value.businessOwnerName = user[0].userName
          form.value.businessOwnerAccount = user[0].account
        }
        form.value.businessOwnerCopyId = val
    }

    onMounted(()=>{
      if(operateType.value !=='create'){
        getInfo()
      }
       selectBusinessCatePage()
    })
    return {
        getBusinessOwner,
        businessOwnerList,
        changeBusinessOwner,
        form,
        basicRules,
        operateType,
        save,
        cancel,
        getInfo,
        businessCateId,
        currentBusinessCateLevel,
        selectBusinessCatePage,
        treeData

    }
  }
}
</script>


<style lang="less" scoped>
.main{
    height: calc(100% - 40px);
    margin:20px;
    text-align: left;
    
}
.base-info{
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
}
.footer{
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
}
</style>