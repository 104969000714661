<template>
  <div class="xml-tree">
    <div class="xml-element" v-for="(el,index) in data" :key="`${el.xpath}-${index}`">
      <div v-if="el.nodeType === 1 && el.leaf" :class="{'xml-start-tags': el.children.length === 1 && el.children[0].nodeType === 3}">
        <span class="xml-el-tag" v-text="'\<'"></span>
        <span class="xml-el-item" @click="handlePath(el)">{{ el.tagName }}</span>
        <template v-if="el.attributes.length > 0">
          <span v-for="(attr, i) in el.attributes" :key="`${attr.name}-${i}`" class="xml-tag-attr" @click="handlePath(attr)">
            {{ attr.name }}={{ `"${attr.value}"` }}
          </span>
        </template>
        <span class="xml-el-tag" v-text="'\>'"></span>
      </div>
      <div v-else-if="el.nodeType === 1 && !el.leaf" class="xml-single-tag">
        <span class="xml-el-tag" v-text="'\<'"></span>
        <span class="xml-el-item">{{ el.tagName }}</span>
        <span class="xml-el-tag" v-text="'\>'"></span>
        <span class="xml-el-tag" v-text="'\<\/'"></span>
        <span class="xml-el-item"  @click="handlePath(el)">{{ el.tagName }}</span>
        <span class="xml-el-tag" v-text="'\>'"></span>
      </div>
      <div class="xml-el-text" v-else-if="el.nodeType === 3">
        <span class="xml-el-item" @click="handlePath(el)">{{ el.data }}</span>
      </div>

      <template  v-if="el.leaf">
        <div class="xml-children">
          <xml-tree :data="el.children"></xml-tree>
        </div>
        <div class="xml-el-endtag">
          <span class="xml-el-tag" v-text="'\<\/'"></span>
          <span class="xml-el-item" @click="handlePath(el)">{{ el.tagName }}</span>
          <span class="xml-el-tag" v-text="'\>'"></span>
        </div>
      </template>
    </div>
  </div>
</template>

<script >
export default {
  name: 'xmlTree',
  props: ['data'],
  setup() {
    function handlePath(item) {
      window.getNewXml(item.xpath) 
    }
    return {
      handlePath,
    };
  },
};
</script>

<style lang="scss" scoped>
.xml-tree {
  .xml-element {
    cursor: default;
    user-select: none;
    margin: 12px 0px;

    .xml-el-tag {
      color: #ccc;
    }

    .xml-el-item {
      cursor: pointer;
      padding: 2px 5px;

      &:hover {
        background-color: #37f;
        color: #fff;
      }
    }

    .xml-children {
      margin-left: 20px;
    }

    .xml-start-tags {
      float: left;
    }

    .xml-el-text {
      float: left;
      margin: 0px 10px;
    }

    .xml-single-tag,.xml-start-tags {
      clear: both;
    }

    .xml-tag-attr {
      margin-left: 5px;
      cursor: pointer;
      padding: 2px 3px;

      &:hover {
        background-color: #37f;
        color: #fff;
      }
    }
  }
}
</style>