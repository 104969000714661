<template>
  <div class="common-layout" style="height: 100%;width: 100%">
    <el-container style="height: 100%;width: 100%;">
      <!-- <el-aside style="width: 15%;height: 100%;background:#fff">
        <el-row style="height: 100%">
          <el-col>
            <el-menu class="background-color-menu" :default-active="routerPath" style="background:#FFF">
              <el-menu-item index="/home/Security/Alarm"
                @click="change('Alarm')" class="el-menu-item-fontsize">
                <span>告警配置</span>
              </el-menu-item>
              <el-menu-item index="/home/Security/policy"
                @click="change('policy')" class="el-menu-item-fontsize">
                <span>安全策略</span>
              </el-menu-item>
              <el-menu-item index="/home/Security/Config"
                @click="change('Config')" class="el-menu-item-fontsize">
                <span>API安全配置</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-aside> -->
      <el-main style="padding: 24px;">
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Security",
  methods: {
    change(x) {
      router.push('/home/Security/' + x)
    }
  },
  computed: {
    routerPath() {
      return this.$route.meta.GuidePath ? this.$route.meta.JumpPath : this.$route.path
    }
  }
}
</script>

<style scoped></style>