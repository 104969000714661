<template>
  <div class="home-bg">
    <div class="content">
      <div class="index-top-box">
        <div class="iods">iODS</div>
        <div>数据开放平台</div>
        <!-- <div>为企业搭建规范且标准的数据服务总线</div>
        <div class="last_text">针对API接口服务提供自定义/可视化设计、开发、注册、发布、订阅、运营等功能，以构建全面、安全、高效的数据开放能力</div> -->
        <!-- <div></div> -->
        <div class="last_text">提供<b>API可视化设计、</b> <b>开发、</b> <b>注册、</b><b>发布、</b><b>订阅、</b><b>安全、</b><b>运营一站式</b>功能，以构建高效、安全、全面的数据服务开放能力。</div>
      </div>
      <div class="home-search margin-botton-24">
        <el-row class="margin-botton-12">
          <el-col class="search_col" :span="12">
            <el-input
              v-model="queryKey"
              placeholder="请输入API名称"
              size="large"
              class="input-transparent" clearable/>
            <p class="home-btn" @click="searchAPI(selectedAppId)">搜索</p>
          </el-col>
        </el-row>
        <div class="app-list" @click="changeApp">
          <div  style="width:100px;text-align: right;"><span  :appId="'all'" :class="selectedAppId === 'all' ? 'app-active' : ''">全部</span></div>
          <div >
            <span v-for="item in dataSet.appList" :key="item.appId"
            :appId="item.appId"
            :class="selectedAppId == item.appId ? 'app-active' : ''">{{ item.appName }}</span></div>
        </div>
      </div>

      <div class="api-level">
        <div class="title">一级分类</div>
        <div class="tags">
            <div :class="{'active-tag':!firstBusinessId}" class="tag" @click="changeFirstBussiness('')">全部</div>
            <div :class="{'active-tag':business.businessCateId == firstBusinessId,tag}" class="tag" v-for="business in BusinessCateList" :key="business.businessCateId" @click="changeFirstBussiness(business.businessCateId)">{{business.businessCateName}}</div>
        </div>
      </div>
      <div class="api-level">
        <div  class="title">二级分类</div>
        <div class="tags">
            <div class="tag" :class="{'active-tag':!secondBusinessIds.length}"   @click="changeSecondBussiness('')">全部</div>
            <div  class="tag" :class="{'active-tag':secondBusinessIds.includes(business.businessCateId)}"   v-for="business in BusinessCateChildList" :key="business.businessCateId" @click="changeSecondBussiness(business.businessCateId)">{{business.businessCateName}}</div>
        </div>
      </div>

      <div class="app_list_tab">
        <div class="tabs">
          <span
            :class="selectType === 'publish' ? 'active-tab' : ''"
            @click="getApiListByType('publish')">
            最新发布
          </span>
          <!-- <span
            :class="selectType === 'mySubscribe' ? 'active-tab' : ''"
            @click="getApiListByType('mySubscribe')">
            我的订阅
          </span> -->
        </div>
        <div class="show-way">
          <el-tooltip effect="dark" content="卡片" placement="top-start">
            <img
              :src="cardType ? cardActiveImg : cardImg"
              @click="switchCard(true)"
            />
          </el-tooltip>
          <el-tooltip effect="dark" content="列表" placement="top-start">
            <img
              :src="cardType ? listImg : listActiveImg"
              @click="switchCard(false)"
            />
          </el-tooltip>
        </div>
 
        <el-row :gutter="20" v-if="cardType" class="cardType-row" v-loading="loading">
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="cardType-row--col" v-for="(item, index) in dataSet.tableData" :key="index">
            <div class="cardType-row--item--box">
              <div class="api-card" @click="viewDoc(item)">
                <div class="api-card-top iasm-flex">
                  <img src="../../assets/image/home/market.png" />
                  <div class="api-card-top-info top-height">
                    <div class="base-info iasm-flex">
                      <span class="api-name" :title="item.apiName">{{ item.apiName }}</span>
                      <span class="api-type tag-text" :title="renderCreateType(item.apiType)">{{ renderCreateType(item.apiType) }}</span>
                      <span class="api-version tag-text" :title="item.apiVersion ? `${item.apiVersion}版本` : '-'">{{ item.apiVersion ? `${item.apiVersion}版本` : '-' }}</span>
                    </div>
                    <div class="update-info">
                      <!-- <span class="update-name">
                        更新人：<span class="value">{{ item.updatedName }}</span>
                      </span> -->
                      <span class="update-name">
                        更新时间：<span class="value">{{ renderCreateTime(item.updateTime) }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="api-card-content">
                  <p>
                    <span class="title">URL</span>
                    <span class="value" :title="item.apiUrl">{{ item.apiUrl }}</span>
                  </p>
                  <p>
                    <span class="title">请求方式</span>
                    <span class="value">{{ renderRequestMethod(item.apiRequestM) }}</span>
                  </p>
                  <p>
                    <span class="title">所属应用</span>
                    <span class="value">{{ item.appName }}</span>
                  </p>
                  <p>
                    <span class="title">创建人</span>
                    <span class="value">{{ item.createdName + ' ' + item.createdNumber }}</span>
                  </p>
                  <p>
                    <span class="title">创建时间</span>
                    <span class="value">{{ renderCreateTime(item.creationTime) }}</span>
                  </p>
                </div>
              </div>
              <div class="api-operate">
                <div class="iasm-flex" v-if="selectType === 'publish'">
                  <img
                    src="../../assets/image/apiMarket/fail.png"
                    v-if="item.approvalStatus == '审批驳回' || item.approvalStatus == '审批异常'" />
                  <img
                    src="../../assets/image/apiMarket/pending.png"
                    v-if="item.approvalStatus == '待审批'" />
                  <img
                    src="../../assets/image/apiMarket/success.png"
                    v-if="item.approvalStatus == '审批通过' || item.apiIssecurity == 'N'" />
                  <span>{{
                    item.approvalStatus == "审批通过"
                      ? "已订阅"
                      : item.approvalStatus == "待审批"
                      ? "审批中"
                      : item.approvalStatus == "审批驳回"
                      ? "审批驳回" 
                      :item.approvalStatus == "审批异常"?
                      "审批异常"
                      : item.apiIssecurity == 'N'?"免订阅":""
                  }}</span>
                </div>
                <div class="iasm-flex" v-else>
                  <img
                    src="../../assets/image/apiMarket/fail.png"
                    v-if="item.approvalStatus == '审批驳回'" />
                  <img
                    src="../../assets/image/apiMarket/pending.png"
                    v-if="item.approvalStatus == '待审批'" />
                  <span>{{
                    item.approvalStatus == "待审批"
                      ? "审批中"
                      : item.approvalStatus == "审批驳回"
                      ? "审批驳回" 
                      :item.approvalStatus == "审批异常"?
                      "审批异常": ""
                  }}</span>
                </div>
                <el-button v-if="selectType === 'publish' && (!item.approvalStatus || item.approvalStatus == '已撤回' || item.approvalStatus == '审批驳回') && item.apiIssecurity !== 'N' && item.appId != currentAppId" size="small" type="primary" plain v-has="'hidden&apiMall.list.subscribe'" @click="subscribe(item)">立即订阅</el-button>
                
                <el-button v-if="selectType === 'mySubscribe' && item.approvalStatus != '待审批'" size="small" type="primary" plain @click="unSubscribe(item)">取消订阅</el-button>
              </div>
            </div>
          </el-col>
          <el-col :span="24" class="box-card-radius-eight white-bg" v-if="dataSet.tableData.length === 0">
            <div align="center" class="nodata">暂无数据</div>
          </el-col>
        </el-row>

        <div class="box-card-radius-eight white-bg" v-if="!cardType" v-loading="loading">
          <div class="list-item iasm-flex" v-for="(item, index) in dataSet.tableData" :key="index">
            <div class="list-item-content iasm-flex" @click="viewDoc(item)">
              <img class="title-img" src="../../assets/image/home/market.png" />
              <div class="api-info">
                <div class="api-card-top-info">
                  <div class="base-info">
                    <span class="api-name" :title="item.apiName">{{ item.apiName }}</span>
                    <span class="api-type tag-text" :title="renderCreateType(item.apiType)">{{ renderCreateType(item.apiType) }}</span>
                    <span class="api-version tag-text" :title="item.apiVersion ? `${item.apiVersion}版本` : '-'">{{ item.apiVersion ? `${item.apiVersion}版本` : '-' }}</span>
                  </div>
                  <div class="update-info">
                    <span class="update-name">
                      更新人：<span class="value">{{ item.updatedName }}</span>
                    </span>
                    <span class="update-name">
                      更新时间：<span class="value">{{ renderCreateTime(item.updateTime) }}</span>
                    </span>
                  </div>
                  <div class="api-base-info api-card-content iasm-flex">
                    <p class="ellipsis">
                      <span class="title">URL</span>
                      <span class="value" :title="item.apiUrl">{{ item.apiUrl }}</span>
                    </p>
                    <p class="ellipsis">
                      <span class="title">请求方式</span>
                      <span class="value">{{ renderRequestMethod(item.apiRequestM) }}</span>
                    </p>
                    <p class="ellipsis">
                      <span class="title">所属应用</span>
                      <span class="value">{{ item.appName }}</span>
                    </p>
                    <p class="ellipsis">
                      <span class="title">创建人</span>
                      <span class="value">{{ item.createdName + ' ' + item.createdNumber }}</span>
                    </p>
                    <p class="ellipsis">
                      <span class="title">创建时间</span>
                      <span class="value">{{ renderCreateTime(item.creationTime) }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div align="right" class="api-operate">
              <div class="iasm-flex" v-if="selectType === 'publish'">
                <img
                  src="../../assets/image/apiMarket/fail.png"
                  v-if="item.approvalStatus == '审批驳回'" />
                <img
                  src="../../assets/image/apiMarket/pending.png"
                  v-if="item.approvalStatus == '待审批'" />
                <img
                  src="../../assets/image/apiMarket/success.png"
                  v-if="item.approvalStatus == '审批通过'" />
                <span>{{
                  item.approvalStatus == "审批通过"
                    ? "已订阅"
                    : item.approvalStatus == "待审批"
                    ? "审批中"
                    : item.approvalStatus == "审批驳回"
                    ? "审批驳回"
                    : ""
                }}</span>
              </div>
              <div class="iasm-flex" v-else>
                <img
                  src="../../assets/image/apiMarket/fail.png"
                  v-if="item.approvalStatus == '审批驳回'" />
                <img
                  src="../../assets/image/apiMarket/pending.png"
                  v-if="item.approvalStatus == '待审批'" />
                <span>{{
                  item.approvalStatus == "待审批"
                    ? "审批中"
                    : item.approvalStatus == "审批驳回"
                    ? "审批驳回"
                    : ""
                }}</span>
              </div>
              <el-button v-if="selectType === 'publish' && (!item.approvalStatus || item.approvalStatus == '已撤回' || item.approvalStatus == '审批驳回') && item.apiIssecurity !== 'N' && item.appId != currentAppId" size="small" type="primary" plain @click="subscribe(item)">立即订阅</el-button>
              <el-button v-if="selectType === 'mySubscribe' && item.approvalStatus != '待审批'" size="small" type="primary" plain @click="unSubscribe(item)">取消订阅</el-button>
            </div>
          </div>
          <div align="center" class="nodata" v-if="dataSet.tableData.length === 0">暂无数据</div>
        </div>
      </div>

      <!--分页组件-->
      <div style="margin: 20px 0;overflow:hidden;">
         <el-pagination background small
           style="float:right;"
          :current-page="page.pageNum"
          :page-size="page.pageSize"
          :page-sizes="[12]"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
    </div>

    </div>
  </div>
</template>

<script>
import { reactive, ref,onMounted } from 'vue'
import router from "@/router";
import {request} from '@/api'
import {RequestMap,createTypeMap} from '@/utils/constant'
import { ElMessage, ElMessageBox } from "element-plus";
import listImg from '../../assets/image/apiMarket/list.png'
import listActiveImg from '../../assets/image/apiMarket/list-active.png'
import cardImg from '../../assets/image/apiMarket/card.png'
import cardActiveImg from '../../assets/image/apiMarket/card-active.png'
export default {
  beforeCreate() {
    this.searchAPI()
  },
  setup() { 
    let BusinessCateList = ref([])
    let BusinessCateChildList = ref([])
    let firstBusinessId = ref('')
    let secondBusinessIds = ref([])
    let currentAppId = ref(localStorage.getItem("appId"))
    const queryKey = ref('') // 查询关键字
    const selectedAppId = ref('all') // 选中的应用，默认全部
    const selectType = ref('publish') // 选中的展示列表类型，默认最新发布
    let bool = localStorage.getItem('cardType') ? localStorage.getItem('cardType') === 'true' : true
    
    const cardType = ref(bool) // api列表展示方式，true-卡片展示，false-列表展示
    const loading = ref(true)
    const total = ref(0)
    const autoLoad = ref(false) // 初次加载是否已完成
    const searchUrl = ref('selectOnlineApiByPage')
    let dataSet = reactive({
      appList: [],  // 应用列表
      tableData: [] // API列表
    })
    const page = reactive({
      pageNum: 1,
      pageSize: 12,
    })
    const appId = localStorage.getItem("appId")

    const handleSizeChange = (val) => {
      page.pageSize = val;
      searchAPI(selectedAppId.value);
    }
    const handleCurrentChange = (val) => {
      page.pageNum = val;
      searchAPI(selectedAppId.value)
    }

    const searchAPI = (id='') => {
      request.searchSubscribeApi({
          pageVO: {
            currentPage: page.pageNum,
            pageSize: page.pageSize,
          },
          appId,
          queryAppId: id === 'all' ? '' : id,
          queryKey: queryKey.value,
          oneBusinessCateId:firstBusinessId.value,
          twoBusinessCateIdList:secondBusinessIds.value
        },searchUrl.value).then((res) => {
        selectedAppId.value = id || 'all'
        dataSet.tableData = res?.data?.data?.result || []
        total.value = res?.data?.data?.pageVO?.total
        if (!autoLoad.value) {
          dataSet.appList = res.data.data.extResult
        }
        autoLoad.value = true
      }).finally(() => {
        loading.value = false
      });
    }

    const changeApp = (e) => {
      loading.value = true
      searchAPI(e.target.attributes.appId.value)
    }

    const getApiListByType = (type) => {
      selectType.value = type
      searchUrl.value = type === 'publish' ? 'selectOnlineApiByPage' : 'selectMySubApiByPage'
      searchAPI(selectedAppId.value)
    }

    // 查看
    const viewDoc = (row) => {
      router.push({
        path: `/home/ApiMarket/ApiInformation`,
         query:{
          apiId:row.apiId,
          type:'1',
          apiType:row.apiType
        }
      })
      // window.open(url.href);
    };

    // api类型渲染
    const renderCreateType = (data) => {
      if (!data) {
        return "-";
      }
      return createTypeMap[data];
    };

    // 时间渲染
    const renderCreateTime = (data) => {
      if (!data) {
        return "-";
      }
      let dt = new Date(data);
      let y = dt.getFullYear();
      let m = (dt.getMonth() + 1 + "").padStart(2, "0");
      let d = (dt.getDate() + "").padStart(2, "0");
      let hh = (dt.getHours() + "").padStart(2, "0");
      let mm = (dt.getMinutes() + "").padStart(2, "0");
      let ss = (dt.getSeconds() + "").padStart(2, "0");
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
    };

    // 请求方式渲染
    const renderRequestMethod = (data) => {
      if (!data) {
        return "-";
      }
      return RequestMap[data];
    };

    // 立即订阅
    const subscribe = (item) => {
      ElMessageBox.confirm("确认订阅" + item.apiName + "?")
        .then(() => {
          request.subscribeApi({
              ...item,
              authorizedAppId: appId,
            }).then((res) => {
            if (res?.data?.code === 200) {
                ElMessage.success("等待审批！");
                getApiApprovalStatus(item);
            } else {
                ElMessage.error(res?.data?.msg || "申请订阅失败！");
            }
          });
        })
        .catch(() => {});
    }

    // 取消订阅
    const unSubscribe = (row) => {
      ElMessageBox.confirm("确认取消订阅" + row.apiName + "?").then(() => {
        request.unSubscribeApi({
            apiId: row.apiId,
            apiAssociationId: row.apiAssociationId,
            authorizedAppId: appId
          }).then(() => {
          ElMessage.success("等待审批")
          searchAPI(selectedAppId.value)
        });
      });
    }
    
    const getApiApprovalStatus = (item) => {
      request.getApiApprovalStatus({apiId: item.apiId,appId}).then((res) => {
        let a = res.data.data;
        item.approvalStatus = a.approvalStatus;
        console.log(a);
      });
    }

    const changeFirstBussiness = (first) => {
      firstBusinessId.value = first
      secondBusinessIds.value = []
      if(first){
        BusinessCateChildList.value = BusinessCateList.value.filter(item =>{return item.businessCateId === firstBusinessId.value})[0].child
      }else{
        BusinessCateChildList.value = []
      }
      page.pageNum = 1
      searchAPI()
    }
    const changeSecondBussiness = (second) => {
      if(secondBusinessIds.value.includes(second)){
        secondBusinessIds.value = secondBusinessIds.value.filter(item=>{
          return item !== second
        })
      }else{
        secondBusinessIds.value.push(second)
      }
      
      page.pageNum = 1
      searchAPI()
    }
    
    const getBusinessCatePage = ()=>{
      request.selectBusinessCatePage({
          currentPage: 1,
          pageSize: 100
        }).then((res) => {
        if (res?.data?.code === 200) {
          BusinessCateList.value = res.data.data
        }
      });
    }

    const switchCard = (bool)=>{
      cardType.value = bool
      localStorage.setItem('cardType',bool)
    }

    onMounted(()=>{
      getBusinessCatePage()
    })

    return {
      firstBusinessId,
      secondBusinessIds,
      changeSecondBussiness,
      changeFirstBussiness,
      BusinessCateList,
      BusinessCateChildList,
      getBusinessCatePage,
      listImg,
      listActiveImg,
      cardImg,
      cardActiveImg,
      queryKey,
      dataSet,
      selectedAppId,
      selectType,
      cardType,
      loading,
      autoLoad,
      total,
      searchUrl,
      page,
      searchAPI,
      changeApp,
      handleSizeChange,
      handleCurrentChange,
      viewDoc,
      renderCreateType,
      renderCreateTime,
      renderRequestMethod,
      subscribe,
      unSubscribe,
      getApiListByType,
      currentAppId,
      switchCard
    }
  }
}
</script>
<style  lang="less">
.input-transparent {
        border-radius: 20px;
        font-size: 16px;
        & :deep .el-input__wrapper {
          background: transparent;
        }
      }
</style>
<style lang="less" scoped>
  .home-bg {
    height: 100%;
    text-align: left;overflow-y: auto;
    .content {
      padding: 48px 104px 24px;
      .last_text {
        max-width: 750px;
      }
      .search_col {
        position: relative;
      }
      
      .app-list {
        font-size: 14px;
        color: #091328;
        display: flex;
        font-family: Source Han Sans CN, Source Han Sans CN-400;
        font-weight: 400;
        span {
          display: inline-block;
          cursor: pointer;
          margin-right: 20px;
          margin-bottom: 5px;
          padding: 3px 4px;
        }
        .app-active {
          color: #0e68e7;
          background: #e0edff;
        }
      }
      .api-level{
        display: flex;
        margin-bottom: 15px;
        .title{
          font-size:14px;
          color:#A8ABB2;
          margin-right:30px;
        }
        .tags{
          display: flex;
          cursor: pointer;
          font-size:14px;
          text-align: center;
          .tag{
            padding:3px 4px;
            color: #091328;
            font-family: Source Han Sans CN, Source Han Sans CN-400;
            font-weight: 400;
             margin-right:10px;
          }
          .active-tag{
            background: #e0edff;
            color: #1f7aff;
            border-radius: 3px;
          }
        }
      }
      .app_list_tab {
        position: relative;
        margin-top: 24px;
        .tabs {
          margin-bottom: 24px;
          span {
            font-size: 16px;
            color: #091328;
            cursor: pointer;
            &:nth-child(1) {
              margin-right: 12px;
            }
          }
          .active-tab {
            color: #0067ff;
            padding-bottom: 8px;
            border-bottom: 2px solid #0067ff;
          }
        }
        .show-way {
          position: absolute;
          right: 0;
          top: 6px;
          z-index: 1;
          cursor: pointer;
        }
      }
      .cardType-row{
        max-height: 640px;
        overflow-y: auto;
      }
      .cardType-row--item--box {
        background: #fff;
        border: 2px solid #fff;
        border-radius: 8px;
        box-shadow: 4px 4px 16px 0px rgba(0, 67, 161, 0.1);
        margin-bottom: 20px;
        &:hover {
          background: linear-gradient(180deg,#d9e3ff,#fff 100%);
          border: 2px solid #fff;
          border-radius: 8px;
          box-shadow: 3px 6px 24px 7px rgba(0,67,161,.15);
          transform: scale(1.03);
        }
        .api-card {
          padding: 12px;
        }
        .api-card-top {
          .top-height {
            height: 46px;
          }
          img {
            width: 52px;
            height: 52px;
          }
        }
        .api-card-content {
          margin-top: 18px;
          padding-left: 6px;
          p {
            display: flex;
            align-items: center;
            .title {
              width: 80px;
              display: inline-block;
            }
            .value {
              display: inline-block;
              max-width: 300px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .api-operate {
          height: 45px;
          border-top: 1px solid #d6dae0;
          padding: 0px 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .api-card-top-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 6px;
        .base-info {
          .api-name {
            font-size: 16px;
            font-weight: 700;
            color: #091328;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .tag-text {
            padding: 4px;
            border-radius: 2px;
            display: inline-block;
            font-size: 12px;
            width: 52px;
            text-align: center;
          }
          .api-type {
            background: #e0edff;
            color: #1f7aff;
            margin-left: 8px;
          }
          .api-version {
            background: #f3ecfc;
            color: #a16fe7;
            margin-left: 8px; 
          }
        }
        .update-info {
          font-size: 12px;
          color: #4e5156;
          .value {
            color: #091328;
          }
        }
      }
      .api-card-content {
        font-size: 14px;
        p {
          margin: 12px 0;
          .title {
            display: inline-block;
            color: #777d84;
          }
          .value {
            color: #091328;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .api-operate {
          .iasm-flex span {
            padding-left: 6px;
          }
        }
      .list-item {
        justify-content: space-between;
        &:not(:last-child) {
          border-bottom: 1px solid #d6dae0;
          margin-bottom: 12px;
          padding-bottom: 12px;
        }
        .title-img {
          width: 84px;
          height: 84px;
        }
        .update-info {
          padding: 6px 0 12px 0;
          .update-name:nth-child(2) {
            padding-left: 24px;
          }
        }
        .api-card-content p {
          width: 20%;
          margin: 0;
          span {
            margin-right: 12px;
          }
        }
        .list-item-content {
          width: calc(100% - 120px);
          .api-info {
            width: 100%;
          }
        }
        .api-operate {
          width: 120px;
          .iasm-flex {
            justify-content: end;
          }
        }
      }
      .nodata {
        color: #909399;
        font-size: 14px;
        text-align: center;
      }
    }
  }
</style>