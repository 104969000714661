<template>
  <div>
  <Detail :ApiFlowChart="true" />
  </div>
</template>

<script lang="js">
import Detail from "@/views/ApiDesign/Detail.vue"

export default {
  name: "ApiFlowChartManagementDetail",
  components:{
    Detail
  },
  setup() {
  return{
  }
  },
    
}
</script>


<style scoped>
</style>