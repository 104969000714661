<template>
  <div class="main_bgimg bg_img1" style="height: 260px">
    <div class="main_header">
      <div class="main_header_text">{{ text }}</div>
    </div>
    <div class="main_content">
      <div
        v-for="(item, index) in state.tableData"
        :key="index"
        :class="['common_bubble', 'bubble' + index]"
      >
        <img :src="state.bubbleList[index]" />
        <span
          :class="[
            'common_bubble_text',
            'bubble_text' + index,
            item.api_name.length < 4 ? 'bubble_textL' + index : '',
          ]"
          @mouseenter="showBubbleInfo(index)"
          @mouseleave="hiddenBubbleInfo"
        >
          {{
            item.api_name.length > 6
              ? item.api_name.substring(0, 5) + "..."
              : item.api_name
          }}
          <!-- {{item.api_name}} -->
        </span>
        <span
          :class="['hover-common-box', 'hover-box_' + index]"
          v-if="item.isShow"
        >
          <span class="hover-name">{{ item.api_name + "：" }}</span
          ><span class="hover-count">{{ item.countInvoke }}</span
          ><span class="hover-unit">次</span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import {request} from '@/api'
import hotBubble0 from "@/assets/image/cockpit/hotBubble0.png";
import hotBubble1 from "@/assets/image/cockpit/hotBubble1.png";
import hotBubble2 from "@/assets/image/cockpit/hotBubble2.png";
import hotBubble3 from "@/assets/image/cockpit/hotBubble3.png";
import hotBubble4 from "@/assets/image/cockpit/hotBubble4.png";
import hotBubble5 from "@/assets/image/cockpit/hotBubble5.png";
import hotBubble6 from "@/assets/image/cockpit/hotBubble6.png";
import hotBubble7 from "@/assets/image/cockpit/hotBubble7.png";
import hotBubble8 from "@/assets/image/cockpit/hotBubble8.png";
import hotBubble9 from "@/assets/image/cockpit/hotBubble9.png";
import { ref, reactive, watch } from "vue";
export default {
  name: "RightTop",
  props: {
    appId: {
      type: String,
    },
  },
  setup(props) {
    const text = ref("低流量API TOP10");
    const state = reactive({
      tableData: [
        { api_name: "财务看板" },
        { api_name: "制造看板" },
        { api_name: "库存看板" },
        { api_name: "采购看板" },
        { api_name: "销售看板" },
        { api_name: "用户1" },
        { api_name: "用户2" },
        { api_name: "用户3" },
        { api_name: "用户4" },
        { api_name: "用户5" },
      ],
      bubbleList: [
        hotBubble0,
        hotBubble1,
        hotBubble2,
        hotBubble3,
        hotBubble4,
        hotBubble5,
        hotBubble6,
        hotBubble7,
        hotBubble8,
        hotBubble9,
      ],
    });
    const getData = () => {
      request.invokeBottom({appId:props.appId}).then((res) => {
        if (res.data.code == 200) {
          res.data.data.map((item) => {
            item.isShow = false;
          });
          state.tableData = res.data.data;
        }
      });
    };
    getData();
    const showBubbleInfo = (index) => {
      state.tableData.map((item) => {
        item.isShow = false;
      });
      state.tableData[index].isShow = true;
    };
    const hiddenBubbleInfo = () => {
      state.tableData.map((item) => {
        item.isShow = false;
      });
    };
    watch(
      () => props.appId,
      (newData) => {
        if (newData) {
          getData();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return {
      text,
      state,
      getData,
      showBubbleInfo,
      hiddenBubbleInfo,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "./RightMiddle.scss";
</style>