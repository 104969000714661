<template>
  <div class="api-views">
    <div class="box-card-radius-eight white-bg form-box">
      <div class="header-title">
      <strong>基础信息</strong>
      <!-- <div> 
        <el-dropdown>
        <span class="el-dropdown-link">
          {{form.apiVersion}}版本
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="version in apiVersionList" :key="version.value"  @click="changeVersion(version)">{{version.label}}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      </div> -->
      </div>
      <el-divider />
      <el-form :model="form" align-right>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item
              label="API名称："
              prop="apiName"
              label-position="right"
            >
              <span class="val"> {{ form.apiName }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="是否鉴权："
              prop="apiIssecurity"
              label-position="right"
            >
              <span class="val"> {{ form.apiIssecurity==='N'?'否':'是' }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="请求方式：" prop="apiRequestM">
              <span class="val">
                {{RequestMaps[form.apiRequestM]}}
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="返回数据结构：">
              <span class="val">
                {{resultDataBuildMaps[form.resultDataBuild]}}
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="请求数据类型："
              prop="apiAuthDatatype"
              label-position="right"
            >
              <span class="val"> {{ form.apiAuthDatatype?form.apiAuthDatatype:'application/json' }} </span>
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item
              label="返回数据类型："
              prop="apiAuthDatatype"
              label-position="right"
            >
              <span class="val"> {{ form.apiAuthDatatype?form.apiAuthDatatype:'JSON' }} </span>
            </el-form-item>
          </el-col>
        <el-col :span="8">
            <el-form-item label="URL：" prop="apiUrl">
              <span class="ellipsis val"> {{ form.apiUrl }} </span>
            </el-form-item>
          </el-col>
          
           <el-col :span="8">
            <el-form-item label="所属应用：" prop="appName">
              <span class="val"> {{ form.appName }} </span>
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="一级业务分类：" >
              <span class="val"> {{ oneBusinessCateName }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="二级业务分类：" >
              <span class="val"> {{ twoBusinessCateName }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务类型：" prop="apiServicetype">
              <span class="val"> {{ apiServiceTypesMap[form.apiServicetype] || '-' }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务类别：" >
              <span class="val"> {{ createTypeMaps[form.apiType]}} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务版本：" >
              <span class="val"> {{ form.apiVersion}} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务OWNER：" >
              <span class="val"> {{ form.apiOwnerName? form.apiOwnerName + ' ' + form.apiOwnerNumber  : '-'}} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务设计人：" >
              <span class="val"> {{ form.designUserName? form.designUserName + ' ' + form.designUserNumber : '-' }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务开发人：" >
              <span class="val"> {{ form.developerUserName? form.developerUserName + ' ' + form.developerUserNumber : '-'}} </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" v-if="form.apiCreateM === '2'">
          <el-col :span="24">
            <el-form-item label="源URL：" align="left">
              <span> {{ form.apiSourceUrl }} </span>
            </el-form-item>
          </el-col>
        </el-row>
        
        <el-row :gutter="24" >
          <el-col :span="24">
            <el-form-item label="访问URL：" align="left">
              <span> {{ form.callUrl }} </span>
            </el-form-item>
          </el-col>
        </el-row>
         <el-row :gutter="24" >
          <el-col :span="24">
            <el-form-item label="Mock地址：" align="left">
              <span> {{ form.mockUrl }} </span>
              <el-tooltip content="复制完整URL">
                  <el-button  type="text" icon="CopyDocument" @click="copyUrl"/>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="描述：" align="left">
              <span> {{ form.apiDescription }} </span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

 <div class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
   
   <div class="form-box">
     <div class="header-title"><strong>请求参数</strong></div>
     <el-divider />
      <el-table :data="form.requestParamters" row-key="roleId" height="300">
       <el-table-column
            label="参数中文名称"
            prop="paramNameCm"
            :show-overflow-tooltip="true"
          >
           <template #default="scope">
            {{scope.row.paramNameCm || scope.row.apiFieldCnname}}
          </template>
          </el-table-column>
          <el-table-column
            label="参数名称"
            prop="paramName"
            :show-overflow-tooltip="true"
          >
          <template #default="scope">
            {{scope.row.paramName || scope.row.apiFieldName}}
          </template>
          </el-table-column>
          <el-table-column
          label="数据类型"
          prop="paramType"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            {{paramsTypesMaps[scope.row.paramType] || scope.row.paramType}}
          </template>
        </el-table-column>
         <el-table-column
          label="默认值"
          prop="paramValue"
          :show-overflow-tooltip="true"
        >
          <template #default="scope" >
             {{scope.row.paramValue}}
          </template>
        </el-table-column>
        <el-table-column
          label="是否必填"
          prop="paramIsMust"
          :show-overflow-tooltip="true"
        >
          <template #default="scope" >
             {{scope.row.paramIsMust === 'Y'?'是':'否'}}
          </template>
        </el-table-column>
        <el-table-column
          prop="paramFun"
          :show-overflow-tooltip="true"
        >
        <template #header>
              <span style="display:flex;align-items:center;">输入函数 
                <el-tooltip 
                content="函数用法:函数名称(#{参数名称},固定参数值,...) 例如:trim(#{username}),对参数变量username去除前后空格"
                placement="top"
                >
                <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
                </span>
            </template>
        <template #default="scope" >
             {{scope.row.paramFun}}
        </template>
        </el-table-column>
        <el-table-column
          label="排序"
          prop="sort"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="example" label="示例值" width="200" :show-overflow-tooltip="true"></el-table-column>
       <el-table-column
            label="描述"
            prop="paramDescription"
            :show-overflow-tooltip="true"
          />
      </el-table>
    </div>
 </div>

 <div class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
   <div class="form-box">
     <div class="header-title"><strong>取数逻辑</strong></div>
     <el-divider />
        <span style="margin-right:5px;">数据源：</span>
        <el-select class="db-sel" v-model="form.dbId" filterable clearable disabled>
          <el-option v-for="item in dbList" :key="item.dataSourceId" :label="item.dataSourceNameCn" :value="item.dataSourceId" />
        </el-select>

        <div class="sql-config" style="margin-top:20px;">
           <CodeMirror  class="codemirror" v-model:value="form.apiCreateSql" ></CodeMirror>
        </div>
   
   </div>
</div>
<div class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
   <div class="form-box">
     <div class="header-title"><strong>返回内容</strong></div>
     <el-divider />
        <el-table :data="form.fieldMappingList" height="300">
        <el-table-column
            label="参数中文名称"
            :show-overflow-tooltip="true"
          >
           <template #default="scope">
            {{scope.row.paramNameCm || scope.row.apiFieldCnname}}
          </template>
        </el-table-column>

         <el-table-column
            prop="dbFieldName"
            label="数据库字段名"
            width="200"
            :show-overflow-tooltip="true"
          >
         </el-table-column>
        <el-table-column
            prop="dbFieldType"
            label="数据库字段类型"
            width="150"
            :show-overflow-tooltip="true"
        >
        <template #default="scope">
            {{paramsTypesMaps[scope.row.dbFieldType] || scope.row.dbFieldType}}
          </template>
        
          </el-table-column>

          <el-table-column prop="dbFieldLength" label="数据库字段长度" width="200"></el-table-column>
          <el-table-column prop="apiFieldName" label="api字段名" width="200" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="apiFieldType" label="api字段类型"  width="150">
              <template #default="scope">
                {{paramsTypesMaps[scope.row.apiFieldType] || scope.row.apiFieldType}}
              </template>
          </el-table-column>
          <el-table-column prop="apiFieldLength" label="api字段长度"  width="200"> </el-table-column>
          <el-table-column prop="sort" label="排序"  width="200"></el-table-column>
          <el-table-column
              prop="paramFun"
              width="200"
          >
              <template #header>
                  <span style="display:flex;align-items:center;">输出函数 
                    <el-tooltip 
                      content="函数用法:函数名称(#{参数名称},固定参数值,...) 例如:trim(#{username}),对参数变量username去除前后空格"
                      placement="top"
                    >
                      <el-icon><QuestionFilled /></el-icon>
                    </el-tooltip>
                  </span>
              </template>
              <template #default="scope" >
                  {{scope.row.paramFun}}
              </template>
          </el-table-column>
          <el-table-column prop="example" label="示例值" width="200" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column
            label="描述"
            prop="fieldDescription"
            :show-overflow-tooltip="true"
          />
      </el-table>
   </div>
</div>

<div class="box-card-radius-eight white-bg" style="margin-bottom:100px;">
    <div class="form-box">
    <div class="header-title"><strong>返回结果</strong></div>
     <el-divider />
      <CodeMirror  class="codemirror" v-model:value="form.apiResponseE" ></CodeMirror>
    </div>
</div>

<div class="box-card-radius-eight white-bg" style="margin-bottom:100px;" v-if="form.isMock === '1'">
    <div class="form-box">
    <div class="header-title"><strong>Mock数据</strong></div>
     <el-divider />
      <CodeMirror  class="codemirror"  v-model:value="form.mockData" ></CodeMirror>
    </div>
</div>

<div class="box-card-radius-eight white-bg" style="margin-bottom:60px;" v-if="fromPage == 2">
   <div class="form-box">
         <div class="header-title"><strong >审批意见</strong></div>
      <el-form :model="lform" :rules="notNull" ref="ruleFormRef">
        <el-form-item label="审批意见" prop="approvalStatus">
          <el-radio v-model="lform.approvalStatus" label="2">同意</el-radio>
          <el-radio v-model="lform.approvalStatus" label="3">驳回</el-radio>
        </el-form-item>
        <el-form-item label="" prop="rejectReasons" v-if="lform.approvalStatus === '3'">
           <el-input v-model="lform.rejectReasons" type="textarea" style="width: 100%;" rows="3" placeholder="驳回意见"  />
        </el-form-item>
      </el-form>
       </div>
      <div style="flex: auto;">
        <el-button type="primary" @click="submitApproval(ruleFormRef)" v-has="'hidden&userCenter.myWaitFor.approve'">确定</el-button>
      </div>
      
    </div>


    <div align="right" class="footer white-bg">
      <el-button type="primary" @click="this.$router.back()">返回</el-button>
    </div>
  </div>
</template>
  
<script>
import { useRoute } from "vue-router";
import router from '@/router';
import {request} from '@/api'
import { reactive, onMounted,ref} from "vue";
import { ElMessage } from "element-plus";
import {copyClipboard} from '@/utils/function'
import iasmjs from "@/assets/js/iasm.js";
import {paramsTypesMap,RequestMap,apiServiceTypes,createTypeMap,resultDataBuildMap} from '@/utils/constant'

import CodeMirror from '@/components/common/CodeMirror'

export default {
  name: "ApiDocView",
  components: {
      CodeMirror
    },
  setup() {
    const route = useRoute();
    const apiId = route.query.apiId;
    // const routeApi = route.query.routeApi;
    const changeFlag = route.params.changeFlag || 'N';
    const fromPage = route.query.fromPage;
    let functionList = ref([])
    const id = route.query.id
    let dbList = ref([])
    const lform = reactive({
      approvalId: '',
      apiId: '',
      appId: '',
      approvalStatus: '',
      rejectReasons: ''
    })
  
    let twoBusinessCateName = ref('-')
    let oneBusinessCateName = ref('-')
    const tableData = reactive({
      //表格参数
      list: [],
    });
    let RequestMaps = ref(RequestMap)
    let resultDataBuildMaps = ref(resultDataBuildMap)
    let paramsTypesMaps = ref(paramsTypesMap)
    let apiServiceTypesMap = ref(apiServiceTypes)
    let createTypeMaps = ref(createTypeMap)
    const form = ref({
      apiId: "",
      apiName: "",
      apiRequestM: "",
      apiUrl: "",
      apiOwner: "",
      apiOwnerName: "",
      apiDescription: "",
      status: "",
      delFlag: "",
      apiCreateSql: null,
      dbId: "",
      tenantId: "",
      apiResponseE: null,
      paramList: [],
      requestParamters: [],
      fieldMappingList: [],
      userList: [],
      appName:"",
      callUrl:"",
      apiCreateM: "",
      apiSourceUrl: ""
    });
    

    const getData = () => {
      if(apiId){
          request.getApiInfo({apiId,changeFlag,operateType: "7",}).then((res) => {
          let result = res.data.data;
          if (result) {
          form.value = result
          form.value.requestParamters = result.requestParamters?JSON.parse(result.requestParamters) : [];
          form.value.fieldMappingList =  result.responseParamters?JSON.parse(result.responseParamters) : []
          form.value.apiResponseE = result.apiResponseE?JSON.stringify(JSON.parse(result.apiResponseE),null,4) : null
          getAppNameByAppId(result.appId);
          getBusinessCateOptions(form.value.oneBusinessCateId,form.value.twoBusinessCateId)
          }
        });
      }
    };

    const copyUrl = ()=>{
      copyClipboard(form.value.mockUrl)
    }

    const getBusinessCateOptions = (oneBusinessCateId,twoBusinessCateId)=>{
      request.selectBusinessCatePage({
          currentPage: 1,
          pageSize: 10000
        }).then((res) => {
        if (res?.data?.code === 200) {
            res.data.data.forEach(item=>{
              if(item.businessCateId=== oneBusinessCateId){
                oneBusinessCateName.value = item.businessCateName
                item.child.forEach(ele=>{
                  if(ele.businessCateId === twoBusinessCateId){
                    twoBusinessCateName.value = ele.businessCateName
                  }
                })
              }
            })
        }
      });
    }

  const getAppNameByAppId=(appId)=>{
    request.selectAppById({appId}).then((res) => {
        if(res.data.code==200){
          if(res.data.data && res.data.data.result){
            form.value.appName = res.data.data.result[0].appName
          }
        }
       
      });
    }

    // 获取数据源列表
        const getDbList = () => {
            request.searchApiDbList().then(res => {
                dbList.value = res?.data?.data || [];
            });
        };

    
    const ruleFormRef = ref(null);
    const notNull = reactive({
      approvalStatus: [{ required: true, message: '请选择审批意见', trigger: 'blur' }]
    });

    const getOverallFunction = ()=>{
      request.getOverallFunction({
          currentPage: 1,
          pageSize: 100,
          
        }).then((res) => {
        if (res?.data?.code === 200) {
          functionList.value = res.data.data?.result || []
        } else {
          ElMessage.error('失败')
        }
      });
    }

    const submitApproval = async (formEl) => { //保存
      await formEl.validate(async (valid) => { //
        if (valid) {
          let res
            const params = {
                id,
                appId: localStorage.getItem('appId')
            }

            if (lform.approvalStatus === '2') {
                res =  await request.issuePass(params)
            } else if (lform.approvalStatus === '3') {
               params.rejectReasons = lform.rejectReasons || ''
               res =  await request.issueRefuse(params)
                
            }

            let respCode = res.data.code;
            if (respCode == '200') {
              router.back(-1);
              const msg = lform.approvalStatus === '2' ? '审批成功' : '驳回成功'
              ElMessage.success(msg);
            } else {
              let msg = lform.approvalStatus === '2' ? '审批失败' : '驳回失败'
              if (res?.data?.msg) {
                msg = res?.data?.msg
              }
              ElMessage.error(msg);
            }
        }
      })
    }

    onMounted(()=>{
      getData()
      getDbList()
      getOverallFunction()
    })
    return {
      copyUrl,
      getOverallFunction,
      functionList,
      submitApproval,
      ruleFormRef,
      notNull,
      fromPage,
      form,
      lform,
      dbList,
      RequestMaps,
      resultDataBuildMaps,
      paramsTypesMaps,
      tableData,
      getData,
      getAppNameByAppId,
      iasmjs,
      apiServiceTypesMap,
      createTypeMaps,
      getBusinessCateOptions,
      twoBusinessCateName,
      oneBusinessCateName
    };
  },
};
</script>
  
<style lang="less" scoped>
.api-views {
  margin: 20px;
  border-radius: 8px;
  .footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 48px);
    padding: 16px 24px;
    left: 0;
    box-shadow: 0 -4px 16px 0 rgba(0,67,161,0.10);
    z-index: 10;
    display: flex;
    justify-content: center;
  }
  .form-box{
    text-align: left;
    margin-bottom: 20px;
    .header-title{
      text-align: left;
      display: flex;
      justify-content: space-between;
    }
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>