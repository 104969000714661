<template>
  <div
    style="
      text-align: left;
      height: calc(100% - 28px);
      width: 100%;
      padding-left: 0px;
      padding-top: 14px;
      margin-left: 0px;
      margin-top: 0px;
    "
  >
     <div class="white-bg box-card-radius-eight" style="overflow:hidden;">
      <!-- <div style="text-align: left; margin-bottom: 20px">
        <el-button
          type="primary"
          @click="add()"
        >
          <el-icon :size="20">
            <CirclePlus /> </el-icon
          >新增运营指标
        </el-button>
      </div> -->
       <el-table :data="tableData" stripe style="width: 100%">
         <el-table-column
          prop="no"
          label="序号"
          width="60"
          align="center"
          type="index"
        />
        <el-table-column
          prop="indexTypeName"
          label="指标类型"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="indexStatus"
          label="指标状态"
        >
         <template #default="scope">
              <el-switch v-model="scope.row.indexStatus" @change="changeStatus(scope.row)" :active-value="1" :inactive-value="2" class="main-el-switch" active-text="正常"  inactive-color="rgba(0, 0, 0, 0.25)" inactive-text="冻结"></el-switch>
         </template>
         </el-table-column>
        <el-table-column
          prop="indexOwner"
          label="指标看护人"
          :show-overflow-tooltip="true"
        >
        <template #default="scope">
        {{
          scope.row.indexOwnerName + ' ' + scope.row.indexOwnerNumber
        }}
         </template>
        </el-table-column>
        <el-table-column
          prop="creationTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="createdBy"
          label="创建人"
          :show-overflow-tooltip="true"
        >
         <template #default="scope">
        {{
          scope.row.createdByName + ' ' + scope.row.createdByNumber
        }}
         </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="最后修改时间"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="updatedBy"
          label="最后修改人"
          :show-overflow-tooltip="true"
        >
        <template #default="scope">
        {{
          scope.row.updatedByName + ' ' + scope.row.updatedByNumber
        }}
         </template>
        </el-table-column>
         <el-table-column
          prop="operation"
          label="操作"
          width="180"
          fixed="right"
        >
          <template #default="scope">
           
            <el-tooltip  content="编辑">
              <el-button
                size="medium"
                icon="Edit"
                type="primary"
                link
                @click="handleEdit(scope.row)"
                :disabled="scope.row.indexStatus == 2"
              ></el-button>
            </el-tooltip>
            <!-- <el-divider
              direction="vertical"
            /> -->
            <!-- <el-tooltip  content="删除">
              <el-button
                size="medium"
                icon="delete"
                type="danger"
                link
                @click="handleDelete(scope.row)"
              ></el-button>
            </el-tooltip> -->
          </template>
        </el-table-column>
       </el-table>
       <!--分页组件-->
      <div style="padding: 10px 0; float: right;">
        <el-pagination
          background
          small
          :current-page="pageVO.currentPage"
          :page-size="pageVO.pageSize"
          :page-sizes="[2, 5, 10, 20]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageVO.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {  ref } from "vue";
import {useRouter} from 'vue-router'
import { request } from "@/api";
import {ElMessageBox,ElMessage } from "element-plus";
import { WarningFilled } from "@element-plus/icons-vue";
export default {
  name: "OperateIndicatorsManagementList",
  setup() {
    let router = useRouter()
    let tableData = ref([])
    let pageVO = ref({
      pageSize:10,
      currentPage:1,
      total:0
    })
    const add = ()=> {
      router.push('/home/operateIndicatorsManagement/edit')
    }

    const handleEdit = (row)=>{
      router.push(`/home/operateIndicatorsManagement/edit?indexId=${row.indexId}`)
    }
    const handleDelete = (row) => {
      ElMessageBox.confirm(`确定删除当前指标?`, {
        icon: WarningFilled,
        type: "warning",
      })
        .then(() => {
          request
            .deleteIndicatordefinition({
              apiId: row.indexId,
              operateType: "8",
            })
            .then((res) => {
              if (res?.data?.code === 200) {
                ElMessage.success("删除成功");
                reloadTable();
              }
            });
        })
        .catch(() => {});
    }
    const changeStatus = (row)=>{
      request.updateIndicatordefinition({
        indexId: row.indexId,
        indexStatus: row.indexStatus // 1:启动 2：冻结
          }).then((res) => {
          if (res?.data?.code === 200) {
            ElMessage.success("修改状态成功");
              reloadTable()
          }else{
            ElMessage.error(res.data.msg);
          }
      })
    }

    const handleSizeChange = (val) => {
      pageVO.value.pageSize = val;
      getListIndicatordefinition();
    };
    const handleCurrentChange = (val) => {
      pageVO.value.currentPage = val;
      getListIndicatordefinition();
    };

    const reloadTable = ()=>{
       pageVO.value.currentPage = 1
      getListIndicatordefinition()
    }
    const getListIndicatordefinition = ()=>{
      request.getListIndicatordefinition({
            appId:localStorage.getItem('appId'),
            currentPage: pageVO.value.currentPage,
            pageSize:  pageVO.value.pageSize,
          }).then((res) => {
          if (res?.data?.code === 200) {
              tableData.value = res.data.data.result
              pageVO.value.total =  res.data.data.pageVO.total
          }
        });
    }
    getListIndicatordefinition()
    return {
      add,
      tableData,
      handleEdit,
      handleDelete,
      changeStatus,
      getListIndicatordefinition,
      pageVO,
      handleSizeChange,
      handleCurrentChange,
      reloadTable
    };
  },
};
</script>

<style scoped>
</style>
