<template>
  <!-- <div style="height:380px">{{text}}</div> -->
  <div class="main_bgimg bg_img1" style="height: 260px">
    <div class="main_header">
      <div class="main_header_text">{{ text }}</div>
    </div>
    <div class="main_content">
      <el-row
        style="height: 36px; display: flex; align-items: center"
        :gutter="[12, 24]"
      >
        <el-col :span="4" style="padding: 0 0 0 20px">
          <div>排名</div>
        </el-col>
        <el-col :span="11">
          <div class="text_omit">API名称</div>
        </el-col>
        <el-col :span="6">
          <div>响应时间</div>
        </el-col>
      </el-row>
      <div
        ref="RightBottomScrollBox"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        style="height: 180px; overflow: hidden"
      >
        <el-row
          style="height: 36px; display: flex; align-items: center"
          :gutter="[12, 24]"
          v-for="(item, index) in state.list"
          :key="index"
        >
          <el-col :span="4" style="padding: 6px 20px">
            <div class="sort_number">
              {{ index + 1 }}
            </div>
          </el-col>
          <el-col :span="11">
            <div class="text_omit" title="item.apiName">{{ item.apiName }}</div>
          </el-col>
          <el-col :span="6">
            <div>
              <span
                :class="['response-com', index < 3 ? 'top3' : 'other']"
              ></span>
              <span :class="index < 3 ? 'reponse-time-top' : 'reponse-time'">
                <span>{{ item.avgCost }}</span
                ><span style="font-size: 14px">s</span>
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import {request} from '@/api'
import { ref, reactive, onBeforeUnmount, onUnmounted, watch } from "vue";
export default {
  name: "RightTop",
  props: {
    appId: {
      type: String,
    },
  },
  setup(props) {
    const text = ref("API调用平均响应时间 TOP10");
    const timer = ref(null);
    const RightBottomScrollBox = ref(null);
    const state = reactive({
      list: [
        { dbName: "财务看板", time: "3", unti: "min" },
        { dbName: "制造看板", time: "2", unti: "min" },
        { dbName: "库存看板", time: "1", unti: "min" },
        { dbName: "利率看板", time: "30", unti: "s" },
        { dbName: "销售看板", time: "20", unti: "s" },
      ],
    });
    const formatProgress = () => {
      return "";
    };
    const colors = [
      { color: "#f56c6c", percentage: 20 },
      { color: "#e6a23c", percentage: 40 },
      { color: "#5cb87a", percentage: 60 },
      { color: "#1989fa", percentage: 80 },
      { color: "#6f7ad3", percentage: 100 },
    ];
    const getData = () => {
      request.costInvoke({ appId: props.appId }).then((res) => {
        if (res.data.code == 200) {
          res.data.data.map((item) => {
            item.avgCost = item.avgCost
              ? (item.avgCost / 1000).toFixed(2)
              : "-";
          });
          state.list = res.data.data;
          start();
        }
      });
    };
    getData();
    const ListScroll = () => {
      let scrollDom = RightBottomScrollBox.value;
      // 判读组件是否渲染完成
      if (scrollDom.offsetHeight == 0) {
        scrollDom = RightBottomScrollBox.value;
      } else {
        // 如果列表数量过少不进行滚动
        if (scrollDom.children.length < 6) {
          clearTimeout(timer.value);
          return;
        }
        // 组件进行滚动
        scrollDom.scrollTop += 1;
        // 判断是否滚动到底部
        if (
          scrollDom.scrollTop ==
          scrollDom.scrollHeight - scrollDom.clientHeight
        ) {
          // 获取组件第一个节点
          let first = scrollDom.children[0];
          // 删除节点
          scrollDom.removeChild(first);
          // 将该节点拼接到组件最后
          scrollDom.append(first);
        }
      }
    };
    const start = () => {
      clearTimeout(timer.value);
      // 定时器触发周期
      let speed = ref(25);
      timer.value = setInterval(ListScroll, speed.value);
    };
    onBeforeUnmount(() => {
      clearTimeout(timer.value);
    });
    onUnmounted(() => {
      clearTimeout(timer.value);
    });
    const handleMouseEnter = () => {
      clearTimeout(timer.value);
    };
    const handleMouseLeave = () => {
      start();
    };
    watch(
      () => props.appId,
      (newData) => {
        if (newData) {
          state.list = [];
          clearTimeout(timer.value);
          getData();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return {
      text,
      state,
      formatProgress,
      colors,
      getData,
      timer,
      RightBottomScrollBox,
      ListScroll,
      start,
      handleMouseLeave,
      handleMouseEnter,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "./RightBottom.scss";
</style>