<template>
    <div class="condition-config">
        <div class="operation-sql">
            <div class="btn-left"></div>
            <div class="btn-right">
                <el-button type="primary" @click="addCondition">添加条件</el-button>
                <el-button @click="addGroup">添加条件组</el-button>
            </div>
        </div>

        <h5 align="left" v-if="conditionData.list.filter(el => !el.groupFilterList).length > 0">条件</h5>
        <el-table :data="conditionData.list.filter(el => !el.groupFilterList)" :show-header="false" max-height="385">
            <el-table-column prop="operator" label="链接符号" width="80" align="center">
                <template #default="scope">
                    <span class="operator" @click="changeLinkCharacter(scope.row)">{{ scope.row.operator }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="tableName" label="表名">
                <template #default="scope">
                    <el-select v-model="scope.row.tableName" filterable clearable placeholder="请选择表名" @focus="getTableList" @change="changeTable(scope.row)">
                        <el-option v-for="item in conditionData.tableList" :key="item.id" :label="item.id"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column prop="columnName" label="字段名">
                <template #default="scope">
                    <el-select v-model="scope.row.columnName" filterable clearable placeholder="请选择字段名" @change="changeField(scope.row)">
                        <el-option v-for="item in scope.row.fieldsList" :key="item.columnName" :label="item.columnName"
                            :value="item.columnName">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column prop="express" label="操作符">
                <template #default="scope">
                    <el-select v-model="scope.row.express" clearable placeholder="请选择表达式">
                        <el-option v-for="item in joinOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column prop="valueType" label="值类型">
                <template #default="scope">
                    <el-select v-model="scope.row.valueType" filterable clearable placeholder="请选择值类型">
                        <el-option label="动态参数" value="dynamics"></el-option>
                        <el-option label="固定参数" value="regular"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column prop="value" label="值">
                <template #default="scope">
                    <el-input v-if="scope.row.valueType === 'regular'" v-model="scope.row.value" clearable placeholder=""></el-input>

                    <el-select v-else v-model="scope.row.value" filterable placeholder="">
                        <el-option v-for="item in paramList" :key="item.paramName" :label="item.paramName"
                            :value="item.paramName">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="60">
              <template #default="scope">
                <!-- v-if="scope.$index > 0" -->
                <el-button icon="minus" circle type="danger" size="small" @click="deleteRow(scope.$index)"></el-button>
              </template>
            </el-table-column>
        </el-table>

        <h5 align="left" v-if="conditionData.list.filter(el => !!el.groupFilterList).length > 0">条件组</h5>
        <div v-for="(item, index) in conditionData.list.filter(el => !!el.groupFilterList)" :key="index">
            <el-row class="group_table">
                <el-col :span="3">
                    <span @click="changeLinkCharacter(item)">{{ item.operator }}</span>
                </el-col>
                <el-col :span="21">
                    <el-table :data="item.groupFilterList" :show-header="false" :row-style="rowStyle">
                        <el-table-column prop="operator" label="链接符号" width="80" align="center">
                            <template #default="scope">
                                <span class="operator" @click="changeLinkCharacter(scope.row)">{{ scope.row.operator }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="tableName" label="表名">
                            <template #default="scope">
                                <el-select v-model="scope.row.tableName" filterable clearable placeholder="请选择表名" @focus="getTableList" @change="changeTable(scope.row)">
                                    <el-option v-for="item in conditionData.tableList" :key="item.id" :label="item.id"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column prop="columnName" label="字段名">
                            <template #default="scope">
                                <el-select v-model="scope.row.columnName" filterable clearable placeholder="请选择字段名" @change="changeField(scope.row)">
                                    <el-option v-for="item in scope.row.fieldsList" :key="item.columnName" :label="item.columnName"
                                        :value="item.columnName">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column prop="express" label="操作符">
                            <template #default="scope">
                                <el-select v-model="scope.row.express" clearable placeholder="请选择表达式">
                                    <el-option v-for="item in joinOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column prop="valueType" label="值类型">
                            <template #default="scope">
                                <el-select v-model="scope.row.valueType" filterable clearable placeholder="请选择值类型">
                                    <el-option label="动态参数" value="dynamics"></el-option>
                                    <el-option label="固定参数" value="regular"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column prop="value" label="值">
                            <template #default="scope">
                                <el-input v-if="scope.row.valueType === 'regular'" v-model="scope.row.value" clearable placeholder=""></el-input>

                                <el-select v-else v-model="scope.row.value" filterable placeholder="">
                                    <el-option v-for="item in paramList" :key="item.paramName" :label="item.paramName"
                                        :value="item.paramName">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="60">
                        <template #default="scope">
                            <el-button icon="minus" circle type="danger" size="small" @click="deleteGroupRow(item.groupFilterList, scope.$index, index)"></el-button>
                        </template>
                        </el-table-column>
                    </el-table>
                    <el-button @click="addConfitionGroup(item.groupFilterList)" size="small" style="margin-top: 6px;">新增条件</el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
export default {
    props: {
        data: Array,
        paramList: Array
    },
    setup(props) {
        const store = useStore()

        const conditionData = reactive({
            list: [
                // { tableName: '', columnName: '', express: '', valueType: props.paramList.length > 0 ? 'dynamics' : 'regular', paramName: '', operator: '', fieldsList: [] }
            ],
            tableList: [], // 选中表名-列表
        })

        const expresss = {
            '=': '=',
            '>': '>',
            '<': '<',
            '>=': '>=',
            '<=': '<=',
            '<>': '<>',
            包含: 'in',
            不包含: 'not in',
            匹配: 'like',
            不匹配: 'not like',
        }
        const joinOptions = Object.entries(expresss).map(el => ({
            value: el[1],
            label: el[0],
        }))

        const addCondition = () => {
            conditionData.list.push({
                tableName: '',
                columnName: '',
                express: '',
                valueType: props.paramList.length > 0 ? 'dynamics' : 'regular',
                paramName: '',
                operator: conditionData.list.length === 0 ? '' : 'AND',
                // operator: 'AND',
                fieldsList: []
            })
        }

        const changeLinkCharacter = (row) => {
            const { operator } = row
            if (operator) {
                row.operator = operator === 'AND' ? 'OR' : 'AND'
            }
        }

        const deleteRow = (index) => {
            let delIndex = -1
            conditionData.list.forEach((el, idx) => {
                if (!el.groupFilterList) {
                    delIndex++
                }
                if (delIndex === index) {
                    conditionData.list.splice(idx, 1);
                }
            })
            const list = conditionData.list.filter(el => !el.groupFilterList)
            if (list.length > 0) {
                list[0].operator = ''
            }
        }

        const deleteGroupRow = (arr, index, itemidx) => {
            if (arr.length > 1) {
                arr.splice(index, 1);
                arr[0].operator = ''
            } else {
                let delIndex = -1
                conditionData.list.forEach((el, index) => {
                    if (el.groupFilterList) {
                        delIndex++
                    }
                    if (delIndex === itemidx) {
                        conditionData.list.splice(index, 1);
                    }
                })
                
            }
        }

        const getTableList = () => {
            const { canvasData } = store.state
            conditionData.tableList = canvasData.nodes
        }

        const changeTable = (row) => {
            const { selectedTableObj } = store.state
            row.fieldsList = selectedTableObj[row.tableName] || []
            row.columnName = ''
            row.dataType = ''
            row.tableNameAlias = row.tableName
        }

        const changeField = (row) => {
            const { columnName, fieldsList } = row
            const curFields = fieldsList.filter(el => el.columnName === columnName)
            if (curFields.length > 0) {
                row.dataType = curFields[0].columnType || ''
            }
        }

        const addGroup = () => {
            const item = {
                tableName: '',
                columnName: '',
                express: '',
                valueType: props.paramList.length > 0 ? 'dynamics' : 'regular',
                paramName: '',
                operator: '',
                fieldsList: []
            }
            conditionData.list.push({
                groupFilterList: [item],
                operator: 'AND',
                tableNameAlias: ''
            })
        }

        const addConfitionGroup = (item) => {
            item.push({
                tableName: '',
                columnName: '',
                express: '',
                valueType: props.paramList.length > 0 ? 'dynamics' : 'regular',
                paramName: '',
                operator: conditionData.list.length === 0 ? '' : 'AND',
                fieldsList: []
            })
        }

        const rowStyle = () => {
            return {
                background: '#cccccc20'
            }
        }

        onMounted(() => {
            if (props.data && props.data.length > 0) {
                conditionData.list = props.data
            }
        })

        // 删除节点时同步清空相关的条件
        watch(
            () => store.state.canvasData.nodes,
            () => {
                const tables = store.state.canvasData.nodes.map(el => el.id)
                conditionData.list = conditionData.list.filter(el => {
                    if (el.groupFilterList) {
                        el.groupFilterList = el.groupFilterList.filter(item => tables.includes(item.tableName))
                        if (el.groupFilterList.length > 0) return el
                    } else {
                        if (tables.includes(el.tableName)) return el
                    }
                })
            }
        )

        return {
            conditionData, joinOptions, addCondition, changeLinkCharacter, deleteRow, getTableList, changeTable, changeField, addGroup, addConfitionGroup, deleteGroupRow, rowStyle
        }
    }
}
</script>

<style lang="less" scoped>
    .condition-config {
        height: 450px;
        .operation-sql {
            display: flex;
            justify-content: space-between;
            background: #fff;
            padding: 12px;
        }
        .operator {
            cursor: pointer;
        }

        .group_table {
            align-items: center;
            border: 1px solid #ebeef5;
            padding-bottom: 12px;
            margin-bottom: 12px;
            border-radius: 8px;
            background: #cccccc20;
        }
    }
</style>