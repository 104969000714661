<template>
  <div class="common-layout" style="height: 100%;width: 100%">
    <el-container style="height: 100%;width: 100%;">
      <!-- <el-aside style="width: 15%;height: 100%">
        <el-row style="height: 100%">
          <el-col>
            <el-menu class="background-color-menu" :default-active="routerPath" style="background:#FFF;height:100%">
              <el-menu-item v-has="'hidden&basicSetup.app'" index="/home/BaseConfig/App/AppInformation"
                @click="change('App')" class="el-menu-item-fontsize">
                <span>应用配置</span>
              </el-menu-item>
              <el-menu-item v-has="'hidden&basicSetup.authority'" index="/home/BaseConfig/AuthorityManagement"
                @click="change('AuthorityManagement')" class="el-menu-item-fontsize">
                <span>权限管理</span>
              </el-menu-item>
              <el-menu-item v-has="'hidden&basicSetup.role'" index="/home/BaseConfig/BaseConfigRole"
                @click="change('BaseConfigRole')" class="el-menu-item-fontsize">
                <span>角色管理</span>
              </el-menu-item>
              <el-menu-item v-has="'hidden&basicSetup.user'" index="/home/BaseConfig/BaseConfigUser"
                @click="change('BaseConfigUser')" class="el-menu-item-fontsize">
                <span>用户管理</span>
              </el-menu-item>
              <el-menu-item v-has="'hidden&basicSetup.config'" index="/home/BaseConfig/BaseConfigBase/lookup"
                @click="change('BaseConfigBase')" class="el-menu-item-fontsize">
                <span>基础配置</span>
              </el-menu-item>
              <el-menu-item v-has="'hidden&basicSetup.auditConf'" index="/home/BaseConfig/ApprovalConfig"
                @click="change('ApprovalConfig')" class="el-menu-item-fontsize">
                <span>审批配置</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-aside> -->
      <el-main style="min-height:750px;padding: 24px;">
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "BaseConfig",
  methods: {
    change(x) {
      router.push('/home/BaseConfig/' + x)
    }
  },
  computed: {
    routerPath() {
      return this.$route.meta.GuidePath ? this.$route.meta.JumpPath : this.$route.path
    }
  }
}
</script>

<style scoped></style>