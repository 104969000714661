<template>
  <div style="height:260px" class="main_bgimg bg_img1">
    <div class="main_header">
      <div class="main_header_text">{{ text }}</div>
    </div>
    <div class="main_content iasm-flex">
      <div class="left">
        <img :src="manualImg" />
        <p>手工创建</p>
        <p>
          <span><strong>{{ state.apiManualCount }}</strong>个</span>
          <span><strong>{{ state.apiManualPercentage }}</strong>%</span>
        </p>
      </div>
      <div class="cycle-bg">
        <div id="echarts-cycle" :style="{ height: '150px', width: '150px' }"></div>
      </div>
      <div class="right">
        <div style="padding-left: 20px;">
          <img :src="registeredImg"/>
          <p>第三方注册</p>
          <p>
            <span><strong>{{ state.apiRegisterCount }}</strong>个</span>
            <span><strong>{{ state.apiRegisterPercentage }}</strong>%</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted, watch } from "vue"
import * as echarts from 'echarts'
import {request} from '@/api'
import manualImg from '@/assets/image/cockpit/manual.png'
import registeredImg from '@/assets/image/cockpit/registered.png'
import { ElMessage } from 'element-plus'
export default {
  name: "LeftTop",
  props: {
    appId: {
      type: String,
    },
  },
  setup(props) {
    onMounted(() => {
      initeCharts()
    })
    const text = ref("API分布");
    const state = reactive({
      apiManualCount: 0,
      apiManualPercentage: 0,
      apiRegisterCount: 0,
      apiRegisterPercentage: 0,
      options: {
        series: [
          {
            type: 'pie',
            radius: [38, 46],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: '#0072ff' // 0% 处的颜色
                }, {
                    offset: 1, color: '#3ea8f4' // 100% 处的颜色
                }]
              },
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: '#60efcd' // 0% 处的颜色
                }, {
                    offset: 1, color: '#0cc89b' // 100% 处的颜色
                }]
              }
            ],
            colorBy: 'data',
            avoidLabelOverlap: false,
            startAngle: 0,
            emphasis: {
              scale: false
            },
            itemStyle: {
              borderRadius: 12,
              borderColor: '#00041e',
              borderWidth: 2,
            },
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 0, name: '手工创建' },
              { value: 0, name: '第三方注册' }
            ]
          },
          {
            type: 'pie',
            radius: [46, 68],
            color: [
              '#001d50',
              '#022b38'
            ],
            startAngle: 0,
            avoidLabelOverlap: false,
            itemStyle: {
              borderWidth: 2,
              borderColor: '#00041e'
            },
            emphasis: {
              scale: false
            },
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            data: [0, 0]
          }
        ]
      }
    })

    const initData = async () => {
      return new Promise((resolve, reject) => {
        request.differentApiPercentage({ appId: props.appId }).then((res) => {
          if(res.status === 200) {
            const { code, data } = res.data
            if (code === 200) {
              resolve(data)
            } else {
              reject()
            }
          } else {
            reject()
          }
        }).catch(err => {
          reject(err)
        })
      })
    }

    const initeCharts = () => {
      initData().then(data => {
        state.apiManualCount = data.apiManualCount
        state.apiManualPercentage = data.apiManualPercentage.replace('%', '')
        state.apiRegisterCount = data.apiRegisterCount
        state.apiRegisterPercentage = data.apiRegisterPercentage.replace('%', '')
        state.options.series[0].data[0].value = data.apiManualCount
        state.options.series[0].data[1].value = data.apiRegisterCount
        state.options.series[1].data = [data.apiManualCount, data.apiRegisterCount]
      }).catch(err => {
        ElMessage.error(err || 'API分布初始化错误')
      }).finally(() => {
        // 绘制图表
        let myChart1 = echarts.init(document.getElementById('echarts-cycle'))
        myChart1.setOption(state.options)
        // 根据页面大小自动响应图表大小
        window.addEventListener("resize", function () {
          myChart1.resize()
        })
      })
    }

    watch(
      () => props.appId,
      (newData) => {
        if (newData) {
          initeCharts();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    )
    return {
      text,
      manualImg,
      registeredImg,
      state
    };
  },
};
</script>
<style lang="scss" scoped>
@import "./LeftTop.scss";
</style>