<template>
  <div class="content box-card-radius-eight white-bg">
    <div class="toolbar">
      <div class="tool">
        <el-tooltip v-for="item in toolbarItemList" :content="item.tooltip" :key="item.type">
          <div @click="onControl(item)">
            <el-icon style="margin-right:5px;" ><component :is="item.icon" /></el-icon>
            <span>{{item.tooltip}}</span>
            <el-divider  direction="vertical" />
          </div>
        </el-tooltip>
        <el-tooltip content="打开网格" v-if="!gridStatus">
          <div  @click="gridSwitch(true)">
            <el-icon style="margin-right:5px;" ><Grid /></el-icon>
            <span>打开网格</span>
          </div>
        </el-tooltip>
        <el-tooltip content="关闭网格" v-if="gridStatus">
          <div  @click="gridSwitch(false)">
            <el-icon style="margin-right:5px;" ><Menu /></el-icon>
            <span>关闭网格</span>
          </div>
        </el-tooltip>
         
      </div>
      <div class="name" >{{arrangeDetailData.iasmApiArrange.apiName}}<el-icon style="color:#1890ff;margin-left:5px;" v-if="!isView" @click="handleEdit"><Edit /></el-icon></div>
      <div class="btn-box">
        <el-button type="primary" @click="saveOperator" v-if="!isView">保存编排</el-button>
      </div>
    </div>
    <div class="diagram-main">
      <div class="diagram-sidebar">
        <OperatorTools @dragInNode="dragInNode" @getStreamOperators="getStreamOperators"/>
      </div>

      <div ref="lfElRef" class="diagram-container"> </div>
    </div>
    <operatorDrawer ref="operatorDrawerRef" @handleSuccess="handleSuccess"/>
    <paramsDialog ref="paramsDialogRef" />

  </div>
</template>

<script >
import { ref,unref,nextTick,computed,onMounted,onBeforeUnmount} from 'vue';
import {useRoute,useRouter} from 'vue-router'
import { request } from "@/api";
import { ElMessage } from "element-plus";
import {operations} from './config'
import LogicFlow from '@logicflow/core';
import '@logicflow/core/dist/style/index.css';
import '@logicflow/extension/lib/style/index.css';
import { createFlowChartContext } from './useFlowContext';
import { toLogicFlowData } from './adpterForTurbo';
import { Snapshot, BpmnElement, Menu, DndPanel, SelectionSelect } from '@logicflow/extension';
import streamOperator from './node';
import OperatorTools from './OperatorTools'
import operatorDrawer from '../operatorDrawer'
import paramsDialog from '../paramsDialog'
export default {
  name: "ApiFlowChartManagementDetail",
  components:{
    OperatorTools,
    operatorDrawer,
    paramsDialog
  },
  props:{
    flowOptions: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    patternItems: {
      type: Array,
    },
    model: String,
  },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const isView = ref(route?.query?.operation === 'view')
    var count = '';
    let arrangeDetailData = ref({
      iasmApiArrange:{
        apiName:'',
        iasmApiArrange:null
      }
    })
    //算子信息
    const operatorData = ref({});
    let showOperatorDrawer = ref(false)
    let streamOperators = ref(null)
    let lfInstance = ref(null)
    let operatorDrawerRef = ref(null)
    let paramsDialogRef = ref(null)
    const lfElRef = ref(null);
    createFlowChartContext({
      logicFlow: lfInstance
    });

    const getFlowOptions = computed(() => {
      const { flowOptions } = props;
      const defaultOptions = {
        grid: false,
        background: {
          //color: appStore.getDarkMode === 'light' ? '#f7f9ff' : '#151515',
          backgroundRepeat: 'repeat',
        },
        keyboard: {
          enabled: true,
        },
        ...flowOptions,
      };
      return defaultOptions;
    });

     const init =  async ()=> {
      await nextTick();

      const lfEl = unref(lfElRef);
      if (!lfEl) {
        return;
      }

      LogicFlow.use(DndPanel);

      //LogicFlow.use(htmldemo);

      // Canvas configuration
      LogicFlow.use(Snapshot);
      // Use the bpmn plug-in to introduce bpmn elements, which can be used after conversion in turbo
      LogicFlow.use(BpmnElement);
      // Start the right-click menu
      LogicFlow.use(Menu);
      LogicFlow.use(SelectionSelect);

      lfInstance.value = new LogicFlow({
        ...unref(getFlowOptions),
        container: lfEl,
      });
      const lf = unref(lfInstance);

      //注册自定义节点
      lf.register(streamOperator);
      if(localStorage.getItem("operatorData")){
        operatorData.value = JSON.parse(localStorage.getItem("operatorData"))
      }
      lf.extension.menu.setMenuConfig({
        nodeMenu: [
          {
            text: "删除",
            callback(node) {
              lf.deleteNode(node.id);
              operatorData.value[node.id] = null
            },
          },
          {
            text: "编辑节点描述",
            callback(node) {
              lf.editText(node.id);
            },
          },
          {
            text: "复制",
            callback(node) {
              let returnNode = lf.cloneNode(node.id);
              let num = Math.floor(Math.random()*(100 -1) + 1)
              if(operatorData.value[node.id]){
                operatorData.value[returnNode.id] = {
                operatorTaskConfig: null,
                operatorTaskMapperTables: null
              }
                let newTaskData = operatorData.value[node.id].operatorTaskConfig.taskData && JSON.parse(operatorData.value[node.id].operatorTaskConfig.taskData)
                newTaskData.configName = newTaskData.configName + '_' + num
                operatorData.value[returnNode.id].operatorTaskConfig =  {...operatorData.value[node.id].operatorTaskConfig}

                operatorData.value[returnNode.id].operatorTaskMapperTables = operatorData.value[node.id].operatorTaskMapperTables

                operatorData.value[returnNode.id].operatorTaskConfig.taskData = JSON.stringify(newTaskData)

                operatorData.value[returnNode.id].operatorTaskConfig.operatorFlowId = returnNode.id
                operatorData.value[returnNode.id].operatorTaskConfig.taskConfigName = operatorData.value[node.id].operatorTaskConfig.taskConfigName + '_' + num
              }else{
                lf.setProperties(returnNode.id,{...node.properties,defaultNodeName:node.properties.defaultNodeName + '_' + num})
              }
            },
          },
        ],
      });

      lf.on('node:dbclick', (data) => {
        console.log('>>dbclick>>>>>',data)
        
        //获取，判断该接单是否有数据

        //获取配置的json数据
        let lf = unref(lfInstance);
        if (!lf) {
          return;
        }
        let nodeConfigGraphData = unref(lf).getGraphData();

        //获取当前节点ID
        // let id = data.data.id;

        //获取节点配置关联信息
        let defaultNodeName = ''
        let nodeCode = ''
        let nodeId = ''
        if(operatorData.value[data.data.id] && operatorData.value[data.data.id].iasmApiArrangeNode){
          defaultNodeName = operatorData.value[data.data.id].iasmApiArrangeNode.nodeConfigName
          nodeCode = operatorData.value[data.data.id].iasmApiArrangeNode.nodeCode || ''
          nodeId = operatorData.value[data.data.id].iasmApiArrangeNode.nodeId || ''
        } else {
          defaultNodeName = data.data.properties.defaultNodeName
          nodeCode = data.data.properties.nodeCode || ''
          nodeId = data.data.properties.nodeId || ''
        }

        data.nodeName = defaultNodeName
        data.nodeCode = nodeCode
        data.nodeId = nodeId

        if(data.nodeId === '1146490369635262464' || data.nodeId === '1146490369635262470') return

        operatorDrawerRef.value.show({
          data,
          operatorData: operatorData.value,
          nodeConfigGraphData: nodeConfigGraphData,
          iasmApiArrange:arrangeDetailData.value.iasmApiArrange
        })
      });
      lf.on('node:click', (data) => {
        let elements = document.getElementsByClassName('defaultImg')
        let activeElements = document.getElementsByClassName('activeImg')
        if (elements && elements.length > 0){
          elements[0].style.display = 'block'
          elements[0].className = ''
        }
        if (activeElements && activeElements.length > 0){
          activeElements[0].style.display = 'none'
          activeElements[0].className = ''
        }
        let id = data.data.id
        let defaultImg = document.getElementById('default_'+id)
        let activeImg = document.getElementById('active_'+id)
        defaultImg.className = 'defaultImg'
        activeImg.className = 'activeImg'
        defaultImg.style.display = 'none'
        activeImg.style.display = 'block'


      });

      lf?.setDefaultEdgeType('polyline');

      lf?.setTheme({
        polyline: {
          stroke: '#34AA9A',
          strokeWidth: 1,
        },
      });

      //  onRender();
      // lf?.setPatternItems(props.patternItems || configDefaultDndPanel(lf));
        handleData();
    }

     const  onRender = async ()=> {
      await nextTick();
      const lf = unref(lfInstance);
      if (!lf) {
        return;
      }
      const lFData = toLogicFlowData(props.data);
      lf.render(lFData);
    }

    const getStreamOperators = (data)=> {
      streamOperators.value = [...data]
    }

    const dragInNode = async (data)=> {
      let num = '';
      count < 10 ? (num = '0' + count) : (num = count);
      const lf = unref(lfInstance);
      //传递文本信息
      await lf.dnd.startDrag({
        type: 'streamOperator',
        text: data.text + '_' + num,
        defaultNodeName: data.text + '_' + num,
        properties: {
          icon: data.icon,
          operator_id: data.id,
          nodeId:data.nodeId,
          operatorType: data.operatorType,
          operatorCode:data.nodeCode,
          nodeCode:data.nodeCode,
          defaultNodeName: data.text + '_' + num,
        },
      });
      
      count += 1;
      // //开始节点和结束节点
      if(data.nodeId === '1146490369635262464' || data.nodeId === '1146490369635262470'){
        const query = route.query
        operatorData.value[data.nodeId]={
          iasmApiArrangeNode:{
            apiId:query.apiId,
            extend:null,
            nodeCode:data.nodeCode,
            nodeConfigId:data.nodeId,
            nodeConfigName:data.text + '_' + num,
            nodeData:null,
            nodeId:data.nodeId,
            operatorFlowId:data.nodeId
          }
        }
      }
    }

   

    async function handleData() {
      await nextTick();

      const lf = unref(lfInstance);
      if (!lf) {
        return;
      }

        let data = {}
        const query = route.query
        data = await request.getApiArrangeDetails({apiId:query.apiId})
        arrangeDetailData.value = data.data.data
        //渲染拖拉拽显示组件
        let taskConfigParamGson  = null

        if (localStorage.getItem('taskConfigParamGson')){
          taskConfigParamGson = JSON.parse(localStorage.getItem('taskConfigParamGson'))
        } else {
          taskConfigParamGson = data.data.data.apiArrangeParamGson;
        }
        if (taskConfigParamGson) {
          //必须得有组件拖拉拽配置才能渲染
          lf.render(taskConfigParamGson);
          lf.translateCenter();
          //获取每个组件的详情数据信息
          if(localStorage.getItem("operatorData")){
            operatorData.value = JSON.parse(localStorage.getItem("operatorData"))
          }else{
              let configData
              configData = data.data.data.apiArrangeConfigConfigs
              //初始化各节点数据
              configData.forEach((item) => {
                operatorData.value[item?.iasmApiArrangeNode?.operatorFlowId] = item;
              });
          }
          
          if (taskConfigParamGson.nodes) {
            count = taskConfigParamGson.nodes.length + 1;
          } else {
            count = 1;
          }
          // if(route.query && route.query.instanceId){
          //   idfCountData = await querybatchMetricsCount({ instanceId: route.query.instanceId });
          //   createTooltipNodes(configData,idfCountData)

          //   timer.value = setInterval(async ()=>{
          //     idfCountData = await querybatchMetricsCount({ instanceId: route.query.instanceId });
          //     changeIdfCount(idfCountData)
          //   },1000)
          // }
        } else {
          count = 1;
          onRender();
        }
    }

    let statusShow = sessionStorage.getItem('gridStatus') || 'hide'
    let gridStatus = ref(statusShow === 'show')

    const toolbarItemList = ref([
      {
        type: operations.ZOOM_IN,
        icon: 'ZoomIn',
        tooltip: '放大',
      },
      
      {
        type: operations.ZOOM_OUT,
        icon: 'ZoomOut',
        tooltip: '缩小',
      },
      
      {
        type: operations.RESET_ZOOM,
        icon: 'Refresh',
        tooltip: '还原',
      },
      
      {
        type: operations.UNDO,
        icon: 'DArrowLeft',
        tooltip: '后退',
        disabled: true,
      },
      {
        type: operations.REDO,
        icon: 'DArrowRight',
        tooltip: '前进',
        disabled: true,
      },
      
      {
        type: operations.SNAPSHOT,
        icon: 'Download',
        tooltip: '下载',
      },
      {
        type: operations.PARAMS_DATA,
        icon: 'Operation',
        tooltip: '参数配置',
      }
    ]);


     const onControl = (item) => {
      const lf = unref(lfInstance);
      if (!lf) {
        return;
      }
      switch (item.type) {
        case operations.ZOOM_IN:
          lf.zoom(true);
          break;
        case operations.ZOOM_OUT:
          lf.zoom();
          break;
        case operations.RESET_ZOOM:
          lf.resetZoom();
          break;
        case operations.UNDO:
          lf.undo();
          break;
        case operations.REDO:
          lf.redo();
          break;
        case operations.SNAPSHOT:
          lf.getSnapshot();
          break;
        case operations.PARAMS_DATA:
          openParameter()
          break
        // case operations.SAVE_DATA:
        //   emit('save');
        //   break
        // case operations.DRAFT_DATA:
        //   emit('saveDraft');
        //   break;
      }
    };

    const openParameter = ()=>{
      paramsDialogRef.value.show(arrangeDetailData.value.iasmApiArrange)
    }
  
    const  gridSwitch = (grid)=>{
      gridStatus.value = grid
      sessionStorage.setItem('gridStatus', grid?'show':'hide')
      let dom = document.getElementsByClassName('lf-background-area')
      if(gridStatus.value){
         dom[0].style.backgroundImage = 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAwIDEwIEwgNDAgMTAgTSAxMCAwIEwgMTAgNDAgTSAwIDIwIEwgNDAgMjAgTSAyMCAwIEwgMjAgNDAgTSAwIDMwIEwgNDAgMzAgTSAzMCAwIEwgMzAgNDAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2QwZDBkMCIgb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjEiLz48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZDBkMGQwIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=")'
      }else{
         dom[0].style.backgroundImage = null
      }
    }

    const saveOperator = ()=> {
      const lf = unref(lfInstance);
        if (!lf) {
          return;
        }
      let apiArrangeParamGson = unref(lf).getGraphData();
      
      let apiArrangeConfigConfigs = [];
      for (let key in operatorData.value) {
        if(operatorData.value[key]){
          //开始节点和结束节点
          if(key === '1146490369635262464' || key === '1146490369635262470'){
            let sNode = apiArrangeParamGson.nodes.filter(item=>{
              return item.properties.nodeId === key
            })[0]
            operatorData.value[key].iasmApiArrangeNode.operatorFlowId = sNode.id
          }

          apiArrangeConfigConfigs.push(operatorData.value[key]);
        }
        }


      //判断是否有未填写信息的算子
      let noFillNodes = []
      apiArrangeParamGson.nodes.forEach(node=>{
        let fillNode = apiArrangeConfigConfigs.filter(config=>{
          return config.iasmApiArrangeNode.operatorFlowId ===  node.id || node.properties.nodeId == '1146490369635262464' || node.properties.nodeId === '1146490369635262470'
        })
        if(fillNode.length === 0){
          noFillNodes.push(node.text.value) }
      })
      if(noFillNodes.length > 0){
        ElMessage.error(noFillNodes.join(', ') + '算子信息未填写,请填写或删除');
        return
      }
      
      let saveParams = {
        iasmApiArrange:{
          ...arrangeDetailData.value.iasmApiArrange,
          appId:localStorage.getItem("appId"),
          apiId:route.query.apiId,
          operateType: "1"
        },
        apiArrangeParamGson,
        apiArrangeConfigConfigs
      }


        request.addOrUpdateArrangeApi({...saveParams}).then((resp) => {
          let respCode = resp.data.code;
          if (respCode == "200") {
            ElMessage.success('成功');
          } else {
            ElMessage.error(resp.data.msg);
          }
        });
    
    }

     /**
     *  填写算子，点击确定,获取算子数据信息
     */
    function handleSuccess(operatorFlowId, resultData) {
       operatorData.value[operatorFlowId] = resultData
      //把数据保存起来
        if(resultData.iasmApiArrangeNode.nodeId && resultData.iasmApiArrangeNode.nodeId === '1146490369635262467'){
          getLinesData(operatorFlowId,resultData)
        }
    }

       const handleEdit = () => {
        router.push({
          path: "/home/ApiFlowChart/ApiFlowChartManagementEdit",
          query: {
            operation: "edit",
            apiId: arrangeDetailData.value.iasmApiArrange.apiId,
          },
        });
      };

      // SWITCH算子--处理数据
    function getLinesData(operatorFlowId,resultData){
      let data = JSON.parse(resultData.iasmApiArrangeNode.nodeData).data;
      const lf = unref(lfInstance);
        if (!lf) {
          return;
        }
      let apiArrangeParamGson = unref(lf).getGraphData();
      let addlines = []
      let allNodes = apiArrangeParamGson.nodes || []
      let allEdges = apiArrangeParamGson.edges || []
      let nodeList = []
      let nowNodeItem = null
      allNodes.forEach(eleX => {
        if(eleX.id == operatorFlowId){
          nowNodeItem = eleX
        }
      })
      //已经和当前switch算子连接的节点连线集合
      let deleteLines = []
      allEdges.forEach(edge=>{
        if(edge.sourceNodeId === nowNodeItem.id){
          deleteLines.push(edge.id)
        }
      })
      allNodes.forEach(eleX => {
        data.forEach(eleY => {
          if(eleY.nodeId && eleY.nodeId == eleX.id){
            let nowLines = getPointToLine(nowNodeItem,eleX)
            addlines.push(nowLines)
          }
        });
      })

      if (addlines.length > 0) {
        const lf = unref(lfInstance);
        if (!lf) {
          return;
        }
        let apiArrangeParamGson = unref(lf).getGraphData();
        let edges 
        if(deleteLines){
          edges = apiArrangeParamGson.edges.filter(edge=>{
            return !deleteLines.includes(edge.id)
          })
        }
        apiArrangeParamGson.edges = [...addlines,...edges]
        apiArrangeParamGson.nodes = [...nodeList,...apiArrangeParamGson.nodes]
        localStorage.setItem('taskConfigParamGson',JSON.stringify(apiArrangeParamGson))
        localStorage.setItem('operatorData',JSON.stringify({
          ...operatorData.value,
          [operatorFlowId]:resultData
        }))
        
        init(false)
    }
    }

    // switch和规则算子--连线
    function getPointToLine(startItem, endItem){
      let isLeftX = startItem.x > endItem.x
      let nowLine = {
            id:new Date().getTime().toString() + Math.random(),
            type: 'polyline',
            sourceNodeId: startItem.id,
            targetNodeId: endItem.id,
            startPoint:{x: isLeftX ? startItem.x - 30 : startItem.x + 30, y: startItem.y },
            endPoint:{x: isLeftX ? endItem.x + 30 : endItem.x - 30, y: endItem.y},
            pointsList:[
              {x: isLeftX ? startItem.x - 30 : startItem.x + 30, y: startItem.y},
              {x: isLeftX ? startItem.x - 40 : startItem.x + 40, y: startItem.y},
              {x: isLeftX ? startItem.x - 40 : startItem.x + 40, y: endItem.y},
              {x: isLeftX ? endItem.x + 40 : endItem.x - 40, y: endItem.y}
            ],
            properties:{},
          }
       return nowLine
    }

  onMounted(()=>{
      init(true)
  })

  function destory() {
      localStorage.removeItem('taskConfigParamGson')
      localStorage.removeItem('operatorData')
  }
  onBeforeUnmount(() => {
      destory();
  });

  return{
    isView,
    handleEdit,
    count,
    operatorData,
    getStreamOperators,
    dragInNode,
    streamOperators,
    init,
    lfInstance,
    lfElRef,
    gridSwitch,
    gridStatus,
    toolbarItemList,
    onControl,
    onRender,
    showOperatorDrawer,
    operatorDrawerRef,
    paramsDialogRef,
    handleData,
    saveOperator,
    handleSuccess,
    getLinesData,
    arrangeDetailData
  }
  },
    
}
</script>


<style scoped lang="less">
  .content{
    display: flex;
    flex-direction: column;
    margin: 20px 30px;
    padding: 0;
    height: calc(100% - 101px);
    .toolbar{
      padding:15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom:1px solid #d9d9d9;
      .tool{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        color: #091328;
        div{
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
        }
      }
      .name{
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        color: #091328;
        background: #fff;
      }
    }
    .diagram-main{
      display: flex;
      flex:1;
      overflow: hidden;
      .diagram-sidebar{
        width:270px;
        height: 100%;
        overflow-y: hidden;
      }
      .diagram-container{
        flex:1;
        height: 100%;
      }
    }
  }
</style>