<template>
  <div class="createSql" style="margin:28px;padding-bottom:90px;">
    <el-form ref="ruleFormRef" :model="form" :rules="basicRules" label-position="top">
    <div  class="box-card-radius-eight white-bg margin-botton-12">
     <div align="left" class="step-title margin-botton-12"><strong>基本信息</strong></div>
          
            <el-row :gutter="24">
              <el-col :span="8" align="left">
                <el-form-item label="密钥名称" placeholder="请输入密钥名称" prop="secretkeyName">
                  <el-input v-model="form.secretkeyName" maxlength="100" show-word-limit :disabled="isView"/>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="密钥编码" placeholder="密钥编码" prop="secretkeyCode">
                  <el-input v-model="form.secretkeyCode" maxlength="100" show-word-limit :disabled="isEdit || isView" />
                </el-form-item>
              </el-col>
               <el-col :span="8" align="left">
                <el-form-item label="密码类型" prop="secretkeyType">
                  <el-select
                    v-model="form.secretkeyType"
                      filterable
                    style="width: 100%"
                    :disabled="isView"
                  >
                    <el-option
                      v-for="item in keyTypeOptionMap"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24" align="left">
              <el-form-item label="密钥描述">
              <el-input
                v-model="form.secretkeyDesc"
                maxlength="1000"
                show-word-limit
                type="textarea"
                :rows="4"
                :disabled="isView"
              />
            </el-form-item>
            </el-col>
            </el-row>
             </div>
          <div  class="box-card-radius-eight white-bg">
           <div align="left" class="step-title margin-botton-12"><strong>密钥设置</strong></div>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="公钥">
                  <el-input
                    v-model="form.publicKey"
                    :disabled="isView"
                    maxlength="1000"
                    show-word-limit
                    type="textarea"
                    :rows="4"
                  />
                </el-form-item>
             </el-col>
             <el-col :span="8">
                <el-form-item label="私匙">
                  <el-input
                    v-model="form.privateKey"
                    :disabled="isView"
                    maxlength="1000"
                    show-word-limit
                    type="textarea"
                    :rows="4"
                  />
                </el-form-item>
             </el-col>
             <el-col :span="8">
                <el-form-item label="其他密钥">
                  <el-input
                    v-model="form.otherKey"
                    :disabled="isView"
                    maxlength="1000"
                    show-word-limit
                    type="textarea"
                    :rows="4"
                  />
                </el-form-item>
             </el-col>
             <el-col :span="8">
              <el-form-item prop="secretkeyStarttime" label="有效开始日期">
             <el-date-picker :disabled="isView" v-model="form.secretkeyStarttime"  type="datetime" value-format="YYYY-MM-DD HH:mm:ss" placeholder="选择日期时间" style="width:100%;">
             </el-date-picker>
            </el-form-item>
            </el-col>
            <el-col :span="8">
            <el-form-item prop="secretkeyEndtime" label="有效截止日期">
             <el-date-picker :disabled="isView" v-model="form.secretkeyEndtime"  type="datetime" value-format="YYYY-MM-DD HH:mm:ss" placeholder="选择日期时间"  style="width:100%;">
             </el-date-picker>
            </el-form-item>
            </el-col>
             </el-row>
          </div>
       
 </el-form>
       
    
    <div class="footer" align="right">
      <div class="btn">
        <el-button  @click="cancel">取消</el-button>
       
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted} from 'vue';
import {request} from '@/api'
import { ElMessage } from "element-plus";
import { useRouter,useRoute } from "vue-router";
import {keyTypeOptions} from '@/utils/constant'

export default {
  name: 'KeyManagementDetail',
  setup () {
    const keyTypeOptionMap = ref(keyTypeOptions)

    const router = useRouter();
    const route = useRoute();
    let isEdit = ref(route?.query?.operation === 'edit' || false)
    let isView = ref(route?.query?.operation === 'view' || false)

    let secretkeyId = ref(route?.query?.secretkeyId || '')
    let secretkeyCode = ref(route?.query?.secretkeyCode || '')
    let ruleFormRef = ref(null)
    let form = ref({
      secretkeyDesc:null,
      secretkeyCode:null,
      secretkeyName:null,
      secretkeyType:null,
      publicKey:null,
      privateKey:null,
      otherKey:null,
      secretkeyStarttime:null,
      secretkeyEndtime:null
    })
    const basicRules = reactive({
      secretkeyCode: [
        { required: true, message: "请输入密钥编码", trigger: "blur" },
      ],
      secretkeyName: [
        { required: true, message: "请输入密钥名称", trigger: "blur" },
      ],
      secretkeyType: [
        { required: true, message: "请选择", trigger: "change" },
      ],
    });



    const addOrUpdateSecret = ()=>{
      let id = isEdit.value ?  secretkeyId.value:''
      let saveParams = {
        ...form.value,
        projectId: 1,
        secretkeyId: id
      }
      request.addOrUpdateSecret(saveParams).then((resp) => {
        let respCode = resp.data.code;
        if (respCode == "200") {
          ElMessage.success('创建成功');
          cancel()
        } else {
          ElMessage.error(resp.data.msg);
        }
      });
    }

    const save =  async ()=>{
      if(!ruleFormRef.value) return
        await ruleFormRef.value.validate((valid) => {
        if (valid) {
          addOrUpdateSecret();
        }
      });
    }

    const cancel = ()=>{
      router.push('/home/DataResourceCenter/keyManagement/list')
    }

    const getInfo = ()=>{
      request.querySecretKeyById({secretkeyId:secretkeyId.value}).then((res) => {
        console.log(res.data)
        let result = res.data
         if (result.code == 200) {
          form.value = {...result.data}
        } else {
          ElMessage.error('异常');
        }
      })
    }

    const querySecretKeyByCode = ()=>{
      request.querySecretKeyByCode({secretkeyCode:secretkeyCode.value}).then((res) => {
        let result = res.data
         if (result.code == 200) {
          let {otherKey,privateKey,publicKey} = result.data
          form.value = {
            ...form.value,
            otherKey,privateKey,publicKey
          }
        } else {
          ElMessage.error('异常');
        }
      })
    }

   

    onMounted(()=>{
        if(route.query.operation !== 'add'){
          getInfo()
        }
        if(route.query.operation == 'view'){
          querySecretKeyByCode()
        }
        
    })
    return {
        isEdit,
        isView,
        ruleFormRef,
        basicRules,
        form,
        save,
        cancel,
        keyTypeOptionMap,
        secretkeyId,
        secretkeyCode,
        querySecretKeyByCode
    }
  },
}
</script>

<style lang="less" scoped>
  .footer {
    margin-top: 24px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 64px;
    line-height: 64px;
    background: #fff;
    z-index: 10;
    .btn {
      padding-right: 24px;
    }
  }
</style>