<template>
  <div class="api-views">
    <div class="box-card-radius-eight white-bg form-box">
      <div class="header-title">
      <strong>基础信息</strong>
      </div>
      <el-divider />
      <el-form :model="form" align-right>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item
              label="API名称："
              prop="apiName"
              label-position="right"
            >
              <span class="val"> {{ form.apiName }} </span>
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item
              label="是否鉴权："
              prop="apiIssecurity"
              label-position="right"
            >
              <span class="val"> {{ form.apiIssecurity==='N'?'否':'是' }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="请求方式：" prop="apiRequestM">
              <span class="val">
                {{RequestMaps[form.apiRequestM]}}
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="返回数据结构：">
              <span class="val">
                {{resultDataBuildMaps[form.resultDataBuild]}}
              </span>
            </el-form-item>
          </el-col>
          
          
           <el-col :span="8">
            <el-form-item label="所属应用：" prop="appName">
              <span class="val"> {{ form.appName }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="一级业务分类：" >
              <span class="val"> {{ oneBusinessCateName }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="二级业务分类：" >
              <span class="val"> {{ twoBusinessCateName }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务类型：" prop="apiServicetype">
              <span class="val"> {{ apiServiceTypesMap[form.apiServicetype] || '-' }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务类别：" >
              <span class="val">API注册</span>
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="服务版本：" >
              <span class="val"> {{ form.apiVersion}} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="API渠道：" >
              <span class="val"> {{ form.apiType === 'route_api'?'内部API':'外部API' }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="form.apiType === 'route_api'">
            <el-form-item label="注册中心项目：" >
              <span class="val"> {{ form.registryCenterId }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="API责任人：" >
              <span class="val"> {{ form.apiOwnerName? form.apiOwnerName + ' ' + form.apiOwnerNumber : '-' }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="访问URL：">
              <span class="ellipsis val"> {{ form.callUrl }} </span>
            </el-form-item>
          </el-col>
          <el-row :gutter="24" >
            <el-col :span="24">
              <el-form-item label="Mock地址：" align="left">
                <span> {{ form.mockUrl }} </span>
                <el-tooltip content="复制完整URL">
                  <el-button  type="text" icon="CopyDocument" @click="copyUrl"/>
              </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
          <el-col :span="24">
            <el-form-item label="描述：">
              <span class="ellipsis val"> {{ form.apiDescription }} </span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

 <div class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
   
   <div class="form-box">
     <div class="header-title"><strong>入参配置</strong></div>
     <el-divider />
      <el-table :data="tableData.list" height="300">
       <el-table-column
            label="参数中文名称"
            prop="paramNameCm"
            :show-overflow-tooltip="true"
          >
           <template #default="scope">
            {{scope.row.paramNameCm || scope.row.apiFieldCnname}}
          </template>
          </el-table-column>
          <el-table-column
            label="参数名称"
            prop="paramName"
            :show-overflow-tooltip="true"
          >
          <template #default="scope">
            {{scope.row.paramName || scope.row.apiFieldName}}
          </template>
          </el-table-column>
          <el-table-column
          label="数据类型"
          prop="paramType"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            {{paramsTypesMaps[scope.row.paramType] || scope.row.apiFieldType}}
          </template>
        </el-table-column>
         <el-table-column
          label="默认值"
          prop="paramValue"
          :show-overflow-tooltip="true"
        >
          <template #default="scope" >
            <span  v-if="scope.row.parameterType === 'authenticationParameters'">
              {{authenticationParameterList.length && authenticationParameterList.filter(item=>{
                return item.apiAuthId === scope.row.paramValue
              })[0].apiAuthName}}
            </span>
            <span v-else> {{scope.row.paramValue}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="是否必填"
          prop="paramIsMust"
          :show-overflow-tooltip="true"
        >
          <template #default="scope" >
             {{scope.row.paramIsMust === 'Y'?'是':'否'}}
          </template>
        </el-table-column>
        <el-table-column
          prop="paramFun"
          :show-overflow-tooltip="true"
        >
        <template #header>
              <span style="display:flex;align-items:center;">输入函数 
                <el-tooltip 
                content="函数用法:函数名称(#{参数名称},固定参数值,...) 例如:trim(#{username}),对参数变量username去除前后空格"
                placement="top"
                >
                <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
                </span>
            </template>
        <template #default="scope" >
             {{ scope.row.paramFun && functionList.filter(item=>{
              return item.functionId === scope.row.paramFun
             })[0].functionName || ''}}
        </template>
        </el-table-column>
        <el-table-column
          label="排序"
          prop="sort"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
         <el-table-column prop="example" label="示例值" width="200" :show-overflow-tooltip="true"></el-table-column>
         <el-table-column
            label="描述"
            prop="paramDescription"
            :show-overflow-tooltip="true"
          />
      </el-table>
    </div>
 </div>
<div class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
   
   <div class="form-box">
     <div class="header-title"><strong>出参配置</strong></div>
     <el-divider />
      <el-table :data="form.fieldMappingList" height="300">
       <el-table-column
            label="参数中文名称"
            prop="paramNameCm"
            :show-overflow-tooltip="true"
          >
           <template #default="scope">
            {{scope.row.paramNameCm || scope.row.apiFieldCnname}}
          </template>
          </el-table-column>
          <el-table-column
            label="参数名称"
            prop="paramName"
            :show-overflow-tooltip="true"
          >
          <template #default="scope">
            {{scope.row.paramName || scope.row.apiFieldName}}
          </template>
          </el-table-column>
          <el-table-column
          label="数据类型"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            {{paramsTypesMaps[scope.row.paramType] || scope.row.apiFieldType}}
          </template>
        </el-table-column>
          <el-table-column
              prop="example"
              label="示例值"
              width="200"
              :show-overflow-tooltip="true"
            >
             
            </el-table-column>
       <el-table-column
            label="描述"
            prop="paramDescription"
            :show-overflow-tooltip="true"
          />
      </el-table>
    </div>
 </div>

  <div class="box-card-radius-eight white-bg" style="margin-bottom:100px;">
      <div class="form-box">
      <div class="header-title"><strong>返回结果示例</strong></div>
      <el-divider />
        <CodeMirror  class="codemirror" v-model:value="form.apiResponseE" ></CodeMirror>
      </div>
  </div>

  <div class="box-card-radius-eight white-bg" style="margin-bottom:100px;" v-if="form.isMock === '1'">
      <div class="form-box">
      <div class="header-title"><strong>Mock数据</strong></div>
      <el-divider />
        <CodeMirror  class="codemirror"  v-model:value="form.mockData" ></CodeMirror>
      </div>
  </div>

 <div class="box-card-radius-eight white-bg" style="margin-bottom:60px;" v-if="fromPage == 2">
  <div class="form-box">
         <div class="header-title"><strong >审批意见</strong></div>
      <el-form :model="lform" :rules="notNull" ref="ruleFormRef">
        <el-form-item label="审批意见" prop="approvalStatus">
          <el-radio v-model="lform.approvalStatus" label="2">同意</el-radio>
          <el-radio v-model="lform.approvalStatus" label="3">驳回</el-radio>
        </el-form-item>
        <el-form-item label="" prop="rejectReasons" v-if="lform.approvalStatus === '3'">
           <el-input v-model="lform.rejectReasons" type="textarea" style="width: 100%;" rows="3" placeholder="驳回意见"  />
        </el-form-item>
      </el-form>
       </div>
      <div style="flex: auto">
        <el-button type="primary" @click="submitApproval(ruleFormRef)" v-has="'hidden&userCenter.myWaitFor.approve'">确定</el-button>
      </div>
    </div>
 
    <div align="right" class="footer white-bg">
      <el-button type="primary" @click="this.$router.back()">返回</el-button>
    </div>
  </div>
</template>
  
<script>
import { useRoute } from "vue-router";
import {request} from '@/api'
import { ElMessage } from "element-plus";
import { reactive, onMounted,ref} from "vue";
import router from '@/router';
import {paramsTypesMap,RequestMap,apiServiceTypes,createTypeMap,resultDataBuildMap} from '@/utils/constant'
import {copyClipboard} from '@/utils/function'
import CodeMirror from '@/components/common/CodeMirror'

export default {
  components:{
    CodeMirror
  },
  setup() {
    const route = useRoute();
    const routeApi = route.query.routeApi;
    const fromPage = route.query.fromPage;
    const id = route.query.id
    let functionList = ref([])
    let authenticationParameterList = ref([])

    let twoBusinessCateName = ref('-')
    let oneBusinessCateName = ref('-')
    let projectList = ref([])
    const tableData = reactive({
      //表格参数
      list: [],
    });
    let RequestMaps = ref(RequestMap)
    let resultDataBuildMaps = ref(resultDataBuildMap)
    let paramsTypesMaps = ref(paramsTypesMap)
    let apiServiceTypesMap = ref(apiServiceTypes)
    let createTypeMaps = ref(createTypeMap)
    const form = ref({});
    const lform = reactive({
      approvalId: '',
      apiId: '',
      appId: '',
      approvalStatus: '',
      rejectReasons: ''
    })

    const copyUrl = ()=>{
      copyClipboard(form.value.mockUrl)
    }

    const getData = () => {
      request.findRouteApiDetails({routeApi,operateType: "7",}).then((res) => {
            let result = res.data.data;
            if (result) {
            form.value = result
            tableData.list = result.requestParamters?JSON.parse(result.requestParamters) : [];
            // tableData.list =  tableData.list.filter(item=>{
            //   return item.parameterType !== 'authenticationParameters'
            // })
            
            form.value.fieldMappingList =  result.responseParamters?JSON.parse(result.responseParamters) : []
            getAppNameByAppId(result.appId);
            getBusinessCateOptions(form.value.oneBusinessCateId,form.value.twoBusinessCateId)
            if(result.apiType === 'route_api'){
              getProjectList()
            }
            getApiAuthList()
          }
        });
    };

    const getApiAuthList = ()=>{
        request
          .pageListAuth({
            currentPage: 1,
            pageSize: 1000,
          })
          .then((res) => {
            if (res?.data?.code === 200) {
               authenticationParameterList.value = res.data.data.result
            }
          });
      }

    const getAppNameByAppId=(appId)=>{
      request.selectAppById({appId}).then((res) => {
        if(res.data.code==200){
          if(res.data.data&&res.data.data.result){
            form.value.appName=res.data.data.result[0].appName
          }
        }
       
      });
    }

    const getBusinessCateOptions = (oneBusinessCateId,twoBusinessCateId)=>{
      request.selectBusinessCatePage({
          currentPage: 1,
          pageSize: 10000
        }).then((res) => {
        if (res?.data?.code === 200) {
            res.data.data.forEach(item=>{
              if(item.businessCateId=== oneBusinessCateId){
                oneBusinessCateName.value = item.businessCateName
                item.child.forEach(ele=>{
                  if(ele.businessCateId === twoBusinessCateId){
                    twoBusinessCateName.value = ele.businessCateName
                  }
                })
              }
            })
        }
      });
    }

    const getProjectList = async () => {
      let res = await request.pageRegisterProject({
        currentPage:1,
        pageSize:10000,
        appId:localStorage.getItem('appId')
      }); 
      if (res?.data?.code === 200) {
        form.value.registryCenterId = res.data.data.result.filter(item=>{
            return item.registerProjectId === form.value.registryCenterId
        })[0].projectCnname
      } 
    };

    const ruleFormRef = ref(null);
    const notNull = reactive({
      approvalStatus: [{ required: true, message: '请选择审批意见', trigger: 'blur' }]
    });

    const submitApproval = async (formEl) => { //保存
      await formEl.validate(async (valid) => { //
        if (valid) {
          let res
            const params = {
                id,
                appId: localStorage.getItem('appId')
            }

            if (lform.approvalStatus === '2') {
                res =  await request.issuePass(params)
            } else if (lform.approvalStatus === '3') {
               params.rejectReasons = lform.rejectReasons || ''
               res =  await request.issueRefuse(params)
                
            }

            let respCode = res.data.code;
            if (respCode == '200') {
              router.back(-1);
              const msg = lform.approvalStatus === '2' ? '审批成功' : '驳回成功'
              ElMessage.success(msg);
            } else {
              let msg = lform.approvalStatus === '2' ? '审批失败' : '驳回失败'
              if (res?.data?.msg) {
                msg = res?.data?.msg
              }
              ElMessage.error(msg);
            }
        }
      })
    }

     const getOverallFunction = ()=>{
      request.getOverallFunction({
          currentPage: 1,
          pageSize: 100,
          
        }).then((res) => {
        if (res?.data?.code === 200) {
          functionList.value = res.data.data?.result || []
        } else {
          ElMessage.error('失败')
        }
      });
    }

   

    onMounted(()=>{
      getData()
      getOverallFunction()
      
    })
    return {
      copyUrl,
      authenticationParameterList,
      getOverallFunction,
      functionList,
      submitApproval,
      ruleFormRef,
      notNull,
      fromPage,
      form,
      lform,
      RequestMaps,
      resultDataBuildMaps,
      paramsTypesMaps,
      tableData,
      getData,
      apiServiceTypesMap,
      createTypeMaps,
      getAppNameByAppId,
      getBusinessCateOptions,
      twoBusinessCateName,
      oneBusinessCateName,
      getProjectList,
      projectList,
    };
  },
  name: "ApiManageRegisterView",
};
</script>
  
<style lang="less" scoped>
.api-views {
  margin: 20px;
  border-radius: 8px;
  .footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 48px);
    padding: 16px 24px;
    left: 0;
    box-shadow: 0 -4px 16px 0 rgba(0,67,161,0.10);
    z-index: 10;
    display: flex;
    justify-content: center;
  }
  .form-box{
    text-align: left;
    margin-bottom: 20px;
    .header-title{
      text-align: left;
      display: flex;
      justify-content: space-between;
    }
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>