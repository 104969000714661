<template>
  <div  style="margin:28px;">
    运营管理编辑页
  </div>
</template>

<script>

export default {
  name: 'OperateManagementEdit',
  setup () {
    return {
    }
  },
}
</script>

<style lang="less" scoped>
</style>