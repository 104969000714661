<template>
    <div id="wrap" class="relation-config">
        <div id="container"
            :ref="(el) => el && !state.continer ? state.continer = el : ''"
            :onDrop="drop"
            :style="[isDraging ? 'background-color: rgb(210, 241, 247);' : 'background-color: rgb(237, 241, 247);']">
        </div>

        <el-dialog v-if="state.show.relationMatchVisible" v-model="state.show.relationMatchVisible" title="关系匹配" width="55%">
            <template #header>
                <div>关系匹配</div>
            </template>
            <inLineRelationMath
                :ref="el => (el && !state.inLineConfigRef ? state.inLineConfigRef = el : '')"
                :state="state"
                :inlineRelations="state.inlineRelations"
                :inlineRelList="state.inlineRelList"></inLineRelationMath>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="inlineConfSubmit">确认</el-button>
                    <el-button @click="state.show.relationMatchVisible = false">取消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { reactive, ref, nextTick } from 'vue'
import { useG6 } from '@/G6'
import { useStore } from 'vuex'
import { ElMessage, ElLoading } from 'element-plus'
import dragitemIcon from '@/assets/image/tableItem.png'
import inLineRelationMath from './inLineRelationMath.vue'
export default {
    components: {
        inLineRelationMath
    },
    props: {
        data: Array
    },
    setup(props) {
        const state = reactive({
            continer: null,  // 拖拽区域ref
            relation: [],
            inLineConfigRef: null, // 内联关系匹配组件ref
            show: {
                relationMatchVisible: false // 内联关系匹配弹框是否显示
            },
            inlineRelations: { // 内联关系表
                source: '',
                target: '',
            },
            inlineRelList: props.data || [], // 内联关系配置数据集合
        })

        const isDraging = ref(false)

        // 不能放在reactive里面，放了，会导致连线出来不能取消，proxy导致的bug
        const store = useStore()
        const { renderCanvas, graphData } = useG6(state)

        const drop = async (evt) => {
            // 忽略拖拽进的重复的节点，禁止拖入重名的表名
            const allNodes = graphData.nodes
            const arr = allNodes.filter(el => el.id === store.state.dragItem)
            if (arr.length > 0) {
                ElMessage('请勿拖入重复的数据集！')
                return
            }

            // 防止接口未完成前继续拖动
            const loadingInstance = ElLoading.service({
                target: '#container',
                background: 'rgb(237, 241, 247)',
            })

            const dragItem = store.state.dragItem
            graphData.nodes.push({
                id: dragItem,
                label: dragItem,
                x: evt.offsetX,
                y: evt.offsetY,
                logoIcon: {
                    img: dragitemIcon
                },
            })

            renderCanvas()

            nextTick(() => {
                loadingInstance.close()
            })
        }

        const inlineConfSubmit = () => {
            const join = state.inLineConfigRef?.relationValue
            const columnList = state.inLineConfigRef?.inLineRelationData.tableData || []
            const filterEmp = columnList.filter(el => (!el.fromColumn || !el.express || !el.toColumn) && !el.groupList) // 表格行不完整的数据
            if (!join) {
                ElMessage.error('请选择关联关系！')
                return false
            } else if (filterEmp.length > 0 ) {
                ElMessage.error('字段与表达式必填！')
                return false
            }
            const { source, target } = state.inlineRelations
            const obj = {
                fromTable: source,
                toTable: target,
                fromTableAlias: source,
                toTableAlias: target,
                join,
                columnList
            }
            state.inlineRelList = state.inlineRelList.filter(el => !(el.fromTable === source && el.toTable === target))
            state.inlineRelList.push(obj)
            state.show.relationMatchVisible = false
        }

        return {
            state, isDraging, drop, inlineConfSubmit
        }
    }
}
</script>

<style lang="less" scoped>
    #container {
        border: 1px solid #dcdfe6;
        height: 450px;
    }
    #container /deep/ .g6-component-contextmenu {
        padding: 0;
        .contextMenu {
            border-radius: 4px;
            background-color: #fff;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            z-index: 1;
            width: 100px;
            margin: 0 0 0 12px;
            // margin-left: 12px;
            padding: 0;
            li {
                cursor: pointer;
                font-size: 12px;
                height: 28px;
                line-height: 28px;
                border-bottom: 1px solid #e4e7ed;
                text-align: left;
                list-style: none;
                &:hover {
                background-color: #f5f7fa;
                }
            }
        }
    }
</style>
