<template>
  <div class="api-views">
    <div class="box-card-radius-eight white-bg form-box">
      <div class="header-title">
      <strong>基础信息</strong>
      </div>
      <el-divider />
      <el-form :model="form" align-right>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item
              label="函数名称："
              prop="functionName"
              label-position="right"
            >
              <span class="val"> {{ form.functionName }} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="函数分类："
              label-position="right"
            >
              <span class="val"> {{ functionCateMaps[form.functionCate]}} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="函数类别：">
              <span class="val">
                {{form.functionFamily}}
              </span>
            </el-form-item>
          </el-col>
         
        <el-col :span="8">
            <el-form-item label="中文简称：" >
              <span class="ellipsis val"> {{ form.functionCnname }} </span>
            </el-form-item>
          </el-col>
          
           <el-col :span="8">
            <el-form-item label="类路径：" >
              <span class="val"> {{ form.functionClasspath }} </span>
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="状态：" >
              <span class="val"> {{ functionStatusMaps[form.functionStatus] }} </span>
            </el-form-item>
          </el-col>
          </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="描述：" align="left">
              <span> {{ form.functionDesc }} </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="备注：" align="left">
              <span> {{ form.tag }} </span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

 <div class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
   
   <div class="form-box">
     <div class="header-title"><strong>函数入参</strong></div>
     <el-divider />
      <el-table :data="inputParams" :row-key="getRowKey" height="300">
          <el-table-column
            label="参数名称"
            prop="paramterName"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
          label="数据类型"
          prop="paramterDatatype"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        
        <el-table-column
          label="是否必填"
          prop="requiredFlag"
          :show-overflow-tooltip="true"
        >
          <template #default="scope" >
             {{scope.row.requiredFlag === 'Y'?'是':'否'}}
          </template>
        </el-table-column>
      
       <el-table-column
            label="参数描述"
            prop="paramterDesc"
            :show-overflow-tooltip="true"
          />
      </el-table>
    </div>
 </div>


<div class="box-card-radius-eight white-bg" style="margin-bottom:20px;">
   <div class="form-box">
     <div class="header-title"><strong>函数出参</strong></div>
     <el-divider />
      <el-table :data="outputParams" height="300">
        <el-table-column
            prop="no"
            label="序号"
            width="60"
            align="left"
            type="index"
        />
        <el-table-column
            label="数据类型"
            prop="paramterDatatype"
            :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
            label="参数描述"
            prop="paramterDesc"
            :show-overflow-tooltip="true"
        />
      </el-table>
   </div>
</div>

    <div align="right" class="footer white-bg">
      <el-button type="primary" @click="this.$router.back()">返回</el-button>
    </div>
  </div>
</template>
  
<script>
import { useRoute } from "vue-router";
import {request} from '@/api'
import {onMounted,ref} from "vue";
import {functionCateMap,functionStatusMap} from '@/utils/constant'


export default {
  setup() {
    const route = useRoute();
    const functionId = route.query.functionId;
    let outputParams = ref([]);
    let inputParams = ref([]);
    let functionCateMaps = ref(functionCateMap)
    let functionStatusMaps = ref(functionStatusMap)

    let form = ref({
      functionName:null,
      tag:null,
      functionDesc:null,
      functionStatus:null,
      functionClasspath:null,
      functionCnname:null,
      functionFamily:null,
      functionCate:null
    });
    

    const getData = () => {
          request.getOverallFunctionDetail({functionId}).then((res) => {
          let data = res.data;
          if (data.code === 200) {
          form.value = {...data.data.overallFunctionEntity}
          inputParams.value = data.data.inputParams || []
          outputParams.value = data.data.outputParams || []

          }
      })
    }

  

    onMounted(()=>{
      getData()
    })
    return {
      form,
      outputParams,
      inputParams,
      getData,
      functionCateMaps,
      functionStatusMaps
    };
  },
  name: "ApiDocView",
  methods: {
    getRowKey(row) {
      return row.roleId;
    },
  },
};
</script>
  
<style lang="less" scoped>
.api-views {
  margin: 20px;
  border-radius: 8px;
  .footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 48px);
    padding: 16px 24px;
    left: 0;
    box-shadow: 0 -4px 16px 0 rgba(0,67,161,0.10);
    z-index: 10;
  }
  .form-box{
    text-align: left;
    margin-bottom: 20px;
    .header-title{
      text-align: left;
      display: flex;
      justify-content: space-between;
    }
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>