

export const operations =  {
    ZOOM_IN :'zoomIn',
    ZOOM_OUT :'zoomOut',
    RESET_ZOOM :'resetZoom',
  
    UNDO :'undo',
    REDO :'redo',
  
    SNAPSHOT :'snapshot',
    VIEW_DATA :'viewData',
    SAVE_DATA :'saveData',
    DRAFT_DATA :'draftData',
    PARAMS_DATA :'paramsSet'
  }