<template>
  <div></div>
</template>
<script>
// @ is an alias to /src
import { ElMessageBox } from 'element-plus'
import {request} from '@/api'
import router from '@/router'
import {  onMounted } from "vue";
//import ApiMarketList from "@/views/ApiMarketMain/ApiMarketList.vue";
export default {
  name: `start`,
  setup() {
    const getUserInfo = async () => {
      //potal页面
      let ticket = router?.currentRoute?.value?.query?.ticket
      //第三方门户页面
      let weportTicket = router?.currentRoute?.value?.query?.weportTicket
      let resp 
      if(weportTicket){
        resp = await request.setWeportalToken({code: router.currentRoute.value.query.weportTicket})
      } else if(ticket){
        resp = await request.ssoStToken({ticket: router.currentRoute.value.query.ticket})
      }
      if (resp) {
          if (resp.data.data.loginStatus === '1') {
            localStorage.setItem('token', resp.data.data.token)
            localStorage.setItem('userId', resp.data.data.userId)
            localStorage.setItem('account', resp.data.data.account)
            localStorage.setItem('userName', resp.data.data.userName)
            localStorage.setItem('tenantId', resp.data.data.tenantId)
            localStorage.setItem('tenantName', resp.data.data.tenantName)
            localStorage.setItem(
              'phoneNumber',
              resp.data.data.phoneNumber || ''
            )
            localStorage.setItem(
              'currentRoleName',
              resp.data.data.currentRoleName
            )
            localStorage.setItem('currentRoleId', resp.data.data.currentRoleId)
            localStorage.setItem('appId', resp.data.data.appId)
            localStorage.setItem('appName', resp.data.data.appName)
            localStorage.setItem('jumpToLoginCnt', 0)
            localStorage.setItem(
              'AuthCodeList',
              resp.data.data.currentAuthCodeList
            )
            const loginCount = resp.data.data.loginCount
            localStorage.setItem('loginCount', loginCount)
            if (loginCount === 1) {
              router.push({
                path: '/home/UserCenter/AccountSettings',
                query: {
                  activeDrawer: 'true',
                },
              })
            } else {
              router.push({
                path: '/home/ApiMarket/ApiMarketList',
                query: {
                  login: 'login',
                },
              })
            }
          } else {
            // 如果账号密码错误的话就要进行提示，并且重新回到登录页面
            ElMessageBox.alert(resp.data.data.loginMsg)
            // router.push('/');
          }
      }
    }

    onMounted(()=>{
      getUserInfo()
    }) 
    return {
      getUserInfo,
    }
  },
  beforeCreate() {
    //this.getUserInfo()
  },
  
}
</script>
<style lang="scss">
.el-header,
.el-footer {
  background-color: #f4f6fb;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  text-align: center;
  line-height: 200px;
  background-color: #f4f6fb;
}

.el-main {
  // background-color: #e9eef3;
  color: #333;
  text-align: center;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

/* LOGO位移 */
.div-logo {
  position: absolute;
  left: 30px;
  top: 25px;
  z-index: 100;
  width: 100px;
  height: 80px;
}

.flex-grow1 {
  flex-grow: 1;
}

.img-icon {
  display: block;
  width: 200px;
  height: 55px;
}

.el-menu-class {
  height: 100%;
  width: 100%;
}

.menu-title {
  font-size: 18px !important;
  font-weight: 800;
  letter-spacing: 1px;
}

.menu-font {
  font-size: 16px !important;
  letter-spacing: 1px;
  .el-sub-menu__title{
          color:#fff!important;
          .el-sub-menu__icon-arrow{
            color:#fff!important;
          }
        }
}

// .el-menu-item {
//   font-size: 20px !important;
// }

.el-menu-item.is-active {
  color: #fff !important;
}

.el-header {
  
  .el-menu--horizontal > .el-menu-item.is-active {
    background: #1f7cff;
    color: #fff !important;
  }
  .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    background: #1f7cff;
  }

  .el-sub-menu.is-active {
    .el-sub-menu__title {
      background: #1f7cff !important;
      color: #fff !important;
    }
  }
}
.home-sub-menu {
  .el-menu {
    background: #fff !important;
    .menu-switch {
      background: #fff !important;
      color: #091328 !important;
    }
    .is-active {
      font-weight: 600;
      color: #205cf5 !important;
      background: #e5effc !important;
    }
    .menu-switch:hover {
      color: #205cf5 !important;
      background: #e5effc !important;
      // background: rgb(40 81 121) !important;
    }
  }
}
</style>
<style lang="less">
//下拉框标题文本label
:deep.el-form-item__label {
  color: #336699;
  font-weight: 100;
}
//选择框
:deep.el-input__wrapper {
  background-color: #336699 !important;
  width: 130px;
}
//下拉框文字
:deep.el-input__inner {
  color: #091328;
}
//下拉框背景色
.el-popper.is-light.selectStyle {
  background-color: #fff !important;
  border: 1px solid #fff !important;
}
//下拉框的链接小方块
.selectStyle.el-popper[data-popper-placement^='bottom']
  .el-popper__arrow::before {
  background: #fff !important;
  border: 1px solid #fff !important;
}
.selectStyle.el-popper[data-popper-placement^='top'] .el-popper__arrow::before {
  background: #336699 !important;
  border: 1px solid #336699 !important;
}
//鼠标移动上去的选中色
.selectStyle {
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    color: #205cf5 !important;
    background: #e5effc !important;
    // background: rgb(40 81 121) !important;
  }
  //下拉框的文本颜色
  .el-select-dropdown__item {
    color: #091328 !important;
  }
  //选中之后的颜色
  .el-select-dropdown__item.selected {
    color: #205cf5 !important;
    background: #e5effc !important;
  }
}
.badge_item .el-badge__content.is-fixed {
  transform: translateY(50%) translateX(100%);
}
</style>
