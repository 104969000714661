<template>
  <div
    class="common-layout"
    style="
      height: 100%;
      width: 100%;
      padding: 28px 0;
      box-sizing: border-box;
      background: linear-gradient(180deg, #f5f6fd);
    "
  >
    <el-container
      style="height: 100%; width: 100%; flex-direction: column; display: flex"
    >
      <div style="display: flex">
        
        <el-main
          style="
            border-radius: 8px;
            overflow: hidden;
            padding: 0px;
            height: 100%;
            width: calc(100% - 224px);
            margin: 0 28px 20px 20px;
          "
        >
        <ApiManageList tabIndex="4" :mine="true"/>
        </el-main>
      </div>
    </el-container>
  </div>
</template>

<script lang="js">
import ApiManageList from "@/views/ApiManageMain/ApiManageList.vue"

export default {
  name: "ApiManage",
  components:{
    ApiManageList
  },
  setup() {
  return{
  }
  },
    
}
</script>


<style scoped>
</style>