<template>
    <div class="common-form-search margin-botton-24">
        <div class="common-input margin-botton-24">
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-input v-model="queryKey" :placeholder="PlaceHolder" clearable class="input-transparent">
                        <template #suffix>
                            <el-icon class="icon-search" @click="search"><Search /></el-icon>
                        </template>
                    </el-input>
                </el-col>
                <el-col :span="4" align="left">
                    <el-link type="primary" :underline="false" class="more-icon" @click="expandFlag = !expandFlag">
                        高级搜索&nbsp;
                        <el-icon v-if="expandFlag"><ArrowUpBold /></el-icon>
                        <el-icon v-else><ArrowDownBold /></el-icon>
                    </el-link>
                </el-col>
                <el-col :span="12" align="right">
                    <slot name="cardList" />
                </el-col>
            </el-row>
        </div>
        <div class="more-fields" v-if="expandFlag">
            <slot />
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
export default {
    props: {
        PlaceHolder: {
            type: String,
            default: () => { return '请输入API名称、URL' }
        },
        value: { // 默认查询关键字
            type: String,
            default: () => { return ''}
        }
    },
    setup(prop, { emit }) {
        const queryKey = ref('') // 查询关键字API名称、URL
        const expandFlag = ref(false) // 更多查询条件是否展开 false收起，true展开

        // 搜索
        const search = () => {
            emit('handleSearch', queryKey.value)
        }

        onMounted(() => {
            queryKey.value = prop.value
        })

        return {
            queryKey,
            expandFlag,
            search
        }
    }
}
</script>
<style lang="less" scoped>
    .common-form-search {
        .input-transparent {
            border-radius: 4px;
            .icon-search {
                cursor: pointer;
                color: #262c38;
                font-size: 16px;
            }
            & :deep .el-input__wrapper {
                background: none;
            }
        }
        .more-icon {
            vertical-align: sub;
            color: #478bed;
            font-weight: bolder;
            .el-icon {
                font-size: 12px;
            }
        }
    }
</style>