<template>
  <div  style="margin:28px;">
   <div  class="box-card-radius-eight white-bg">
       <el-form ref="ruleFormRef" :model="form" label-width="120" >
            <el-row :gutter="24">
              <el-col :span="8" align="left">
                <el-form-item label="指标中文名称:">
                  <el-input v-model="form.index_paramter_cnname" disabled/>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="指标英文名称:">
                  <el-input v-model="form.index_paramter_enname" disabled/>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="指标类型:">
                  <el-input v-model="form.indexTypeName" disabled/>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="指标看护责任人:">
                  <el-select
                    v-model="form.indexOwner"
                    @change="changeIndexOwner"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入搜索关键字"
                    :remote-method="getindexOwnerList"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in indexOwnerList"
                      :key="item.userId"
                      :label="item.userName + ' ' + item.account"
                      :value="item.userId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item :label="indexParamLabel +':'">
                  <el-input v-model="form.indexParamValue" />
                </el-form-item>
              </el-col>
               <el-col :span="24" align="left">
                <el-form-item label="备注:">
                  <el-input v-model="form.tag" :rows="4" type="textarea"  maxlength="1000" show-word-limit />
                </el-form-item>
              </el-col>
            </el-row>
       </el-form>
   </div>
   <div class="footer" align="right">
      <div class="btn">
        <el-button  @click="cancel">取消</el-button>
       
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted} from 'vue';
import { request } from "@/api";
import {useRoute,useRouter} from 'vue-router'
import {ElMessage } from "element-plus";

export default {
  name: 'OperateManagementEdit',
  setup () {
    let route = useRoute()
    let router = useRouter()
    let indexData = ref(null)
    let form = ref({
      indexParamValue:null,
      indexOwner:null,
      indexOwnerNumber:null,
      indexType:null,
      indexTypeName:null,
      copyIndexOwner:null,
      index_paramter_cnname:null,
      index_paramter_enname:null,
      tag:null
    })

    let indexParamLabel = ref(null)

    let indexOwnerList = ref([])
    let indexTypeList = ref([])

    const changeIndexOwner = (val) => {
      let user = indexOwnerList.value.filter((item) => {
        return item.userId === val;
      });
      if (user.length > 0) {
        form.value.indexOwner = user[0].userName;
        form.value.indexOwnerNumber = user[0].account;
      }
      form.value.copyIndexOwner = val
    };

    const getindexOwnerList = (query)=>{
      if (query) {
       request.selectUserByParams({
            likeData:query,
            appId:localStorage.getItem('appId'),
            currentPage: 1,
            pageSize: 10000
          }).then((res) => {
          if (res?.data?.code === 200) {
            indexOwnerList.value = res.data.data.result
          }
        });
      } else {
        indexOwnerList.value = []
      }
    }

    const save = ()=>{
      let indexId = route.query.indexId || null
      let indexDataParam = {
        ...indexData.value,
        value:form.value.indexParamValue
      }
      request.addOrUpdateIndicatordefinition({
              indexId :indexId, 
              indexType : form.value.indexType,
              indexOwner : form.value.copyIndexOwner,
              indexData : JSON.stringify(indexDataParam),
            }).then((res) => {
            if (res?.data?.code === 200) {
             ElMessage.success('保存成功')
             cancel()
            }
      });
    }

    const cancel = ()=>{
      router.back(-1)
    }
    const getIndicatordefinitionDetails = ()=>{
      if(route?.query?.indexId){
        let indexId = route.query.indexId
        request.getIndicatordefinitionDetails({
              indexId,
            }).then((res) => {
            if (res?.data?.code === 200) {
              let result = res.data.data
              indexData.value = JSON.parse(result.indexData)
              indexParamLabel.value = indexData.value.index_paramter_enname
              form.value.index_paramter_cnname = indexData.value.index_paramter_cnname
              form.value.index_paramter_enname = indexData.value.index_paramter_enname
              form.value.tag = indexData.value.tag

              form.value.indexParamValue = indexData.value.value
              form.value.copyIndexOwner = result.indexOwner
              form.value.indexOwner = result.indexOwnerName + ' ' + result.indexOwnerNumber
              form.value.indexType = result.indexType
              form.value.indexTypeName = result.indexTypeName

            }
          });
       }
    }

    onMounted(()=>{
      getIndicatordefinitionDetails()
    })
    return {
      form,
      changeIndexOwner,
      getindexOwnerList,
      indexOwnerList,
      indexTypeList,
      getIndicatordefinitionDetails,
      indexParamLabel,
      save,
      cancel,
      indexData
    }
  },
}
</script>

<style lang="less" scoped>
.footer {
    margin-top: 24px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 64px;
    line-height: 64px;
    background: #fff;
    z-index: 10;
    .btn {
      padding-right: 24px;
    }
  }
</style>