<template>
  <div class="main">
    <div class="data-source-left-bar" style="height: auto">
      <el-scrollbar style="width: 100%">
        <el-input
          v-model="filterText"
          placeholder="请输入"
          suffix-icon="Search"
          style="width: 192px; height: 32px; margin: 0 auto 16px"
        />
        <el-tree
          ref="treeRef"
          class="api-filter-tree"
          :data="dataSourceTreeData"
          :props="{
            children: 'children',
            label: 'categoryName',
          }"
          default-expand-all
          :filter-node-method="filterNode"
          :indent="20"
          :highlight-current="true"
        >
          <template #default="{ node }">
            <span class="custom-tree-node" @click="clickNode(node)">
              <span :title="node.label" class="custom-tree-label">
                <span class="tree-file-icon"></span>
                  <div class="custom-tree-label-div">{{ node.label }}</div>
                （{{node.data.apiSize}}）
              </span>
            </span>
          </template>
        </el-tree>
      </el-scrollbar>
    </div>
    <div class="data-box">
      <div class="search-box">
        <el-input
          class="input-transparent"
          v-model="apiName"
          placeholder="请输入API名称或请求路径"
          clearable
          style="margin-bottom: 24px; width: 600px"
          @keyup.enter="reloadTable"
        >
          <template #suffix>
            <el-icon class="icon-search" @click="reloadTable"
              ><Search
            /></el-icon>
          </template>
        </el-input>
        <div class="toolbar">
          <el-button
            type="primary"
            icon="plus"
            style="color: #fff"
            @click="handleAdd"
            >{{ApiFlowChart?'新增编排':'新增API设计'}}</el-button
          >
          <div style="display: flex">
            <el-tooltip
              class="box-item"
              effect="dark"
              content="卡片"
              placement="top"
              v-if="!showCard"
            >
              <div class="card" @click="showCard = !showCard">
                <img class="card-img" src="@/assets/image/apiMarket/card.png" />
                <img
                  class="card-active-img"
                  src="@/assets/image/apiMarket/card-active.png"
                />
              </div>
            </el-tooltip>
            <el-tooltip
              class="box-item"
              effect="dark"
              content="列表"
              placement="top"
              v-if="showCard"
            >
              <div class="list" @click="showCard = !showCard">
                <img class="list-img" src="@/assets/image/apiMarket/list.png" />
                <img
                  class="list-active-img"
                  src="@/assets/image/apiMarket/list-active.png"
                />
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>

      <el-row :gutter="20" v-if="showCard" class="cardType-row">
        <el-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          :xl="6"
          class="cardType-row--col"
          v-for="item in tableData"
          :key="item.apiId"
        >
          <div class="card-box">
            <div class="card-box-top">
              <img
                src="@/assets/image/home/market.png"
                width="48"
                height="48"
              />
              <div>
                <p class="ellipsis apiname" :title="item.apiName">
                  {{ item.apiName }}
                </p>
                <div style="display: flex">
                  <p
                    class="ellipsis"
                    style="margin: 0; padding-top: 6px; padding-right: 12px"
                  >
                    <span class="card-time">
                      创建时间：
                      {{ item.creationTime }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card-box-mid">
              <p>
                <span class="card-label-mid">请求路径：</span>
                <span class="card-content-mid" :title="item.apiUrl">{{
                  item.apiUrl
                }}</span>
              </p>
              <p>
                <span class="card-label-mid">请求方式：</span>
                <span class="card-content-mid">{{
                  RequestMaps[item.apiRequestM]
                }}</span>
              </p>
              <p>
                <span class="card-label-mid">服务类型：</span>
                <span class="card-content-mid">{{
                  apiServiceTypesMap[item.apiServicetype]  || '-'
                }}</span>
              </p>
              <p v-if="!ApiFlowChart">
                <span class="card-label-mid">开发人：</span>
                <span
                  class="card-content-mid"
                  :title="item.developerUserName"
                  >{{ item.developerUserName + ' ' + item.developerUserNumber }}</span
                >
              </p>
              <p v-if="!ApiFlowChart">
                <span class="card-label-mid">设计人：</span>
                <span class="card-content-mid" :title="item.designUserName">{{
                  item.designUserName + ' ' + item.designUserNumber
                }}</span>
              </p>
            </div>
            <div class="card-box-bottom">
             <div class="card-box-bottom-status">
              <span style="display: inline-block; width: 20px"></span>
              <img
                src="@/assets/image/apiMarket/draft.png"
                alt=""
                v-if="item.status == 'draft'"
                style="margin-right: 5px"
              />
              <img
                src="@/assets/image/apiMarket/pending.png"
                alt=""
                v-if="item.status == 'released' || item.status == 'approval' || item.status == 'pending_developed' || item.status == 'design'"
                style="margin-right: 5px"
              />
              <img
                src="@/assets/image/status/inApproval.png"
                alt=""
                v-if="item.status == 'developed'"
                style="margin-right: 5px"
              />
              
              <img
                src="@/assets/image/apiMarket/success.png"
                alt=""
                v-if="item.status == 'online'"
                style="margin-right: 5px"
              />
              <img
                src="@/assets/image/apiMarket/offline.png"
                alt=""
                v-if="item.status == 'offline'"
                style="margin-right: 5px"
              />
              {{
                apiStatusMap[item.status]
              }}
            </div>
            <div style="display:flex;align-items:center;">
                  <el-tooltip 
                  content="查看"
                  >
                  <el-button 
                    icon="View" 
                    @click="viewDoc(item)"
                    link>
                  </el-button>
              </el-tooltip>
              <el-tooltip 
               content="编辑"
               v-if="item.status == 'design' || item.status == 'draft' || item.status == 'offline' || item.status == 'released' || item.status == 'online' || item.status == 'redesign'"  
              >
                  <el-button 
                    icon="Edit" 
                    @click="handleEdit(item)"
                    link>
                  </el-button>
              </el-tooltip>
              <el-tooltip 
               content="测试"
               v-if="item.status == 'online' || item.status == 'offline' || item.status == 'released'"
              >
                  <el-button 
                    icon="CaretRight" 
                    @click="apiInvoke(item)"
                    link>
                  </el-button>
              </el-tooltip>
           
              <el-dropdown class="card-box-bottom-op" :hide-on-click="false">
                <el-icon><MoreFilled /></el-icon>
                <template #dropdown>
                  <el-dropdown-menu>
                   <el-dropdown-item
                        v-if="(item.status == 'offline' || item.status == 'released') && ApiFlowChart"
                        icon="upload"
                        @click="onlineRow(item)"
                        >上线</el-dropdown-item>

                      <el-dropdown-item
                        v-if="item.status == 'online' && ApiFlowChart"
                        icon="download"
                        @click="offlineRow(item)"
                        >下线</el-dropdown-item>
                     <el-dropdown-item
                      v-if="(item.status !== 'offline' && item.status !== 'online') && !ApiFlowChart"
                      icon="RefreshRight"
                      @click="updateApiStatus(item,'2')"
                      >重新设计</el-dropdown-item
                    >
                    <!-- <el-dropdown-item 
                      v-if="item.status == 'design' || item.status == 'draft' || item.status == 'offline' || item.status == 'released' || item.status == 'online' || item.status == 'redesign'"
                      icon="Edit"
                      @click="handleEdit(item)"
                        >编辑</el-dropdown-item
                      > -->
                    <el-dropdown-item
                      v-if="(item.status === 'design' || item.status == 'redesign') && !ApiFlowChart"
                      icon="DocumentChecked"
                      @click="updateApiStatus(item,'1')"
                      >完成设计</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="Delete"
                      style="color: #f56c6c"
                      @click="handleDelete(item)"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
               </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div
        class="box-card-radius-eight white-bg"
        style="overflow: hidden"
        v-else
      >
        <el-table :data="tableData" row-key="apiId" border>
          <el-table-column
            prop="apiName"
            label="API名称"
            width="200"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="apiUrl"
            label="请求路径"
            width="200"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column prop="apiRequestM" label="请求方式" width="150">
            <template #default="scope">
              <span>{{ scope.row.apiRequestM == 1 ? "GET" : "POST" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="apiVersion" label="版本"> </el-table-column>
          <el-table-column label="服务类型" width="150">
            <template #default="scope">
              <span>{{
                apiServiceTypesMap[scope.row.apiServicetype]  || '-'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" width="180">
            <template #default="scope">
              <span >{{apiStatusMap[scope.row.status] || '-'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="developerUserName" label="开发人" width="200" v-if="!ApiFlowChart">
            <template #default="scope">
              <span >{{scope.row.developerUserName + ' ' + scope.row.developerUserNumber}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="designUserName" label="设计人" width="200" v-if="!ApiFlowChart">
            <template #default="scope">
              <span >{{scope.row.designUserName + ' ' + scope.row.designUserNumber}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="creationTime" label="创建时间" width="180">
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            width="200"
          >
            <template #default="scope">
              <el-tooltip content="上线"  v-if="(scope.row.status == 'offline' || scope.row.status == 'released') && ApiFlowChart">
                 <el-button size="medium"  type="text" icon="upload"  @click="onlineRow(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip content="下线"  v-if="scope.row.status == 'online' && ApiFlowChart">
                 <el-button  size="medium"  type="text" icon="download"  @click="offlineRow(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip content="重新设计"  v-if="(scope.row.status !== 'offline' && scope.row.status !== 'online') && !ApiFlowChart">
                <el-button
                  size="medium"
                  icon="RefreshRight"
                  type="text"
                  @click="updateApiStatus(scope.row,'2')"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="编辑"  v-if="scope.row.status == 'design' || scope.row.status == 'draft' || scope.row.status == 'offline' || scope.row.status == 'released' || scope.row.status == 'online' || scope.row.status == 'redesign'">
                <el-button
                  size="medium"
                  icon="Edit"
                  type="text"
                  @click="handleEdit(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="完成设计" v-if="(scope.row.status === 'design' || scope.row.status == 'redesign') && !ApiFlowChart">
                <el-button
                  size="medium"
                  icon="DocumentChecked"
                  type="text"
                  @click="updateApiStatus(scope.row,'1')"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="查看" >
                <el-button
                  size="medium"
                  icon="View"
                  type="text"
                  @click="viewDoc(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="删除" >
                <el-button
                  @click="handleDelete(scope.row)"
                  size="medium"
                  icon="delete"
                  type="text"
                  style="color: #f56c6c"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页组件-->
      <div style="padding: 10px 0; float: right">
        <el-pagination
          background
          small
          :current-page="pageVO.currentPage"
          :page-size="pageVO.pageSize"
          :page-sizes="[2, 5, 10, 20]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageVO.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { request } from "@/api";
import { ElMessage, ElMessageBox } from "element-plus";
import { WarningFilled } from "@element-plus/icons-vue";
import {apiStatus,apiServiceTypes,RequestMap} from '@/utils/constant'
export default {
  name: "ApiDesign",
  props:{
    mine:{
      type:Boolean,
      default:false
    },
    //服务编排
    ApiFlowChart:{
      type:Boolean,
      default:false
    }
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    let isMyDesign = ref(route.fullPath === "/home/mine/myDesign");
    let apiName = ref("");
    let categoryId = ref(null)
    let tableData = ref();
    let filterText = ref("");
    let showCard = ref(true);
    let pageVO = ref({
      currentPage: 1,
      pageSize: 10,
      total: 0,
    });
    let dataSourceTreeData = ref([]);
    let apiStatusMap = ref(apiStatus)
    let apiServiceTypesMap = ref(apiServiceTypes);
    let RequestMaps = ref(RequestMap)
    const filterNode = (value, data) => {
      if (!value) return true;
      return data.categoryName.includes(value);
    };

    const getApiCategory = () => {
      request
        .newListTree({
          appId: localStorage.getItem("appId"),
          categoryName: filterText.value,
          type:props.ApiFlowChart?5:1,
          userId:props.mine?localStorage.getItem("userId"):null
        })
        .then((res) => {
          if (res.data.code == 200) {
            dataSourceTreeData.value = [res.data.data];
          }
        });
    };
    const clickNode = (node) => {
      categoryId.value = node.data.categoryId
      reloadTable()
      // /home/ApiManage/ApiDesign/detail?operation=edit&apiId=1191748735319941120
      // router.push({path:'/home/ApiManage/ApiDesign/detail',query:{id:node.data.categoryId}})
    };
    const handleSizeChange = (val) => {
      //size修改
      pageVO.value.pageSize = val;
      getServiceList();
    };
    const handleCurrentChange = (val) => {
      //num修改
      pageVO.value.currentPage = val;
      getServiceList();
    };

    const getServiceList = async () => {
      let res;
      if (isMyDesign.value) {
        res = await request.myDesignApi({
          pageVO: {
            ...pageVO.value,
          },
          apiName: apiName.value,
          categoryId:categoryId.value,
          appId:localStorage.getItem('appId')
        });
      } else if(props.ApiFlowChart){
        res = await request.getListArrange({
          currentPage: pageVO.value.currentPage,
          pageSize: pageVO.value.pageSize,
          keyWord: apiName.value,
          categoryId:categoryId.value,
          appId:localStorage.getItem('appId')
        });
      } else {
        res = await request.selectApiByPage({
          currentPage: pageVO.value.currentPage,
          pageSize: pageVO.value.pageSize,
          apiName: apiName.value,
          categoryId:categoryId.value,
          appId:localStorage.getItem('appId')
        });
      }
      if (res?.data?.code === 200) {
        tableData.value = res.data.data.result;
        pageVO.value.total = res.data.data.pageVO.total;
      }
    };

    const handleDelete = (row) => {
      ElMessageBox.confirm(`确定删除当前服务：` + row.apiName, {
        icon: WarningFilled,
        type: "warning",
      })
        .then(() => {
          if(props.ApiFlowChart){
             request
            .deleteApiArrange({
              apiId: row.apiId,
              operateType: "8",
            })
            .then((res) => {
              if (res?.data?.code === 200) {
                ElMessage.success("删除成功");
                reloadTable();
              }
            });

          }else{
             request
              .deleteApi({
                apiId: row.apiId,
                operateType: "8",
              })
              .then((res) => {
                if (res?.data?.code === 200) {
                  ElMessage.success("删除成功");
                  reloadTable();
                }
              });
          }
         
            
        })
        .catch(() => {});
    };

    const handleEdit = (row) => {
      router.push({
        path: props.mine?"/home/mine/myDesign/detail":props.ApiFlowChart?"/home/ApiFlowChart/ApiFlowChartManagementEdit":"/home/ApiManage/ApiDesign/detail",
        query: {
          operation: "edit",
          apiId: row.apiId,
        },
      });
    };

    const offlineRow = (row) => {
      //下线
      row.newStatus = 'offline'; //下线状态码
      row.operateType = "5"; //操作码
      ElMessageBox.confirm(`确定下线当前服务编排：` + row.apiName)
        .then(() => {
          request.apiArrangeUpdateStatus(row).then((res) => {
            if (res.data.code == 200) {
              ElMessage.success("操作成功");
              reloadTable();
            } else {
              ElMessage.error(res?.data?.data || "操作失败");
            }
          });
        })
        .catch(() => {});
    };

    const onlineRow = (row) => {
      //上线
      row.newStatus = 'online'; //上线状态码
      row.operateType = "4"; //操作码 
      ElMessageBox.confirm(`确定上线当前服务编排：` + row.apiName)
        .then(() => {
          request.apiArrangeUpdateStatus(row).then((res) => {
            if (res.data.code == 200) {
              ElMessage.success("操作成功");
              reloadTable()
            } else {
              ElMessage.error(res?.data?.msg || "操作失败");
            }
          });
        })
        .catch(() => {});
    };

    const updateApiStatus = (row,operationType) => {
      let str
      if(operationType ==='1'){
        str = '确定完成设计当前服务：'
      }else{
        str = '确定重新设计当前服务：'
      }
      ElMessageBox.confirm(str + row.apiName, {
        icon: WarningFilled,
        type: "warning",
      })
        .then(() => {
          request
            .updateApiStatus({
              apiId: row.apiId,
              operationType,
            })
            .then((res) => {
              if (res?.data?.code === 200) {
                ElMessage.success("成功");
                reloadTable();
              }
            });
        })
        .catch(() => {});
    };

    const handleAdd = () => {
      router.push({
        path: props.mine?"/home/mine/myDesign/detail":props.ApiFlowChart?"/home/ApiFlowChart/ApiFlowChartManagementEdit":"/home/ApiManage/ApiDesign/detail",
        query: {
          operation: "create",
        },
      });
    };

    const viewDoc = (row) => {
      //api文档
      router.push({
        path:props.ApiFlowChart?'/home/ApiFlowChart/ApiFlowChartManagementEdit':'/home/ApiManage/ApiView',
        query: {
          apiId:row.apiId,
          changeFlag:row.changeFlag || 'N',
          operation:'view'
        }
      });
    };

    const apiInvoke = (row) => {
      router.push({
        path:"/home/serviceOperation/ApiTest",
        query:{
          apiName:row.apiName,
          apiId:row.apiId,
          apiType:props.ApiFlowChart?'arrange_api':'platform_api'
        }
      });
    }

    const reloadTable = () => {
      pageVO.value.currentPage = 1;
      getServiceList();
    };

    onMounted(() => {
      getServiceList();
      getApiCategory();
    });

    return {
      apiInvoke,
      onlineRow,
      offlineRow,
      viewDoc,
      showCard,
      apiName,
      getServiceList,
      reloadTable,
      handleDelete,
      handleEdit,
      handleAdd,
      tableData,
      filterText,
      filterNode,
      dataSourceTreeData,
      clickNode,
      apiServiceTypesMap,
      RequestMaps,
      apiStatusMap,
      updateApiStatus,
      isMyDesign,
      pageVO,
      handleSizeChange,
      handleCurrentChange,
      categoryId,
    };
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  display: flex;
  margin-top: 20px;
  text-align: left;
  .toolbar {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }
  .data-source-left-bar {
    width: 224px;
  }
  .data-box {
    width: calc(100% - 300px);
    margin-left: 20px;
    .search-box {
      width: 100%;
      .card {
        &:hover {
          .card-img {
            display: none;
          }
          .card-active-img {
            display: block;
          }
        }
        .card-img {
          display: block;
        }
        .card-active-img {
          display: none;
        }
      }
      .list {
        &:hover {
          .list-img {
            display: none;
          }
          .list-active-img {
            display: block;
          }
        }
        .list-img {
          display: block;
        }
        .list-active-img {
          display: none;
        }
      }
    }
    .cardType-row {
      max-height: 650px;
      overflow-y: auto;
      .card-box {
        background: #ffffff;
        border: 2px solid #ffffff;
        border-radius: 8px;
        box-shadow: 4px 4px 16px 0px rgba(0, 67, 161, 0.1);
        margin-bottom: 12px;
        cursor: pointer;
        box-sizing: border-box;
        &:hover {
          background: linear-gradient(180deg, #d9e3ff, #ffffff 100%);
          box-shadow: 3px 6px 15px 5px rgba(21, 69, 137, 0.16);
        }
        .card-box-top {
          display: flex;
          padding: 12px 12px 0 12px;
          align-items: center;
          .apiname {
            margin: 0 12px 0 0;
            text-align: left;
            font-size: 16px;
            font-family: Source Han Sans CN, Source Han Sans CN-700;
            font-weight: 700;
            max-width: 170px;
            min-wdith: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .card-time {
            font-size: 12px;
            font-family: Source Han Sans CN, Source Han Sans CN-400;
            font-weight: 400;
            text-align: LEFT;
            color: #4e5156;
          }
        }
        .card-box-mid {
          margin: 18px 0 0;
          padding: 0 12px 0 18px;
          p {
            margin: 0 0 12px 0;
            text-align: left;
            display: flex;
          }
          .card-label-mid {
            display: inline-block;
            width: 80px;
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-400;
            font-weight: 400;
            text-align: LEFT;
            color: #777d84;
          }
          .card-content-mid {
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-400;
            font-weight: 400;
            text-align: LEFT;
            color: #091328;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .card-box-bottom {
          border-top: 1px solid #d6dae0;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          text-align: left;
          justify-content: space-between;
          border-top: 1px solid #d6dae0;
          height: 44px;
          .card-box-bottom-status{
             display: flex;
             align-items: center;
          }
          .card-box-bottom-op{
            padding-right: 20px;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
</style>
