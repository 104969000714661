<template>
<div class="main">
        <div >
            <el-input
            class="input-transparent"
            v-model="businessCateName"
            placeholder="请输入业务名称"
            clearable
            style="margin-bottom: 24px; width: 600px;"
            @keyup.enter="getServiceList"
            >
            <template #suffix>
                <el-icon class="icon-search" @click="getServiceList"><Search /></el-icon>
            </template>
        </el-input>
        </div>
        <div class="box-card-radius-eight white-bg">
          <div class="toolbar">
            <el-button type="primary" icon="plus" style="color:#fff;" @click="handleAdd">新增业务分类</el-button>
          </div>
          <el-table 
            :data="tableData"
            style="width: 100%;margin-bottom: 20px;"
            row-key="businessCateId"
            border
            default-expand-all
            :tree-props="{children: 'child'}">  
            <el-table-column
            prop="businessCateName"
            label="分类名称">
            </el-table-column>
            <el-table-column
            prop="businessCateLevel"
            label="级别"
            width="180">
            </el-table-column>
            <!-- <el-table-column
            prop="address"
            width="150"
            label="状态">
            <template #default="scope">
                 <el-switch v-model="scope.row.value" class="main-el-switch" active-text="正常"  inactive-color="#ff4949" inactive-text="冻结"></el-switch>
            </template>
            </el-table-column> -->
            <el-table-column
            label=" 业务owner">
             <template #default="scope">
                {{scope.row.businessOwnerName + ' ' + scope.row.businessOwnerAccount }}
            </template>
            </el-table-column>
            <el-table-column
            prop="createdBy"
            label="创建人">
            
            </el-table-column>
            <el-table-column
            prop="creationTime"
            label="创建时间">
            </el-table-column>
            <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="150">
            <template #default="scope">
                 <el-button  size="medium" icon="Edit" type="text"  @click="handleEdit(scope.row)"></el-button>
                <el-button @click="handleDelete(scope.row)"  size="medium" icon="delete" type="text" style="color:#f56c6c;"></el-button>
            </template>
            </el-table-column>
        </el-table>
            
        </div>
 </div>
</template>

<script>
import { ref, onMounted } from "vue";
import {  useRouter } from 'vue-router';
import {request} from '@/api'
import { ElMessage } from "element-plus";


export default {
  name: `ServiceClassificationManagement`,
  setup() {
    const router = useRouter()
    let businessCateName = ref('')
    let tableData =  ref()
    const getServiceList = ()=>{
      request.selectBusinessCatePage({
          currentPage: 1,
          pageSize: 10,
          businessCateName:businessCateName.value
        }).then((res) => {
        if (res?.data?.code === 200) {
          tableData.value = res.data.data
        } else {
          ElMessage.error('失败')
        }
      });
    }
    const handleDelete = (row)=>{
        request.deleteBusinessCate({
          businessCateId:row.businessCateId
        }).then((res) => {
          if (res?.data?.code === 200) {
            ElMessage.success('删除成功')
            getServiceList()
          } else {
            ElMessage.error('失败')
          }
       })
    }

    const handleEdit =  (row)=>{
         router.push({
          path: '/home/DataResourceCenter/ServiceClassificationManagement/detail',
          query: {
            operation:'edit',
            businessCateId:row.businessCateId
          }
        })
    }

    const handleAdd = ()=>{
      router.push({
          path: '/home/DataResourceCenter/ServiceClassificationManagement/detail',
          query: {
            operation:'create'
          }
        })
    }

    onMounted(()=>{
      getServiceList()
    })

    return {
      businessCateName,
      getServiceList,
      handleDelete,
      handleEdit,
      handleAdd,
      tableData
    }
  }
}
</script>

<style  lang="less">
.input-transparent {
    border-radius: 20px;
    font-size: 16px;
    .el-input__wrapper {
        background: transparent;
    }

}
.icon-search{
    cursor: pointer;
}

 .main-el-switch{
    position: relative;
    .el-switch__core {
    height: 24px;
    width: 60px;
    border-radius: 12px;
    &:after {
    left: 4px;
    top: 10px;
    }
    }
    &.el-switch {
    .is-active {
    .el-switch__core {
    &:after {
    margin-left: -20px;
    left: 100%;
    }
    }
    }
    }
    
    .el-switch__label--left {
    opacity: 0;
    }
    .el-switch__label--right {
    opacity: 0;
    }
    .is-active {
      opacity: 1;
    }
    .el-switch__label {
    position: absolute;
    top: 5px;
    }
    .el-switch__label--left {
    right: 0;
    color: #fff!important;
    z-index: 1;
    margin-right: 8px;
    }

    .el-switch__label--right {
    left: 0;
    color: #fff!important;
    z-index: 1;
    margin-left: 8px;
    }
}
//  /*修改展开按钮的样式 start*/
//     /*1.取消原本展开的旋转动效*/
//     /deep/.el-table__expand-icon{
//         -webkit-transform: rotate(0deg);
//         transform: rotate(0deg);
//     }
//     /*2.展开按钮未点击的样式是加号带边框*/
//      .el-table__expand-icon .el-icon:before{
//         content: "\e6d9";
//         border: 1px solid #ccc;
//         padding: 2px;
//     }
//     /*3.展开按钮点击后的样式是减号带边框*/
//     .el-table__expand-icon--expanded .el-icon:before{
//         content: "\e6d8";
//     }

</style>
<style lang="less" scoped>
.main{
    padding:20px;
    text-align: left;
    .toolbar{
      margin-bottom: 24px;
    }
}
</style>