<template>
  <div>
    <div
      style="
        width: 100%;
        padding-left: 0px;
        margin-left: 0px;
        margin-top: 0px;
        min-height:750px
      "
    >
      <div style="text-align: left">
        <el-input
          v-model="searchForm.apiName"
          placeholder="请输入API名称或URL"
          class="input-with-select input-transparent"
          clearable
          style="margin-bottom: 24px; width: 600px; height: 32px"
          @keyup.enter="getData()"
        >
        <template #suffix>
            <el-icon class="icon-search" @click="getData()"><Search /></el-icon>
         </template>
        </el-input>
        <img
          src="@/assets/image/apiMarket/search-open.png"
          alt=""
          v-if="!onSearch"
          style="margin-bottom: 5px; cursor: pointer; margin-left: 20px"
          @click="onSearch = !onSearch"
        />
        <img
          src="@/assets/image/apiMarket/search-close.png"
          alt=""
          v-if="onSearch"
          style="margin-bottom: 5px; cursor: pointer; margin-left: 20px"
          @click="onSearch = !onSearch"
        />
      </div>
      <el-form
        v-if="onSearch"
        :inline="true"
        v-model="searchForm"
        style="text-align: left"
      >
        <el-form-item label="请求方式" >
          <el-dropdown>
            <span class="el-dropdown-link">
              {{apiRequestMLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
           <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="changeApiRequestM('','全部')">全部</el-dropdown-item>
              <el-dropdown-item v-for="request in Requests" :key="request.label" @click="changeApiRequestM(request.value,request.label)">{{request.label}}</el-dropdown-item>
            </el-dropdown-menu>
            </template>
          </el-dropdown>
          
        </el-form-item>
        
        <!-- <el-form-item label="创建方式" prop="apiCreateM">
           <el-dropdown>
            <span class="el-dropdown-link">
              {{apiCreateMLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
           <template #dropdown>
            <el-dropdown-menu >
              <el-dropdown-item @click="changeApiCreateM('','全部')">全部</el-dropdown-item>
              <el-dropdown-item @click="changeApiCreateM('1','手工创建')">手工创建</el-dropdown-item>
              <el-dropdown-item @click="changeApiCreateM('2','API注册')">API注册</el-dropdown-item>
            </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-form-item> -->
        <el-form-item label="状态" prop="status" v-if="listType != 3">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{statusLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
           <template #dropdown>
            <el-dropdown-menu >
              <el-dropdown-item @click="changeStatus('','全部')">全部</el-dropdown-item>
              <el-dropdown-item @click="changeStatus('draft','草稿')">草稿</el-dropdown-item>
              <el-dropdown-item @click="changeStatus('released','待上线')">待上线</el-dropdown-item>
              <el-dropdown-item @click="changeStatus('approval','审批中')">审批中</el-dropdown-item>
              <el-dropdown-item @click="changeStatus('online','上线')">上线</el-dropdown-item>
              <el-dropdown-item @click="changeStatus('offline','下线')">下线</el-dropdown-item>
            </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-form-item>
      </el-form>
      <!--api列表-->
      <el-row :gutter="20" v-if="cardType" class="cardType-row">
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="cardType-row--col" v-for="(item, index) in tableData.list" :key="index" @click="viewDoc2(item)">
          <div class="market-card">
          <div class="market-card-top">
            <img
              src="@/assets/image/home/market.png"
              alt=""
              style="width: 48px; height: 48px"
            />
            <div style="margin-left: 16px;width: calc(100% - 46px);">
              <div style="margin: 0; display: flex; align-items: center;flex-flow: nowrap;width: calc(100% - 12px);">
                <p
                  :title="item.apiName"
                  style="
                    margin: 0 12px 0 0;
                    text-align: left;
                    font-size: 16px;
                    font-family: Source Han Sans CN, Source Han Sans CN-700;
                    font-weight: 700;
                    max-width: 170px;
                    min-wdith: 140px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >
                  {{ item.apiName }}
                </p>
                <span
                  class="ellipsis"
                  :title="renderCreateType(item.apiType)"
                  style="
                    padding: 4px;
                    background: #e0edff;
                    border-radius: 2px;
                    color: #1f7aff;
                    font-size: 12px;
                    display: inline-block;
                    width: 52px;
                  "
                  >{{ renderCreateType(item.apiType) }}</span>
                <span class="ellipsis" v-if="item.changeFlag === 'Y'" style="
                    padding: 4px;
                    background: #f3ecfc;
                    border-radius: 2px;
                    color: #a16fe7;
                    display: inline-block;
                    margin-left: 8px;
                    font-size: 12px;
                    width: 52px;
                  ">迭代中
                  </span>
                <span
                  v-else
                  class="ellipsis"
                  :title="item.apiVersion ? item.apiVersion + '版本' : '-'"
                  style="
                    padding: 4px;
                    background: #f3ecfc;
                    border-radius: 2px;
                    color: #a16fe7;
                    display: inline-block;
                    margin-left: 8px;
                    font-size: 12px;
                    width: 52px;
                  "
                  >{{ item.apiVersion ? item.apiVersion + "版本" : "-" }}</span
                >
              </div>
              <div style="display: flex">
                <p class="ellipsis" style="margin: 0;padding-top: 6px;padding-right: 12px;">
                  <span class="market-label">
                    上线时间：
                    {{ renderCreateTime(item.apiOnlineTime) }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="market-card-mid">
            <p>
              <span class="market-label-mid">URL</span>
              <span class="market-content-mid" :title="item.apiUrl">{{ item.apiUrl }}</span>
            </p>
            <p>
              <span class="market-label-mid">请求方式</span>
              <span class="market-content-mid">{{
                RequestMaps[item.apiRequestM]
              }}</span>
            </p>
            <!-- <p>
              <span class="market-label-mid">所属应用</span>
              <span class="market-content-mid">{{ item.appName }}</span>
            </p> -->
            <p>
              <span class="market-label-mid">创建人</span>
              <span class="market-content-mid">{{
                item.createdName ? item.createdName + ' ' + item.createdNumber : "-"
              }}</span>
            </p>
            <p style="margin-bottom: 20px">
              <span class="market-label-mid">创建时间</span>
              <span class="market-content-mid">{{
                renderCreateTime(item.creationTime)
              }}</span>
            </p>
          </div>
          <div class="market-card-bottom" @click.stop="" >
            <div class="market-card-bottom-status">
              <span style="display: inline-block; width: 20px"></span>
              <img
                src="@/assets/image/apiMarket/draft.png"
                alt=""
                v-if="item.status == 'draft'"
                style="margin-right: 5px"
              />
              <img
                src="@/assets/image/apiMarket/pending.png"
                alt=""
                v-if="item.status == 'released' || item.status == 'approval' || item.status == 'pending_developed' || item.status == 'design'"
                style="margin-right: 5px"
              />
              <img
                src="@/assets/image/status/inApproval.png"
                alt=""
                v-if="item.status == 'developed'"
                style="margin-right: 5px"
              />
              
              <img
                src="@/assets/image/apiMarket/success.png"
                alt=""
                v-if="item.status == 'online'"
                style="margin-right: 5px"
              />
              <img
                src="@/assets/image/apiMarket/offline.png"
                alt=""
                v-if="item.status == 'offline'"
                style="margin-right: 5px"
              />
              {{
                apiStatusMap[item.status]
              }}
            </div>
            <div style="display:flex;align-items:center;">
              <el-tooltip 
               content="查看"
               v-if="item.status == 'draft' || item.status == 'online' || item.status == 'offline' || item.status == 'released'"
               v-has="'hidden&apiManage.list.document'"
              >
                  <el-button 
                    icon="View" 
                    @click="viewDoc2(item)"
                    link>
                  </el-button>
              </el-tooltip>
              <el-tooltip 
               content="编辑"
               v-if="item.status == 'draft' || item.status == 'offline' || item.status == 'released' || item.status == 'online'|| (item.status == 'developed' && listType !== '3')"
                v-has="'hidden&apiManage.list.edit'"
              >
                  <el-button 
                    icon="Edit" 
                    @click="editRow(item)"
                    link>
                  </el-button>
              </el-tooltip>
              <el-tooltip 
               content="测试"
               v-if="item.status == 'online' || item.status == 'offline' || item.status == 'released'"
              >
                  <el-button 
                    icon="CaretRight" 
                    @click="apiInvoke(item)"
                    link>
                  </el-button>
              </el-tooltip>
              <el-dropdown :hide-on-click="false" style="margin-left:5px;"  v-if="!(listType == '1' && item.status == 'developed')">
                <span class="more-opre el-dropdown-link"> </span>
                <template #dropdown>
                  <el-dropdown-menu>
                      <el-dropdown-item
                        v-if="item.status == 'offline' || item.status == 'released'"
                        icon="upload"
                        @click="onlineRow(item)"
                        v-has="'hidden&apiManage.list.online'"
                        >上线</el-dropdown-item>

                      <el-dropdown-item
                        v-if="item.status == 'online'"
                        icon="download"
                        @click="offlineRow(item)"
                        v-has="'hidden&apiManage.list.offline'"
                        >下线</el-dropdown-item>
                       <el-dropdown-item
                        v-if="(item.status == 'pending_developed' || item.status == 'developed') && listType == '3'"
                        icon="Edit"
                        @click="editRow(item)"
                        v-has="'hidden&apiManage.list.edit'"
                        >进入开发</el-dropdown-item>
                        <!-- <el-dropdown-item
                        v-if="item.status == '50'"
                        icon="Refresh"
                        @click="restoreRow(item)"
                        v-has="'hidden&apiManage.list.restore'"
                        >恢复</el-dropdown-item> -->

                        <!-- <el-dropdown-item
                        v-if="item.status == 'online' || item.status == 'offline' || item.status == 'released'"
                        icon="CirclePlus"
                        @click="updateVersion(item)"
                        >迭代</el-dropdown-item> -->
                      <el-dropdown-item 
                       v-if="item.status == 'draft' || item.status == 'released' || item.status == 'offline'"
                      icon="delete" @click="deleteRow(item)">删除</el-dropdown-item>

                      <el-dropdown-item v-if="item.status == 'approval'">审批中禁止操作</el-dropdown-item>

                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <span style="display: inline-block; width: 20px"></span>
            </div>
          </div>
          </div>
        </el-col>
      </el-row>
      <div style="" v-if="!cardType" class="table-box">
        <el-table
          :data="tableData.list"
          stripe
          @selection-change="selectChange"
          row-key="apiId"
          :tooltip-options="tooltipOptions"
          size="large"
        >
          <el-table-column
            prop="no"
            label="序号"
            width="60"
            align="left"
            type="index"
          />
          <el-table-column
            prop="apiName"
            label="名称"
            width="200"
            :show-overflow-tooltip="true"
          >
            <template #default="scope">
              <span
                @click="viewDoc2(scope.row)"
                style="color: #1890ff; cursor: pointer"
                >{{ scope.row.apiName }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="apiUrl"
            label="URL"
            width="300"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="apiRequestM"
            label="请求方式"
            width="120"
            :show-overflow-tooltip="true"
          >
            <template #default="scope">
              <span>{{ RequestMaps[scope.row.apiRequestM]}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="apiVersion"
            label="版本"
            :show-overflow-tooltip="true"
            width="120"
          >
            <template #default="scope">
              <el-tag type="warning" v-if="scope.row.changeFlag === 'Y'" style="width: 56px;">迭代中</el-tag>
              <el-button size="small" v-else style="width: 56px;">{{ scope.row.apiVersion }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" align="left" width="150">
            <template #default="scope">
              <img
                src="@/assets/image/apiMarket/draft.png"
                alt=""
                v-if="scope.row.status == 'draft'"
              />
              <img
                src="@/assets/image/apiMarket/pending.png"
                alt=""
                v-if="scope.row.status == 'released' || scope.row.status == 'approval'"
              />
              <img
                src="@/assets/image/status/inApproval.png"
                alt=""
                v-if="scope.row.status == 'developed'"
                style="margin-right: 5px"
              />
              <img
                src="@/assets/image/apiMarket/success.png"
                alt=""
                v-if="scope.row.status == 'online'"
              />
              <img
                src="@/assets/image/apiMarket/offline.png"
                alt=""
                v-if="scope.row.status == 'offline'"
              />
              {{
                apiStatusMap[scope.row.status]
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createdName"
            label="创建人"
            width="200px"
            :show-overflow-tooltip="true"
          >
           <template #default="scope">
              <span >{{scope.row.createdName ? scope.row.createdName + ' ' + scope.row.createdNumber : "-"}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="apiOnlineTime"
            label="上线时间"
            width="200px"
            :formatter="iasmjs.formatDate"
          />
          <el-table-column
            prop="apiDescription"
            label="描述"
            width="200px"
            :show-overflow-tooltip="true"
          />
          <!-- <el-table-column
            prop="updatedName"
            label="最后更新人"
            width="120"
            :show-overflow-tooltip="true"
          /> -->
          <el-table-column
            prop="operation"
            label="操作"
            width="350px"
            align="left"
            fixed="right"
          >
            <template #default="scope">
              <div >
                <el-button
                 v-if="scope.row.status == 'draft' || scope.row.status == 'online' || scope.row.status == 'offline' || scope.row.status == 'released'"
                  type="primary"
                  link
                  @click="viewDoc2(scope.row)"
                  v-has="'hidden&apiManage.list.document'"
                  >查看</el-button
                >

                <el-button
                 v-if="scope.row.status == 'offline' || scope.row.status == 'released'"
                  type="primary"
                  link
                  @click="onlineRow(scope.row)"
                  v-has="'hidden&apiManage.list.online'"
                  >上线</el-button>

                <el-button v-if="scope.row.status == 'online'"  type="primary" link  @click="offlineRow(scope.row)" v-has="'hidden&apiManage.list.offline'" >下线</el-button>

                <el-button 
                  v-if="scope.row.status == 'online' || scope.row.status == 'offline' || scope.row.status == 'released'" 
                  type="primary" link @click="apiInvoke(scope.row)"
                  >测试</el-button>
                  <!-- <el-button  v-if="scope.row.status == '50'"  type="primary" link @click="restoreRow(scope.row)" v-has="'hidden&apiManage.list.restore'" >恢复</el-button> -->
               
                  <!-- <el-button 
                  v-if="scope.row.status == 'online' || scope.row.status == 'offline' || scope.row.status == 'released'" 
                  type="primary" link @click="updateVersion(scope.row)">迭代</el-button> -->

                <el-button
                  v-if="(scope.row.status == 'pending_developed' || scope.row.status == 'developed') && listType == '3'"
                  type="primary"
                  link
                  @click="editRow(scope.row)"
                  v-has="'hidden&apiManage.list.edit'"
                  >进入开发</el-button
                >
                <el-button
                  v-if="scope.row.status == 'draft' || scope.row.status == 'offline' || scope.row.status == 'released' || scope.row.status == 'online' || (scope.row.status == 'developed' && listType !== '3')"
                  type="primary"
                  link
                  @click="editRow(scope.row)"
                  v-has="'hidden&apiManage.list.edit'"
                  >编辑</el-button
                >
                
                <el-button
                 v-if="scope.row.status == 'draft' || scope.row.status == 'released' || scope.row.status == 'offline'"
                 type="primary" link @click="deleteRow(scope.row)"
                  >删除</el-button>
                <el-button v-if="scope.row.status == 'approval'" type="primary" link disabled>审批中禁止操作</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页组件-->
      <div style="padding: 10px 0;float:right;">
        <el-pagination background small
          :current-page="page.pageNum"
          :page-size="page.pageSize"
          :page-sizes="[2, 5, 10, 20]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <div>
      <el-dialog v-model="dialogFormVisible" title="授权应用" width="55%">
        <span>
          <el-form
            label-width="100px"
            :model="updateValue"
            style="max-width: 460px"
          >
            <el-form-item prop="appList" label="应用">
              <el-select
                v-model="form.authorizedAppIdList"
                filterable
                remote
                reserve-keyword
                multiple
                collapse-tags
                collapse-tags-tooltip
                remote-show-suffix
                @click="getApp()"
              >
                <el-option
                  v-for="item in appList.list"
                  :key="item.appId"
                  :label="item.appName"
                  :value="item.appId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取消</el-button>
            <el-button type="primary" @click="authorization()">
              确定
            </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
  
  <script>
import {request} from '@/api'
import { reactive, ref, watch } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import iasmjs from "@/assets/js/iasm.js";
import { useRoute, useRouter } from "vue-router";
// import { useStore } from "vuex";
import { Search } from "@element-plus/icons";
import {apiStatus,RequestList,RequestMap,createTypeMap} from '@/utils/constant'
export default {
  name: "ApiMarketMyIssue",
  computed: {},
  props: {
    listType: {
      type: String,
      default: () => "1", // 默认API列表
    },
    cardType: {
      type: Boolean,
      default: () => true,
    },
    categoryId:{
      type:[String,Number]
    },
    
  },
  setup(props) {
    const onSearch = ref(false);
    // const store = useStore();
    let apiStatusMap = ref(apiStatus)
    const routers = useRoute();
    const router = useRouter();
    let apiRequestMLabel = ref('全部')
    let apiCreateMLabel = ref('全部')
    let statusLabel = ref('全部')
    let Requests = ref(RequestList)
    let RequestMaps = ref(RequestMap)
    const tooltipOptions = reactive({
      effect: "light",
      popperClass: "my-tooltip-class",
    });
    const getData = async (flag) => {
      if (flag) {
        const { apiInfo, apiStatus } = routers.query;
        searchForm.apiName = apiInfo || "";
        searchForm.status = apiStatus || "";
      }
      searchForm.currentPage = page.pageNum;
      searchForm.pageSize = page.pageSize;

      let params = {
          pageVO: {
            currentPage: searchForm.currentPage,
            pageSize: searchForm.pageSize,
          },
          queryKey: searchForm.apiName,
          apiRequestM: searchForm.apiRequestM,
          apiCreateM: searchForm.apiCreateM,
          status: searchForm.status,
          apiOwner: searchForm.apiOwner,
          apiUrl: searchForm.apiUrl,
          categoryId: props.categoryId || "-1",
      }
      let res
      if(props.listType === "1"){
        res = await request.selectMyAppIdIssueApiByPage(params)
      }else if(props.listType === "3"){
        res = await request.myDevelopmentApi(params)
      }else{
        res = await request.selectMyAppIdAndUserIdIssueApiByPage(params)
      }
      
      tableData.list = res.data.data.result;
      total.value = res.data.data.pageVO.total;
    };

    const updateValue = reactive({
      authorizedAppId: [],
    });

    const changeApiRequestM = (val,label)=>{
      searchForm.apiRequestM = val
      apiRequestMLabel.value = label
      getData();
    }

    const changeApiCreateM = (val,label)=>{
      searchForm.apiCreateM = val
      apiCreateMLabel.value = label
      getData();
    }

    const changeStatus = (val,label)=>{
      searchForm.status = val
      statusLabel.value = label
      getData();
    }

    const handleSizeChange = (val) => {
      //size修改
      page.pageSize = val;
      getData();
    };
    const handleCurrentChange = (val) => {
      //num修改
      page.pageNum = val;
      getData();
    };
    const viewDoc = (row) => {
      let url = router.resolve({
        path: "/home/ApiMarket/ApiInformation",
        query:{
          apiId:row.apiId,
          type:'2',
        }
      });
      window.open(url.href);
    };
    const getRequestMethods = () => {
      request.getLookupHead({lookupHCode: "requestMethod"}).then((res) => {
        let a = res.data.data;
        rqList.list = a.details;
      });
    };

    const getUserList = () => {
      request.selectUser1().then((res) => {
        let a = res.data.data;
        userList.list = a.result;
      });
    };

    const searchApi = () => {
      getData();
    };

    const reset = () => {
      searchForm.apiName = "";
      searchForm.apiRequestM = "";
      searchForm.status = "";
      searchForm.apiOwner = "";
      searchForm.apiUrl = "";
    };

    const operate = () => {
      showAll.value = !showAll.value;
    };

    const formData = reactive({
      likeData: "",
    });
    const tableData = reactive({
      //表格参数
      list: [],
    });

    const appList = reactive({
      list: [],
    });

    const page = reactive({
      //配置对应的查询参数
      pageNum: 1,
      pageSize: 10,
    });

    const searchForm = reactive({
      currentPage: "",
      pageSize: "",
      apiId: "",
      apiName: "",
      apiRequestM: "",
      apiResponseE: "",
      apiUrl: "",
      apiOwner: "",
      apiDescription: "",
      status: "",
      delFlag: "",
      apiCreateSql: "",
      dbId: "",
      tenantId: "",
      paramList: [],
      realParamList: [],
      fieldMappingList: [],
      userList: [],
      paramCategory: "",
      appId: "",
      authorizedAppId: "",
      apiCreateM: "",
    });
    const showAll = ref(false);

    const total = ref(0);

    const rqList = reactive({
      list: [],
    });

    const userList = reactive({
      list: [],
    });
    const dialogFormVisible = ref(false);
    const formLabelWidth = "140px";
    const form = reactive({
      apiId: "",
      appId: "",
      authorizedAppIdList: [],
    });
    const openDialog = (row) => {
      request.selectAuthorizeAppByApi({apiId: row.apiId}).then((res) => {
        form.authorizedAppIdList = res.data.data;
        form.apiId = row.apiId;
        form.appId = localStorage.getItem("appId");
      });
      getApp();
      dialogFormVisible.value = true;
    };
    const getApp = () => {
      request.selectApp1({appId: formData.likeData}).then((res) => {
        appList.list = res.data.data.result;
      });
    };

    const authorization = () => {
      request.getAuthorization(form).then((res) => {
        if (res.data.data.code == 500) {
          ElMessage.error(res.data.data.message);
        }
        if (res.data.data.code == 200) {
          dialogFormVisible.value = false;
          ElMessage.success("授权成功！");
        }
      });
    };

    const viewDoc2 = (row) => {
      //api文档
      router.push({
        path:'/home/ApiManage/ApiView',
        query: {
          apiId:row.apiId,
          changeFlag:row.changeFlag || 'N'
        }
      });
    };

    const editRow = (row) => {
      router.push({
          path: "/home/ApiManage/ApiManageCreate",
          query: {
            apiId: row.apiId,
            active: 0,
            updateVersion: false,
            id: router.currentRoute.value.query.id || "-1",
            changeFlag: row.changeFlag || 'N',
            listType: props.listType
          },
        });
    };

    const deleteRow = (row) => {
      //删除
      row.operateType = "8"; //操作码
      ElMessageBox.confirm(`确定删除当前服务：` + row.apiName)
        .then(() => {
          request.deleteApi(row).then((res) => {
            if (res.data.code == 200) {
              getData();
              ElMessage.success(res.data.msg)
            }else{
              ElMessage.error(res.data.msg)
            }
          });
        })
        .catch(() => {});
    };

    const offlineRow = (row) => {
      //下线
      row.newStatus = 'offline'; //下线状态码
      row.operateType = "5"; //操作码
      ElMessageBox.confirm(`确定下线当前服务：` + row.apiName)
        .then(() => {
          request.apiUpdateStatus(row).then((res) => {
            if (res.data.code == 200) {
              ElMessage.success("操作成功");
              getData();
            } else {
              ElMessage.error(res?.data?.data || "操作失败");
            }
          });
        })
        .catch(() => {});
    };

    const apiInvoke = (row) => {
      router.push({
        path:"/home/serviceOperation/ApiTest",
        query:{
          apiName:row.apiName,
          apiId:row.apiId,
          apiType:'platform_api'
        }
      });
      // //编辑
      // if (row.apiCreateM == "1") {
      //   router.push({
      //     path: "/home/ApiManage/ApiManageCreate",
      //     query: {
      //       apiId: row.apiId,
      //       active: 2,
      //       updateVersion: false,
      //       id: router.currentRoute.value.query.id || "-1",
      //       changeFlag: row.changeFlag || 'N'
      //     },
      //   });
      // } else {
      //   router.push({
      //     path: "/home/ApiManage/ApiManageRegister",
      //     query: {
      //       apiId: row.apiId,
      //       active: 1,
      //       updateVersion: false,
      //       id: router.currentRoute.value.query.id || "-1",
      //       changeFlag: row.changeFlag || 'N'
      //     },
      //   });
      // }
    };

    const getUpdataApiId = async (apiId) => {
      return new Promise((resolve, reject) => {
        request.changeApiBegin({ apiId }).then((res) => {
          const { status, data } = res
          if (status === 200 && data?.code === 200 && data.data) {
            resolve(data.data)
          } else {
            reject(data?.message || '更新出错！')
          }
        }).catch(err => reject(err));
      })
    }
    const updateVersion = async (row) => {
      let nextFlag = true
      let _newApiId = row.apiId
      if (row.changeFlag !== 'Y') { // 不在变更中，重新获取apiId
        await getUpdataApiId(row.apiId).then(data => {
          setTimeout(() => {
            _newApiId = data.apiId
          }, 200);
        }).catch((err) => {
          nextFlag = false
          ElMessage.error(err || '更新出错！')
        })
      }
      console.log(_newApiId);
      if (!nextFlag) return;
      //编辑
      if (row.apiCreateSql != null) {
        router.push({
          path: "/home/ApiManage/ApiManageCreate",
          query: {
            apiId: row.apiId,
            // newApiId: _newApiId,
            active: 0,
            updateVersion: true,
            id: router.currentRoute.value.query.id || "-1",
            // changeFlag: row.changeFlag
            changeFlag: 'Y'
          },
        });
      } else {
        router.push({
          path: "/home/ApiManage/ApiManageRegister",
          query: {
            apiId: row.apiId,
            active: 0,
            updateVersion: true,
            id: router.currentRoute.value.query.id || "-1",
            // changeFlag: row.changeFlag
            changeFlag: 'Y'
          },
        });
      }
    };

    const onlineRow = (row) => {
      //上线
      row.newStatus = 'online'; //上线状态码
      row.operateType = "4"; //操作码
      ElMessageBox.confirm(`确定上线当前服务：` + row.apiName)
        .then(() => {
          request.apiUpdateStatus(row).then((res) => {
            if (res.data.code == 200) {
              ElMessage.success("操作成功");
              getData();
              // store.dispatch("updateWaitForCount");
            } else {
              ElMessage.error(res?.data?.msg || "操作失败");
            }
          });
        })
        .catch(() => {});
    };


    const restoreRow = (row) => {
      //恢复
      row.newStatus = 'draft'; //草稿状态码
      row.operateType = "3"; //操作码
      ElMessageBox.confirm(`确定恢复当前服务：` + row.apiName)
        .then(() => {
          request.apiUpdateStatus(row).then((res) => {
            if (res.data.code == 200) {
              getData();
            } else {
              ElMessage.error(res?.data?.msg || "操作失败");
            }
          });
        })
        .catch(() => {});
    };

    const routerChange = (name) => {
      //router.push(`/home/ApiManage/${name}`);
      router.push({
        path: `/home/ApiManage/${name}`,
        query: { id: router.currentRoute.value.query.id || "-1" },
      });
    };

    const selectedRows = ref([]);

    const selectChange = (selects) => {
      selectedRows.value = selects;
    };

    const isDisabledBtn = () => {
      return selectedRows.value.length === 0;
    };

    // 00：草稿、10：待上线、20：审批中、30：上线、40：下线、
    // 状态码为00、10、40、50可以编辑
    // 状态码为00、10、50可以删除
    // 编辑
    const btnEdit = () => {
      const editFlag = selectedRows.value.every(
        (el) =>
          el.status === 'draft' ||
          el.status === 'released' ||
          el.status === 'offline'
      );
      if (selectedRows.value.length > 1) {
        ElMessage.error("请选择一条数据进行编辑！");
      } else if (!editFlag) {
        ElMessage.error("只能编辑状态为草稿、待上线、下线的数据！");
      } else {
        editRow(selectedRows.value[0]);
      }
    };

    // 批量删除
    const bulkDel = () => {
      const delFlag = selectedRows.value.every(
        (el) => el.status === 'draft' || el.status === 'released'
      );
      if (!delFlag) {
        ElMessage.error("只能删除状态为草稿、待上线、归档的数据！");
      } else {
        ElMessageBox.confirm(`确定删除当前所选服务?`)
          .then(() => {
            request.deleteApiByIds({
                operateType: "8", //操作码
                apiIdList: selectedRows.value.map((el) => el.apiId),
            }).then((res) => {
              if (res.data.code == 200) {
                getData();
              }
            });
          })
          .catch(() => {});
      }
    };
    const renderCreateTime = (data) => {
      if (!data) {
        return "-";
      }
      let dt = new Date(data);
      let y = dt.getFullYear();
      let m = (dt.getMonth() + 1 + "").padStart(2, "0");
      let d = (dt.getDate() + "").padStart(2, "0");
      let hh = (dt.getHours() + "").padStart(2, "0");
      let mm = (dt.getMinutes() + "").padStart(2, "0");
      let ss = (dt.getSeconds() + "").padStart(2, "0");
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
    };
    const renderRequestMethod = (data) => {
      if (!data) {
        return "-";
      }
      var text = "";
      if (data == "1") {
        text = "GET";
      } else {
        text = "POST";
      }
      return text;
    };
    const renderCreateType = (data) => {
      if (!data) {
        return "API开发";
      }
      return createTypeMap[data];
    };
    watch(() => props.categoryId, getData, {
      immediate: true,
    });
    return {
      onSearch,
      tooltipOptions,
      formData,
      getData,
      tableData,
      page,
      total,
      handleSizeChange,
      handleCurrentChange,
      viewDoc,
      searchForm,
      showAll,
      getRequestMethods,
      rqList,
      getUserList,
      userList,
      operate,
      searchApi,
      reset,
      dialogFormVisible,
      appList,
      formLabelWidth,
      openDialog,
      getApp,
      authorization,
      form,
      updateValue,
      iasmjs,
      viewDoc2,
      editRow,
      deleteRow,
      offlineRow,
      apiInvoke,
      updateVersion,
      onlineRow,
      restoreRow,
      routerChange,
      selectChange,
      isDisabledBtn,
      btnEdit,
      bulkDel,
      renderCreateTime,
      renderRequestMethod,
      renderCreateType,
      Search,
      changeApiRequestM,
      changeApiCreateM,
      changeStatus,
      statusLabel,
      Requests,
      RequestMaps,
      apiRequestMLabel,
      apiCreateMLabel,
      apiStatusMap
    };
  },
  beforeCreate() {
    this.getRequestMethods();
    this.getData(true);
  },
};
</script>
  
  <style scoped lang="less">
.table-btn-operate--left {
  text-align: left;
  margin-bottom: 12px;
}
.market-card-box {
  display: flex;
  flex-wrap: wrap;
}
.market-card {
  /* height: 277px; */
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 8px;
  box-shadow: 4px 4px 16px 0px rgba(0, 67, 161, 0.1);
  margin-bottom: 12px;
  cursor: pointer;
  box-sizing: border-box;
}
.market-card:hover {
  background: linear-gradient(180deg, #d9e3ff, #ffffff 100%);
  box-shadow: 3px 6px 15px 5px rgba(21, 69, 137, 0.16);
}
.market-card-top {
  display: flex;
  padding: 12px 12px 0 12px;
  align-items: center;
}
.market-label {
  font-size: 12px;
  font-family: Source Han Sans CN, Source Han Sans CN-400;
  font-weight: 400;
  text-align: LEFT;
  color: #4e5156;
}
.market-content {
  font-size: 12px;
  font-family: Source Han Sans CN, Source Han Sans CN-400;
  font-weight: 400;
  text-align: LEFT;
  color: #091328;
}
.market-card-mid {
  margin: 18px 0 0;
  padding: 0 12px 0 18px;
}
.market-card-mid p {
  margin: 0 0 12px 0;
  text-align: left;
  display: flex;
}
.market-label-mid {
  display: inline-block;
  width: 80px;
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-400;
  font-weight: 400;
  text-align: LEFT;
  color: #777d84;
}
.market-content-mid {
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-400;
  font-weight: 400;
  text-align: LEFT;
  color: #091328;
   max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.market-card-bottom {
  border-top: 1px solid #d6dae0;
  height: 44px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  .market-card-bottom-status{
    display: flex;
    align-items: center;
  }
}
.green-color {
  color: #1bcd8b;
}
.blue-color {
  color: #0067ff;
}
.table-box {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 4px 4px 16px 0px rgba(0, 67, 161, 0.1);
  padding: 20px 20px 32px 20px;
}
.more-opre {
  background: url("@/assets/image/apiMarket/more.png") no-repeat center;
  display: inline-block;
  width: 28px;
  height: 28px;
}
.more-opre:hover {
  background: url("@/assets/image/apiMarket/more-hover.png") no-repeat
    center;
  display: inline-block;
  width: 28px;
  height: 28px;
  outline: none;
}
.el-dropdown-link{
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-400;
  font-weight: 500;
  color: #091328;
  .el-icon-arrow-down{
    margin-left: 3px;
  }
  }
  .cardType-row{
    max-height: 650px;
    overflow-y: auto;
  }
</style>