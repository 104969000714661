<template>
  <div>
    <el-button type="primary" @click="addRow" style="margin-bottom:20px;">新增计算列</el-button>
     <el-table :data="out" max-height="285">

                 <el-table-column
                    prop="apiFieldCnname"
                    label="参数中文名称"
                    width="200"
                    :show-overflow-tooltip="true"
                    >
                    <template #default="scope">
                        <el-input
                        v-model="scope.row.apiFieldCnname"
                        clearable
                        maxlength="50"
                        placeholder="请输入"
                        ></el-input>
                    </template>
                    </el-table-column>
                     <el-table-column
                    prop="apiFieldName"
                    label="参数名称"
                    width="200"
                    :show-overflow-tooltip="true"
                    >
                    <template #default="scope">
                        <el-input
                        v-model="scope.row.apiFieldName"
                        clearable
                        maxlength="50"
                        placeholder="请输入"
                        ></el-input>
                    </template>
                    </el-table-column>

                <el-table-column prop="apiFieldType" label="字段类型" width="200">
                    <template #default="scope">
                        <el-select
                            v-model="scope.row.apiFieldType"
                            clearable
                            placeholder="请选择参数类型"
                            >
                             <el-option v-for="type in paramsTypes" :key="type.label" :label="type.label" :value="type.value" />
                       </el-select>
                    </template>
                </el-table-column>

                <el-table-column prop="source" label="值来源" width="200">
                    <template #default="scope">
                        <el-select
                            v-model="scope.row.source"
                            clearable
                            placeholder="请选择值来源"
                            @change="scope.row.paramFun = null"
                            >
                            <el-option label="计算函数" value="comput_function" />
                            <el-option label="API入参" value="api_input_paramter" />
                            <!-- <el-option label="上游结果数据" value="node_result_data"/> -->
                            <el-option label="固定常量" value="api_constant" />
                       </el-select>
                    </template>
                </el-table-column>
                 <el-table-column
                    prop="function"
                    label="函数"
                    width="300"
                    :show-overflow-tooltip="true"
                    >
                    <template #header>
                    <span style="display:flex;align-items:center;">函数或值
                        <el-tooltip 
                        content="函数用法:函数名称(#{参数名称},固定参数值,...) 例如:trim(#{username}),对参数变量username去除前后空格"
                        placement="top"
                        >
                        <el-icon><QuestionFilled /></el-icon>
                        </el-tooltip>
                        </span>
                    </template>
                   <template #default="scope">
                         <el-select
                            style="width:100%;"
                            v-if="scope.row.source === 'comput_function'"
                            v-model="scope.row.paramFun"
                            clearable
                            placeholder="请选择"
                            >
                            <el-option v-for="f in functionList" :label="f.functionName" :value="f.functionName" :key="f.functionName"/>
                           </el-select>
                            <el-select
                                v-else-if="scope.row.source === 'api_input_paramter'"
                                style="width:100%;"
                                v-model="scope.row.paramFun"
                                clearable
                                placeholder="请选择"
                                >
                                <el-option v-for="p in apiInputParamters" :label="p.paramName" :value="p.paramName" :key="p.paramName"/>
                                
                        </el-select>
                        <el-input  v-else  v-model.trim="scope.row.paramFun"></el-input>
                   </template>
                </el-table-column>
                <el-table-column
                    prop="defaultValue"
                    label="默认值"
                    width="200"
                    :show-overflow-tooltip="true"
                    >
                    <template #default="scope">
                        <el-input
                        v-model="scope.row.defaultValue"
                        clearable
                        maxlength="50"
                        placeholder="请输入"
                        ></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="fieldDescription"
                    label="描述"
                    width="200"
                    :show-overflow-tooltip="true"
                    >
                    <template #default="scope">
                        <el-input
                        v-model="scope.row.fieldDescription"
                        clearable
                        maxlength="50"
                        placeholder="请输入"
                        ></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80" align="center" fixed="right">
                    <template #default="scope">
                        <el-popconfirm
                        title="删除后将无法恢复，请确认是否删除？"
                        confirm-button-type="danger"
                        icon="warning-filled"
                        icon-color="#F56C6C"
                        width="250"
                        placement="top-end"
                        @confirm="deleteRow(scope.$index)"
                        >
                        <template #reference>
                            <el-button
                            icon="delete"
                            type="danger"
                            text
                            ></el-button>
                        </template>
                        </el-popconfirm>
                    </template>
                    </el-table-column>
            </el-table>
  </div>
</template>

<script>
import { ref,onBeforeUnmount } from 'vue';
import {request} from '@/api'
import { ElMessage } from "element-plus";
import {getInitMapperTable} from '@/utils/function.js'
import {paramsTypesList} from '@/utils/constant'
export default {
    props:['iasmApiArrangeNode','nodeConfigGraphData','operatorData','operatorFlowId','iasmApiArrange'],
    setup(props){
        let out = ref([])
        let parentout = ref([])
        let functionList = ref([])
        let apiInputParamters = ref([])
        let paramsTypes = ref(paramsTypesList)
        apiInputParamters.value = (props.iasmApiArrange?.apiInputParamters && JSON.parse(props.iasmApiArrange.apiInputParamters)) || []
        if(props.iasmApiArrangeNode && props.iasmApiArrangeNode.nodeData){
            let nodeData = JSON.parse(props.iasmApiArrangeNode.nodeData)
            
            out.value = nodeData.out
            parentout.value = nodeData.parentout
        }else{
            //获取上一级的table
            out.value = getInitMapperTable(
                props.nodeConfigGraphData,
                props.operatorData,
                props.operatorFlowId,
            );
        }

        

        const getNodeData = ()=>{
            return {
                out:out.value,
                parentout:parentout.value 
            }
        }

        const addRow = () => {
            out.value.push({
                 apiFieldCnname:null,
                 apiFieldName:null,
                 apiFieldType:null,
                 defaultValue:null,
                 fieldDescription:null,
                 paramsType:null,
                 source:null,
            });
        };

        const deleteRow = (index)=>{
          out.value.splice(index, 1);
        }

        const querySearch = (queryString, cb) => {
            console.log(queryString,'>>>queryString>>>')
            let results 
            let restaurants = functionList.value
            if(queryString && queryString !== 'null'){
                console.log(queryString,'>>>queryString22>>>')
                results = restaurants.filter(createFilter(queryString))
            }else{
                results = restaurants
            }
            cb(results)
        }

        const createFilter = (queryString) => {
            return (restaurant) => {
                return (
                restaurant.functionName.toLowerCase().indexOf(queryString.toLowerCase()) === 0
                )
            }
        }

        const getOverallFunction = ()=>{
            request.getOverallFunction({
                currentPage: 1,
                pageSize: 10000,
                }).then((res) => {
                if (res?.data?.code === 200) {
                functionList.value = res.data.data?.result || []
                } else {
                ElMessage.error('失败')
                }
            });
        }

        const reset = ()=>{
            out.value = []
        }

        const validate = ()=>{
          return true
        }

        onBeforeUnmount(() => {
         reset()
        });

        getOverallFunction()
        return{
            out,
            addRow,
            deleteRow,
            getNodeData,
            querySearch,
            getOverallFunction,
            reset,
            paramsTypes,
            validate,
            apiInputParamters,
            functionList
        }
    }

}
</script>

<style lang="less" scoped>
.step-title{
    margin-top: 50px;
}
</style>