<template>
   <CodeMirror  
      class="codemirror" 
      @change="changeCode" 
      :value="value"   
      :options="cmOptions" 
      border 
      ref="CodeMirroRef"  
      style="height:200px;text-align:left;line-height:16px;" >
    </CodeMirror>
</template>

<script>
  import { ref} from 'vue';
  import 'codemirror/mode/javascript/javascript.js'
  import CodeMirror from 'codemirror-editor-vue3'
  import 'codemirror/addon/display/autorefresh'
  import 'codemirror/mode/sql/sql'
  import 'codemirror/addon/hint/show-hint'
  import 'codemirror/addon/hint/sql-hint'
  import 'codemirror/addon/hint/show-hint.css'
  import { MODE } from '@/utils/constant';
  export default {
    components:{
      CodeMirror
    },
    props:{
    mode: {
      type: String,
      default: MODE.JSON,
      validator(value) {
        // 这个值必须匹配下列字符串中的一个
        return Object.values(MODE).includes(value);
      },
    },
    value: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    TooltipBool: { type: Boolean, default: false },
    TooltipValue: { type: String, default: '' },
  },
  emits:['change', 'handSaveDraft', 'update:value'],
  setup(props,{emit}) {
    let CodeMirroRef = ref(null);

     const cmOptions = {
            value:props.value,
            mode: props.mode,
            smartIndent: true, // 智能缩进
            lineNumbers: true, // 是否显示行号
            indentUnit: 2, // 智能缩进单位为4个空格长度
            foldGutter: true, // 启用行槽中的代码折叠
            tabSize: 2, // 缩进
            lineWrapping:true,//自动换行
            styleActiveLine: true, // 高亮选中行
            matchBrackets: true, // 括号匹配
            // cursorHeight: 0.9,
            theme: 'default', // 主题名称
            autofocus: false,
            autoRefresh: true,
            extraKeys: {
                'Ctrl': 'autocomplete'
            },
            hintOptions: {
                completeSingle: false
            },
            readOnly: props.readonly // 是否只读
        }
      const changeCode = (v)=>{
        emit('update:value', v);
        emit('change', v);
      }
      
      return {
        cmOptions,
        changeCode,
        CodeMirroRef
      }
  }
  }

  
</script>
<style lang="less">
.codemirror{
  .CodeMirror{
    height:100%;
    width: 100%;
  }
}
</style>
