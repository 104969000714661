import MysqlImg from '../../assets/image/dataSource/mysql.webp'
import SQLSeverImg from '../../assets/image/dataSource/SQLSever.webp'
import OracleImg from '../../assets/image/dataSource/oracle.webp'
import Postgresqlimg from '../../assets/image/dataSource/Postgresql.webp'
import hanaImg from '../../assets/image/dataSource/HANA.png'
import sybaseiqImg from '../../assets/image/dataSource/iq.png'
import dmImg from '../../assets/image/dataSource/dameng.png'
import mariadbImg from '../../assets/image/dataSource/mariadb.png'
import gpImg from '../../assets/image/dataSource/greenplum.webp';
import dwsImg from '../../assets/image/dataSource/dws.png'
import db2Img from '../../assets/image/dataSource/DB2.webp'
import guassImg from '../../assets/image/dataSource/gs.png'
const dbs = [
    // {
    //   label: 'DB2',
    //   type: 'db2',
    //   src: DB2,
    // },
  
    {
      label: 'MySQL',
      type: 'mysql',
      src: MysqlImg,
      classification: 'relationalDB'
    },
    {
      label: 'SQL Sever',
      type: 'mssql',
      src: SQLSeverImg,
      classification: 'relationalDB'
    },
    {
      label: 'Oracle',
      type: 'oracle',
      src: OracleImg,
      classification: 'relationalDB'
    },
    {
      label: 'PostgreSQL',
      type: 'postgres',
      src: Postgresqlimg,
      classification: 'relationalDB'
    },
    {
      label: 'SAP HANA',
      type: 'hana',
      src: hanaImg,
      classification: 'relationalDB'
    },
    {
      label: 'SAP IQ',
      type: 'sybaseiq',
      src: sybaseiqImg,
      classification: 'relationalDB'
    },
    // {
    //   label: 'MongoDB',
    //   type: 'mongodb',
    //   src: mongo,
    // },
    {
      label: 'MariaDB',
      type: 'mariadb',
      src: mariadbImg,
      classification: 'relationalDB'
    },
    {
      label: 'Greenplum',
      type: 'greenplum',
      src: gpImg,
      classification: 'relationalDB'
    },
    {
      label: 'DB2',
      type: 'db2',
      src: db2Img,
      classification: 'relationalDB'
    },
    {
      label: 'DWS',
      type: 'dws',
      src: dwsImg,
      classification: 'dataDB'
    },
    {
      label: '达梦',
      type: 'dm',
      src: dmImg,
      classification: 'domesticDB'
    },
    {
      label: 'GuassDB',
      type: 'gaussdb',
      src: guassImg,
      classification: 'domesticDB'
    }
  ]
  export default dbs