<template>
  <div class="data-source-left-bar">
    <el-scrollbar>
      <el-input
        v-model="filterText"
        placeholder="请输入"
        suffix-icon="Search"
        style="
          width: 80%;
          margin-left: 30px;
          margin-right: 10px;
          margin: 20px 0;
        "
      />
      <el-tree
        ref="treeRef"
        class="filter-tree"
        :data="dataSourceTreeData"
        :props="defaultProps"
        default-expand-all
        :filter-node-method="filterNode"
        :indent="20"
        :highlight-current="true"
      >
        <template #default="{ node }">
          <span class="custom-tree-node" @click="clickNode(node)" style="position:relative;">
            <span :title="node.label">
              <span
                v-if="node.data.type == 'folder' || node.data.type == 'root'"
              >
                <img src="../assets/image/dataSource/db/file.png" alt="" style="vertical-align: text-bottom"/>
              </span>
              <span v-else>
                <img
                  src="../assets/image/dataSource/db/mysql.webp"
                  alt=""
                  style="width: 14px; height: 14px"
                  v-if="node.data.dataType == 'mysql'"
                />
                <img
                  src="../assets/image/dataSource/db/hana.webp"
                  alt=""
                  style="width: 14px; height: 14px"
                  v-if="node.data.dataType == 'hana'"
                />
                <img
                  src="../assets/image/dataSource/db/postgresql.webp"
                  alt=""
                  style="width: 14px; height: 14px"
                  v-if="node.data.dataType == 'postgres'"
                />
                <img
                  src="../assets/image/dataSource/db/sqlserver.webp"
                  alt=""
                  style="width: 14px; height: 14px"
                  v-if="node.data.dataType == 'mssql'"
                />
                <img
                  src="../assets/image/dataSource/db/iq.png"
                  alt=""
                  style="width: 14px; height: 14px"
                  v-if="node.data.dataType == 'sybaseiq'"
                />
                <img
                  src="../assets/image/dataSource/db/oracle.webp"
                  alt=""
                  style="width: 14px; height: 14px"
                  v-if="node.data.dataType == 'oracle'"
                />
                <img
                  src="../assets/image/dataSource/mariadb.png"
                  alt=""
                  style="width: 14px; height: 14px"
                  v-if="node.data.dataType == 'mariadb'"
                />
                <img
                  src="../assets/image/dataSource/greenplum.webp"
                  alt=""
                  style="width: 14px; height: 14px"
                  v-if="node.data.dataType == 'greenplum'"
                />
                <img
                  src="../assets/image/dataSource/DB2.webp"
                  alt=""
                  style="width: 14px; height: 14px"
                  v-if="node.data.dataType == 'db2'"
                />
                <img
                  src="../assets/image/dataSource/dws.png"
                  alt=""
                  style="width: 14px; height: 14px"
                  v-if="node.data.dataType == 'dws'"
                />
                <img
                  src="../assets/image/dataSource/dameng.png"
                  alt=""
                  style="width: 14px; height: 14px"
                  v-if="node.data.dataType == 'dm'"
                />
                <img
                  src="../assets/image/dataSource/gs.png"
                  alt=""
                  style="width: 14px; height: 14px"
                  v-if="node.data.dataType == 'gaussdb'"
                />
              </span>
              {{ node.label }}
              <span
                v-if="node.data.type == 'folder' || node.data.type == 'root'"
                >{{ `(${node.data.children.length})` }}</span
              >
            </span>
            <el-dropdown :hide-on-click="false" >
              <span class="el-dropdown-link">
                <el-icon size="small"><MoreFilled /></el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <div v-if="node.data.type == 'root'">
                    <el-dropdown-item
                      icon="Document"
                      @click="relName('add', node.data)"
                      >新建文件夹</el-dropdown-item
                    >
                  </div>
                  <div v-if="node.data.type == 'folder'">
                    <el-dropdown-item
                      icon="SetUp"
                      @click="relName('edit', node.data)"
                      >重命名</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="Delete"
                      @click="deleteItem('folder', node.data.key)"
                      >删除</el-dropdown-item
                    >
                  </div>
                  <div v-if="node.data.type == 'datasource'">
                    <el-dropdown-item
                      icon="Sort"
                      @click="moveTo(node.data)"
                      >移动到</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="Delete"
                      @click="deleteItem('datasource', node.data.key)"
                      >删除</el-dropdown-item
                    >
                  </div>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </span>
        </template>
      </el-tree>
      <el-dialog
        v-model="centerDialogVisible"
        ref="dialog"
        :title="title"
        width="500px"
      >
        <el-form>
          <el-form-item>
            <el-input
              v-model.trim="folderInfo.folderName"
              maxlength="130"
              style="width: 100%"
              placeholder="请输入文件夹名"
            ></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="close()">取消</el-button>
            <el-button type="primary" @click="save()"> 确定 </el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        v-model="moveVisble"
        ref="moveDialog"
        title="移动到"
        width="500px"
      >
        <el-form>
          <el-form-item label="目录">
            <el-tree-select
              style="width: 100%"
              v-model="categoryIdInfo.categoryId"
              :data="treeData"
              :render-after-expand="false"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="closeMove()">取消</el-button>
            <el-button type="primary" @click="saveMove()"> 确定 </el-button>
          </span>
        </template>
      </el-dialog>
    </el-scrollbar>
  </div>
</template>
<script>
import router from "@/router";
import floder from "../assets/image/dataSource/db/file.png";
import {request} from '@/api'
import { reactive, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useStore } from 'vuex'
import { WarningFilled } from '@element-plus/icons';
export default {
  name: "DataSourceTree",
  components: {},
    computed: {
    dataSourceTreeData() {
      const store = useStore()
      if(store.state.dataSourceTreeData.length==0){
        store.dispatch('getTreeData')
      }
      return store.state.dataSourceTreeData
    }
  },
  setup() {
    const store=useStore()
    const treeData = ref([]);
    const title = ref("");
    const centerDialogVisible = ref(false);
    const moveVisble = ref(false);
    const floderImg = ref(floder);
    const defaultProps = {
      children: "children",
      label: "title",
    };
    const dbTypeNum = reactive({
      MySQL: 0,
      PostgreSQL: 0,
      Oracle: 0,
      GaussDB: 0,
      GaussDBDWS: 0,
    });
    const folderInfo = reactive({
      folderName: "",
    });
    const categoryIdInfo = reactive({
      categoryId: "",
    });
    const filterText = ref("");
    const filterNode = (value, data) => {
      if (!value) return true;
      return data.title.includes(value);
    };
    const dataSource = reactive({
      tree: [],
    });
    // const getData = () => {
    //    store.dispatch('getTreeData')
    //    console.log('aaaa',store.state)
    // };
    const clickNode = (node) => {
      if (node.data.type == "datasource") {
        router.push({
          path: "/home/DataResourceCenter/DataSourceManage/DataSourceInfo",
          query: {
            dataSourceType: node.data.dataType,
            operateType: "update",
            id: node.data.id,
          },
        });
      }
    };
    const deleteItem = (type, nodeKey) => {
      ElMessageBox.confirm(`删除后将无法恢复，请确认是否删除当前数据?`, {
        icon: WarningFilled,
        type: 'error'
      })
        .then(async () => {
          let resp
          if(type == "datasource"){
           resp = await  request.deleteWithRef({nodeKey})
          }else{
           resp = await  request.deleteDatasource({nodeKey})
          }
          if (resp?.data?.code === 0) {
            ElMessage.success("删除成功");
            store.dispatch('getTreeData')
          } else {
            ElMessage.error(resp.data.msg);
          }
          store.dispatch('getTreeData')
        })
        .catch(() => {});
    };
    const currentNode = reactive({});
    const relName = (type, nodeInfo) => {
      if (type == "add") {
        title.value = "新建文件夹";
      } else {
        title.value = "重命名文件夹";
        folderInfo.folderName = nodeInfo.title;
      }
      centerDialogVisible.value = true;
      currentNode.key = nodeInfo.key;
    };
    const close = () => {
      centerDialogVisible.value = false;
      folderInfo.folderName = "";
    };
    const save = async () => {
      if (!folderInfo.folderName) {
        ElMessage.error("请输入文件夹名");
        return;
      }

      let resp 
      if(title.value == "新建文件夹"){
        resp = await request.saveDatasource({
          categoryParentId: currentNode.key,
          nameCn: folderInfo.folderName,
        })
      } else {
        resp = await request.renameDatasource({
           categoryId: currentNode.key,
           nameCn: folderInfo.folderName,
        })
      }
      if (resp.data.code == 200) {
        ElMessage.success(title.value == "新建文件夹" ? "新建成功" : "修改成功");
        close();
        store.dispatch('getTreeData')
      }else{
        ElMessage.error(resp.data.msg)
      }
    };
    const treeDataMapping = (data) => {
      return data
        .filter((item) => item.type !== "datasource")
        .map((item) =>
          Array.isArray(item.children) && item.children.length
            ? {
                value: item.id,
                label: item.title,
                children: treeDataMapping(item.children),
              }
            : { value: item.id, label: item.title }
        );
    };
    const moveTo = (nodeInfo) => {
      const list = sessionStorage.getItem("DatasourceTree");
      treeData.value = treeDataMapping(JSON.parse(list));
      moveVisble.value = true;
      currentNode.key = nodeInfo.key;
    };
    const closeMove = () => {
      moveVisble.value = false;
      categoryIdInfo.categoryId = "";
    };
    const saveMove = () => {
      if (!categoryIdInfo.categoryId) {
        ElMessage.error("请选择文件夹");
        return;
      }

      request.moveDatasourceinfo({ categoryId: categoryIdInfo.categoryId, id: currentNode.key }).then((resp) => {
        if (resp.data.code == 200) {
          ElMessage.success("操作成功");
          closeMove();
          store.dispatch('getTreeData')
        }else{
          ElMessage.error(resp.data.msg)
        }
      });
    };
    return {
      defaultProps,
      filterNode,
      //getData,
      dataSource,
      dbTypeNum,
      filterText,
      floderImg,
      clickNode,
      deleteItem,
      relName,
      centerDialogVisible,
      title,
      folderInfo,
      close,
      save,
      currentNode,
      categoryIdInfo,
      moveVisble,
      treeData,
      saveMove,
      closeMove,
      moveTo,
    };
  },
  beforeCreate() {
    //this.getData();
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    },
  },
};
</script>