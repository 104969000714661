//axiosInstance.js
//导入axios
import axios from 'axios'
import router from '../router'
import {ElMessageBox,ElMessage} from "element-plus"
const isProduct=false
//使用axios下面的create([config])方法创建axios实例，其中config参数为axios最基本的配置信息。
const API = axios.create({
    // baseUrl:'http://10.28.55.224/', //请求后端数据的基本地址，自定义
    timeout: 70000,                  //请求超时设置，单位ms 
})
// http request 拦截器
API.interceptors.request.use(
    config => {
      if (localStorage.token) { //判断token是否存在
        config.headers.satoken = localStorage.token;  //将token设置成请求头
      }
      config.headers['Accept-Language'] = 'zh_CN'
      
      return config;
    },
    err => {
      return Promise.reject(err);
    }
);
// http response 拦截器
let count = 0
API.interceptors.response.use(
  response => {
    var res = response.data
    if(res.code === 200){
      return response;
    }else if (res.code == 401 || (res.code == 500 && res.msg && res.msg.indexOf("Token") !== -1) ){
      if(count > 0) return
      count++;
      // 验证失败后跳转登录页面
        ElMessage.error("登录状态已失效，请重新登录！")
        //http://portal.nanniwan.online/#/login
        //http://10.28.55.225:9001/#/login 测试环境
        if(isProduct){
          location.href='http://portal.nanniwan.online/#/login'
        }else{
          router.push({
            path: '/login',
          })
        }
        localStorage.clear()
      
    }
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 验证失败跳转登录界面
          if(isProduct){
            location.href='http://portal.nanniwan.online/#/login'
          }else{
            router.push({
              path: '/login',
            })
          }
          localStorage.clear()
            break
      case 403:
          // 权限不足
          ElMessageBox.alert("权限不足，无法访问！");
          /*router.replace({
              path: '/home/homeView',
              query: { redirect: router.currentRoute.path },
          })*/
          console.log(router)
          // router.go(-1)
          break
      case 500:
            ElMessageBox.alert(error.response.data.msg || error.response.data.error);
            break
        default:
          ElMessageBox.alert("服务器异常，请联系管理员！");
      } 
    }
    return Promise.reject(error.response.data)
  },
)
  
//导出我们建立的axios实例模块，ES6 export用法
export default API
