<template>
 <el-dialog
        v-model="dialogVisible"
        ref="dialog"
        title="参数配置"
        width="1200px"
      >
       <div>
        <div  class="box-card-radius-eight white-bg" style="margin-top:20px;">
          <div align="left" class="step-title margin-botton-12 margin-top-24"><strong>入参配置</strong></div>
          <div align="left" class="table-btn__up margin-botton-12">
            <el-button type="primary" icon="plus" @click="addRow('apiInputParamters')" >新增入参</el-button>
          </div>
          <el-table :data="apiInputParamters" style="width: 100%" max-height="285">
            <el-table-column
              prop="paramNameCm"
              label="参数中文名称"
              :show-overflow-tooltip="true"
               width="300"
            >
              <template #default="scope">
                <el-input
                
                  v-model="scope.row.paramNameCm"
                  maxlength="100"
                  placeholder="请输入参数中文名称"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="paramName"
              label="参数名称"
               width="300"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                
                  v-model="scope.row.paramName"
                  maxlength="50"
                  placeholder="请输入参数名称"
                  
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="paramValue"
              label="默认值"
               width="200"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                
                  v-model="scope.row.paramValue"
                  maxlength="50"
                  placeholder="请输入默认值"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="paramType" label="数据类型"  width="200">
              <template #default="scope">
                <el-select
                
                  v-model="scope.row.paramType"
                  placeholder="请选择数据类型"
                >
                  <el-option v-for="p in paramsTypes" :key="p.label" :label="p.label" :value="p.value" />
                  </el-select>
                  </template>
            </el-table-column>
            <el-table-column
              prop="paramIsMust"
              label="是否必填写"
               width="200"
            >
              <template #default="scope">
                <el-select v-model="scope.row.paramIsMust" default="N" >
                  <el-option value="Y" label="是" />
                  <el-option value="N" label="否" />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="sort"
              label="排序"
               width="200"
            >
              <template #default="scope">
                <el-input-number
                
                  v-model="scope.row.sort"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column
              prop="paramFun"
              label="输入函数"
               width="500"
            >
              <template #default="scope">
                <el-autocomplete
                   
                    v-model="scope.row.paramFun"
                    :fetch-suggestions="querySearch"
                    value-key="functionName"
                    label="functionName"
                    clearable
                    style="width:100%;"
                  />
               
              </template>
            </el-table-column>
            <el-table-column
                  prop="example"
                  label="示例值"
                  width="200"
                  :show-overflow-tooltip="true"
                >
                  <template #default="scope">
                    <el-input
                      clearable
                      v-model="scope.row.example"
                      placeholder="请输入"
                      maxlength="50"
                    ></el-input>
                  </template>
                </el-table-column>
            <el-table-column
              prop="paramDescription"
               width="300"
              label="描述"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                
                  v-model="scope.row.paramDescription"
                  placeholder="请输入描述"
                  maxlength="50"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" fixed="right" >
              <template #default="scope">
                <el-popconfirm
                  title="删除后将无法恢复，请确认是否删除？"
                  confirm-button-type="danger"
                  icon="warning-filled" icon-color="#F56C6C"
                  width="250" placement="top-end"
                  @confirm="deleteRow(scope.$index,'apiInputParamters')">
                  <template #reference>
                    <el-button icon="delete" type="danger" text ></el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>

          </div>

          <div  class="box-card-radius-eight white-bg"  style="margin-top:20px;">
          <div align="left" class="step-title margin-botton-12 margin-top-24"><strong>出参配置</strong></div>
          <div align="left" class="table-btn__up margin-botton-12">
            <el-button type="primary" icon="plus" @click="addRow('apiOutputParamters')" >新增出参</el-button>
          </div>
          <el-table  
              ref="mappingDataList"
              :data="apiOutputParamters" style="width: 100%" max-height="285"> 
            <el-table-column
              prop="apiFieldCnname"
              label="参数中文名称"
               width="300"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                
                  v-model="scope.row.apiFieldCnname"
                  maxlength="50"
                  placeholder="请输入参数中文名称"
                ></el-input>
              </template>
            </el-table-column> 
           
             <el-table-column
              prop="apiFieldName"
              label="api字段名"
               width="300"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                
                  v-model="scope.row.apiFieldName"
                  maxlength="50"
                  placeholder="请输入参数名称"
                  
                ></el-input>
              </template>
            </el-table-column> 
            
            <el-table-column
              prop="apiFieldType"
              label="api字段类型"
               width="200"
              :show-overflow-tooltip="true"
            >
               <template #default="scope">
                <el-select
                
                  v-model="scope.row.apiFieldType"
                  placeholder="请选择数据类型"
                >
                 <el-option v-for="type in paramsTypes" :key="type.label" :label="type.label" :value="type.value" />
                  </el-select>
                  </template>
            </el-table-column> 
            <el-table-column
              prop="apiFieldLength"
              label="api字段长度"
               width="200"
            >
              <template #default="scope">
                <el-input-number
                
                  v-model="scope.row.apiFieldLength"
                ></el-input-number>
              </template>
            </el-table-column> 
            <el-table-column
              prop="sort"
              label="排序"
               width="200"
            >
              <template #default="scope">
                <el-input-number
                
                  v-model="scope.row.sort"
                ></el-input-number>
              </template>
            </el-table-column> 
            <el-table-column
              prop="paramFun"
               width="500"
              :show-overflow-tooltip="true"
            >
            <template #header>
              <span style="display:flex;align-items:center;">输出函数 
                <el-tooltip 
                content="函数用法:函数名称(#{参数名称},固定参数值,...) 例如:trim(#{username}),对参数变量username去除前后空格"
                placement="top"
                >
                <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
                </span>
            </template>
              <template #default="scope">
                 <el-autocomplete
                 
                    v-model="scope.row.paramFun"
                    :fetch-suggestions="querySearch"
                    value-key="functionName"
                    label="functionName"
                    clearable
                    style="width:100%;"
                  />
              </template>
            </el-table-column> 
            <el-table-column
                  prop="example"
                  label="示例值"
                  width="200"
                  :show-overflow-tooltip="true"
                >
                  <template #default="scope">
                    <el-input
                      clearable
                      v-model="scope.row.example"
                      placeholder="请输入"
                      maxlength="50"
                    ></el-input>
                  </template>
                </el-table-column>
            <el-table-column
              prop="fieldDescription"
              label="描述"
              width="300"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                  v-model="scope.row.fieldDescription"
                  placeholder="请输入描述"
                  maxlength="50"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" fixed="right" >
              <template #default="scope">
                <el-popconfirm
                  title="删除后将无法恢复，请确认是否删除？"
                  confirm-button-type="danger"
                  icon="warning-filled" icon-color="#F56C6C"
                  width="250" placement="top-end"
                  @confirm="deleteRow(scope.$index,'apiOutputParamters')">
                  <template #reference>
                    <el-button icon="delete" type="danger" text ></el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
           </div>
       </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancel()">取消</el-button>
            <el-button type="primary" @click="save()"> 确定 </el-button>
          </span>
        </template>
      </el-dialog>
</template>

<script>
import { ref } from 'vue';
import {request} from '@/api'
import {paramsTypesList} from '@/utils/constant'
import { ElMessage } from "element-plus";
import {useRoute} from 'vue-router'

export default {
    setup(){
        const route = useRoute()
        let paramsTypes = ref(paramsTypesList)
        let functionList = ref([])
        let dialogVisible = ref(false)
        let apiInputParamters = ref([])
        let apiOutputParamters = ref([])
        let apiId = ref(null)

        let show = async ()=>{
            const query = route.query
            let data = await request.getApiArrangeDetails({apiId:query.apiId})
            let iasmApiArrange = data.data.data.iasmApiArrange
            apiInputParamters.value = iasmApiArrange.apiInputParamters?JSON.parse(iasmApiArrange.apiInputParamters):[]
            apiOutputParamters.value = iasmApiArrange.apiOutputParamters?JSON.parse(iasmApiArrange.apiOutputParamters):[]
            apiId.value = iasmApiArrange.apiId
            dialogVisible.value = true
        }

        let cancel = ()=>{
            dialogVisible.value = false
        }

        let save = ()=>{
            request.editArrangeConfiguration({
                apiId:apiId.value,
                apiInputParamters:JSON.stringify(apiInputParamters.value),
                apiOutputParamters:JSON.stringify(apiOutputParamters.value)
            }).then(res=>{
                if(res.data.code === 200){
                   ElMessage.success('配置成功')
                   dialogVisible.value = false
                }
            })
        }

        let addRow = (type)=>{
            if(type === 'apiInputParamters'){
                apiInputParamters.value.push({
                    paramNameCm:'',
                    paramName:'',
                    paramValue:'',
                    paramType:'',
                    paramDescription:'',
                    sort:0,
                    paramIsMust:'N',
                    paramFun:'', 
                    example:''
                });
            }else{
                apiOutputParamters.value.push({
                    apiFieldCnname:'',
                    dbFieldName:'',
                    dbFieldType:'',
                    dbFieldLength:'',
                    apiFieldName:'',
                    apiFieldType:'',
                    apiFieldLength:'',
                    sort:0,
                    fieldDescription:'',
                    paramFun:'',
                    example:''
                })
            }
        }

        let deleteRow = (index,type)=>{
             if(type === 'apiInputParamters'){
                apiInputParamters.value.splice(index, 1);
             }else{
                apiOutputParamters.value.splice(index, 1);
             }
        }

        const querySearch = (queryString, cb) => {
            let results 
            let restaurants = functionList.value
            if(queryString && queryString !== 'null'){
                results = restaurants.filter(createFilter(queryString))
            }else{
                results = restaurants
            }
            cb(results)
        }

        const createFilter = (queryString) => {
            return (restaurant) => {
                return (
                restaurant.functionName.toLowerCase().indexOf(queryString.toLowerCase()) === 0
                )
            }
        }

        const getOverallFunction = ()=>{
            request.getOverallFunction({
                currentPage: 1,
                pageSize: 100,
                
                }).then((res) => {
                if (res?.data?.code === 200) {
                functionList.value = res.data.data?.result || []
                } else {
                ElMessage.error('失败')
                }
            });
        }

        getOverallFunction()

        
        return{
            dialogVisible,
            save,
            cancel,
            apiInputParamters,
            apiOutputParamters,
            addRow,
            deleteRow,
            show,
            paramsTypes,
            functionList,
            querySearch
        }
    }

}
</script>

