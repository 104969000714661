<template>
<div class="main">
        <div >
            <el-input
            class="input-transparent"
            v-model.trim="keyWord"
            placeholder="请输入密钥编码或密钥名称"
            clearable
            style="margin-bottom: 24px; width: 600px;"
            @keyup.enter="getKeyList"
            >
            <template #suffix>
                <el-icon class="icon-search" @click="getKeyList"><Search /></el-icon>
            </template>
        </el-input>
         <img
          src="../../assets/image/apiMarket/search-open.png"
          alt=""
          v-if="!onSearch"
          style="margin-bottom: 5px; cursor: pointer; margin-left: 20px"
          @click="onSearch = !onSearch"
        />
        <img
          src="../../assets/image/apiMarket/search-close.png"
          alt=""
          v-if="onSearch"
          style="margin-bottom: 5px; cursor: pointer; margin-left: 20px"
          @click="onSearch = !onSearch"
        />
        </div>
         <el-form
        v-if="onSearch"
        :inline="true"
        v-model="searchForm"
        style="text-align: left"
      >
        <el-form-item label="密码类型：">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{keyTypeLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
           <template #dropdown>
            <el-dropdown-menu >
              <el-dropdown-item @click="changeKeyType('','全部')">全部</el-dropdown-item>
              <el-dropdown-item v-for="cate in keyTypeOptionMap" :key="cate.label" @click="changeKeyType(cate.value,cate.label)">{{cate.label}}</el-dropdown-item>
            </el-dropdown-menu>
            </template>
          </el-dropdown>
          
        </el-form-item>
        
      
      </el-form>
        <div class="box-card-radius-eight white-bg">
          <el-button
            type="primary"
            icon="plus"
            style="color: #fff;margin-bottom:20px;"
            @click="handleAdd"
            >新增密钥</el-button
          >
          <el-table 
            :data="tableData"
            style="width: 100%;margin-bottom: 20px;"
            row-key="secretkeyId"
            border>  
            <el-table-column
            prop="secretkeyName"
            label="密钥名称"
            width="150">
            </el-table-column>
            <el-table-column
            prop="secretkeyCode"
            label="密钥编码"
            width="150">
            </el-table-column>
            <el-table-column
            prop="secretkeyType"
            label="密码类型"
            width="150">
            <template #default="scope">{{keyTypeOptionMap.find(item =>{return item.value === scope.row.secretkeyType}).label || '-' }} </template>
            
            </el-table-column>
            <el-table-column
            prop="secretkeyStatus"
            label="密钥状态"
            width="150"
            align="center">
            <template #default="scope"> 
               <el-switch
                    v-model="scope.row.secretkeyStatus"
                    @change="changeStatus(scope.row)"
                    :active-value="1"
                    :inactive-value="2"
                    class="main-el-switch"
                    active-text="正常"
                    inactive-color="rgba(0, 0, 0, 0.25)"
                    inactive-text="禁用"
                ></el-switch>
            </template>
            </el-table-column>

            <el-table-column
            prop="secretkeyStarttime"
            label="有效开始时间"
            width="200">
            </el-table-column>

            <el-table-column
            prop="secretkeyEndtime"
            label="有效期结束时间"
            width="200">
            </el-table-column>

             <el-table-column
            prop="createBy"
            label="创建人"
            width="200"
            >
            <template #default="scope"> {{scope.row.createNickName + ' ' + scope.row.createUserNumber}}</template>
            </el-table-column>

             <el-table-column
              prop="createTime"
              label="创建时间"
              width="200"
              >
            </el-table-column>

             <el-table-column
              label="最后修改人"
              width="200"
              >
              <template #default="scope"> {{scope.row.updateNickName + ' ' + scope.row.updateUserNumber}}</template>
            </el-table-column>
            
             <el-table-column
            prop="updateTime"
            label="最后修改时间"
            width="200">
            </el-table-column>
            
            <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="150">
              <template #default="scope">
                <el-tooltip content="查看">
                <el-button
                  size="medium"
                  icon="View"
                  type="text"
                  @click="handleView(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="编辑">
                <el-button
                  size="medium"
                  icon="Edit"
                  type="text"
                  @click="handleEdit(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="删除">
                <el-button
                  @click="handleDelete(scope.row)"
                  size="medium"
                  icon="delete"
                  type="text"
                  style="color: #f56c6c"
                ></el-button>
              </el-tooltip>
            </template>
            </el-table-column>
        </el-table>
            <!--分页组件-->
      <div style="margin: 20px 0;overflow:hidden;">
         <el-pagination background small
           style="float:right;"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          :page-sizes="[10,20,50,100]"
          show
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
    </div>
        </div>
        
 </div>
</template>

<script>
import { ref, onMounted } from "vue";
import {  useRouter } from 'vue-router';
import {request} from '@/api'
import { ElMessage,ElMessageBox} from "element-plus";
import { WarningFilled } from "@element-plus/icons-vue";
import {keyTypeOptions} from '@/utils/constant'


export default {
  name: `KeyManagement`,
  setup() {
    const router = useRouter()
    let keyWord = ref(null)
    let secretkeyType = ref(null)
    let onSearch = ref(false);
    let tableData =  ref([])
    let page = ref({
      currentPage: 1,
      pageSize: 10,
    })
    let total = ref(10)
    let keyTypeLabel = ref('全部')
    const keyTypeOptionMap = ref(keyTypeOptions)

    const getKeyList = ()=>{
      request.getKeyList({
          ...page.value,
          secretkeyType:secretkeyType.value,
          keyWord:keyWord.value
          
        }).then((res) => {
        if (res?.data?.code === 200) {
          tableData.value = res.data.data?.list || []
          total.value =  res.data.data?.total
        } else {
          ElMessage.error('失败')
        }
      });
    }

    const handleSizeChange = (val) => {
      page.value.pageSize = val;
      getKeyList();
    }
    const handleCurrentChange = (val) => {
      page.value.currentPage = val;
      getKeyList()
    }
    
    const changeKeyType = (value,label)=>{
      console.log(value)
       secretkeyType.value = value
       keyTypeLabel.value = label
       getKeyList()
    }

    const changeStatus = (row)=>{
      request.updateStatus({
          secretkeyId: row.secretkeyId, secretkeyStatus: row.secretkeyStatus
        }).then((res) => {
        if (res?.data?.code === 200) {
          ElMessage.success(res.data.data)
          page.value.currentPage = 1
          getKeyList();
        } else {
          ElMessage.error(res.data.msg)
        }
      });
    }

    const handleView =  (row)=>{
         router.push({
          path: '/home/DataResourceCenter/keyManagement/detail',
          query: {
            operation:'view',
            secretkeyCode:row.secretkeyCode,
            secretkeyId:row.secretkeyId
          }
        })
    }

    const handleEdit = (row)=>{
         router.push({
          path: '/home/DataResourceCenter/keyManagement/detail',
          query: {
            operation:'edit',
            secretkeyId:row.secretkeyId
          }
        })
    }

    const handleDelete = (row) => {
      ElMessageBox.confirm(`确定删除当前密钥：` + row.secretkeyName, {
        icon: WarningFilled,
        type: "warning",
      }).then(() => {
        request
          .deleteSecretKey({
            secretkeyId: row.secretkeyId,
          })
          .then((res) => {
            if (res?.data?.code === 200) {
              ElMessage.success("删除成功");
              page.value.currentPage = 1
              getKeyList();
            } else {
              ElMessage.error("失败");
            }
          });
      }).catch(()=>{});
    };

    const handleAdd = ()=>{
         router.push({
          path: '/home/DataResourceCenter/keyManagement/detail',
          query: {
            operation:'add',
          }
        })
    }

    onMounted(()=>{
      getKeyList()
    })

    return {
      keyWord,
      page,
      total,
      getKeyList,
      handleView,
      handleEdit,
      handleAdd,
      tableData,
      onSearch,
      keyTypeOptionMap,
      keyTypeLabel,
      changeKeyType,
      secretkeyType,
      handleSizeChange,
      handleCurrentChange,
      changeStatus,
      handleDelete
    }
  }
}
</script>

<style  lang="less">
.input-transparent {
    border-radius: 20px;
    font-size: 16px;
    .el-input__wrapper {
        background: transparent;
    }

}
.icon-search{
    cursor: pointer;
}

 .main-el-switch{
    position: relative;
    .el-switch__core {
    height: 24px;
    width: 60px;
    border-radius: 12px;
    &:after {
    left: 4px;
    top: 10px;
    }
    }
    &.el-switch {
    .is-active {
    .el-switch__core {
    &:after {
    margin-left: -20px;
    left: 100%;
    }
    }
    }
    }
    
    .el-switch__label--left {
    opacity: 0;
    }
    .el-switch__label--right {
    opacity: 0;
    }
    .is-active {
      opacity: 1;
    }
    .el-switch__label {
    position: absolute;
    top: 5px;
    }
    .el-switch__label--left {
    right: 0;
    color: #fff!important;
    z-index: 1;
    margin-right: 8px;
    }

    .el-switch__label--right {
    left: 0;
    color: #fff!important;
    z-index: 1;
    margin-left: 8px;
    }
}
//  /*修改展开按钮的样式 start*/
//     /*1.取消原本展开的旋转动效*/
//     /deep/.el-table__expand-icon{
//         -webkit-transform: rotate(0deg);
//         transform: rotate(0deg);
//     }
//     /*2.展开按钮未点击的样式是加号带边框*/
//      .el-table__expand-icon .el-icon:before{
//         content: "\e6d9";
//         border: 1px solid #ccc;
//         padding: 2px;
//     }
//     /*3.展开按钮点击后的样式是减号带边框*/
//     .el-table__expand-icon--expanded .el-icon:before{
//         content: "\e6d8";
//     }

</style>
<style lang="less" scoped>
.main{
    padding:20px;
    text-align: left;
    .toolbar{
      margin-bottom: 24px;
    }
}
</style>