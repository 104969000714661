<template>
    <div class="approval_list_detail">
        <List :tabType="tabType"></List>
    </div>
</template>

<script>
import List from './list.vue'
export default {
    props: {
        tabType: {
            type: String,
            default: () => '1'
        }
    },
    components: {
        List
    }
}
</script>