<template>
    <div class="inline-relation-match">
        <div class="operation">
            <div class="btn-left">
                <el-select v-model="relationValue">
                    <el-option v-for="(item, index) in inLineRelationData.joinOptions" :key="index" :value="item.value" :label="item.label" />
                </el-select>
            </div>
            <div class="btn-right">
                <el-button type="primary" @click="addRelation">添加关系</el-button>
                <el-button @click="addGroup">添加关系组</el-button>
            </div>
        </div>

        <h5 align="left">关系</h5>
        <el-table :data="inLineRelationData.tableData.filter(el => !el.groupList)" :show-header="false" max-height="385" :show-overflow-tooltip="true">
            <el-table-column prop="operator" label="链接符号" width="80" align="center">
                <template #default="scope">
                    <span class="linkCharacter" @click="changeLinkCharacter(scope.row)">{{ scope.row.operator }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="source" label="左表"></el-table-column>
            <el-table-column prop="fromColumn" label="字段名">
                <template #default="scope">
                    <el-select v-model="scope.row.fromColumn" filterable clearable placeholder="请选择字段">
                        <el-option v-for="(item, index) in inLineRelationData.sourceColumnsOptions" :key="index" :label="item.columnName"
                            :value="item.columnName">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column prop="express" label="连接符">
                <template #default="scope">
                    <el-select v-model="scope.row.express" clearable placeholder="请选择表达式">
                        <el-option v-for="(item, index) in inLineRelationData.expressOptions" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column prop="target" label="右表"></el-table-column>
            <el-table-column prop="toColumn" label="字段名">
                <template #default="scope">
                    <el-select v-model="scope.row.toColumn" filterable clearable placeholder="请选择字段">
                        <el-option v-for="(item, index) in inLineRelationData.targetColumnsOptions" :key="index" :label="item.columnName"
                            :value="item.columnName">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="60">
              <template #default="scope">
                <el-button v-if="scope.$index > 0" icon="minus" circle type="danger" size="small" @click="deleteRow(scope.$index)"></el-button>
              </template>
            </el-table-column>
        </el-table>

        <h5 align="left" v-if="inLineRelationData.tableData.filter(el => !!el.groupList).length > 0">关系组</h5>
        <div v-for="(item, index) in inLineRelationData.tableData.filter(el => !!el.groupList)" :key="index">
            <el-row class="group_table">
                <el-col :span="3">
                    <span @click="changeLinkCharacter(item)">{{ item.operator }}</span>
                </el-col>
                <el-col :span="21">
                    <el-table :data="item.groupList" :show-header="false" max-height="385" :show-overflow-tooltip="true" :row-style="rowStyle">
                        <el-table-column prop="operator" label="链接符号" width="80" align="center">
                            <template #default="scope">
                                <span class="linkCharacter" @click="changeLinkCharacter(scope.row)">{{ scope.row.operator }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="source" label="左表"></el-table-column>
                        <el-table-column prop="fromColumn" label="字段名">
                            <template #default="scope">
                                <el-select v-model="scope.row.fromColumn" filterable clearable placeholder="请选择字段">
                                    <el-option v-for="(item, index) in inLineRelationData.sourceColumnsOptions" :key="index" :label="item.columnName"
                                        :value="item.columnName">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column prop="express" label="连接符">
                            <template #default="scope">
                                <el-select v-model="scope.row.express" clearable placeholder="请选择表达式">
                                    <el-option v-for="(item, index) in inLineRelationData.expressOptions" :key="index" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column prop="target" label="右表"></el-table-column>
                        <el-table-column prop="toColumn" label="字段名">
                            <template #default="scope">
                                <el-select v-model="scope.row.toColumn" filterable clearable placeholder="请选择字段">
                                    <el-option v-for="(item, index) in inLineRelationData.targetColumnsOptions" :key="index" :label="item.columnName"
                                        :value="item.columnName">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="60">
                        <template #default="scope">
                            <!-- v-if="scope.$index > 0"  -->
                            <el-button icon="minus" circle type="danger" size="small" @click="deleteGroupRow(item.groupList, scope.$index, index)"></el-button>
                        </template>
                        </el-table-column>
                    </el-table>
                    <el-button size="small" @click="addGroupRel(item.groupList)" style="margin-top: 6px;">新增关系</el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
export default {
    name: 'inLineRelationMath',
    props: {
        inlineRelations: Object,
        state: Object,
        inlineRelList: Array,
        // inlineGroupList: Array,
        // relationship: String
    },
    setup(props) {
        const store = useStore()
        const { selectedTableObj } = store.state
        const p = props
        const { source, target } = p.state.inlineRelations

        const relationValue = ref('')

        const express = ['=', '>', '<', '>=', '<=', '<>']
        const inLineRelationData = reactive({
            joinOptions: [
                { value: 'INNER JOIN', label: '内联' },
                { value: 'LEFT JOIN', label: '左联' },
                { value: 'RIGHT JOIN', label: '右联' }
            ],
            tableData: [
                { operator: '', source, fromColumn: '', express: '=', target, toColumn: '' }
            ],
            sourceColumnsOptions: selectedTableObj[source] || [], // source 表下字段集合
            expressOptions: express.map(el => ({ value: el, label: el })), // 条件判断运算符
            targetColumnsOptions: selectedTableObj[target] || [] // target 表下字段集合
        })

        const changeLinkCharacter = (row) => {
            const { operator } = row
            if (operator) {
                row.operator = operator === 'and' ? 'or' : 'and'
            }
        }

        const addRelation = () => {
            inLineRelationData.tableData.push({
                operator: 'and', source, fromColumn: '', express: '=', target, toColumn: ''
            })
        }

        const addGroupRel = (item) => {
            item.push({
                operator: 'and', source, fromColumn: '', express: '=', target, toColumn: ''
            })
        }

        const addGroup = () => {
            const item = {
                operator: '', source, fromColumn: '', express: '=', target, toColumn: ''
            }
            inLineRelationData.tableData.push({
                groupList: [item],
                operator: 'and'
            })
        }

        const deleteRow = (index) => {
            let delIndex = -1
            inLineRelationData.tableData.forEach((el, idx) => {
                if (!el.groupList) {
                    delIndex++
                }
                if (delIndex === index) {
                    inLineRelationData.tableData.splice(idx, 1);
                }
            })
            const list = inLineRelationData.tableData.filter(el => !el.groupList)
            if (list.length > 0) {
                list[0].operator = ''
            }
        }

        const deleteGroupRow = (arr, index, itemidx) => {
            if (arr.length > 1) {
                arr.splice(index, 1);
                arr[0].operator = ''
            } else {
                let delIndex = -1
                inLineRelationData.tableData.forEach((el, index) => {
                    if (el.groupList) {
                        delIndex++
                    }
                    if (delIndex === itemidx) {
                        inLineRelationData.tableData.splice(index, 1);
                    }
                })
                
            }
        }

        const rowStyle = () => {
            return {
                background: '#cccccc20'
            }
        }

        onMounted(() => {
            nextTick(() => {
                const arr = props.inlineRelList || []
                if (Array.isArray(arr) && arr.length > 0) {
                    const list = props.inlineRelList.filter(el => el.fromTable === source && el.toTable === target)
                    if (list.length > 0) {
                        inLineRelationData.tableData = list[0].columnList.map(el => {
                            el.source = list[0].fromTable
                            el.target = list[0].toTable
                            return el
                        })
                        relationValue.value = list[0].join
                    } else {
                        inLineRelationData.tableData = [{ operator: '', source, fromColumn: '', express: '=', target, toColumn: '' }]
                        relationValue.value = ''
                    }
                }
            })
            
        })

        return {
            relationValue,
            inLineRelationData,
            changeLinkCharacter,
            addRelation,
            addGroupRel,
            addGroup,
            deleteRow,
            deleteGroupRow,
            rowStyle
        }
    }
}
</script>

<style lang="less" scoped>
    .operation {
        display: flex;
        justify-content: space-between;
        background: #fff;
        padding: 12px;
        border-bottom: 1px solid #dcdfe6;
    }
    .group_table {
        align-items: center;
        border: 1px solid #ebeef5;
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-radius: 8px;
        background: #cccccc20;
    }
</style>