
function formatDate (row, column) {
    // 获取单元格数据
    let data = row[column.property];
    if(data == null) {
        return null;
    }
    let dt= new Date(data);
    let y= dt.getFullYear();
    let m=(dt.getMonth()+1+'').padStart(2,'0');
    let d=(dt.getDate()+'').padStart(2,'0');
    let hh =(dt.getHours()+'').padStart(2,'0');
    let mm =(dt.getMinutes()+'').padStart(2,'0');
    let ss=(dt.getSeconds()+'').padStart(2,'0');
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
}

function formatDateWithoutTime (row, column) {
    // 获取单元格数据
    let data = row[column.property];
    if(data == null) {
        return null;
    }
    let dt= new Date(data);
    let y= dt.getFullYear();
    let m=(dt.getMonth()+1+'').padStart(2,'0');
    let d=(dt.getDate()+'').padStart(2,'0');
    return `${y}-${m}-${d}`;
}

export default {
    formatDate,formatDateWithoutTime
}

export const  dispatchEventStroage= ()=> {
    const signSetItem = localStorage.setItem
    localStorage.setItem = function (key, val) {
        let setEvent = new Event('setItemEvent')
        setEvent.key = key
        setEvent.newValue = val
        window.dispatchEvent(setEvent)
        signSetItem.apply(this, arguments)
    }
}

export const dispatchEventSessionStroage=()=>{
    const signSetItem = sessionStorage.setItem
    sessionStorage.setItem = function (key, val) {
        let setEvent = new Event('changeSession')
        setEvent.key = key
        setEvent.newValue = val
        window.dispatchEvent(setEvent)
        signSetItem.apply(this, arguments)
    }
}

