import { reactive, onMounted, nextTick } from 'vue'
import G6, { Graph } from '@antv/g6'
import { useStore } from 'vuex'

const fittingString = (str, maxWidth, fontSize) => {
    const ellipsis = '...'
    const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0]
    let currentWidth = 0
    let res = str
    const pattern = new RegExp('[\u4E00-\u9FA5]+') // distinguish the Chinese charactors and letters
    str.split('').forEach((letter, i) => {
      if (currentWidth > maxWidth - ellipsisLength) return
      if (pattern.test(letter)) {
        // Chinese charactors
        currentWidth += fontSize
      } else {
        // get the width of single letter according to the fontSize
        currentWidth += G6.Util.getLetterWidth(letter, fontSize)
      }
      if (currentWidth > maxWidth - ellipsisLength) {
        res = `${str.substr(0, i)}${ellipsis}`
      }
    })
    return res
}

const getG6Config = container => {
    const width = container.offsetWidth,
      height = container.offsetHeight,
      nodeWidth = 200,
      nodeHeight = 50
    return {
      container: container,
      width,
      height,
      maxZoom: 2,
      minZoom: 0.5,
      defaultNode: {
        // 默认节点配置
        type: 'modelRect',
        size: [nodeWidth, nodeHeight],
        color: '#E8E8E8',
        labelCfg: {
          style: {
            fontSize: 13,
          },
        },
        // logoIcon: {
        //   show: true,
        //   img: IconDataSet,
        //   width: 25,
        //   height: 25,
        //   offset: -10,
        // },
        stateIcon: {
          show: false
        },
        style: {
          cursor: 'move',
        },
        // 定义四边的锚点，需要通过linkPoints显示出来
        anchorPoints: [
          [0.5, 0],
          [1, 0.5],
          [0, 0.5],
          [0.5, 1],
        ],
        linkPoints: {
          top: true,
          bottom: true,
          left: true,
          right: true,
          fill: '#fff',
          size: 7,
          stroke: '#1aa4ff',
        },
      },
      defaultEdge: {
        // 默认连线配置
        label: '内联', // 默认内联
        style: {
          endArrow: {
            path: G6.Arrow.triangle(5, 10, 5), // 绘制箭头
            d: 5, // 绘制箭头偏移
            fill: '#1aa4ff',
          },
          cursor: 'pointer',
          stroke: '#1aa4ff',
          fill: '#1aa4ff',
          lineWidth: 2,
          lineAppendWidth: 10, // 边响应鼠标事件时的检测宽度，当 lineWidth 太小而不易选中时，可以通过该参数提升击中范围
        },
      },
      modes: {
        // 交互模式，管理behavior交互行为
        // 配置模式
        default: [
          {
            // 启用拖拽
            type: 'drag-node',
            enableDelegate: true, // 拖动过程中是否使用方框代替元素的直接移动
            shouldEnd() {
              return true
            }
          },
          {
            // 启用连线
            type: 'create-edge',
            trigger: 'click',
            shouldEnd(evt) {
              // 两个节点之间只能连接一条线
              if (evt.item._cfg.edges.length !== 0) return false
              // 节点不能连接本身
              return true
            },
          },
        ]
      }
    }
  }

export function useG6(state) {
  const store = useStore()
  const { nodes, edges } = store.state.canvasData
    // 图的节点和连线数据
  const graphData = reactive({
    // 节点
    nodes: nodes || [],
    // 边
    edges: edges || []
  })

  // 不能放在reactive里面，放了，会导致连线出来不能取消，proxy导致的bug
  let graph = Graph

  // 监听各种事件
  const listen = () => {
    graph.on('node:dragstart', () => {})
    graph.on('node:dragend', evt => {
      // 拖拽后记录新的坐标
      store.dispatch('changePosition', {
        id: evt.item._cfg.id,
        x: evt.x,
        y: evt.y,
      })
    })
    graph.on('edge:click', evt => {
      // 点击连线显示弹框
      const { source, target } = evt.item._cfg.model
      state.inlineRelations.source = source
      state.inlineRelations.target = target
      state.show.relationMatchVisible = true
    })
    graph.on('aftercreateedge', e => {
      // 连线后更改连线数据
      graphData.edges.push({
        source: e.edge._cfg.model.source,
        target: e.edge._cfg.model.target,
      })
      return true
    })
  }

  const renderCanvas = () => {
    // 截取掉节点名称，多余的显示...
    graphData.nodes.forEach(node => {
      node.label = fittingString(node.label, 130, 13)
    })
    graph.data(graphData)
    graph.render()
    // 暂存画布内数据，其余组件使用
    store.dispatch('updataCanvasData', graphData)
  }

  onMounted(async () => {
    // 等待容器渲染完，不然获取的宽高可能为0
    await nextTick()
    // 节点右键菜单
    const contextMenu = new G6.Menu({
        // 在哪些类型的元素上响应
        itemTypes: ['node', 'edge'],
        // 需要加上父级容器的 padding-left 16 与自身偏移量 10
        offsetX: 16 + 10,
        // 需要加上父级容器的 padding-top 24 、画布兄弟元素高度、与自身偏移量 10
        offsetY: 0,
        getContent(evt) {
          const itemType = evt.item.getType()
          if (itemType == 'node') {
            return `
                  <ul class='contextMenu'>
                      <li >删除节点</li>
                  </ul>`
          } else if (itemType == 'edge') {
            return `
                  <ul class='contextMenu'> 
                      <li >删除连线</li>
                  </ul>`
          }
        },
        handleMenuClick: (evt, item) => {
          const id = item._cfg.model.id
          const { source, target } = item._cfg.model
          switch (evt.innerText) {
            case '查看数据集':
              break
            case '删除节点':
                // 1.获取节点id  2.删除节点,删除节点对应GUI数据结构   3.删除连线，删除连线对应GUi数据结构 4.重绘
                // store.state.GuiData.dragSetData = store.state.GuiData.dragSetData.filter(
                //     el => el.attributes.tableName != id
                // )
                graphData.nodes = graphData.nodes.filter(el => el.id != id)
                if (graphData.edges.length > 0)
                    graphData.edges = graphData.edges.filter(
                        el => el.source !== id && el.target !== id
                    )

                state.inlineRelList = state.inlineRelList.filter(el => {
                  if (el.fromTable !== id && el.toTable !== id) {
                    return el
                  }
                })
                renderCanvas()
              break
            case '删除连线':
              if (graphData.edges.length > 0)
                graphData.edges = graphData.edges.filter(
                  el => !(el.source == source && el.target == target)
                )
                state.inlineRelList = state.inlineRelList.filter(el => {
                  if (el.fromTable !== source && el.toTable !== target) {
                    return el
                  }
                })
              renderCanvas()
              break
            default:
              break
          }
        }
    })
    // 根据容器宽高动态设置画布宽高
    graph = new G6.Graph({
        ...getG6Config(state.continer),
        plugins: [contextMenu],
    })
    listen()
    renderCanvas()
  })
  

  return {
    graphData,
    renderCanvas
  }
}