<template>
    <div class="approval_config white-bg box-card-radius-eight" >
        <h4>基本信息</h4>
        <el-form label-width="100px" ref="formRef" :rules="validateForm" :model="form">
            <el-form-item prop="modelCode" label="模板编码">
                <el-input v-model="form.modelCode" autocomplete="off" disabled maxlength="20" show-word-limit>
                    <template #prepend>Process_iods</template>
                </el-input>
            </el-form-item>
            <el-form-item prop="modelName" label="模板名称">
                <el-input v-model="form.modelName" autocomplete="off" disabled maxlength="20" show-word-limit />
            </el-form-item>
            <el-form-item prop="callbackUrl" label="回调URL">
                <el-input v-model="form.callbackUrl" autocomplete="off"  />
            </el-form-item>
            <el-form-item label="模板描述">
                <el-input :rows="4" type="textarea" maxlength="1000" show-word-limit v-model="form.modelDesc" />
            </el-form-item>
        </el-form>

        <h4>审批设置</h4>
        <el-button type="primary" @click="add" style="margin-bottom: 12px;">新 增</el-button>
        <el-form ref="approvalTabRef" :model="tableData" :rules="tableRules" class="table-form">
            <el-table :data="tableData.list" stripe style="width: 100%" v-loading="loading">
                <el-table-column prop="no" label="序号" width="60" align="center" fixed type="index" />
                <el-table-column prop="nodeCode" label="节点编码" :show-overflow-tooltip="true">
                    <template #default="scope">
                        <el-form-item :prop="'list.'+scope.$index+'.nodeCode'" :rules="tableRules.nodeCode">
                            <el-input v-model="scope.row.nodeCode" placeholder="请输入"></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="nodeName" label="节点名称" :show-overflow-tooltip="true">
                    <template #default="scope">
                        <el-form-item :prop="'list.'+scope.$index+'.nodeName'" :rules="tableRules.nodeName">
                            <el-input v-model="scope.row.nodeName" placeholder="请输入"></el-input>
                        </el-form-item>
                    </template></el-table-column>
                <el-table-column prop="nodeType" label="节点类型" :show-overflow-tooltip="true">
                    <template #default="scope">
                        <el-select v-model="scope.row.nodeType"  @change="changeNodeType(scope)">
                            <el-option value="01" label="单人审批节点">单人审批节点</el-option>
                            <el-option value="02" label="多人审批节点">多人审批节点</el-option>
                            <el-option value="03" label="接口动态获取">接口动态获取</el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column prop="executeType" label="执行类型" :show-overflow-tooltip="true">
                    <template #default="scope">
                        <el-form-item :prop="'list.'+scope.$index+'.executeType'" :rules="tableRules.executeType">
                            <el-select v-model="scope.row.executeType" >
                                <el-option value="并行any">并行any</el-option>
                                <el-option value="并行all">并行all</el-option>
                            </el-select>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="auditName" label="审批人" :show-overflow-tooltip="true">
                    <template #default="scope">
                        <!-- <el-select
                        v-model="form.apiOwner"
                        @change="changeDesignUser"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入搜索关键字"
                        :remote-method="getdesignUser"
                        style="width: 100%"
                    >
                        <el-option
                        v-for="item in designUserList"
                        :key="item.userId"
                        :label="item.userName + ' ' +item.account"
                        :value="item.userId"
                        /> -->
                    <!-- </el-select> -->
                        <el-select 
                            v-if="scope.row.nodeType == '03'"
                            v-model="scope.row.auditName" 
                            filterable 
                            style="width: 100%"
                            placeholder="请输入搜索关键字">
                           <el-option value="api_owner" label="API责任人"></el-option>
                            <el-option value="appmanager_owner" label="应用管理员"></el-option>
                            <el-option value="business_owner" label="业务分类责任人"></el-option>
                        </el-select>
                         <el-select 
                            v-else
                            v-model="scope.row.auditName" 
                            filterable 
                            style="width: 100%"
                            placeholder="请输入搜索关键字">
                            <el-option v-for="item in tableData.auditOp" :key="item.userId" :value="item.userId" :label="item.userName+ ' ' +item.account"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column prop="operation" label="操作" width="100" fixed="right">
                    <template #default="scope">
                    <el-button size="small" icon="delete" type="danger" link @click="handleUserDelete(scope.row)" v-has="'hidden&basicSetup.user.delete'"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>

        <div class="operation-footer" align="center" style="margin-top: 24px;">
            <!-- <el-button @click="submit">确 认</el-button> -->
            <el-button type="primary" @click="publicNodes">发 布</el-button>
        </div>
    </div>
</template>
<script>
  import { reactive, ref, onMounted} from 'vue';
  import {request} from '@/api'
  import { ElMessage,ElMessageBox } from "element-plus";
  import {  useRoute,useRouter } from 'vue-router';
  export default {
    name: "BaseConfigUser",
    setup() {
      const route = useRoute()
      const router = useRouter()
      const tableData = reactive({ //表格参数
        list: [],
        auditOp: [] // 审批人列表
      });

      let loading = ref(true)
      const formRef = ref()
      const approvalTabRef = ref()

      let form = ref({});

      const tableRules = reactive({
        nodeCode: [{ required: true, trigger: 'blur', message: '节点编码必填' }],
        nodeName: [{ required: true, trigger: 'blur', message: '节点名称必填' }],
        executeType: [{ required: true, trigger: 'change', message: '执行类型必填' }]
      })
  
     
      const add = () => {
        // if (tableData.list.length > 0) {
        //     ElMessage.warning('只能存在一个节点！')
        //     return
        // }
        tableData.list.push({
            id:Date.now(),
            nodeCode: '',
            nodeName: '',
            nodeType: '03',
            executeType: '',
            auditName: ''
        })
      }

      const changeNodeType = ({ row }) => {
        const { nodeType } = row
        if (nodeType === '01') {
            row.executeType = '默认'
        }
      }

      const validateLen = (rule, value, callback) => {
        if (value.length < 6) {
            callback(new Error('模板编码总长度不能小于18位'))
        } else {
            callback()
        }
      }

      const validateForm = reactive({
        modelCode: [
            { required: true, message: '请输入模板编码', trigger: 'blur'  },
            { validator: validateLen, trigger: "change" }
        ],
        modelName: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        callbackUrl: [{ required: true, message: '请输入模板回调URL', trigger: 'blur' }]
      });

      const handleUserDelete = (row) => {
        // let accountStr = row.callbackUrl;
        ElMessageBox.confirm('确定删除吗？')
          .then(() => {
            tableData.list = tableData.list.filter(item=>{
                return item.id !== row.id
            });
          }).catch(() => { })
      };

      const formValid = (callback) => {
        formRef.value.validate((valid) => {
            if (valid) {
                if (tableData.list.length === 0) {
                    ElMessage.error('审批设置必填！')
                } else {
                    approvalTabRef.value.validate((_valid) => {
                        if(_valid) {
                            callback()
                        }
                    })
                }
            }
        })
      }

      const submit = () => {
        formValid(()=> {
            const params = {
                ...form.value,
                modelConfList: tableData.list,
                appId: localStorage.getItem("appId"),
                id:route.query.id?route.query.id:null
            }
            Object.assign(params, { modelCode: `Process_iods${form.value.modelCode}` })
            request.publicModel(params).then(res => {
                const {code, msg} = res.data
                if (res.status === 200 && code === 200) {
                    ElMessage.success(msg)
                    router.push('/home/BaseConfig/ApprovalConfig/list')
                } else {
                    ElMessage.error(msg)
                }
            })
        })
      }

      const publicNodes = () => {
        formValid(()=> {
            const params = {
                ...form.value,
                modelConfList: tableData.list,
                appId: localStorage.getItem("appId"),
                id:route.query.id?route.query.id:null
            }
            Object.assign(params, { modelCode: `Process_iods${form.value.modelCode}` })
            request.saveModelNode(params).then(res => {
                const {code, msg} = res.data
                if (res.status === 200 && code === 200) {
                    ElMessage.success('发布成功！')
                    router.push('/home/BaseConfig/ApprovalConfig/list')
                } else {
                    ElMessage.error(msg)
                }
            })
        })
      }

    const findModelNodeDetails = (id)=>{
        request.findModelNodeDetails({id}).then(res => {
                const {code, data, msg} = res.data
                const {modelCode,modelName,callbackUrl,modelDesc,modelType} = data
                if ( code === 200) {
                    tableData.list = data.modelConfList || []
                    form.value = {modelCode,modelName,callbackUrl,modelDesc,modelType}
                    form.value.modelCode = data.modelCode.substr(12)
                } else {
                    ElMessage.error(msg)
                }
            }).finally(() => {
            loading.value = false
        })
        
    }

    const getAuditOp = (query) => {
        request
          .selectUserByParams({
            likeData: query,
            appId: localStorage.getItem("appId"),
            currentPage: 1,
            pageSize: 10000,
          })
          .then((res) => {
            if (res?.data?.code === 200) {
              tableData.auditOp = res.data.data.result;
            }
          });
    };
    

    onMounted(()=>{
        if(route?.query?.id){
            findModelNodeDetails(route.query.id)
        }
        getAuditOp()
    })
      return {
        getAuditOp,
        findModelNodeDetails,
        loading,
        formRef,
        approvalTabRef,
        tableRules,
        tableData,
        form,
        validateForm,
        handleUserDelete,
        add,
        changeNodeType,
        submit,
        publicNodes
      }
    },
  }
</script>
  
<style lang="less" scoped>
.approval_config{
    text-align: left;
}
    .table-form {
        .el-table .cell {
            overflow: visible;
        }
        .el-form-item {
            margin-bottom: 0;
        }
        .el-form-item__error {
            padding-top: 0;
            margin-top: -3px;
        }
    }
</style>