import { HtmlNodeModel, HtmlNode } from '@logicflow/core';
import defaultImg from '@/assets/image/defaultImg.png';
import computingActiveImg from '@/assets/image/computingActiveImg.png';
import inputActiveImg from '@/assets/image/inputActiveImg.png';
import outputActiveImg from '@/assets/image/outputActiveImg.png';
import seniorActiveImg from '@/assets/image/seniorActiveImg.png';


function getActiveImgName (id){
   switch(id) {
    case '1044553856337584129' :
    case '1047522392471248897' : 
    case '1047522392471248899' : 
    case '1020030598129131521' : 
    case '1020030598129131522' : 
    case '1107684048291241984' : 
    case '1107684048291241985' : 
    case '1107684048291241986' : 
    return inputActiveImg 
    case '1020031997642547200' :
    case '1043175257428729857' : 
    case '1045642272554885120' : 
    case '1045642272554885122' : 
    case '1045642272554885125' : 
    case '1047522392471248904' : 
    case '1047522392471248905' : 
    case '1105523677946191873' : 
    case '1105523677946191874' : 
    case '1105523677946191875' : 
    case '1105523677946191876' : 
    case '1105523677946191877' : 
    case '1105523677946191878' : 
    case '1105523677946191879' : 
    return computingActiveImg
    case '1020032035215122432' : 
    case '1043117826065571840' : 
    case '1043175257428729856' : 
    case '1047522392471248900' : 
    case '1047522392471248901' : 
    case '1047522392471248902' : 
    case '1047522392471248903' : 
    case '1101165598618165248' : 
    case '1101165598618165249' : 
    case '1101165598618165251' : 
    case '1108325163109457920' : 
    case '1112805840152436736' : 
    case '1108325163109457921' : 
    return outputActiveImg
    case '1044553856337584130' :
    case '1045642272554885121' : 
    case '1045642272554885126' : 
    case '1101165598618165250' : 
    case '1101165598618165252' : 
    case '1101165598618165253' : 
    case '1101165598618165254' : 
    case '1122485270181322752' : 
    case '1122487838705983500' :
    return seniorActiveImg
    default :
    return inputActiveImg

   }
}

/**
 *  自定义html节点,拖拉显示节点图片
 */

class UmlModel extends HtmlNodeModel {
  setAttributes() {
    this.text.editable = true; // 禁止节点文本编辑
    // 设置节点宽高和锚点
    const width = 60;
    const height = 60;
    this.width = width;
    this.height = height;
    this.anchorsOffset = [
      [width / 2, 0],
      [0, height / 2],
      [-width / 2, 0],
      [0, -height / 2],
    ];

    this.text.y = this.y + 50;
  }
}
class UmlNode extends HtmlNode {
  currrentProperties;
  setHtml(rootEl) {
    const { properties, id } = this.props.model;
    const el = document.createElement('div');
    el.className = 'uml-wrapper';
    const html = `
      <div style="width:60px;cursor: pointer;" title="${properties.nodeDesc?properties.nodeDesc: '暂无描述'}">
          <div id="${id+''}" style="overflow:hidden;width:60px; height:60px;position:relative;">
          <img id="${'default_'+id}" src="${defaultImg}" width="60" style="display:block;height:60px;position:absolute;"/>
          <img id="${'active_'+id}" src="${getActiveImgName(properties.operator_id)}" width="60" style="display:none;height:60px;position:absolute;"/>
          <img  src="${properties.icon}"  style="width:40px; height:40px;position:absolute;margin-left:10px;margin-top:10px;display:block;" />
          </div>
      </div>
    `;
    el.style.height = 60 + 'px'
    el.innerHTML = html;
    // 需要先把之前渲染的子节点清除掉。
    rootEl.innerHTML = '';
    rootEl.appendChild(el);
  }
}

const streamOperator = {
  type: 'streamOperator',
  view: UmlNode,
  model: UmlModel,
};

export default streamOperator;
