<template>
  <div class="main">
    <div class="data-source-left-bar" style="height: auto">
      <el-scrollbar style="width: 100%">
        <el-input
          v-model="filterText"
          placeholder="请输入"
          suffix-icon="Search"
          style="width: 192px; height: 32px; margin: 0 auto 16px"
        />
        <el-tree
          ref="treeRef"
          class="api-filter-tree"
          :data="dataSourceTreeData"
          :props="{
            children: 'children',
            label: 'categoryName',
          }"
          default-expand-all
          :filter-node-method="filterNode"
          :indent="20"
          :highlight-current="true"
        >
          <template #default="{ node }">
            <span class="custom-tree-node" @click="clickNode(node)">
              <span :title="node.label" class="custom-tree-label">
                <span class="tree-file-icon"></span>
                  <div class="custom-tree-label-div">{{ node.label }}</div>
                （{{node.data.apiSize}}）
              </span>
            </span>
          </template>
        </el-tree>
      </el-scrollbar>
    </div>
    <div class="data-box">
      <div class="search-box">
        <el-input
          class="input-transparent"
          v-model="keyWord"
          placeholder="请输入API名称或URL"
          clearable
          style="margin-bottom: 24px; width: 600px"
          @keyup.enter="reloadTable"
        >
          <template #suffix>
            <el-icon class="icon-search" @click="reloadTable"
              ><Search
            /></el-icon>
          </template>
        </el-input>
        <div class="toolbar">
          <el-button
            type="primary"
            icon="plus"
            style="color: #fff"
            @click="handleAdd"
            >API注册</el-button
          >
          <div style="display: flex">
            <el-tooltip
              class="box-item"
              effect="dark"
              content="卡片"
              placement="top"
              v-if="!showCard"
            >
              <div class="card" @click="showCard = !showCard">
                <img class="card-img" src="@/assets/image/apiMarket/card.png" />
                <img
                  class="card-active-img"
                  src="@/assets/image/apiMarket/card-active.png"
                />
              </div>
            </el-tooltip>
            <el-tooltip
              class="box-item"
              effect="dark"
              content="列表"
              placement="top"
              v-if="showCard"
            >
              <div class="list" @click="showCard = !showCard">
                <img class="list-img" src="@/assets/image/apiMarket/list.png" />
                <img
                  class="list-active-img"
                  src="@/assets/image/apiMarket/list-active.png"
                />
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>

      <el-row :gutter="20" v-if="showCard" class="cardType-row">
        <el-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          :xl="6"
          class="cardType-row--col"
          v-for="item in tableData"
          :key="item.apiId"
        >
          <div class="card-box">
            <div class="card-box-top">
              <img
                src="@/assets/image/home/market.png"
                width="48"
                height="48"
              />
              <div style="margin-left: 16px;width: calc(100% - 46px);">
                <div style="margin: 0; display: flex; align-items: center;flex-flow: nowrap;width: calc(100% - 12px);">
                  <p class="ellipsis apiname" :title="item.apiName">
                    {{ item.apiName }}
                  </p>
                  <span
                  class="ellipsis"
                  title="API注册"
                  style="
                    padding: 4px;
                    background: #e0edff;
                    border-radius: 2px;
                    color: #1f7aff;
                    font-size: 12px;
                    display: inline-block;
                    width: 52px;
                  "
                  >API注册</span>
                  <span class="ellipsis" v-if="item.changeFlag === 'Y'" style="
                    padding: 4px;
                    background: #f3ecfc;
                    border-radius: 2px;
                    color: #a16fe7;
                    display: inline-block;
                    margin-left: 8px;
                    font-size: 12px;
                    width: 52px;
                  ">迭代中
                  </span>
                <span
                  v-else
                  class="ellipsis"
                  :title="item.apiVersion ? item.apiVersion + '版本' : '-'"
                  style="
                    padding: 4px;
                    background: #f3ecfc;
                    border-radius: 2px;
                    color: #a16fe7;
                    display: inline-block;
                    margin-left: 8px;
                    font-size: 12px;
                    width: 52px;
                    text-align:center;
                  "
                  >{{ item.apiVersion ? item.apiVersion + "版本" : "-" }}</span
                >
                </div>
                
                
                <div style="display: flex">
                  <p
                    class="ellipsis"
                    style="margin: 0; padding-top: 6px; padding-right: 12px"
                  >
                    <span class="card-time">
                      创建时间：
                      {{ item.creationTime }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card-box-mid">
              <p>
                <span class="card-label-mid">请求路径：</span>
                <span class="card-content-mid" :title="item.apiUrl">{{
                  item.apiUrl
                }}</span>
              </p>
              <p>
                <span class="card-label-mid">请求方式：</span>
                <span class="card-content-mid">
                  {{RequestMaps[item.apiRequestM]}}</span>
              </p>
              <p>
                <span class="card-label-mid">服务类型：</span>
                <span class="card-content-mid">{{
                  apiServiceTypesMap[item.apiServicetype] || ""
                }}</span>
              </p>
              <p>
                <span class="card-label-mid">API责任人：</span>
                <span
                  class="card-content-mid"
                  :title="item.apiOwner"
                  >{{ item.apiOwnerName + ' ' + item.apiOwnerNumber }}</span
                >
              </p>
            </div>
            <div class="card-box-bottom">
               <div class="card-box-bottom-status">
              <img
                src="../../assets/image/apiMarket/draft.png"
                alt=""
                v-if="item.status == 'draft'"
                style="margin-right: 5px"
              />
              <img
                src="../../assets/image/apiMarket/pending.png"
                alt=""
                v-if="item.status == 'released' || item.status == 'approval' || item.status == 'pending_developed'"
                style="margin-right: 5px"
              />
              <img
                src="@/assets/image/status/inApproval.png"
                alt=""
                v-if="item.status == 'developed'"
                style="margin-right: 5px"
              />
              
              <img
                src="../../assets/image/apiMarket/success.png"
                alt=""
                v-if="item.status == 'online'"
                style="margin-right: 5px"
              />
              <img
                src="../../assets/image/apiMarket/offline.png"
                alt=""
                v-if="item.status == 'offline'"
                style="margin-right: 5px"
              />
              <span>
                {{
                  apiStatusMap[item.status]
                }}
              </span>
            </div>
            <div style="display:flex;align-items:center;">
              <el-tooltip 
               content="查看"
               v-if="item.status == 'draft' || item.status == 'online' || item.status == 'offline' || item.status == 'released'"
               v-has="'hidden&apiManage.list.document'"
              >
                  <el-button 
                    icon="View" 
                    @click="viewDoc(item)"
                    link>
                  </el-button>
              </el-tooltip>
              <el-tooltip 
               content="编辑"
               v-if="item.status == 'draft' || item.status == 'offline' || item.status == 'released' || item.status == 'online'"
              >
                  <el-button 
                    icon="Edit" 
                    @click="handleEdit(item)"
                    link>
                  </el-button>
              </el-tooltip>
              <el-tooltip 
               content="测试"
               v-if="item.status == 'online' || item.status == 'offline' || item.status == 'released'"
              >
                  <el-button 
                    icon="CaretRight" 
                    @click="apiInvoke(item)"
                    link>
                  </el-button>
              </el-tooltip>
              <el-dropdown :hide-on-click="false" style="margin-left:5px;">
                <el-icon><MoreFilled /></el-icon>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item 
                        icon="upload"
                        @click="onlineRow(item)"
                        v-has="'hidden&apiManage.list.online'"
                        v-if="item.status == 'offline' || item.status == 'released'"
                      >上线</el-dropdown-item
                    >

                    <el-dropdown-item
                        v-if="item.status == 'online'"
                        icon="download"
                         @click="offlineRow(item)"
                    >下线</el-dropdown-item>

                    <el-dropdown-item
                      icon="Delete"
                      style="color: #f56c6c"
                      @click="handleDelete(item)"
                      v-if="item.status == 'draft' || item.status == 'released' || item.status == 'offline'"
                      >删除</el-dropdown-item
                    >

                   

                    <el-dropdown-item v-if="item.status == 'approval'">审批中禁止操作</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div
        class="box-card-radius-eight white-bg"
        style="overflow: hidden"
        v-else
      >
        <el-table :data="tableData" row-key="apiId" border>
          <el-table-column
            prop="apiName"
            label="API名称"
            width="200"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
            <el-table-column
            prop="oneBusinessCateName"
            label="一级分类"
            width="200"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
            <el-table-column
            prop="twoBusinessCateName"
            label="二级分类"
            width="200"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
           <el-table-column
            prop="apiOwner"
            label="API责任人"
            width="200"
            :show-overflow-tooltip="true"
          >
           <template #default="scope">
              <span>{{ scope.row.apiOwnerName + ' ' + scope.row.apiOwnerNumber }}</span>
            </template>
          </el-table-column>
           <el-table-column
            prop="apiType"
            label="API渠道"
            width="200"
            :show-overflow-tooltip="true"
          >
           <template #default="scope">
              <span>{{ scope.row.apiType == 'routeApi' ? "内部API" : "外部API" }}</span>
            </template>
          </el-table-column>
           <el-table-column
            prop="apiUrl"
            label="URL"
            width="200"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="status"
            label="API状态"
            width="200"
            :show-overflow-tooltip="true"
          >
           <template #default="scope">
              <span >{{apiStatusMap[scope.row.status]  || '-'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="apiRequestM" label="请求方式" width="150">
            <template #default="scope">
              <span>{{ RequestMaps[scope.row.apiRequestM]}}</span>
            </template>
          </el-table-column>
          <el-table-column label="服务类型" width="150">
            <template #default="scope">
              <span>{{
                apiServiceTypesMap[scope.row.apiServicetype]  || '-'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="creationTime" label="创建时间" width="180"></el-table-column>
          <el-table-column
            prop="operation"
            label="操作"
            width="350px"
            align="left"
            fixed="right"
          >
            <template #default="scope">
              <div >
                <el-button
                  type="primary"
                  link
                  icon="Document"
                  @click="viewDoc(scope.row)"
                  v-has="'hidden&apiManage.list.document'"
                  >查看</el-button
                >

                <el-button
                  type="primary"
                  link
                  icon="upload"
                  @click="onlineRow(scope.row)"
                  v-has="'hidden&apiManage.list.online'"
                  v-if="scope.row.status == 'offline' || scope.row.status == 'released'"
                  >上线</el-button>

                <el-button 
                  type="primary" 
                  link 
                  icon="download" 
                  v-if="scope.row.status == 'online'"
                  @click="offlineRow(scope.row)"
                  >下线</el-button>

                <el-button 
                  type="primary" 
                  link 
                  icon="CaretRight" 
                  v-if="scope.row.status == 'online' || scope.row.status == 'offline' || scope.row.status == 'released'"
                  @click="apiInvoke(scope.row)"
                  >测试</el-button>

                <el-button
                  @click="handleEdit(scope.row)"
                  v-if="scope.row.status == 'draft' || scope.row.status == 'offline' || scope.row.status == 'released' || scope.row.status == 'online'"
                  type="primary"
                  link
                  icon="Edit" 
                  v-has="'hidden&apiManage.list.edit'"
                  >编辑</el-button
                >
                
                <el-button
                 type="primary" 
                 link
                 icon="Delete"
                 v-if="scope.row.status == 'draft' || scope.row.status == 'released' || scope.row.status == 'offline'"
                 @click="handleDelete(scope.row)"
                  >删除</el-button>
                <el-button v-if="scope.row.status == 'approval'" type="primary" link disabled>审批中禁止操作</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页组件-->
      <div style="padding: 10px 0; float: right">
        <el-pagination
          background
          small
          :current-page="pageVO.currentPage"
          :page-size="pageVO.pageSize"
          :page-sizes="[2, 5, 10, 20]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageVO.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { request } from "@/api";
import { ElMessage, ElMessageBox } from "element-plus";
import { WarningFilled } from "@element-plus/icons-vue";
import {apiStatus,apiServiceTypes,RequestMap} from '@/utils/constant'
export default {
  name: "ApiDesign",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const RequestMaps = ref(RequestMap)
    let isMyDesign = ref(route.fullPath === "/home/mine/myDesign");
    let keyWord = ref("");
    let categoryId = ref(null)
    let tableData = ref();
    let filterText = ref("");
    let showCard = ref(true);
    let pageVO = ref({
      currentPage: 1,
      pageSize: 10,
      total: 0,
    });
    let dataSourceTreeData = ref([]);
    let apiStatusMap = ref(apiStatus)
    let apiServiceTypesMap = ref(apiServiceTypes);
    const filterNode = (value, data) => {
      if (!value) return true;
      return data.categoryName.includes(value);
    };

    const getApiCategory = () => {
      request
        .routeTree({
          appId: localStorage.getItem("appId"),
          categoryName: filterText.value,
        })
        .then((res) => {
          if (res.data.code == 200) {
            dataSourceTreeData.value = [res.data.data];
          }
        });
    };
    const clickNode = (node) => {
      categoryId.value = node.data.categoryId
      reloadTable()
    };
    const handleSizeChange = (val) => {
      //size修改
      pageVO.value.pageSize = val;
      getRouterList();
    };
    const handleCurrentChange = (val) => {
      //num修改
      pageVO.value.currentPage = val;
      getRouterList();
    };

    const getRouterList = async () => {
      let res = await request.pageListRoute({
           ...pageVO.value,
          appId:localStorage.getItem('appId'),
          keyWord:keyWord.value,
          categoryId:categoryId.value
        });
      if (res?.data?.code === 200) {
        tableData.value = res.data.data.result;
        pageVO.value.total = res.data.data.pageVO.total;
      }
    };

    const handleDelete = (row) => {
      ElMessageBox.confirm(`确定删除当前服务：` + row.apiName, {
        icon: WarningFilled,
        type: "warning",
      })
        .then(() => {
          request
            .deleteRouteApiDetails({
              routeApi: row.routeApi,
              operateType: "8",
            })
            .then((res) => {
              if (res?.data?.code === 200) {
                ElMessage.success("删除成功");
                reloadTable();
              }
            });
        })
        .catch(() => {});
    };

    const handleEdit = (row) => {
      router.push({
        path: "/home/ApiManage/ApiManageRegister/detail",
        query: {
          operation: "edit",
          routeApi: row.routeApi,
        },
      });
    };

    

    const handleAdd = () => {
      router.push({
        path: "/home/ApiManage/ApiManageRegister/detail",
        query: {
          operation: "create",
        },
      });
    };

    const viewDoc = (row) => {
      //api文档
      router.push({
        path:'/home/ApiManage/ApiManageRegister/view',
        query: {
          routeApi:row.routeApi,
          changeFlag:row.changeFlag || 'N'
        }
      });
    };

    const onlineRow = (row) => {
      //上线
      row.newStatus = 'online'; //上线状态码
      row.operateType = "4"; //操作码
      ElMessageBox.confirm(`确定上线当前服务：` + row.apiName)
        .then(() => { 
          request.apiRouteUpdateStatus({
            ...row,
            newStatus: "online",
            operateType: "4",
          }).then((res) => {
            if (res.data.code == 200) {
              ElMessage.success("操作成功");
              reloadTable();
            } else {
              ElMessage.error(res?.data?.msg || "操作失败");
            }
          });
        })
        .catch(() => {});
    };

    const offlineRow = (row) => {
      //下线
      row.newStatus = 'offline'; //下线状态码
      row.operateType = "5"; //操作码
      ElMessageBox.confirm(`确定下线当前服务：` + row.apiName)
        .then(() => {
          request.apiRouteUpdateStatus({
            ...row
            }).then((res) => {
            if (res.data.code == 200) {
              ElMessage.success("操作成功");
              reloadTable();
            } else {
              ElMessage.error(res?.data?.msg || "操作失败");
            }
          });
        })
        .catch(() => {});
    };

    const apiInvoke = (row) => {
      router.push({
        path:"/home/serviceOperation/ApiTest",
        query:{
          apiName:row.apiName,
          apiId:row.routeApi,
          apiType:'route_api'
        }
      });
    };

    const reloadTable = () => {
      pageVO.value.currentPage = 1;
      getRouterList();
    };

    onMounted(() => {
      getApiCategory();
      getRouterList()
    });

    return {
      apiInvoke,
      offlineRow,
      onlineRow,
      viewDoc,
      showCard,
      keyWord,
      getRouterList,
      reloadTable,
      handleDelete,
      handleEdit,
      handleAdd,
      tableData,
      filterText,
      filterNode,
      dataSourceTreeData,
      clickNode,
      apiServiceTypesMap,
      apiStatusMap,
      isMyDesign,
      RequestMaps,
      pageVO,
      handleSizeChange,
      handleCurrentChange,
      categoryId,
    };
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  display: flex;
  margin-top: 20px;
  text-align: left;
  .toolbar {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }
  .data-source-left-bar {
    width: 224px;
  }
  .data-box {
    width: calc(100% - 300px);
    margin-left: 20px;
    .search-box {
      width: 100%;
      .card {
        &:hover {
          .card-img {
            display: none;
          }
          .card-active-img {
            display: block;
          }
        }
        .card-img {
          display: block;
        }
        .card-active-img {
          display: none;
        }
      }
      .list {
        &:hover {
          .list-img {
            display: none;
          }
          .list-active-img {
            display: block;
          }
        }
        .list-img {
          display: block;
        }
        .list-active-img {
          display: none;
        }
      }
    }
    .cardType-row {
      max-height: 650px;
      overflow-y: auto;
      .card-box {
        background: #ffffff;
        border: 2px solid #ffffff;
        border-radius: 8px;
        box-shadow: 4px 4px 16px 0px rgba(0, 67, 161, 0.1);
        margin-bottom: 12px;
        cursor: pointer;
        box-sizing: border-box;
        &:hover {
          background: linear-gradient(180deg, #d9e3ff, #ffffff 100%);
          box-shadow: 3px 6px 15px 5px rgba(21, 69, 137, 0.16);
        }
        .card-box-top {
          display: flex;
          padding: 12px 12px 0 12px;
          align-items: center;
          .apiname {
            margin: 0 12px 0 0;
            text-align: left;
            font-size: 16px;
            font-family: Source Han Sans CN, Source Han Sans CN-700;
            font-weight: 700;
            max-width: 170px;
            min-wdith: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .card-time {
            font-size: 12px;
            font-family: Source Han Sans CN, Source Han Sans CN-400;
            font-weight: 400;
            text-align: LEFT;
            color: #4e5156;
          }
        }
        .card-box-mid {
          margin: 18px 0 0;
          padding: 0 12px 0 18px;
          p {
            margin: 0 0 12px 0;
            text-align: left;
            display: flex;
          }
          .card-label-mid {
            display: inline-block;
            width: 80px;
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-400;
            font-weight: 400;
            text-align: LEFT;
            color: #777d84;
          }
          .card-content-mid {
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-400;
            font-weight: 400;
            text-align: LEFT;
            color: #091328;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .card-box-bottom {
          height: 45px;
          border-top: 1px solid #d6dae0;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          text-align: left;
          justify-content: space-between;
          padding: 5px;
          .card-box-bottom-status{
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>
