<template>
  
     <div style="padding:28px;">
       <div class="search"> 
        <el-input
              class="input-transparent"
              v-model="apiName"
              placeholder="请输入API名称"
              clearable
              style="margin-bottom: 24px;width:40%;"
              @keyup.enter="reloadTable"
            >
              <template #suffix>
                <el-icon class="icon-search" @click="reloadTable"
                  ><Search
                /></el-icon>
              </template>
        </el-input>
        <el-select
            style="margin-bottom: 24px; margin-left: 24px;width:40%;"
            v-model="apiOwner"
            class="input-transparent"
            filterable
            clearable
            remote
            reserve-keyword
            placeholder="请输入API责任人"
            :remote-method="getApiOwnerList"
            @change="reloadTable"
            >
          <el-option
              v-for="item in apiOwnerList"
              :key="item.userId"
              :label="item.userName + ' ' +item.account"
              :value="item.userId"
          />
        </el-select>
  </div>
  <div class="content">
    <div class="totals">
      <div class="total box-card-radius-eight white-bg" v-for="item of indexDetails" :key="item">
        <div class="num">{{item.total}}</div>
        <div class="name">{{item.name}}</div>
      </div>
    </div>

      <div class="cards">
        <div class="card box-card-radius-eight white-bg"  v-for="item of indexDetails" :key="item" 
        :style="{width:item.indexLength === indexLength - 1 && indexLength % 2===1?'88%':'42%'}">
          <div class="card-header">
            <div class="name">{{item.name}}</div>
            <div class="more" @click="getMore(item)">更多<el-icon style="color:#1890ff;margin-left:5px;" ><DArrowRight /></el-icon></div>
          </div>
          <div class="card-table">
              <el-table :data="item.list" height="200">
               
                <el-table-column
                  prop="apiName"
                  label="API名称"
                  width="200"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  prop="apiType"
                  label="API类型"
                  width="200"
                  :show-overflow-tooltip="true"
                >
                <template #default="scope">
                {{
                  createTypeMaps[scope.row.apiType]
                }}
                </template>
                </el-table-column>
                <el-table-column
                  prop="apiVersion"
                  label="版本"
                />
                
                <el-table-column
                  prop="total"
                  label="总请求量"
                  :width="item.name !== '零访问服务'?200:null"
                  v-if="item.name !== '零访问服务'"
                />
                <el-table-column
                  prop="durationAvg"
                  label="总平均响应时间（单位：秒）"
                  width="240"
                  v-if="item.name === '慢服务'"
                />
                <el-table-column
                  prop="slowDurationAvg"
                  label="慢服务平均响应时间（单位：秒）"
                  width="240"
                  v-if="item.name === '慢服务'"
                />
                <el-table-column
                  prop="slowTotal"
                  label="慢服务请求总量"
                  width="200"
                  v-if="item.name === '慢服务'"
                />
                <el-table-column
                  prop="slowRatio"
                  label="慢服务请求占比"
                  width="200"
                  v-if="item.name === '慢服务'"
                />
                 <el-table-column
                  prop="errorTotal"
                  label="异常请求量"
                  width="200"
                  v-if="item.name === '高异常服务'"
                />
                 <el-table-column
                  prop="errorRatio"
                  label="异常率占比"
                   width="200"
                   v-if="item.name === '高异常服务'"
                />
                <el-table-column
                  prop="bigdataTotal"
                  label="大数据量"
                  v-if="item.name === '大数据量服务'"
                />
                <el-table-column
                  prop="bigdataRatio"
                  label="大数据率占比"
                  width="200"
                   v-if="item.name === '大数据量服务'"
                />
                 <el-table-column
                  prop="apiUrl"
                  label="URL"
                   width="200"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="责任人"
                  width="200"
                >
                <template #default="scope">
                {{
                  scope.row.apiOwnerName + ' ' + scope.row.apiOwnerNumber
                }}
                </template>
                </el-table-column>
                <el-table-column
                  prop="creationTime"
                  label="统计时间"
                  width="200"
                />
              </el-table>
          </div>

        </div>
      </div>
  </div>
  <moreDrawer ref="moreDrawerRef"/>
  </div>
</template>
<script>
import {  ref, onMounted } from "vue";
import {request} from '@/api'
import moreDrawer from './moreDrawer.vue'
import {createTypeMap} from '@/utils/constant'
export default {
  name: "OperateManagementList",
  components:{
    moreDrawer
  },
  setup() {
    let moreDrawerRef = ref(null)
    let tableData = ref([])
    let apiOwnerList = ref([])
    let apiOwner = ref(null)
    let apiName = ref(null)
    let indexDetails = ref({})
    let createTypeMaps = ref(createTypeMap)

    let indexLength = ref(0)

   
    const reloadTable = ()=>{
      findIndexDetails()
    }

    const getMore = (item)=>{
      moreDrawerRef.value.show(item.name)
    }


    const findIndexDetails = ()=>{
      request.findIndexDetails({
            apiOwner: apiOwner.value,
            apiName:apiName.value
          }).then((res) => {
          if (res?.data?.code === 200) {
            indexDetails.value = formatterData(res.data.data)
          }
        });
    }
    
    const formatterData = (obj)=>{
      for(let key in obj){
         indexLength.value++;
         obj[key].indexLength = indexLength.value - 1
          obj[key].list = obj[key].list.map(item=>{
            if(item.indexResultdata){
              let indexResultdata = JSON.parse(item.indexResultdata)
              return {
                ...item,
                ...indexResultdata,
              }
            }else{
              return item
            }
          })
      }
      return obj
    }

    const getApiOwnerList = (query)=>{
      if (query) {
       request.selectUserByParams({
            likeData:query,
            appId:localStorage.getItem('appId'),
            currentPage: 1,
            pageSize: 10000
          }).then((res) => {
          if (res?.data?.code === 200) {
            apiOwnerList.value = res.data.data.result
          }
        });
      } else {
        apiOwnerList.value = []
      }
    }

    onMounted(()=>{
      findIndexDetails()
    })
    return {
      moreDrawerRef,
      tableData,
      reloadTable,
      getApiOwnerList,
      apiOwnerList,
      apiOwner,
      apiName,
      findIndexDetails,
      indexDetails,
      getMore,
      indexLength,
      createTypeMaps
    };
  },
};
</script>

<style lang="less" scoped >
.search{
  display: flex;
  justify-content: center;
}
.content{
  .totals{
    width: 90%;
    margin: auto;
    display: flex;
    .total{
      flex:1;
      margin-right: 7px;
      padding: 10px;
      .num{
        font-size: 20px;
        font-family: DIN,DIN-700;
        font-weight: 700;
      }
      .name{
        font-size: 16px;
        color: #091328;
          font-weight: 700;
      }
    }
  }

  .cards{
    width: 100%;
    max-height: 600px;
    margin-top: 30px;
    overflow-y: auto;
    .card{
      width:42%;
      height: 300px;
      display: inline-block;
      margin: 15px;
      border: 1px solid #d9d9d9;
      .card-header{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 5px;
        margin-bottom: 5px;
        .more{
          color:#1890ff;
          cursor: pointer;
        }
        .name{
          font-size: 16px;
          color: #091328;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
