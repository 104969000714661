<template>
    <div>
      <div style="margin-top: 10px;" align="left">
        <el-form v-model="searchForm">
          <el-row :gutter="24">
            <el-col :span="6">
              <el-form-item label="申请单号：">
                <el-input v-model="searchForm.applyNo" placeholder="请输入" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="申请类型：">
                <el-input v-model="searchForm.applyType" placeholder="请输入" />
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="showType === 1">
              <el-form-item label="状态：">
                <el-select v-model="searchForm.status" placeholder="请选择" style="width: 100%;">
                  <el-option label="待审批" value="待审批" />
                  <el-option label="已撤回" value="已撤回" />
                  <el-option label="审批通过" value="审批通过" />
                  <el-option label="审批驳回" value="审批驳回" />
                  <el-option label="审批异常" value="审批异常" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="showType === 2">
              <el-form-item label="状态：">
                <el-select v-model="searchForm.status" placeholder="待审批" disabled style="width: 100%;">
                </el-select>
              </el-form-item>
            </el-col>
          <!-- </el-row>
          <el-row :gutter="24"> -->
            <el-col :span="6" align="left">
              <!-- <el-form-item> -->
                <el-button type="primary" @click="getData()">搜索</el-button>
                <el-button @click="reset()">重置</el-button>
              <!-- </el-form-item> -->
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div style="margin-top: 24px;">
        <el-table :data="tableData.list" :row-key="getRowKey" stripe style="width: 100%">
          <el-table-column v-if="false" prop="approvalId" />
          <el-table-column label="申请单号" prop="applyNo" width=210% :show-overflow-tooltip="true" />
          <el-table-column label="申请内容" prop="applyContents" width=240% :show-overflow-tooltip="true" />
          <el-table-column label="申请单类型" prop="applyType" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="审批状态" prop="status"></el-table-column>
          <el-table-column label="申请所属应用" prop="appName" />
          <el-table-column label="当前处理人" prop="pendingApproval" v-if="tabType !== '3'" :show-overflow-tooltip="true" />
          <el-table-column label="申请人" prop="createBy" />
          <el-table-column label="申请时间" prop="createDate" :formatter="iasmjs.formatDate" width="200%" />
          <el-table-column label="单据跟踪" :show-overflow-tooltip="true" width="100">
            <template #default="scope">
              <el-button text type="primary" @click="apprNodeDetail(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="operation" width="140" fixed="right">
            <template #default="scope">
              <el-button size="small" type="primary" v-has="'hidden&userCenter.myWaitFor.approve'" @click="toApproveDetail(scope.row)">详情</el-button>
              <el-button size="small" type="primary" v-if="(scope.row.status === '待审批' || scope.row.status === '审批异常') && tabType === '1'" @click="recall(scope.row.id)">撤回</el-button>
              <el-button size="small" type="primary" v-if="scope.row.status === '已撤回' && tabType === '1'" @click="del(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
  
        <el-col :span="24" class="toolbar" style="text-align:center;margin-top: 12px;">
           <el-pagination style="float:right;" background small :current-page="page.currentPage" :page-size="page.pageSize" :page-sizes="[2, 5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
        </el-col>
      </div>

      <el-dialog v-model="nodeState.visible" title="单据跟踪">
        <el-table :data="nodeState.list">
          <el-table-column prop="number" label="编号"></el-table-column>
          <el-table-column prop="approvalNode" label="审批节点"></el-table-column>
          <el-table-column prop="handler" label="当前审批人"></el-table-column>
          <el-table-column prop="handlingTime" label="处理时间" :formatter="iasmjs.formatDate"></el-table-column>
          <el-table-column prop="reasons" label="处理原因"></el-table-column> 
          <el-table-column prop="operate" label="操作"></el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </template>
  
  <script>
  
  import {request} from '@/api'
  import { reactive, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import iasmjs from "@/assets/js/iasm.js";
  import { ElMessage } from 'element-plus';
  
  export default {
    props: {
        tabType: {
            type: String,
            default: () => '1'
        }
    },
    setup(props) {
      const _props = props
      const pageFrom = _props.tabType;
      const pageFromVal = ref(pageFrom);
      const searchForm = reactive({
        applyNo: '',
        applyType: '',
        status: '',
        // dateRange: ''
      });
      const nodeState = reactive({
        visible: false,
        list: []
      })
      const tableData = reactive({ //表格参数
        list: []
      });
      const total = ref(0);  //总条数
      const page = reactive({  //配置对应的查询参数
        currentPage: 1,
        pageSize: 10,
      });
      const showType = ref(1);
      const getData = async (type) => {
        let from = props.tabType;
        if (type) {
          from = type;
          pageFromVal.value = type;
        }
        let params = {  
          //注意是params，get请求必须写上params，不能写data
            applyNo: searchForm.applyNo,
            applyType: searchForm.applyType,
            status: searchForm.status,
            // startTime: searchForm.dateRange.length > 0 ? searchForm.dateRange[0] : '',
            // endTime: searchForm.dateRange.length > 0 ? searchForm.dateRange[1] : '',
            currPage: page.currentPage,
            pageSize: page.pageSize
          }
        let res
        if (from == '1') {
          showType.value = 1;
          res = await request.applyList(params)
        } else if (from == '2') {
          showType.value = 2;
          res = await request.pendingList(params)
        } else {
          showType.value = 3;
          res = await request.processedList(params)
        }
         tableData.list = res.data.data.list;
         total.value = res.data.data.totalCount
      };
      const handleSizeChange = (val) => { //size修改
        page.pageSize = val
        getData(pageFrom);
      };
      const handleCurrentChange = (val) => { //num修改
        page.currentPage = val
        getData(pageFrom);
      };
      const reset = () => {
        searchForm.applyNo = '';
        // searchForm.dateRange = ''
        searchForm.applyType = '';
        searchForm.status = '';
        searchForm.currentPage = page.pageNum;
        searchForm.pageSize = page.pageSize
        getData(pageFrom);
      }
  
      const that = useRouter()
      const toApproveDetail = (row) => {
        if(row.applyType === 'API订阅' || row.applyType === '取消订阅'){
          that.push({
            path: '/home/ApiMarket/ApiInformation',
            query: {
              apiId: row.businessId,
              fromPage: pageFromVal.value,
              changeFlag: row.applyType === 'API迭代' ? 'Y' : 'N',
              applyType:row.applyType,
              apiType:row.apiType,
              type:'1',
              id: row.id
            }
          }) 
        } else{
          if(row.apiType === 'platform_api'|| row.apiType === 'arrange_api'){
            that.push({
              path: '/home/ApiManage/ApiView',
              query: {
                apiId: row.businessId,
                fromPage: pageFromVal.value,
                changeFlag: row.applyType === 'API迭代' ? 'Y' : 'N',
                applyType:row.applyType,
                apiType:row.apiType,
                type:'1',
                id: row.id
              }
            })
          }else{
            that.push({
            path: '/home/ApiManage/ApiManageRegister/view',
            query: {
              routeApi: row.businessId,
              fromPage: pageFromVal.value,
              changeFlag: row.applyType === 'API迭代' ? 'Y' : 'N',
              applyType:row.applyType,
              apiType:row.apiType,
              type:'1',
              id: row.id
            }
          }) 
          }
          
        }
        
      };

      const recall = (id) => {
        request.recall({ id }).then(res => {
          if (res?.data?.code === 200) {
            ElMessage.success('撤回成功')
            getData(pageFrom);
          } else {
            ElMessage.error(res?.data?.data || res?.data?.msg || '撤回失败！')
          }
        })
      }

      const del = (id) => {
        request.deleteAudit({ id }).then(res => {
          if (res?.data?.code === 200) {
            ElMessage.success('删除成功')
            getData(pageFrom);
          } else {
            ElMessage.error(res?.data?.data || res?.data?.msg || '删除失败！')
          }
        })
      }

      const apprNodeDetail = (id) => {
        nodeState.visible = true
        request.searchApprovalNode({ id }).then(res => {
          if (res?.data?.code === 200) {
            nodeState.list = res?.data?.data
          }
        })
      }
      return {
        tableData,
        total,
        page,
        searchForm,
        pageFrom,
        nodeState,
        reset,
        getData,
        handleSizeChange,
        handleCurrentChange,
        toApproveDetail,
        apprNodeDetail,
        recall,
        del,
        iasmjs,
        showType
      }
    },
    name: "MyToDos",
    beforeCreate() {
      this.getData();
    },
    beforeRouteUpdate(to, from, next) {
      const type = to.params.fromPage;
      next();
      this.reset();
      this.getData(type);
    },
    methods: {
      getRowKey(row) {
        return row.approvalId;
      }
    }
  }
  </script>
  
  <style lang="less" scoped >
  .page-position-common {
    float: right;
    margin-right: 15px !important;
    margin-left: 5px;
  }
  </style>