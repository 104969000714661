import useClipboard from 'vue-clipboard3'
import { ElMessage } from "element-plus";

export const formatterMenuList = (menuList)=>{
    let list = []
    menuList.forEach(menu=>{
      if(menu.meta.showMenu){
        let item = {}
        if(menu.children && menu.children.length > 0){
          let childList = []
          menu.children.forEach(child=>{
            if(child.meta.showMenu){
              childList.push(child)
            }
          })
          item = {
            ...menu,
            children:childList
          }
        }else{
          item = {
            ...menu
          }
        }
        list.push(item)
      }

    })
    return list
  }




  export const  getInitMapperTable= (task_config_param, result, operatorFlowId)=> {
    //1.根据当前节点ID，获取上一级节点ID
    const edges = task_config_param.edges;

    //获取上一级节点ID
    let sourceNodeId = '';
    edges.forEach(function (item) {
        if (item.targetNodeId == operatorFlowId) {
           sourceNodeId = item.sourceNodeId;
        }
    });

    //根据上一级节点ID，获取上一级的mappertable数据
    const data = result[sourceNodeId];
    //根据上一级节点获取生成当前节点数据
    const newMappertable = [];
    //获取上一级节点数据
    if (data != null && data.iasmApiArrangeNode.nodeData != null) {
        const mappertable = JSON.parse(data.iasmApiArrangeNode.nodeData).out;
        let rowList = []
        mappertable.forEach(function (item) {
        let rowStr = item.apiFieldCnname + item.apiFieldType
        //去重
        if(!rowList.includes(rowStr)){
            rowList.push(rowStr)
            newMappertable.push({
            apiFieldCnname:item.apiFieldCnname,
            apiFieldName:item.apiFieldName,
            apiFieldType:item.apiFieldType,
            defaultValue:item.defaultValue,
            fieldDescription:item.fieldDescription,
            key: `${Date.now()}`,
            });
        }
        
        });
    }

    return newMappertable;
 }



const toString = Object.prototype.toString;

export function is(val, type) {
  return toString.call(val) === `[object ${type}]`;
}


export function isString(val){
  return is(val, 'String');
}



export const copyClipboard = async (board)=>{
  const {toClipboard} = useClipboard()
   try{
     await toClipboard(board)
     ElMessage.success('复制成功');
   } catch (e){
     ElMessage.error('复制失败');
   }
}