<template>
  <div class="createSql" style="margin:28px;padding-bottom:90px;">
    <div  class="box-card-radius-eight white-bg">
     <div align="left" class="step-title margin-botton-12"><strong>基本信息</strong></div>
          <el-form ref="ruleFormRef" :model="form" :rules="basicRules" label-position="top">
            <el-row :gutter="48">
              <el-col :span="8" align="left">
                <el-form-item label="API名称" placeholder="请输入名称" prop="apiName">
                  <el-input v-model="form.apiName" maxlength="100" show-word-limit @change="formChange" :disabled="isView" />
                </el-form-item>
              </el-col>
              <el-col :span="16" align="left">
                <el-form-item label="URL" placeholder="请输入URL" prop="apiUrl">
                  <el-input v-model.trim="form.apiUrl"
                    :disabled="isView"
                    maxlength="200" >
                    <template #prepend>{{domain}}</template>
                   <template #append>
                      <el-tooltip content="复制完整URL">
                        <el-button  type="text" icon="CopyDocument" @click="copyUrl"/>
                      </el-tooltip>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="挂载目录" prop="categoryId">
                  <el-tree-select
                  :disabled="isView"
                    v-model="form.categoryId"
                    placeholder="请选择挂载目录"
                    :data="treeData"
                    filterable
                    check-strictly
                    :render-after-expand="false"
                    :props="{
                      children: 'children',
                      label: 'categoryName',
                      value:'categoryId'
                    }"
                    style="width: 100%"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="业务分类" prop="businessCateId">
                  <el-cascader
                  :disabled="isView"
                    v-model="form.businessCateId"
                    :options="businessCateOptions"
                    :props="{ 
                      value:'businessCateId',
                      label:'businessCateName',
                      children:'child',
                      expandTrigger: 'hover' }"
                      @change="changebusinessCateId"
                    style="width:100%">
                    </el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="服务类型" prop="apiServicetype">
                  <el-select
                    v-model="form.apiServicetype"
                    :disabled="isView"
                    placeholder="请选择服务类型"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in apiServicetypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
               <el-col :span="8" align="left">
                <el-form-item label="服务版本" prop="apiVersion">
                  <el-select
                    v-model="form.apiVersion"
                    :disabled="isView"
                    placeholder="请选择服务版本"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in apiVersionList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left" v-if="!ApiFlowChart">
                <el-form-item label="开发人" prop="developerUserId">
                  <el-select
                  :disabled="isView"
                    v-model="form.developerUserId"
                    @change="changeDesignUser"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="请输入搜索关键字"
                      :remote-method="getdesignUser"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in designUserList"
                      :key="item.userId"
                      :label="item.userName + ' ' +item.account"
                      :value="item.userId"
                      
                    />
                  </el-select>
                </el-form-item>
                
              </el-col>

              <el-col :span="8" align="left">
               <el-form-item label="API责任人" prop="apiOwner">
                  <el-select
                  :disabled="isView"
                    v-model="form.apiOwner"
                    @change="changeApiOwner"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入搜索关键字"
                    :remote-method="getApiOwnerList"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in apiOwnerList"
                      :key="item.userId"
                      :label="item.userName + ' ' +item.account"
                      :value="item.userId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
             
              <el-col :span="8" align="left">
                <el-form-item label="是否安全认证" prop="apiIssecurity">
                  <el-radio-group v-model="form.apiIssecurity" :disabled="isView">
                    <el-radio label="Y">是</el-radio>
                    <el-radio label="N">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left">
                <el-form-item label="请求方式" prop="apiRequestM">
                   <el-select
                   :disabled="isView"
                    v-model="form.apiRequestM"
                    filterable
                    placeholder="请选择请求方式"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in Requests"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" align="left" v-if="form.apiServicetype === 'select'">
                <el-form-item label="返回数据结构" prop="resultDataBuild" required>
                   <el-select
                   :disabled="isView"
                    v-model="form.resultDataBuild"
                    filterable
                    placeholder="请选择返回数据结构"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in resultDataBuilds"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            
            <el-form-item label="描述">
              <el-input
              :disabled="isView"
                v-model="form.apiDescription"
                maxlength="1000"
                show-word-limit
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-form>
        </div>

      <div  class="box-card-radius-eight white-bg" style="margin-top:20px;">
          <div align="left" class="step-title margin-botton-12 margin-top-24"><strong>入参配置</strong></div>
          <div align="left" class="table-btn__up margin-botton-12">
            <el-button type="primary" icon="plus" @click="addRow()" :disabled="isView">新增入参</el-button>
          </div>
          <el-table :data="paramList" style="width: 100%" max-height="285">
            <el-table-column
              prop="paramNameCm"
              label="参数中文名称"
              :show-overflow-tooltip="true"
               width="300"
            >
              <template #default="scope">
                <el-input
                :disabled="isView"
                  v-model="scope.row.paramNameCm"
                  maxlength="100"
                  placeholder="请输入参数中文名称"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="paramName"
              label="参数名称"
               width="300"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                
                  v-model="scope.row.paramName"
                  maxlength="50"
                  placeholder="请输入参数名称"
                  :disabled="isView"
                ></el-input>
              </template>
            </el-table-column>
           
            
            <el-table-column
              prop="paramValue"
              label="默认值"
               width="200"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                :disabled="isView"
                  v-model="scope.row.paramValue"
                  maxlength="50"
                  placeholder="请输入默认值"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="paramType" label="数据类型"  width="200">
              <template #default="scope">
                <el-select
                :disabled="isView"
                  v-model="scope.row.paramType"
                  placeholder="请选择数据类型"
                >
                  <el-option v-for="p in paramsTypes" :key="p.label" :label="p.label" :value="p.value" />
                  </el-select>
                  </template>
            </el-table-column>
            <el-table-column
              prop="paramIsMust"
              label="是否必填写"
               width="200"
            >
              <template #default="scope">
                <el-select v-model="scope.row.paramIsMust" default="N" :disabled="isView">
                  <el-option value="Y" label="是" />
                  <el-option value="N" label="否" />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="sort"
              label="排序"
               width="200"
            >
              <template #default="scope">
                <el-input-number
                :disabled="isView"
                  v-model="scope.row.sort"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column
              prop="paramFun"
               width="500"
            >
            <template #header>
              <span style="display:flex;align-items:center;">输入函数 
                <el-tooltip 
                content="函数用法:函数名称(#{参数名称},固定参数值,...) 例如:trim(#{username}),对参数变量username去除前后空格"
                placement="top"
                >
                <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
                </span>
            </template>
              <template #default="scope">
                <el-autocomplete
                   :disabled="isView"
                    v-model="scope.row.paramFun"
                    :fetch-suggestions="querySearch"
                    value-key="functionName"
                    label="functionName"
                    clearable
                    style="width:100%;"
                  />
               
              </template>
            </el-table-column>
            <el-table-column
                  prop="example"
                  label="示例值"
                  width="200"
                  :show-overflow-tooltip="true"
                >
                  <template #default="scope">
                    <el-input
                      clearable
                      v-model="scope.row.example"
                      placeholder="请输入"
                      maxlength="50"
                    ></el-input>
                  </template>
                </el-table-column>
            <el-table-column
              prop="paramDescription"
               width="300"
              label="描述"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                :disabled="isView"
                  v-model="scope.row.paramDescription"
                  placeholder="请输入描述"
                  maxlength="50"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" fixed="right" v-if="!isView">
              <template #default="scope">
                <el-popconfirm
                  title="删除后将无法恢复，请确认是否删除？"
                  confirm-button-type="danger"
                  icon="warning-filled" icon-color="#F56C6C"
                  width="250" placement="top-end"
                  @confirm="deleteRow(scope.$index)">
                  <template #reference>
                    <el-button icon="delete" type="danger" text :disabled="scope.row.isDisable"></el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>

          </div>

          <div  class="box-card-radius-eight white-bg"  style="margin-top:20px;">
          <div align="left" class="step-title margin-botton-12 margin-top-24"><strong>出参配置</strong></div>
          <div align="left" class="table-btn__up margin-botton-12">
            <el-button type="primary" icon="plus" @click="addfieldMappingListRow()" :disabled="isView">新增出参</el-button>
          </div>
          <el-table  
              ref="mappingDataList"
              :data="fieldMappingList" style="width: 100%" max-height="285"> 
            <el-table-column
              prop="apiFieldCnname"
              label="参数中文名称"
               width="300"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                :disabled="isView"
                  v-model="scope.row.apiFieldCnname"
                  maxlength="50"
                  placeholder="请输入参数中文名称"
                ></el-input>
              </template>
            </el-table-column> 
            <el-table-column
              prop="dbFieldName"
              label="数据库字段名"
               width="300"
               v-if="!ApiFlowChart"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                  v-model="scope.row.dbFieldName"
                  maxlength="50"
                  placeholder="请输入参数名称"
                  :disabled="isView"
                ></el-input>
              </template>
            </el-table-column>
            
            <el-table-column
              prop="dbFieldType"
              label="数据库字段类型"
              v-if="!ApiFlowChart"
               width="200"
              :show-overflow-tooltip="true"
            >
               <template #default="scope"> 
                <el-select
                :disabled="isView"
                  v-model="scope.row.dbFieldType"
                  placeholder="请选择数据类型"
                >
                  <el-option v-for="type in paramsTypes" :key="type.label" :label="type.label" :value="type.value" />
                  </el-select>
                  </template>
            </el-table-column> 
            <el-table-column
              prop="dbFieldLength"
              label="数据库字段长度"
              v-if="!ApiFlowChart"
               width="200"
            >
              <template #default="scope">
                <el-input-number
                :disabled="isView"
                  v-model="scope.row.dbFieldLength"
                ></el-input-number>
              </template>
            </el-table-column> 
             <el-table-column
              prop="apiFieldName"
              label="api字段名"
               width="300"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                
                  v-model="scope.row.apiFieldName"
                  maxlength="50"
                  placeholder="请输入参数名称"
                  :disabled="isView"
                ></el-input>
              </template>
            </el-table-column> 
            
            <el-table-column
              prop="apiFieldType"
              label="api字段类型"
               width="200"
              :show-overflow-tooltip="true"
            >
               <template #default="scope">
                <el-select
                :disabled="isView"
                  v-model="scope.row.apiFieldType"
                  placeholder="请选择数据类型"
                >
                 <el-option v-for="type in paramsTypes" :key="type.label" :label="type.label" :value="type.value" />
                </el-select>
                  </template>
            </el-table-column> 
            <el-table-column
              prop="apiFieldLength"
              label="api字段长度"
               width="200"
            >
              <template #default="scope">
                <el-input-number
                :disabled="isView"
                  v-model="scope.row.apiFieldLength"
                ></el-input-number>
              </template>
            </el-table-column> 
            <el-table-column
              prop="sort"
              label="排序"
               width="200"
            >
              <template #default="scope">
                <el-input-number
                :disabled="isView"
                  v-model="scope.row.sort"
                ></el-input-number>
              </template>
            </el-table-column> 
            <el-table-column
              prop="paramFun"
               width="500"
              :show-overflow-tooltip="true"
            >
            <template #header>
              <span style="display:flex;align-items:center;">输出函数 
                <el-tooltip 
                content="函数用法:函数名称(#{参数名称},固定参数值,...) 例如:trim(#{username}),对参数变量username去除前后空格"
                placement="top"
                >
                <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
                </span>
            </template>
              <template #default="scope">
                 <el-autocomplete
                 :disabled="isView"
                    v-model="scope.row.paramFun"
                    :fetch-suggestions="querySearch"
                    value-key="functionName"
                    label="functionName"
                    clearable
                    style="width:100%;"
                  />
              </template>
            </el-table-column> 
            <el-table-column
                  prop="example"
                  label="示例值"
                  width="200"
                  :show-overflow-tooltip="true"
                >
                  <template #default="scope">
                    <el-input
                      clearable
                      v-model="scope.row.example"
                      placeholder="请输入"
                      maxlength="50"
                    ></el-input>
                  </template>
                </el-table-column>
            <el-table-column
              prop="fieldDescription"
              label="描述"
              width="300"
              :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <el-input
                :disabled="isView"
                  v-model="scope.row.fieldDescription"
                  placeholder="请输入描述"
                  maxlength="50"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" fixed="right" v-if="!isView">
              <template #default="scope">
                <el-popconfirm
                  title="删除后将无法恢复，请确认是否删除？"
                  confirm-button-type="danger"
                  icon="warning-filled" icon-color="#F56C6C"
                  width="250" placement="top-end"
                  @confirm="deletefieldMappingListRow(scope.$index)">
                  <template #reference>
                    <el-button icon="delete" type="danger" text :disabled="scope.row.isDisable"></el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
           <el-form  :model="form"  label-position="top" style="padding-top:20px;">
                <el-row :gutter="24">
                    <el-col :span="24" align="left">
                <el-form-item label="返回结果示例">
                  <span style="color:#606266;padding-right:20px;">是否作为Mock数据</span> 
                  <el-radio-group v-model="form.isMock" :disabled="isView">
                          <el-radio label="0">是</el-radio>
                          <el-radio label="1">否</el-radio>
                    </el-radio-group>
                    <CodeMirror  class="codemirror" v-model:value="form.apiResponseE" ></CodeMirror>
                
                  </el-form-item>
                  </el-col>
                  <el-col :span="24" align="left">
                  <el-form-item label="Mock数据" v-if="form.isMock === '1'"> 
                        <CodeMirror  class="codemirror"  v-model:value="form.mockData" ></CodeMirror>
                  </el-form-item>
                    </el-col>
                </el-row>
           </el-form>
          </div>
    
    <div class="footer" align="right">
      <div class="btn">
        <el-button  @click="cancel">返回</el-button>
        <el-button type="primary" @click="save"  v-if="!isView">提交</el-button>
        <el-button type="primary" @click="nextStep" v-if="ApiFlowChart">下一步</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted} from 'vue';
import {request} from '@/api'
import { ElMessage } from "element-plus";
import { useRouter,useRoute } from "vue-router";
import useClipboard from 'vue-clipboard3'
import { paramsTypesList,RequestList, resultDataBuildList } from '@/utils/constant'
import CodeMirror from '@/components/common/CodeMirror'

export default {
  name: 'toDevelopServices',
  components:{
    CodeMirror
  },
  props:{
    ApiFlowChart:{
      type:Boolean,
      default:false
    }
  },
  setup (props) {
    const router = useRouter();
    const route = useRoute();
    const paramsTypes = ref(paramsTypesList)
    const Requests = ref(RequestList)
    const resultDataBuilds= ref(resultDataBuildList)
    const isView = ref(route?.query?.operation === 'view')
    let treeData = ref([])
    let functionList = ref([])
    let fieldMappingList = ref([])
    let paramList = ref([])
     let businessCateOptions = ref([])
     let apiVersionList = ref([{
      value:1,
      label:1
    }])

    let domain = ref('')
    let userList = ref([])
    let apiOwnerList = ref([])
    let designUserList = ref([])
    let apiServicetypeList = ref([
      {
        value:'select',
        label:'查询服务'
      },
      {
        label:'写入服务',
        value:'insert',
      },
      {
        label:'修改服务',
        value:'update',
      },
      {
        label:'删除服务',
        value:'delete',
      },
    ])
    let ruleFormRef = ref(null)
    let form = ref({
      apiResponseE:'',
      mockData:'',
      isMock:'0',
      apiServicetype:'',
      apiIssecurity:'Y',
      apiVersion:1,
      businessCateId:[],//分类ID
      apiName:'',
      apiRequestM: '1',
      fieldMappingList:[],
      apiUrl:'',
      apiOwner:'',
      apiOwnerName:'',
      apiOwnerNumber:'',
      copyApiOwner:'',
      developerUserId:'',
      developerUserName:'',
      developerUserNumber:'',
      apiDescription:'',
      categoryId:'',
      copyDeveloperUserId:'',
      resultDataBuild:'',
    })

    const validateUrl = (rule, value, callback) => {
      const reg = /((\/[a-zA-Z0-9_-]+){1,}(({[a-zA-Z0-9]+}){0,}))$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(
          new Error(
            "支持英文，数字，下划线，连接符（-），不超过200个字符，且只能以 / 开头, 以英文，数字结尾"
          )
        );
      }
    };

    const validateResultDataBuild = (rule, value, callback) => {
      if (!value && form.value.apiServicetype === 'select') {
        callback(
          new Error( "请选择返回数据结构")
        );
      } else {
        callback();
      }
    };
    const basicRules = reactive({
      apiName: [
        { required: true, message: "请输入名称", trigger: "blur" },
        { min: 1, max: 100, message: "长度在 1 到 100 个字符", trigger: "blur" },
      ],
      apiOwner: [
        { required: true, message: "请输入api责任人", trigger: "blur" }
      ],
      apiServicetype: [
        { required: true, message: "请选择服务类型", trigger: "change" },
      ],
      apiRequestM: [
        { required: true, message: "请选择请求方式", trigger: "change" },
      ],
      resultDataBuild: [
        { validator: validateResultDataBuild, trigger: ["blur","change"] },
      ],
      apiVersion:[
        { required: true, message: "请选择服务版本", trigger: "change" },
      ],
      businessCateId: [
        { required: true, message: "请选择分类", trigger: "change" },
      ],
      categoryId: [
        { required: true, message: "请选择挂载目录", trigger: "change" },
      ],
      apiUrl: [
        { required: true, message: "请输入接口路径", trigger: "blur" },
        { min: 1, max: 200, message: "长度在 1 到 200 个字符", trigger: "blur" },
        { validator: validateUrl, trigger: "blur" },
      ],
      developerUserId: [
        { required: true, message: "请选择", trigger: "change" },
      ],
      apiDesigner: [
        { required: true, message: "请选择", trigger: "change" },
      ],
    });

    const addRow = () => {
      paramList.value.push({
        paramNameCm:'',
        paramName:'',
        paramValue:'',
        paramType:'',
        paramDescription:'',
        sort:0,
        paramIsMust:'N',
        paramFun:'',
        example:''
      }); //在paramList表格数组中添加对象
    }

    const deleteRow = (index) => {
       paramList.value.splice(index, 1);
    };

    const addfieldMappingListRow = () => { 
      fieldMappingList.value.push({
        apiFieldCnname:'',
        dbFieldName:'',
        dbFieldType:'',
        dbFieldLength:'',
        apiFieldName:'',
        apiFieldType:'',
        apiFieldLength:'',
        sort:0,
        fieldDescription:'',
        paramFun:'',
        example:''
      }); //在paramList表格数组中添加对象
    }

    const deletefieldMappingListRow = (index) => {
       fieldMappingList.value.splice(index, 1);
    };

    // const treeToData = (tree) => {
    //   return tree.reduce((preResult, item) => {
    //     const { children, ...data } = item;
    //     return preResult.concat(
    //       data,
    //       children && children.length ? treeToData(children) : []
    //     );
    //   }, []);
    // };

    const getTreeData = () => {
      //查询列表
      request.getListTree({appId:localStorage.getItem("appId"),filterText:''}).then((res) => {
        if (res.data.code == 200) {
          // const list = treeToData([res.data.data]);
          // 过滤categoryId为-1的项
          treeData.value = res.data.data.children || []
        }
      });
    };

    const getBusinessCateOptions = ()=>{
      request.selectBusinessCatePage({
          currentPage: 1,
          pageSize: 10000
        }).then((res) => {
        if (res?.data?.code === 200) {
          businessCateOptions.value = res.data.data
        }
      });
    }

    const getdesignUser = (query)=>{
      getUserList(query,'developerUserId')
    }

    const getApiOwnerList = (query)=>{
      getUserList(query,'apiDesigner')
    }
    
    const getUserList = (query,type)=>{
      if (query) {
       request.selectUserByParams({
            likeData:query,
            appId:localStorage.getItem('appId'),
            currentPage: 1,
            pageSize: 10000
          }).then((res) => {
          if (res?.data?.code === 200) {
            if(type === 'developerUserId'){
              designUserList.value =  res.data.data.result
            }else{
              apiOwnerList.value = res.data.data.result
            }
          }
        });
      } else {
        if(type === 'developerUserId'){
          designUserList.value =  []
        }else{
          apiOwnerList.value = []
        }
      }
    }

    
    const querySearch = (queryString, cb) => {
      let results 
      let restaurants = functionList.value
      if(queryString && queryString !== 'null'){
          results = restaurants.filter(createFilter(queryString))
      }else{
          results = restaurants
      }
      cb(results)
    }

    const createFilter = (queryString) => {
      return (restaurant) => {
        return (
          restaurant.functionName.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
      }
    }

    const changeDesignUser = (val)=>{
        let user =  designUserList.value.filter(item => {
          return item.userId === val
        })
        if(user.length > 0){
          form.value.developerUserName = user[0].userName
          form.value.developerUserNumber = user[0].account
        }
        form.value.copyDeveloperUserId = val
    }

    const changeApiOwner = (val) => {
      let user = apiOwnerList.value.filter((item) => {
        return item.userId === val;
      });
      if (user.length > 0) {
        form.value.apiOwnerName = user[0].userName;
        form.value.apiOwnerNumber = user[0].account;
      }
      form.value.copyApiOwner = val
    };

    const saveApi = (action)=>{
      let saveParams = {
        ...form.value,
        oneBusinessCateId:form.value.businessCateId[0],
        twoBusinessCateId:form.value.businessCateId[1],
        apiOwner:form.value.copyApiOwner,
        paramList: paramList.value,
        fieldMappingList: fieldMappingList.value,
        appId:localStorage.getItem("appId"),
        apiId:route.query.apiId?route.query.apiId:null,
        developerUserId:route.query.apiId?form.value.copyDeveloperUserId:form.value.developerUserId 
      }
      if(route.query.apiId){
        saveParams.operateType = "1"
      }

      if(props.ApiFlowChart){
        saveParams.apiInputParamters =  JSON.stringify(paramList.value)
        saveParams.apiOutputParamters = JSON.stringify(fieldMappingList.value)
        request.addOrUpdateArrangeApi({
          iasmApiArrange:saveParams,
          apiArrangeParamGson:saveParams.apiArrangeParamGson,
          apiArrangeConfigConfigs:saveParams.apiArrangeConfigConfigs
          }).then((resp) => {
          let respCode = resp.data.code;
          if (respCode == "200") {
            if(action && action === 'nextStep'){
              let apiId = resp.data.data.iasmApiArrange.apiId
               router.push(`/home/ApiFlowChart/ApiFlowChartManagementDetail?apiId=${apiId}&operation=${route?.query?.operation}`)
               return
            }
            ElMessage.success('成功');
          } else {
            ElMessage.error(resp.data.msg);
          }
        });
      }else{
        saveParams.status = 'design'
        request.saveApiBasicInfo(saveParams).then((resp) => {
          let respCode = resp.data.code;
          if (respCode == "200") {
            ElMessage.success('成功');
            cancel()
          } else {
            ElMessage.error(resp.data.msg);
          }
        });
      }
    }
    
    const save =  async (action)=>{
      if(!ruleFormRef.value) return
        await ruleFormRef.value.validate((valid) => {
        if (valid) {
          saveApi(action);
        }
      });
    }

    const nextStep = ()=>{
      if(isView.value){
         router.push(`/home/ApiFlowChart/ApiFlowChartManagementDetail?apiId=${form.value.apiId}&operation=${route?.query?.operation}`)
         return
      }
      save('nextStep')
    }

    const cancel = ()=>{
      router.back(-1)
    }

    const getInfo = async ()=>{
      let apiId = route.query.apiId
      let result
      if(props.ApiFlowChart){
        result = await request.getApiArrangeDetails({apiId})
        result = result.data
      }else{
        result = await request.getApiInfo({apiId})
        result = result.data
      }
         if (result.code == "200") {
          if(props.ApiFlowChart){
            form.value = {...result.data.iasmApiArrange,apiArrangeParamGson:result.data.apiArrangeParamGson,apiArrangeConfigConfigs:result.data.apiArrangeConfigConfigs}
            paramList.value = form.value.apiInputParamters?JSON.parse(form.value.apiInputParamters) : []
            fieldMappingList.value = form.value.apiOutputParamters?JSON.parse(form.value.apiOutputParamters) : []
          }else{
             let {
            developerUserId,
            developerUserName,
            developerUserNumber,
          } = result.data
            form.value = {
              ...result.data,
               developerUserId:developerUserName + ' ' + developerUserNumber,
               copyDeveloperUserId:developerUserId,
            }
            paramList.value = form.value.paramList
            fieldMappingList.value = form.value.fieldMappingList
          }
          form.value.copyApiOwner = form.value.apiOwner
          form.value.apiOwner = form.value.apiOwnerName + ' ' + form.value.apiOwnerNumber
          form.value.businessCateId = [form.value.oneBusinessCateId,form.value.twoBusinessCateId]
          form.value.apiResponseE = result.data.apiResponseE?JSON.stringify(JSON.parse(result.data.apiResponseE),null,4):""
          form.value.mockData = result.data.mockData||""


          getApiCallUrl()
        } else {
          ElMessage.error('异常');
        }
    }

    const getApiCallUrl = ()=>{
      let apiType = 'platform_api'
      if(props.ApiFlowChart){
        apiType = 'arrange_api'
      }
      request.getApiCallUrl({
         apiType,
         apiUrl:'/test',
         status:'online'
      }).then((res) => {
            if (res?.data?.code === 200) {
                domain.value = res.data.data.domin + res.data.data.projectpath + res.data.data.urlprefix
            } else {
                ElMessage.error(res?.data?.msg);
            }
      });
    }

    const getOverallFunction = ()=>{
      request.getOverallFunction({
          currentPage: 1,
          pageSize: 100,
          
        }).then((res) => {
        if (res?.data?.code === 200) {
          functionList.value = res.data.data?.result || []
        } else {
          ElMessage.error('失败')
        }
      });
    }

    const copyUrl = async ()=>{
       const {toClipboard} = useClipboard()
        try{
          await toClipboard(domain.value + form.value.apiUrl)
          ElMessage.success('复制成功');
        } catch (e){
          ElMessage.error('复制失败');
        }
    }

   
    onMounted(()=>{
        getBusinessCateOptions()
        getTreeData()
        getOverallFunction()

        if(route.query.apiId){
          getInfo()
        }else{
          getApiCallUrl()
        }

        
        
    })
    return {
        nextStep,
        querySearch,
        getOverallFunction,
        functionList,
        apiOwnerList,
        changeApiOwner,
        paramsTypes,
        Requests,
        resultDataBuilds,
        domain,
        copyUrl,
        getApiCallUrl,
        addfieldMappingListRow,
        deletefieldMappingListRow,
        ruleFormRef,
        getUserList,
        getApiOwnerList,
        getdesignUser,
        designUserList,
        changeDesignUser,
        basicRules,
        form,
        getTreeData,
        treeData,
        businessCateOptions,
        getBusinessCateOptions,
        paramList,
        fieldMappingList,
        userList,
        apiServicetypeList,
        apiVersionList,
        addRow,
        deleteRow,
        save,
        cancel,
        isView
    }
  },
}
</script>

<style lang="less" scoped>
  .footer {
    margin-top: 24px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 64px;
    line-height: 64px;
    background: #fff;
    z-index: 10;
    .btn {
      padding-right: 24px;
    }
  }
</style>