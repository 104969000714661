<template>
<div>
 
  <div class="main">
     <el-button type="primary" style="position:absolute;top:-25px;right:20px;" v-if="showReturnBtn" @click="prevStep">返回</el-button>
    <div class="request">
      <div align="left" class="step-title margin-botton-12">
        <strong>基本信息</strong>
      </div>
      <el-form
        ref="ruleFormRef"
        :model="form"
        :rules="basicRules"
        label-position="top"
      >
        <el-form-item label="App Key:">
          <el-input v-model="form.appCode"  />
        </el-form-item>
        <el-form-item label="App Secret:">
          <el-input v-model="form.secretkey"  />
        </el-form-item>
        <el-form-item label="Version:" prop="apiVersion">
          <el-input v-model="form.apiVersion"  />
        </el-form-item>
        <el-form-item label="URL链接:" prop="url">
          <el-input v-model.trim="form.url"  />
        </el-form-item>
      </el-form>

      <div align="left" class="step-title margin-botton-12">
        <strong>输入参数</strong>
      </div>
      <el-button type="primary" icon="plus" @click="addRow"
      style="margin-bottom:20px;"
        >新增输入参数</el-button
      >
<div style="width:100%;">
      <el-table :data="apiRequestE" max-height="285" >
        <el-table-column
          prop="paramName"
          label="参数名称"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <el-input
              v-model="scope.row.paramName"
              maxlength="50"
              placeholder="请输入参数名称"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="paramValue"
          label="默认值"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <!-- <el-select
              v-if="scope.row.parameterType === 'authenticationParameters'"
              v-model="scope.row.paramValue"
                clearable
                filterable
                placeholder="请输入搜索关键字"
              style="width: 100%"
              >
                    <el-option
                      v-for="parameter in authenticationParameterList"
                      :key="parameter.apiAuthId"
                      :label="parameter.apiAuthName"
                      :value="parameter.apiAuthId"
                    />
            </el-select> -->
            <span  v-if="scope.row.parameterType === 'authenticationParameters'"></span>
            <el-input
              v-else
              v-model="scope.row.paramValue"
              clearable
              maxlength="50"
              placeholder="请输入默认值"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="parameterPosition"
          label="参数位置"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <el-select
              v-model="scope.row.parameterPosition"
              placeholder="请选择参数位置"
            >
              <el-option label="Header" value="Header" />
              <el-option label="URLPath" value="URLPath" />
              <el-option label="QueryParam" value="QueryParam" />
              <el-option label="Body" value="Body" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作"  align="center" width="100">
                  <template #default="scope">
                    <el-popconfirm
                      title="删除后将无法恢复，请确认是否删除？"
                      confirm-button-type="danger"
                      icon="warning-filled"
                      icon-color="#F56C6C"
                      width="250"
                      placement="top-end"
                      @confirm="deleteRow(scope.$index)"
                    >
                      <template #reference>
                        <el-button
                          icon="delete"
                          type="danger"
                          text
                        ></el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
      </el-table>
      </div>
    </div>
    <div class="btn" @click="testApi" v-loading="showRotate">
      <el-icon :size="40" color="#fff" style="margin-bottom: 10px"
        ><Refresh :spin="true"
      /></el-icon>
      运行
    </div>
    <div class="response">
      <div align="left" class="step-title margin-botton-12">
        <strong>输出结果</strong>
        <el-button type="primary" v-if="showBtn" style="float:right;margin-bottom:12px;" @click="saveApiResponseE">同步结果到API返回示例中</el-button>
      </div>

      <div style="height: calc(100% - 35px)">
         <CodeMirror  class="codemirror"  v-model:value="apiResponse"  style="height:100%;"></CodeMirror>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { request } from "@/api";
import {  useRoute,useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import CodeMirror from '@/components/common/CodeMirror'
// import {request} from '@/api'
export default {
  components: {
    CodeMirror,
  },
  setup() {
    // let authenticationParameterList = ref([])
    const route = useRoute()
    const router = useRouter()
    const apiType = route.query.apiType
    const apiId = route.query.apiId
    let showReturnBtn = ref(route.query.apiId?true:false)

    let showRotate = ref(false)
    let showBtn = ref(false)
    const ruleFormRef = ref(null);
    let cmRef = ref(null);
    let apiResponse = ref(null);

    let apiRequestE = ref([]);

   

    let form = ref({
      appCode: '',
      secretkey: '',
      apiVersion: 1,
      url: '',
      apiType:apiType,
      method:'1',
      apiOwner:'',
      createdBy:''
    });

   

    let basicRules = reactive({
      apiVersion: [{ required: true, message: "请输入", trigger: "blur" }],
      url: [
        { required: true, message: "请输入接口路径", trigger: "blur" },
        {
          min: 1,
          max: 200,
          message: "长度在 1 到 200 个字符",
          trigger: "blur",
        },
      ],
    });

    const addRow = () => {
      apiRequestE.value.push({
        parameterName: "",
        paramName: "",
        paramValue: "",
        parameterPosition: "Body"
      });
    };

    const deleteRow = (index) => {
      apiRequestE.value.splice(index, 1);
    };

    const testApi = async ()=>{
      await ruleFormRef.value.validate((valid) => {
        if (valid) {
          showRotate.value = true
          request
              .testApi({
                ...form.value,
                url:form.value.url.replace(/ /g,''),
                params:apiRequestE.value
                })
              .then((res) => {
                apiResponse.value = JSON.stringify(res.data, null, 2)
                let userId = localStorage.getItem("userId")
                if((res.data.status == 200 || res.data.code == 200) && (userId === form.value.apiOwner || userId === form.value.createdBy)){
                  showBtn.value = true
                }else{
                  showBtn.value = false
                }
            }).finally(()=>{
              showRotate.value = false
            });  
        }
      });
    }

    const saveApiResponseE = async ()=>{
      let res = await request.saveApiResponseE({apiId,apiResponseE:apiResponse.value})
      if(res.data.code == 200){
        ElMessage.success('同步成功')
      }
    }

    const getApiInfo = async ()=>{
      let res = null
      let result
      //api管理
   
      res = await request.getApiInfo({apiId})
          if(res.data.code == 200){
            result = res.data.data
            apiRequestE.value  = result.requestParamters?JSON.parse(result.requestParamters) : [];
            let {callUrl,appAuthCode,appAuthKey,apiVersion,apiRequestM,apiOwner,createdBy} = result
            form.value ={
                appCode:appAuthCode,
                secretkey: appAuthKey,
                apiVersion,
                url:callUrl,
                apiType,
                method:apiRequestM,
                apiOwner,
                createdBy
            };

            apiRequestE.value = apiRequestE.value.filter(item=>{
              return item.parameterType !== 'authenticationParameters'
            })
      }
      
      }

      const prevStep = ()=>{
        router.go('-1')
      }

    
    onMounted(()=>{
      if(apiId){
        getApiInfo()
      }
    })

    return {
      // getApiAuthList,
      // authenticationParameterList,
      prevStep,
      cmRef,
      ruleFormRef,
      testApi,
      form,
      basicRules,
      apiRequestE,
      addRow,
      deleteRow,
      apiResponse,
      showRotate,
      showReturnBtn,
      showBtn,
      saveApiResponseE
    };
  },
};
</script>
<style lang="less" scoped>
.main {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  height: 800px;
  .request {
    width:calc(50% - 150px);
    height: 100%;
    margin: 76px 20px 20px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 3px 6px 15px 5px rgba(21, 69, 137, 0.16);
  }
  .btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 150px;
    border-radius: 40px;
    background-color: #1890ff;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    .rotate {
      animation: rotate 2s linear infinite;
    }

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(-360deg);
      }
    }
  }
  .response {
    flex:1;
    overflow-x: auto;
    height: 100%;
    margin: 76px 20px 20px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 3px 6px 15px 5px rgba(21, 69, 137, 0.16);
  }
}
</style>
