<template>
  <div class="main">
    <div class="content">
        <el-icon :size="100" color="#67c23a"><SuccessFilled /></el-icon>
        <div style="font-size:30px;font-weight:500;margin-bottom:30px;">提交成功</div>
        <div>
            <el-button type="primary" @click="gotoList">返回列表</el-button>
            <el-button @click="testApi">测试</el-button>
        </div>
    </div>
  </div>
</template>

<script>
import {  useRouter,useRoute } from "vue-router";
export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const apiId = route.query.apiId
    const listType = route.query.listType
    const apiType = route.query.apiType

    const testApi = ()=>{
      router.push({
        path:"/home/serviceOperation/ApiTest",
        query:{
          apiId,
          apiType
        }
      });
    }

    const gotoList = ()=>{
      if(listType == '3'){
        router.push("/home/mine/toDevelopServices");
      }else{
        router.push("/home/ApiManage/ApiManagelist");
      }
      
    }
    return {
        testApi,
        gotoList
    };
  },
};
</script>
<style lang="less" scoped>
.main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  .content{

  }
 
}
</style>
