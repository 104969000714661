<template>
    <div class="fields-config">
        <el-row :gutter="12">
            <el-col :span="12">
                <div class="selectTable">
                    <span>选择数据集：</span>
                    <el-select v-model="tableName" @focus="getTableList" @change="changeTable">
                        <el-option v-for="(item, index) in tableData.tableList" :key="index" :value="item.id" :label="item.id"></el-option>
                    </el-select>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="12">
            <el-col :span="12">
                <el-table ref="allTable" :data="tableData.allData" border height="400" :show-overflow-tooltip="true" @select="handleSelect" @select-all="handleSelectAll">
                    <el-table-column type="selection" width="40"></el-table-column>
                    <el-table-column prop="columnName" label="字段名"></el-table-column>
                    <el-table-column prop="columnType" label="字段类型"></el-table-column>
                    <el-table-column prop="columnLength" label="字段长度"></el-table-column>
                    <el-table-column prop="remarks" label="字段描述"></el-table-column>
                </el-table>
            </el-col>
            <el-col :span="12">
                <el-table ref="selectTable" :data="tableData.selectData" border height="400" :show-overflow-tooltip="true" @select="cancelSelectd" @select-all="cancelSelectdAll">
                    <el-table-column type="selection" width="40"></el-table-column>
                    <el-table-column prop="columnName" label="字段名"></el-table-column>
                    <el-table-column prop="tableName" label="表名"></el-table-column>
                    <el-table-column prop="fieldAlias" label="字段别名">
                        <template #default="scope">
                            <el-input v-model="scope.row.fieldAlias" placeholder="字段别名" clearable></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="columnType" label="字段类型"></el-table-column>
                    <el-table-column prop="columnLength" label="字段长度"></el-table-column>
                    <el-table-column prop="remarks" label="字段描述"></el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { nextTick, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
export default {
    props: {
        data: Array
    },
    setup(props) {
        const store = useStore()
        const tableName = ref('')
        const allTable = ref()
        const selectTable = ref()
        const tableData = reactive({
            tableList: [],
            allData: [], // 选择数据集下的所有字段
            selectData: [] // 选中的字段
        })

        const getTableList = () => {
            const { canvasData } = store.state
            tableData.tableList = canvasData?.nodes || []
        }

        const changeTable = () => {
            const { selectedTableObj } = store.state
            tableData.allData = selectedTableObj[tableName.value] || []
            const selArr = []
            tableData.allData.forEach(item => {
                tableData.selectData.forEach(el => {
                    if (el.tableName === tableName.value && el.columnName === item.columnName) {
                        selArr.push(item)
                        allTable.value.toggleRowSelection(item, true)
                    }
                });
            });
            nextTick(() => {
                selArr.forEach(row => {
                    allTable.value.toggleRowSelection(row, true)
                });
                
            })
            
            
        }

        const handleSelect = (list) => {
            tableData.selectData = tableData.selectData.filter(el => el.tableName !== tableName.value)
            tableData.selectData = tableData.selectData.concat(list.map(el => {
                el.tableName = tableName.value
                el.fieldAlias = el.fieldAlias || el.columnName
                el.columnAlias = el.fieldAlias
                el.dataType = el.columnType || ''
                el.columnCname = el.remarks
                el.tableNameAlias = el.tableName
                return el
            }))
            selectTable.value.toggleAllSelection(true)
        }

        const handleSelectAll = (list) => {
            handleSelect(list)
        }

        const cancelSelectd = (list, row) => {
            tableData.selectData = list
            selectTable.value.toggleAllSelection(true)
            const name  = row.tableName
            if (name === tableName.value) {
                const curRow = tableData.allData.filter(el => el.columnName === row.columnName)
                curRow.length > 0 ? allTable.value.toggleRowSelection(curRow[0], false) : ''
            }
        }

        const cancelSelectdAll = (list) => {
            if (list.length === 0) {
                tableData.selectData = []
                tableData.allData.forEach(el => {
                    if (el.tableName === tableName.value) {
                        allTable.value.toggleRowSelection(el, false)
                    }
                });
            }
            
        }

        onMounted(() => {
            tableData.selectData = (props.data || []).map(el => {
                el.fieldAlias = el.columnAlias || el.columnName
                el.columnAlias = el.fieldAlias
                el.remarks = el.columnCname
                el.columnType = el.dataType
                return el
            })
            selectTable.value.toggleAllSelection(true)
        })

        // 删除节点时同步清除选中的已删除节点的字段
        watch(
            () => store.state.canvasData.nodes,
            () => {
                const tables = store.state.canvasData.nodes.map(el => el.id)
                tableData.selectData = tableData.selectData.filter(el => tables.includes(el.tableName))
                if (!tables.includes(tableName.value)) {
                    tableName.value = ''
                    changeTable()
                }
            }
        )
        return {
            tableName, tableData, allTable, selectTable, getTableList, changeTable, handleSelect, handleSelectAll, cancelSelectd, cancelSelectdAll
        }
    }
}
</script>

<style lang="less" scoped>
    .fields-config {
        height: 450px;
        .selectTable {
            text-align: left;
            margin-bottom: 12px;
            font-size: 14px;
            & /deep/ .el-select {
                width: calc(100% - 85px);
            }
        }
    }
</style>