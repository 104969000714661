<template>
    <div class="data-discovery--main">
        <el-tabs :before-leave="beforeLeaveTab" v-model="tabsValue">
            <el-tab-pane name="conf" v-if="false">
                <template #label>
                    <el-icon><Operation/></el-icon> 界面开发
                </template>

                <el-tabs type="border-card">
                    <el-tab-pane label="关系配置">
                        <RalationConfig :key="showFlag" :ref="el => el ? relRef = el : ''" :data="init.relation"></RalationConfig>
                    </el-tab-pane>
                    <el-tab-pane label="条件配置" style="overflow-y: auto;">
                        <ConditionConf :key="showFlag" :ref="el => el ? conditionsRef = el : ''" :data="init.condition" :paramList="paramList"></ConditionConf>
                    </el-tab-pane>
                    <el-tab-pane label="字段配置">
                        <FieldsConf :key="showFlag" :ref="el => el ? fieldsRef = el : ''" :data="init.fields"></FieldsConf>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>

            <el-tab-pane name="inp">
                <template #label>
                    <div class="tab_item_bottom">
                        <el-icon><Edit/></el-icon> SQL开发
                    </div>
                </template>
                <el-tabs type="border-card" v-model="editSqlTabsValue" :before-leave="() => { return false }">
                    <el-tab-pane label="SQL" name="1">
                        <div class="sql-config">
                            <CodeMirror :key="showFlag" class="codemirror" v-model:value="sqlVal" :options="cmOptions" border ref="cmRef" @change="changeSql"></CodeMirror>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane name="2" @click.prevent="" v-if="apiServicetype === 'select'">
                        <template #label>
                            <div>
                                <el-button text type="primary" @click="sqlValid">SQL校验</el-button>
                            </div>
                        </template>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>

<script>
import { ref, nextTick, reactive, onUnmounted } from 'vue'
import RalationConfig from '@/components/DataDiscovery/InterfaceConfig/relationConfig.vue'
import ConditionConf from '@/components/DataDiscovery/InterfaceConfig/conditionConfig.vue'
import FieldsConf from '@/components/DataDiscovery/InterfaceConfig/fieldsConfig.vue'
import 'codemirror/mode/javascript/javascript.js'

import CodeMirror from 'codemirror-editor-vue3'

import 'codemirror/addon/display/autorefresh'
import 'codemirror/mode/sql/sql'
import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/hint/sql-hint'

import 'codemirror/addon/hint/show-hint.css'
import { ElMessageBox } from 'element-plus'
import store from '@/store';
export default {
    components: {
        RalationConfig, ConditionConf, FieldsConf, CodeMirror
    },
    props: {
        list: Object,
        paramList: Array,
        initData: Object,
        mysql: String,
        apiServicetype: String,
    },
    setup(props, { emit }) {
        const showFlag = ref(false)
        const relRef = ref(null)
        const conditionsRef = ref(null)
        const fieldsRef = ref(null)
        const editSqlTabsValue = ref('1')
        const name = props.initData?.mainContainer?.tabsValue || 'inp'
        const tabsValue = ref(name)

        const init = reactive({
            condition: [],
            fields: [],
            relation: []
        })

        let sqlVal = ref('')

        const cmRef = ref()

        const cmOptions = {
            mode: 'text/x-mysql',
            smartIndent: true, // 智能缩进
            lineNumbers: true, // 是否显示行号
            indentUnit: 2, // 智能缩进单位为4个空格长度
            foldGutter: true, // 启用行槽中的代码折叠
            tabSize: 2, // 缩进
            lineWrapping:true,//自动换行
            styleActiveLine: true, // 高亮选中行
            matchBrackets: true, // 括号匹配
            // cursorHeigt: 0.9,
            theme: 'default', // 主题名称
            autofocus: true,
            autoRefresh: true,
            extraKeys: {
                'Ctrl': 'autocomplete'
            },
            hintOptions: {
                completeSingle: false
            }
            // readOnly: false // 是否只读
        }

        nextTick(() => {
            // sqlVal.value = props.mysql
            const { conditionsRef, fieldsRef, relRef, sqlVal: value } = props.initData.mainContainer
            sqlVal.value = value || props.mysql
            let condl = conditionsRef?.conditionData?.list || []
            if (condl.length === 0) {
                condl = props.list?.filter || []
            }

            let columns = fieldsRef?.tableData?.selectData || []
            if (columns.length === 0) {
                columns = props.list?.displayColumn || []
            }

            let relData = relRef?.state?.inlineRelList || []
            if (relData.length === 0) {
                relData = props.list?.relation || []
            }

            init.condition = condl
            init.fields = columns
            init.relation = relData
            showFlag.value = !showFlag.value
        })

        // 切换数据集时清空所有配置数据
        const clearAllData = () => {
            // 清空sql语句
            sqlVal.value = '-- 请双击表查看示例SQL\n'
            init.relation = []
            init.condition = []
            init.fields = []
            showFlag.value = !showFlag.value
        }

        const setMySql = (example) => {
            if (!sqlVal.value.includes(example)) { // 避免重复添加注释
                sqlVal.value = `${example}\n\n${sqlVal.value}`
            }
        }

        const beforeLeaveTab = async (name) => {
            const title = name === 'conf' ? 'SQL开发' : '界面开发'
            let flag = false
            await ElMessageBox.confirm(`确认是否清空${title}配置数据？`)
                .then(() => {
                    if (name === 'conf') {
                        sqlVal.value = ''
                    } else {
                        init.condition = []
                        init.fields = []
                        init.relation = []

                        store.dispatch('updataCanvasData', {
                            nodes: [], edges: []
                        }) // 初始化拖拽数据

                        showFlag.value = !showFlag.value
                    }
                    flag = true
                })
                .catch(() => {
                    flag = false
                })
            return flag
        }

        // sql校验
        const sqlValid = () => {
            emit('sqlValid', sqlVal.value)
        }

        const changeSql = () => {
            emit('setSqlChangedFlag', true)
        }

        onUnmounted(() => {
            cmRef.value?.destroy()
        })

        return {
            showFlag,
            relRef,
            conditionsRef,
            fieldsRef,
            editSqlTabsValue,
            tabsValue,
            init,
            cmRef,
            sqlVal,
            cmOptions,
            clearAllData,
            beforeLeaveTab,
            setMySql,
            sqlValid,
            changeSql
        }
    }
}
</script>

<style lang="less" scoped>
    .data-discovery--main {
        padding-left: 24px;
        .el-tabs {
            margin-top: 6px;
            .tab_item_bottom {
                padding-bottom: 16px;
            }
        }
    }
    .sql-config {
        height: 313px;
        .codemirror {
            text-align: left;
        }
    }
    /deep/ .el-tabs__nav {
        width: 100%;
        justify-content: space-between;
    }
</style>