<template>
  <div style="display: flex; margin: 0; padding: 28px 0;">
    <div class="data-source-left-bar" style="margin-left: 0;margin-right: 28px;">
          <el-scrollbar style="width: 100%">
            <el-input
              v-model="filterText"
              placeholder="请输入"
              suffix-icon="Search"
              style="width: 192px; height: 32px; margin: 0 auto 16px"
            />
            <el-tree
              ref="treeRef"
              class="api-filter-tree"
              :data="dataSourceTreeData"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              :indent="20"
              :highlight-current="true"
            >
              <template #default="{ node }">
                <span class="custom-tree-node" @click="clickNode(node)">
                  <span :title="node.label" class="custom-tree-label">
                    <span class="tree-file-icon"></span>
                     <div  class="custom-tree-label-div" >{{ node.label }}</div>

                    （{{node.data.apiSize}}）
                  </span>
                </span>
              </template>
            </el-tree>
          </el-scrollbar>
        </div>
    <el-main
      style="
        height: 100%;
        padding: 0px;
        overflow: hidden;
        position: relative;
      "
    >
      <!-- <el-tabs v-model="tabActive" label-width="40%" class="api-param-tabs">
        <el-tab-pane label="API列表" name="1"> </el-tab-pane>
        <el-tab-pane label="我的API" name="2"> </el-tab-pane>
      </el-tabs> -->
      <div
        style="
          position: absolute;
          text-align: right;
          right:0px;
          top:10px
        "
      >
        <el-tooltip
          class="box-item"
          effect="dark"
          content="卡片"
          placement="top-start"
          :visible="visible1"
        >
          <img
            src="../../assets/image/apiMarket/card.png"
            alt=""
            v-if="!cardType"
            @click="changeShowType(true)"
            style="cursor: pointer"
            @mouseenter="visible1 = true"
            @mouseleave="visible1 = false"
          />
        </el-tooltip>
        <el-tooltip
          class="box-item"
          effect="dark"
          content="卡片"
          placement="top-start"
          :visible="visible2"
        >
          <img
            @mouseenter="visible2 = true"
            @mouseleave="visible2 = false"
            src="../../assets/image/apiMarket/card-active.png"
            alt=""
            v-if="cardType"
            style="cursor: pointer"
          />
        </el-tooltip>
        <el-tooltip
          class="box-item"
          effect="dark"
          content="列表"
          placement="top-start"
          :visible="visible3"
        >
          <img
            src="../../assets/image/apiMarket/list.png"
            alt=""
            v-if="cardType"
            @click="changeShowType(false)"
            @mouseenter="visible3 = true"
            @mouseleave="visible3 = false"
            style="cursor: pointer"
          />
        </el-tooltip>
        <el-tooltip
          class="box-item"
          effect="dark"
          content="列表"
          placement="top-start"
          :visible="visible4"
        >
          <img
            src="../../assets/image/apiMarket/list-active.png"
            alt=""
            v-if="!cardType"
            style="cursor: pointer"
            @mouseenter="visible4 = true"
            @mouseleave="visible4 = false"
          />
        </el-tooltip>
      </div>
      <div style="padding-top: 20px">
        <apiList :listType="tabActive" :key="tabActive" :cardType="cardType" :categoryId="categoryId"></apiList>
      </div>
    </el-main>
  </div>
</template>
  
  <script>
import apiList from "./apiList.vue";
import { ref } from "vue";
import {request} from '@/api'
export default {
  name: "ApiMarketMyIssue",
  components: {
    apiList,
  },
  props: {
    tabIndex: {
      type: String,
      default: () => "1", // 默认API列表
    },
    mine: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    let filterText = ref('')
    let tabActive = ref(props.tabIndex)
    let categoryId = ref('')
    const dataSourceTreeData=ref([])
      const defaultProps = {
      children: "children",
      label: "categoryName",
      apiSize:'apiSize'
    };

     
    const visible1 = ref(false);
    const visible2 = ref(false);
    const visible3 = ref(false);
    const visible4 = ref(false);
    const cardType = ref(true);

    const filterNode = (value, data) => {
      if (!value) return true;
      return data.categoryName.includes(value);
    };
    
    const getApiCategory=()=>{
      request.newListTree({
          appId: localStorage.getItem("appId"),
          categoryName: filterText.value,
          type:tabActive.value == 3? 3: tabActive.value == 1? 2 : 4,
          userId:props.mine?localStorage.getItem("userId"):null
        }).then((res) => {
        if (res.data.code == 200) {
          dataSourceTreeData.value = [res.data.data];
        }
      });
    }

    const clickNode=(node)=>{
      console.log(node,'>>>>>node.data')
      categoryId.value = node.data.categoryId
    }

    const changeShowType = (value) => {
      cardType.value = value;
      visible1.value = false;
      visible2.value = false;
      visible3.value = false;
      visible4.value = false;
    };

    getApiCategory()
    return {
      cardType,
      changeShowType,
      visible1,
      visible2,
      visible3,
      visible4,
      filterText,
      dataSourceTreeData,
      clickNode,
      defaultProps,
      filterNode,
      tabActive,
      categoryId
    };
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    },
  }
};
</script>
  
  <style scoped>
.table-btn-operate--left {
  text-align: left;
  margin-bottom: 12px;
}
</style>