<template>
    <el-popover
        placement="left-start"
        :teleported="false"
        :width="600"
        trigger="click"
        :visible="showAI"
    >
    <solt name="content">
            <div  v-loading="loading" style="margin-top:-55px;">
                <div style="position:relative;top:55px">
                    <div class="header-box">
                    <img  src="@/assets/image/AIimg.svg" alt="">
                    <div class="title">小i-智能服务台</div>
                </div>
                <div class="tip">
                    <div class="tip-top">您好，我是小i，您的AI智能助手</div>
                    <div class="tip-content">专注为您解决问题，提升工作效率而设计，我的任务是针对用户的问题与要求提供最精准的答复与支持。</div>
                    <!-- <div class="example" style="color:#0261F0;">您可以这样问：怎么批量导出数据？</div> -->
                </div>
                <div class="questions">
                    <!-- <div class="question-top">
                        <div class="example">您可以这样问</div>
                        <div class="change">
                            <el-icon :size="20" color="#0261F1" ><Refresh /></el-icon>
                            <div>换一换</div>
                        </div>
                    </div>
                    <div class="question-content">
                        <div class="item">
                            怎么样批量导入数据？
                        </div>
                    </div> -->
                </div>
                <Close style="position:absolute;right:10px;top:15px;width: 2rem; height: 2rem;cursor:pointer;" @click="closeAI"/>
                </div>
                <iframe 
                    id="iframe"
                    ref="frameRef"
                    src="http://49.7.154.168:3000/issgpt/chat/share?shareId=6t2x515i785e61t6ucuxmbc8"
                    height="400px"
                    width="550px"
                    style="border-radius:8px;border:0;overflow-y:auto;"
                    v-if="showAI"
                    @load="loadIframe"
                    >
                </iframe>
            </div>
        </solt>
        <template #reference>
            <div class="ai-btn" id="ai-btn"  @click="openAI" >
                <img class="AIsvg"  src="@/assets/image/AI.svg" alt=""   >
                <div id="cover"></div>
            </div>
        </template>
    </el-popover>
</template>

<script>
import {ref, onMounted } from 'vue'
// import { ElMessage} from 'element-plus'
export default {
    setup() {
        const frameRef = ref()
        let showAI = ref(false)
        let loading = ref(false)
        const openAI = ()=>{
            showAI.value = true
            let iframe =document.getElementById('iframe');
            if(iframe)return
            loading.value = true
        }
        const closeAI = ()=>{
            showAI.value = false
            loading.value = false
        }

        const loadIframe = ()=>{
            loading.value = false
            // const iframe = frameRef.value;
            // console.log(aa.srcElement['#document'],bb,cc,'>>>>>>>>>>iframe>>>>>>>')
            // console.log(iframe,'>>>>>>>>>>>>>>>>>',iframe.contentDocument,iframe.contentWindow)
            // if (iframe) {
            //     // 确保iframe已经加载完成
            //     if (iframe.contentDocument && iframe.contentWindow) {
            //     // 获取iframe内部的body元素
            //     const bodyElement = iframe.contentDocument.querySelector('body');
            //         console.log(bodyElement); // 输出iframe内部的body元素
            //     } else {
            //     console.error("无法获取iframe内部的DOM");
            //     }
            // } else {
            //     console.error("未能找到指定的iframe元素");
            // }
        }
         
       
        onMounted(()=>{
            //移除事件
            let dom = document.getElementById('ai-btn');
            let cover = document.getElementById('cover');

            //1.鼠标按下
            cover.onmousedown = function(event){
                //短路语法兼容事件对象
                var e = event || window.event;
                //获取鼠标距离元素左侧顶部数据
                var startX = e.offsetX;
                var startY = e.offsetY;  
                // console.log(startX,startY); 
                //2.鼠标在整个网页中移动
                cover.onmousemove =function(event1){

                    var e1= event1 || window.event;
                    //元素进行拖拽
                    dom.style.left = e1.clientX - startX + "px";
                    dom.style.top = e1.clientY - startY + "px";
                    e1.preventDefault()
                    
                }
              
            }   
            //3. 鼠标抬起事件 ---> 将鼠标移动事件移除：给这个元素绑定多次事件，后者覆盖前者。
            document.onmouseup = function(){
                cover.onmousemove = null;
            }
        })
        return {
            openAI,
            closeAI,
            showAI,
            loading,
            loadIframe,
            frameRef
        }
    }
}
</script>
<style lang="less">
.css-5a8asn{
    display: none;
}
</style>
<style lang="less" scoped>
.header-box{
    display: flex;
    align-content: center;
    margin-bottom: 15px;
   img{
     height: 48px;
     width: 48px;
   }
   .title{
    height: 48px;
    line-height: 48px;
    margin-left: 11px;
    font-family: PingFangSC-Semibold;
    font-size: 24px;
    color: #333333;
    letter-spacing: 0;
    font-weight: 600;
   }
}
.tip{
    background-image: linear-gradient(-80deg, #F5F2FF 0%, #DDE9FF 100%);
    border: 1px solid rgba(255,255,255,1);
    border-radius: 6px;
    padding: 24px 24px 36px;
    // margin-bottom: 12px;
    .tip-top{
        height: 33px;
        line-height: 33px;
        margin-bottom: 9px;
        font-family: AlibabaPuHuiTiB;
        font-size: 24px;
        color: #0261F1;
        letter-spacing: 0;
        font-weight: 700;
    }
    .tip-content{
        font-family: AlibabaPuHuiTiR;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 400;
        margin-bottom: 10px;
    }
}
.questions{
    // background: #F5F9FF;
    background: #fff;
    border-radius: 8px;
    padding: 16px;
    .question-top{
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        .example{
            font-family: AlibabaPuHuiTiR;
            font-size: 16px;
            color: #333333;
            letter-spacing: 0;
            font-weight: 400;
        }
        .change{
           display: flex;
           align-content: center;
           color: #0261F1;
           cursor: pointer;
        }
    }
    .question-content{
        .item{
            padding: 14px 16px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #333333;
            letter-spacing: 0;
            font-weight: 400;
            background: #FFFFFF;
            border-radius: 4px;
            cursor: pointer;
        }
    }
}
.ai-btn{
    display: flex;
    position:fixed;
    bottom:80px;
    right:30px;
    width:56px;
    height:56px;
    cursor:pointer;
    z-index:10000000000;
    justify-content: center;
    align-content: center;
    #cover{
        width: 60px;
        height: 60px;
        position: absolute;
        z-index:10000000000;
    }
}



</style>