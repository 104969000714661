import API from "@/plugins/axiosInstance";
let businessUrl =  'iods_gateway/iodsApibusinessAdmin/'
const  getListTree = (params)=>{
    return API({
        url: `${businessUrl + 'apicategory/listTree'}?appId=${params.appId}&categoryName=${params.filterText}`,
        method: 'get'
      })
}



const selectResponsible = ()=>{
    return API({
        url: businessUrl + 'form/management/selectResponsible',
        method: 'get'
      })
}




const getApiInfo = (params)=>{
    return API({
        url: businessUrl + 'apiManager/getApiInfo',
        method: 'post',
        data:params
      })
}

const selectMyAppIdIssueApiByPage = (params)=>{
    return API({
        url: businessUrl + 'mall/selectMyAppIdIssueApiByPage',
        method: 'post',
        headers:{
            'Content-Type' : 'application/json',
          },
        data:params
      })
}

const selectMyAppIdIssueApiOnlineByPage = (params)=>{
    return API({
        url: businessUrl + 'mall/selectMyAppIdIssueApiOnlineByPage',
        method: 'post',
        headers:{
            'Content-Type' : 'application/json',
          },
        data:params
      })
}

const selectMyAppIdAndUserIdIssueApiByPage = (params)=>{
    return API({
        url: businessUrl + 'mall/selectMyAppIdAndUserIdIssueApiByPage',
        method: 'post',
        data:params
      })
}

const selectAuthorizeAppByApi = (params)=>{
    return API({
        url: businessUrl + 'mall/selectAuthorizeAppByApi',
        method: 'get',
        params
      })
}

const deleteApi = (params)=>{
    return API({
        url: businessUrl + 'apiManager/deleteApi',
        method: 'post',
        data:params
      })
}

const apiUpdateStatus = (params)=>{
    return API({
        url: businessUrl + 'apiManager/apiUpdateStatus',
        method: 'post',
        data:[params]
      })
}

const changeApiBegin = (params)=>{
    return API({
        url: businessUrl + 'apiManager/changeApiBegin',
        method: 'post',
        data:params
      })
}

const deleteApiByIds = (params)=>{
    return API({
        url: businessUrl + 'apiManager/deleteApiByIds',
        method: 'post',
        data:params
      })
}

const testApiAvailability = (params)=>{
    return API({
        url: businessUrl + 'apiInvoke/testApiAvailability',
        method: 'post',
        data:params
      })
}

const apiResultExport = (params)=>{
    return API({
        url: businessUrl + 'apiResultExport',
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            Accept: "blob",
        },
        // 二进制流文件，一定要设置成blob，默认是json
        responseType: "blob",
        data:params
      })
}

const checkUrlDuplicate = (params)=>{
    return API({
        url: businessUrl + 'apiManager/checkUrlDuplicate',
        method: 'post',
        data:params
      })
}

const initApiSql = (params)=>{
    return API({
        url: businessUrl + 'apiManager/initApiSql',
        method: 'post',
        data:params
      })
}

const saveApiBasicInfo = (params)=>{
    return API({
        url: businessUrl + 'apiManager/saveApiBasicInfo',
        method: 'post',
        data:params
      })
}

const submitApiChange = (params)=>{
    return API({
        url: businessUrl + 'apiManager/submitApiChange',
        method: 'post',
        data:params
      })
}

const changApiCancel = (params)=>{
    return API({
        url: businessUrl + 'apiManager/changApiCancel',
        method: 'post',
        data:params,
      })
}

const getApiApprovalStatus = (params)=>{
    return API({
        url: businessUrl + 'mall/getApiApprovalStatus',
        method: 'get',
        params:params
      })
}

const subscribeApi = (params)=>{
    return API({
        url: businessUrl + 'mall/subscribeApi',
        method: 'post',
        data:params
      })
}

const unSubscribeApi = (params)=>{
    return API({
        url: businessUrl + 'mall/unSubscribeApi',
        method: 'post',
        data:params
      })
}

const searchSubscribeApi = (params,searchUrl)=>{
    return API({
        url: businessUrl + `mall/${searchUrl}`,
        method: 'post',
        data:params
      })
}

const selectMyIssueApiByPage = (params)=>{
    return API({
        url: businessUrl + 'mall/selectMyIssueApiByPage',
        method: 'post',
        data:params
      })
}

const selectMySubApiByPage = (params)=>{
    return API({
        url: businessUrl + 'mall/selectMySubApiByPage',
        method: 'post',
        data:params
      })
}

const selectApiInvokeTopToday = (params)=>{
    return API({
        url: businessUrl + 'overView/selectApiInvokeTopToday',
        method: 'get',
        params
      })
}

const selectApiSubTopTenByApp = (params)=>{
    return API({
        url: businessUrl + 'overView/selectApiSubTopTenByApp',
        method: 'get',
        params
      })
}

const selectApiInvokeInfo = (params)=>{
    return API({
        url: businessUrl + 'overView/selectApiInvokeInfo',
        method: 'get',
        params
      })
}

const selectApiInvokeInWeek = (params)=>{
    return API({
        url: businessUrl + 'overView/selectApiInvokeInWeek',
        method: 'get',
        params
      })
}

const apiTrafficMonitoring = (params)=>{
    return API({
        url: businessUrl + 'statistics/apiTrafficMonitoring',
        method: 'post',
        data:params
      })
}

const selectApiListByStatistics = (params)=>{
    return API({
        url: businessUrl + 'statistics/selectApiListByStatistics',
        method: 'get',
        params
      })
}

const selectInvokeDetailList = (params)=>{
    return API({
        url: businessUrl + 'statistics/selectInvokeDetailList',
        method: 'get',
        params
      })
}

const selectLogList = (params)=>{
    return API({
        url: businessUrl + 'apiLog/selectLogList',
        method: 'get',
        params
      })
}

const validName = (params)=>{
    return API({
        url: businessUrl + 'apicategory/validName',
        method: 'post',
        data:params
      })
}

const saveApicategory = (params)=>{
    return API({
        url: businessUrl + 'apicategory/save',
        method: 'post',
        data:params
      })
}

const deleteApicategory = (params)=>{
    return API({
        url: businessUrl + `apicategory/delete?categoryId=${params.categoryId}`,
        method: 'get'
      })
}

const recordByTime = (params)=>{
    return API({
        url: businessUrl + 'statistics/invoke/recordByTime',
        method: 'post',
        data:params
      })
}

const subscribeAndPublish = (params)=>{
    return API({
        url: businessUrl + 'statistics/cycle/subscribeAndPublish',
        method: 'post',
        data:params
      })
}

const getApiIndicator = (params)=>{
    return API({
        url: businessUrl + 'currentAppOverview/getApiIndicator',
        method: 'post',
        data:params
      })
}

const subscribeTop = (params)=>{
    return API({
        url: businessUrl + `statistics/top/subscribe?num=15&appId=${params.appId}`,
        method: 'get'
      })
}

const apiSubscriptionDistribution = (params)=>{
    return API({
        url: businessUrl + 'currentAppOverview/apiSubscriptionDistribution',
        method: 'post',
        data:params
      })
}

const differentApiPercentage = (params)=>{
    return API({
        url: businessUrl + 'currentAppOverview/differentApiPercentage',
        method: 'post',
        data:params
      })
}

const costInvoke = (params)=>{
    return API({
        url: businessUrl + 'statistics/invoke/cost',
        method: 'post',
        data:params
      })
}

const invokeBottom = (params)=>{
    return API({
        url: businessUrl + `statistics/bottom/invoke?num=10&appId=${params.appId}`,
        method: 'get'
      })
}

const invokeTop = (params)=>{
    return API({
        url: businessUrl + `statistics/top/invoke?num=15&appId=${params.appId}`,
        method: 'get'
      })
}

const selectApiPolicyConfigByPage = (params)=>{
    return API({
        url: businessUrl + 'apiPolicyConfig/selectApiPolicyConfigByPage',
        method: 'post',
        data:params
      })
}

const saveApiPolicyConfig = (params)=>{
    return API({
        url: businessUrl + 'apiPolicyConfig/saveApiPolicyConfig',
        method: 'post',
        data:params
      })
}

const updateApiPolicyConfig = (params)=>{
    return API({
        url: businessUrl + 'apiPolicyConfig/updateApiPolicyConfig',
        method: 'post',
        data:params
      })
}

const selectApiByPolicy = (params)=>{
    return API({
        url: businessUrl + 'apiPolicyConfig/selectApiByPolicy',
        method: 'post',
        data:params
      })
}

const deleteApiPolicyConfig = (params)=>{
    return API({
        url: businessUrl + `apiPolicyConfig/deleteApiPolicyConfig/${params.id}`,
        method: 'get'
      })
}


const getApiPolicyConfigById = (params)=>{
    return API({
        url: businessUrl + `apiPolicyConfig/getApiPolicyConfigById/${params.id}`,
        method: 'get'
      })
}

const selectBusinessCatePage = (params)=>{
    return API({
        url: businessUrl + 'iasmBusinessCate/selectBusinessCateTree',
        method: 'get',
        params
      })
}

const configApiByTemplate = (params)=>{
    return API({
        url: businessUrl + 'apiPolicyConfig/configApiByTemplate',
        method: 'post',
        data:params
      })
}

const deleteApiPolicy = (params)=>{
    return API({
        url: businessUrl + 'apiPolicyConfig/deleteApiPolicy',
        method: 'get',
        params
      })
}

const resetApiPolicyById = (params)=>{
    return API({
        url: businessUrl + 'apiPolicyConfig/resetApiPolicyById',
        method: 'post',
        data: params
      })
}

const saveIasmBusinessCate = (params)=>{
    return API({
        url: businessUrl + 'iasmBusinessCate/saveIasmBusinessCate',
        method: 'post',
        data: params
      })
}

const findBusinessCate = (params)=>{
    return API({
        url: businessUrl + `iasmBusinessCate/findBusinessCate/${params.businessCateId}`,
        method: 'get'
      })
}

const deleteBusinessCate = (params)=>{
    return API({
        url: businessUrl + `iasmBusinessCate/deleteBusinessCate/${params.businessCateId}`,
        method: 'get'
      })
}

const myDevelopmentApi = (params)=>{
    return API({
        url: businessUrl + 'mall/myDevelopmentApi',
        method: 'post',
        data: params
      })
}

const selectApiByPage = (params)=>{
    return API({
        url: businessUrl + 'apiManager/selectApiByPage',
        method: 'get',
        params
      })
}

const updateApiStatus = (params)=>{
    return API({
        url: businessUrl + 'apiManager/updateApiStatus',
        method: 'post',
        data: params
      })
}

const myDesignApi = (params)=>{
    return API({
        url: businessUrl + 'mall/myDesignApi',
        method: 'post',
        data: params
      })
}

const pageListAuth = (params)=>{
    return API({
        url: businessUrl + 'auth/pageListAuth',
        method: 'get',
        params
      })
}

const addOrUpdateAuth = (params)=>{
    return API({
        url: businessUrl + 'auth/addOrUpdateAuth',
        method: 'post',
        data: params
      })
}

const getApiAuthData = (params)=>{
    return API({
        url: businessUrl + 'auth/getApiAuthData',
        method: 'post',
        data: params
      })
}

const deleteAuth = (params)=>{
    return API({
        url: businessUrl + 'auth/deleteAuth',
        method: 'post',
        data: params
      })
}

const pageRegisterProject = (params)=>{
    return API({
        url: businessUrl + 'registerProject/pageRegisterProject',
        method: 'get',
        params 
      })
}

const deleaddOrUpdateRegisterProjecteAuth = (params)=>{
    return API({
        url: businessUrl + 'registerProject/addOrUpdateRegisterProject',
        method: 'post',
        data: params
      })
}

const deleteRegisterProject = (params)=>{
    return API({
        url: businessUrl + 'registerProject/deleteRegisterProject',
        method: 'post',
        data: params
      })
}

const updateRegisterProjectStatus = (params)=>{
    return API({
        url: businessUrl + 'registerProject/updateRegisterProjectStatus',
        method: 'post',
        data: params
      })
}

const findRegisterProjectDetails = (params)=>{
    return API({
        url: businessUrl + 'registerProject/findRegisterProjectDetails',
        method: 'post',
        data: params
      })
}

const addOrUpdateRegisterProject = (params)=>{
    return API({
        url: businessUrl + 'registerProject/addOrUpdateRegisterProject',
        method: 'post',
        data: params
      })
}

const pageListRoute = (params)=>{
    return API({
        url: businessUrl + 'route/pageListRoute',
        method: 'get',
        params
      })
}

const findRouteApiDetails = (params)=>{
    return API({
        url: businessUrl + 'route/findRouteApiDetails',
        method: 'post',
        data: params
      })
}

const addOrUpdateRouteApi = (params)=>{
    return API({
        url: businessUrl + 'route/addOrUpdateRouteApi',
        method: 'post',
        data: params
      })
}

const apiRouteUpdateStatus = (params)=>{
    return API({
        url: businessUrl + 'route/apiRouteUpdateStatus',
        method: 'post',
        data: params
      })
}



const newListTree = (params)=>{
    return API({
        url: businessUrl + 'apicategory/newListTree',
        method: 'get',
        params
      })
}

const routeTree = (params)=>{
    return API({
        url: businessUrl + 'route/routeTree',
        method: 'get',
        params
      })
}

const runApi = (params)=>{
    return API({
        url: businessUrl + 'apiInvoke/runApi',
        method: 'post',
        data: params
      })
}

const testApi = (params)=>{
    return API({
        url: businessUrl + 'apiInvoke/testApi',
        method: 'post',
        data: params
      })
}

const findLogDetails = (params)=>{
    return API({
        url: businessUrl + 'apiLog/findLogDetails',
        method: 'get',
        params
      })
}

const findAuthDetails = (params)=>{
    return API({
        url: businessUrl + 'auth/details',
        method: 'post',
        data:params
      })
}

const getApiResultData = (params)=>{
    return API({
        url: businessUrl + 'auth/getApiResultData',
        method: 'post',
        data:params
      })
}

const getApiCallUrl = (params)=>{
    return API({
        url: businessUrl + 'apiManager/getApiCallUrl',
        method: 'post',
        data:params
      })
}

const findApiVersion = (params)=>{
    return API({
        url: businessUrl + 'apiManager/findApiVersion',
        method: 'get',
        params
      })
}

const deleteRouteApiDetails = (params)=>{
    return API({
        url: businessUrl + 'route/deleteRouteApiDetails',
        method: 'post',
        data:params
      })
}

const selectApiSubscribeList = (params)=>{
  return API({
      url: businessUrl + 'mall/selectApiSubscribeList',
      method: 'post',
      data:params
    })
}

const getOverallFunction = (params)=>{
  return API({
      url: businessUrl + 'overallFunction/pageList',
      method: 'get',
      params
    })
}

const getOverallFunctionDetail = (params)=>{
  return API({
      url: businessUrl + 'overallFunction/details',
      method: 'post',
      data:params
    })
}

const findNodeList = (params)=>{
  return API({
      url: businessUrl + 'node/findNodeList',
      method: 'post',
      data:params
    })
}

const getListArrange = (params)=>{
  return API({
      url: businessUrl + 'arrange/pageListArrange',
      method: 'get',
      params
    })
}

const getApiArrangeDetails = (params)=>{
  return API({
      url: businessUrl + 'arrange/getApiArrangeDetails',
      method: 'post',
      data:params
    })
}

const addOrUpdateArrangeApi = (params)=>{
  return API({
      url: businessUrl + 'arrange/addOrUpdateArrangeApi',
      method: 'post',
      data:params
    })
}

const queryApiSubscribeList = (params)=>{
  return API({
      url: businessUrl + 'mall/queryApiSubscribeList',
      method: 'post',
      data:params
    })
}

const getIndexTypeList = (params)=>{
  return API({
      url: businessUrl + 'indicatordefinition/getIndexTypeList',
      method: 'get',
      params
    })
}

const getListIndicatordefinition = (params)=>{
  return API({
      url: businessUrl + 'indicatordefinition/pageListIndicatordefinition',
      method: 'get',
      params
    })
}

const deleteIndicatordefinition = (params)=>{
  return API({
      url: businessUrl + 'indicatordefinition/deleteIndicatordefinition',
      method: 'post',
      data:params
    })
}

const getIndicatordefinitionDetails = (params)=>{
  return API({
      url: businessUrl + 'indicatordefinition/indicatordefinitionDetails',
      method: 'post',
      data:params
    })
}

const addOrUpdateIndicatordefinition = (params)=>{
  return API({
      url: businessUrl + 'indicatordefinition/addOrUpdateIndicatordefinition',
      method: 'post',
      data:params
    })
}

const findIndexDetails = (params)=>{
  return API({
      url: businessUrl + 'indicatordefinition/findIndexDetails',
      method: 'post',
      data:params
    })
}


const apiArrangeUpdateStatus = (params)=>{
  return API({
      url: businessUrl + 'arrange/apiArrangeUpdateStatus',
      method: 'post',
      data:params
    })
}

const updateIndicatordefinition = (params)=>{
  return API({
      url: businessUrl + 'indicatordefinition/updateIndicatordefinition',
      method: 'post',
      data:params
    })
}

const pageListIndexDetails = (params)=>{
  return API({
      url: businessUrl + 'indicatordefinition/pageListIndexDetails',
      method: 'get',
      params
    })
}

const deleteApiArrange = (params)=>{
  return API({
      url: businessUrl + 'arrange/deleteApiArrange',
      method: 'post',
      data:params
    })
}

const getArrangePackageList = (params)=>{
  return API({
      url: businessUrl + 'arrangePackage/pageListArrangePackage',
      method: 'get',
      params
    })
}

const uploadArrangePackage = (params)=>{
  return API({
      url: businessUrl + 'arrangePackage/uploadArrangePackage',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*/*',
        'accept-encoding': 'gzip, deflate, br',
      },
      data:params
    })
}

const deletePackageClass = (params)=>{
  return API({
      url: businessUrl + 'arrangePackage/deletePackageClass',
      method: 'post',
      data:params
    })
}

const getPackageClassByPackageId = (params)=>{
  return API({
      url: businessUrl + 'arrangePackage/getPackageClassByPackageId',
      method: 'post',
      data:params
    })
}

const deleteArrangePackage = (params)=>{
  return API({
      url: businessUrl + 'arrangePackage/deleteArrangePackage',
      method: 'post',
      data:params
    })
}

const addOrUpdatePackageClass = (params)=>{
  return API({
      url: businessUrl + 'arrangePackage/addOrUpdatePackageClass',
      method: 'post',
      data:params
    })
}

const getArrangePackageDetails = (params)=>{
  return API({
      url: businessUrl + 'arrangePackage/getArrangePackageDetails',
      method: 'post',
      data:params
    })
}

const savePackageClassList = (params)=>{
  return API({
      url: businessUrl + 'arrangePackage/savePackageClassList',
      method: 'post',
      data:params
    })
}

const updatePackageClassStatus = (params)=>{
  return API({
      url: businessUrl + 'arrangePackage/updatePackageClassStatus',
      method: 'post',
      data:params
    })
}

const saveApiResponseE = (params)=>{
  return API({
      url: businessUrl + 'apiManager/saveApiResponseE',
      method: 'post',
      data:params
    })
}

const developmentTemplatesDownLoad = (params)=>{
  return API({
      url: businessUrl + 'arrangePackage/developmentTemplatesDownLoad',
      method: 'get',
      params
    })
}

const editArrangeConfiguration = (params)=>{
  return API({
      url: businessUrl + 'arrange/editArrangeConfiguration',
      method: 'post',
      data:params
    })
}




export const businessApi = {
    editArrangeConfiguration,
    developmentTemplatesDownLoad,
    saveApiResponseE,
    updatePackageClassStatus,
    savePackageClassList,
    getArrangePackageDetails,
    addOrUpdatePackageClass,
    deleteArrangePackage,
    getPackageClassByPackageId,
    deletePackageClass,
    uploadArrangePackage,
    getArrangePackageList,
     deleteApiArrange,
     pageListIndexDetails,
    updateIndicatordefinition,
    apiArrangeUpdateStatus,
    findIndexDetails,
    addOrUpdateIndicatordefinition,
    getIndicatordefinitionDetails,
    deleteIndicatordefinition,
    getListIndicatordefinition,
    getIndexTypeList,
    queryApiSubscribeList,
    addOrUpdateArrangeApi,
    getApiArrangeDetails,
    getListArrange,
    findNodeList,
    getOverallFunctionDetail,
    getOverallFunction,
    selectApiSubscribeList,
    deleteRouteApiDetails,
    findApiVersion,
    getApiCallUrl,
    getApiResultData,
    findAuthDetails,
    findLogDetails,
    testApi,
    runApi,
    routeTree,
    apiRouteUpdateStatus,
    newListTree,
    addOrUpdateRouteApi,
    findRouteApiDetails,
    pageListRoute,
    addOrUpdateRegisterProject,
    findRegisterProjectDetails,
    updateRegisterProjectStatus,
    deleteRegisterProject,
    pageRegisterProject,
    deleaddOrUpdateRegisterProjecteAuth,
    deleteAuth,
    getApiAuthData,
    addOrUpdateAuth,
    pageListAuth,
    myDesignApi,
    updateApiStatus,
    selectApiByPage,
    myDevelopmentApi,
    deleteBusinessCate,
    findBusinessCate,
    saveIasmBusinessCate,
    resetApiPolicyById,
    deleteApiPolicy,
    configApiByTemplate,
    selectBusinessCatePage,
    getApiPolicyConfigById,
    deleteApiPolicyConfig,
    selectApiByPolicy,
    saveApiPolicyConfig,
    updateApiPolicyConfig,
    selectApiPolicyConfigByPage,
    invokeTop,
    invokeBottom,
    costInvoke,
    differentApiPercentage,
    apiSubscriptionDistribution,
    subscribeTop,
    getApiIndicator,
    subscribeAndPublish,
    recordByTime,
    deleteApicategory,
    saveApicategory,
    validName,
    selectLogList,
    selectInvokeDetailList,
    selectApiListByStatistics,
    apiTrafficMonitoring,
    selectApiInvokeInWeek,
    selectApiInvokeInfo,
    selectApiSubTopTenByApp,
    selectApiInvokeTopToday,
    selectMySubApiByPage,
    selectMyIssueApiByPage,
    unSubscribeApi,
    searchSubscribeApi,
    subscribeApi,
    getApiApprovalStatus,
    changApiCancel,
    submitApiChange,
    saveApiBasicInfo,
    initApiSql,
    checkUrlDuplicate,
    apiResultExport,
    testApiAvailability,
    deleteApiByIds,
    changeApiBegin,
    apiUpdateStatus,
    deleteApi,
    selectAuthorizeAppByApi,
    selectMyAppIdIssueApiByPage,
    selectMyAppIdIssueApiOnlineByPage,
    selectMyAppIdAndUserIdIssueApiByPage,
    getListTree,
    selectResponsible,
    getApiInfo,
}