<template>
<div class="main">
    <div class="data-box">
        <div class="search-box">
            <el-input
                class="input-transparent"
                v-model="apiAuthName"
                placeholder="请输入认证名称"
                clearable
                style="width: 600px;margin-right:20px;"
                @keyup.enter="getApiAuthList"
                >
                <template #suffix>
                    <el-icon class="icon-search" @click="getApiAuthList"><Search /></el-icon>
                </template>
            </el-input>
            <!-- <div @click="showSearch = !showSearch" class="icon-box">
                高级搜索
                <el-icon :size="18" >
                <ArrowDown v-if="!showSearch" />
                <ArrowUp v-else/>
                </el-icon>
            </div> -->
        </div>
        <!-- <el-form
            v-if="showSearch"
            :inline="true"
            style="text-align: left"
        > -->
        <!-- <el-form-item label="业务分类" prop="status">
          <el-dropdown >
            <span class="el-dropdown-link">
              {{apiAuthCodeLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
           <template #dropdown>
            <el-dropdown-menu >
              <el-dropdown-item @click="changeApiAuthCode('','全部')">全部</el-dropdown-item>
              <el-dropdown-item @click="changeApiAuthCode('dispatch_api_auth','调度API组件认证')">调度API组件认证</el-dropdown-item>
              <el-dropdown-item @click="changeApiAuthCode('operator_api_auth','API算子组件认证')">API算子组件认证</el-dropdown-item>
            </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-form-item> -->
      <!-- </el-form> -->
        

        <div class="box-card-radius-eight white-bg" style="overflow:hidden;">
          <div class="toolbar">
            <el-button type="primary" icon="plus" style="color:#fff;" @click="handleAdd">新增API认证</el-button>
          </div>
          <el-table 
            :data="tableData"
            row-key="apiId"
            border
            >
            <!-- <el-table-column
            prop="apiAuthName"
            label="业务分类"
            width="180">
            </el-table-column> -->
             <el-table-column
            prop="apiAuthName"
            label="认证名称"
            width="200">
            </el-table-column>
            <el-table-column
            prop="apiAuthUrl"
            label="URL"
            width="400"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
            prop="apiAuthMethod"
            label="请求方法"
            width="180">
              <template #default="scope">
                <span>{{RequestMaps[scope.row.apiAuthMethod]}}</span>
            </template>
            </el-table-column>
            <el-table-column
            prop="apiAuthDatatype"
            label="请求数据类型"
            width="200">
            </el-table-column>
            <el-table-column
            prop="apiAuthPasretype"
            label="解析类型"
            width="150">
            <template #default="scope">
                <span v-if="scope.row.apiAuthPasretype =='JSON'">JSON</span>
                <span v-if="scope.row.apiAuthPasretype =='XML'">XML</span>
                <span v-if="scope.row.apiAuthPasretype =='REGULAR'">正则表达式</span>

            </template>
            </el-table-column>
           
             <el-table-column 
                prop="createBy"
                label="创建人"
                width="180">
                <template #default="scope">
                  {{scope.row.createdName + ' ' + scope.row.createdNumber}}
                </template>
            </el-table-column>
            <el-table-column
                prop="createtime"
                label="创建时间"
                width="180">
            </el-table-column>
            <el-table-column 
                prop="updateBy"
                label="最后修改人"
                width="180">
                <template #default="scope">
                  {{scope.row.updatedName + ' ' + scope.row.updatedNumber}}
                </template>
            </el-table-column>
            <el-table-column
                prop="updatetime"
                label="最后修改时间"
                width="180">
            </el-table-column>
            <el-table-column
                label="操作"
                width="120"
                fixed="right">
            <template #default="scope">
                 <el-button  size="medium" icon="Edit" type="text"  @click="handleEdit(scope.row)"></el-button>
                 <el-button @click="handleDelete(scope.row)"  size="medium" icon="delete" type="text" style="color:#f56c6c;"></el-button>
            </template>
            </el-table-column>
          </el-table>
          <!--分页组件-->
            <div style="padding: 10px 0;float:right;">
                <el-pagination background small
                :current-page="page.currentPage"
                :page-size="page.pageSize"
                :page-sizes="[2, 5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                />
            </div>
        </div>
        
    </div>
 </div>
</template>

<script>
import { ref, onMounted } from "vue";
import {  useRouter,useRoute } from 'vue-router';
import {request} from '@/api'
import { ElMessage } from "element-plus";
import { RequestMap } from '@/utils/constant'


export default {
  name: 'ApiDesign',
  setup() {
    const router = useRouter()
    const route = useRoute()
    let RequestMaps = ref(RequestMap)

    let showSearch = ref(false)
    let apiAuthCodeLabel = ref('全部')
    let apiAuthCode = ref('')
    let page = ref({
        currentPage: 1,
        pageSize: 10,
        total:0
    })
    let isMyDesign = ref(route.fullPath === '/home/mine/myDesign')
    let apiAuthName = ref('')
    let tableData =  ref()
   
    const changeApiAuthCode = (code,label)=>{
        apiAuthCode.value = code
        apiAuthCodeLabel.value = label
        page.value.currentPage = 1
        getApiAuthList()
    }

    const handleSizeChange = (val) => {
      //size修改
      page.value.pageSize = val;
      getApiAuthList();
    };
    const handleCurrentChange = (val) => {
      //num修改
      page.value.currentPage = val;
      getApiAuthList();
    };

    const getApiAuthList = async ()=>{
      let res = await request.pageListAuth({
          currentPage: 1,
          pageSize: 10,
          apiAuthName:apiAuthName.value,
          appid:localStorage.getItem('appId')
        })
      if (res?.data?.code === 200) {
          tableData.value = res.data.data.result
          page.value.total = res.data.data.pageVO.total
      }
    }
    const handleDelete = (row)=>{
        request.deleteAuth({
          apiAuthId:row.apiAuthId,
          "projectId":1,
        }).then((res) => {
          if (res?.data?.code === 200) {
            ElMessage.success('删除成功')
            getApiAuthList()
          }
       })
    }

    const handleEdit =  (row)=>{
         router.push({
          path: '/home/DataResourceCenter/APIAuthManagement/APIAuthManagementDetail',
          query: {
            operation:'edit',
            apiAuthId:row.apiAuthId
          }
        })
    }

    const handleAdd = ()=>{
      router.push({
          path: '/home/DataResourceCenter/APIAuthManagement/APIAuthManagementDetail',
          query: {
            operation:'create'
          }
        })
    }

    onMounted(()=>{
      getApiAuthList()
    })

    return {
      RequestMaps,
      apiAuthName,
      getApiAuthList,
      handleDelete,
      handleEdit,
      handleAdd,
      tableData,
      isMyDesign,
      apiAuthCodeLabel,
      apiAuthCode,
      showSearch,
      changeApiAuthCode,
      page,
      handleSizeChange,
      handleCurrentChange
    }
  }
}
</script>


<style lang="less" scoped>
.main{
    margin:20px;
    text-align: left;
    .toolbar{
      margin-bottom: 24px;
    }
  .data-box{
    margin-left:20px;
    .search-box{
        display:flex;
        align-items:center;
        margin-bottom: 20px;
        color:#1890ff;
        font-size:16px;
        .icon-box{
            display:flex;
           align-items:center;
        }
    }
  }
}
</style>