<template>
  <div class="iods-cockpit-box">
    <div id="iods" ref="appRef">
      <div class="iods-bg">
        <div class="iods-host_body">
          <div class="top_title">
            <div class="main_title left">
              <div class="">
                <img
                  src="../../assets/image/cockpit/coclkpit_logo.png"
                  alt=""
                  style="margin-left: 20x; width: 80px; height: 52px"
                />
              </div>
              <div class="title_text">数据开放平台驾驶舱</div>
            </div>
            <div class="top_title_right left">
              <!-- <div class="map_operate_user right">
                <img
                  src="../../assets/image/home/user.png"
                  alt=""
                  style="
                    width: 28px;
                    height: 28px;
                    vertical-align: middle;
                    margin-right: 15px;
                  "
                />
                {{ userName }}
              </div> -->
              <!-- <div
                class="right map_operate_right"
              >
                <div class="operate_text">{{ state.currentRoleName }}</div>
                <div class="{styles.operate_icon}">
                  <div
                    :class="state.roleArrowShow ? 'arrow_up' : 'arrow_down'"
                  ></div>
                </div>
                <div class="down_show" v-if="state.roleArrowShow">
                  <div
                    v-for="(item, index) in state.roleList"
                    :key="index"
                    :class="[
                      'down_text',
                      item.id === state.currentRoleId ? 'down_text_active' : '',
                    ]"
                  >
                    {{ item.roleName }}
                  </div>
                </div>
              </div> -->
              <div
                class="right map_operate_right"
                @click="state.workSpaceArrowShow = !state.workSpaceArrowShow"
              >
                <div class="operate_text">
                  {{ state.currentWorkSpaceName }}
                </div>
                <div class="operate_icon">
                  <div
                    :class="[
                      state.workSpaceArrowShow ? 'arrow_up' : 'arrow_down',
                    ]"
                  ></div>
                </div>

                <div class="down_show" v-if="state.workSpaceArrowShow">
                  <div
                    v-for="(item, index) in state.workSpaceList"
                    :key="index"
                    :class="[
                      'down_text',
                      item.appId === state.currentWorkSpaceId
                        ? 'down_text_active'
                        : '',
                    ]"
                    @click="workSpaceNameChange(item, $event)"
                  >
                    {{ item.appName }}
                  </div>
                </div>
              </div>
              <div @click="goHome" class="right map_operate">
                <div class="left map_operate_icon"></div>
                <div class="left">{{ state.textButton }}</div>
              </div>
            </div>
          </div>
          <div class="body_box">
            <el-row :gutter="[12, 24]">
              <el-col
                span="6"
                class="left"
                style="display: block; flex: 0 0 25%; max-width: 25%"
              >
                <LeftTop :appId="state.currentWorkSpaceId" style="margin-bottom: 20px" />
                <LeftMiddle :appId="state.currentWorkSpaceId" style="margin-bottom: 20px" />
                <LeftBottom :appId="state.currentWorkSpaceId" />
              </el-col>
              <el-col
                span="12"
                class="center"
                style="display: block; flex: 0 0 50%; max-width: 50%"
              >
                <CenterTop
                  style="margin: 0 20px 20px"
                  :appId="state.currentWorkSpaceId"
                />
                <CenterMiddle
                  style="margin: 20px"
                  :appId="state.currentWorkSpaceId"
                />
                <CenterBottom
                  style="margin: 20px"
                  :appId="state.currentWorkSpaceId"
                />
              </el-col>
              <el-col
                span="6"
                class="right"
                style="display: block; flex: 0 0 25%; max-width: 25%"
              >
                <RightTop
                  style="margin-bottom: 20px"
                  :appId="state.currentWorkSpaceId"
                />
                <RightMiddle
                  style="margin-bottom: 20px"
                  :appId="state.currentWorkSpaceId"
                />
                <RightBottom :appId="state.currentWorkSpaceId" />
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {request} from '@/api'
import router from "@/router";
import useDraw from "@/utils/cockpit/useDraw.ts";
import { onMounted, onUnmounted, reactive, ref } from "vue";
import LeftTop from "@/components/cockpit/LeftTop";
import LeftMiddle from "@/components/cockpit/LeftMiddle";
import LeftBottom from "@/components/cockpit/LeftBottom";
import CenterTop from "@/components/cockpit/CenterTop";
import CenterMiddle from "@/components/cockpit/CenterMiddle";
import CenterBottom from "@/components/cockpit/CenterBottom";
import RightTop from "@/components/cockpit/RightTop";
import RightMiddle from "@/components/cockpit/RightMiddle";
import RightBottom from "@/components/cockpit/RightBottom";
export default {
  name: "Cockpit",
  components: {
    LeftTop,
    LeftMiddle,
    LeftBottom,
    CenterTop,
    CenterMiddle,
    CenterBottom,
    RightTop,
    RightMiddle,
    RightBottom,
  },
  setup() {
    const { appRef, calcRate, windowDraw, unWindowDraw } = useDraw();
    const userName = ref(localStorage.getItem("userName"));
    
    const state = reactive({
      currentRoleName: localStorage.getItem("currentRoleName"),
      roleList: [],
      roleArrowShow: false,
      currentRoleId: parseInt(localStorage.getItem("currentRoleId")),
      workSpaceArrowShow: false,
      workSpaceList: [],
      currentWorkSpaceName: localStorage.getItem("appName"),
      currentWorkSpaceId: parseInt(localStorage.getItem("appId")),
      textButton: "数据开放工作台",
    });
    const roleNameChange = (val, e) => {
      state.currentRoleId = val.roleId;
      state.roleList.map((item) => {
        if (item.roleId === state.currentRoleId) {
          state.currentRoleName = item.roleName;
        }
      });
      e.stopPropagation();
      state.roleArrowShow = false;
    };
    const getRolesInfo = () => {
      request.selectRolesByUserId({userId: localStorage.getItem("userId")}).then((resp) => {
        state.roleList = resp.data.data;
      });
    };
    const getAppInfoList = () => {
      request.selectUserAppInfos({
          userId: localStorage.getItem("userId"),
          tenantId:-1
        }).then((res) => {
        state.workSpaceList = res.data.data.result;
      });
    };
    getAppInfoList();
    const workSpaceNameChange = (val, e) => {
      state.currentWorkSpaceId = val.appId;
      state.workSpaceList.map((item) => {
        if (item.appId === state.currentWorkSpaceId) {
          state.currentWorkSpaceName = item.appName;
        }
      });
      e.stopPropagation();
      state.workSpaceArrowShow = false;
      // workplaceChange(state.currentWorkSpaceId);
    };
    const goHome = () => {
      router.push("/home/ApiMarket/ApiMarketList");
    };
    getRolesInfo();
    const init = () => {};
    init();
    onMounted(() => {
      // todo 屏幕适应
      windowDraw();
      calcRate();
    });
    onUnmounted(() => {
      unWindowDraw();
    });
    return {
      appRef,
      userName,
      state,
      roleNameChange,
      getRolesInfo,
      workSpaceNameChange,
      goHome,
      init,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/index.scss";
</style>