<template>
  <div class="login-box">
    <img src="../assets/image/login/iods-logo.png" alt="" class="login-logo" />
    <div style="position: absolute;top: 20px;right: 100px;cursor: pointer;" title="关于我们" @click="getDetail">
       <el-icon :size="'20px'" :color="'#fff'">
        <Reading />
      </el-icon>
    </div>
    <div class="login-form-box">
      <div class="login-form-title-en">iODS</div>
      <div class="login-form-title-cn">数据开放平台</div>
      <el-form :model="formData" class="login-form" label-position="top">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="用户名" prop="account" style="position:relative">
              <el-input
                type="input"
                auto-complete="off"
                placeholder="请输入用户名"
                v-model="formData.account"
                clearable
              ></el-input>
              <img :src="imgInfo.user" alt="" style="position:absolute;left:10px;width:20px;height:20px">
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="密码" prop="passWord" label-width="80px" style="position:relative">
              <el-input
                type="password"
                auto-complete="off"
                placeholder="请输入密码"
                v-model="formData.passWord"
                show-password
                clearable
                @keydown.enter="login()"
              ></el-input>
               <img :src="imgInfo.pswImg" alt="" style="position:absolute;left:10px;width:20px;height:20px">
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item>
              <!--  这里通过@click绑定login函数，用于点击的时候触发登录功能 -->
              <el-button
              class="login-button"
                type="primary"
                @click="login()"
                >登&nbsp;&nbsp;录</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-dialog
      v-model="dialogVisible"
      title="关于数据开放平台"
      width="50%"
      @closed="closeDialog"
    >
      <div >
        <h3>数据开放平台</h3>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;为企业搭建规范且标准的数据服务总线，针对API接口服务提供自定义/可视化设计、开发、注册、发布、订阅、运营等功能，以构建全面、安全、高效的数据开放能力
        </p>
        
      </div>
      <div style="text-align:center;">
        <p>
         license到期时间： <el-tag>{{notAfter}}</el-tag>
        </p>
         <el-upload
            v-model:file-list="fileList"
            action="/iods_gateway/iodsApibusinessAdmin/license/upload"
            :on-success="handleSuccess"
            :limit="1"
            accept=".lic"
          >
        <el-button type="primary" size="small">更新license</el-button>
        </el-upload>
      </div>
      
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import {request} from '@/api'
import router from "@/router";
import { ElMessageBox } from "element-plus";
import { reactive,ref } from "vue";
import iasmIcon from "@/assets/image/iasm.png";
import { dataEncryption } from "@/assets/js/dataEncryption.js";
import userImg from '../assets/image/login/login_user.png'
import pswImg from '../assets/image/login/login_password.png'
import { useStore } from "vuex";
import { setupMenusGuard } from '@/router/guard'
export default {
  name: "Login",
  setup() {
    const store = useStore()
    // vue3中获取表单数据需要使用reactive
    const formData = reactive({
      account: "",
      passWord: "",
    });
    let dialogVisible = ref(false)
    const imgInfo=reactive({
      user:userImg,
      pswImg:pswImg
    })
    let notAfter = ref()
    const fileList = ref([])
    const login = () => {
      if (formData.account == "") {
        ElMessageBox.alert("请输入用户名");
        return;
      }
      if (formData.passWord == "") {
        ElMessageBox.alert("请输入密码");
        return;
      }
      // 加密数据
      let password = dataEncryption(formData.passWord);
      request.loginByHeader({
          account: formData.account,
          passWord: password,
        }).then(async (resp) => {
          if (resp.data.code === 1000) {
             ElMessageBox.alert('license无效,请联系管理员')
            return
          }
        if (resp.data.data.loginStatus === "1") {
          localStorage.setItem("token", resp.data.data.token);
          localStorage.setItem("userId", resp.data.data.userId);
          localStorage.setItem("account", resp.data.data.account);
          localStorage.setItem("userName", resp.data.data.userName);
          localStorage.setItem("tenantId", resp.data.data.tenantId);
          localStorage.setItem("tenantName", resp.data.data.tenantName);
          localStorage.setItem("phoneNumber", resp.data.data.phoneNumber || "");
          localStorage.setItem(
            "currentRoleName",
            resp.data.data.currentRoleName
          );
          localStorage.setItem("currentRoleId", resp.data.data.currentRoleId);
          localStorage.setItem("appId", resp.data.data.appId);
          localStorage.setItem("appName", resp.data.data.appName);
          localStorage.setItem(
            "AuthCodeList",
            resp.data.data.currentAuthCodeList
          );
          const loginCount = resp.data.data.loginCount;
          localStorage.setItem("loginCount", loginCount);
          if (loginCount === 1) {
            router.push({
              path: "/home/UserCenter/AccountSettings",
              query: {
                activeDrawer: "true",
              },
            });
          } else {
            let roleId = resp.data.data.currentRoleId;
            let res = await request.getMenuListByRoleId({roleId,projectId:1})
            if(res.data.data.length > 0){
              store.commit('setMenuList', res.data.data)
              router.push({
              path: "/home/ApiMarket/ApiMarketList"
            });
              // router.push({
              //   path: res.data.data[0].path,
              //   query: {
              //     login: "login",
              //   },
              // });
            } else {
              router.push({
                path: '/home/noPermission'
              });
            }
            
            setupMenusGuard(router,store)
          }
        } else {
          // 如果账号密码错误的话就要进行提示，并且重新回到登录页面
          ElMessageBox.alert(resp.data.data.loginMsg);
          // router.push("/login");
        }
      });
    };

    const getDetail = async ()=>{
      let res = await request.about()
      notAfter.value = res.data.data.notAfter
      dialogVisible.value = true
    }
    const handleSuccess = (res,uploadFile)=>{
        console.log(res,uploadFile,fileList,'>>handleSuccess')
    }
    const closeDialog = ()=>{
      fileList.value = []
    }
    return {
      login,
      formData,
      iasmIcon,
      imgInfo,
      dialogVisible,
      getDetail,
      handleSuccess,
      fileList,
      closeDialog,
      notAfter
    };
  },
  data() {
    return {
      //loginBg: 'url(' + require('../assets/image/loginbg.jpg') + ')',
      defaultImg: "url(" + require("../assets/image/iasm.png") + ")",
    };
  },
  mounted() {
    document.body.style.backgroundSize = "105%";
    //document.body.style.backgroundImage = this.loginBg
  },
  beforeRouteLeave() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>

<style  scoped>
.card {
  text-align: center;
  background-color: rgba(0%, 0%, 0%, 0%);
  border-radius: 20px;
  width: 300px;
  height: 350px;
  position: absolute;
  left: 70%;
  top: 45vh;
  transform: translate(-50%, -50%);
}

.center {
  position: absolute;
  left: 80px;
  width: 200px;
  top: 8px;
}

.img-icon {
  display: block;
  width: 240px;
  height: 80px;
}

.div-padding {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
}
</style>
