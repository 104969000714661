<template>
  <div>
  <div>
    <CommonSearchForm :value="searchForm.queryKey" @handleSearch="handleSearch">
      <el-form :inline="true" v-model="searchForm" align="left">
            <el-form-item label="请求方式" prop="apiRequestM">
            <el-dropdown >
            <span class="el-dropdown-link">
              {{apiRequestMLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
           <template #dropdown>
            <el-dropdown-menu >
              <el-dropdown-item @click="changeApiRequestM('','全部')">全部</el-dropdown-item>
              <el-dropdown-item v-for="item in rqList.list" :key="item.lookupDKey"
                  :value="item.lookupDKey"  @click="changeApiRequestM(item.lookupDKey,item.lookupDValue)">{{item.lookupDValue}}</el-dropdown-item>
            </el-dropdown-menu>
            </template>
          </el-dropdown>
            </el-form-item>
            <el-form-item label="状态" >
              <el-dropdown>
            <span class="el-dropdown-link">
              {{statusLabel}}<el-icon class="el-icon-arrow-down"><ArrowDown /></el-icon>
            </span>
           <template #dropdown>
            <el-dropdown-menu >
              <el-dropdown-item @click="changeStatus('','全部')">全部</el-dropdown-item>
              <el-dropdown-item @click="changeStatus('draft','草稿')">草稿</el-dropdown-item>
              <el-dropdown-item @click="changeStatus('released','待上线')">待上线</el-dropdown-item>
              <el-dropdown-item @click="changeStatus('approval','审批中')">审批中</el-dropdown-item>
              <el-dropdown-item @click="changeStatus('online','上线')">上线</el-dropdown-item>
              <el-dropdown-item @click="changeStatus('offline','下线')">下线</el-dropdown-item>
             
            </el-dropdown-menu>
            </template>
          </el-dropdown>
            </el-form-item>
            <!-- <el-form-item label="责任人">
              <el-select v-model="searchForm.apiOwner" filterable clearable placeholder="请选择责任人" @click="getUserList()">
                <el-option v-for="item in userList.list" :key="item.userId" :label="item.userName" :value="item.userId" />
              </el-select>
            </el-form-item> -->
      </el-form>
    </CommonSearchForm>
    
    <div class="white-bg box-card-radius-eight">
      <el-table :data="tableData.list" stripe style="width: 100%" :header-cell-style="{ textAlign: 'left' }">
        <el-table-column prop="apiName" label="API名称" :show-overflow-tooltip="true" width="300">
          <template #default="scope">
            <span >{{ scope.row.apiName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="apiUrl" label="URL" :show-overflow-tooltip="true" width="300"/>
        <el-table-column prop="apiVersion" label="版本" minWidth="100" :show-overflow-tooltip="true">
          <template #default="scope">
            <el-tag type="info" effect="plain">v{{ scope.row.apiVersion }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="apiRequestM" label="请求方式"  :formatter="requestMethodFormatter">
        </el-table-column>
        <!-- <el-table-column prop="apiCreateM" label="创建方式" minWidth="120">
          <template #default="scope">
            {{ scope.row.apiCreateM == 1 ? '手工创建' : 'API注册' }}
          </template>
        </el-table-column> -->
        <el-table-column prop="status" label="状态" width="200">
          <template #default="scope">
            <div>
              <img class="status-icon" src='../../assets/image/status/draft.png' v-if="scope.row.status === 'draft'"/>
              <img class="status-icon" src='../../assets/image/status/tobeOnLine.png' v-else-if="scope.row.status === 'released' || scope.row.status === 'pending_developed'"/>
              <img class="status-icon" src='../../assets/image/status/inApproval.png' v-else-if="scope.row.status === 'approval' || scope.row.status === 'design' || scope.row.status === 'developed'"/>
              <img class="status-icon" src='../../assets/image/status/online.png' v-else-if="scope.row.status === 'online'"/>
              <img class="status-icon" src='../../assets/image/status/offline.png' v-else-if="scope.row.status === 'offline'"/>
              <span>{{apiStatusMap[scope.row.status] || '-'}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="invokeCount" label="近30天调用次数" width="220" />
        <el-table-column prop="invokeTime" label="最后调用时间" width="220" :formatter="iasmjs.formatDate"
           />
        <!-- <el-table-column prop="apiOwnerName" label="API责任人" minWidth="120" /> -->
        <el-table-column prop="apiOnlineTime" label="上线时间" width="220" :formatter="iasmjs.formatDate"
           />
        <el-table-column fixed="right" label="操作" width="220">
          <template #default="scope">
            <el-button link type="primary" @click="goDetails(scope.row)" v-has="'hidden&apiOperate.statistics.invoke'">调用详情</el-button>
            <!-- <el-divider direction="vertical" /> -->
            <el-button link type="primary" @click="apiLog(scope.row)" v-has="'hidden&apiOperate.statistics.log'">操作日志</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页组件-->
    <div class="margin-top-12 margin-botton-12" >
       <el-pagination background small :current-page="page.pageNum" :page-size="page.pageSize" :page-sizes="[2, 5, 10, 20]"
        layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" style="justify-content: flex-end;"/>
    </div>
    
  </div>

  <div>
    <el-dialog v-model="invokeDetailsVisible" ref="dialog" :title=currentApiName width="70%" @close='closeDialog'>
      <el-table :data="invokeData.list" border style="width: 100%;" @filter-change="filterStatus">
        <el-table-column prop="invokeTenantName" label="调用方租户">
        </el-table-column>
        <el-table-column prop="invokeAppName" label="调用方应用">
        </el-table-column>
        <el-table-column prop="invokeStatus" label="调用状态" width="100%" align="center" :filters="[
          { text: '成功', value: '1' },
          { text: '告警', value: '2' },
          { text: '失败', value: '0' },
        ]" column-key="invokeStatus">
          <template #default="scope">
            <span v-if="scope.row.invokeStatus === 1">
              <el-icon color="#13ce66" size="150%">
                <CircleCheck />
              </el-icon>
            </span>
            <span v-if="scope.row.invokeStatus === 2">
              <el-icon color="#ff9900" size="150%">
                <Warning />
              </el-icon>
            </span>
            <span v-if="scope.row.invokeStatus === 0">
              <el-icon size="150%">
                <CircleClose />
              </el-icon>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="invokeTime" label="调用时间" width="250%" :formatter="iasmjs.formatDate" align="center">
        </el-table-column>
        <el-table-column prop="invokeLog" label="信息" width="400%" :show-overflow-tooltip="true">
        </el-table-column>
      </el-table>
      <!--分页组件-->
      <div style="padding: 10px 0">
         <el-pagination background small :current-page="pageDetail.pageNum" :page-size="pageDetail.pageSize" :page-sizes="[2, 5, 10, 20]"
          layout="total, sizes, prev, pager, next, jumper" :total="totalDetail" @size-change="handleSizeChangeDetail"
          @current-change="handleCurrentChangeDetail" />
      </div>
    </el-dialog>
  </div>

  <div>
    <el-dialog v-model="apiLogVisible" ref="dialog" :title=currentApiName width="80%" @close='closeDialog'>
      <el-table :data="logData.list" border style="width: 100%;" @filter-change="filterLogStatus">
        <el-table-column prop="operateTenantName" label="操作租户" width="150%" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="operateType" label="操作类型" width="90%">
        </el-table-column>
        <el-table-column prop="invokeAppName" label="调用方应用">
        </el-table-column>
        <el-table-column prop="inParam" label="调用参数" width="200%" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="operateStatus" label="操作状态" width="100%" align="center" :filters="[
          { text: '成功', value: '1' },
          { text: '告警', value: '2' },
          { text: '失败', value: '0' },
        ]" column-key="operateStatus">
          <template #default="scope">
            <span v-if="scope.row.operateStatus === 1">
              <el-icon color="#13ce66" size="150%">
                <CircleCheck />
              </el-icon>
            </span>
            <span v-if="scope.row.operateStatus === 2">
              <el-icon color="#ff9900" size="150%">
                <Warning />
              </el-icon>
            </span>
            <span v-if="scope.row.operateStatus === 0">
              <el-icon size="150%">
                <CircleClose />
              </el-icon>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="operatorName" label="操作人">
        </el-table-column>
        <el-table-column prop="operationTime" label="操作时间" width="165%" :formatter="iasmjs.formatDate" align="center">
        </el-table-column>
        <el-table-column prop="operateMsg" label="信息" width="220%" :show-overflow-tooltip="true">
        </el-table-column>
      </el-table>
      <!--分页组件-->
      <div style="padding: 10px 0">
         <el-pagination background small :current-page="pageLog.pageNum" :page-size="pageLog.pageSize" :page-sizes="[2, 5, 10, 20]"
          layout="total, sizes, prev, pager, next, jumper" :total="totalLog" @size-change="handleSizeChangeLog"
          @current-change="handleCurrentChangeLog" />
      </div>
    </el-dialog>
  </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import {request} from '@/api'
import { useRoute, useRouter } from 'vue-router';
import iasmjs from "@/assets/js/iasm.js";
import { ElMessageBox } from "element-plus";
import CommonSearchForm from '@/components/common/listSearchForm.vue'
import {apiStatus} from '@/utils/constant'

export default {
  name: "ApiOperationStatistics",
  components: {
    CommonSearchForm
  },
  setup() {
    let apiStatusMap = ref(apiStatus)
    const tableData = reactive({ //表格参数
      list: []
    });
    const total = ref(0);  //总条数
    const page = reactive({  //配置对应的查询参数
      pageNum: 1,
      pageSize: 10,
    });
    const totalDetail = ref(0);  //总条数
    const pageDetail = reactive({  //配置对应的查询参数
      pageNum: 1,
      pageSize: 10,
    });
    const totalLog = ref(0);  //总条数
    const pageLog = reactive({  //配置对应的查询参数
      pageNum: 1,
      pageSize: 10,
    });
    const handleSearch = (queryKey) => {
      page.pageNum = 1
      searchForm.queryKey = queryKey
      getData()
    }
    const getData = () => {
      if (router.query.apiInfo != '') {
        searchForm.queryKey = router.query.apiInfo
      }
      if (router.query.apiCategoryId != '') {
        searchForm.apiCategoryId = router.query.apiCategoryId
      }//查询列表
      searchForm.currentPage = page.pageNum
      searchForm.pageSize = page.pageSize
      request.selectApiListByStatistics(searchForm).then(res => {
        tableData.list = res.data.data.result;
        total.value = res.data.data.pageVO.total
        router.query.apiInfo = ''
        router.query.apiCategoryId = ''
      })
    };
    const handleSizeChange = (val) => { //size修改
      page.pageSize = val
      getData();
    };
    const handleCurrentChange = (val) => { //num修改
      page.pageNum = val
      getData();
    };
    const detailStatus = ref();
    const handleSizeChangeDetail = (val) => { //size修改
      pageDetail.pageSize = val
      invokeDetails(null, detailStatus.value);
    };
    const handleCurrentChangeDetail = (val) => { //num修改
      pageDetail.pageNum = val
      invokeDetails(null, detailStatus.value);
    };
    const logStatus = ref();
    const handleSizeChangeLog = (val) => { //size修改
      pageLog.pageSize = val
      apiLog(null, logStatus.value);
    };
    const handleCurrentChangeLog = (val) => { //num修改
      pageLog.pageNum = val
      apiLog(null, logStatus.value);
    };
    const router = useRoute()
    const that = useRouter()
    const editRow = (row) => { //编辑
      if (row.apiCreateSql != null) {
        that.push({
          path: '/home/ApiManage/ApiManageCreate',
          query: {
            apiId: row.apiId,
            active: 0,
            updateVersion: false
          }
        })
      } else {
        that.push({
          path: '/home/ApiManage/ApiManageRegister',
          query: {
            apiId: row.apiId,
            active: 0,
            updateVersion: false
          }
        })
      }
    }
    const viewDoc = (row) => { //api文档
       router.push({
        path:'/home/ApiManage/ApiView',
        query: {
          apiId:row.apiId,
          changeFlag:row.changeFlag || 'N'
        }
      });
    }
    let apiRequestMLabel = ref('全部')
    let statusLabel = ref('全部')
    const searchForm = reactive({
      queryKey: '',
      currentPage: '',
      pageSize: '',
      apiId: '',
      // apiName: '',
      apiRequestM: '',
      apiResponseE: '',
      // apiUrl: '',
      apiCategoryId: '',
      apiOwner: '',
      apiDescription: '',
      status: '',
      delFlag: '',
      apiCreateSql: '',
      dbId: '',
      tenantId: '',
      paramList: [],
      realParamList: [],
      fieldMappingList: [],
      userList: [],
      paramCategory: ''
    })
    const userList = reactive({
      list: []
    })
    const rqList = reactive({
      list: []
    })
    const acList = reactive({
      list: []
    })
    const reset = () => {
      // searchForm.apiName = '';
      searchForm.apiRequestM = '';
      searchForm.apiCategoryId = '';
      searchForm.status = '';
      searchForm.apiOwner = '';
      // searchForm.apiUrl = '';
      searchForm.currentPage = page.pageNum;
      searchForm.pageSize = page.pageSize;
      request.selectApiListByStatistics(searchForm).then(res => {
        tableData.list = res.data.data.result;
        total.value = res.data.data.pageVO.total
      })
    }
    const getUserList = () => {
      request.selectUser().then(res => {
        let a = res.data.data;
        userList.list = a.result;
      })
    }

    const changeApiRequestM = (val,label)=>{
      searchForm.apiRequestM = val
      apiRequestMLabel.value = label
      getData();
    }

    const changeStatus = (val,label)=>{
      searchForm.status = val
      statusLabel.value = label
      getData();
    }

    const getRequestMethods = () => {
      request.getLookupHead({lookupHCode: 'requestMethod'}).then(res => {
        let a = res.data.data;
        rqList.list = a.details;
      })
    }
    const getApiCategory = () => {
      request.getLookupHead({lookupHCode: 'apiCategory'}).then(res => {
        let a = res.data.data;
        acList.list = a.details;
      })
    };
    // // eslint-disable-next-line
    // const apiCategoryFormatter = (row, column, cellValue) => {
    //   let a = acList.list;
    //   //row每行的内容 column每一列的属性信息 cellValue单元格的值
    //   for (var i = 0; i < a.length; i++) {
    //     if (row.apiCategoryId == a[i].lookupDKey) {
    //       return a[i].lookupDValue
    //     }
    //   }
    // }
    // eslint-disable-next-line
    const requestMethodFormatter = (row, column, cellValue) => {
      let a = rqList.list;
      //row每行的内容 column每一列的属性信息 cellValue单元格的值
      for (var i = 0; i < a.length; i++) {
        if (row.apiRequestM == a[i].lookupDKey) {
          return a[i].lookupDValue
        }
      }
    };
    const invokeData = reactive({
      list: []
    });
    const invokeApiId = ref(0);
    const invokeDetailsVisible = ref(false);
    const currentApiName = ref('');
    const invokeDetails = (row, status) => {
      if (row) {
        invokeApiId.value = row.apiId;
        currentApiName.value = "API [" + row.apiName + "] 调用记录";
      }

      request.selectInvokeDetailList({
          apiId: invokeApiId.value,
          invokeStatus: status.invokeStatus[0],
          currentPage: pageDetail.pageNum,
          pageSize: pageDetail.pageSize
        }).then(res => {
        if (res.data.code == 200) {
          invokeData.list = res.data.data.result;
          totalDetail.value = res.data.data.pageVO.total
          invokeDetailsVisible.value = true;
        } else {
          ElMessageBox.alert(res.data.msg);
        }
      })
    };
    const goDetails = (row) => {
      request.findLogDetails({
          apiUrl: row.apiUrl
      }).then(res=>{
         if (res.data.code == 200) {res.data
         window.open(res.data.data,'_blank')
         }
      })
      // that.push({
      //   path: '/home/Security/InvokeDetails',
      //   query: {
      //     apiUrl: row.apiUrl,
      //     apiName: row.apiName
      //   }
      // })
    };
    const logData = reactive({
      list: []
    });
    const apiLogVisible = ref(false);
    const apiLog = (row) => {
      that.push({
        path: '/home/Security/LogDetails',
        query: {
          apiId: row.apiId,
          apiName: row.apiName
        }
      })
    };
    const closeDialog = () => {
      pageDetail.pageNum = 1;
      pageDetail.pageSize = 10;
      pageLog.pageNum = 1;
      pageLog.pageSize = 10;
      detailStatus.value = null;
    };
    const filterStatus = (value) => {
      detailStatus.value = value;
      invokeDetails(null, value);
    };
    const filterLogStatus = (value) => {
      logStatus.value = value;
      apiLog(null, value);
    };
    return {
      apiStatusMap,
      tableData,
      total,
      page,
      getData,
      iasmjs,
      handleSearch,
      handleSizeChange,
      handleCurrentChange,
      handleSizeChangeDetail,
      handleCurrentChangeDetail,
      handleSizeChangeLog,
      handleCurrentChangeLog,
      editRow,
      viewDoc,
      searchForm,
      reset,
      getUserList,
      userList,
      rqList,
      getRequestMethods,
      getApiCategory,
      acList,
      // apiCategoryFormatter,
      requestMethodFormatter,
      invokeDetails,
      invokeDetailsVisible,
      invokeData,
      currentApiName,
      totalDetail,
      pageDetail,
      totalLog,
      pageLog,
      apiLog,
      apiLogVisible,
      logData,
      closeDialog,
      filterStatus,
      filterLogStatus,
      goDetails,
      apiRequestMLabel,
      statusLabel,
      changeApiRequestM,
      changeStatus
    }
  },
  beforeCreate() {
    this.getRequestMethods();
    this.getApiCategory();
    this.getData();
  }
}
</script>
<style scoped>
  .status-icon {
    width: 16px;
    height: 16px;
    vertical-align: sub;
    padding-right: 6px;
  }
</style>