<template>
  <div>
    <el-button type="primary" @click="addRules" style="margin-bottom:20px;">新增触发规则</el-button>
    <div v-for="(item,index) in rules" :key="index">
        <div v-if="index == 0" style="margin: 16px 0;">
              <span style="margin-right:12px;">默认节点：</span>
              <el-select 
                v-model="item.nodeId" 
                style="width: 150px" 
                placeholder="请选择节点"  
                @change="changeSWITCHNode" >
                <el-option
                    v-for="item in filteredOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.disabled"/>
              </el-select>
        </div>
        <div v-else>
            <el-collapse v-model="item.activeName">
                <el-collapse-item title="触发规则" name="1">
                    <div style="display:flex;justify-content:space-between;margin-bottom:10px;">
                        <el-select 
                        v-model="item.nodeId" 
                        style="width: 150px" 
                        placeholder="请选择节点"  
                        @change="changeSWITCHNode" >
                        <el-option
                            v-for="item in filteredOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled="item.disabled"/>
                    </el-select>
                    <div>
                       <el-icon :size="25" @click="addRow(index)" ><Plus/></el-icon>
                       <el-icon :size="25" @click="deleteRule(index,item)" style="color:#f56c6c;margin-left:10px;"><Delete/></el-icon>
                    </div>
                    </div>
                    <el-table :data="item.filterTable" max-height="285">
                        <el-table-column prop="relationship" label="逻辑关系">
                            <template #default="scope">
                                <el-select
                                v-model="scope.row.relationship"
                                clearable
                                placeholder="请选择逻辑关系"
                                >
                                <el-option label="且" value="0" />
                                <el-option label="或" value="1" />
                                </el-select>
                            </template>
                        </el-table-column>

                        <el-table-column
                            prop="parameterName"
                            label="字段"
                            :show-overflow-tooltip="true"
                            >
                            <template #default="scope">
                                <el-input
                                v-model="scope.row.parameterName"
                                clearable
                                maxlength="50"
                                placeholder="请输入"
                                ></el-input>
                            </template>
                        </el-table-column>

                        <el-table-column prop="expression" label="表达式">
                            <template #default="scope">
                                <el-select
                                v-model="scope.row.expression"
                                clearable
                                placeholder="请选择表达式"
                                >
                                <el-option v-for="expression in expressionOptionsMap" :label="expression.label" :value="expression.value" :key="expression.value" />
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="parameterValue"
                            label="值"
                            :show-overflow-tooltip="true"
                            >
                            <template #default="scope">
                                <el-input
                                v-model="scope.row.parameterValue"
                                clearable
                                maxlength="50"
                                placeholder="请输入"
                                ></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="80" align="center">
                            <template #default="scope">
                                <el-popconfirm
                                    title="删除后将无法恢复，请确认是否删除？"
                                    confirm-button-type="danger"
                                    icon="warning-filled"
                                    icon-color="#F56C6C"
                                    width="250"
                                    placement="top-end"
                                    @confirm="deleteRow(scope.$index,index)"
                                >
                                <template #reference>
                                    <el-button
                                    icon="delete"
                                    type="danger"
                                    text
                                    ></el-button>
                                </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount} from 'vue';
import {expressionOptions} from '@/utils/constant'

// import {request} from '@/api'
// import { ElMessage } from "element-plus";
export default {
    props:['iasmApiArrangeNode','nodeConfigGraphData','operatorFlowId'],
    setup(props){
        let rules = ref([
            {
               nodeId:null,
               activeName:'1'
            }
        ])
        let componentsNodeList = ref([])
        let selectedSWITCHNode = ref([])
        let expressionOptionsMap = ref(expressionOptions)

        if(props.iasmApiArrangeNode && props.iasmApiArrangeNode.nodeData){
            let nodeData = JSON.parse(props.iasmApiArrangeNode.nodeData)
            rules.value = nodeData.data
            
            selectedSWITCHNode.value = nodeData.data.map(item=>{
                return item.nodeId
            })
        }

        const filteredOptions = computed(() => componentsNodeList.value.map(node=>{
            //已选择的和开始，结束算子
            if(selectedSWITCHNode.value.includes(node.value) || node.nodeId === "1146490369635262464" || node.nodeId === "1146490369635262470"){
                node.disabled = true
            }else{
                node.disabled = false
            }
            return node
        }));

        const addRules = ()=>{
            rules.value.push({
                nodeId:null,
                activeName:'1',
                filterTable:[{
                    relationship:null,
                    parameterName:null,
                    expression:null,
                    parameterValue:null,
                }]
            })
        }

        const addRow = (index)=>{
            rules.value[index].filterTable.push({
                    relationship:null,
                    parameterName:null,
                    expression:null,
                    parameterValue:null,
                })
        }

        const deleteRule = (index,item)=>{
            rules.value.splice(index, 1);
            selectedSWITCHNode.value = selectedSWITCHNode.value.filter(node=>{
                return node !== item.nodeId
            })
        }

        // 获取画布中节点列表
        const getNodeList = ()=>{
            componentsNodeList.value = []
            
            props.nodeConfigGraphData.nodes.forEach(element => {
                if(element.id !== props.operatorFlowId ){
                componentsNodeList.value.push({
                    value: element.id,
                    label: element.text.value,
                    nodeId: element.properties.nodeId
                })
                }
            });
        }

          // 高级算子-SWITCH算子 改变节点
        const changeSWITCHNode = ()=>{
            selectedSWITCHNode.value = rules.value.map(item=>{
                return item.nodeId
            })     
        }

        const deleteRow = (index,itemIndex)=>{
             rules.value[itemIndex].filterTable.splice(index, 1)
        }

        const getNodeData = ()=>{
            return {
                data:rules.value
            }
        }

        const validate = ()=>{
          return true
        }

        const reset = ()=>{
            rules.value = [
                {
                nodeId:null,
                activeName:'1'
                }
            ]
        }
        onMounted(()=>{
            getNodeList()
        })

        onBeforeUnmount(() => {
         reset()
        });
       
        return{
           validate,
           reset,
           addRules,
           rules,
           getNodeList,
           changeSWITCHNode,
           selectedSWITCHNode,
           componentsNodeList,
           filteredOptions,
           expressionOptionsMap,
           deleteRow,
           addRow,
           deleteRule,
           getNodeData
        }
    }

}
</script>

<style lang="less" scoped>
.step-title{
    margin-top: 50px;
}
</style>