<template>
  <div
    style="
      text-align: left;
      height: calc(100% - 28px);
      width: 100%;
      padding-left: 0px;
      padding-top: 14px;
      margin-left: 0px;
      margin-top: 0px;
    "
  >
    <!--查询框-->
    <div style="text-align: left; width: 100%; margin-bottom: 1%">
      <el-input
        v-model="formData.likeData"
        placeholder="模糊匹配 应用ID 应用名称"
        style="width: 400px"
        clearable
        class="input-transparent"
        @keyup.enter="onSearch()"
        >
        <template #suffix>
            <el-icon class="icon-search" @click="onSearch()"><Search /></el-icon>
         </template>
      </el-input>
     
    </div>
    <!--添加修改抽屉框-->
    <div>
      <el-drawer v-model="drawer" :direction="direction" destroy-on-close>
        <div
          style="
            text-align: left;
            margin-left: 0%;
            margin-bottom: 20%;
            margin-top: 1%;
            font-size: 26px;
          "
        >
          <span style="width: 8px; height: 30px; background-color: #409eff"
            >&nbsp;&nbsp;</span
          >
          <span style="margin-left: 2%">{{ labelName }}</span>
        </div>
        <el-form
          label-width="100px"
          :model="updatevalue"
          style="max-width: 460px"
          :rules="rules"
          ref="ruleFormRef"
        >
          <el-form-item label="应用编码" prop="appCode">
            <el-input
              v-model="updatevalue.appCode"
              maxlength="20"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="应用名称" prop="appName">
            <el-input
              v-model="updatevalue.appName"
              maxlength="100"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="应用描述" prop="description">
            <el-input
              :rows="4"
              type="textarea"
              maxlength="1000"
              show-word-limit
              v-model="updatevalue.description"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <div>
            <el-button type="primary" @click="confirmClick(ruleFormRef)"
              >保存</el-button
            >
            <el-button @click="cancelClick">取消</el-button>
          </div>
        </template>
      </el-drawer>
    </div>
    <!-- App信息展示table-->
    <div class="white-bg box-card-radius-eight">
      <div style="text-align:left;margin-bottom:20px;">
         <el-button
        type="primary"
        @click="addAppInforamation()"
        v-has="'hidden&basicSetup.app.add'"
      >
        <el-icon :size="20">
          <CirclePlus />
        </el-icon>
        新建
      </el-button>
      </div>
      <el-table :data="tableData.list" stripe style="width: 100%">
        <el-table-column
          prop="no"
          label="序号"
          width="60%"
          align="center"
          fixed
          type="index"
        />
        <el-table-column
          prop="appCode"
          label="应用编码"
          :show-overflow-tooltip="true"
          fixed
        />
        <el-table-column
          prop="appName"
          label="应用名称"
          :show-overflow-tooltip="true"
          fixed
        />
        <el-table-column
          prop="tenantName"
          label="租户"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="appAuthCode"
          label="授权码"
          width="300%"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="updatedName"
          label="最后更新人"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="description"
          label="描述"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="updateTime"
          label="最后更新时间"
          width="200%"
          :formatter="formatDateTime"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="operation"
          label="操作"
          width="250%"
          fixed="right"
        >
          <template #default="scope">
              <el-button
              icon="Edit"
              type="primary"
              link
              size="medium"
              @click="edit(scope.row)"
              v-has="'hidden&basicSetup.app.edit'"
            >
            </el-button>
           
            <el-divider direction="vertical" />
            <el-button
              icon="key"
              type="primary"
              link
              size="medium"
              @click="getAppDetails(scope.row)"
              v-has="'hidden&basicSetup.app.authorization'"
            >
            </el-button>
            
            <el-divider direction="vertical" />
            <el-button
              icon="delete"
              style="color:#f56c6c;"
              link
              size="medium"
              @click="del(scope.row)"
              v-has="'hidden&basicSetup.app.delete'"
            >
            </el-button>
           
          </template>
        </el-table-column>
      </el-table>
   
    <!--分页组件-->
    <div style="padding: 10px 0">
       <el-pagination 
       style="float:right;"
       background small
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        :page-sizes="[2, 5, 10, 20]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
     </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import {request} from '@/api'
import { ElMessage, ElMessageBox } from "element-plus";
import router from "@/router";

export default {
  name: "AppInformation",
  setup() {
    const getData = () => {
      //查询列表
      request.selectApp({
          appCode: formData.likeData,
          appName: formData.likeData,
          currentPage: page.pageNum,
          pageSize: page.pageSize,
        }).then((res) => {
        tableData.list = res.data.data.result;
        total.value = res.data.data.pageVO.total;
      });
    };

    const tableData = reactive({
      //表格参数
      list: [],
    });
    const page = reactive({
      //配置对应的查询参数
      pageNum: 1,
      pageSize: 10,
    });
    const total = ref(0);
    const formatDateTime = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      let y = dt.getFullYear();
      let m = (dt.getMonth() + 1 + "").padStart(2, "0");
      let d = (dt.getDate() + "").padStart(2, "0");
      let hh = (dt.getHours() + "").padStart(2, "0");
      let mm = (dt.getMinutes() + "").padStart(2, "0");
      let ss = (dt.getSeconds() + "").padStart(2, "0");
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
    };

    const onSearch = () => {
      getData();
    };
    const formData = reactive({
      likeData: "",
    });
    const direction = ref("rtl");
    const drawer = ref(false);
    const updatevalue = reactive({
      appCode: "",
      appName: "",
      appId: "",
      description: "",
    });
    const validateUrl = (rule, value, callback) => {
      const reg =  /^[a-zA-Z]{1}([a-zA-Z0-9]){1,}$/
      if (reg.test(value)) {
        callback();
      } else {
        callback(
          new Error(
            "长度20，只允许大写字母、小写字母、数字，且只能以字母开头, 以英文，数字结尾"
          )
        );
      }
    };
    const rules = reactive({
      appCode: [
        {
          required: true,
          message: "应用Id未填写",
          trigger: "blur",
          min: 1,
          max: 20,
        },
        { validator: validateUrl, trigger: 'blur'}
      ],
      appName: [
        {
          required: true,
          message: "应用名称未填写",
          trigger: "blur",
          min: 1,
          max: 100,
        },
      ],
      status: [{ required: true }],
    });
    const labelName = ref("");
    const addAppInforamation = () => {
      labelName.value = "新建应用";
      updatevalue.appCode = null;
      updatevalue.appName = null;
      updatevalue.appId = null;
      updatevalue.description = null;
      drawer.value = true;
    };
    const confirmClick = async (formEl) => {
      await formEl.validate((valid) => {
        if (valid) {
          if (
            updatevalue.appID == "" ||
            updatevalue.appId == null ||
            updatevalue.appId == undefined
          ) {
            //新增应用配置
            request.insertApp({
                appCode: updatevalue.appCode,
                appName: updatevalue.appName,
                description: updatevalue.description,
              }).then((resp) => {
                if (resp.data.code == 200) {
                  updatevalue.appCode = null;
                  updatevalue.appName = null;
                  updatevalue.description = null;
                  updatevalue.appId = null;
                  drawer.value = false;
                  ElMessage.success(resp.data.msg);
                } else {
                  ElMessage.error(resp.data.msg);
                }
              })
              .then(() => {
                getData();
              });
          } else {
            if (valid) {
              request.updateApp({
                  appCode: updatevalue.appCode,
                  appName: updatevalue.appName,
                  description: updatevalue.description,
                  appId: updatevalue.appId,
                  delFlag: "N",
                }).then((resp) => {
                  if (resp.data.code == 200) {
                    updatevalue.appCode = null;
                    updatevalue.appName = null;
                    updatevalue.description = null;
                    updatevalue.appId = null;
                    drawer.value = false;
                    ElMessage.success(resp.data.msg);
                  } else {
                    ElMessage.error(resp.data.msg);
                  }
                })
                .then(() => {
                  getData();
                });
            }
          }
        }
      });
    };

    const del = (row) => {
      ElMessageBox.confirm(`确定删除当前应用配置:` + row.appName)
        .then(() => {
          request.deleteApp({
              appCode: row.appCode,
              appName: row.appName,
              description: row.description,
              appId: row.appId,
              appAuthCode: row.appAuthCode,
            }).then((resp) => {
            drawer.value = false;
            if (resp.data.code == 200) {
              ElMessage.success(resp.data.msg);
            } else {
              ElMessage.warning(resp.data.msg);
            }
            getData();
          });
        })
        .catch(() => {});
    };

    function cancelClick() {
      updatevalue.appCode = null;
      updatevalue.appName = null;
      updatevalue.description = null;
      updatevalue.appId = null;
      drawer.value = false;
    }

    const edit = (rowData) => {
      labelName.value = "编辑应用配置";
      updatevalue.appCode = rowData.appCode;
      updatevalue.appName = rowData.appName;
      updatevalue.description = rowData.description;
      updatevalue.appId = rowData.appId;
      drawer.value = true;
    };
    const getAppDetails = (row) => {
      router.push({
        path: "/home/BaseConfig/APP/AppDetails",
        query: {
          appCode: row.appCode,
          appName: row.appName,
          appAuthCode: row.appAuthCode,
          appId: row.appId,
        },
      });
    };
    const handleSizeChange = (val) => {
      //size修改
      page.pageSize = val;
      getData();
    };
    const handleCurrentChange = (val) => {
      //num修改
      page.pageNum = val;
      getData();
    };
    const ruleFormRef = ref(null);
    const reset = () => {
      formData.likeData = null;
      page.pageNum = 1;
      page.pageSize = 10;
      getData();
    };
    return {
      tableData,
      formatDateTime,
      getData,
      formData,
      page,
      total,
      onSearch,
      direction,
      drawer,
      updatevalue,
      rules,
      labelName,
      addAppInforamation,
      confirmClick,
      cancelClick,
      edit,
      del,
      getAppDetails,
      handleSizeChange,
      handleCurrentChange,
      ruleFormRef,
      reset,
    };
  },
  beforeCreate() {
    this.getData();
  },
};
</script>

<style scoped></style>