<template>
  <div style="height: calc(100% - 28px);width: 100%;padding-left: 0px;padding-top: 14px;margin-left: 0px;margin-top: 0px">
    <div>
      <div style="text-align: left;width:100%;margin-bottom: 1%">
            <el-input v-model="formData.likeData" placeholder="模糊匹配 角色编码 角色名称" style="width:400px" clearable  class="input-transparent" 
            @keyup.enter="getData()"
             >
            <template #suffix>
                <el-icon class="icon-search" @click="getData()"><Search /></el-icon>
            </template>
          </el-input>
            
      </div>
      <el-drawer v-model="drawer2" destroy-on-close>
        <template #default>
          <div style="text-align:left;margin-left: 0%;margin-bottom: 20%;margin-top: 1%;font-size:26px">
            <span style="width: 8px; height: 30px; background-color: #409eff;">&nbsp;&nbsp;</span>
            <span style="margin-left: 2%">{{ labelName }}</span>
          </div>
          <div>
            <el-form label-width="100px" :model="updateData" style="max-width: 460px" :rules="notNull" ref="ruleFormRef">
              <el-form-item>
                <el-input v-model="updateData.roleId" v-show="false" />
              </el-form-item>
              <el-form-item label="角色编码" prop="roleCode">
                <el-input v-model="updateData.roleCode" maxlength="20" show-word-limit />
              </el-form-item>
              <el-form-item label="角色名称" prop="roleName">
                <el-input v-model="updateData.roleName" maxlength="20" show-word-limit />
              </el-form-item>
              <el-form-item label="角色状态" prop="status">
                <el-radio v-model="updateData.status" label="1">启用</el-radio>
                <el-radio v-model="updateData.status" label="2">禁用</el-radio>
              </el-form-item>
              <el-form-item label="备注">
                <el-input :rows="4" type="textarea" v-model="updateData.description" maxlength="1000" show-word-limit />
              </el-form-item>
            </el-form>
          </div>
        </template>
        <template #footer>
          <div style="flex: auto">
            <el-button type="primary" @click="saveRoleClick(ruleFormRef)">保存</el-button>
            <el-button @click="cancelRoleClick">取消</el-button>
          </div>
        </template>
      </el-drawer>
    </div>
    <div class="white-bg box-card-radius-eight">
      <!-- <p align="left"><font size="30">角色管理 </font>
      <el-icon :size="30"><CirclePlus /></el-icon>新建角色
    </p> -->
    <div style="text-align:left;margin-bottom:20px;">
      <el-button type="primary" @click="addRoleClick()"  v-has="'hidden&basicSetup.role.add'">
              <el-icon :size="20">
                <CirclePlus />
              </el-icon>新建
            </el-button>
      <el-button @click="clearRoleAuth" >清除缓存</el-button>   
    </div>
      <el-table :data="tableData.list" :row-key="getRowKey">
        <el-table-column prop="no" label="序号" width="60" align="center" fixed type="index" />
        <el-table-column v-if="false" prop="roleId" />
        <el-table-column label="角色名称" prop="roleName"  />
        <el-table-column label="角色编码" prop="roleCode"  />
        <el-table-column label="角色类型" prop="roleType"  >
          <template #default="scope">
           {{scope.row.roleType === 'system_role'?'系统角色':'自定义角色'}}
          </template>
          </el-table-column>
        <el-table-column label="最后更新人" prop="updatedName" :show-overflow-tooltip="true" />
        <el-table-column label="最后更新时间" prop="updateTime" :formatter="iasmjs.formatDate" :show-overflow-tooltip="true" />
        <el-table-column label="角色状态" align="center" prop="status">
          <template #default="scope">
            <span v-if="scope.row.status === '1'">
              <el-icon color="#0E68E7" size="150%">
                <CircleCheck />
              </el-icon>
            </span>
            <span v-else><el-icon size="150%">
                <CircleClose />
              </el-icon></span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" prop="operation" width="250" fixed="right">
          <template #default="scope">
            <el-tooltip content="设置权限">
                <el-button size="medium" icon="tools" link  type="primary" @click="setPermission(scope.row)" v-has="'hidden&basicSetup.role.getAuthTree'"
              :disabled="scope.row.isUpdate && scope.row.isUpdate === '0'"></el-button>
            </el-tooltip>
            <el-divider direction="vertical"  />
            <el-tooltip content="编辑角色">
            <el-button size="medium" icon="Edit" type="primary" link @click="editRoleClick(scope.row)" v-has="'hidden&basicSetup.role.edit'"
              :disabled="scope.row.isUpdate && scope.row.isUpdate === '0'"></el-button>
            </el-tooltip>
            <el-divider direction="vertical" />
            <el-tooltip content="删除角色">
            <el-button size="medium" icon="delete" type="danger" link @click="deleteRow(scope.row)" v-has="'hidden&basicSetup.role.delete'"
              :disabled="scope.row.isUpdate && scope.row.isUpdate === '0'"></el-button>
            </el-tooltip>
              <el-divider direction="vertical" />
            
          </template> 
        </el-table-column>
      </el-table>
      <!--  <el-pagination background small class="page-position-common" current-page="" background layout="total, sizes, prev, pager, next"
                    :total="total" v-model:currentPage="currentPage" v-model:page-size="pageSize"
                   :page-sizes="[1, 5, 10, 15, 20, 50]"
                   @size-change="handleSizeChange" @current-change="changePage"/> -->

      <el-col :span="24" class="toolbar" style="text-align:center;padding:10px 0">
         <el-pagination background small style="float:right;" :current-page="page.currentPage" :page-size="page.pageSize" :page-sizes="[2, 5, 10, 20]"
          layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </el-col>
    </div>
    <div>
      <el-dialog v-model="centerDialogVisible" ref="dialog" title="权限设置" width="55%" :before-close="cancelRoleAuth">
        <span>
          <div v-show="false"> <!-- 树获取roleId用，隐藏 -->
            <el-select v-model="roleId" ref="roles" filterable>
              <el-option v-for="item in form.list" :key="item.roleId" :label="item.roleName" :value="item.roleId" />
            </el-select>
          </div>
          <el-tree :data="authTree.list" ref="tree" show-checkbox node-key="menuId" :render-content="renderContent"
            :default-expand-all="true" :default-checked-keys="authorityList.list" :props="defaultProps" />
        </span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelRoleAuth()">取消</el-button>
            <el-button type="primary" @click="saveRoleAuth()">
              确定
            </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { CirclePlus } from '@element-plus/icons';
import { ElMessage, ElMessageBox } from "element-plus";
import {request} from '@/api'
import { reactive, ref } from 'vue';
import iasmjs from "@/assets/js/iasm.js";

export default {
  setup() {
    const formData = reactive({
      likeData: '',
    });
    const tableData = reactive({ //表格参数
      list: []
    });
    const total = ref(0);  //总条数
    const page = reactive({  //配置对应的查询参数
      currentPage: 1,
      pageSize: 10,
    });
    const updateData = reactive({
      roleId: '',
      roleCode: '',
      roleName: '',
      status: '1',
      description: '',
      roleType:'custom_role'
    });
    const form = reactive({
      list: []
    });
    const roleId = ref(null);
    const drawer2 = ref(false);
    const centerDialogVisible = ref(false);
    const labelName = ref('');
    const authorityList = reactive({
      list: []
    });
    const defaultProps = {
      children: 'children',
      label: 'menuName',
    }
    const authTree = reactive(
      {
        list: []
      }
    )
    const getData = () => {
      request.selectRole({  
        //注意是params，get请求必须写上params，不能写data
          tenantId: localStorage.getItem("tenantId"),
          likeData: formData.likeData,
          currentPage: page.currentPage,
          pageSize: page.pageSize
        }).then(res => {
      if(res.data.code==200){
          tableData.list = res.data.data.result;
        total.value = res.data.data.pageVO.total
      }
      })
    };
    const handleSizeChange = (val) => { //size修改
      page.pageSize = val
      getData();
    };
    const handleCurrentChange = (val) => { //num修改
      page.currentPage = val
      getData();
    };
    const deleteRow = (row) => {
      ElMessageBox.confirm('请确认删除当前角色：' + row.roleName)
        .then(() => {
          request.delRole(row).then(res => {
            let respCode = res.data.code;
            if (respCode == '200') {
              drawer2.value = false;
              ElMessage.success(res.data.msg);
              getData()
            } else {
              ElMessage.error(res.data.msg);
            }
          })
        }).catch(() => { })
    };
    //const saveRoleClick = () => {
    const saveRoleClick = async (formEl) => { //保存
      await formEl.validate(async (valid) => { //
        if (valid) {
          let res
          if (updateData.roleId == "") {
            res = await request.addRole({
              ...updateData,
              appId:localStorage.getItem("appId")
            })
          } else {
            res = await request.updateRole({
              ...updateData,
              appId:localStorage.getItem("appId")
            })
          }
          let respCode = res.data.code;
            if (respCode == '200') {
              drawer2.value = false;
              ElMessage.success(res.data.msg);
              getData()
            } else {
              ElMessage.error(res.data.msg);
            }
        }
      })
    }
    const addRoleClick = () => {
      labelName.value = '新建角色';
      drawer2.value = true;
      updateData.roleId = '';
      updateData.roleCode = '';
      updateData.roleName = '';
      updateData.status = '1';
      updateData.description = '';
      updateData.roleType = 'custom_role';
    };
    const editRoleClick = (rowData) => {
      labelName.value = '编辑角色';
      drawer2.value = true;
      updateData.roleId = rowData.roleId;
      updateData.roleCode = rowData.roleCode;
      updateData.roleName = rowData.roleName;
      updateData.status = rowData.status;
      updateData.description = rowData.description;
      updateData.roleType = rowData.roleType;
    };
    const cancelRoleClick = () => {
      drawer2.value = false;
    };
    const ruleFormRef = ref(null);
    const notNull = reactive({
      roleCode: [{ required: true, message: '请输入角色编码', trigger: 'blur' }],
      roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
      status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
    });
    const setPermission = (rowData) => {
      form.list.push(rowData);
      request.findMenuList(
        {projectId:1}
      ).then(res => {
        if (res.data.code == 200) {
          authTree.list = res?.data?.data || [];
          // 只有超级管理员才展示基础设置选项
          if (localStorage.getItem('currentRoleId') !== '802') {
            authTree.list = authTree.list.filter(el => el.authorityCode !== 'basicSetup' && el.authorityName !== '基础设置')
          }
          request.getAuthByRoleId({roleId: rowData.roleId}).then(res1 => {
            // eslint-disable-next-line
            authorityList.list = res1.data.data;
            handleExpand();
          })
          roleId.value = rowData.roleId;
        } else {
          ElMessageBox.alert(res.data.msg);
        }
      })
    };
    const handleExpand = () => {//节点被渲染后时触发的事件
      //因为该函数执行在renderContent函数之前，所以得加定时
      setTimeout(() => {
        changeCss();
      }, 100);
      centerDialogVisible.value = true;
    }
    const changeCss = () => {
      let levelName = document.getElementsByClassName("foo"); // levelname是上面的最底层节点的名字
      for (var i = 0; i < levelName.length; i++) {
        levelName[i].parentNode.style.cssFloat = "left"; // 最底层的节点，包括多选框和名字都让他左浮动
        levelName[i].parentNode.style.styleFloat = "left";
        levelName[i].parentNode.onmouseover = function () {
          this.style.backgroundColor = "#fff";
        };
      }
    };
    const reset = () => {
      formData.likeData = null;
      page.currentPage = 1;
      page.pageSize = 10;
      getData();
    };
    const clearRoleAuth = () => {
      request.clearRoleAuth().then(res => {
        let respCode = res.data.code;
        if (respCode == '200') {
          ElMessage.success(res.data.data);
          getData()
        } else {
          ElMessage.error(res.data.data || res.data.msg);
        }
      })
    }
    return {
      tableData,
      total,
      page,
      updateData,
      drawer2,
      labelName,
      formData,
      getData,
      deleteRow,
      handleSizeChange,
      handleCurrentChange,
      addRoleClick,
      editRoleClick,
      saveRoleClick,
      cancelRoleClick,
      iasmjs,
      notNull,
      ruleFormRef,
      centerDialogVisible,
      setPermission,
      authTree,
      defaultProps,
      authorityList,
      handleExpand,
      form,
      roleId,
      reset,
      clearRoleAuth
    }
  },
  name: "BaseConfigRole",
  components: { CirclePlus },
  beforeCreate() {
    this.getData();
  },
  methods: {
    getRowKey(row) {
      return row.roleId;
    },
    saveRoleAuth() {
      let total = this.getCheckedKeys();
      let roleId = this.$refs.roles.selected.value;
      request.saveRoleAuth({
          roleId: roleId,
          authList: total
        }).then(res => {
        let respCode = res.data.code;
        if (respCode == '200') {
          // 关闭弹窗
          this.$refs.dialog.visible = false;
          this.$refs.tree.setCheckedKeys([]);
          ElMessage.success('设置成功');
          if(roleId == parseInt(localStorage.getItem("currentRoleId"))){
            this.changecurrentRoleAuth()
          }
        } else {
          ElMessage.error('设置失败');
        }
      })
    },

    changecurrentRoleAuth(){
      request.switchRoles({
              userId: localStorage.getItem("userId"),
              roleId: parseInt(localStorage.getItem("currentRoleId")),
            }).then((resp) => {
            localStorage.setItem("AuthCodeList", resp.data.data);
            window.location.reload();
          });
    },

    getCheckedKeys() {
      let zi = this.$refs.tree.getCheckedKeys()//返回选中子节点的key
      let fu = this.$refs.tree.getHalfCheckedKeys()//返回选中子节点的父节点的key
      let total = zi.concat(fu)
      return total;
    },
    // eslint-disable-next-line
    renderContent(h, { node, data, store }) {//树节点的内容区的渲染 Function
      // eslint-disable-next-line
      let classname = '';
      // 由于项目中有二级菜单也有三级菜单，就要在此做出判断
      if (node.level === 3) {
        classname = "foo";
      }
      if (node.level === 2 && node.childNodes.length === 0) {
        classname = "foo";
      }
      // 在需要做横向排列的模块做标记
      return h(
        "p",
        {
          class: classname
        },
        node.label
      );
    },
    cancelRoleAuth() {
      // 关闭弹窗
      this.$refs.dialog.visible = false;
      this.$refs.tree.setCheckedKeys([]);
    }
  }
}
</script>

<style scoped>
.page-position-common {
  float: right;
  margin-right: 15px !important;
  margin-left: 5px;
}</style>