import {baseApi} from './baseApi'
import {businessApi} from './businessApi'
// import {publicApi} from './publicApi'


export const request = {
    ...baseApi,
    ...businessApi,
    // ...publicApi
}
   