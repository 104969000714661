<template>
  <el-drawer v-model="showDrawer" size="1000" style="text-align:left;"  @closed="close">
    <template #header>
      <h4 class="drawer-title">添加编排组件</h4>
    </template>
    <template #default>
      <div>
        <div style="text-align: left; margin-bottom: 20px">
            <el-button
            type="primary"
            @click="add()"
            >
            <el-icon :size="20">
                <Plus /> </el-icon
            >添加编排组件
            </el-button>
        </div>
       <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column
          prop="nodeName"
          label="编排组件名称"
           width="200"
          :show-overflow-tooltip="true"
        >
         <template #default="scope">
            <el-input
                v-model="scope.row.nodeName"
                clearable
                placeholder="请输入"
                ></el-input>
            </template>
        </el-table-column>
        <el-table-column
          prop="nodeClassPath"
          label="类路径"
           width="500"
          :show-overflow-tooltip="true"
        >
        <template #default="scope">
            <el-input
                v-model="scope.row.nodeClassPath"
                clearable
                placeholder="具体执行类地址，eg : com.iods.data.system.node.TestProcessArrangeNode"
                ></el-input>
            </template>
        </el-table-column>
        <el-table-column
          prop="nodeStatus"
          label="状态"
           width="100"
        >
         <template #default="scope">
              <el-switch v-model="scope.row.nodeStatus" @change="changeNodeStatus(scope.row)"  :active-value="1" :inactive-value="2" class="main-el-switch" active-text="生效"  inactive-color="rgba(0, 0, 0, 0.25)" inactive-text="冻结"></el-switch>
         </template>
         </el-table-column>

        <el-table-column
          prop="createTime"
          label="创建时间"
           width="200"
          :show-overflow-tooltip="true"
        />
        
        <el-table-column
          prop="updateTime"
          label="最后修改时间"
           width="200"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="nodeDesc"
          label="描述"
          width="200"
          :show-overflow-tooltip="true"
        >
        <template #default="scope">
            <el-input
                v-model="scope.row.nodeDesc"
                clearable
                placeholder="请输入"
                ></el-input>
            </template>
        </el-table-column>
        
         <el-table-column label="操作" width="80" align="center" fixed="right">
                    <template #default="scope">
                        <el-popconfirm
                        title="删除后将无法恢复，请确认是否删除？"
                        confirm-button-type="danger"
                        icon="warning-filled"
                        icon-color="#F56C6C"
                        width="250"
                        placement="top-end"
                        @confirm="deleteRow(scope.$index)"
                        >
                        <template #reference>
                            <el-button
                            icon="delete"
                            type="danger"
                            text
                            ></el-button>
                        </template>
                        </el-popconfirm>
                    </template>
                    </el-table-column>
       </el-table>
      </div>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="cancelClick">取消</el-button>
        <el-button type="primary" @click="confirmClick">确定</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script>
import { ref } from 'vue';
import { request } from "@/api";
import {ElMessage } from "element-plus";
export default {
  emits:['reloadTable'],
setup(_,{emit}){
    let showDrawer = ref(false)
    let tableData = ref([])
    let packageId = ref(null)

    const add = ()=>{
        tableData.value.push({
          packageId:packageId.value,
					appId: localStorage.getItem("appId") ,
					nodeName: null,
					nodeClassPath: null,
					nodeDesc: null,
          nodeStatus:1
        })
    }
    const show = (id)=>{
        packageId.value = id
        showDrawer.value = true
        getPackageClassByPackageId()
    }

    const cancelClick = ()=>{
         showDrawer.value = false
        //  tableData.value = []
    }

    const confirmClick = ()=>{
       request
            .savePackageClassList({
              packageId:packageId.value,
              appId:localStorage.getItem("appId"),
              classList:tableData.value
              })
            .then((res) => {
              if (res?.data?.code === 200) {
                 emit('reloadTable')
                 showDrawer.value = false
              }
            });
    } 

    const changeNodeStatus = (row)=>{
       request
            .updatePackageClassStatus({ 
              classId:row.classId,
              nodeStatus:row.nodeStatus,
             })
            .then((res) => {
              if (res?.data?.code === 200) {
                  ElMessage.success("修改成功");
              }
            });
    }

    const deleteRow = (index)=>{
          tableData.value.splice(index, 1);
    }

    const close = ()=>{
        tableData.value = []
    }

    const getPackageClassByPackageId = ()=>{
         request
            .getPackageClassByPackageId({packageId:packageId.value})
            .then((res) => {
              if (res?.data?.code === 200) {
                tableData.value = res.data.data
              }
            });
    }

    return {
        showDrawer,
        tableData,
        show,
        cancelClick,
        confirmClick,
        changeNodeStatus,
        deleteRow,
        close,
        add,
        getPackageClassByPackageId,
        packageId
    }
}
}
</script>

<style>

</style>