import { createRouter, createWebHashHistory } from 'vue-router'
// import {basicRoutes} from './routes'
// import {routeList} from '@/router/routes/routeList'
// import { transformObjToRoute } from '@/router/helper/routeHelper';
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import MyAPI from '@/views/Mine/myAPI.vue'
import toDevelopServices from '@/views/Mine/toDevelopServices.vue'

import MySubscription from '@/views/Mine/MySubscription.vue'
import ApiMarket from '@/components/ApiMarket.vue'
import DataSourceManage from '@/components/DataSourceManage.vue'
import ApiManage from '@/components/ApiManage.vue'
import BaseConfig from '@/components/BaseConfig.vue'
import ApiInformation from '@/views/ApiMarketMain/ApiInformation.vue'
import ApiManageCreate from '@/views/ApiManageMain/ApiManageCreate.vue'
import ApiTest from '@/views/ApiManageMain/ApiTest.vue'

import ApiManageRegister from '@/views/ApiManageRegister'
import ApiManageRegisterDetail from '@/views/ApiManageRegister/Detail'
import ApiManageRegisterView from '@/views/ApiManageRegister/view'
import ApiConfirmPage from '@/views/ApiManageMain/ApiConfirmPage.vue'
import ApiDocView from '@/views/ApiManageMain/ApiDocView.vue'
import ApiOperationStatistics from '@/views/ApiOperationMain/ApiOperationStatistics.vue'
import LogDetails from '@/views/ApiOperationMain/LogDetails.vue'
import ApiOperationLow from '@/views/ApiOperationMain/ApiOperationLow.vue'
import BaseConfigTenant from '@/views/BaseConfigMain/BaseConfigTenant.vue'
import CategoryManage from '@/views/BaseConfigMain/CategoryManage.vue'
import BaseConfigUser from '@/views/BaseConfigMain/BaseConfigUser.vue'
import BaseConfigRole from '@/views/BaseConfigMain/BaseConfigRole.vue'
import AuthorityManagement from '@/views/BaseConfigMain/menuManagement'
import AuthorityManagementDetail from '@/views/BaseConfigMain/menuManagement/detail.vue'
import AccountSettings from '@/views/UserCenterMain/AccountSettings.vue'
import AppInformation from '@/views/BaseConfigMain/AppInformation/AppInformation.vue'
import AppDetails from '@/views/BaseConfigMain/AppInformation/AppDetails.vue'
import App from '@/App.vue'
import start from '@/views/start.vue'
import DataSourceList from '@/components/DataSourceList.vue'
import DataSourceInfo from '@/components/SetDataSourceInfo.vue'
import Security from '@/components/Security.vue'
import Alarm from '@/views/Security/Alarm.vue'
import Policy from '@/views/Security/Policy.vue'
import ApprovalCenter from '@/views/approvalCenter'
import ServiceClassificationManagement from '@/views/ServiceClassificationManagement'
import ServiceClassificationManagementDetail from '@/views/ServiceClassificationManagement/Detail.vue'
import OverallfunctionList from '@/views/OverallfunctionManagement'
import OverallfunctionDetail from '@/views/OverallfunctionManagement/Detail.vue'
import KeyManagementList from '@/views/KeyManagement'
import KeyManagementDetail from '@/views/KeyManagement/Detail.vue'
import ApiDesignDetail from '@/views/ApiDesign/Detail.vue'
import ApiDesign from '@/views/ApiDesign'
import myDesign from '@/views/Mine/myDesign'
import APIAuthManagementList from '@/views/APIAuthManagement/APIAuthManagementList'
import APIAuthManagementDetail from '@/views/APIAuthManagement/Detail'
import ProjectManagementList from '@/views/ProjectManagement/ProjectManagementList'
import ProjectManagementDetail from '@/views/ProjectManagement/Detail'
import approvalConfig from '@/views/BaseConfigMain/ApprovalConfig'
import ApprovalConfigDetail from '@/views/BaseConfigMain/ApprovalConfig/Detail'

import ApiFlowChartManagementList from '@/views/ApiFlowChart/ApiFlowChartManagement/index.vue'
import ApiFlowChartManagementEdit from '@/views/ApiFlowChart/ApiFlowChartManagement/edit.vue'
import ApiFlowChartManagementDetail from '@/views/ApiFlowChart/ApiFlowChartManagement/flow/index.vue'
import ApiCodePluginsList from '@/views/ApiFlowChart/apiCodePlugins/index.vue'

import OperateManagementList from '@/views/OperateManagement/list.vue'
import OperateManagementEdit from '@/views/OperateManagement/edit.vue'

import OperateIndicatorsManagementList from '@/views/OperateIndicatorsManagement/list.vue'
import OperateIndicatorsManagementEdit from '@/views/OperateIndicatorsManagement/edit.vue'

import noPermission from '@/views/noPermission'

import Cockpit from '@/views/Cockpit'
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/start',
    name: 'start',
    component: start,
  },
  {
    path: '/home',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Home,
    redirect: '/home/mine',
    children: [
      {
        path: '/home/noPermission',
        name: 'noPermission',
        component: noPermission,
        meta: { title: 'noPermission' },
      },
      {
        path: '/home/serviceOperation',
        name: 'serviceOperation',
        meta: {
          isDirect: true,
          permission: 'menu:service_operation',
        },
        children: [
          {
            path: '/home/serviceOperation/ApiTest',
            name: 'ApiTest',
            component: ApiTest,
            meta: {
              permission: 'serviceOperation.ApiTest',
            },
          },
        ],
      },
      {
        path: '/home/mine',
        name: 'mine',
        meta: { isDirect: true },
        children: [
          {
            path: '/home/mine/toDevelopServices',
            name: 'toDevelopServices',
            component: toDevelopServices,
            meta: {
              permission: 'menu:toDevelopServices',
            },
          },
          {
            path: '/home/mine/myDesign',
            name: 'myDesign',
            component: myDesign,
            meta: {
              permission: 'menu:myDesign',
            },
          },
          {
            path: '/home/mine/myDesign/detail',
            name: 'mineApiDesignDetail',
            component: ApiDesignDetail,
            meta: {
              permission: 'menu:myDesignDetail',
            },
          },
          {
            path: '/home/mine/myAPI',
            name: 'MyAPI',
            component: MyAPI,
            meta: {
              permission: 'menu:MyAPI',
            },
          },
          {
            path: '/home/mine/mySubscription',
            name: 'MySubscription',
            component: MySubscription,
            meta: {
              permission: 'menu:MySubscription',
            },
          },
          {
            path: '/home/mine/ApprovalCenter',
            name: 'ApprovalCenter',
            component: ApprovalCenter,
            meta: {
              permission: 'auditCenter',
            },
          },
          {
            path: '/home/mine/ApprovalCenter/ApproveDetail',
            name: 'ApproveCenterDetail',
            component: () => import('@/views/approvalCenter/details.vue'),
            meta: {
              permission: 'auditCenter.edit',
            },
          },
        ],
      },
      {
        path: '/home/ApiMarket',
        name: 'ApiMarket',
        meta: {
          isDirect: true,
          permission: 'apiMall.list',
        },
        children: [
          {
            path: '/home/ApiMarket/ApiMarketList',
            name: 'ApiMarketList',
            component: ApiMarket,
            meta: {
              permission: 'apiMall.list',
            },
          },
          {
            path: '/home/ApiMarket/ApiInformation',
            name: 'ApiInformation',
            component: ApiInformation,
            meta: {
              permission: 'apiMall.list.search',
            },
          },
        ],
      },
      {
        path: '/home/ApiManage',
        name: 'ApiManage',
        meta: { isDirect: true },
        children: [
          {
            path: '/home/ApiManage/ApiDesign',
            name: 'ApiDesign',
            component: ApiDesign,
            meta: {
              permission: 'menu:ApiDesign',
            },
          },

          {
            path: '/home/ApiManage/ApiDesign/detail',
            name: 'ApiDesignDetail',
            component: ApiDesignDetail,
            meta: {
              permission: 'menu:ApiDesignDetail',
            },
          },
          {
            path: '/home/ApiManage/ApiManageCreate',
            name: 'ApiManageCreate',
            component: ApiManageCreate,
            meta: {
              permission: 'apiManage.create',
            },
          },
          {
            path: '/home/ApiManage/ApiManagelist',
            name: 'ApiManageList',
            component: ApiManage,
            meta: {
              permission: 'apiManage.create',
            },
          },

          {
            path: '/home/ApiManage/ApiView',
            name: 'ApiDocView',
            component: ApiDocView,
            meta: {
              permission: 'apiManage.ApiDocView',
            },
          },
          {
            path: '/home/ApiManage/ApiConfirmPage',
            name: 'ApiConfirmPage',
            component: ApiConfirmPage,
            meta: {
              permission: 'apiManage.ApiConfirmPage',
            },
          },
          {
            path: '/home/ApiManage/ApiManageRegister',
            name: 'ApiManageRegister',
            component: ApiManageRegister,
            meta: {
              permission: 'apiManage.register',
            },
          },
          {
            path: '/home/ApiManage/ApiManageRegister/detail',
            name: 'ApiManageRegisterDetail',
            component: ApiManageRegisterDetail,
            meta: {
              permission: 'menu:ApiManageRegisterDetail',
            },
          },
          {
            path: '/home/ApiManage/ApiManageRegister/view',
            name: 'ApiManageRegisterView',
            component: ApiManageRegisterView,
            meta: {
              permission: 'apiManage.ApiManageRegisterView',
            },
          },
        ],
      },
      {
        path: '/home/ApiFlowChart',
        name: 'ApiFlowChart',
        meta: {
          isDirect: true,
          permission: 'ApiFlowChart',
        },
        children: [
          {
            path: '/home/ApiFlowChart/ApiFlowChartManagement',
            name: 'ApiFlowChartManagementList',
            component: ApiFlowChartManagementList,
            meta: { permission: 'ApiFlowChartManagementList' },
          },
          {
            path: '/home/ApiFlowChart/ApiFlowChartManagementEdit',
            name: 'ApiFlowChartManagementEdit',
            component: ApiFlowChartManagementEdit,
            meta: {
              permission: 'ApiFlowChartManagementEdit',
            },
          },
          {
            path: '/home/ApiFlowChart/ApiFlowChartManagementDetail',
            name: 'ApiFlowChartManagementDetail',
            component: ApiFlowChartManagementDetail,
            meta: {
              permission: 'ApiFlowChartManagementDetail',
            },
          },
          {
            path: '/home/ApiFlowChart/apiCodePlugins',
            name: 'ApiCodePluginsList',
            component: ApiCodePluginsList,
            meta: { permission: 'ApiCodePluginsList' },
          },
        ],
      },
      {
        path: '/home/Security',
        name: 'Security',
        component: Security,
        meta: {
          isDirect: true,
          permission: 'apiSecurity',
        },
        children: [
          {
            path: '/home/Security/Policy',
            name: 'Policy',
            component: Policy,
            meta: { permission: 'apiSecurity.Policy' },
          },
          {
            path: '/home/Security/registerProject',
            name: 'registerProject',
            component: ProjectManagementList,
            meta: {
              permission: 'apiSecurity.registerProject',
            },
          },
          {
            path: '/home/Security/registerProjectDetail',
            name: 'registerProjectDetail',
            component: ProjectManagementDetail,
            meta: {
              permission: 'apiSecurity.registerProjectEdit',
            },
          },
          {
            path: '/home/Security/ApiOperationStatistics',
            name: 'ApiOperationStatistics',
            component: ApiOperationStatistics,
            meta: {
              permission: 'apiOperate.statistics',
            },
          },
          {
            path: '/home/Security/ApiOperationLow',
            name: 'ApiOperationLow',
            component: ApiOperationLow,
            meta: {
              permission: 'apiOperate.low',
            },
          },
          // {
          //     path: '/home/Security/InvokeDetails', 已废弃
          //     name: 'InvokeDetails',
          //     component: InvokeDetails,

          //     permission: 'apiOperate.statistics.invoke',
          //     meta: { title: '调用详情', GuidePath: true, JumpPath: "/home/Security/ApiOperationStatistics",permission: 'apiOperate.statistics.invoke', }
          // },
          {
            path: '/home/Security/LogDetails',
            name: 'LogDetails',
            component: LogDetails,

            meta: {
              title: '日志详情',
              GuidePath: true,
              JumpPath: '/home/Security/ApiOperationStatistics',
              permission: 'apiOperate.statistics.invoke',
            },
          },
          {
            path: '/home/Security/Alarm',
            name: 'Alarm',
            component: Alarm,
            meta: {
              permission: 'apiSecurity.alarm',
            },
          },
          {
            path: '/home/Security/Config',
            name: 'Config',
            component: () => import('@/views/SecurityConfig'),
            meta: {
              permission: 'apiSecurity.policyConf',
            },
          },
        ],
      },
      {
        path: '/home/operateIndicatorsManagement',
        name: 'operateIndicatorsManagement',
        meta: {
          isDirect: true,
          permission: 'operateIndicatorsManagement',
        },
        children: [
          {
            path: '/home/operateIndicatorsManagement/list',
            name: 'OperateIndicatorsManagementList',
            component: OperateIndicatorsManagementList,
            meta: { permission: 'OperateIndicatorsManagementList' },
          },
          {
            path: '/home/operateIndicatorsManagement/edit',
            name: 'OperateIndicatorsManagementEdit',
            component: OperateIndicatorsManagementEdit,
            meta: {
              permission: 'OperateIndicatorsManagementEdit',
            },
          },
        ],
      },
      {
        path: '/home/operateManagement',
        name: 'OperateManagement',
        meta: {
          isDirect: true,
          permission: 'operateManagement',
        },
        children: [
          {
            path: '/home/operateManagement/list',
            name: 'OperateManagementList',
            component: OperateManagementList,
            meta: { permission: 'OperateManagementList' },
          },
          {
            path: '/home/operateManagement/edit',
            name: 'OperateManagementEdit',
            component: OperateManagementEdit,
            meta: {
              permission: 'OperateManagementEdit',
            },
          },
        ],
      },

      {
        path: '/home/DataResourceCenter',
        name: 'DataResourceCenter',
        meta: { isDirect: true, permission: 'menu:DataResourceCenter' },
        children: [
          {
            path: '/home/DataResourceCenter/APIAuthManagement',
            name: 'APIAuthManagement',
            component: APIAuthManagementList,
            meta: {
              permission: 'menu:APIAuthManagement',
            },
          },
          {
            path: '/home/DataResourceCenter/APIAuthManagement/APIAuthManagementDetail',
            name: 'APIAuthManagementDetail',
            component: APIAuthManagementDetail,
            meta: {
              permission: 'menu:APIAuthManagementDetail',
            },
          },
          {
            path: '/home/DataResourceCenter/DataSourceManage',
            name: 'DataSourceManage',
            component: DataSourceManage,
            meta: {
              permission: 'dataSource',
            },
            redirect:
              '/home/DataResourceCenter/DataSourceManage/DataSourceList',
            children: [
              {
                path: '/home/DataResourceCenter/DataSourceManage/DataSourceList',
                name: 'DataSourceList',
                component: DataSourceList,
                meta: {
                  permission: 'dataSource.list',
                },
              },
              {
                path: '/home/DataResourceCenter/DataSourceManage/DataSourceInfo',
                name: 'DataSourceInfo',
                component: DataSourceInfo,
                meta: {
                  permission: 'dataSource.create',
                },
              },
            ],
          },
          {
            path: '/home/DataResourceCenter/ServiceClassificationManagement/list',
            name: 'ServiceClassificationManagementDetailList',
            meta: {
              permission: 'menu:ServiceClassificationManagementDetailList',
            },
            component: ServiceClassificationManagement,
          },
          {
            path: '/home/DataResourceCenter/ServiceClassificationManagement/detail',
            name: 'ServiceClassificationManagementDetail',
            meta: {
              permission: 'menu:ServiceClassificationManagementDetail',
            },
            component: ServiceClassificationManagementDetail,
          },
          {
            path: '/home/DataResourceCenter/Overallfunction/list',
            name: 'OverallfunctionList',
            meta: {
              permission: 'menu:OverallfunctionList',
            },
            component: OverallfunctionList,
          },
          {
            path: '/home/DataResourceCenter/Overallfunction/detail',
            name: 'OverallfunctionDetail',
            meta: {
              permission: 'menu:OverallfunctionDetail',
            },
            component: OverallfunctionDetail,
          },
          {
            path: '/home/DataResourceCenter/keyManagement/list',
            name: 'KeyManagement',
            meta: {
              permission: 'menu:KeyManagementList',
            },
            component: KeyManagementList,
          },
          {
            path: '/home/DataResourceCenter/keyManagement/detail',
            name: 'KeyManagementDetail',
            meta: {
              permission: 'menu:KeyManagementDetail',
            },
            component: KeyManagementDetail,
          },
        ],
      },
      {
        path: '/home/BaseConfig',
        name: 'BaseConfig',
        component: BaseConfig,

        meta: { isDirect: true, permission: 'basicSetup' },
        children: [
          {
            path: '/home/BaseConfig/CategoryManage',
            name: 'CategoryManage',
            component: CategoryManage,
            meta: {
              permission: 'basicSetup.category',
            },
          },
          {
            path: '/home/BaseConfig/BaseConfigUser',
            name: 'BaseConfigUser',
            component: BaseConfigUser,
            meta: {
              permission: 'basicSetup.user',
            },
          },
          {
            path: '/home/BaseConfig/ApprovalConfig',
            name: 'ApprovalConfig',
            meta: {
              isDirect: true,
              permission: 'basicSetup.auditConf.list',
            },
            children: [
              {
                path: '/home/BaseConfig/ApprovalConfig/list',
                name: 'ApprovalConfigList',
                meta: {
                  permission: 'basicSetup.auditConf.list',
                },
                component: approvalConfig,
              },
              {
                path: '/home/BaseConfig/ApprovalConfig/detail',
                name: 'ApprovalConfigDetail',
                meta: {
                  permission: 'basicSetup.auditConf.detail',
                },
                component: ApprovalConfigDetail,
              },
            ],
          },
          {
            path: '/home/BaseConfig/BaseConfigRole',
            name: 'BaseConfigRole',
            component: BaseConfigRole,
            meta: {
              permission: 'basicSetup.role',
            },
          },
          {
            path: '/home/BaseConfig/AuthorityManagement',
            name: 'AuthorityManagement',
            meta: { isDirect: true, permission: 'basicSetup.authority' },
            children: [
              {
                path: '/home/BaseConfig/AuthorityManagement/list',
                name: 'AuthorityManagementList',
                meta: {
                  permission: 'basicSetup.authority.search',
                },
                component: AuthorityManagement,
              },
              {
                path: '/home/BaseConfig/AuthorityManagement/detail',
                name: 'AuthorityManagementDetail',
                meta: {
                  permission: 'basicSetup.authority.edit',
                },
                component: AuthorityManagementDetail,
              },
            ],
          },
          {
            path: '/home/BaseConfig/App',
            name: 'App',
            component: App,
            meta: {
              permission: 'basicSetup.app',
            },
            redirect: '/home/BaseConfig/App/AppInformation',
            children: [
              {
                path: '/home/BaseConfig/App/AppInformation',
                name: 'AppInformation',
                meta: {
                  permission: 'basicSetup.app.search',
                },
                component: AppInformation,
              },
              {
                path: '/home/BaseConfig/APP/AppDetails',
                name: 'AppDetails',
                component: AppDetails,
                meta: {
                  title: 'APP详情',
                  GuidePath: true,
                  JumpPath: '/home/BaseConfig/App/AppInformation',
                  permission: 'basicSetup.app.edit',
                },
              },
            ],
          },
          {
            path: '/home/BaseConfig/BaseConfigTenant',
            name: 'BaseConfigTenant',
            component: BaseConfigTenant,
            meta: {
              permission: 'basicSetup.tenant',
            },
          },

          // {
          //     path: '/home/BaseConfig/BaseConfigBase',
          //     name: 'BaseConfigBase',
          //     component: BaseConfigBase,
          //     meta:{
          //         permission: 'basicSetup.config'
          //     },
          //     redirect: '/home/BaseConfig/BaseConfigBase/lookup',
          //     children: [
          //         {
          //             path: '/home/BaseConfig/BaseConfigBase/details',
          //             name: 'details',
          //             component: details,
          //             meta: { title: 'lookup详情', GuidePath: true, JumpPath: "/home/BaseConfig/BaseConfigBase/lookup", permission: 'basicSetup.config'}
          //         },
          //         {
          //             path: '/home/BaseConfig/BaseConfigBase/lookup',
          //             name: 'lookup',
          //             meta:{
          //                 permission: 'basicSetup.config'
          //             },
          //             component: Lookup
          //         }
          //     ]
          // },
        ],
      },
      {
        path: '/home/UserCenter/AccountSettings',
        name: 'AccountSettings',
        component: AccountSettings,
      },
      // {
      //     path: '/home/UserCenter',
      //     name: 'UserCenter',
      //     component: UserCenter,
      //     meta: { isDirect: true },
      //     children: [
      //         {
      //             path: '/home/UserCenter/MyToDos/:fromPage',
      //             name: 'MyToDos',
      //             component: MyToDos
      //         },
      //         {
      //             path: '/home/UserCenter/ApproveDetail/:fromPage',
      //             name: 'ApproveDetail',
      //             component: ApproveDetail,
      //             meta: { title: 'APP详情', GuidePath: true, JumpPath: "/home/UserCenter/MyToDos/",}
      //         },
      //         {
      //             path: '/home/UserCenter/AccountSettings',
      //             name: 'AccountSettings',
      //             component: AccountSettings
      //         }
      //     ]
      // },
    ],
  },
  {
    path: '/Cockpit',
    name: 'Cockpit',
    component: Cockpit,
    meta: {
      permission: 'apiOperate.dashBoard.search',
    },
  },
  // {
  //     path:'http://idfdoc.nanniwan.online/idf/01_overview.html',
  //     name:'Cockpit111',
  //     component: "",
  //     meta:{
  //         permission: 'apiOperate.dashBoard.search'
  //     }
  // },
  // {
  //     path: "/404",
  //     name: "NotFound",
  //     component: () => import("@/views/PAGE_NOT_FOUND.vue"),
  //     meta: { title: "404" },
  // },
  // 当什么都没有匹配到的时候，重定向页面到 404 页面
  {
    path: '/Expired',
    name: 'Expired',
    component: () => import('@/views/Expired.vue'),
    meta: { title: 'Expired' },
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notMatch',
    component: () => import('@/views/PAGE_NOT_FOUND.vue'),
    meta: { title: '404' },
  },
]

const router = createRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  history: createWebHashHistory(),
  routes,
})

// function isPermissionMenu(menuList,path){
//     let hasPermission = false
//     for(let i = 0; i<menuList.length;i++){
//         if(menuList[i].path === path && menuList[i].menuStatus == 2){
//             hasPermission = true
//             console.log(hasPermission,'>>>>>>hasPermission222')
//             return hasPermission
//         }
//         if(menuList[i].children && menuList[i].children.length){
//             return isPermissionMenu(menuList[i].children,path)
//         }
//     }
//     return hasPermission

// }

// // // 路由拦截，父级菜单点击跳转时根据页面权限重定向第一个有权限的菜单
// router.beforeEach((to, from, next) => {
//    console.log(to, from,'>>>>>to, from')
//     if(to.path !=='/login' && to.path !=='/start' && to.path !=='/404'){
//       let menuList = JSON.parse(localStorage.getItem('menuList'))
//       console.log(isPermissionMenu(menuList,to.path),from)
//       if(isPermissionMenu(menuList,to.path)){
//         next()
//       }else{
//         router.push('/404')
//       }
//     }else{
//         next()
//     }

// })

export default router
