<template>
  <div class="main_bgimg bg_img" style="height: 380px">
    <div class="main_header">
      <div class="main_header_text">{{ text }}</div>
    </div>
    <div
      class="main_content"
      ref="LeftScrollBox"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      style="height:310px;overflow: hidden"
    >
      <el-row
        style="
          margin-top: 20px;
          height: 24px;
          display: flex;
          align-items: center;
        "
        :gutter="[12, 24]"
        v-for="(item, index) in state.list"
        :key="index"
      >
        <el-col :span="3">
          <div class="sort_number">
            <img
              src="../../../assets/image/cockpit/list-icon.png"
              alt=""
              style="margin-left: 20px"
            />
          </div>
        </el-col>
        <el-col :span="9">
          <div class="text_omit" title="item.apiName">{{ item.apiName }}</div>
        </el-col>
        <el-col :span="9">
          <el-progress
            :percentage="item.percentage"
            :color="index < 3 ? greeColors : blueColors"
            :format="formatProgress"
          />
        </el-col>
        <el-col :span="3">
          <div :class="index < 3 ? 'count-box-top' : 'count-box-bot'">
            {{ item.countSubscribe ? item.countSubscribe + "次" : "-" }}
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {request} from '@/api'
import { ref, reactive, onUnmounted, onBeforeUnmount, watch } from "vue";
export default {
  name: "LeftBottom",
  props: {
    appId: {
      type: String,
    },
  },
  setup(props) {
    const text = ref("最受欢迎API：订阅次数TOP15");
    const timer = ref(null);
    const LeftScrollBox = ref(null);
    const state = reactive({
      list: [
        { dbName: "查询用户基本信息", count: 1510, percentage: 100 },
        { dbName: "查询用户下的API数量", count: 792, percentage: 90 },
        { dbName: "查询API订阅数量", count: 657, percentage: 80 },
        { dbName: "保存API", count: 441, percentage: 70 },
        { dbName: "上线API服务", count: 102, percentage: 60 },
        { dbName: "订阅API", count: 80, percentage: 50 },
        { dbName: "上线API", count: 49, percentage: 30 },
      ],
    });
    const formatProgress = () => {
      return "";
    };
    const greeColors = [
      { color: "#0CC89B", percentage: 0 },
      // { color: "#0CC89B", percentage: 50 },
      { color: "#82FFE1", percentage: 100 },
    ];
    const blueColors = [
      { color: "#00132F", percentage: 0 },
      // { color: "#3178EF", percentage: 50 },
      { color: "#30D3EF", percentage: 100 },
    ];
    const getData = () => {
      request.subscribeTop({appId:props.appId}).then((res) => {
        if (res.data.code == 200) {
          res.data.data.map((item) => {
            item.percentage = (item.countSubscribe / 150) * 50;
          });
          state.list = res.data.data;
         start();
        }
      });
    };
    getData();
    const ListScroll = () => {
      let scrollDom = LeftScrollBox.value;
      // 判读组件是否渲染完成
      if (scrollDom.offsetHeight == 0) {
        scrollDom = LeftScrollBox.value;
      } else {
        // 如果列表数量过少不进行滚动
        if (scrollDom.children.length < 8) {
          clearTimeout(timer.value);
          return;
        }
        // 组件进行滚动
        scrollDom.scrollTop += 1;
        // 判断是否滚动到底部
        if (
          scrollDom.scrollTop ==
          scrollDom.scrollHeight - scrollDom.clientHeight
        ) {
          // 获取组件第一个节点
          let first = scrollDom.children[0];
          // 删除节点
          scrollDom.removeChild(first);
          // 将该节点拼接到组件最后
          scrollDom.append(first);
        }
      }
    };
    const start = () => {
      clearTimeout(timer.value);
      // 定时器触发周期
      let speed = ref(25);
      timer.value = setInterval(ListScroll, speed.value);
    };

    onBeforeUnmount(() => {
      clearTimeout(timer.value);
    });
    onUnmounted(() => {
      clearTimeout(timer.value);
    });
    const handleMouseEnter = () => {
      clearTimeout(timer.value);
    };
    const handleMouseLeave = () => {
      start();
    };
    watch(
      () => props.appId,
      (newData) => {
        if (newData) {
          state.list = [];
          clearTimeout(timer.value);
          getData();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return {
      text,
      state,
      formatProgress,
      greeColors,
      blueColors,
      getData,
      timer,
      LeftScrollBox,
      ListScroll,
      start,
      handleMouseLeave,
      handleMouseEnter,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "./leftBottom.scss";
</style>