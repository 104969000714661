<template>
  <div>
    <el-drawer v-model="showDrawer" size="1000" style="text-align:left;" @closed="close">
    <template #header>
      <h4 class="drawer-title">{{indexName}}</h4>
    </template>
    <template #default>
      <div class="search"  style="display:flex;"> 
        <el-input
              v-model="apiName"
              placeholder="请输入API名称"
              clearable
              style="margin-bottom: 24px;flex:1;"
              @keyup.enter="reloadTable"
            >
              <template #suffix>
                <el-icon class="icon-search" @click="reloadTable"
                  ><Search
                /></el-icon>
              </template>
        </el-input>
        <el-select
            style="margin-left: 24px;flex:1;"
            v-model="apiOwner"
            filterable
            remote
            reserve-keyword
            placeholder="请输入API责任人"
            :remote-method="getApiOwnerList"
            @change="reloadTable"
            >
          <el-option
              v-for="item in apiOwnerList"
              :key="item.userId"
              :label="item.userName + ' ' +item.account"
              :value="item.userId"
          />
        </el-select>

        <el-select
            style="margin-left: 24px;flex:1;"
            v-model="time"
            @change="changeTime"
            placeholder="请选择日期"
            >
          <el-option
              v-for="item in timeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
  </div>
      <el-table :data="tableData">
                
                <el-table-column
                  prop="apiName"
                  label="API名称"
                   width="200"
                  :show-overflow-tooltip="true"
                  
                />
                 <el-table-column
                  prop="apiType"
                  label="API类型"
                  width="200"
                  :show-overflow-tooltip="true"
                >
                  <template #default="scope">
                    {{
                      createTypeMaps[scope.row.apiType]
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="apiVersion"
                  label="版本"
                   width="200"
                />
                
                <el-table-column
                  prop="total"
                  label="总请求量"
                  v-if="indexName !== '零访问服务'"
                />
                <el-table-column
                  prop="durationAvg"
                  label="总平均响应时间（单位：秒）"
                  width="240"
                  v-if="indexName === '慢服务'"
                />
                <el-table-column
                  prop="slowDurationAvg"
                  label="慢服务平均响应时间（单位：秒）"
                  width="240"
                  v-if="indexName === '慢服务'"
                />
                <el-table-column
                  prop="slowTotal"
                  label="慢服务请求总量"
                  width="200"
                  v-if="indexName === '慢服务'"
                />
                <el-table-column
                  prop="slowRatio"
                  label="慢服务请求占比"
                  width="200"
                  v-if="indexName === '慢服务'"
                />
                 <el-table-column
                  prop="errorTotal"
                  label="异常请求量"
                  width="200"
                  v-if="indexName === '高异常服务'"
                />
                 <el-table-column
                  prop="errorRatio"
                  label="异常率占比"
                   width="200"
                   v-if="indexName === '高异常服务'"
                />
                <el-table-column
                  prop="bigdataTotal"
                  label="大数据量"
                  v-if="indexName === '大数据量服务'"
                />
                <el-table-column
                  prop="bigdataRatio"
                  label="大数据率占比"
                  width="200"
                   v-if="indexName === '大数据量服务'"
                />
                <el-table-column
                  prop="apiUrl"
                  label="URL"
                   width="200"
                  :show-overflow-tooltip="true"
                  
                />
                <el-table-column
                  label="责任人"
                  width="200"
                >
                <template #default="scope">
                {{
                  scope.row.apiOwnerName + ' ' + scope.row.apiOwnerNumber
                }}
                </template>
                </el-table-column>
                <el-table-column
                  prop="creationTime"
                  label="统计时间"
                  width="200"
                />
              </el-table>
              <!--分页组件-->
            <div style="padding: 10px 0; float: right;">
              <el-pagination
                background
                small
                :current-page="currentPage"
                :page-size="pageSize"
                :page-sizes="[10,20,50,100]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
    </template>
  </el-drawer>
  </div>
</template>

<script>
import { ref } from 'vue';
import {request} from '@/api'
import {createTypeMap} from '@/utils/constant'
export default {
    setup(){
        let time = ref('lastDay')
        let apiOwnerList = ref([])
        let apiName = ref(null)
        let indexName = ref(null)
        let statisticsType = ref('day')
        let statisticsCount = ref(-1)
        let apiOwner = ref(null)
        let currentPage = ref(1)
        let pageSize = ref(10)
        let total = ref(0)
        let tableData = ref([])
        let createTypeMaps = ref(createTypeMap)
        let indexType = {
          '大数据量服务':'bigdata_api',
          '高异常服务':'error_api',
          '高频服务':'high_api',
          '低频服务':'low_api',
          '慢服务':'slow_api',
          '零访问服务':'zero_api',

        }
        let timeList = ref({
          lastDay:{
            label:'近一天',
            value:'lastDay',
            statisticsType:'day',
            statisticsCount:-1
          },
          lastWeek:{
            label:'近一周',
            value:'lastWeek',
            statisticsType:'day',
            statisticsCount:-7
          },
          lastMonth:{
            label:'近一月',
            value:'lastMonth',
            statisticsType:'month',
            statisticsCount:-1
          },
          lastThreeMonth:{
            label:'近三个月',
            value:'lastThreeMonth',
            statisticsType:'month',
            statisticsCount:-3
          },
          lastSixMonth:{
            label:'近半年',
            value:'lastSixMonth',
            statisticsType:'month',
            statisticsCount:-6
          },
        })


        let showDrawer = ref(false)
        const show = (name,)=>{
          indexName.value = name
          showDrawer.value = true
          getData()
        }

        const close = ()=>{
           apiOwner.value = null
           time.value = 'lastDay'
           apiName.value = null
           statisticsType.value = 'day'
           statisticsCount.value = -1
           tableData.value = []
        }
        const getApiOwnerList = (query)=>{
          if (query) {
            request.selectUserByParams({
                likeData:query,
                // appId:localStorage.getItem('appId'),
                currentPage: 1,
                pageSize: 10000
              }).then((res) => {
              if (res?.data?.code === 200) {
                apiOwnerList.value = res.data.data.result
              }
            });
          } else {
            apiOwnerList.value = []
          }
        }

        const changeTime = (value)=>{
          if(value){
            let time = timeList.value[value]
            statisticsType.value = time.statisticsType
            statisticsCount.value = time.statisticsCount
          }
          reloadTable()
        }

        const reloadTable = ()=>{
          currentPage.value = 1
          getData()
        }

         const handleSizeChange = (val) => {
          pageSize.value = val;
          getData();
        };
        const handleCurrentChange = (val) => {
          currentPage.value = val;
          getData();
        };

        const getData = ()=>{
          request.pageListIndexDetails({
            statisticsType: statisticsType.value,
            statisticsCount:statisticsCount.value,
            apiName:apiName.value,
            apiOwner:apiOwner.value,
            currentPage:currentPage.value,
            pageSize:pageSize.value,
            indexType:indexType[indexName.value],
          }).then((res) => {
          if (res?.data?.code === 200) {
            tableData.value = res.data.data?.result?formatterData(res.data.data.result):[]
            total.value = res.data.data.pageVO.total
          }
        });
        }

        const formatterData = (data)=>{
          if(data.length){
           return data.map(item=>{
                if(item.indexResultdata){
                  let indexResultdata = JSON.parse(item.indexResultdata)
                  return {
                    ...item,
                    ...indexResultdata,
                  }
                }else{
                  return item
                }
            })
          }else{
            return []
          }
        }

        return{
            close,
            time,
            showDrawer,
            show,
            apiName,
            getData,
            apiOwner,
            statisticsType,
            statisticsCount,
            currentPage,
            pageSize,
            tableData,
            createTypeMaps,
            reloadTable,
            changeTime,
            apiOwnerList,
            getApiOwnerList,
            indexName,
            timeList,
            total,
            handleSizeChange,
            handleCurrentChange
        }
    }

}
</script>

<style lang="less" scoped>
.drawer-title{
  text-align:left;color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  border-bottom:1px solid #d9d9d9;
  padding-bottom:15px;
  margin-bottom: 0;
}
/deep/.el-drawer__header{
  margin-bottom: 0;
}

</style>