<template>
  <div style="height: 92%;width: 100%;padding-left: 0px;padding-top: 4%;margin-left: 0px;margin-top: 0px">
    <div style="text-align:left; margin-left: 16px; font-size: 25px">
      账户设置
    </div>
    <div style="text-align:left; margin-top: 30px; margin-left: 80px">
      <div style="width: 50%; height: 20px; float: left;">密码修改</div>
      <div style="width: 50%; height: 40px; float: right;">
        <el-button style="width: 60px; height: 30px" type="primary" size="small" @click="updatePassWord()">修改</el-button>
      </div>
      <div style="height: 20px"></div>
    </div>
    <div style="margin-left: 80px; margin-top: 15px; width:60%; height:1px; border-top:solid rgb(0,0,0) 1px;"></div>
    <div style="text-align:left; margin-top: 10px; margin-left: 80px">
      <div style="width: 50%; height: 30px; float: left;">密保手机</div>
      <div style="width: 50%; height: 40px; float: right;">
        <el-button style="margin-top: 10px; width: 60px; height: 30px" type="primary" size="small"
          @click="updatePhoneNumber()">修改</el-button>
      </div>
      <div style="height: 20px">已绑定手机号：{{ phoneNumber }}</div>
    </div>
    <!-- <div style="height: 20px">已登录本系统{{ loginCount }}次</div> -->
    <el-drawer v-model="drawer2">
      <template #header>
        <div style="font-size: 30px">{{ labelName }} </div>
      </template>
      <template #default>
        <div>
          <el-form label-position="right" label-width="100px" ref="ruleFormRef" :rules="validateForm" :model="updateData"
            style="max-width: 460px">
            <el-form-item v-if="updateData.updateType == 1" prop="phoneNumber" label="手机号">
              <el-input v-model="updateData.phoneNumber" autocomplete="off" maxlength="11" show-word-limit />
            </el-form-item>
            <el-form-item v-if="updateData.updateType != 1" prop="originalPassword" label="请输原密码">
              <el-input v-model="updateData.originalPassword" type="password" autocomplete="original-password"
                maxlength="20" show-password show-word-limit />
            </el-form-item>
            <el-form-item v-if="updateData.updateType != 1" prop="password1" label="请输新密码">
              <el-input v-model="updateData.password1" type="password" autocomplete="new-password" maxlength="20"
                show-password show-word-limit />
            </el-form-item>
            <el-form-item v-if="updateData.updateType != 1" prop="password2" label="确认新密码">
              <el-input v-model="updateData.password2" type="password" autocomplete="new-password" maxlength="20"
                show-password show-word-limit />
            </el-form-item>
          </el-form>
        </div>
        <div v-if="updateData.updateType != 1">
          <h6>提示：密码修改成功将自动注销登录，请使用新密码重新登录。</h6>
        </div>
      </template>
      <template #footer>
        <div style="flex: auto">
          <el-button type="primary" @click="confirmInfo(ruleFormRef)">保存</el-button>
          <el-button @click="cancelClick">取消</el-button>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import {request} from '@/api'
import { ElMessage, ElMessageBox } from "element-plus";
import { dataEncryption } from "@/assets/js/dataEncryption.js";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "AccountSettings",
  setup() {

    const tenantId = ref(localStorage.getItem("tenantId"));  //租户ID
    const loginId = ref(localStorage.getItem("userId"));  //登录用户ID
    const phoneNumber = ref(localStorage.getItem("phoneNumber")); //手机号
    const loginCount = ref(localStorage.getItem("loginCount"));
    const drawer2 = ref(false);
    const labelName = ref('密码修改');
    const updateData = reactive({
      phoneNumber: '',
      originalPassword: '',
      password1: '',
      password2: '',
      updateType: ''
    });

    const route = useRoute();
    const router = useRouter()
    const activeDrawer = route.query.activeDrawer;

    const validatePass2 = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请再次输入密码"));
      } else if (value != updateData.password1) {
        callback(new Error("两次密码不匹配!"));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      var reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error('请输入有效的手机号码'));
      } else {
        callback();
      }
    };
    const ruleFormRef = ref(null);
    const validateForm = reactive({
      phoneNumber: [{ required: true, message: '请输入手机号', trigger: 'blur' },
      { validator: validatePhone, trigger: 'blur' }],
      originalPassword: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
      password1: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
      password2: [{ required: true, message: '请再次输入新密码', trigger: 'blur' },
      { validator: validatePass2, trigger: 'blur' }],
    });
    const updatePhoneNumber = () => {
      if (ruleFormRef.value != null) {
        ruleFormRef.value.resetFields();
      }
      labelName.value = '手机号修改';
      updateData.updateType = 1;
      drawer2.value = true;
    };
    const updatePassWord = () => {
      if (ruleFormRef.value != null) {
        ruleFormRef.value.resetFields();
      }
      labelName.value = '密码修改';
      updateData.updateType = 2;
      drawer2.value = true;
    };
    const confirmInfo = async (formEl) => {
      await formEl.validate((valid) => {
        if (valid) {
          // 加密数据
          let password = dataEncryption(updateData.password1);
          let originalPassword = dataEncryption(updateData.originalPassword);
          request.updateUserOneself({
              tenantId: tenantId.value,
              userId: loginId.value,
              originalPassword: originalPassword,
              password: password,
              phoneNumber: updateData.phoneNumber,
              updatedBy: loginId.value
            }).then(resp => {
            let respCode = resp.data.code;
            if (respCode == '200') {
              if (updateData.updateType == 1) {
                localStorage.setItem("phoneNumber", updateData.phoneNumber || '');
                phoneNumber.value = updateData.phoneNumber;
                ElMessage.success("手机号修改成功");
                drawer2.value = false;
              } else {
                ElMessage.success("密码修改成功");
                drawer2.value = false;
                router.push('/login')
              }
            } else {
              ElMessageBox.alert(resp.data.msg);
            }
          })
        }
      })
    };
    const cancelClick = () => {
      updateData.password1 = '';
      updateData.password2 = '';
      updateData.phoneNumber = '';
      drawer2.value = false;
      ruleFormRef.value.resetFields();
    };
    return {
      tenantId,
      loginId,
      phoneNumber,
      labelName,
      updateData,
      drawer2,
      ruleFormRef,
      validateForm,
      validatePass2,
      validatePhone,
      updatePhoneNumber,
      updatePassWord,
      confirmInfo,
      cancelClick,
      activeDrawer,
      loginCount
    }
  },
  beforeCreate() {
    if (this.activeDrawer === 'true') {
      this.drawer2 = true;
    }
  }
}
</script>

<style scoped></style>